const trans = {
  "Customer Growth": "نمو العملاء",
  Absolute: "مطلق",
  Relative: "نسبي",
  "New Customers": "عملاء جدد",
  "Dormant Customers": "عملاء لم يعاودوا الطلب",
  "Returning Customers": "عملاء عاودوا الطلب",
  "This chart shows how our customer base is growing, and what proportion of them are still active (in yellow), new (in green), and dormant - or who have not made an order in the month, but have in previous months.":
    "الرسم البياني التالي يوضح كيفية نمو قاعدة عملائك، ونسبة العملاء الذين استمروا بالشراء (باللون الاصفر) والعملاء الجدد (باللون الأخضر) والعملاء الذين انقطعوا عن الشراء (باللون الأحمر)",
  "Customers as % of Total": "العملاء كنسبة من المجموع",
  "Customer Growth Over Time": "نمو العملاء على مر الوقت",

  RFM: "فئات العملاء",
  "RFM Report": "تقرير فئات العملاء",
  "Customer Segments": "تقرير فئات العملاء",
  Segment: "الفئة",
  "Recency (Days)": "الحداثة (بالأيام)",
  "Purchases (Orders)": "المشتريات (الطلبات)",
  Best: "الأفضل",
  Montserrat: "Cairo",
  "At Risk": "في خطر",
  "At-Risk": "في خطر",
  Frugal: "الاقل صرفا",
  Lost: "منقطع",
  Spenders: "الأكثر صرفا",
  Loyal: "الأوفياء",
  Others: "آخرين",
  "Reports > Customers": "التقارير > الزبائن",
  "RFM Analysis": "تحليل فئات العملاء",
  "Customer Base Mix": "تشكيلة العملاء",
  "Customer Growth": "نمو العملاء",
  "Since Opening": "منذ الافتتاح",
  Quarter: "ربع سنوي",
  Segments: "فئات العملاء",
  "Segments (%)": " % شرائح العملاء ",
  "Customer Segments (%)": "% شرائح العملاء",
  right: "left",
  left: "right",
  Search: "بحث",
  "Money Spent": "نقد",
  "Number of Orders": "عدد الطلبات",
  "Days Since Last Order": "الايام منذ آخر طلب",
  // "These customers are new in the selected time period. They have not purchased before the selected start date. Compared to other customers, they purchased their first order most recently.":
  //   "هؤلاء العملاء جدد في الفترة الزمنية المحددة. لم يشتروا قبل تاريخ البدء المحدد. مقارنة بالعملاء الآخرين ، قاموا بطلبهم الأول مؤخرًا.",
  // "These customers are your best customers. Compared to other customers, they buy often, spend a lot, and recently purchased from your shop.":
  //   "أفضل عملائك. بالمقارنة مع العملاء الآخرين ، فإنهم يشترون وينفقون أكثر",
  // "Compared to others, loyal customers purchase from you most frequently and have also recently purchased from your store. They spend an average amount. These customers should be rewarded for their loyalty.":
  //   "العملاء المخلصون الذين يشترون منك بشكل متكرر وشرائهم مؤخرًا من متجرك.",
  // "These customers used to frequent your shop often and spend a good amount compared to other clients. Recently, they have stopped coming and buying - you should reach out to them and try to win them back. ":
  //   "هؤلاء هم العملاء الذين كانوا مشترين متكررين ، لكن مؤخرًا لم يعدوا يزورون متجرك.",
  // "These clients do not come frequently, but when they do - they spend more than the average customers. Casually target these clients to match their purchase cycles.":
  //   "لا يأتي هؤلاء العملاء كثيرًا ، لكنهم يشترون الكثير عندما يأتون للتسوق في متجرك.",
  // "These customers do not spend a lot compared to others. They may shop often or very little. Targeting these clients with advertising is not recommended. Understand what these customers purchase and try to create in-store promotions to increase their basket size when they do shop.":
  //   "هؤلاء العملاء لا يشترون الكثير مقارنة بالآخرين.",
  // "These customers have purchased very little compared to other clients in this period. They are lost clients and winning them back is more difficult than targeting other segments.":
  //   "اشترى هؤلاء العملاء القليل جدًا مقارنة بالعملاء الآخرين في هذه الفترة.",

  "All customers who have made a purchase and fall under any of the segments":
    "جميع العملاء الذين أجروا عملية شراء ويندرجون ضمن أي من الشرائح",
  "These are all registered customers including imported ones":
    "هؤلاء جميعًا عملاء مسجلون بما في ذلك العملاء المستوردون",
  "Buy and spend more then others and have very recently made a purchase - Suggest to reward with premium perks and benefits":
    "يشترون ويصرفون اكثر من الآخرين وقد قاموا بالشراء مؤخرا - نقترح مكافأتهم بمزايا وعروض مميزة",
  "Purchase from you very often and spend a relatively good amount relative to other customers - Suggest to reward with exclusive perks and benefits":
    "يشترون بكثرة ويصرفون مبالغ جيدة مقارنة بباقي العملاء - نقترح مكافأتهم بمميزات حصرية",
  "Spend well with you however recently have dropped - Reengage with a call/message and discount voucher":
    "يصرفون جيدا ولكن لم يطلبوا من فترة - نقترح اعادة استهدافهم باتصال/رسالة وكود خصم",
  "New in the selected time period. - Encourage a second order with a call/message and discount voucher":
    "عملاء جدد في الفترة المحددة. - قم بتحفيزهم للطلب مرة ثانية باتصال/رسالة وكود خصم",
  "Do not spend much or purchase from you often. - Targeting these clients with advertising is not recommended, instead understand what these customers need and provide through promotions etc.":
    "لا يصرفون او يعاودون الطلب بكثرة مقارنة بالعملاء الآخرين",
  "Do not purchase frequently, but when they do, they spend more than the average customer. - Keep engaged with updates, as discounts may not incentivize this segment to purchase more frequently":
    "لا يأتي هؤلاء العملاء كثيرًا ، لكنهم يشترون الكثير عندما يأتون للتسوق في متجرك.",
  "Purchased very little and less recently relative to others. - Energy better focused on other segments":
    "اشترى هؤلاء العملاء القليل جدًا مقارنة بالعملاء الآخرين في هذه الفترة.",
  "These customers do not fall into any particular segment in the selected period":
    "لا ينتمي هؤلاء العملاء إلى أي شريحة معينة في الفترة المحددة.",
};

export default trans;
