import {
  FETCH_ORDERS,
  SET_ORDERS_LOADING_TRUE,
  UPDATE_ORDER_STATUS_PAYMENT,
  UPDATE_ORDERS_AFTER_BULK,
  UPDATE_ORDER_NOTES,
  DISPATCH_ORDER,
  TOGGLE_SOUND,
  FETCH_PAST_ORDERS,
  EDIT_EXPECTED_DELIVERY,
  SET_ORDERS_LOADING_FALSE,
  CLEAR_PAST_ORDERS,
  STORE_SEARCHED_ORDERS,
  CHANGE_ORDERS_LAYOUT,
  EDIT_PAYMENT_TYPE,
  SOCKET_MASHKOR_UPDATE_ORDER_STATUS,
  SET_DHL_INFO,
  SET_DHL_ORDER_DISPATCHED,
  UNDISPATCH_ORDER,
  EDIT_ADDRESS,
  CHANGE_DRIVER,
  FETCH_TOPUPS,
  EDIT_BRANCH,
  EDIT_TABLE_NUMBER,
  TRANSFER_ORDERS,
  CANCEL_TOPUP,
  UPDATE_CUSTOMER_LAT_LNG,
  EDIT_SPECIAL_REMARKS,
} from "../actions/actionTypes";
import moment from "moment";

const initialState = {
  orders: null,
  promises: null,
  new_orders: null,
  topups: null,
  loading: false,
  bellHasNewOrders: false,
  bellHasNewAggregate: false,
  soundEnabled: localStorage.getItem("soundEnabled")
    ? localStorage.getItem("soundEnabled") == "true"
      ? true
      : false
    : true,
  didInteract: false,
  pastOrders: null,
  didFetchPastOrders: false,
  recentlyReceived: [],
  ordersLayout: localStorage.getItem("orders_layout")
    ? localStorage.getItem("orders_layout")
    : "new",

  refundLoading: false,
  pickupArrivalRequests: [],
  openOrderModal: null,
  audio: null,
  audioPaused: false,

  isHelpOpen: localStorage.getItem("helpOpen")
    ? localStorage.getItem("helpOpen") === "true"
      ? true
      : false
    : false,
};

const reducer = (state = initialState, { type, payload }) => {
  let newOrders = null;
  let newPastOrders = null;
  let newRecentlyReceived = [];
  let newPromises = null;
  let index = null;
  switch (type) {
    case CHANGE_ORDERS_LAYOUT:
      return { ...state, ordersLayout: payload };
    case TOGGLE_SOUND:
      localStorage.setItem(
        "soundEnabled",
        !state.soundEnabled == true ? "true" : "false"
      );
      return { ...state, soundEnabled: !state.soundEnabled };

    case SET_ORDERS_LOADING_TRUE:
      return { ...state, loading: true };
    case SET_ORDERS_LOADING_FALSE:
      return { ...state, loading: false };

    case SET_DHL_INFO:
      const { oid, dhl_info } = payload;

      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      newRecentlyReceived = state.recentlyReceived;

      if (newOrders?.length) {
        index = state.orders.findIndex((ord) => ord.id == oid);
        if (index >= 0)
          newOrders[index]["shipping"] = {
            ...newOrders[index]["shipping"],
            dispatcher: "dhl",
            dhl_info,
          };
      }
      if (newPastOrders?.length) {
        index = state.pastOrders.findIndex((ord) => ord.id == oid);
        if (index >= 0)
          newPastOrders[index]["shipping"] = {
            ...newPastOrders[index]["shipping"],
            dispatcher: "dhl",
            dhl_info,
          };
      }
      if (newRecentlyReceived?.length) {
        index = state.recentlyReceived.findIndex((ord) => ord.id == oid);
        if (index >= 0)
          newRecentlyReceived[index]["shipping"] = {
            ...newRecentlyReceived[index]["shipping"],
            dispatcher: "dhl",
            dhl_info,
          };
      }
      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case SET_DHL_ORDER_DISPATCHED:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      newRecentlyReceived = state.recentlyReceived;

      if (newOrders?.length) {
        index = state.orders.findIndex((ord) => ord.id == payload);
        if (index >= 0) {
          newOrders[index]["shipping"] = {
            ...newOrders[index]["shipping"],
            is_dispatched: true,
            dispatcher: "dhl",
            dispatch_reference:
              newOrders[index]["shipping"].dhl_info?.airway_bill_number,
          };
          newOrders[index].status = "Out for Delivery";
        }
      }
      if (newPastOrders?.length) {
        index = state.pastOrders.findIndex((ord) => ord.id == payload);
        if (index >= 0) {
          newPastOrders[index]["shipping"] = {
            ...newPastOrders[index]["shipping"],
            is_dispatched: true,
            dispatcher: "dhl",
            dispatch_reference:
              newPastOrders[index]["shipping"].dhl_info?.airway_bill_number,
          };
          newPastOrders[index].status = "Out for Delivery";
        }
      }
      if (newRecentlyReceived?.length) {
        index = state.recentlyReceived.findIndex((ord) => ord.id == payload);
        if (index >= 0) {
          newRecentlyReceived[index]["shipping"] = {
            ...newRecentlyReceived[index]["shipping"],
            is_dispatched: true,
            dispatcher: "dhl",
            dispatch_reference:
              newRecentlyReceived[index]["shipping"].dhl_info
                ?.airway_bill_number,
          };
          newRecentlyReceived[index].status = "Out for Delivery";
        }
      }
      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case CHANGE_DRIVER:
      const { orderId, driverId } = payload;
      let allOrdersDriver = state.orders;
      let allPastOrdersDriver = state.pastOrders;
      let allRecentDriver = state.recentlyReceived;
      let indexInAllOrdersDriver = state.orders.findIndex(
        (ord) => ord.id === orderId
      );
      let indexInPastOrdersDriver = state.pastOrders
        ? state.pastOrders.findIndex((ord) => ord.id === orderId)
        : -1;
      let indexInRecentDriver = state.recentlyReceived.findIndex(
        (ord) => ord.id === orderId
      );

      if (indexInAllOrdersDriver >= 0) {
        allOrdersDriver[indexInAllOrdersDriver]["shipping"] = {
          ...allOrdersDriver[indexInAllOrdersDriver]["shipping"],
          driver: driverId,
        };
      }
      if (indexInPastOrdersDriver >= 0) {
        allPastOrdersDriver[indexInPastOrdersDriver]["shipping"] = {
          ...allPastOrdersDriver[indexInPastOrdersDriver]["shipping"],
          driver: driverId,
        };
      }
      if (indexInRecentDriver >= 0) {
        allRecentDriver[indexInRecentDriver]["shipping"] = {
          ...allRecentDriver[indexInRecentDriver]["shipping"],
          driver: driverId,
        };
      }
      return {
        ...state,
        orders: allOrdersDriver,
        pastOrders: allPastOrdersDriver,
        recentlyReceived: allRecentDriver,
      };

    case DISPATCH_ORDER:
      const {
        id,
        dispatcher,
        reference,
        trackingLink,
        estimatedDuration,
        QR,
        walletCharge,
        driver_id,
      } = payload;

      let allOrders = state.orders;
      let allPastOrders = state.pastOrders;
      let allRecent = state.recentlyReceived;
      let indexInAllOrders = state.orders.findIndex((ord) => ord.id == id);
      let indexInPastOrders = state.pastOrders
        ? state.pastOrders.findIndex((ord) => ord.id == id)
        : -1;
      let indexInRecent = state.recentlyReceived.findIndex(
        (ord) => ord.id == id
      );

      if (indexInAllOrders >= 0) {
        allOrders[indexInAllOrders]["shipping"] = {
          ...allOrders[indexInAllOrders]["shipping"],
          dispatch_reference: reference,
          is_dispatched: true,
          dispatcher: dispatcher,
          tracking_link: trackingLink,
          estimated_duration: estimatedDuration,
          qr_code: QR,
          driver: driver_id,
          wallet_charge: walletCharge,
        };
        allOrders[indexInAllOrders].status = "Out for Delivery";
      }
      if (indexInPastOrders >= 0) {
        allPastOrders[indexInPastOrders]["shipping"] = {
          ...allPastOrders[indexInPastOrders]["shipping"],
          dispatch_reference: reference,
          is_dispatched: true,
          dispatcher: dispatcher,
          tracking_link: trackingLink,
          estimated_duration: estimatedDuration,
          qr_code: QR,
          driver: driver_id,
          wallet_charge: walletCharge,
        };
        allPastOrders[indexInPastOrders].status = "Out for Delivery";
      }
      if (indexInRecent >= 0) {
        allRecent[indexInRecent]["shipping"] = {
          ...allRecent[indexInRecent]["shipping"],
          dispatch_reference: reference,
          is_dispatched: true,
          dispatcher: dispatcher,
          tracking_link: trackingLink,
          estimated_duration: estimatedDuration,
          qr_code: QR,
          driver: driver_id,
          wallet_charge: walletCharge,
        };
        allRecent[indexInRecent].status = "Out for Delivery";
      }
      return {
        ...state,
        orders: allOrders,
        pastOrders: allPastOrders,
        recentlyReceived: allRecent,
      };

    case FETCH_ORDERS:
      if (payload.specific) {
        let tempPromises = [
          ...payload.promises,
          ...(state.promises || []),
        ].sort((a, b) => (a.id > b.id ? -1 : 1));

        let incoming_current_or_future_orders = [];
        let incoming_past_orders = [];
        let incoming_past_orders_tracking_ids = [];
        if (payload.orders?.length) {
          incoming_current_or_future_orders = payload.orders.filter((o) =>
            !!o.expected_delivery_date
              ? o.expected_delivery_date >= moment().format("YYYY-MM-DD")
              : o.created.substring(0, 10) >= moment().format("YYYY-MM-DD")
          );
          incoming_past_orders = payload.orders.filter((o) =>
            !!o.expected_delivery_date
              ? o.expected_delivery_date < moment().format("YYYY-MM-DD")
              : o.created.substring(0, 10) < moment().format("YYYY-MM-DD")
          );
          incoming_past_orders_tracking_ids = incoming_past_orders.map(
            (o) => o.tracking_id
          );
        }
        let tempOrders = [
          ...incoming_current_or_future_orders,
          ...(state.orders || []),
        ]
          .sort((a, b) => (a.id > b.id ? -1 : 1))
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
          .filter(
            (p) => !incoming_past_orders_tracking_ids.includes(p.tracking_id)
          );
        let tempNewOrders = [
          ...payload.new_orders,
          ...(state.new_orders || []),
        ].sort((a, b) => (a.id > b.id ? -1 : 1));

        if (payload.new_orders.length === 1) {
          tempOrders = tempOrders.filter(
            (o) => o.id !== payload.new_orders[0].id
          );
        }
        let orderTrackingIDs = tempOrders.map((o) => o.tracking_id);
        tempPromises = tempPromises
          .filter((p) => !orderTrackingIDs.includes(p.tracking_id))
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        tempNewOrders = tempNewOrders
          .filter((p) => !orderTrackingIDs.includes(p.tracking_id))
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

        let tempPastOrders = state.pastOrders || [];
        if (incoming_past_orders.length) {
          incoming_past_orders.forEach((po) => {
            let indexInPastOrders = tempPastOrders.findIndex(
              (x) => x.id === po.id
            );
            if (indexInPastOrders >= 0) tempPastOrders[indexInPastOrders] = po;
            else tempPastOrders.push(po);
          });
          tempPastOrders = tempPastOrders
            .sort((a, b) => (a.id > b.id ? -1 : 1))
            .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        }
        return {
          ...state,
          orders: tempOrders,
          promises: tempPromises,
          new_orders: tempNewOrders,
          pastOrders: tempPastOrders,
          loading: false,
          bellHasNewOrders:
            tempPromises.length || tempNewOrders.length ? true : false,
        };
      } else
        return {
          ...state,
          orders: payload.orders,
          promises: payload.promises,
          new_orders: payload.new_orders,
          loading: false,
          bellHasNewOrders:
            payload.promises.length || payload.new_orders.length ? true : false,
        };
    case CLEAR_PAST_ORDERS:
      return { ...state, pastOrders: null };
    case FETCH_PAST_ORDERS:
      return {
        ...state,
        pastOrders: state.pastOrders
          ? [...state.pastOrders, ...payload.pastOrders]
          : payload.pastOrders,
        didFetchPastOrders: true,
      };
    case UPDATE_ORDER_NOTES:
      const orderID = payload.orderID;
      const notes = payload.notes;

      // try {
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      newRecentlyReceived = state.recentlyReceived;

      if (newOrders) {
        index = newOrders.findIndex((order) => order.id == orderID);
        if (index >= 0) {
          newOrders[index].notes = notes;
          newOrders[index].notes_attachment = payload.attachment;
        }
      }
      if (newPastOrders) {
        index = newPastOrders.findIndex((order) => order.id == orderID);
        if (index >= 0) {
          newPastOrders[index].notes = notes;
          newPastOrders[index].notes_attachment = payload.attachment;
        }
      }
      index = newRecentlyReceived.findIndex((order) => order.id == orderID);
      if (index >= 0) {
        newRecentlyReceived[index].notes = notes;
        newRecentlyReceived[index].notes_attachment = payload.attachment;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case UPDATE_CUSTOMER_LAT_LNG:
      // try {
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      newRecentlyReceived = state.recentlyReceived;
      let indxOrders;
      let indxPastOrders;

      if (newOrders) {
        indxOrders = newOrders.findIndex(
          (order) => order.id == payload.orderID
        );
        if (indxOrders >= 0) {
          newOrders[indxOrders].customer_lat = payload.lat;
          newOrders[indxOrders].customer_lng = payload.lng;
        }
      }
      if (newPastOrders) {
        indxPastOrders = newPastOrders.findIndex(
          (order) => order.id == payload.orderID
        );
        if (indxPastOrders >= 0) {
          newPastOrders[indxPastOrders].customer_lat = payload.lat;
          newPastOrders[indxPastOrders].customer_lng = payload.lng;
        }
      }
      const indxRecent = newRecentlyReceived.findIndex(
        (order) => order.id == payload.orderID
      );
      if (indxRecent >= 0) {
        newRecentlyReceived[indxRecent].customer_lat = payload.lat;
        newRecentlyReceived[indxRecent].customer_lng = payload.lng;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };
    // } catch {
    //   return { ...state };
    // }
    case UNDISPATCH_ORDER:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      newRecentlyReceived = state.recentlyReceived;

      if (newOrders) {
        index = newOrders.findIndex((order) => order.id == payload);
        if (index >= 0) {
          newOrders[index].shipping = {
            ...newOrders[index].shipping,
            dispatch_reference: "",
            is_dispatched: false,
            dispatcher: null,
            tracking_link: null,
            estimated_duration: null,
            qr_code: null,
            driver: null,
            wallet_charge: 0,
          };
        }
      }
      if (newPastOrders) {
        index = newPastOrders.findIndex((order) => order.id == payload);
        if (index >= 0) {
          newPastOrders[index].shipping = {
            ...newPastOrders[index].shipping,
            dispatch_reference: "",
            is_dispatched: false,
            dispatcher: null,
            tracking_link: null,
            estimated_duration: null,
            qr_code: null,
            driver: null,
          };
        }
      }

      index = newRecentlyReceived.findIndex((order) => order.id == payload);
      if (index >= 0) {
        newRecentlyReceived[index].shipping = {
          ...newRecentlyReceived[index].shipping,
          dispatch_reference: "",
          is_dispatched: false,
          dispatcher: null,
          tracking_link: null,
          estimated_duration: null,
          qr_code: null,
          driver: null,
        };
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case UPDATE_ORDERS_AFTER_BULK:
      const { otype, keys, status, paymentStatus, newFetchedOrders } = payload;

      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      newRecentlyReceived = state.recentlyReceived;
      newPromises = state.promises;
      let newOrdersPos = state.new_orders;
      let bellHasNewOrdersNew = state.bellHasNewOrders;
      if (otype == "orders") {
        const isItInOrders = newOrders.some((order) => keys.includes(order.id));
        const isItInPastOrders = newPastOrders
          ? newPastOrders.some((order) => keys.includes(order.id))
          : false;
        const isItInRecentlyReceived = newRecentlyReceived.some((order) =>
          keys.includes(order.id)
        );
        if (isItInOrders) {
          newOrders = newOrders.map((order) => {
            if (keys.includes(order.id)) {
              let newOrder = order;
              if (status) newOrder.status = status;
              if (paymentStatus)
                newOrder.payment_complete =
                  paymentStatus == "paid" ? true : false;
              return newOrder;
            } else return order;
          });
        }
        if (isItInPastOrders) {
          newPastOrders = newPastOrders.map((order) => {
            if (keys.includes(order.id)) {
              let newOrder = order;
              if (status) newOrder.status = status;
              if (paymentStatus)
                newOrder.payment_complete =
                  paymentStatus == "paid" ? true : false;
              return newOrder;
            } else return order;
          });
        }
        if (isItInRecentlyReceived) {
          newRecentlyReceived = newRecentlyReceived.map((order) => {
            if (keys.includes(order.id)) {
              let newOrder = order;
              if (status) newOrder.status = status;
              if (paymentStatus)
                newOrder.payment_complete =
                  paymentStatus == "paid" ? true : false;
              return newOrder;
            } else return order;
          });
        }
      } else if (otype == "promises") {
        newPromises = newPromises.filter(
          (promise) => !keys.includes(promise.id)
        );
        newOrdersPos = newOrdersPos.filter(
          (promise) => !keys.includes(promise.id)
        );

        if (newPromises.length + newPromises.length == 0) {
          bellHasNewOrdersNew = false;
        }
      }
      const ordersToGoInOrdersArray = newFetchedOrders.filter(
        (order) =>
          moment(order.expected_delivery_date, "YYYY-MM-DD") > moment() ||
          payload.expected_delivery_date == moment().format("YYYY-MM-DD")
      );
      const ordersToGoInPastArray = newFetchedOrders.filter(
        (order) => moment(order.expected_delivery_date, "YYYY-MM-DD") < moment()
      );
      if (newFetchedOrders) {
        return {
          ...state,
          orders: [...ordersToGoInOrdersArray, ...newOrders],
          promises: newPromises,
          pastOrders: newPastOrders
            ? [...ordersToGoInPastArray, ...newPastOrders]
            : null,
          new_orders: newOrdersPos,
          bellHasNewOrders: bellHasNewOrdersNew,
          recentlyReceived: [...newFetchedOrders, ...state.recentlyReceived],
        };
      } else {
        return {
          ...state,
          orders: newOrders,
          pastOrders: newPastOrders,
          recentlyReceived: newRecentlyReceived,
          promises: newPromises,
          bellHasNewOrders: bellHasNewOrdersNew,
        };
      }

    case UPDATE_ORDER_STATUS_PAYMENT:
      if (payload.type == "Order") {
        //Try to find the order (will return an order if status is changing from anything but New)

        // Searching for the order in three arrays
        const existingOrderInOrders = state.orders.find(
          (order) => order.id == payload.id
        );
        let existingOrderInPastOrders = null;
        if (state.pastOrders)
          existingOrderInPastOrders = state.pastOrders.find(
            (order) => order.id == payload.id
          );
        let existingOrderInRecentlyReceived = null;
        if (state.recentlyReceived.length > 0)
          existingOrderInRecentlyReceived = state.recentlyReceived.find(
            (order) => order.id == payload.id
          );

        let recentlyReceived = state.recentlyReceived;
        let pastOrders = state.pastOrders;
        newOrders = state.orders;
        // if it's not found anywhere, it's new

        if (
          !existingOrderInOrders &&
          !existingOrderInPastOrders &&
          !existingOrderInRecentlyReceived
        ) {
          //An order was received
          //Although this triggers socket to update,
          // I'm adding to orders and removing from promises to make the process faster

          //Add the order to orders array to where it belongs
          if (
            moment(payload.expected_delivery_date, "YYYY-MM-DD") > moment() ||
            payload.expected_delivery_date == moment().format("YYYY-MM-DD")
          ) {
            newOrders = [payload, ...state.orders];
          } else if (
            moment(payload.expected_delivery_date, "YYYY-MM-DD") < moment()
          ) {
            pastOrders = pastOrders ? [payload, ...state.pastOrders] : null;
          }
          recentlyReceived = [payload, ...state.recentlyReceived];
          //Remove promise from 'New' section
          const newPromises = state.promises.filter(
            (promise) => promise.tracking_id != payload.tracking_id
          );
          const newOrdersPos = state.new_orders.filter(
            (promise) => promise.tracking_id != payload.tracking_id
          );
          return {
            ...state,
            orders: newOrders,
            new_orders: newOrdersPos,
            promises: newPromises,
            bellHasNewOrders: newPromises.length > 0,
            recentlyReceived: recentlyReceived,
            pastOrders: pastOrders,
          };
        }

        // It's an existing product somewhere in the three arrays
        if (existingOrderInOrders) {
          const indexOfOldOrder = newOrders.findIndex(
            (order) => order.id == payload.id
          );
          newOrders[indexOfOldOrder] = payload;
        }
        if (existingOrderInRecentlyReceived) {
          const indexOfOldOrder = recentlyReceived.findIndex(
            (order) => order.id == payload.id
          );
          recentlyReceived[indexOfOldOrder] = payload;
        }
        if (existingOrderInPastOrders) {
          const indexOfOldOrder = pastOrders.findIndex(
            (order) => order.id == payload.id
          );
          pastOrders[indexOfOldOrder] = payload;
        }
        let newUpdatedPromise = state.promises;
        if (
          recentlyReceived.length == state.recentlyReceived.length &&
          !existingOrderInRecentlyReceived &&
          !existingOrderInOrders &&
          !existingOrderInPastOrders
        ) {
          recentlyReceived = [payload, ...recentlyReceived];
          newUpdatedPromise = state.promises.filter(
            (promise) => promise.tracking_id != payload.tracking_id
          );
        }
        return {
          ...state,
          orders: newOrders,
          pastOrders: pastOrders,
          recentlyReceived: recentlyReceived,
          promises: newUpdatedPromise,
        };
      } else {
        //It will never go here but just in case
        const existingPromise = state.promises.find(
          (promise) => promise.id == payload.id
        );
        const indexOfOldPromise = state.promises.indexOf(existingPromise);
        const newPromises = state.promises;
        newPromises[indexOfOldPromise] = payload;
        return { ...state, orders: newPromises };
      }

    case EDIT_EXPECTED_DELIVERY:
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;

      let existingOrderInOrders = null;
      if (state.orders) {
        existingOrderInOrders = state.orders.find(
          (order) => order.id == payload.id
        );
      }
      let existingOrderInPastOrders = null;
      if (state.pastOrders)
        existingOrderInPastOrders = state.pastOrders.find(
          (order) => order.id == payload.id
        );
      let existingOrderInRecentlyReceived = null;
      if (state.recentlyReceived.length > 0)
        existingOrderInRecentlyReceived = state.recentlyReceived.find(
          (order) => order.id == payload.id
        );

      if (existingOrderInOrders) {
        const indexOfOldOrder = newOrders.findIndex(
          (order) => order.id == payload.id
        );
        // If the new date is in the past
        if (payload.date < moment().format("YYYY-MM-DD")) {
          if (newPastOrders) {
            // Copy the order to past orders
            newPastOrders = [newOrders[indexOfOldOrder], ...newPastOrders];
            existingOrderInPastOrders = true;
          }
          // then remove it from orders
          newOrders = newOrders.filter((ord) => ord.id != payload.id);
        } else {
          if (
            newOrders[indexOfOldOrder].expected_delivery_date != payload.date
          ) {
            newOrders[indexOfOldOrder].date_edited_by = payload.user;
          }
          if (
            newOrders[indexOfOldOrder].expected_delivery_slot_start !=
              payload.start ||
            newOrders[indexOfOldOrder].expected_delivery_slot_end != payload.end
          ) {
            newOrders[indexOfOldOrder].time_edited_by = payload.user;
          }

          newOrders[indexOfOldOrder].expected_delivery_date = payload.date;
          newOrders[indexOfOldOrder].expected_delivery_slot_start =
            payload.start;
          newOrders[indexOfOldOrder].expected_delivery_slot_end = payload.end;
        }
      }
      if (existingOrderInRecentlyReceived) {
        const indexOfOldOrder = newRecentlyReceived.findIndex(
          (order) => order.id == payload.id
        );
        if (
          newRecentlyReceived[indexOfOldOrder].expected_delivery_date !=
          payload.date
        ) {
          newRecentlyReceived[indexOfOldOrder].date_edited_by = payload.user;
        }
        if (
          newRecentlyReceived[indexOfOldOrder].expected_delivery_slot_start !=
            payload.start ||
          newRecentlyReceived[indexOfOldOrder].expected_delivery_slot_end !=
            payload.end
        ) {
          newRecentlyReceived[indexOfOldOrder].time_edited_by = payload.user;
        }
        newRecentlyReceived[indexOfOldOrder].expected_delivery_date =
          payload.date;
        newRecentlyReceived[indexOfOldOrder].expected_delivery_slot_start =
          payload.start;
        newRecentlyReceived[indexOfOldOrder].expected_delivery_slot_end =
          payload.end;
      }
      if (existingOrderInPastOrders) {
        const indexOfOldOrder = newPastOrders.findIndex(
          (order) => order.id == payload.id
        );

        if (
          newPastOrders[indexOfOldOrder].expected_delivery_date != payload.date
        ) {
          newPastOrders[indexOfOldOrder].date_edited_by = payload.user;
        }
        if (
          newPastOrders[indexOfOldOrder].expected_delivery_slot_start !=
            payload.start ||
          newPastOrders[indexOfOldOrder].expected_delivery_slot_end !=
            payload.end
        ) {
          newPastOrders[indexOfOldOrder].time_edited_by = payload.user;
        }

        newPastOrders[indexOfOldOrder].expected_delivery_date = payload.date;
        newPastOrders[indexOfOldOrder].expected_delivery_slot_start =
          payload.start;
        newPastOrders[indexOfOldOrder].expected_delivery_slot_end = payload.end;

        // If the new date is in the current or future
        if (
          payload.date >= moment().format("YYYY-MM-DD") &&
          !existingOrderInOrders
        ) {
          // Copy the order to the orders array
          if (newOrders) {
            newOrders = [newPastOrders[indexOfOldOrder], ...newOrders];
          } else newOrders = [newPastOrders[indexOfOldOrder]];
          // then remove it from past orders
          newPastOrders = newPastOrders.filter((ord) => ord.id != payload.id);
        }
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case EDIT_PAYMENT_TYPE:
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;

      let existingOrderInOrders2 = null;
      if (newOrders) {
        existingOrderInOrders2 = newOrders.find(
          (order) => order.id == payload.id
        );
      }
      let existingOrderInPastOrders2 = null;
      if (newPastOrders)
        existingOrderInPastOrders2 = newPastOrders.find(
          (order) => order.id == payload.id
        );
      let existingOrderInRecentlyReceived2 = null;
      if (newRecentlyReceived.length > 0)
        existingOrderInRecentlyReceived2 = newRecentlyReceived.find(
          (order) => order.id == payload.id
        );

      if (existingOrderInOrders2) {
        existingOrderInOrders2.payment_method = payload.paymentType;
        existingOrderInOrders2.payment_ctype = payload.paymentCType;
        existingOrderInOrders2.payment_edited_by = payload.user;
      }
      if (existingOrderInRecentlyReceived2) {
        existingOrderInRecentlyReceived2.payment_method = payload.paymentType;
        existingOrderInRecentlyReceived2.payment_ctype = payload.paymentCType;
        existingOrderInRecentlyReceived2.payment_edited_by = payload.user;
      }
      if (existingOrderInPastOrders2) {
        existingOrderInPastOrders2.payment_method = payload.paymentType;
        existingOrderInPastOrders2.payment_ctype = payload.paymentCType;
        existingOrderInPastOrders2.payment_edited_by = payload.user;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case EDIT_SPECIAL_REMARKS:
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;

      let existingOrderInOrders5 = null;
      if (newOrders) {
        existingOrderInOrders5 = newOrders.find(
          (order) => order.id == payload.id
        );
      }
      let existingOrderInPastOrders5 = null;
      if (newPastOrders)
        existingOrderInPastOrders5 = newPastOrders.find(
          (order) => order.id == payload.id
        );
      let existingOrderInRecentlyReceived5 = null;
      if (newRecentlyReceived.length > 0)
        existingOrderInRecentlyReceived5 = newRecentlyReceived.find(
          (order) => order.id == payload.id
        );

      if (existingOrderInOrders5) {
        existingOrderInOrders5.special_remarks = payload.specialRemarks;
        existingOrderInOrders5.remarks_edited_by = payload.user;
      }
      if (existingOrderInRecentlyReceived5) {
        existingOrderInRecentlyReceived5.special_remarks =
          payload.specialRemarks;
        existingOrderInRecentlyReceived5.remarks_edited_by = payload.user;
      }
      if (existingOrderInPastOrders5) {
        existingOrderInPastOrders5.special_remarks = payload.specialRemarks;
        existingOrderInPastOrders5.remarks_edited_by = payload.user;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case STORE_SEARCHED_ORDERS:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;

      payload.orders.forEach((order) => {
        if (
          moment(
            order.expected_delivery_date || order.created,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
        ) {
          if (newOrders) {
            let indexInOrders = newOrders.findIndex((o) => o.id == order.id);

            // If the order does not exist, add it
            if (indexInOrders == -1) {
              newOrders = [order, ...newOrders];
              newOrders = newOrders.sort((a, b) => b.id - a.id);
            }
          }
        } else if (
          moment(
            order.expected_delivery_date || order.created,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
        ) {
          if (newPastOrders) {
            let indexInPastOrders = newPastOrders.findIndex(
              (o) => o.id == order.id
            );

            // If the order does not exist, add it
            if (indexInPastOrders == -1) {
              newPastOrders = [order, ...newPastOrders];
              newPastOrders = newPastOrders.sort((a, b) => b.id - a.id);
            }
          } else {
            newPastOrders = [order];
          }
        }
      });

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
      };

    case SOCKET_MASHKOR_UPDATE_ORDER_STATUS:
      const order_id = payload.orderID;

      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;

      if (newOrders) {
        index = newOrders.findIndex((o) => o.id == order_id);
        if (index > -1) {
          newOrders[index].status = payload.status;
        }
      }

      if (newRecentlyReceived) {
        index = newRecentlyReceived.findIndex((o) => o.id == order_id);
        if (index > -1) {
          newRecentlyReceived[index].status = payload.status;
        }
      }

      if (newPastOrders) {
        index = newPastOrders.findIndex((o) => o.id == order_id);
        if (index > -1) {
          newPastOrders[index].status = payload.status;
        }
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case "REFUND_LOADING":
      return { ...state, refundLoading: !state.refundLoading };

    case "SET_DID_INTERACT":
      return { ...state, didInteract: true };
    case "RESET_DID_INTERACT":
      return { ...state, didInteract: false };
    case EDIT_ADDRESS:
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;
      let existingOrderInOrders4 = null;
      if (newOrders) {
        existingOrderInOrders4 = newOrders.find(
          (order) => order.id == payload.id
        );
      }
      let existingOrderInPastOrders4 = null;
      if (newPastOrders)
        existingOrderInPastOrders4 = newPastOrders.find(
          (order) => order.id == payload.id
        );
      let existingOrderInRecentlyReceived4 = null;
      if (newRecentlyReceived.length > 0)
        existingOrderInRecentlyReceived4 = newRecentlyReceived.find(
          (order) => order.id == payload.id
        );

      if (existingOrderInOrders4) {
        existingOrderInOrders4.area = payload.area;
        existingOrderInOrders4.address = payload.address;
      }
      if (existingOrderInRecentlyReceived4) {
        existingOrderInRecentlyReceived4.area = payload.area;
        existingOrderInRecentlyReceived4.address = payload.address;
      }
      if (existingOrderInPastOrders4) {
        existingOrderInPastOrders4.area = payload.area;
        existingOrderInPastOrders4.address = payload.address;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case FETCH_TOPUPS:
      return { ...state, topups: payload };
    case TRANSFER_ORDERS: {
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;
      newPromises = state.promises;
      const userHasPermission = payload.hasPermission;
      payload.orders.forEach((order) => {
        let existingOrderInOrders4 = -1;

        if (newOrders) {
          existingOrderInOrders4 = newOrders.findIndex((o) => o.id == order.id);
        }
        let existingOrderInPastOrders4 = -1;
        if (newPastOrders)
          existingOrderInPastOrders4 = newPastOrders.findIndex(
            (o) => o.id == order.id
          );
        let existingOrderInRecentlyReceived4 = -1;
        if (newRecentlyReceived.length > 0)
          existingOrderInRecentlyReceived4 = newRecentlyReceived.findIndex(
            (o) => o.id == order.id
          );

        let existingOrderInPromises4 = -1;

        if (newPromises) {
          existingOrderInPromises4 = newPromises.findIndex(
            (promise) => promise.id == order.id
          );
        }

        if (existingOrderInOrders4 != -1) {
          if (userHasPermission) {
            newOrders[existingOrderInOrders4].for_branch_id =
              order.for_branch_id;
            newOrders[existingOrderInOrders4].for_branch = order.for_branch;
            newOrders[existingOrderInOrders4].for_branch_ar =
              order.for_branch_ar;
          } else {
            newOrders.splice(existingOrderInOrders4, 1);
          }
        }
        if (existingOrderInRecentlyReceived4 != -1) {
          if (userHasPermission) {
            newRecentlyReceived[
              existingOrderInRecentlyReceived4
            ].for_branch_id = order.for_branch_id;
            newRecentlyReceived[existingOrderInRecentlyReceived4].for_branch =
              order.for_branch;
            newRecentlyReceived[
              existingOrderInRecentlyReceived4
            ].for_branch_ar = order.for_branch_ar;
          } else {
            newRecentlyReceived.splice(existingOrderInRecentlyReceived4, 1);
          }
        }
        if (existingOrderInPastOrders4 != -1) {
          if (userHasPermission) {
            newPastOrders[existingOrderInPastOrders4].for_branch_id =
              order.for_branch_id;
            newPastOrders[existingOrderInPastOrders4].for_branch =
              order.for_branch;
            newPastOrders[existingOrderInPastOrders4].for_branch_ar =
              order.for_branch_ar;
          } else {
            newPastOrders.splice(existingOrderInPastOrders4, 1);
          }
        }
        if (existingOrderInPromises4 != -1) {
          if (userHasPermission) {
            newPromises[existingOrderInPromises4].for_branch_id =
              order.for_branch_id;
            newPromises[existingOrderInPromises4].for_branch = order.for_branch;
            newPromises[existingOrderInPromises4].for_branch_ar =
              order.for_branch_ar;
          } else {
            newPromises.splice(existingOrderInPromises4, 1);
          }
        }
      });

      return {
        ...state,
        promises: newPromises,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };
    }

    case EDIT_TABLE_NUMBER:
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;

      let existingOrderInOrders3 = null;
      if (newOrders) {
        existingOrderInOrders3 = newOrders.find(
          (order) => order.id == payload.id
        );
      }
      let existingOrderInPastOrders3 = null;
      if (newPastOrders)
        existingOrderInPastOrders3 = newPastOrders.find(
          (order) => order.id == payload.id
        );
      let existingOrderInRecentlyReceived3 = null;
      if (newRecentlyReceived.length > 0)
        existingOrderInRecentlyReceived3 = newRecentlyReceived.find(
          (order) => order.id == payload.id
        );

      if (existingOrderInOrders3) {
        existingOrderInOrders3.address.parking_number = payload.tableNumber;
        existingOrderInOrders3.table_edited_by = payload.user;
      }
      if (existingOrderInRecentlyReceived3) {
        existingOrderInRecentlyReceived3.address.parking_number =
          payload.tableNumber;
        existingOrderInRecentlyReceived3.table_edited_by = payload.user;
      }
      if (existingOrderInPastOrders3) {
        existingOrderInPastOrders3.address.parking_number = payload.tableNumber;
        existingOrderInPastOrders3.table_edited_by = payload.user;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case "UPDATE_ORDER":
      newOrders = state.orders;
      newRecentlyReceived = state.recentlyReceived;
      newPastOrders = state.pastOrders;
      if (newOrders) {
        index = newOrders.findIndex((order) => order.id == payload.id);
        if (index >= 0) newOrders[index] = payload;
      }
      if (newPastOrders) {
        index = newPastOrders.findIndex((order) => order.id == payload.id);
        if (index >= 0) newPastOrders[index] = payload;
      }
      if (newRecentlyReceived.length > 0) {
        index = newRecentlyReceived.findIndex(
          (order) => order.id == payload.id
        );
        newRecentlyReceived[index] = payload;
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        recentlyReceived: newRecentlyReceived,
      };

    case "FETCH_PICKUP_ARRIVAL_REQUESTS":
      return { ...state, pickupArrivalRequests: payload };
    case "NEW_PICKUP_ARRIVAL_REQUEST":
      return {
        ...state,
        pickupArrivalRequests: [payload, ...state.pickupArrivalRequests],
      };
    case "SET_OPEN_ORDER_MODAL":
      return {
        ...state,
        openOrderModal: payload,
      };
    case CANCEL_TOPUP:
      const newTopups = state.topups;
      const newTopup = newTopups.find((topup) => topup.key == payload);
      newTopup.refunded = true;
      return { ...state, topups: newTopups };
    case "SET_AUDIO":
      return { ...state, audio: payload };
    case "PAUSE_AUDIO":
      return { ...state, audioPaused: !state.audioPaused };

    case "TOGGLE_HELP":
      localStorage.setItem(
        "helpOpen",
        !state.isHelpOpen == true ? "true" : "false"
      );
      return { ...state, isHelpOpen: !state.isHelpOpen };

    case "SET_NEW_ORDER_BELL_AGGREGATE":
      return { ...state, bellHasNewAggregate: payload };

    default:
      return { ...state };
  }
};

export default reducer;
