import React, {
  Component,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import GoogleMap from "./GoogleMap";
import { connect } from "react-redux";
import { Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { withNamespaces } from "react-i18next";
// import { MapContainer, TileLayer, Circle, useMap } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import { ReactComponent as Marker } from "./assets/gps.svg";
import DraggableMap from "./DraggableMap";

function getCoordinates(customer_lat, customer_lng, base_location) {
  if (
    customer_lat &&
    customer_lng &&
    !Number.isNaN(customer_lat) &&
    !Number.isNaN(customer_lng) &&
    customer_lat != "None" &&
    customer_lng != "None"
  )
    return { lat: customer_lat, lng: customer_lng };
  if (base_location)
    return {
      lat: base_location?.split(",")[0],
      lng: base_location?.split(",")[1],
    };

  return { lat: "", lng: "" };
}
function CustomerInfo({ selectedOrder, t, settings, language, setNewLatLng }) {
  const [position, setPosition] = useState({
    lat: getCoordinates(
      selectedOrder.customer_lat,
      selectedOrder.customer_lng,
      settings.base_location
    ).lat,
    lng: getCoordinates(
      selectedOrder.customer_lat,
      selectedOrder.customer_lng,
      settings.base_location
    ).lng,
  });

  const order = selectedOrder;
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = order.address.home_picture;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    message.success(t("Image link copied successfully"));
  };

  if (order) {
    const lat = position.lat;
    const lng = position.lng;

    if (
      lat &&
      lng &&
      !Number.isNaN(lat) &&
      !Number.isNaN(lng) &&
      lat != "None" &&
      lng != "None"
    )
      return (
        <>
          {order.address.home_picture && (
            <>
              <div>
                <p>{t("Customer's home picture")}</p>
                <Button
                  type="primarys"
                  icon={<CopyOutlined />}
                  style={{ marginBottom: 10 }}
                  onClick={() => copyToClipboard()}
                >
                  {t("Copy image link")}
                </Button>
              </div>
              <img
                src={order.address.home_picture}
                style={{ width: "100%", height: 300, marginBottom: 25 }}
              />
            </>
          )}
          {lat && lng ? (
            <div>
              {order.customer_lat &&
                order.customer_lng &&
                !Number.isNaN(order.customer_lat) &&
                !Number.isNaN(order.customer_lng) &&
                order.customer_lat != "None" &&
                order.customer_lng != "None" && (
                  <div>
                    <p>{t("Customer's location")}</p>
                    <div className="mb-3">
                      <Button
                        type="default"
                        onClick={() => {
                          const textField = document.createElement("textarea");
                          textField.innerText = `https://maps.google.com/maps?daddr=${lat},${lng}`;
                          document.body.appendChild(textField);
                          textField.select();
                          document.execCommand("copy");
                          textField.remove();
                          message.success(t("Copied successfully"));
                        }}
                      >
                        {t("Copy link")}
                      </Button>

                      <Button
                        type="default"
                        className="mx-2"
                        onClick={() =>
                          window.open(
                            "https://wa.me/?text=".concat(
                              `https://maps.google.com/maps?daddr=${lat},${lng}`
                            ),
                            "_blank"
                          )
                        }
                      >
                        {t("WhatsApp")}
                      </Button>
                    </div>
                  </div>
                )}
              {/* <GoogleMap lat={lat} lng={lng} /> */}
              <p>{t("Drag the map to edit the location")}</p>
              <DraggableMap
                lat={lat}
                lng={lng}
                setLatLng={(lat, lng) => {
                  setPosition({ lat, lng });
                  setNewLatLng({ lat, lng });
                }}
              />
            </div>
          ) : null}
        </>
      );
    else return <></>;
  } else return <></>;
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    settings: state.auth.settings,
    language: state.config.language,
  };
};

export default connect(mapStateToProps)(withNamespaces()(CustomerInfo));
