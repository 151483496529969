import React, { Component } from "react";
import moment from "moment";
import instance from "../../store/actions/instance";
import { Button, Dropdown, Menu, message } from "antd";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import EditExpectedModal from "./EditExpectedModal";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CarOutlined,
  DownOutlined,
  EditOutlined,
  GiftOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import EditPaymentType from "./EditPaymentType";
import NewAddressModal from "../POS/LocationModal/NewAddressModal";
import { editAddress } from "../../store/actions/orders";
import EditBranchModal from "./EditBranchModal";
import EditCustomerModal from "./EditCustomerModal";
import EditTableNumberModal from "./EditTableNumberModal";
import { createSurvey } from "../../store/actions/feedbacks";
import InvoiceTable from "./InvoiceTable";
import QRCode from "qrcode.react";

class Invoice extends Component {
  state = {
    orderDetails: null,
    loading: false,
    editExpectedOpen: false,
    editBranchOpen: false,
    editPaymentType: false,
    editTableNumber: false,
    newAddressModalVisible: false,
    showDiscountDetails: false,
    QRPayLinkShow: false,
    QRPayLink: "",
    lastEditedBy: {},
  };

  fetchOrderDetails = async (type, id) => {
    this.setState({ loading: true });
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_order_details/${type}/${id}/`);

      this.setState({ orderDetails: res.data, loading: false });
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };
  updateOrderDetails = (items, NewStatus) => {
    let newOrderDetails = { ...this.state.orderDetails };
    items.forEach((i) => {
      const item = newOrderDetails.items.find((item) => item.id === i);
      if (item) {
        item.status = NewStatus;
      }
    });
    this.setState({ orderDetails: newOrderDetails });
  };
  componentDidMount = () => {
    const type =
      this.props.selectedOrder.promise &&
      this.props.selectedOrder.obj.type !== "Order"
        ? "promise"
        : "order";

    this.fetchOrderDetails(type, this.props.selectedOrder.key);
    if (type === "order") this.fetchLastEditedBy(this.props.selectedOrder.key);
  };

  fetchLastEditedBy = async () => {
    try {
      const res = await instance.post("/get_order_last_edited_by/", {
        id: this.props.selectedOrder.key,
      });
      if (res.data.success) {
        this.setState({ lastEditedBy: res.data });
      }
    } catch (e) {
      console.log("Couldn't fetch last edited by");
    }
  };
  getInvoiceToken = async () => {
    this.setState({ loading: true });
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_printable_token/`);
      if (res.data.success) {
        return res.data.token;
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };

  sendSMS = async () => {
    this.setState({ smsLoading: true });
    const { t } = this.props;
    try {
      const res = await instance.post(`/send_sms_invoice/`, {
        id: this.props.selectedOrder.key,
      });
      if (res.data.success) {
        message.success(t("Sent SMS successfully"));
      } else {
        message.error(res.data.message || t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
    this.setState({ smsLoading: false });
  };

  sendSurveySMS = async (stracking, phone_number, name) => {
    const { t } = this.props;
    try {
      const res = await instance.post(`/send_sms_survey/${stracking}/`, {
        phone_number: phone_number,
        name: name,
      });
      if (res.data.success) {
        message.success(t("Sent SMS successfully"));
      } else {
        message.error(res.data.message || t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  generateInvoicePrintUrl = async (type, language) => {
    const { selectedOrder, settings } = this.props;
    const token = await this.getInvoiceToken();
    return `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/${type}${token}/?lang=${language}`;
  };

  async createSurvey(key) {
    const { t, selectedOrder } = this.props;
    try {
      const res = await instance.post(`/create_survey/`, {
        reference: selectedOrder.obj.tracking_id,
        branch: selectedOrder.obj.for_branch_id,
        name: selectedOrder.obj.customer_name,
        phone_number: selectedOrder.obj.phone,
      });
      if (res.data.success == true) {
        this.props.createSurvey(res.data.data, false);
        message.success(t("Survey created successfully"));
        if (key == "link") {
          const textField = document.createElement("textarea");
          textField.innerText = `https://${this.props.domain}/customer_survey/${res.data.data.tracking_id}/`;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand("copy");
          textField.remove();
          message.success(t("Survey link copied successfully"));
        } else if (key == "qr") {
          window.open(
            `https://${this.props.domain}/survey/qr/${res.data.data.tracking_id}/`,
            "_blank"
          );
        } else if (key == "sms") {
          this.sendSurveySMS(
            res.data.data.tracking_id,
            selectedOrder.obj.phone_number,
            selectedOrder.obj.name
          );
        } else if (key == "whatsapp") {
          window.open(
            `https://wa.me/${selectedOrder.obj.phone_number.replace(
              "+",
              ""
            )}?text=Share your experience with us, it will take a few seconds and would really help us improve ${`https://${this.props.domain}/customer_survey/${res.data.data.tracking_id}/`}`,
            "_blank"
          );
        }
      } else message.error(t(res.data.message) || t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  }

  renderPrintButtonMenu = (type) => {
    const { t } = this.props;
    return (
      <Menu>
        <Menu.Item
          onClick={async () => {
            window.open(
              await this.generateInvoicePrintUrl(type, "en"),
              "_blank"
            );
          }}
        >
          {t("English")}
        </Menu.Item>
        <Menu.Item
          onClick={async () => {
            window.open(
              await this.generateInvoicePrintUrl(type, "ar"),
              "_blank"
            );
          }}
        >
          {t("Arabic")}
        </Menu.Item>
      </Menu>
    );
  };

  renderPrintButtons = () => {
    const { t, config } = this.props;
    return (
      <div className="mb-3">
        <p className="mb-1">{t("Print")}</p>
        <Dropdown
          overlay={() => this.renderPrintButtonMenu("")}
          className={config.direction == "ltr" ? "mr-2 mb-2" : "ml-2 mb-2"}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            icon={<PrinterOutlined className="mr-2" style={{ fontSize: 19 }} />}
          >
            A4 <DownOutlined />
          </Button>
        </Dropdown>

        <Dropdown
          overlay={() => this.renderPrintButtonMenu("pdf/")}
          className={config.direction == "ltr" ? "mr-2 mb-2" : "ml-2 mb-2"}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            icon={<PrinterOutlined className="mr-2" style={{ fontSize: 19 }} />}
          >
            {t("Thermal")} <DownOutlined size="large" />
          </Button>
        </Dropdown>
        <Dropdown
          overlay={() => this.renderPrintButtonMenu("preparation/base64/")}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            icon={<PrinterOutlined className="ml-2" style={{ fontSize: 19 }} />}
          >
            {t("Preparation")} <DownOutlined size="large" />
          </Button>
        </Dropdown>
      </div>
    );
  };

  downloadFile = (e, file_url, file_name) => {
    e.preventDefault();

    fetch(file_url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  getAddressBeingEdited = () => {
    return {
      country: this.props.order.area?.country_name || "",
      ar_country: this.props.order.area?.country_ar_name || "",
      area: this.props.order.area?.name || "",
      ar_area: this.props.order.area?.ar_name || "",
      area_id: this.props.order.area?.id || null,
      country_id: this.props.order.area?.country || null,
      addressName: "",
      block:
        this.props.order.address?.block || this.props.order.address?.zone || "",
      street: this.props.order.address?.street || "",
      avenue: this.props.order.address?.avenue || "",
      building: this.props.order.address?.building || "",
      floor: this.props.order.address?.floor || "",
      apartment: this.props.order.address?.apartment || "",
      additional: this.props.order.address?.additional || "",
    };
  };
  editAddress = async (address) => {
    const { t, selectedOrder } = this.props;

    try {
      const res = await instance.post("/edit_address/", {
        id: selectedOrder.key,
        address,
      });
      this.props.editAddress({ id: selectedOrder.key, ...res.data });
      this.fetchLastEditedBy();
      message.success(t("Edited successfully"));
    } catch (err) {
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else message.error(t("SomethingWentWrong"));
    }
  };

  render() {
    const {
      order,
      selectedOrder,
      t,
      config,
      settings,
      permissions,
      productInfo,
    } = this.props;
    const {
      orderDetails,
      editExpectedOpen,
      editPaymentType,
      editTableNumber,
      newAddressModalVisible,
      editBranchOpen,
    } = this.state;
    const copyToClipboard = (lang, hidePrice = false) => {
      const textField = document.createElement("textarea");
      if (lang == "ar") {
        if (hidePrice) {
          textField.innerText = `https://${this.props.domain}/invoice/${order.tracking_id}/?lang=ar&hidePrice=true`;
        } else {
          textField.innerText = `https://${this.props.domain}/invoice/${order.tracking_id}/?lang=ar`;
        }
      } else {
        if (hidePrice) {
          textField.innerText = `https://${this.props.domain}/invoice/${order.tracking_id}/?hidePrice=true`;
        } else {
          textField.innerText = `https://${this.props.domain}/invoice/${order.tracking_id}/`;
        }
      }
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      message.success(t("Invoice link copied successfully"));
    };

    const generateQRPayLink = (lang) => {
      this.setState({ QRPayLinkShow: true });
      const link =
        lang === "ar"
          ? `https://${this.props.domain}/invoice/${this.props.order.tracking_id}/?lang=ar`
          : `https://${this.props.domain}/invoice/${this.props.order.tracking_id}/`;
      this.setState({ QRPayLink: link });
      // message.success(t("Invoice QR Available successfully"));
    };

    const areaText = order.area.country_name == "Qatar" ? "District" : "Area";
    const getPaymentMethodText = (order) => {
      const pm = order.payment_method;
      if (
        settings.enable_table_qr &&
        settings.enable_cash === false &&
        pm === "cash" &&
        order.payment_complete === false
      ) {
        return "-";
      }
      let temp = pm
        .replace("kod", "KNET on delivery")
        .replace("myfatoorah", "MyFatoorah")
        .replace("bookey", "bookeey")
        .replace("pos", "POS");
      temp = temp[0].toUpperCase() + temp.slice(1);
      return temp;
    };

    const getPaymentTypeText = (order) => {
      const pm = order.payment_method;
      const ct = order.payment_ctype;
      if (
        settings.enable_table_qr &&
        settings.enable_cash === false &&
        pm === "cash" &&
        order.payment_complete === false
      ) {
        return "-";
      }
      return pm == "cash"
        ? "Cash"
        : pm == "pos"
        ? `POS`
        : pm == "benefit-transfer"
        ? t("Benefit Transfer")
        : pm == "wallet"
        ? t("Wallet")
        : pm == "custom"
        ? `Custom (${ct})`
        : `Online (${
            ct === "1" ? "Knet" : ct === "2" || ct === "0" ? "credit" : ct
          })`;
    };

    const keywords = {
      "#customer#":
        this.props.order.language === "arabic"
          ? selectedOrder.customer
            ? selectedOrder.customer[0]?.trim()
            : "بك"
          : selectedOrder.customer
          ? selectedOrder.customer[0]
          : "",
      "#tracking_code#": order.tracking_id,
      "#domain#": this.props.domain,
      "#storename#": this.props.settings.name,
      "#product_name#": productInfo?.product__name,
      "#booking_date#":
        this.props.order.language === "arabic"
          ? moment(productInfo?.booking_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : moment(productInfo?.booking_date, "YYYY-MM-DD").format(
              "Do MMMM YYYY"
            ),
      "#booking_start#":
        this.props.order.language === "arabic"
          ? moment(productInfo?.booking_start, "HH:mm:ss")
              .format("h:mm A")
              .replace("AM", "ص")
              .replace("PM", "م")
          : moment(productInfo?.booking_start, "HH:mm:ss").format("h:mm A"),
      "#receiver#": order.gift_recipient_name,
      "#sender#": order.send_gift_anonymously
        ? this.props.order.language === "arabic"
          ? "شخص ما"
          : "Someone"
        : this.props.order.language === "arabic"
        ? selectedOrder.customer
          ? selectedOrder.customer[0]?.trim()
          : "بك"
        : selectedOrder.customer
        ? selectedOrder.customer[0]
        : "",
      "#order_type#": order.is_delivery
        ? this.props.order.language === "arabic"
          ? "توصيل"
          : "Delivery"
        : this.props.order.language === "arabic"
        ? "استلام"
        : "Pickup",
      "#store_phone#": settings.link_phone
        ? settings.link_phone
        : settings.link_whatsapp,
      "#order_date#": moment(order.expected_delivery_date).format(
        "MMM Do, YYYY"
      ),
      "#order_time#":
        moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
          "h:mm a"
        ) ===
        moment(order.expected_delivery_slot_end, "HH:mm:ss").format("h:mm a")
          ? `${moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
              "h:mm a"
            )}`
          : `(${moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
              "h:mm a"
            )} - ${moment(order.expected_delivery_slot_end, "HH:mm:ss").format(
              "h:mm a"
            )})`,
    };
    const customWhatsAppMessage =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message.replace(
        /#domain#|#customer#|#receiver#|#store_phone#|#sender#|#order_type#|#order_date#|#order_time#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );
    const customWhatsAppMessageBooking =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message_booking.replace(
        /#domain#|#customer#|#receiver#|#store_phone#|#sender#|#order_type#|#order_date#|#order_time#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );
    const customWhatsAppMessageArabic =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message_ar.replace(
        /#domain#|#customer#|#receiver#|#store_phone#|#sender#|#order_type#|#order_date#|#order_time#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );
    const customWhatsAppMessageArabicBooking =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message_booking_ar.replace(
        /#domain#|#customer#|#receiver#|#store_phone#|#order_type#|#sender#|#order_date#|#order_time#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );
    const customWhatsAppMessageUnknownGift =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message_for_unknown_gift_location.replace(
        /#domain#|#customer#|#receiver#|#store_phone#|#order_type#|#sender#|#order_date#|#order_time#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );
    const customWhatsAppMessageUnknownGiftArabic =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message_for_unknown_gift_location_ar.replace(
        /#domain#|#customer#|#receiver#|#store_phone#|#order_type#|#sender#|#order_date#|#order_time#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );

    const whatsappMessage = !this.props.fromCustomerBookings
      ? `https://wa.me/${selectedOrder.customer[1].slice(
          1
        )}?text=${customWhatsAppMessage}`
      : `https://wa.me/${selectedOrder.customer[1].slice(
          1
        )}?text=${customWhatsAppMessageBooking}`;

    let whatsappMessageArabic = !this.props.fromCustomerBookings
      ? `https://wa.me/${selectedOrder.customer[1].slice(
          1
        )}?text=${customWhatsAppMessageArabic}`
      : `https://wa.me/${selectedOrder.customer[1].slice(
          1
        )}?text=${customWhatsAppMessageArabicBooking}`;

    whatsappMessageArabic = whatsappMessageArabic.trim() + "/?lang=ar";

    const whatsappMessageArabicHidePrice = whatsappMessageArabic + "&hp=1";

    let whatsappMessageHidePrice = whatsappMessage + "?hp=1";

    const WhatsappMenu = (
      <Menu>
        <Menu.Item key="1">
          <a target="_blank" href={whatsappMessage}>
            {t("English")}
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a target="_blank" href={whatsappMessageArabic}>
            {t("Arabic")}
          </a>
        </Menu.Item>
        <Menu.Item key="3">
          <a target="_blank" href={whatsappMessageHidePrice}>
            {t("English (Hide product price)")}
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a target="_blank" href={whatsappMessageArabicHidePrice}>
            {t("Arabic (Hide product price)")}
          </a>
        </Menu.Item>
      </Menu>
    );

    const SurveyMenu = (
      <Menu onClick={(e) => this.createSurvey(e.key)}>
        <Menu.Item key="qr">{t("QR Code")}</Menu.Item>
        {this.props.selectedOrder.obj.phone_number && (
          <>
            <Menu.Item key="whatsapp">{t("Whatsapp")}</Menu.Item>
            <Menu.Item key="sms">{t("SMS")}</Menu.Item>
          </>
        )}

        <Menu.Item key="link">{t("Copy link")}</Menu.Item>
      </Menu>
    );

    const copyLinkMenu = (
      <Menu>
        <Menu.Item key="1" onClick={() => copyToClipboard("en")}>
          {t("English")}
        </Menu.Item>
        <Menu.Item key="2" onClick={() => copyToClipboard("ar")}>
          {t("Arabic")}
        </Menu.Item>
        <Menu.Item key="3" onClick={() => copyToClipboard("en", true)}>
          {t("English (Hide product price)")}
        </Menu.Item>
        <Menu.Item key="4" onClick={() => copyToClipboard("ar", true)}>
          {t("Arabic (Hide product price)")}
        </Menu.Item>
      </Menu>
    );
    const QRPayLinkMenu = (
      <Menu>
        <Menu.Item key="1" onClick={() => generateQRPayLink("en")}>
          {t("English")}
        </Menu.Item>
        <Menu.Item key="2" onClick={() => generateQRPayLink("ar")}>
          {t("Arabic")}
        </Menu.Item>
      </Menu>
    );
    return (
      <div
        className="jumbotron bg-white p-0 "
        style={{ paddingTop: 20, minWidth: 320 }}
      >
        {editExpectedOpen &&
          permissions.can_edit_orders &&
          !this.props.pending && (
            <EditExpectedModal
              visible={editExpectedOpen}
              selectedOrder={order}
              onClose={() => this.setState({ editExpectedOpen: false })}
              refetchLastEditedBy={() => this.fetchLastEditedBy()}
            />
          )}
        {editPaymentType &&
          permissions.can_edit_orders &&
          !this.props.pending && (
            <EditPaymentType
              visible={editPaymentType}
              selectedOrder={order}
              onClose={() => this.setState({ editPaymentType: false })}
              refetchLastEditedBy={() => this.fetchLastEditedBy()}
            />
          )}
        {editTableNumber &&
          permissions.can_edit_orders &&
          !this.props.pending && (
            <EditTableNumberModal
              visible={editTableNumber}
              selectedOrder={order}
              onClose={() => this.setState({ editTableNumber: false })}
              refetchLastEditedBy={() => this.fetchLastEditedBy()}
            />
          )}
        {this.state.editCustomer &&
          permissions.can_edit_customers &&
          !this.props.pending && (
            <EditCustomerModal
              selectedOrder={order}
              onClose={() => this.setState({ editCustomer: false })}
            />
          )}
        {newAddressModalVisible && (
          <NewAddressModal
            visible={newAddressModalVisible}
            fromInvoicePage={true}
            addNewAddress={(address) => this.editAddress(address)}
            branch={this.props.order?.for_branch_id}
            editMode={true}
            addressBeingEdited={this.getAddressBeingEdited()}
            onClose={() => {
              this.setState({
                newAddressModalVisible: false,
              });
            }}
          />
        )}
        {editBranchOpen &&
          permissions.can_transfer_orders &&
          !this.props.pending && (
            <EditBranchModal
              visible={editBranchOpen}
              selectedOrders={[order.id]}
              orderType={order.type}
              originalBranchID={order.for_branch_id}
              onClose={(closeOrder = false) => {
                this.setState({ editBranchOpen: false });
                if (closeOrder) this.props.closeOrder?.();
              }}
              refetchLastEditedBy={() => this.fetchLastEditedBy()}
            />
          )}
        <div style={{ marginBottom: 12 }}>
          {order.has_downloadables && (
            <Button
              size="small"
              className={config.direction == "ltr" ? "mr-1" : "ml-1"}
            >
              <a
                href={`https://${this.props.domain}/download/${order.tracking_id}`}
                target="_blank"
              >
                {t("Downloads page")}
              </a>
            </Button>
          )}
          {!this.props.pending &&
            !this.props.hidePrint &&
            (permissions.can_edit_orders ||
              !this.props.selectedOrder.promise) && (
              <Button
                size="small"
                className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                onClick={() => this.props.print()}
              >
                {t("Print")}
              </Button>
            )}
          {this.props.showDispatchButton &&
            order.area.country_name !== "International" && (
              <Button
                size="small"
                className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                onClick={() => this.props.dispatchOrder()}
              >
                {t("Assign Driver")}
              </Button>
            )}
          {settings.enable_invoicing &&
            !this.props.pending &&
            permissions.can_copy_link_orders && (
              <>
                {(!this.props.selectedOrder.promise ||
                  this.props.selectedOrder.status === "New POS") && (
                  <Dropdown overlay={copyLinkMenu}>
                    <Button
                      size="small"
                      className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                    >
                      {t("Copy link")}
                    </Button>
                  </Dropdown>
                )}
                {(settings.enable_qr_pay_link &&
                  !this.props.selectedOrder.promise) ||
                  (this.props.selectedOrder.status === "New POS" && (
                    <Dropdown overlay={QRPayLinkMenu}>
                      <Button
                        size="small"
                        className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                      >
                        {t("QR Pay Link")}
                      </Button>
                    </Dropdown>
                  ))}
                {(!settings.enable_table_qr ||
                  settings.require_dine_in_contact_info) &&
                  (!this.props.selectedOrder.promise ||
                    this.props.selectedOrder.status === "New POS") && (
                    <Dropdown overlay={WhatsappMenu}>
                      <Button
                        size="small"
                        className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                      >
                        {t("Whatsapp")}
                      </Button>
                    </Dropdown>
                  )}
                <Dropdown overlay={SurveyMenu}>
                  <Button
                    size="small"
                    className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                  >
                    {t("Survey")}
                  </Button>
                </Dropdown>
                {!settings.enable_table_qr &&
                  (!this.props.selectedOrder.promise ||
                    this.props.selectedOrder.status === "New POS") && (
                    <Button
                      size="small"
                      className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                      onClick={() => this.sendSMS()}
                      loading={this.state.smsLoading}
                    >
                      {t("SMS")}
                    </Button>
                  )}
              </>
            )}
        </div>
        {this.state.QRPayLinkShow && (
          <>
            <h5>{t("Payment QR")} </h5>
            <div style={{ textAlign: "center", padding: 2 }}>
              <QRCode
                value={this.state.QRPayLink}
                size={240}
                id={"QR".concat(order.tracking_id)}
                bgColor={"white"}
                fgColor={"black"}
              />
            </div>
          </>
        )}
        {this.props.fromServiceBookings && this.renderPrintButtons()}
        <h5>
          {t("Invoice")}{" "}
          {order.is_gift && (
            <>
              {""}
              <span>
                <GiftOutlined />
              </span>
            </>
          )}
        </h5>
        {!!order.parking_number && (
          <p className="" style={{ lineHeight: 1.3 }}>
            <span
              style={{
                fontWeight: "bold",
                width: "150px",
                display: "inline-block",
              }}
            >
              {settings.enable_car_qr ? t("Parking #") : t("Table #")}:
            </span>
            {this.props.selectedOrder.obj.address.parking_number}
            {!this.props.selectedOrder.promise &&
              permissions.can_edit_orders && (
                <>
                  <Button
                    type="link"
                    className="px-2"
                    icon={<EditOutlined />}
                    onClick={() => this.setState({ editTableNumber: true })}
                  >
                    {t("Edit")}
                  </Button>
                  {!!this.state.lastEditedBy.table_edited_by && (
                    <p
                      style={{
                        color: "#aaaaaa",
                        fontStyle: "italic",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Last edited by")}{" "}
                      {this.state.lastEditedBy.table_edited_by}
                    </p>
                  )}
                </>
              )}
          </p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p className="m-0">
              <span
                style={{
                  fontWeight: "bold",
                  width: "150px",
                  display: "inline-block",
                }}
              >
                {t("Tracking Code")}:
              </span>
              {order.tracking_id}
            </p>
            <p className="m-0">
              <span
                style={{
                  fontWeight: "bold",
                  width: "150px",
                  display: "inline-block",
                }}
              >
                {t("Payment Type")}:
              </span>
              {t(getPaymentMethodText(order))}
              {!this.props.selectedOrder.promise &&
                this.props.selectedOrder.obj.channel === "Online POS" &&
                permissions.can_edit_orders && (
                  <>
                    <Button
                      type="link"
                      className="px-2"
                      icon={<EditOutlined />}
                      onClick={() => this.setState({ editPaymentType: true })}
                    >
                      {t("Edit")}
                    </Button>
                    {!!this.state.lastEditedBy.payment_edited_by && (
                      <p
                        style={{
                          color: "#aaaaaa",
                          fontStyle: "italic",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Last edited by")}{" "}
                        {this.state.lastEditedBy.payment_edited_by}
                      </p>
                    )}
                  </>
                )}
            </p>
            <p className="m-0">
              <span
                style={{
                  fontWeight: "bold",
                  width: "150px",
                  display: "inline-block",
                }}
              >
                {t("Payment Method")}:
              </span>
              {t(getPaymentTypeText(order))}
            </p>
          </div>
          <div>
            {settings.show_tracking_id_qr && (
              <>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <QRCode
                    value={order.tracking_id}
                    size={60}
                    id={"QR".concat(order.tracking_id)}
                    bgColor={"white"}
                    fgColor={"black"}
                  />
                  <span
                    style={{
                      width: "150px",
                    }}
                  >
                    {t("Tracking Code")}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        {order.expected_delivery_date &&
        order.expected_delivery_slot_start &&
        order.expected_delivery_slot_end &&
        !this.props.fromCustomerBookings &&
        !settings.enable_table_qr ? (
          <p
            className="m-0"
            style={{
              display: "flex",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                width: "150px",
                display: "inline-block",
              }}
            >
              {t("Expected Delivery")}:
            </span>

            <span
              style={{
                flex: 1,
              }}
            >
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                {moment(order.expected_delivery_date).format("MMM Do, YYYY")}
              </span>
              <br />
              <p
                style={{
                  direction: "ltr",
                  margin: 0,
                  display: "inline-block",
                  textDecoration: "underline",
                }}
              >
                {`(${moment(
                  order.expected_delivery_slot_start,
                  "HH:mm:ss"
                ).format("h:mm a")} - ${moment(
                  order.expected_delivery_slot_end,
                  "HH:mm:ss"
                ).format("h:mm a")})`}
              </p>
              {!this.props.selectedOrder.promise &&
                permissions.can_edit_orders &&
                this.props.selectedOrder.status !== "Refunded" && (
                  <>
                    <Button
                      type="link"
                      className="px-2"
                      icon={<EditOutlined />}
                      onClick={() => this.setState({ editExpectedOpen: true })}
                    >
                      {t("Edit")}
                    </Button>

                    {!!this.state.lastEditedBy.date_edited_by && (
                      <p
                        style={{
                          color: "#aaaaaa",
                          fontStyle: "italic",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Date last edited by")}{" "}
                        {this.state.lastEditedBy.date_edited_by}
                      </p>
                    )}
                    {!!this.state.lastEditedBy.time_edited_by && (
                      <p
                        style={{
                          color: "#aaaaaa",
                          fontStyle: "italic",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Time last edited by")}{" "}
                        {this.state.lastEditedBy.time_edited_by}
                      </p>
                    )}
                  </>
                )}
            </span>
          </p>
        ) : null}
        <br />
        <p className="m-0">
          <span
            style={{
              fontWeight: "bold",
              width: "150px",
              display: "inline-block",
            }}
          >
            {t("Placed")}:
          </span>
          {moment(order.placed).format("MMM Do YYYY - h:mm a")}
        </p>
        <p className="m-0">
          <span
            style={{
              fontWeight: "bold",
              width: "150px",
              display: "inline-block",
            }}
          >
            {t("ReceivedInvoice")}:
          </span>
          {order.received
            ? moment(order.received).format("MMM Do YYYY - h:mm a")
            : "-"}
        </p>
        <br />

        {settings.is_branch_based_ordering && order.for_branch_id && (
          <p className="m-0">
            <span
              style={{
                fontWeight: "bold",
                width: "150px",
                display: "inline-block",
              }}
            >
              {t("Branch")}:
            </span>
            {(config.language === "arabic" && order.for_branch_ar) ||
              order.for_branch}
            {permissions.can_transfer_orders &&
              this.props.selectedOrder.status !== "Refunded" && (
                <>
                  <Button
                    type="link"
                    className="px-2"
                    icon={<EditOutlined />}
                    onClick={() => this.setState({ editBranchOpen: true })}
                  >
                    {t("Edit")}
                  </Button>
                  {!!this.state.lastEditedBy?.branch_edited_by && (
                    <p
                      style={{
                        color: "#aaaaaa",
                        fontStyle: "italic",
                        fontSize: "0.75rem",
                      }}
                    >
                      {t("Last edited by")}{" "}
                      {this.state.lastEditedBy?.branch_edited_by}
                    </p>
                  )}
                </>
              )}
          </p>
        )}
        {order.no_cutlery && (
          <p style={{ fontWeight: "bold" }}>{t("No Cutlery")}</p>
        )}
        {!settings.enable_table_qr || settings.require_dine_in_contact_info ? (
          <>
            {!settings.enable_table_qr && (
              <h5 className="mt-3">
                {order.is_delivery ? t("Deliver to") : t("Pickup Address")}
                {!selectedOrder.promise &&
                  permissions.can_edit_orders &&
                  order.is_delivery && (
                    <>
                      <Button
                        type="link"
                        className="px-2"
                        icon={<EditOutlined />}
                        onClick={() =>
                          this.setState({ newAddressModalVisible: true })
                        }
                      >
                        {t("Edit")}
                      </Button>
                      {!!this.state.lastEditedBy.address_edited_by && (
                        <p
                          style={{
                            color: "#aaaaaa",
                            fontStyle: "italic",
                            fontSize: "0.75rem",
                          }}
                        >
                          {t("Last edited by")}{" "}
                          {this.state.lastEditedBy.address_edited_by}
                        </p>
                      )}
                    </>
                  )}
              </h5>
            )}
            {order.is_gift ? (
              <>
                <p>
                  {order.call_recipient
                    ? t("Please call recipient number on arrival")
                    : ""}
                </p>
                <p className="my-0">
                  <span
                    style={{
                      fontWeight: "bold",
                      width: "150px",
                      display: "inline-block",
                    }}
                  >
                    {t("Receiver Name")}:
                  </span>
                  {order.gift_recipient_name}
                </p>
                <p className="my-0">
                  <span
                    style={{
                      fontWeight: "bold",
                      width: "150px",
                      display: "inline-block",
                    }}
                  >
                    {t("Receiver Number")}:
                  </span>
                  {order.gift_recipient_number}
                </p>
                {order.unknown_gift_recipient_location && (
                  <>
                    <p
                      className="my-0"
                      style={{
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          width: "150px",
                          display: "inline-block",
                        }}
                      >
                        {t("Receiver Address")}:
                      </span>

                      <span
                        style={{
                          fontWeight: "normal",
                          flex: "1",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          <b>{t("Country")}:</b> {t(order.area.country_name)},{" "}
                          <b>{t(areaText)}:</b>{" "}
                          {config.language == "arabic"
                            ? order.area.ar_name
                            : order.area.name}
                        </p>
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {order.address.block &&
                          order.address.block !== "-" &&
                          order.area.country_name != "Qatar" ? (
                            <>
                              <b>{t("Block")}:</b> {order.address.block},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.zone &&
                          order.address.zone !== "-" &&
                          order.area.country_name == "Qatar" ? (
                            <>
                              <b>{t("Zone")}:</b> {order.address.zone},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.street &&
                          order.address.street !== "-" ? (
                            <>
                              <b>{t("Street")}:</b> {order.address.street},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.building &&
                          order.address.building !== "-" ? (
                            <>
                              <b>{t("Building")}:</b> {order.address.building},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.avenue ? (
                            <>
                              <b>{t("Avenue")}:</b> {order.address.avenue},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.floor ? (
                            <>
                              <b>{t("Floor")}:</b> {order.address.floor},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.apartment ? (
                            <>
                              <b>{t("Apartment")}:</b> {order.address.apartment}
                              ,{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.location_id &&
                          order.area.country_name == "Kuwait" ? (
                            <>
                              <b>{t("PACI")}:</b> {order.address.location_id},{" "}
                            </>
                          ) : (
                            ""
                          )}
                          {order.address.additional ? (
                            <>
                              <b>{t("Additional")}:</b>{" "}
                              {order.address.additional}
                            </>
                          ) : (
                            ""
                          )}
                        </p>

                        <span
                          style={{
                            border: "1px solid rgb(214, 48, 49)",
                            backgroundColor: "rgb(255, 206, 206)",
                            borderRadius: "5px",
                            padding: "5px 15px",
                            display: "inline-block",
                            color: "rgb(155, 0, 0)",
                            cursor: !selectedOrder.promise ? "pointer" : "",
                            marginTop: "10px",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            if (!selectedOrder.promise) {
                              this.setState({ newAddressModalVisible: true });
                            }
                          }}
                        >
                          {t("Address details unknown.")}{" "}
                          {!selectedOrder.promise && (
                            <u>
                              {t("Edit")}{" "}
                              {config.language === "arabic" ? (
                                <span>
                                  {" "}
                                  <ArrowLeftOutlined />{" "}
                                </span>
                              ) : (
                                <ArrowRightOutlined />
                              )}
                            </u>
                          )}
                        </span>
                        <span
                          style={{
                            border: "1px solid rgb(48, 214, 53)",
                            backgroundColor: "rgb(210, 255, 206)",
                            borderRadius: "5px",
                            padding: "5px 15px",
                            display: "inline-block",
                            color: "rgb(0, 155, 18)",
                            marginTop: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <a
                            target={"_blank"}
                            href={`https://wa.me/${order.gift_recipient_number.replace(
                              /[^0-9]/g,
                              ""
                            )}?text=${
                              order.language === "arabic"
                                ? customWhatsAppMessageUnknownGiftArabic
                                : customWhatsAppMessageUnknownGift
                            }`}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "rgb(10, 146, 0)",
                            }}
                          >
                            {t("Contact receiver")}{" "}
                            {config.language == "arabic" ? (
                              <span>
                                {" "}
                                <ArrowLeftOutlined />{" "}
                              </span>
                            ) : (
                              <ArrowRightOutlined />
                            )}
                          </a>
                        </span>
                      </span>
                    </p>
                  </>
                )}
              </>
            ) : (
              <p className="my-0">
                <span
                  style={{
                    fontWeight: "bold",
                    width: "150px",
                    display: "inline-block",
                  }}
                >
                  {t("Customer")}:
                </span>
                <span>
                  {order.first_name} {order.last_name}
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      width: "150px",
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </span>
                  (
                  {order.phone.startsWith("+965")
                    ? `${order.phone.slice(0, 4)}-${order.phone.slice(4)}`
                    : order.phone}
                  )
                  {!selectedOrder.promise &&
                    !this.props.pending &&
                    permissions.can_edit_customers &&
                    order.first_name !== "walk-in" && (
                      <Button
                        type="link"
                        className="px-2"
                        icon={<EditOutlined />}
                        onClick={() => this.setState({ editCustomer: true })}
                      >
                        {t("Edit")}
                      </Button>
                    )}
                </span>
              </p>
            )}
            {!(order.is_gift && order.unknown_gift_recipient_location) &&
              !settings.enable_table_qr && (
                <p>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        width: "150px",
                        display: "inline-block",
                      }}
                    >
                      {order.is_delivery
                        ? order.is_gift
                          ? t("Receiver Address")
                          : t("Address")
                        : t("PickupFrom")}
                      :
                      {/*{order.is_gift ? t("Receiver Address") : t("Address")}:*/}
                    </span>
                    <span
                      style={{
                        flex: 1,
                      }}
                    >
                      {order.is_delivery && (
                        <>
                          {order.area.country_name !== "International" && (
                            <b>{t("Country")}:</b>
                          )}
                          {t(order.area.country_name)},{" "}
                        </>
                      )}

                      {(order.area != "-" || order.pickup_address) && (
                        <>
                          {order.is_delivery && (
                            <b>
                              {order.area.country_name === "International"
                                ? t("Country")
                                : t(areaText)}
                              :{" "}
                            </b>
                          )}
                          {config.language == "arabic"
                            ? order.area.ar_name
                            : order.area.name}
                        </>
                      )}

                      {!order.is_delivery &&
                        order.pickup_address &&
                        settings.ask_for_car_info && (
                          <>
                            <p
                              className="mt-2 mb-1"
                              style={{
                                marginLeft:
                                  config.language === "arabic" ? 0 : "-150px",
                                marginRight:
                                  config.language === "arabic" ? "-150px" : 0,
                              }}
                            >
                              <CarOutlined />
                              <span className="mx-2">
                                <b>{t("Car details")}:</b>
                              </span>
                            </p>
                            <div
                              // className="mx-3"
                              style={{
                                marginLeft:
                                  config.language === "arabic" ? 0 : "-147px",
                                marginRight:
                                  config.language === "arabic" ? "-147px" : 0,
                              }}
                            >
                              {order.car_make && (
                                <p className="m-0">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      width: "150px",
                                      display: "inline-block",
                                    }}
                                  >
                                    {t("Make")}:
                                  </span>
                                  {order.car_make}
                                </p>
                              )}
                              {order.car_color && (
                                <p className="m-0">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      width: "150px",
                                      display: "inline-block",
                                    }}
                                  >
                                    {t("Color")}:
                                  </span>
                                  {order.car_color}
                                </p>
                              )}
                              {order.car_plate_number && (
                                <p className="m-0">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      width: "150px",
                                      display: "inline-block",
                                    }}
                                  >
                                    {t("Plate number")}:
                                  </span>
                                  {order.car_plate_number}
                                </p>
                              )}{" "}
                              {!order.car_make &&
                              !order.car_color &&
                              !order.car_plate_number ? (
                                <p>{t("Car details were not provided")}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      {order.is_delivery && (
                        <p
                          style={{
                            display: "flex",
                          }}
                        >
                          {/*<span*/}
                          {/*  style={{*/}
                          {/*    fontWeight: "bold",*/}
                          {/*    width: "150px",*/}
                          {/*    display: "inline-block",*/}
                          {/*  }}*/}
                          {/*></span>*/}
                          <span
                            style={{
                              fontWeight: "normal",
                              flex: "1",
                            }}
                          >
                            {order.address.block &&
                            order.area.country_name != "Qatar" ? (
                              <>
                                <b
                                  style={{
                                    display:
                                      order.area.country_name ===
                                        "International" && "block",
                                  }}
                                >
                                  {order.area.country_name === "International"
                                    ? t("Address line 1")
                                    : t("Block")}
                                  :
                                </b>{" "}
                                {order.address.block}
                                {order.area.country_name !== "International"
                                  ? ","
                                  : ""}{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.zone &&
                            order.area.country_name == "Qatar" ? (
                              <>
                                <b>{t("Zone")}:</b> {order.address.zone},{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.street ? (
                              <>
                                <b
                                  style={{
                                    display:
                                      order.area.country_name ===
                                        "International" && "block",
                                  }}
                                >
                                  {order.area.country_name === "International"
                                    ? t("Address line 2")
                                    : t("Street")}
                                  :
                                </b>{" "}
                                {order.address.street}
                                {order.area.country_name !== "International"
                                  ? ","
                                  : ""}{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.building ? (
                              <>
                                <b>{t("Building")}:</b> {order.address.building}
                                ,{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.avenue ? (
                              <>
                                <b
                                  style={{
                                    display:
                                      order.area.country_name ===
                                        "International" && "block",
                                  }}
                                >
                                  {order.area.country_name === "International"
                                    ? t("Postcode")
                                    : t("Avenue")}
                                  :
                                </b>{" "}
                                {order.address.avenue}
                                {order.area.country_name === "International"
                                  ? ""
                                  : ","}{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.floor ? (
                              <>
                                <b>{t("Floor")}:</b> {order.address.floor},{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.apartment ? (
                              <>
                                <b>{t("Apartment")}:</b>{" "}
                                {order.address.apartment},{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.location_id &&
                            order.area.country_name == "Kuwait" ? (
                              <>
                                <b>{t("PACI")}:</b> {order.address.location_id},{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {order.address.additional ? (
                              <>
                                <b>{t("Additional")}:</b>{" "}
                                {order.address.additional},{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                      )}
                    </span>
                  </div>
                </p>
              )}

            {settings.ask_for_civil_id && (
              <p>
                <span
                  style={{
                    fontWeight: "bold",
                    width: "150px",
                    display: "inline-block",
                  }}
                >
                  {t("Civil ID #")}:
                </span>
                {order.civil_id || t("Not provided")}
              </p>
            )}
          </>
        ) : (
          <div className="mb-3"></div>
        )}
        {orderDetails ? (
          <>
            {settings.enable_table_qr &&
              order.parking_number &&
              orderDetails?.items.some((i) => i.status === "New") && (
                <InvoiceTable
                  orderDetails={orderDetails}
                  order={order}
                  isUnservedTable={true}
                  updateOrderDetails={this.updateOrderDetails}
                  refetchLastEditedBy={() => this.fetchLastEditedBy()}
                  lastEditedBy={this.state.lastEditedBy}
                />
              )}
            <InvoiceTable
              orderDetails={orderDetails}
              order={order}
              refetchLastEditedBy={() => this.fetchLastEditedBy()}
              lastEditedBy={this.state.lastEditedBy}
            />
          </>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editAddress: (payload) => dispatch(editAddress(payload)),
    createSurvey: (survey, isBulk) => dispatch(createSurvey(survey, isBulk)),
  };
};
const mapStateToProps = (state) => {
  return {
    config: state.config,
    domain: state.auth.settings.domain,
    settings: state.auth.settings,
    permissions: state.auth.permissions,
    appearance: state.appearance,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Invoice));
