import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../../../store/actions/instance";
import { v4 as uuidv4 } from "uuid";

//Ant
import { message, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
export class ProductFileField extends Component {
  state = {
    value: this.props.selectedExtraField?.value || null,
  };
  handleFileUpload = async (value, extraField) => {
    const { t } = this.props;
    const file = value[0].originFileObj;
    if (!file) return;
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const allowableExtensions = [
      "txt",
      "jpg",
      "jpeg",
      "bmp",
      "png",
      "doc",
      "docx",
      "odt",
      "pdf",
      "xls",
      "xlsx",
      "ods",
      "ppt",
      "pptx",
      "rar",
      "zip",
      "zipx",
      "7z",
      "csv",
      "mp3",
      "wav",
      "m4a",
      "flac",
      "aac",
      "ogg",
      "wma",
      "aiff",
      "aif",
      "ape",
      "midi",
      "mid",
      "amr",
      "ac3",
      "caf",
      "au",
    ];

    if (!allowableExtensions.includes(fileExtension)) {
      message.error(t("Invalid file format"));

      return;
    }

    if (file.size / 1024 / 1024 > 10) {
      message.error(t("File must be smaller than 10 MB"));
      return;
    }

    let formData = new FormData();
    let keys = [];

    if (extraField.type === "file" && file) {
      formData.append(`file${extraField.id}`, file, file.name);
      keys.push(extraField.id);
    }

    if (!keys.length) {
      return;
    }
    formData.append(`keys`, keys);
    formData.append(`store`, this.props.settings.slug);

    try {
      let res = await instance.post(
        "https://proxy.ordable.com/upload_file/",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const fileURL =
            "https://tapcom-live.ams3.cdn.digitaloceanspaces.com/" +
              res.data[0]?.file_url || null;
          const fileName = file?.name || "";
          resolve({ url: fileURL, name: fileName });
        }, 1000);
      });
    } catch (err) {
      message.error("An error has occured. Try again later");
      console.error(err);

      return "-1";
    }
  };
  removeFile = (extaField, key) => {
    this.props.addExtraFieldsToProduct(extaField, null, key);
    this.setState({ value: this.props.selectedExtraField?.value || null });
  };

  beforeUpload = (file, showMessage = true) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "text/csv" ||
      file.type === "image/gif" ||
      file.type === "application/pdf" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document";

    if (!isJpgOrPng && showMessage) {
      message.error("You can only upload files of the specified formats");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M && showMessage) {
      message.error("File must smaller than 1MB");
    }
    return isJpgOrPng && isLt2M;
  };
  render() {
    const { config, extraField, key1, group, t } = this.props;
    const { value } = this.state;

    return (
      <div className="mt-3 mb-4">
        <h4
          className="mb-3 mt-2 mx-1 border-top border-bottom p-3"
          style={{
            fontSize: "1rem",
          }}
        >
          {config.language === "arabic"
            ? group
              ? group.ar_name + " - " + extraField.ar_name
              : extraField.ar_name
            : group
            ? group.name + " - " + extraField.name
            : extraField.name}
        </h4>
        <Upload
          defaultFileList={
            value
              ? [
                  {
                    uid: "1",
                    name: value.name,
                    status: "done",
                    response: '{"status": "success"}',
                    url: value.url,
                  },
                ]
              : null
          }
          previewFile={false}
          value={value}
          multiple={extraField.quantity_exclusive}
          beforeUpload={(file) => {
            return this.beforeUpload(file);
          }}
          onRemove={() => {
            this.removeFile(extraField, key1);
          }}
          placeholder={
            (config.language === "arabic" && extraField.ar_name) ||
            extraField.name
          }
          customRequest={({ onSuccess }) => onSuccess("ok")}
          onChange={async (e) => {
            this.props.setUploadLoading(true);
            let newValue = await this.handleFileUpload(e.fileList, extraField);
            this.props.addExtraFieldsToProduct(extraField, newValue, key1);
            this.setState({ value: newValue });
            this.props.setUploadLoading(false);
          }}
        >
          <Button disabled={value ? true : false} icon={<UploadOutlined />}>
            {t("Upload")}
          </Button>
        </Upload>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    usedComponents: state.pos.usedComponents,
  };
};

export default connect(mapStateToProps)(withNamespaces()(ProductFileField));
