import {
  CheckPromoProducts,
  getCategoryTotal,
  getTotalCart,
} from "../../components/POS/DiscountModal/helpers";
import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  EDIT_PRODUCT_QUANTITY,
  CLEAR_CART,
  HIDE_HEADER,
  FETCH_ALL_BRANCH_PRODUCTS,
  SET_BRANCH_PRODUCTS_FETCH_LOADING,
  SET_CURRET_BRANCH,
  COLLAPSE_SIDENAV,
  UPDATE_USED_COMPONENTS,
  UPDATE_POS_BRANCH_PRODUCT,
  FETCH_EXTERNAL_SOURCES,
  CONTROL_EXTERNAL_SOURCE,
  ADD_EXTERNAL_SOURCE,
  SET_EXTERNAL_SOURCE_FETCH_LOADING,
  EDIT_PRODUCT_FROM_CART,
  SET_POS_BRANCH_DELIVERY_CHARGES,
  OVERRIDE_ITEM_PRICE,
  FETCH_TABLES,
  POS_CHECK_PROMOTIONS,
  POS_SET_CUSTOMER,
  POS_SET_ADDRESS,
  POS_RESET_PROMOTIONS,
  POS_SET_EDIT_ORDER,
  POS_SET_GIFT,
  POS_SET_MANUALLY_APPLIED_PROMOTION,
  POS_SET_ORDER_SETTINGS,
  POS_SET_DELIVERY_CHARGE,
  POS_SET_ORDER,
} from "../actions/actionTypes";
import moment from "moment";
export const preciseNumber = (number) => {
  return parseFloat(number.toPrecision(7));
};
function getProduct(product_id, allProducts) {
  return allProducts.find((p) => p.id == product_id);
}
export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function reduceCompositeInventories(
  component,
  change,
  allProducts,
  usedComponents
) {
  let product;
  let component_dict;

  let composite_product;

  usedComponents[component.id] = (usedComponents[component.id] || 0) + change;

  for (let product_id in component.composed_products) {
    composite_product = {
      id: parseInt(product_id),
      ...component.composed_products[product_id],
    };

    product = getProduct(composite_product.id, allProducts);

    if (product) {
      component_dict = product.components_dict[component.id];

      let quantityDeduction = getCompositeQuantityDeduction(
        component_dict,
        component.inventory_on_hand,
        change,
        product.inventory_on_hand,
        product.components_dict,
        usedComponents
      );

      product.inventory_on_hand = preciseNumber(
        product.inventory_on_hand - quantityDeduction
      );
    }
  }

  return usedComponents;
}

export function reduceComponentsInventories(
  compositeProduct,
  change,
  allProducts,
  usedComponents
) {
  let product;
  let component_dict;
  let quantityDeduction;
  let compositeProductsToBeUpdated = {};

  for (let product_id in compositeProduct.components_dict) {
    component_dict = {
      id: parseInt(product_id),
      ...compositeProduct.components_dict[product_id],
    };
    product = getProduct(component_dict.id, allProducts);

    quantityDeduction = preciseNumber(change * component_dict.required_amount);

    usedComponents[product_id] =
      (usedComponents[product_id] || 0) + quantityDeduction;

    compositeProductsToBeUpdated = {
      ...compositeProductsToBeUpdated,
      ...component_dict.composed_products,
    };

    if (product && ["stocked", "composite"].includes(product.type_of_product)) {
      product.inventory_on_hand = preciseNumber(
        product.inventory_on_hand - quantityDeduction
      );
    }
  }
  // update other product quantities

  for (let [composed_product_id, values] of Object.entries(
    compositeProductsToBeUpdated
  )) {
    if (composed_product_id == compositeProduct.id) continue;
    let composed_product = getProduct(composed_product_id, allProducts);
    if (composed_product) {
      composed_product.inventory_on_hand = getCompositeInventory(
        composed_product.components_dict,
        usedComponents
      );
    }
  }
  return usedComponents;
}
function getCompositeInventory(productComponents, usedComponents) {
  let inventory = Infinity;
  let mainComponentInventory;
  let componentRequiredAmount;
  for (let component_id in productComponents) {
    if (productComponents[component_id].type_of_product !== "stocked") continue;
    mainComponentInventory = productComponents[component_id].inventory_on_hand;
    componentRequiredAmount = productComponents[component_id].required_amount;

    inventory = Math.min(
      inventory,
      preciseNumber(
        preciseNumber(
          mainComponentInventory - (usedComponents[component_id] || 0)
        ) / componentRequiredAmount
      )
    );
  }
  if (inventory == Infinity) return 999999;
  return inventory;
}
function getCompositeQuantityDeduction(
  component_dict,
  inventory,
  change,
  compositeProductInventory,
  productComponents,
  usedComponents
) {
  const isDeducting = change >= 0;

  let minQuantity = compositeProductInventory * component_dict.required_amount;
  if (isDeducting) {
    if (inventory + change < minQuantity) {
      return preciseNumber(change / component_dict.required_amount);
    }
    if (inventory + change >= minQuantity && inventory <= minQuantity) {
      return preciseNumber(
        (minQuantity - inventory) / component_dict.required_amount
      );
    }
  } else {
    if (inventory + change <= minQuantity) {
      return preciseNumber(
        compositeProductInventory -
          getCompositeInventory(productComponents, usedComponents)
      );
    }
  }
  return 0;
}
function options_are_equal(product1, product2) {
  let keys = Object.keys(product1.selectedOptions);
  let keys_2 = Object.keys(product2.selectedOptions);
  keys = [...new Set(keys.concat(keys_2))];
  let duplicates = false;

  for (const key of keys) {
    if (
      product2.selectedOptions[key] &&
      product1.selectedOptions[key] &&
      product1.selectedOptions[key].quantity ===
        product2.selectedOptions[key].quantity
    ) {
      duplicates = true;
      continue;
    } else {
      duplicates = false;
      break;
    }
  }

  return duplicates;
}
function CheckDuplicate(product, payload) {
  if (!isEmpty(payload.extraFields) || !isEmpty(product.extraFields))
    return false;
  let group_keys = Object.keys(payload.options);

  let duplicates = false;
  if (
    group_keys.length &&
    Object.keys(product.options).length === group_keys.length
  ) {
    for (const group_key of group_keys) {
      if (
        product.options[group_key] &&
        options_are_equal(
          payload.options[group_key],
          product.options[group_key]
        )
      ) {
        duplicates = true;
        continue;
      } else {
        duplicates = false;
        break;
      }
    }
  } else if (isEmpty(payload.options) && isEmpty(product.options)) {
    duplicates = true;
  }

  if (duplicates && payload.ignoreInventory !== product.ignoreInventory) {
    duplicates = false;
  }

  if (duplicates && (payload.specialRemarks || product.specialRemarks)) {
    if (payload.specialRemarks !== product.specialRemarks) duplicates = false;
  }
  if (duplicates && payload.booking_slot) {
    if (payload.booking_slot !== product.booking_slot) duplicates = false;
  }

  return duplicates;
}

function shouldAddPromotion(promotions, state, categories) {
  const newAppliedPromotions = state.appliedPromotions;
  const NewDiscounts = state.discounts;
  const serviceTypes = { Delivery: "delivery", "Pick Up": "pickup" };
  const manuallyAppliedPromotion = state.manuallyAppliedPromotion;
  const CartNumberOfItems = getTotalCart(state.cart); // should only call this when needed
  const customerAddress = state.address;
  const authUser = state.customer?.userDetails;
  for (let i = 0; i < promotions.length; i++) {
    const promotion = promotions[i];
    const existingPromo = newAppliedPromotions.find(
      (p) => p.id === promotion.key
    );
    if (!existingPromo || (existingPromo && existingPromo.repeatable)) {
      let isApplicable = true; // logic to check if it is applicable
      let multiplier = existingPromo ? existingPromo.quantity + 1 : 1;
      let newQuantity = Infinity;
      let [totalPrice, totalItems] = CartNumberOfItems;
      if (
        promotion.excluded_products.length ||
        promotion.excluded_categories.length ||
        promotion.excluded_modifiers.length
      ) {
        [totalPrice, totalItems] = getTotalCart(
          state.cart,
          promotion.excluded_products,
          promotion.excluded_categories,
          promotion.excluded_modifiers
        );
      }
      for (
        let conditionIndex = 0;
        conditionIndex < promotion.conditions.length;
        conditionIndex++
      ) {
        const condition = promotion.conditions[conditionIndex];
        if (
          condition.type === "value" &&
          totalPrice >= condition.value * multiplier
        ) {
          newQuantity = Math.min(
            newQuantity,
            parseInt(totalPrice / condition.value)
          );
          continue;
        } else if (
          condition.type === "number" &&
          totalItems >= condition.value * multiplier
        ) {
          newQuantity = Math.min(
            newQuantity,
            parseInt(totalItems / condition.value)
          );
          continue;
        } else if (condition.type === "products") {
          let [productConditionApplied, tmp_quantity] = CheckPromoProducts(
            condition.products,
            state.cart,
            multiplier,
            condition.value
          );

          if (productConditionApplied) {
            newQuantity = Math.min(newQuantity, tmp_quantity);
            continue;
          }
        } else if (
          condition.type === "coupon" &&
          manuallyAppliedPromotion?.id === promotion.key
        ) {
          continue;
        } else if (condition.type === "category_value") {
          let [categoryTotalPrice, _] = getCategoryTotal(
            state.cart,
            condition.categories.map((cat) => cat.id),
            promotion.excluded_products,
            promotion.excluded_categories,
            promotion.excluded_modifiers,
            categories
          );
          if (categoryTotalPrice >= condition.value * multiplier) {
            newQuantity = Math.min(
              newQuantity,
              parseInt(categoryTotalPrice / condition.value)
            );
            continue;
          }
        } else if (condition.type === "category_number") {
          let [_, cateogryTotalItems] = getCategoryTotal(
            state.cart,
            condition.categories.map((cat) => cat.id),
            promotion.excluded_products,
            promotion.excluded_categories,
            promotion.excluded_modifiers,
            categories
          );
          if (cateogryTotalItems >= condition.value * multiplier) {
            newQuantity = Math.min(
              newQuantity,
              parseInt(cateogryTotalItems / condition.value)
            );
            continue;
          }
        } else if (
          condition.type === "service_type" &&
          customerAddress &&
          serviceTypes[customerAddress.orderType] === condition.value
        ) {
          continue;
        } else if (condition.type === "delivery_area") {
          if (
            condition.value
              .map((area) => area.id)
              .includes(customerAddress?.deliveryAddress?.address?.area_id)
          ) {
            continue;
          }
        } else if (condition.type === "branch") {
          if (
            condition.value.map((b) => b.id).includes(customerAddress?.branchId)
          ) {
            continue;
          }
        } else if (condition.type === "country") {
          if (
            condition.value
              .map((country) => country.id)
              .includes(customerAddress?.deliveryAddress?.address?.country_id)
          ) {
            continue;
          }
        } else if (
          condition.type === "points" &&
          authUser?.points >= condition.value &&
          manuallyAppliedPromotion?.id === promotion.key
        ) {
          continue;
        } else if (condition.type === "orders") {
          if (authUser && authUser.orders_count === condition.value) {
            continue;
          }
        } else if (condition.type === "orders_more") {
          if (authUser && authUser.orders_count > condition.value) {
            continue;
          }
        } else if (condition.type === "orders_less") {
          if (authUser && authUser.orders_count < condition.value) {
            continue;
          }
        } else if (condition.type === "first_time") {
          if (
            authUser &&
            authUser.orders_count <= 0 &&
            moment(authUser.created).isAfter(moment(promotion.created))
          ) {
            continue;
          }
        }
        isApplicable = false;
        break;
      }
      if (
        isApplicable &&
        !authUser &&
        promotion.discounts.some((d) =>
          ["cashback_fixed", "cashback_percent"].includes(d.type)
        )
      ) {
        isApplicable = false;
      }

      if (
        isApplicable &&
        promotion.quantity_per_user >= 1 &&
        (!authUser ||
          promotion.quantity_per_user <=
            (authUser.smart_promotions_usage?.[promotion.key] || 0))
      ) {
        isApplicable = false;
      }

      if (
        isApplicable &&
        promotion.available_to_users.length &&
        !promotion.available_to_users.includes(authUser?.id)
      ) {
        isApplicable = false;
      }

      if (
        isApplicable &&
        !promotion.auto_apply_pos_orders &&
        manuallyAppliedPromotion?.id != promotion.key
      ) {
        isApplicable = false;
      }

      if (
        existingPromo &&
        promotion.remaining + existingPromo.quantity < newQuantity
      ) {
        isApplicable = false;
      } else if (!existingPromo) {
        newQuantity = Math.min(newQuantity, promotion.remaining);
        if (newQuantity <= 0) {
          isApplicable = false;
        }
      }

      if (isApplicable) {
        if (!existingPromo) {
          newAppliedPromotions.push({
            id: promotion.key,
            repeatable: promotion.repeatable,
            quantity: !promotion.repeatable ? 1 : newQuantity,
            version: promotion.version,
          });
          promotion.remaining -= !promotion.repeatable ? 1 : newQuantity;
        } else {
          promotion.remaining -= newQuantity - existingPromo.quantity;
          existingPromo.quantity = newQuantity;
        }
        promotion.discounts.forEach((discount) => {
          if (discount.type === "delivery" && !existingPromo) {
            NewDiscounts.delivery.push({
              id: discount.id,
              promo: promotion.key,
              type: "delivery",
              value: 100,
            });
            NewDiscounts.delivery.sort(function (a, b) {
              return (b.value || 100) - (a.value || 100);
            });
          } else if (discount.type === "delivery_percent" && !existingPromo) {
            NewDiscounts.delivery.push({
              id: discount.id,
              promo: promotion.key,
              type: "delivery_percent",
              value: discount.value,
            });
            NewDiscounts.delivery.sort(function (a, b) {
              return (b.value || 100) - (a.value || 100);
            });
          } else if (discount.type === "fixed" && !existingPromo) {
            if (!NewDiscounts.fixed.length) {
              NewDiscounts.fixed.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.fixed.length; i++) {
                if (discount.value >= NewDiscounts.fixed[i].value) {
                  NewDiscounts.fixed.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.fixed.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          } else if (discount.type === "percent" && !existingPromo) {
            if (!NewDiscounts.percent.length) {
              NewDiscounts.percent.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.percent.length; i++) {
                if (discount.value >= NewDiscounts.percent[i].value) {
                  NewDiscounts.percent.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.percent.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          } else if (discount.type === "products" && discount.products) {
            discount.products.forEach((product) => {
              if (!existingPromo) {
                NewDiscounts.products.push({
                  ...product,
                  original_quantity: product.quantity,
                  quantity: !promotion.repeatable
                    ? product.quantity
                    : product.quantity * newQuantity,
                  promo: promotion.key,
                });
              } else {
                let productPromo = NewDiscounts.products.find(
                  (p) =>
                    product.product_id === p.product_id &&
                    promotion.key === p.promo
                );
                if (productPromo) {
                  productPromo.quantity =
                    product.quantity * newQuantity -
                    (product.quantity * (newQuantity - 1) -
                      productPromo.quantity);
                }
              }
            });
          } else if (discount.type === "category" && !existingPromo) {
            if (!NewDiscounts.category.length) {
              NewDiscounts.category.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.category.length; i++) {
                if (discount.value >= NewDiscounts.category[i].value) {
                  NewDiscounts.category.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.category.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          } else if (discount.type === "products_percent" && !existingPromo) {
            if (!NewDiscounts.products_percent.length) {
              NewDiscounts.products_percent.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.products_percent.length; i++) {
                if (discount.value >= NewDiscounts.products_percent[i].value) {
                  NewDiscounts.products_percent.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.products_percent.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          } else if (discount.type === "modifier" && !existingPromo) {
            if (!NewDiscounts.modifier.length) {
              NewDiscounts.modifier.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.modifier.length; i++) {
                if (discount.value >= NewDiscounts.modifier[i].value) {
                  NewDiscounts.modifier.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.modifier.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          } else if (discount.type === "category_free" && discount.categories) {
            if (!existingPromo) {
              NewDiscounts.category_free.push({
                categories: discount.categories,
                original_quantity: discount.value,
                quantity: !promotion.repeatable
                  ? discount.value
                  : discount.value * newQuantity,
                promo: promotion.key,
                id: discount.id,
              });
            } else {
              let category_promo = NewDiscounts.category_free.find(
                (p) => promotion.key === p.promo && p.id === discount.id
              );
              if (category_promo) {
                category_promo.quantity =
                  discount.value * newQuantity -
                  (discount.value * (newQuantity - 1) -
                    category_promo.quantity);
              }
            }
          } else if (discount.type === "cashback_fixed" && !existingPromo) {
            if (!NewDiscounts.cashback_fixed.length) {
              NewDiscounts.cashback_fixed.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.cashback_fixed.length; i++) {
                if (discount.value >= NewDiscounts.cashback_fixed[i].value) {
                  NewDiscounts.cashback_fixed.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.cashback_fixed.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          } else if (discount.type === "cashback_percent" && !existingPromo) {
            if (!NewDiscounts.cashback_percent.length) {
              NewDiscounts.cashback_percent.push({
                ...discount,
                promo: promotion.key,
              });
            } else {
              let added = false;
              for (let i = 0; i < NewDiscounts.cashback_percent.length; i++) {
                if (discount.value >= NewDiscounts.cashback_percent[i].value) {
                  NewDiscounts.cashback_percent.splice(i, 0, {
                    ...discount,
                    promo: promotion.key,
                  });
                  added = true;
                  break;
                }
              }
              if (!added) {
                NewDiscounts.cashback_percent.push({
                  ...discount,
                  promo: promotion.key,
                });
              }
            }
          }
        });
      }
    }
  }
}

/////////////
function shouldRemovePromotion(
  promotions,
  state,
  storeSettings,
  resetPromotions,
  categories
) {
  const newAppliedPromotions = state.appliedPromotions;
  const NewDiscounts = state.discounts;
  let newCart = state.cart;

  const serviceTypes = { Delivery: "delivery", "Pick Up": "pickup" };
  const manuallyAppliedPromotion = state.manuallyAppliedPromotion; // no coupons in pos
  const CartNumberOfItems = getTotalCart(state.cart); // should only call this when needed
  const customerAddress = state.address;
  const authUser = state.customer?.userDetails;
  for (let i = 0; i < newAppliedPromotions.length; i++) {
    const appliedPromotion = newAppliedPromotions[i];
    let promo = promotions.find((p) => p.key === appliedPromotion.id);
    let removePromotion = false;
    let newQuantity;
    let multiplier;

    if (
      promo &&
      !resetPromotions &&
      (promo.quantity_per_user < 1 ||
        (authUser &&
          promo.quantity_per_user >
            (authUser.smart_promotions_usage?.[promo.key] || 0))) &&
      (!promo.available_to_users.length ||
        promo.available_to_users.includes(authUser?.id)) &&
      (promo.auto_apply_pos_orders || manuallyAppliedPromotion?.id == promo.key)
    ) {
      newQuantity = appliedPromotion.repeatable ? Infinity : 0;

      multiplier = appliedPromotion.quantity;
      let [totalPrice, totalItems] = CartNumberOfItems;

      if (
        promo.excluded_products.length ||
        promo.excluded_categories.length ||
        promo.excluded_modifiers.length
      ) {
        [totalPrice, totalItems] = getTotalCart(
          state.cart,
          promo.excluded_products,
          promo.excluded_categories,
          promo.excluded_modifiers
        );
      }
      for (let j = 0; j < promo.conditions.length; j++) {
        const condition = promo.conditions[j];
        if (promo.remaining < 0) {
          removePromotion = true;
          newQuantity = appliedPromotion.quantity + promo.remaining;
          break;
        }

        if (
          condition.type === "value" &&
          totalPrice < condition.value * multiplier
        ) {
          if (appliedPromotion.repeatable) {
            newQuantity = Math.min(
              newQuantity,
              parseInt(totalPrice / condition.value)
            );
          }
          removePromotion = true;

          if (!appliedPromotion.repeatable || newQuantity === 0) break;
        } else if (
          condition.type === "number" &&
          totalItems < condition.value * multiplier
        ) {
          if (appliedPromotion.repeatable) {
            newQuantity = Math.min(
              newQuantity,
              parseInt(totalItems / condition.value)
            );
          }

          removePromotion = true;
          if (!appliedPromotion.repeatable || newQuantity === 0) break;
        } else if (condition.type === "products") {
          let [product_condition, tmp_quantity] = CheckPromoProducts(
            condition.products,
            state.cart,
            multiplier,
            condition.value
          );
          if (!product_condition) {
            if (appliedPromotion.repeatable) {
              newQuantity = Math.min(newQuantity, tmp_quantity);
            }
            removePromotion = true;
            if (!appliedPromotion.repeatable || newQuantity === 0) break;
          }
        } else if (
          condition.type === "coupon" &&
          manuallyAppliedPromotion?.id != promo.key
        ) {
          removePromotion = true;
          break;
        } else if (condition.type === "category_value") {
          let [categoryTotalPrice, _] = getCategoryTotal(
            state.cart,
            condition.categories.map((cat) => cat.id),
            promo.excluded_products,
            promo.excluded_categories,
            promo.excluded_modifiers,
            categories
          );
          if (categoryTotalPrice < condition.value * multiplier) {
            removePromotion = true;
            if (appliedPromotion.repeatable) {
              newQuantity = Math.min(
                newQuantity,
                parseInt(categoryTotalPrice / condition.value)
              );
            }
          }
        } else if (condition.type === "category_number") {
          let [_, cateogryTotalItems] = getCategoryTotal(
            state.cart,
            condition.categories.map((cat) => cat.id),
            promo.excluded_products,
            promo.excluded_categories,
            promo.excluded_modifiers,
            categories
          );
          if (cateogryTotalItems < condition.value * multiplier) {
            removePromotion = true;
            if (appliedPromotion.repeatable) {
              newQuantity = Math.min(
                newQuantity,
                parseInt(cateogryTotalItems / condition.value)
              );
            }
          }
        } else if (
          condition.type === "service_type" &&
          (!customerAddress ||
            serviceTypes[customerAddress.orderType] !== condition.value)
        ) {
          removePromotion = true;
          break;
        } else if (condition.type === "delivery_area") {
          if (
            !condition.value
              .map((area) => area.id)
              .includes(customerAddress?.deliveryAddress?.address?.area_id)
          ) {
            removePromotion = true;
            break;
          }
        } else if (condition.type === "branch") {
          if (
            !condition.value
              .map((b) => b.id)
              .includes(customerAddress?.branchId)
          ) {
            removePromotion = true;
            break;
          }
        } else if (condition.type === "country") {
          if (
            !condition.value
              .map((country) => country.id)
              .includes(customerAddress?.deliveryAddress?.address?.country_id)
          ) {
            removePromotion = true;
            break;
          }
        } else if (
          condition.type === "points" &&
          (authUser?.points < condition.value ||
            manuallyAppliedPromotion?.id !== promo.key)
        ) {
          removePromotion = true;
          break;
        } else if (condition.type === "orders") {
          if (!authUser || authUser.orders_count !== condition.value) {
            removePromotion = true;
            break;
          }
        } else if (condition.type === "orders_more") {
          if (!authUser || authUser.orders_count <= condition.value) {
            removePromotion = true;
            break;
          }
        } else if (condition.type === "orders_less") {
          if (!authUser || authUser.orders_count >= condition.value) {
            removePromotion = true;
            break;
          }
        } else if (condition.type === "first_time") {
          if (
            !authUser ||
            authUser.orders_count > 0 ||
            moment(authUser.created).isBefore(moment(promo.created))
          ) {
            removePromotion = true;
            break;
          }
        }
        continue;
      }
    } else {
      removePromotion = true;
      newQuantity = 0;
      promo = promo || { id: appliedPromotion.id };
    }

    if (
      !removePromotion &&
      (promo.version !== appliedPromotion.version ||
        (!authUser &&
          promo.discounts.some((d) =>
            ["cashback_fixed", "cashback_percent"].includes(d.type)
          )))
    ) {
      removePromotion = true;
      newQuantity = 0;
    }

    if (newQuantity === Infinity) {
      newQuantity = 0;
    }

    if (
      // TODO : Review this
      storeSettings?.allow_one_promo &&
      (newAppliedPromotions.length > 1 || manuallyAppliedPromotion) &&
      ((manuallyAppliedPromotion &&
        manuallyAppliedPromotion?.id != promo.key) ||
        (!manuallyAppliedPromotion &&
          newAppliedPromotions.some((p) => p.id > promo.key)))
    ) {
      removePromotion = true;
      newQuantity = 0;
    }
    if (removePromotion) {
      const delta_quantity = appliedPromotion.quantity - newQuantity;

      appliedPromotion.quantity = appliedPromotion.repeatable ? newQuantity : 0;

      const promoShouldBeRemoved = appliedPromotion.quantity <= 0;

      // promo qunatity
      promo.remaining += delta_quantity;
      if (promoShouldBeRemoved) {
        newAppliedPromotions.splice(i, 1);
        i--;
        if (NewDiscounts.delivery.length) {
          NewDiscounts.delivery = NewDiscounts.delivery.filter(
            (p) => p.promo !== promo.key
          );
        }
        if (NewDiscounts.fixed.length) {
          NewDiscounts.fixed = NewDiscounts.fixed.filter(
            (p) => p.promo !== promo.key
          );
        }
        if (NewDiscounts.percent.length) {
          NewDiscounts.percent = NewDiscounts.percent.filter(
            (p) => p.promo !== promo.key
          );
        }
        if (NewDiscounts.category.length) {
          NewDiscounts.category = NewDiscounts.category.filter(
            (p) => p.promo !== promo.key
          );
        }

        if (NewDiscounts.products_percent.length) {
          NewDiscounts.products_percent = NewDiscounts.products_percent.filter(
            (p) => p.promo !== promo.key
          );
        }
        if (NewDiscounts.modifier.length) {
          NewDiscounts.modifier = NewDiscounts.modifier.filter(
            (p) => p.promo !== promo.key
          );
        }
        if (NewDiscounts.cashback_fixed.length) {
          NewDiscounts.cashback_fixed = NewDiscounts.cashback_fixed.filter(
            (p) => p.promo !== promo.key
          );
        }
        if (NewDiscounts.cashback_percent.length) {
          NewDiscounts.cashback_percent = NewDiscounts.cashback_percent.filter(
            (p) => p.promo !== promo.key
          );
        }
      }
      if (NewDiscounts.products.length) {
        if (promoShouldBeRemoved) {
          NewDiscounts.products = NewDiscounts.products.filter(
            (p) => p.promo !== promo.key
          );
          newCart = newCart.map((item) => {
            if (item.promo === promo.key)
              return {
                ...item,
                promo: undefined,
                discount: undefined,
                forcedPrice: undefined,
              };
            else return item;
          });
        } else {
          for (let i = 0; i < NewDiscounts.products.length; i++) {
            const p = NewDiscounts.products[i];
            if (p.promo === promo.key) {
              p.quantity -= delta_quantity * p.original_quantity;

              for (let j = 0; j < newCart.length; j++) {
                const cartItem = newCart[j];
                if (cartItem.id === p.product_id) {
                  const newPromoItemQuantity = cartItem.quantity + p.quantity;
                  if (newCart[j].quantity > newPromoItemQuantity) {
                    newCart[j].quantity = newPromoItemQuantity;
                    p.quantity = 0;
                  }
                }
              }
            }
          }
        }
      }

      if (NewDiscounts.category_free.length) {
        if (promoShouldBeRemoved) {
          NewDiscounts.category_free = NewDiscounts.category_free.filter(
            (p) => p.promo !== promo.key
          );
          newCart = newCart.map((item) => {
            if (item.promo === promo.key)
              return {
                ...item,
                promo: undefined,
                discount: undefined,
                forcedPrice: undefined,
              };
            else return item;
          });
        } else {
          for (let i = 0; i < NewDiscounts.category_free.length; i++) {
            const p = NewDiscounts.category_free[i];
            if (p.promo === promo.key) {
              p.quantity -= delta_quantity * p.original_quantity;

              for (let j = 0; j < newCart.length; j++) {
                const cartItem = newCart[j];
                if (cartItem.promo === p.promo) {
                  const newPromoItemQuantity = cartItem.quantity + p.quantity;
                  if (newCart[j].quantity > newPromoItemQuantity) {
                    newCart[j].quantity = newPromoItemQuantity;
                    p.quantity = 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return newCart;
}

const initialState = {
  cart: [],
  customer: null,
  address: null,
  gift: null,
  appliedPromotions: [],
  manuallyAppliedPromotion: null,
  discounts: {
    percent: [],
    fixed: [],
    delivery: [],
    products: [],
    category: [],
    products_percent: [],
    modifier: [],
    category_free: [],
    cashback_fixed: [],
    cashback_percent: [],
  },
  totalPrice: 0,

  hideHeader: false,
  sideNavCollapsed: false,
  editOrder: null,
  currentBranch: null,
  branchProducts: {
    loadingAll: true,
    allProducts: [],
    categories: [],
    productsCount: null,
    loadedProducts: 0,
  },
  usedComponents: {},
  externalSources: null,
  externalSourcesLoading: false,
  branchDeliveryCharges: null,
  tables: [],
  paymentMethods: null,
  loading: false,
  dhl_data: null,
  dhl_error: false,
  predefined_discounts: {},

  deliveryCharge: 0,
  deliveryChargeBackup: 0,
  orderSettings: {
    paymentComplete: false,
    // paymentType: "cash", // from props
    paymentCType: "",
    paymentReference: "",
    specialRemarks: "",
    // autoReceive: true, // from props
    source: null,
  },
  appliedDiscounts: [],
  forcedSubtotal: null,
};

export default function store(state = initialState, { type, payload }) {
  let newCart = state.cart;
  let newPrice = state.totalPrice;
  const NewDiscounts = state.discounts;

  switch (type) {
    case ADD_PRODUCT_TO_CART:
      const {
        product,
        quantity,
        total_price,
        options,
        booking_slot,
        bookingSlotDetails,
        ignoreInventory,
        specialRemarks,
        resource,
        resourceSlot,
        quantityExcludedOptionsTotal,
        order_item_id,
        extraFields,
        usedComponents,
        hasError,
        promo,
        discount,
        discountType,
        forcedPrice,
      } = payload;

      let cart_item = {
        id: product.id,
        category: product.category_id,
        category_id: product.category_id,
        category_is_child: product.category_is_child,
        parent: product.parent,
        quantity: quantity,
        price: product.price,
        total_price: total_price,
        options: options,
        booking_slot: booking_slot,
        bookingSlotDetails: bookingSlotDetails,
        ignoreInventory: ignoreInventory,
        specialRemarks: specialRemarks,
        quantityExcludedOptionsTotal: quantityExcludedOptionsTotal,
        order_item_id: order_item_id,
        extraFields: extraFields,
        name: product.name,
        ar_name: product.ar_name,
        photo: product.photo_small || product.photo,
        hasError: hasError,
        usedComponents: usedComponents,
        excludeVat: product.exclude_from_vat,
        promo,
        discount,
        discountType,
        forcedPrice,
        chargeDelivery: product.charge_delivery,
      };
      if (product.type_of_product == "service" && resource && resourceSlot) {
        cart_item["resource"] = resource;
        cart_item["resourceSlot"] = resourceSlot;
      }
      newPrice += total_price * payload.quantity;
      let ProductIndex = newCart.findIndex(
        (p) =>
          p.id === product.id &&
          p.discount === discount &&
          CheckDuplicate(p, cart_item)
      );
      if (ProductIndex === -1 || product.type_of_product == "service") {
        newCart.push(cart_item);
        newPrice += quantityExcludedOptionsTotal;
      } else {
        newCart[ProductIndex].quantity += quantity;
      }
      if (payload.discount) {
        let promotion;
        if (payload.discountType === "category_free") {
          promotion = NewDiscounts.category_free.find(
            (p) => p.id === payload.discount
          );
        } else {
          promotion = NewDiscounts.products.find(
            (p) =>
              p.id === payload.discount &&
              ((!p.has_variants && p.product_id === product.id) ||
                (p.has_variants && p.product_id === product.parent))
          );
        }

        if (promotion) promotion.quantity -= payload.quantity;
      }
      return {
        ...state,
        cart: newCart,
        totalPrice: newPrice,
        discounts: NewDiscounts,
      };
    case EDIT_PRODUCT_QUANTITY:
      let cartProduct = newCart[payload.product];

      newPrice -=
        cartProduct.total_price * (cartProduct.quantity - payload.quantity);
      cartProduct.quantity = payload.quantity;

      return {
        ...state,
        cart: newCart,
        totalPrice: newPrice,
        discount: NewDiscounts,
      };

    case REMOVE_PRODUCT_FROM_CART:
      let removedProduct = newCart.splice(payload.productIndex, 1)[0];
      newPrice =
        state.totalPrice -
        removedProduct.total_price * removedProduct.quantity -
        removedProduct.quantityExcludedOptionsTotal;

      let promotion;
      if (removedProduct.discount) {
        if (removedProduct?.discountType === "category_free") {
          promotion = NewDiscounts.category_free.find(
            (p) => p.id === removedProduct.discount
          );
        } else {
          promotion = NewDiscounts.products.find(
            (p) =>
              p.id === removedProduct.discount &&
              ((!p.has_variants && p.product_id === removedProduct.id) ||
                (p.has_variants && p.product_id === removedProduct.parent))
          );
        }
        if (promotion) promotion.quantity = removedProduct.quantity;
      }

      return {
        ...state,
        cart: newCart,
        totalPrice: newPrice,
        discounts: NewDiscounts,
      };
    case EDIT_PRODUCT_FROM_CART:
      {
        const {
          product,
          quantity,
          total_price,
          options,
          booking_slot,
          bookingSlotDetails,
          ignoreInventory,
          specialRemarks,
          resource,
          resourceSlot,
          quantityExcludedOptionsTotal,
          order_item_id,
          productIndex,
          extraFields,
          forcedPrice,
          discount,
          discountType,
        } = payload;

        let cart_item = {
          id: product.id,
          category: product.category_id,
          category_id: product.category_id,
          category_is_child: product.category_is_child,
          parent: product.parent,
          quantity: quantity,
          total_price: total_price,
          options: options,
          extraFields: extraFields,
          booking_slot: booking_slot,
          bookingSlotDetails: bookingSlotDetails,
          ignoreInventory: ignoreInventory,
          specialRemarks: specialRemarks,
          quantityExcludedOptionsTotal: quantityExcludedOptionsTotal,
          order_item_id: order_item_id,
          hasError: false,
          name: product.name,
          ar_name: product.ar_name,
          photo: product.photo_small || product.photo,
          forcedPrice: forcedPrice,
          excludeVat: product.exclude_from_vat,
          chargeDelivery: product.charge_delivery,
          discount,
          discountType,
        };

        if (product.type_of_product == "service" && resource && resourceSlot) {
          cart_item["resource"] = resource;
          cart_item["resourceSlot"] = resourceSlot;
        }

        const quantityDifference = quantity - newCart[productIndex].quantity;
        newPrice -=
          newCart[productIndex].quantity * newCart[productIndex].total_price;
        newPrice -= newCart[productIndex].quantityExcludedOptionsTotal;
        newPrice += total_price * payload.quantity;
        newPrice += quantityExcludedOptionsTotal;

        newCart[productIndex] = { ...cart_item, options: { ...options } };
        let promotion;
        if (cart_item.discount) {
          if (cart_item?.discountType === "category_free") {
            promotion = NewDiscounts.category_free.find(
              (p) => p.id === cart_item.discount
            );
          } else {
            promotion = NewDiscounts.products.find(
              (p) =>
                p.id === cart_item.discount &&
                ((!p.has_variants && p.product_id === cart_item.id) ||
                  (p.has_variants && p.product_id === cart_item.parent))
            );
          }
          if (promotion) promotion.quantity -= quantityDifference;
        }
      }

      return {
        ...state,
        cart: newCart,
        totalPrice: newPrice,
        discounts: NewDiscounts,
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
        totalPrice: 0,
        editOrder: null,

        appliedPromotions: [],
        manuallyAppliedPromotion: null,
        discounts: {
          percent: [],
          fixed: [],
          delivery: [],
          products: [],
          category: [],
          products_percent: [],
          modifier: [],
          category_free: [],
          cashback_fixed: [],
          cashback_percent: [],
        },
      };

    case HIDE_HEADER:
      return { ...state, hideHeader: !state.hideHeader };

    case COLLAPSE_SIDENAV:
      return { ...state, sideNavCollapsed: !state.sideNavCollapsed };

    case SET_CURRET_BRANCH:
      return { ...state, currentBranch: payload };

    case FETCH_ALL_BRANCH_PRODUCTS:
      let newProducts = state.branchProducts.allProducts;
      newProducts = [...newProducts, ...payload.products];
      let posDiscountsValues = [];
      let posDiscountsValuesPercentage = [];

      try {
        posDiscountsValues =
          payload?.discount?.fixed !== "" && payload?.discount?.fixed !== null
            ? payload.discount.fixed?.split(",")
            : [];

        posDiscountsValuesPercentage =
          payload.discount?.percentage !== "" &&
          payload.discount?.percentage !== null
            ? payload.discount.percentage?.split(",")
            : [];
      } catch (e) {
        console.log(e);
      }

      return {
        ...state,

        branchProducts: {
          allProducts: newProducts,
          categories: state.branchProducts.categories.length
            ? state.branchProducts.categories
            : payload.categories,
          loadingAll:
            state.branchProducts.allProducts.length + payload.products.length ==
            payload.products_count
              ? false
              : true,
          productsCount: payload.products_count,
          loadedProducts:
            state.branchProducts.allProducts.length + payload.products.length,
        },
        predefined_discounts: {
          fixed: posDiscountsValues,
          percentage: posDiscountsValuesPercentage,
        },
      };
    case SET_BRANCH_PRODUCTS_FETCH_LOADING:
      return {
        ...state,

        branchProducts: {
          loadingAll: true,
          allProducts: [],
          categories: [],
          productsCount: null,
          loadedProducts: 0,
        },
      };
    case UPDATE_USED_COMPONENTS:
      return { ...state, usedComponents: payload };

    case UPDATE_POS_BRANCH_PRODUCT:
      const pid = payload.id;

      const index9 = state.branchProducts.allProducts.findIndex(
        (product) => product.id == pid
      );
      let newAllProducts9 = state.branchProducts.allProducts;

      newAllProducts9[index9] = {
        ...payload,
        inventory_on_hand: newAllProducts9[index9].inventory_on_hand,
      };
      if (!newAllProducts9[index9].did_load)
        newAllProducts9[index9].did_load = true;

      return {
        ...state,
        branchProducts: {
          ...state.branchProducts,
          allProducts: newAllProducts9,
        },
      };

    case SET_EXTERNAL_SOURCE_FETCH_LOADING:
      return { ...state, externalSourcesLoading: true };
    case FETCH_EXTERNAL_SOURCES:
      return {
        ...state,
        externalSources: payload,
        externalSourcesLoading: false,
      };
    case ADD_EXTERNAL_SOURCE:
      return {
        ...state,
        externalSources: state.externalSources.concat(payload),
      };
    case CONTROL_EXTERNAL_SOURCE:
      const newExternalSources = state.externalSources;
      let externalSource = newExternalSources[payload.index];

      if (payload.action === "delete") {
        newExternalSources.splice(payload.index, 1);
      } else if (payload.action === "edit english") {
        externalSource.name = payload.name;
      } else if (payload.action === "edit arabic") {
        externalSource.ar_name = payload.name;
      }
      return { ...state, externalSources: newExternalSources };

    case SET_POS_BRANCH_DELIVERY_CHARGES:
      return { ...state, branchDeliveryCharges: payload };
    case OVERRIDE_ITEM_PRICE:
      newCart[payload.index].forcedPrice = payload.forcedPrice;
      return { ...state, cart: newCart };
    case FETCH_TABLES:
      return { ...state, tables: payload.tables };
    case POS_SET_ADDRESS:
      return { ...state, address: payload };
    case POS_SET_CUSTOMER:
      return { ...state, customer: payload };
    case POS_SET_GIFT:
      return { ...state, gift: payload };
    case POS_CHECK_PROMOTIONS:
      const { promotions, storeSettings, storeCategories } = payload;
      // here shouldnt be removing conditions

      shouldAddPromotion(promotions, state, storeCategories); // will check if any promotion should be added | then update based on reference
      newCart = shouldRemovePromotion(
        promotions,
        state,
        storeSettings,
        false,
        storeCategories
      ); // will check if any promotion should be removed| then update based on reference

      return { ...state, cart: newCart };

    case POS_RESET_PROMOTIONS: {
      const { promotions, storeSettings } = payload;
      newCart = shouldRemovePromotion(promotions, state, storeSettings, true); // will check if any promotion should be removed| then update based on reference
      return { ...state, cart: newCart };
    }
    case POS_SET_EDIT_ORDER: {
      return { ...state, editOrder: payload };
    }

    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: payload };

    case "ADD_PAYMENT_METHODS":
      return { ...state, paymentMethods: [...state.paymentMethods, payload] };
    case "DELETE_PAYMENT_METHODS":
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (item) => item.id !== payload
        ),
      };
    case "UPDATE_PAYMENT_METHODS":
      let newPaymentMethods = state.paymentMethods;
      let index = newPaymentMethods.findIndex((item) => item.id === payload.id);
      newPaymentMethods[index] = payload;
      return { ...state, paymentMethods: newPaymentMethods };

    case "SET_SHIPPING_LOADING":
      return { ...state, loading: !state.loading };

    case "FETCH_SHIPPING_DATA":
      return { ...state, dhl_data: payload };

    case "SET_SHIPPING_ERROR":
      return { ...state, dhl_error: payload };

    case POS_SET_MANUALLY_APPLIED_PROMOTION:
      return { ...state, manuallyAppliedPromotion: payload };

    case POS_SET_DELIVERY_CHARGE:
      if (payload.fromPage != "products" ){
      return {
        ...state,
        deliveryCharge: payload.deliveryCharge,
        deliveryChargeBackup: payload.deliveryCharge,
      };  
      }
      return { ...state, deliveryCharge: payload.deliveryCharge };
    case POS_SET_ORDER_SETTINGS:
      return { ...state, deliveryCharge: payload };

    case POS_SET_ORDER:
      return { ...state, ...payload };

    default:
      return state;
  }
}
