import {
  FETCH_PRODUCTION_WORKING_HOURS,
  FETCH_PRODUCTION_WORKING_HOURS_LOADING,
  SLOT_CONTROL,
  CHANGE_PD_DAY_START_END,
  BULK_TOGGLE_SLOTS,
  BULK_DELETE_SLOTS,
  CREATE_NEW_SLOTS,
} from "../actions/actionTypes";

const initialState = {
  productionSlots: [],
  deliverySlots: [],
  productionPresets: [],
  deliveryPresets: [],
  selectedProductionPreset: "",
  selectedDeliveryPreset: "",
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  let newProductionSlots = [];
  let newDeliverySlots = [];
  let newDayLimits = [];
  let index = null;
  switch (type) {
    case FETCH_PRODUCTION_WORKING_HOURS_LOADING:
      return { ...state, loading: true };

    case "CREATE_PRESET":
      return {
        ...state,
        productionPresets:
          payload.type === "production"
            ? [...state.productionPresets, { name: payload.name }].filter(
                (tag, index, array) =>
                  array.findIndex((t) => t.name == tag.name) == index
              )
            : state.productionPresets,
        deliveryPresets:
          payload.type === "delivery"
            ? [...state.deliveryPresets, { name: payload.name }].filter(
                (tag, index, array) =>
                  array.findIndex((t) => t.name == tag.name) == index
              )
            : state.deliveryPresets,
        selectedProductionPreset:
          payload.type === "production"
            ? payload.name
            : state.selectedProductionPreset,
        selectedDeliveryPreset:
          payload.type === "delivery"
            ? payload.name
            : state.selectedDeliveryPreset,
      };
    case "RESET_PRESET":
      return {
        ...state,
        selectedProductionPreset: payload === "working" ? "" : payload,
        selectedDeliveryPreset: payload === "delivery" ? "" : payload,
      };

    case "DELETE_PRESET":
      let newProdutionPresets = state.productionPresets;
      let newDeliveryPresets = state.deliveryPresets;
      let newSelectedProductionPreset = state.selectedProductionPreset;
      let newSelectedDeliveryPreset = state.selectedDeliveryPreset;
      if (payload.type === "working") {
        newProdutionPresets = newProdutionPresets.filter(
          (p) => p.name !== payload.name
        );
        if (payload.name === newSelectedProductionPreset)
          newSelectedProductionPreset = "";
      } else {
        newDeliveryPresets = newDeliveryPresets.filter(
          (p) => p.name !== payload.name
        );
        if (payload.name === newSelectedDeliveryPreset)
          newSelectedDeliveryPreset = "";
      }
      return {
        ...state,
        productionPresets: newProdutionPresets,
        deliveryPresets: newDeliveryPresets,
        selectedProductionPreset: newSelectedProductionPreset,
        selectedDeliveryPreset: newSelectedDeliveryPreset,
      };
    case FETCH_PRODUCTION_WORKING_HOURS:
      return {
        ...state,
        productionSlots: payload.production_slots,
        deliverySlots: payload.delivery_slots,
        dayLimits: payload.limit_days,
        productionPresets: payload.production_presets,
        deliveryPresets: payload.delivery_presets,
        selectedProductionPreset: payload.selected_production_preset,
        selectedDeliveryPreset: payload.selected_delivery_preset,
        loading: false,
      };

    case SLOT_CONTROL:
      newProductionSlots = state.productionSlots;
      newDeliverySlots = state.deliverySlots;

      if (payload.type == "production") {
        if (payload.actionType == "toggle") {
          index = newProductionSlots.findIndex(
            (slot) => slot.id == payload.sid
          );

          newProductionSlots[index].is_active =
            !newProductionSlots[index].is_active;
        } else if (payload.actionType == "delete") {
          newProductionSlots = newProductionSlots.filter(
            (slot) => slot.id != payload.sid
          );
        } else if (payload.actionType == "editLimit") {
          index = newProductionSlots.findIndex(
            (slot) => slot.id == payload.sid
          );

          newProductionSlots[index].limit = payload.newLimit;
        }
      } else if (payload.type == "delivery") {
        if (payload.actionType == "toggle") {
          index = newDeliverySlots.findIndex((slot) => slot.id == payload.sid);

          newDeliverySlots[index].is_active =
            !newDeliverySlots[index].is_active;
        } else if (payload.actionType == "delete") {
          newDeliverySlots = newDeliverySlots.filter(
            (slot) => slot.id != payload.sid
          );
        } else if (payload.actionType == "editLimit") {
          index = newDeliverySlots.findIndex((slot) => slot.id == payload.sid);

          newDeliverySlots[index].limit = payload.newLimit;
        }
      }
      return {
        ...state,
        productionSlots: newProductionSlots,
        deliverySlots: newDeliverySlots,
        selectedProductionPreset:
          payload.type === "production" ? "" : state.selectedProductionPreset,
        selectedDeliveryPreset:
          payload.type === "delivery" ? "" : state.selectedDeliveryPreset,
      };

    case CHANGE_PD_DAY_START_END:
      newProductionSlots = state.productionSlots;
      newDeliverySlots = state.deliverySlots;

      if (payload.type == "production") {
        index = newProductionSlots.findIndex((slot) => slot.id == payload.sid);
        if (payload.fieldType == "day") {
          newProductionSlots[index].day = payload.value;
        } else if (payload.fieldType == "start") {
          newProductionSlots[index].start = payload.value;
        } else if (payload.fieldType == "end") {
          newProductionSlots[index].end = payload.value;
        }
      } else if (payload.type == "delivery") {
        index = newDeliverySlots.findIndex((slot) => slot.id == payload.sid);
        if (payload.fieldType == "day") {
          newDeliverySlots[index].day = payload.value;
        } else if (payload.fieldType == "start") {
          newDeliverySlots[index].start = payload.value;
        } else if (payload.fieldType == "end") {
          newDeliverySlots[index].end = payload.value;
        }
      }
      return {
        ...state,
        productionSlots: newProductionSlots,
        deliverySlots: newDeliverySlots,
        selectedProductionPreset:
          payload.type === "production" ? "" : state.selectedProductionPreset,
        selectedDeliveryPreset:
          payload.type === "delivery" ? "" : state.selectedDeliveryPreset,
      };

    case BULK_TOGGLE_SLOTS:
      newProductionSlots = state.productionSlots;
      newDeliverySlots = state.deliverySlots;

      if (payload.type == "production") {
        payload.ids.forEach((id) => {
          index = newProductionSlots.findIndex((slot) => slot.id == id);
          newProductionSlots[index].is_active = payload.status;
        });
      } else if (payload.type == "delivery") {
        payload.ids.forEach((id) => {
          index = newDeliverySlots.findIndex((slot) => slot.id == id);
          newDeliverySlots[index].is_active = payload.status;
        });
      }
      return {
        ...state,
        productionSlots: newProductionSlots,
        deliverySlots: newDeliverySlots,
        selectedProductionPreset:
          payload.type === "production" ? "" : state.selectedProductionPreset,
        selectedDeliveryPreset:
          payload.type === "delivery" ? "" : state.selectedDeliveryPreset,
      };

    case BULK_DELETE_SLOTS:
      newProductionSlots = state.productionSlots;
      newDeliverySlots = state.deliverySlots;

      if (payload.type == "production") {
        newProductionSlots = newProductionSlots.filter(
          (slot) => !payload.ids.includes(slot.id)
        );
      } else if (payload.type == "delivery") {
        newDeliverySlots = newDeliverySlots.filter(
          (slot) => !payload.ids.includes(slot.id)
        );
      }
      return {
        ...state,
        productionSlots: newProductionSlots,
        deliverySlots: newDeliverySlots,
        selectedProductionPreset:
          payload.type === "production" ? "" : state.selectedProductionPreset,
        selectedDeliveryPreset:
          payload.type === "delivery" ? "" : state.selectedDeliveryPreset,
      };

    case CREATE_NEW_SLOTS:
      if (payload.type == "production") {
        return {
          ...state,
          productionSlots: payload.slots,
          selectedProductionPreset: "",
        };
      }
      if (payload.type == "delivery") {
        return {
          ...state,
          deliverySlots: payload.slots,
          selectedDeliveryPreset: "",
        };
      }
      return { ...state };

    default:
      return state;
  }
};
