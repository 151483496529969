import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { GetEnglishOrArabic } from "../../";
//Ant
import { Radio } from "antd";
// import {
//   LoadingOutlined,
//   MinusSquareTwoTone,
//   PlusSquareTwoTone,
//   DeleteOutlined,
// } from "@ant-design/icons";

//My Components
import OptionChoice from "./OptionChoice";
//Actions

export class OptionGroup extends Component {
  state = { radioValue: null };
  setRadioValue = (value) => {
    this.setState({ radioValue: value });
  };

  render() {
    const {
      t,
      option,
      selectedOverall,
      selectedOptions,
      config,
      productUsedComponents,
      productQuantity,
      editedProduct,
      group,
    } = this.props;

    const radioValue = this.state.radioValue;
    const choices = option.choices.map((choice, index) => (
      <OptionChoice
        key={`${group?.id || ""} ${index}-${choice.id}-${option.id}`}
        choice={choice}
        addOptionToProduct={this.props.addOptionToProduct}
        option={option}
        selectedOverall={selectedOverall}
        setRadioValue={this.setRadioValue}
        selected={
          selectedOptions[choice.id] ? selectedOptions[choice.id].quantity : 0
        }
        productUsedComponents={productUsedComponents}
        productQuantity={productQuantity}
        editedProduct={editedProduct}
        groupID={group?.id}
      />
    ));
    const required = option.is_required ? t("Required") : t("Optional");

    return (
      <>
        <h4
          className="mb-3 mt-2 mx-1 border-top border-bottom p-3"
          style={{
            fontSize: "1rem",
          }}
        >
          {t(
            `${
              group
                ? GetEnglishOrArabic(
                    group.name,
                    group.ar_name,
                    config.language
                  ) + " - "
                : ""
            } ${t("Choose")} ${GetEnglishOrArabic(
              option.name,
              option.ar_name,
              config.language
            )}`
          )}
          <span className="text-muted " style={{ fontSize: "0.8rem" }}>
            <br />
            {required} - {t("min")} : {option.minimum} {t("max")} :
            {option.maximum}
          </span>
        </h4>
        <div className="w-100">{choices}</div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ config: state.config });

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OptionGroup));
