import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Button,
  Checkbox,
  message,
  Spin,
  Switch,
  Radio,
  Tooltip,
  Popconfirm,
} from "antd";
import instance from "../../store/actions/instance";
import TalabatLogo from "./assets/talabat.png";
import { LoadingOutlined, PhoneOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#EC6707" }} spin />
);

export class Talabat extends Component {
  state = {
    loading: true,
    canDispatch: false,
    talabatDetails: null,
    coldbag_needed: false,
    error: "",
    branch_id: null,
    has_enough_balance: false,
  };

  canDispatchTalabat = async (branch_id = null) => {
    const { selectedOrder, t, settings } = this.props;
    this.setState({
      loading: true,
      talabatDetails: null,
      canDispatch: false,
      error: "",
      has_enough_balance: false,
    });
    try {
      const res = await instance.post("/can_dispatch_talabat/", {
        id: selectedOrder.id,
        branch_id: branch_id || this.state.branch_id,
      });
      if (res.data.success && res.data.details) {
        this.setState({
          canDispatch: true,
          talabatDetails: res.data.details,
          has_enough_balance: res.data.has_enough_balance,
        });
      } else if (!!res.data.message) this.setState({ error: res.data.message });
    } catch (e) {}
    this.setState({ loading: false });
  };
  componentDidMount = async () => {
    const { selectedOrder, t, settings, permissions } = this.props;
    if (!settings.enable_talabat) return;

    if (selectedOrder.shipping.is_dispatched) return;
    if (
      !settings.is_branch_based_ordering &&
      permissions.all_permitted_branches.length > 1
    ) {
      return;
    } else if (
      !settings.is_branch_based_ordering &&
      permissions.all_permitted_branches.length === 1
    ) {
      this.setState({ branch_id: permissions.all_permitted_branches[0].id });
      this.canDispatchTalabat(permissions.all_permitted_branches[0].id);
    } else this.canDispatchTalabat();
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  renderStatus = (status) => {
    return status
      .split("_")
      .map((x) => this.capitalizeFirstLetter(x.toLowerCase()))
      .join(" ");
  };

  dispatch = async () => {
    const { selectedOrder, t } = this.props;
    this.setState({ dispatchLoading: true });
    try {
      const res = await instance.post("/dispatch_talabat/", {
        id: selectedOrder.id,
        coldbag_needed: this.state.coldbag_needed,
        branch_id: this.state.branch_id || null,
      });
      if (res.data.success) {
        this.props.updateOrder(res.data.order);
        message.success(t("Order dispatched successfully"));
      } else if (res.data.message === "No balance") {
        this.props.history.push("/manage/wallet");
      }
    } catch (e) {
      message.error(t("SomethingWentWrong"));
    }

    this.setState({ dispatchLoading: false });
  };

  undispatch = async () => {
    const { selectedOrder, t } = this.props;
    this.setState({ undispatchLoading: true });
    try {
      const res = await instance.post("/undispatch_talabat/", {
        oid: selectedOrder.id,
      });
      if (res.data.success) {
        this.props.updateOrder(res.data.order);
        message.success(t("Reverted dispatch successfully"));
      } else {
        message.error(res.data.message || t("SomethingWentWrong"));
      }
    } catch (e) {
      message.error(t("SomethingWentWrong"));
    }

    this.setState({ undispatchLoading: false });
  };
  render() {
    const { selectedOrder, t, settings, permissions, config } = this.props;
    const { loading, talabatDetails } = this.state;
    if (!settings.enable_talabat && !selectedOrder.shipping.is_dispatched)
      return <></>;
    if (
      selectedOrder.shipping.is_dispatched &&
      selectedOrder.shipping.dispatcher !== "talabat"
    )
      return <></>;
    if (!selectedOrder.payment_complete) return <></>;
    return (
      <div>
        <div
          className="mt-2 mb-2"
          style={{
            //minHeight: 200,
            borderRadius: 5,
            backgroundColor: "#ec67072b",
            border: "2px solid #EC6707",
            position: "relative",
          }}
        >
          <img src={TalabatLogo} style={{ width: 261, height: 80 }} />
          <div
            style={{
              position: "relative",
              color: "gray",
              top: -25,
              [`padding${config.direction === "rtl" ? "Right" : "Left"}`]:
                config.direction === "rtl" ? 60 : 40,
            }}
          >
            {t("Support")} <PhoneOutlined />{" "}
            {settings.base_country === "Bahrain" ? "17133019" : "22245015"}
          </div>
          {!selectedOrder.shipping.is_dispatched ? (
            <div className="px-4">
              <div>{t("Get your order delivered faster by Talabat!")}</div>

              {!settings.is_branch_based_ordering &&
              permissions.all_permitted_branches.length > 1 ? (
                <div className="mb-3">
                  <p className="mt-3">{t("Select a branch to deliver from")}</p>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({ branch_id: e.target.value });
                      this.canDispatchTalabat(e.target.value);
                    }}
                    value={this.state.branch_id || undefined}
                  >
                    {permissions.all_permitted_branches.map((branch) => (
                      <Radio.Button value={branch.id}>
                        {config.language == "arabic"
                          ? branch.ar_name
                          : branch.name}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </div>
              ) : (
                <></>
              )}
              {!settings.is_branch_based_ordering &&
              permissions.all_permitted_branches.length > 1 &&
              !this.state.branch_id ? (
                <></>
              ) : loading ? (
                <div className="my-4" style={{ fontWeight: "bold" }}>
                  {t("Checking capability")}{" "}
                  <Spin indicator={antIcon} className="mx-2" />
                </div>
              ) : !!talabatDetails ? (
                <div>
                  <div style={{ marginTop: 20 }}>
                    {t("Delivery fee")}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {talabatDetails.fee.toFixed(settings.currency_decimals)}{" "}
                      {t(settings.currency)}
                    </span>
                  </div>
                  <div>
                    {t("Estimated pickup time")}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {talabatDetails.times.estimated_pickup_time}{" "}
                      {t("minutes")}
                    </span>
                  </div>
                  <div>
                    {t("Estimated delivery time")}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {talabatDetails.times.estimated_delivery_time}{" "}
                      {t("minutes")}
                    </span>
                  </div>
                  <div className="mt-3">
                    <Switch
                      className="talabatColdbag"
                      onChange={(e) => this.setState({ coldbag_needed: e })}
                    />

                    <span
                      className="mx-2"
                      style={{ position: "relative", top: 1 }}
                    >
                      {t("Coldbag needed")}
                    </span>
                  </div>

                  {this.state.has_enough_balance ? (
                    <Button
                      className="my-4"
                      type="primary"
                      style={{ background: "#ec6707", borderColor: "#ec6707" }}
                      onClick={() => this.dispatch()}
                      loading={this.state.dispatchLoading}
                    >
                      {t("Get a driver")}
                    </Button>
                  ) : (
                    <Button
                      className="my-4"
                      type="primary"
                      style={{ background: "#0099cc", borderColor: "#0099cc" }}
                      onClick={() => this.props.history.push("/manage/wallet")}
                    >
                      {t("Top-up wallet")}
                    </Button>
                  )}
                </div>
              ) : (
                <div style={{ margin: "20px 0px" }}>
                  <p>{t("Cannot dispatch this order with Talabat")}</p>
                  <p style={{ color: "red" }}>{t(this.state.error)}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="px-4 mb-4">
              <p>
                {t("Order ID")}:{" "}
                <span className="bold">
                  {selectedOrder.shipping.dispatch_reference}
                </span>
              </p>
              <p>
                {t("Delivery fee")}:{" "}
                <span className="bold">
                  {selectedOrder.shipping.talabat_delivery_fee.toFixed(
                    settings.currency_decimals
                  )}{" "}
                  {t(settings.currency)}
                </span>
              </p>
              <p>
                {t("Status")}:{" "}
                <span className="bold">
                  {this.renderStatus(selectedOrder.talabat_status)}
                </span>
              </p>
              {!!selectedOrder.shipping.driver_name ||
              !!selectedOrder.shipping.driver_phone ? (
                <p>
                  {t("Driver")}:{" "}
                  <span className="bold">
                    {selectedOrder.shipping.driver_name} (
                    {selectedOrder.shipping.driver_phone})
                  </span>
                </p>
              ) : (
                <></>
              )}

              {!!selectedOrder.shipping.estimated_delivery_time &&
              !!selectedOrder.shipping.estimated_pickup_time ? (
                <>
                  <p>
                    {t("Estimated pickup time")}:{" "}
                    <span className="bold">
                      {selectedOrder.shipping.estimated_pickup_time
                        .replace("T", " ")
                        .substring(0, 16)}
                    </span>
                  </p>
                  <p>
                    {t("Estimated delivery time")}:{" "}
                    <span className="bold">
                      {selectedOrder.shipping.estimated_delivery_time
                        .replace("T", " ")
                        .substring(0, 16)}
                    </span>
                  </p>
                </>
              ) : (
                <></>
              )}

              {!!selectedOrder.shipping.tracking_link ? (
                <p>
                  {t("Tracking link")}:{" "}
                  <span className="bold">
                    <a
                      href={selectedOrder.shipping.tracking_link}
                      target="_blank"
                    >
                      {selectedOrder.shipping.tracking_link}
                    </a>
                  </span>
                </p>
              ) : (
                <></>
              )}

              {[
                "NEW",
                "RECEIVED",
                "WAITING_FOR_TRANSPORT",
                "ASSIGNED_TO_TRANSPORT",
              ].includes(selectedOrder.talabat_status) ? (
                <Popconfirm
                  title={t("Are you sure you want to undispatch this order?")}
                  onConfirm={() => {
                    if (permissions.can_undispatch_orders) this.undispatch();
                  }}
                  disabled={!permissions.can_undispatch_orders}
                >
                  {permissions.can_undispatch_orders ? (
                    <Button
                      style={{
                        background: "red",
                        border: "1px solid red",
                        color: "white",
                        marginTop: 20,
                      }}
                      loading={this.state.undispatchLoading}
                    >
                      {t("Cancel driver request")}
                    </Button>
                  ) : (
                    <Tooltip
                      title={t(
                        "You do not have the permission to undo dispatch. Please visit the Permissions section"
                      )}
                    >
                      <Button
                        disabled={true}
                        style={{
                          marginTop: 20,
                        }}
                      >
                        {t("Cancel driver request")}
                      </Button>
                    </Tooltip>
                  )}
                </Popconfirm>
              ) : (
                <Tooltip
                  title={t(
                    "The order has been accepted by the driver. Please contact support"
                  )}
                >
                  <Button
                    disabled={true}
                    style={{
                      marginTop: 20,
                    }}
                  >
                    {t("Cancel driver request")}
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </div>

        {!selectedOrder.shipping.is_dispatched && (
          <p className="my-4">{t("Or use another provider")}</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.auth.settings,
  permissions: state.auth.permissions,
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (order) => dispatch({ type: "UPDATE_ORDER", payload: order }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(Talabat)));
