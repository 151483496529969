import React, { Component } from "react";
import { Radio, Space, Button, message, Tabs, Select } from "antd";
import instance from "../../store/actions/instance";
import { connect } from "react-redux";
import {
  updateOrdersLocallyAfterBulkActions,
  resetProductList,
} from "../../store/actions/orders";
import { withNamespaces } from "react-i18next";
import { updateCustomerBookingStatus } from "../../store/actions/customerBookings";
import { updateCustomerServiceStatus } from "../../store/actions/serviceBookings";
import { WarningOutlined } from "@ant-design/icons";
import { createSurvey } from "../../store/actions/feedbacks";

const { TabPane } = Tabs;
const { Option } = Select;


class PopoverContent extends Component {
  state = {
    radioValue: "",
    radioValue2: "",
    currentTab: "PA1",
    statusTab: "status",
    printType: "A4",
    printLanguage: "english",
    buttonLoading: false,
    sendBy: "",

  };

  getInvoiceToken = async () => {
    this.setState({ loading: true });
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_printable_token/`);
      if (res.data.success) {
        return res.data.token;
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };

  printIframe = async (type) => {
    this.setState({ invoicesLoading: true });
    const { settings, t } = this.props;
    const { printType, printLanguage } = this.state;
    try {
      let token;
      if (!this.state.printableToken) {
        token = await this.getInvoiceToken();
        this.setState({ printableToken: token });
      }
      token = this.state.printableToken;
      let res;
      const lang = printLanguage == "arabic" ? "ar" : "en";
      if (printType === "A4") {
        res = await instance.get(
          `https://${settings.domain}/manage/invoice/bulk/print/a4/${token}/${type}/?lang=${lang}&oids=${this.props.selectedKeys}`,
        );
        let iframe = document.getElementById("bulkPrintingIframe");
        iframe.srcdoc = res.data;
      } else if (printType === "Thermal") {
        let linkout = `https://${settings.domain}/manage/invoice/bulk/print/thermal/${token}/${type}/?lang=${lang}&oids=${this.props.selectedKeys}`;
        window.open(linkout, "_blank") || window.location.replace(linkout);
        this.setState({ invoicesLoading: false });
        return false;
      } else {
        let linkout = `https://${settings.domain}/manage/invoice/bulk/print/preparation/${token}/${type}/?lang=${lang}&oids=${this.props.selectedKeys}`;
        window.open(linkout, "_blank") || window.location.replace(linkout);
        this.setState({ invoicesLoading: false });

        return false;
      }
    } catch (e) {
      console.error(e);
      this.setState({ invoicesLoading: false });
      return false;
    }
    return false;
  };

  async performBulkAction() {
    this.setState({ buttonLoading: true });
    const { t } = this.props;
    try {
      const res = await instance.post(`/perform_bulk_action/`, {
        type: this.props.ordersType, //promises or orders
        ids: this.props.selectedKeys,
        status: this.state.radioValue,
        paymentStatus: this.state.radioValue2,
      });
      if (res.data.message.toLowerCase().includes("success")) {
        this.props.updateOrdersLocallyAfterBulkActions(
          this.props.ordersType,
          this.props.selectedKeys,
          this.state.radioValue,
          this.state.radioValue2,
          res.data.orders
        );
        message.success(t(res.data.message));
        this.props.clearSelectedKeys();
        if (res.data.resetProductList) {
          this.props.resetProductList();
        }

        if (this.props.ordersType == "promises") {
          this.props.switchToRecentlyReceived();
        } else {
          if (this.props.customerBookingsItems.length) {
            this.props.selectedKeys.forEach((key) => {
              if (
                this.props.customerBookingsItems.find(
                  (item) => item.order_id == key
                )
              ) {
                this.props.updateCustomerBookingStatus(
                  key,
                  this.state.radioValue
                );
              }
            });
          }

          if (this.props.serviceBookingsItems.length) {
            this.props.selectedKeys.forEach((key) => {
              if (
                this.props.serviceBookingsItems.find(
                  (item) => item.order_id == key
                )
              ) {
                this.props.updateCustomerServiceStatus(
                  key,
                  this.state.radioValue
                );
              }
            });
          }
        }
      } else {
        message.warning(res.data.message);
      }
    } catch (e) {
      console.error(e);

      message.error(t("SomethingWentWrong"));
    }

    this.setState({ buttonLoading: false, radioValue: "", radioValue2: "" });

    this.props.setPopoverInvisible();
  }

  sendSurveySMS = async (ids) => {
    const { t } = this.props;
    try {
      const res = await instance.post(`/send_sms_survey_bulk/`, {
        ids: ids,
      });
      if (res.data.success) {
        message.success(t("Sent SMS successfully"));
      } else {
        message.error(res.data.message || t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  async bulkSendSurvey() {
    const { t } = this.props;
    try {
      const res = await instance.post(`/create_survey_bulk/`, {
        ids: this.props.selectedKeys,
      });
      if (res.data.success == true) {
        this.props.createSurvey(res.data.data, true);
        message.success(t("Survey created successfully"));
        this.sendSurveySMS(res.data.ids);
      } else message.error(t(res.data.message) || t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  }

  render() {
    const {
      t,
      config,
      permissions,
      highestOrderStatus,
      settings,
      hasOrderWithMultiplePayments,
    } = this.props;
    const { editBranchOpen } = this.state;
    const statusesOrder = {
      Received: 1,
      Preparing: 2,
      "Out for Delivery": 3,
      Complete: 4,
      Cancelled: 5,
      Refunded: 6,
    };
    if (highestOrderStatus === 6)
      return <p>{t("You can't perform actions on refunded orders")}</p>;
    return (
      <>
        <iframe
          id="bulkPrintingIframe"
          style={{ display: "none" }}
          title="Receipt"
          onLoad={() => {
            if (!this.state.invoicesLoading) return;
            const iframe = document.frames
              ? document.frames["bulkPrintingIframe"]
              : document.getElementById("bulkPrintingIframe");
            const iframeWindow = iframe.contentWindow || iframe;
            iframe.focus();
            iframeWindow.print();
            this.setState({ invoicesLoading: false });
          }}
        />{" "}
        <Tabs
          activeKey={this.state.currentTab}
          defaultActiveKey={"PA2"}
          size={"medium"}
          tabPosition={"top"}
          animated={false}
          style={{ minWidth: 200 }}
          onTabClick={(key) => {
            if (key == "SW") {
              this.props.setPopoverInvisible(true);
              this.props.setEditBranchModalVisible();
            } else {
              this.setState({ currentTab: key });
            }
          }}
        >
          <TabPane tab={t("Actions")} key="PA1">
            {(this.props.permissions.can_receive_orders &&
              this.props.ordersType == "promises") ||
            ((this.props.can_edit_orders ||
              this.props.permissions.can_edit_order_payment_status) &&
              this.props.ordersType != "promises") ? (
              <div>
                <div className="mb-3">
                  <p style={{ marginRight: 20 }}>{t("Change status of")}: </p>
                  <Radio.Group
                    defaultValue=""
                    value={this.state.statusTab}
                    buttonStyle="solid"
                    onChange={(e) =>
                      this.setState({
                        statusTab: e.target.value,
                        radioValue: "",
                        radioValue2: "",
                      })
                    }
                  >
                    {((this.props.permissions.can_receive_orders &&
                      this.props.ordersType == "promises") ||
                      (this.props.permissions.can_edit_orders &&
                        this.props.ordersType != "promises")) && (
                      <Radio.Button
                        style={{ width: 100, textAlign: "center" }}
                        value="status"
                      >
                        {t("Order")}
                      </Radio.Button>
                    )}
                    {this.props.permissions.can_edit_order_payment_status && (
                      <Radio.Button
                        disabled={hasOrderWithMultiplePayments}
                        style={{ width: 100, textAlign: "center" }}
                        value="payment"
                      >
                        {t("Payment")}
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </div>

                <div
                  style={{
                    display: this.state.statusTab == "status" ? "" : "none",
                  }}
                >
                  <div className="mb-3">
                    {" "}
                    <span style={{ marginRight: 20 }}>{t("Change to")}: </span>
                  </div>

                  <Radio.Group
                    defaultValue=""
                    value={this.state.radioValue}
                    buttonStyle="solid"
                    size="medium"
                    style={{ marginLeft: 20, marginBottom: 30 }}
                    onChange={(e) =>
                      this.setState({ radioValue: e.target.value })
                    }
                  >
                    {(this.props.is_staff ||
                      (this.props.permissions &&
                        this.props.permissions.can_reverse_order_status)) &&
                    highestOrderStatus !== 6 ? (
                      <Space direction="vertical">
                        {!settings.enable_table_qr && (
                          <>
                            <Radio value="Received">{t("Received")}</Radio>
                            <Radio value="Preparing">{t("Preparing")}</Radio>
                            <Radio value="Out for Delivery">
                              {t("Delivering")}
                            </Radio>
                          </>
                        )}
                        {settings.enable_table_qr && (
                          <Radio value="Open">{t("Open")}</Radio>
                        )}
                        <Radio value="Complete">{t("Complete")}</Radio>
                        {this.props.permissions.can_cancel_orders && (
                          <Radio value="Cancelled">{t("Cancelled")}</Radio>
                        )}
                      </Space>
                    ) : (
                      Object.keys(statusesOrder).map((key) => (
                        <Radio
                          disabled={
                            statusesOrder[key] <= highestOrderStatus ||
                            highestOrderStatus === 6
                          }
                          value={key}
                        >
                          {key == "Out for Delivery" ? "Delivering" : key}
                        </Radio>
                      ))
                    )}
                  </Radio.Group>
                </div>
                <div
                  style={{
                    display: this.state.statusTab == "payment" ? "" : "none",
                  }}
                >
                  <div className="mb-3">
                    {" "}
                    <span style={{ marginRight: 20 }}>{t("Change to")}: </span>
                  </div>

                  <Radio.Group
                    defaultValue=""
                    value={this.state.radioValue2}
                    buttonStyle="solid"
                    style={{ marginLeft: 20, marginBottom: 30 }}
                    onChange={(e) =>
                      this.setState({ radioValue2: e.target.value })
                    }
                  >
                    <Space direction="vertical">
                      <Radio value="Complete">{t("PaymentComplete")}</Radio>
                      <Radio value="Incomplete">{t("PaymentIncomplete")}</Radio>
                    </Space>
                  </Radio.Group>
                </div>

                <div
                  style={{
                    textAlign: config.direction == "ltr" ? "right" : "left",
                  }}
                >
                  <Button
                    className={config.direction == "ltr" ? "mr-2" : "ml-3"}
                    onClick={() => this.props.setPopoverInvisible()}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    type="primary"
                    loading={this.state.buttonLoading}
                    onClick={() => this.performBulkAction()}
                    disabled={!this.state.radioValue && !this.state.radioValue2}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ minHeight: 100, textAlign: "center" }}>
                  <p className="mt-2">
                    <WarningOutlined
                      style={{ fontSize: 36, color: "#0099CC" }}
                    />
                  </p>
                  <p className="mb-3 pb-2">{t("Receive orders first")}</p>
                </div>

                <div
                  style={{
                    textAlign: config.direction == "ltr" ? "right" : "left",
                  }}
                >
                  <Button
                    className={config.direction == "ltr" ? "mr-2" : "ml-3"}
                    onClick={() => this.props.setPopoverInvisible()}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab={t("Print")} key="PA2">
            {!settings.print_before_receive &&
            this.props.ordersType == "promises" ? (
              <div>
                <div style={{ minHeight: 100, textAlign: "center" }}>
                  <p className="mt-2">
                    <WarningOutlined
                      style={{ fontSize: 36, color: "#0099CC" }}
                    />
                  </p>
                  <p className="mb-3 pb-2">{t("Receive orders first")}</p>
                </div>

                <div
                  style={{
                    textAlign: config.direction == "ltr" ? "right" : "left",
                  }}
                >
                  <Button
                    className={config.direction == "ltr" ? "mr-2" : "ml-3"}
                    onClick={() => this.props.setPopoverInvisible()}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <>
                  <Radio.Group
                    defaultValue="A4"
                    value={this.state.printType}
                    buttonStyle="solid"
                    size="medium"
                    onChange={(e) =>
                      this.setState({ printType: e.target.value })
                    }
                  >
                    <Radio.Button
                      style={{ width: 100, textAlign: "center" }}
                      value="A4"
                    >
                      {t("A4")}
                    </Radio.Button>
                    <Radio.Button
                      style={{ width: 100, textAlign: "center" }}
                      value="Thermal"
                    >
                      {t("Thermal")}
                    </Radio.Button>
                    <Radio.Button
                      style={{ width: 120, textAlign: "center" }}
                      value="preparation"
                    >
                      {t("Preparation")}
                    </Radio.Button>
                  </Radio.Group>
                </>{" "}
                <div className="mb-4"> </div>
                <>
                  <Radio.Group
                    defaultValue=""
                    value={this.state.printLanguage}
                    buttonStyle="solid"
                    onChange={(e) =>
                      this.setState({ printLanguage: e.target.value })
                    }
                  >
                    <Radio.Button
                      style={{ width: 100, textAlign: "center" }}
                      value="english"
                    >
                      {t("English")}
                    </Radio.Button>
                    <Radio.Button
                      style={{ width: 100, textAlign: "center" }}
                      value="arabic"
                    >
                      {t("Arabic")}
                    </Radio.Button>
                  </Radio.Group>
                </>
                <div className="mb-3"> </div>
                <div className="mb-3"> </div>
                <div
                  style={{
                    textAlign: config.direction == "ltr" ? "right" : "left",
                  }}
                >
                  <Button
                    className={config.direction == "ltr" ? "mr-2" : "ml-3"}
                    onClick={() => this.props.setPopoverInvisible()}
                  >
                    {t("Cancel")}
                  </Button>
                  {settings.print_before_receive &&
                  this.props.ordersType == "promises" ? (
                    <Button
                      type="primary"
                      loading={this.state.invoicesLoading}
                      onClick={() => this.printIframe("promise")}
                      disabled={
                        !this.state.printType && !this.state.printLanguage
                      }
                    >
                      {t("Print")}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      loading={this.state.invoicesLoading}
                      onClick={() => this.printIframe("order")}
                      disabled={
                        !this.state.printType && !this.state.printLanguage
                      }
                    >
                      {t("Print")}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </TabPane>
          {permissions.can_transfer_orders && (
            <TabPane tab={t("Transfer")} key="SW"></TabPane>
          )}
          {settings.enable_sms && (
            <TabPane tab={t("Survey")} key="SUR" style={{ maxWidth: "321px" }}>
              {this.props.ordersType == "promises" ? (
                <div>
                  <div style={{ minHeight: 100, textAlign: "center" }}>
                    <p className="mt-2">
                      <WarningOutlined
                        style={{ fontSize: 36, color: "#0099CC" }}
                      />
                    </p>
                    <p className="mb-3 pb-2">{t("Receive orders first")}</p>
                  </div>

                  <div
                    style={{
                      textAlign: config.direction == "ltr" ? "right" : "left",
                    }}
                  >
                    <Button
                      className={config.direction == "ltr" ? "mr-2" : "ml-3"}
                      onClick={() => this.props.setPopoverInvisible()}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <>
                    <p>{t("Send Surveys by SMS")}</p>
                    <p style={{ fontSize: 12, color: "#999", marginTop: 7 }}>
                      {t(
                        `"Share your experience with us, it will take a few seconds and would really help us improve {survey link}"`
                      )}
                    </p>
                  </>

                  <div
                    style={{
                      textAlign: config.direction == "ltr" ? "right" : "left",
                    }}
                  >
                    <Button
                      className={config.direction == "ltr" ? "mr-2" : "ml-3"}
                      onClick={() => this.props.setPopoverInvisible()}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      type="primary"
                      loading={this.state.invoicesLoading}
                      onClick={() => this.bulkSendSurvey()}
                    >
                      {t("Send")}
                    </Button>
                  </div>
                </div>
              )}
            </TabPane>
          )}
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.auth.permissions,
    config: state.config,
    settings: state.auth.settings,

    is_staff: state.auth.is_staff,
    customerBookingsItems: state.customerBookings.orderItems,
    serviceBookingsItems: state.serviceBookings.orderItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrdersLocallyAfterBulkActions: (
      type,
      keys,
      status,
      paymentStatus,
      newOrders
    ) =>
      dispatch(
        updateOrdersLocallyAfterBulkActions(
          type,
          keys,
          status,
          paymentStatus,
          newOrders
        )
      ),
    updateCustomerBookingStatus: (order_id, status) =>
      dispatch(updateCustomerBookingStatus(order_id, status)),
    updateCustomerServiceStatus: (order_id, status) =>
      dispatch(updateCustomerServiceStatus(order_id, status)),
    resetProductList: () => dispatch(resetProductList()),
    createSurvey: (survey, isBulk) => dispatch(createSurvey(survey, isBulk)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PopoverContent));
