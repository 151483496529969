import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import "antd/dist/antd.less";
import { checkForExpiredToken } from "./store/actions/auth";
import "./i18n";
import * as Sentry from "@sentry/browser";
store.dispatch(checkForExpiredToken());

// if (process.env.NODE_ENV === "production")
//   Sentry.init({
//     dsn:
//       "https://2555e45eb51a4c41b1a35dbfc5798c77@o398045.ingest.sentry.io/5253185",
//   });

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        {/* <ConfigProvider direction="rtl"> */}
        <App />
        {/* </ConfigProvider> */}
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
