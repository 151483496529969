const trans = {
  "Delivery fee": "سعر التوصيل",
  Driver: "السائق",
  "Get your order delivered faster by Talabat!":
    "قم بتوصيل الطلب بسرعة مع طلبات!",
  "Cannot dispatch this order with Talabat": "لا يمكن توصيل هذا الطلب مع طلبات",
  "Or use another provider": "أو قم باستخدام موفر آخر",
  "The customer did not assign their location on the map":
    "لم يقم العميل بتحديد موقعهم على الخريطة",
  "Checking capability": "جاري التحقق من امكانية التوصيل",
  "Estimated pickup time": "الوقت المتوقع لاستلام الطلب",
  "Estimated delivery time": "الوقت المتوقع لتوصيل الطلب",
  "Coldbag needed": "بحاجة حقيبة تبريد",
  "Top-up wallet": "تعبئة رصيد",
  "Get a driver": "طلب سائق",
  Support: "الدعم",
  "Refund to wallet": "ارجاع النقود الى المحفظة",
  "Cancel driver request": "الغاء طلب السائق",
  "Are you sure you want to undispatch this order?":
    "هل انت متأكد من الغاء طلب السائق؟",
  "The order has been accepted by the driver. Please contact support":
    "لقد قام السائق بقبول الطلب ولا يمكنك الغاؤه الآن. الرجاء التواصل مع الدعم",
};

export default trans;
