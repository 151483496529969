import {
  FETCH_ORDER_ITEMS_LOADING,
  FETCH_ORDER_ITEMS,
  SAVE_BOOKING_ORDER,
  SOCKET_NEW_BOOKING_ORDER,
  SOCKET_REFETCH_BOOKING_ORDER,
  UPDATE_CUSTOMER_BOOKING_STATUS,
  EDIT_BOOKING_DATE_AND_TIME,
} from "../actions/actionTypes";

const initialState = {
  orderItems: [],
  products: [],
  loading: false,
  didFullyLoad: false,
  newItem: null,
  shouldRefetch: false,
};

export default (state = initialState, { type, payload, asyncDispatch }) => {
  let newOrderItems = null;
  let index = null;
  let bookingIndex = null; 

  switch (type) {
    case FETCH_ORDER_ITEMS_LOADING:
      return { ...state, loading: true };

    case FETCH_ORDER_ITEMS:
      return {
        ...state,
        orderItems: payload.items,
        products: payload.products,
        loading: false,
        didFullyLoad: true,
      };

    case SAVE_BOOKING_ORDER:
      newOrderItems = state.orderItems;
      bookingIndex = payload.bookingIndex
      index = newOrderItems[bookingIndex].findIndex((oi) => oi.order__tracking_id == payload.bookingID);
      newOrderItems[bookingIndex][index].order_obj = payload.orderObj;
      newOrderItems[bookingIndex][index].order__payment_method = payload.orderObj.payment_method;
      return {
        ...state,
        orderItems: newOrderItems,
      };

    case SOCKET_REFETCH_BOOKING_ORDER: 
      return {...state, shouldRefetch: payload}

    case SOCKET_NEW_BOOKING_ORDER:
      newOrderItems = state.orderItems;
      if (state.didFullyLoad) newOrderItems = [payload, ...newOrderItems];
      if (window.location.pathname == "/manage/bookings")
        window.setTimeout(() => {
          asyncDispatch((dispatch) => dispatch({ type: "RESET_NEW_ITEM" }));
        }, 7000);
      return {
        ...state,
        orderItems: newOrderItems,
        newItem: payload.id,
      };

    case "RESET_NEW_ITEM":
      return { ...state, newItem: null };

    case UPDATE_CUSTOMER_BOOKING_STATUS:
      newOrderItems = state.orderItems;

      index = newOrderItems.findIndex(
        (item) => item.order_id == payload.order_id
      );

      if (index >= 0) {
        newOrderItems[index].order_status = payload.status;
      }

      return { ...state, orderItems: newOrderItems };

    case EDIT_BOOKING_DATE_AND_TIME:
      newOrderItems = state.orderItems;
      bookingIndex = payload.bookingIndex
     
      index = newOrderItems[bookingIndex].findIndex(
        (item) => item.id == payload.orderItem.id
      );

      if (index >= 0) {
        newOrderItems[bookingIndex][index].booking_original_slot_id = payload.orderItem.booking_original_slot_id;
        newOrderItems[bookingIndex][index].booking_date = payload.orderItem.booking_date;
        newOrderItems[bookingIndex][index].booking_start = payload.orderItem.booking_start;
        newOrderItems[bookingIndex][index].booking_end = payload.orderItem.booking_end;

      }

      return { ...state, orderItems: newOrderItems };
    default:
      return state;
  }
};
