import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import { Modal, Input, Row, Col, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Actions

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("Image must smaller than 5MB!");
  }
  return isJpgOrPng && isLt2M;
}
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class NewTemplateModal extends Component {
  state = {
    saveLoading: false,

    imageURL: null,
    fileList: [],
    name: "",
  };

  createDistributor = async () => {
    this.setState({ saveLoading: true });
    const { t } = this.props;
    const s = this.state;
    const formData = new FormData();
    if (s.fileList.length) {
      formData.append("file", s.fileList[0]);
    }
    Object.keys(s).forEach((key) => formData.append(key, s[key]));
    try {
      const res = await instance.post(`/create_gift_template/`, formData);
      if (res.data.success == true) {
        message.success(t("Template created successfully"));
        this.props.onCreate(res.data.template);
      } else message.error(t(res.data.message) || t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
    this.props.onClose();
  };

  handleImageChange = (info) => {
    if (beforeUpload(info.file)) {
      getBase64(info.file, (imageUrl) =>
        this.setState({
          imageURL: imageUrl,
        })
      );
    }
  };

  saveButtonDisabled = () => {
    if (
      !this.state.name ||
      !this.state.imageURL ||
      !this.state.fileList?.length
    )
      return true;
    return false;
  };

  render() {
    const { config, t } = this.props;
    const { imageURL } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">{t("Upload")}</div>
      </div>
    );
    return (
      <Modal
        style={{ top: 20 }}
        width={500}
        destroyOnClose={true}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("New template")}
          </div>
        }
        visible={true}
        onCancel={() => this.props.onClose()}
        onOk={() => {
          this.createDistributor();
        }}
        okText={t("Save")}
        cancelText={t("Close")}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
        okButtonProps={{
          loading: this.state.saveLoading,
          disabled: this.saveButtonDisabled(),
        }}
      >
        <div className="px-2 my-3 ">
          <div className="my-3 text-center">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => {
                this.setState({ fileList: [file] });
                return false;
              }}
              onChange={this.handleImageChange}
            >
              {imageURL ? (
                <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>

          <div className="mt-3">
            <Row>
              <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                <p>{t("Name")}</p>
              </Col>
              <Col xl={16} lg={16} md={16} sm={16} xs={24}>
                <Input
                  placeholder={t("Template name")}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  style={{ float: "right", direction: config.direction }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewTemplateModal));
