const trans = {
  Home: "الرئيسية",
  "Quick Access": "اختصارات",
  "News Center": "الأخبار",
  "Write a comment": "اكتب تعليق",
  "Your comment": "تعليقك",
  "Posted your comment successfuly": "تم كتابة التعليق بنجاح",
  "You commented:": "تعليقك:",
  "Are you sure you want to delete this comment?":
    "هل انت متأكد من حذف هذا التعليق؟",
  "Posted on:": "نشر: ",
  Post: "انشر",
  "pending orders": "طلبات جديدة",
  "Hello,": "مرحبا،",
  "Last 7 days": "آخر 7 أيام",
  "Yesterday":"الامس",
  "Last 30 days": "آخر 30 يوم",
  Today: "اليوم",
  "Daily Sales": "المبيعات اليومية",
  "# Orders": "عدد الطلبات",
  "News & Updates": "الأخبار والتحديثات",
  "All Branches": "جميع الأفرع",
  Returning: "عملاء متكررين",
  "Avg rating": "معدل التقييمات",
  Feedbacks: "آراء",
  "Repeat Customer": "عملاء متكررين",
  "New Customer": "عملاء جدد",
  Checks: "التحققات",
  "You have": "لديك",
  "major issues": "مشاكل طارئة",
  warnings: "تحذيرات",
  "Add a product": "اضف منتج",
  "Fill in your branch details": "اضف تفاصيل فرعك",
  "Add a payment method": "اضف طريقة دفع",
  "Add a delivery provider": "اضف موفر توصيل",
  "*** of *** items complete": "*** من *** مكتمل",
  "Quick Start Guide": "دليل البدء السريع",
  "Set your working hours": "حدد ساعات عملك",
};

export default trans;
