import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
import moment from "moment";
// Antd Components
import { Modal, Select, message, TimePicker, DatePicker } from "antd";
import { editExpectedDelivery } from "../../store/actions/orders";

// My components

// Actions

const { Option } = Select;
const { RangePicker } = TimePicker;

class DownloadModal extends Component {
  state = {
    saveLoading: false,
    start: moment(
      this.props.selectedOrder.expected_delivery_slot_start,
      "HH:mm"
    ),
    end: moment(this.props.selectedOrder.expected_delivery_slot_end, "HH:mm"),
    date: moment(this.props.selectedOrder.expected_delivery_date, "YYYY-MM-DD"),
  };

  save = async () => {
    this.setState({ saveLoading: true });
    const { t, selectedOrder, user } = this.props;
    const { start, end, date } = this.state;
    try {
      const res = await instance.post("/edit_expected_delivery/", {
        start: start.format("HH:mm:ss"),
        end: end.format("HH:mm:ss"),
        date: date.format("YYYY-MM-DD"),
        id: selectedOrder.id,
      });

      if (res.data.success == true) {
        this.props.editExpectedDelivery(
          selectedOrder.id,
          start.format("HH:mm:ss"),
          end.format("HH:mm:ss"),
          date.format("YYYY-MM-DD"),
          user
        );
        this.props.refetchLastEditedBy();
        message.success(t("Edited successfully"));
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
    this.props.onClose();
  };

  render() {
    const { visible, config, t, downloadableType } = this.props;
    const { start, end, date } = this.state;
    return (
      <>
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Edit expected delivery")}
            </div>
          }
          visible={visible}
          onCancel={() => this.props.onClose()}
          onOk={() => this.save()}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: this.state.saveLoading,
          }}
          width={300}
        >
          <div className="px-2 mb-3 pt-3">
            <p>{t("Please select a date")}</p>
            <DatePicker
              onChange={(dt, dateStr) => this.setState({ date: dt })}
              defaultValue={date}
              allowClear={false}
            />
            <p className="mt-3">{t("Please select start and end")}</p>
            <RangePicker
              format={"HH:mm"}
              allowClear={false}
              order={false}
              onChange={(range, rangeStr) =>
                this.setState({
                  start: range[0],
                  end: range[1],
                })
              }
              defaultValue={[start, end]}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    user: state.auth.username,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editExpectedDelivery: (id, start, end, date, user) =>
      dispatch(editExpectedDelivery(id, start, end, date, user)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(DownloadModal));
