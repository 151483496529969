const trans = {
  Wallet: "المحفظة",
  "Your transactions": "معاملاتك السابقة",
  User: "المستخدم",
  "Talabat delivery": "توصيل طلبات",
  "Top-up": "تعبئة رصيد",
  "Your balance": "رصيدك الحالي",
  "Quick top-up": "تعبئة رصيد سريع",
  "Other ": "آخر",
  "Please enter the amount": "الرجاء ادخال المبلغ",
  "Minimum is": "اقل مبلغ",
  Pay: "الدفع",
  "Balance is non-refundable": "لا يمكن استرجاع الرصيد المتبقي",
  cashback: "مبلغ مسترد",
  topup: "تعبئة رصيد",
  manual: "يدوي",
  Cashback: "مبلغ مسترد",
  Topup: "تعبئة رصيد",
  Manual: "يدوي",
  "Are you sure you want to cancel the topup?":
    "هل انت متأكد من الغاء التعبئة؟",
};

export default trans;
