import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
// Antd Components
import { Modal, message, Input } from "antd";
import { editSpecialRemarks } from "../../store/actions/orders";

// My components

// Actions

class EditSpecialRemarks extends Component {
  state = {
    saveLoading: false,
    specialRemarks: this.props.selectedOrder.special_remarks,
  };

  save = async () => {
    this.setState({ saveLoading: true });
    const { t, selectedOrder, user } = this.props;
    const { specialRemarks } = this.state;
    try {
      const res = await instance.post("/edit_special_remarks/", {
        specialRemarks: specialRemarks,
        id: selectedOrder.id,
      });

      if (res.data.success == true) {
        this.props.editSpecialRemarks(selectedOrder.id, specialRemarks, user);
        this.props.refetchLastEditedBy();
        message.success(t("Edited successfully"));
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
    this.props.onClose();
  };

  render() {
    const { visible, config, t, selectedOrder } = this.props;
    const { specialRemarks } = this.state;

    return (
      <>
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Edit Special Remarks")}
            </div>
          }
          visible={visible}
          onCancel={() => this.props.onClose()}
          onOk={() => this.save()}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: this.state.saveLoading,
            disabled: specialRemarks === selectedOrder.special_remarks,
          }}
          width={300}
        >
          <div className="px-2 mb-3 pt-3 text-center">
            <Input
              placeholder={t("Special Remarks")}
              onChange={(e) =>
                this.setState({ specialRemarks: e.target.value })
              }
              defaultValue={specialRemarks}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    user: state.auth.username,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editSpecialRemarks: (id, specialRemarks, user) =>
      dispatch(editSpecialRemarks(id, specialRemarks, user)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EditSpecialRemarks));
