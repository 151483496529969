import {
  FETCH_POPUP_BANNERS,
  CREATE_POPUP_BANNER,
  TOGGLE_POPUP_BANNER,
  DELETE_POPUP_BANNER,
  CHANGE_POPUP_BANNER_WINDOW_TYPE,
} from "../actions/actionTypes";

const initialState = {
  banners: [],
};

export default (state = initialState, { type, payload }) => {
  let newBanners = [];
  let index;
  switch (type) {
    case FETCH_POPUP_BANNERS:
      return { ...state, banners: payload || [] };

    case TOGGLE_POPUP_BANNER:
      newBanners = state.banners;
      index = newBanners.findIndex((p) => p.id === payload.id);
      newBanners[index].is_active = payload.status;
      return { ...state, banners: newBanners };

    case DELETE_POPUP_BANNER:
      newBanners = state.banners.filter((p) => p.id !== payload.id);
      return { ...state, banners: newBanners };

    case CHANGE_POPUP_BANNER_WINDOW_TYPE:
      newBanners = state.banners;
      index = newBanners.findIndex((p) => p.id === payload.id);
      newBanners[index].window_type = payload.type;
      return { ...state, banners: newBanners };

    case CREATE_POPUP_BANNER:
      newBanners = state.banners;
      index = newBanners.findIndex((p) => p.id == payload.id);
      if (index >= 0) {
        newBanners[index] = payload;
      } else newBanners = [payload, ...state.banners];
      return { ...state, banners: newBanners };

    default:
      return state;
  }
};
