const trans = {
  "Abandoned Carts": "السلات المهملة",
  "Cart items": "المنتجات",
  "Enable automatic re-targeting via email":
    "تفعيل اعادة الاستهداف التلقائي عبر الايميل",
  "Re-target carts after": "اعادة الاستهداف بعد",
  "6 hours": "6 ساعات",
  "1 hour": "ساعة",
  "10 hours": "10 ساعات",
  "24 hours": "24 ساعة",
  "from their last activity, if their cart value is":
    "من اخر نشاط، اذا كانت قيمة السلة",
  "Greater than": "اكبر من",
  "Greater than or equal": "اكبر من او تساوي",
  "Less than": "اصغر من",
  "Less than or equal": "اصغر من او تساوي",
  Equals: "تساوي",
  "Re-targeting rules": "قواعد اعاة الاستهداف",
  "Re-targeting Email Custom Text": "تغيير نص ايميل اعادة الاستهداف",
  "Email Body English": "نص الايميل باللغة الانجليزية",
  "Email Body Arabic": "نص الايميل باللغة العربية",
  "Last activity": "اخر نشاط",
  "Cart created": "تاريخ الانشاء",
  "Cart value": "قيمة السلة",
  "Re-target": "اعادة الاستهداف",
  Items: "المنتجات",
  Link: "الرابط",
  Carts: "السلات",
  Overview: "ملخص",
  "Re-targeted carts": "تم اعادة استهدافها",
  "Converted carts": "تم تحويلها",
  "Conversion rate": "معدل التحويل",
  "Share cart": "مشاركة السلة",
  "Use the same address": "استخدام نفس العنوان",
  "Use a new address": "استخدام عنوان جديد",
  "Enable automatic re-targeting via sms":
    "تفعيل اعادة الاستهداف التلقائي عبر الرسائل النصية",
  "Enable automatic re-targeting via whatsapp":
    "تفعيل اعادة الاستهداف التلقائي عبر الواتساب",
};

export default trans;
