import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import { Modal, message,Input,Select } from "antd";
import { editTable } from "../../store/actions/orders";

// My components

// Actions
const { Option } = Select;
class EditTableNumberModal extends Component {
  state = {
    saveLoading: false,
    loading:true,
    tableNumber: this.props.selectedOrder.parking_number,
    tables:[]
  };

  loadTables = async () => {
    const { t } = this.props;
    try {
      const res = await instance.get("/fetch_tables/");
      if (res.data.success)
        this.setState({ tables: res.data.tables });
      else message.error(t("SomethingWentWrong"));
    } catch (e) {
      message.error(t("SomethingWentWrong"));
    }
    this.setState({ loading: false });
  };
  componentDidMount = () => {
    this.loadTables();
  };

  save = async () => {
    this.setState({ saveLoading: true });
    const { t, selectedOrder,user } = this.props;
    const { tableNumber } = this.state;
    try {
      const res = await instance.post("/edit_table_number/", {
        id: selectedOrder.id,
        tableNumber: tableNumber,
      });
console.log(tableNumber,"table")
      if (res.data.success == true) {
        this.props.editTable(
            selectedOrder.id,
            tableNumber,
            user,
          );
        this.props.refetchLastEditedBy();
        message.success(t("Edited successfully"));
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
    this.props.onClose();
  };
  render() {
    const { visible, config, t, selectedOrder } = this.props;
    const { tableNumber,tables } = this.state;

    return (
        <>
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Edit Table Number")}
            </div>
          }
          visible={visible}
          onCancel={() => this.props.onClose()}
          onOk={() => this.save()}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: this.state.saveLoading,
            disabled: tableNumber === selectedOrder.parking_number,
          }}
          width={300}
        >
          <div className="px-2 mb-3">
            <div className="mt-3 mb-4">
              <p>{t("Table Number")} </p>

              <Select
                showSearch
                loading={this.state.loading}
                value={(!this.loadTables ) || undefined}
                style={{ width: "100%" }}
                placeholder={t("Select a Table Number")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  this.setState({ tableNumber: e });
                }}
              >
                {tables.length
                  ? tables.map((table) => (
                      <Option value={table.display_name}>
                        {table.display_name}
                      </Option>
                    ))
                  : null}
              </Select>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    branches: state.locations.branches,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editTable: (id, tableNumber, user) => dispatch(editTable(id, tableNumber, user)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EditTableNumberModal));
