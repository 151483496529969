const trans = {
  "Scan QR Code": "مسح رمز QR",
  "Attend all": "اختيار الجميع",
  Slot: "الخانة",
  Qty: "العدد",
  Attended: "تم الحضور",
  "Save changes": "حفظ التغييرات",
  "Mark attended": "اتمام الحضور",
  "Cancel attendance": "الغاء الحضور",
  Attendees: "الحضور",
  "Booking can't be found": "لم يتم العثور على الحجز",
  "Take attendance": "تسجيل الحضور",
  "Order does not have bookings": "هذا الطلب لا يحتوي على حجوزات",
  UNPAID: "غير مدفوع",
  PAID: "مدفوع",
};
export default trans;
