import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal, Button, Spin, message, Tag } from "antd";
import moment from "moment";
import instance from "../../store/actions/instance";
import {
  LoadingOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const styles = {
  productContainer: {
    marginBottom: "14px",
    border: "1px solid #ddd",
    //padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  productInfo: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column", // Adjust layout for mobile screens
  },
  productText: {
    marginBottom: "5px",
    wordWrap: "break-word", // Ensures text wraps on small screens
  },
  optionsContainer: {
    paddingLeft: "10px",
    borderTop: "1px solid #eee",
    paddingTop: "10px",
    marginBottom: 10,
  },
  optionItem: {
    padding: "5px 0",
  },
  optionText: {
    wordWrap: "break-word", // Ensures text wraps on small screens
  },
};

class QRScanOrderModal extends Component {
  state = {
    items: null,
    loading: false,
    changes: [],

    attendance: null,
    type: null,
    paymentComplete: false,
  };

  getOrderDetails = async () => {
    this.setState({ loading: true });
    try {
      const res = await instance.get(
        `/get_order_details/${this.props.order.type.toLowerCase()}/${
          this.props.order.id
        }/`
      );
      if (res.data.items)
        this.setState({
          // items: res.data.items,
          items: res.data.items.filter((i) => i.type_of_product === "bookable"),
        });
    } catch (e) {
      console.error(e);
    }
  };

  getAttendanceDetails = async () => {
    try {
      const res = await instance.post("/get_attendance_details/", {
        tracking_id: this.props.order.tracking_id,
      });
      if (res.data.success) {
        this.setState({
          attendance: res.data.data,
          type: res.data.type,
          loading: false,
          paymentComplete: res.data.payment_complete,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  componentDidMount = () => {
    this.getOrderDetails();
    this.getAttendanceDetails();
  };

  increaseAttendance = (att, item) => {
    let tempChanges = this.state.changes;
    if (this.getTempAttended(item) >= item.quantity) return;
    const index = this.state.changes.findIndex((c) => c.id === att.id);
    if (index >= 0 && tempChanges[index].attended_count < item.quantity) {
      tempChanges[index].attended_count += 1;
    } else {
      tempChanges.push({
        id: att.id,
        attended_count: this.getTempAttended(item) + 1,
      });
    }

    let finalTempChanges = [];
    tempChanges.forEach((c) => {
      if (
        this.state.attendance.filter((a) => a.id === c.id)[0].attended_count !==
        c.attended_count
      )
        finalTempChanges.push(c);
    });
    this.setState({ changes: finalTempChanges });
  };

  decreaseAttendance = (att, item) => {
    let tempChanges = this.state.changes;
    if (this.getTempAttended(item) === 0) return;
    const index = this.state.changes.findIndex((c) => c.id === att.id);
    if (index >= 0 && tempChanges[index].attended_count > 0) {
      tempChanges[index].attended_count -= 1;
    } else {
      tempChanges.push({
        id: att.id,
        attended_count: this.getTempAttended(item) - 1,
      });
    }

    let finalTempChanges = [];
    tempChanges.forEach((c) => {
      if (
        this.state.attendance.filter((a) => a.id === c.id)[0].attended_count !==
        c.attended_count
      )
        finalTempChanges.push(c);
    });
    this.setState({ changes: finalTempChanges });
  };

  isEntireOrderAttended = () => {
    return (
      this.state.attendance?.every((x) => x.fully_attended) &&
      !!this.state.attendance?.length
    );
  };

  isItemFullyAttended = (item) => {
    const att = this.state.attendance.filter(
      (x) => x.promise_item_id === item.id || x.order_item_id === item.id
    )[0];
    return att.fully_attended || att.attended_count >= item.quantity;
  };

  markItemFullyAttended = (item) => {
    let tempChanges = this.state.changes || [];
    const att = this.state.attendance.filter(
      (x) => x.promise_item_id === item.id || x.order_item_id === item.id
    )[0];
    const index = tempChanges.findIndex((x) => x.id === att.id);
    if (index >= 0) {
      tempChanges[index].attended_count = item.quantity;
    } else {
      tempChanges.push({ id: att.id, attended_count: item.quantity });
    }
    this.setState({ changes: tempChanges });
  };

  markOrderFullyAttended = async () => {
    const { t } = this.props;
    this.setState({ markingFullyLoading: true });
    try {
      const res = await instance.post("/mark_order_fully_attended/", {
        tracking_id: this.props.order.tracking_id,
      });
      if (res.data.success) {
        message.success(t("Saved successfully"));
        let newAttendance = this.state.attendance;
        this.setState({
          attendance: [
            { ...newAttendance[0], attended_count: 1, fully_attended: true },
          ],
        });
        this.props.onClose();
      } else {
        message.error("Something wrong happened");
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ markingFullyLoading: false });
  };

  doesOrderHaveMultipleBookings = () => {
    if (!this.state.items?.length) return false;
    return (
      this.state.items?.length > 1 ||
      this.state.items.some((i) => i.quantity > 1)
    );
  };

  getTempAttended = (item) => {
    if (!this.state.attendance?.length) return 0;
    const att = this.state.attendance.filter(
      (x) => x.promise_item_id === item.id || x.order_item_id === item.id
    )[0];
    if (this.state.changes.filter((c) => c.id === att.id)?.length)
      return this.state.changes.filter((c) => c.id === att.id)[0]
        ?.attended_count;
    return att.attended_count;
  };

  saveChanges = async () => {
    const { t } = this.props;
    this.setState({ saveChangesLoading: true });
    try {
      const res = await instance.post("/save_attendance_changes/", {
        tracking_id: this.props.order.tracking_id,
        changes: this.state.changes,
      });
      if (res.data.success) {
        message.success(t("Saved successfully"));
        this.setState({ attendance: res.data.data, changes: [] });
        this.props.onClose();
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ saveChangesLoading: false });
  };

  selectAll = () => {
    const { attendance, changes, items } = this.state;
    let tempChanges = [];
    items.forEach((item) => {
      const tempAtt = attendance.filter(
        (c) => c.order_item_id === item.id || c.promise_item_id === item.id
      )[0];
      tempChanges.push({ id: tempAtt.id, attended_count: item.quantity });
    });
    this.setState({ changes: tempChanges });
  };
  selectAllButtonDisabled = () => {
    const { items } = this.state;
    return items.every((item) => this.getTempAttended(item) === item.quantity);
  };
  render() {
    const { settings, config, t, order } = this.props;
    return (
      <Modal
        destroyOnClose={true}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Booking details")}{" "}
            {this.isEntireOrderAttended() && (
              <Tag color="green">{t("Attended")}</Tag>
            )}
          </div>
        }
        visible={true}
        onCancel={() => {
          this.props.onClose();
        }}
        onOk={() => this.save()}
        cancelText={t("Close")}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        width={"95%"}
      >
        {!this.state.loading && !this.state.items?.length ? (
          <div className="px-2 mb-3 pt-3">
            <p>{t("Order does not have bookings")}</p>
          </div>
        ) : (
          <div className="px-2 mb-3 pt-3">
            <p>
              <span className="bold">{t("Tracking ID")}:</span>{" "}
              {order.tracking_id}
            </p>
            <p>
              <span className="bold">{t("Placed")}:</span>{" "}
              {moment(order.placed, "YYYY-MM-DDTHH:mm:ss")
                .format("YYYY-MM-DD h:mm A")
                .replace(/AM/g, config.language === "arabic" ? "ص" : "AM")
                .replace(/PM/g, config.language === "arabic" ? "م" : "PM")}
            </p>
            <p>
              <span className="bold">{t("Customer")}:</span>{" "}
              {order.customer_name} ({order.phone_number})
            </p>
            <p>
              <span className="bold">{t("Payment")}:</span>{" "}
              {this.state.paymentComplete ? (
                <span style={{ color: "green" }}>{t("PAID")}</span>
              ) : (
                <span style={{ color: "red" }}>{t("UNPAID")}</span>
              )}
            </p>
            <div className="mb-2 mt-4">
              <span style={{ fontSize: 20 }}>
                {config.language === "arabic" ? "الحجوزات" : "Bookings"}
              </span>{" "}
              {this.doesOrderHaveMultipleBookings() && (
                <span
                  style={{
                    float: config.direction === "rtl" ? "left" : "right",
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => this.selectAll()}
                    disabled={this.selectAllButtonDisabled()}
                  >
                    {t("Attend all")}
                  </Button>
                </span>
              )}
            </div>
            {this.state.loading || !this.state.items ? (
              <div className="text-center my-3">
                <Spin indicator={antIcon} />
              </div>
            ) : (
              <div>
                {this.state.items.map((item) => (
                  <div key={item.id} style={styles.productContainer}>
                    <div style={styles.productInfo}>
                      <div style={{ padding: 10 }}>
                        <div style={styles.productText}>
                          <strong>{t("Name")}:</strong>{" "}
                          {config.language === "arabic"
                            ? item.name_ar || item.name
                            : item.name}
                        </div>
                        <div style={styles.productText}>
                          <strong>{t("Slot")}:</strong>{" "}
                          {order.booking_is_all_day
                            ? "All day"
                            : `${item.booking_date} (${moment(
                                item.booking_start,
                                "HH:mm:ss"
                              ).format("h:mm A")} - ${moment(
                                item.booking_end,
                                "HH:mm:ss"
                              ).format("h:mm A")})`
                                .replace(
                                  /AM/g,
                                  config.language === "arabic" ? "ص" : "AM"
                                )
                                .replace(
                                  /PM/g,
                                  config.language === "arabic" ? "م" : "PM"
                                )}
                        </div>
                        <div style={styles.productText}>
                          <strong>{t("Qty")}:</strong> {item.quantity}
                        </div>
                      </div>
                    </div>
                    {!!item.options.length && (
                      <div
                        style={{
                          [config.direction === "rtl"
                            ? "paddingRight"
                            : "paddingLeft"]: "10px",
                          borderTop: "1px solid #eee",
                          paddingTop: "10px",
                          marginBottom: 10,
                        }}
                      >
                        <strong>{t("Options")}:</strong>{" "}
                        <div
                          style={{
                            [config.direction === "rtl"
                              ? "paddingRight"
                              : "paddingLeft"]: 10,
                          }}
                        >
                          {item.options.map((option) => (
                            <div key={option.id} style={styles.optionItem}>
                              <div style={styles.optionText}>
                                {config.language === "arabic"
                                  ? option.product_option.option_ar_name ||
                                    option.product_option.option_name
                                  : option.product_option.option_name}
                                :{" "}
                                {config.language === "arabic"
                                  ? option.product_option.ar_value ||
                                    option.product_option.value
                                  : option.product_option.value}{" "}
                                (x
                                {option.quantity})
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {this.doesOrderHaveMultipleBookings() && (
                      <div
                        style={{
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                          height: 42,
                          display: "grid",
                          gridTemplateColumns: "auto 40px 60px 40px",
                        }}
                      >
                        <div
                          style={{
                            alignContent: "center",
                            padding: "0px 10px",
                          }}
                        >
                          <strong>{t("Attendees")}</strong>
                        </div>
                        <div
                          style={{
                            border: "1px solid #ddd",
                            borderTop: "none",
                            borderBottom: "none",
                            alignContent: "center",
                            textAlign: "center",
                          }}
                          onClick={() =>
                            this.decreaseAttendance(
                              this.state.attendance.filter(
                                (c) =>
                                  c.order_item_id === item.id ||
                                  c.promise_item_id === item.id
                              )[0],
                              item
                            )
                          }
                        >
                          <MinusCircleFilled
                            style={{
                              color: "red",
                              margin: "0px 5px",
                              fontSize: 18,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            border: "1px solid #ddd",
                            borderTop: "none",
                            borderBottom: "none",
                            alignContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {this.getTempAttended(item)} / {item.quantity}
                        </div>
                        <div
                          style={{
                            border: "1px solid #ddd",
                            borderTop: "none",
                            borderBottom: "none",
                            alignContent: "center",
                            textAlign: "center",
                            fontSize: 18,
                          }}
                          onClick={() =>
                            this.increaseAttendance(
                              this.state.attendance.filter(
                                (c) =>
                                  c.order_item_id === item.id ||
                                  c.promise_item_id === item.id
                              )[0],
                              item
                            )
                          }
                        >
                          <PlusCircleFilled
                            style={{
                              margin: "0px 5px",
                              color:
                                this.getTempAttended(item) >= item.quantity
                                  ? "gray"
                                  : "#0099cc",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                {this.doesOrderHaveMultipleBookings() && (
                  <Button
                    type="primary"
                    style={{ width: "100%", marginTop: 10 }}
                    onClick={() => this.saveChanges()}
                    loading={this.state.saveChangesLoading}
                    disabled={!this.state.changes?.length}
                  >
                    {t("Save changes")}
                  </Button>
                )}

                {!this.doesOrderHaveMultipleBookings() ? (
                  !this.isEntireOrderAttended() ? (
                    <Button
                      type="primary"
                      style={{ width: "100%", marginTop: 10 }}
                      onClick={() => this.markOrderFullyAttended()}
                      loading={this.state.markingFullyLoading}
                    >
                      {t("Mark attended")}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      style={{ width: "100%", marginTop: 10 }}
                      onClick={() => {
                        this.decreaseAttendance(
                          this.state.attendance[0],
                          this.state.items[0]
                        );
                        this.saveChanges();
                      }}
                      danger={true}
                      loading={this.state.saveChangesLoading}
                    >
                      {t("Cancel attendance")}
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    permissions: state.auth.permissions,
    config: state.config,

    settings: state.auth.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(QRScanOrderModal));
