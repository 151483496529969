import {
  SOCKET_FILE_IS_READY,
  FETCH_DOWNLOADS_LOADING,
  FETCH_DOWNLOADS,
  FETCH_CUSTOMER_GROWTH,
  FETCH_RFM,
  FETCH_SALES_BY_LOCATION,
  FETCH_CLOSING_REPORT,
  FETCH_SALES_BY_MONTH,
  FETCH_SALES_OVER_TIME,
  FETCH_SALES_BY_HOUR,
  FETCH_SALES_BY_PRODUCT,
} from "../actions/actionTypes";
const initialState = {
  //Downloads
  downloadsLoading: false,
  didDownloadsLoad: false,
  downloads: [],
  newDownloadItems: [],

  //Customer Growth,
  growthLoading: true,
  growthData: null,

  rfmLoading: true,
  rfmData: null,

  salesByLocationLoading: true,
  salesByLocationData: null,

  closingReportLoading: true,
  closingReportData: null,

  byMonthLoading: true,
  byMonthData: null,

  overTimeLoading: true,
  overTimeData: null,
  prevOverTimeData: null,
  fetchPrev: false,

  byHourLoading: true,
  byHourData: null,

  byProductLoading: true,
  byProductData: null,

  tableScanLoading: true,
  tableScanData: null,
};

export default (state = initialState, { type, payload, asyncDispatch }) => {
  let newDownloads = [];
  switch (type) {
    case FETCH_DOWNLOADS_LOADING:
      return { ...state, downloadsLoading: true };

    case FETCH_DOWNLOADS:
      return {
        ...state,
        downloads: payload,
        downloadsLoading: false,
        didDownloadsLoad: true,
      };

    case SOCKET_FILE_IS_READY:
      newDownloads = state.downloads;
      newDownloads = [payload, ...newDownloads];

      if (window.location.pathname == "/manage/reports/downloads")
        window.setTimeout(() => {
          asyncDispatch((dispatch) =>
            dispatch({ type: "RESET_NEW_DOWNLOAD_ITEMS" })
          );
        }, 10000);
      return {
        ...state,
        downloads: newDownloads,
        newDownloadItems: [payload.id, ...state.newDownloadItems],
      };

    case "RESET_NEW_DOWNLOAD_ITEMS":
      return { ...state, newDownloadItems: [] };

    case FETCH_CUSTOMER_GROWTH:
      return { ...state, growthData: payload, growthLoading: false };

    case FETCH_RFM:
      return { ...state, rfmData: payload, rfmLoading: false };

    case FETCH_SALES_BY_LOCATION:
      return {
        ...state,
        salesByLocationData: payload,
        salesByLocationLoading: false,
      };

    case FETCH_CLOSING_REPORT:
      return {
        ...state,
        closingReportLoading: false,
        closingReportData: payload,
      };

    case FETCH_SALES_BY_MONTH:
      return { ...state, byMonthLoading: false, byMonthData: payload };

    case FETCH_SALES_OVER_TIME:
      let resetPrev = false

      if (!payload.compare){
        resetPrev = true
      }
      if (payload.fetchPrev)
       {return {
         ...state,
         overTimeLoading: false,
         prevOverTimeData: resetPrev? null :payload.data,
         fetchPrev: payload.fetchPrev,
       };}
      return { ...state, overTimeLoading: false, overTimeData: payload.data, prevOverTimeData: resetPrev ? null : state.prevOverTimeData };
    case "FETCH_TABLE_SCAN_HISTORY":
      return { ...state, tableScanLoading: false, tableScanData: payload };

    case FETCH_SALES_BY_HOUR:
      return { ...state, byHourLoading: false, byHourData: payload };

    case FETCH_SALES_BY_PRODUCT:
      return { ...state, byProductLoading: false, byProductData: payload };
    default:
      return state;
  }
};
