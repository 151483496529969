import React from "react";
import { Modal, InputNumber } from "antd";
import { withNamespaces } from "react-i18next";

function PrintCountModal({ visible, onPrint, onCancel, direction, t }) {
  const [count, setCount] = React.useState(null);

  return (
    <Modal
      title={
        <div
          className="ant-modal-title"
          style={{
            textAlign: direction == "rtl" ? "right" : "left",
          }}
        >
          {t("Enter a number of copies")}
        </div>
      }
      visible={visible}
      width={300}
      onOk={() => onPrint(count)}
      onCancel={onCancel}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: direction == "rtl" ? "right" : "left",
      }}
      okButtonProps={{
        disabled: count <= 0 || count == null || Number.isNaN(parseInt(count)),
      }}
      okText={t("Print")}
    >
      <span className="mx-3">{t("How many do you want?")}</span>
      <InputNumber onChange={(val) => setCount(val)} />
    </Modal>
  );
}

export default withNamespaces()(PrintCountModal);
