let premiumTranslations = {
    "This feature is not available on your current package.": 
        "الخاصية غير متوفرة مع باقة اشتراكك",
        "Upgrade":"حدث",
        "Contact Us":"اتصل بنا",
        "Upgrade and access more features, contact us now.":"احصل على ميزات متعددة مع باقة اكبر. اتصل بنا الآن.",
};


export default premiumTranslations;
