import { FETCH_FAQS, FETCH_FAQS_LOADING } from "../actions/actionTypes";

const initialState = {
  faqs: null,
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FAQS_LOADING:
      return { ...state, loading: true };
    case FETCH_FAQS:
      return {
        ...state,
        faqs: payload,
        loading: false,
      };

    default:
      return state;
  }
};
