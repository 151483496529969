import React, { useEffect, useState } from "react";
import { message, Modal, Spin, Table } from "antd";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import instance from "@/store/actions/instance";
import Column from "antd/es/table/Column";
import moment from "moment/moment";

const DetailCampaignModal = ({
  campaign,
  onClose,
  open,
  t,
  capitalizeFirstLetter,
}) => {
  const direction = useSelector((state) => state.config.direction);
  const getPurpose = (purpose) => {
    const purposeMap = {
      retain: t("Increase retention rate"),
      "increase basket": t("Increase average order value"),
      "re-engage": t("Increase engagement"),
      encourage: t("Increase sales"),
    };
    return purposeMap[purpose];
  };

  const recipients = campaign.recipients?.split(",");
  const dataSource = recipients.map((recipient, index) => ({
    key: index.toString(),
    number: recipient,
  }));
  console.log(recipients.length);
  return (
    <Modal
      title={
        <div
          className="ant-modal-title"
          style={{
            textAlign: direction === "rtl" ? "right" : "left",
          }}
        >
          {t("Campaign Details")}
        </div>
      }
      visible={open}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
      width={800}
      bodyStyle={{
        textAlign: direction === "ltr" ? "left" : "right",
      }}
      cancelText={t("Close")}
    >
      <div className={"mt-3"}>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {t("Name")}:{" "}
        </span>
        <span>{campaign.name}</span>
      </div>
      <div className={"mt-3"}>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {t("Body")}:
        </span>
        <span> {campaign.body}</span>
      </div>
      <div className={"mt-3"}>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {t("Reach")}:{" "}
        </span>
        <span>{campaign.recipients?.split(",")?.length || 0}</span>
      </div>
      <div className={"mt-3"}>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {t("Type")}:{" "}
        </span>
        <span>{capitalizeFirstLetter(campaign.type)}</span>
      </div>
      <div className={"mt-3"}>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {t("Segment")}:{" "}
        </span>
        <span>{capitalizeFirstLetter(campaign.segment)}</span>
      </div>
      <div className={"mt-3"}>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {t("Purpose")}:{" "}
        </span>
        <span>{getPurpose(campaign.purpose)}</span>
      </div>
      {campaign.end && campaign.start && (
        <div className={"mt-3"}>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {t("Date Range")}:{" "}
          </span>
          <span>
            {campaign.start} - {campaign.end}
          </span>
        </div>
      )}
      {recipients.length > 0 && (
        <div
          style={{
            marginTop: 15,
            textAlign: direction === "ltr" ? "left" : "right",
            direction: direction,
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {t("Reach numbers")}:{" "}
          </span>
          <Table
            style={{
              marginTop: 5,
            }}
            dataSource={dataSource}
            size="small"
          >
            <Column
              align={"center"}
              className="px-3"
              title={t("Phone")}
              dataIndex="number"
              key="number"
            />
          </Table>
        </div>
      )}
    </Modal>
  );
};

export default withNamespaces()(DetailCampaignModal);
