const trans = {
  Pages: "الصفحات",
  "New Page": "صفحة جديدة",
  Title: "العنوان",
  "Arabic title": "العنوان بالعربي",
  Placement: "مكان الصفحة",
  Style: "الشكل",
  Window: "نافذة",
  "Full page": "صفحة كاملة",
  "Edit Page": "تعديل الصفحة",
  "English title": "العنوان بالانجليزي",
  "English content": "المحتوى بالانجليزي",
  "Arabic content": "المحتوى بالعربي",
  "Terms and Conditions": "الشروط والأحكام",
  "A Terms and Conditions link will show at the bottom of your home page, and you can customize the link below":
    "سوف يظهر رابط للشروط والأحكام في اسفل الصفحة الرئيسية، وبإمكانك تخصيص الرابط ادناه",
};

export default trans;
