import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../../../store/actions/instance";

// Antd Components
import { Modal, message, Spin, Tabs, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Actions

//Tabs
const DetailsTab = React.lazy(() => import("./DetailsTab"));
const ExtendPromotionTab = React.lazy(() => import("./ExtendPromotionTab"));

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class PromotionModal extends Component {
  saveButtonDisabled = () => {
    const { selectedPromotion } = this.props;
    const { description, arabicDescription } = this.state;

    if (
      description !== selectedPromotion.discount_description ||
      arabicDescription !== selectedPromotion.ar_discount_description
    ) {
      return false;
    }
    return true;
  };

  render() {
    const {
      visible,
      config,
      t,
      selectedPromotion,
      selectedPromotionIndex,
      permissions,
    } = this.props;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {`${t("Edit Promotion")} ${selectedPromotion.name}`}
          </div>
        }
        visible={visible}
        onCancel={() => this.props.onClose()}
        cancelText={t("Close")}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
        footer={[
          <Button key="back" onClick={() => this.props.onClose()}>
            {t("Close")}
          </Button>,
        ]}
      >
        <Tabs
          defaultActiveKey="1"
          size={"large"}
          tabPosition={"top"}
          animated={false}
        >
          <TabPane tab={t("Details")} key="1">
            <Suspense
              fallback={
                <div className="my-4 text-center">
                  <Spin indicator={antIcon} />
                </div>
              }
            >
              <DetailsTab
                selectedPromotion={selectedPromotion}
                selectedPromotionIndex={selectedPromotionIndex}
              />
            </Suspense>
          </TabPane>
          {permissions.can_create_promotions && (
            <TabPane tab={t("Extend Promotion")} key="2">
              <Suspense
                fallback={
                  <div className="my-4 text-center">
                    <Spin indicator={antIcon} />
                  </div>
                }
              >
                <ExtendPromotionTab
                  selectedPromotion={selectedPromotion}
                  selectedPromotionIndex={selectedPromotionIndex}
                />
              </Suspense>
            </TabPane>
          )}
        </Tabs>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PromotionModal));
