import React, { Component } from "react";
import { Layout, Button, Dropdown, Menu, Alert, Space } from "antd";
import { connect } from "react-redux";
import {
  BellOutlined,
  LogoutOutlined,
  GlobalOutlined,
  QrcodeOutlined,
  MedicineBoxOutlined,
  UserOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Badge, Popover, Row, Col, Divider } from "antd";
import { logout } from "../../store/actions/auth";
import { switchLanguage } from "../../store/actions/config";
import { withNamespaces } from "react-i18next";
import { Select } from "antd";
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router-dom";
import { ReactComponent as Alert2 } from "./assets/alert.svg";
import { dismissHealthNotification } from "../../store/actions/health";
import { dismissVerifyNotification } from "../../store/actions/auth";
import moment from "moment";

const { Option } = Select;
const { Header } = Layout;

class LayoutHeader extends Component {
  state = {
    QRuri: "",
    dropdownVisible: false,
    showDismmissModal: false,
  };

  onLanguageChange = (value) => {
    this.props.switchLanguage(value);
    this.setState({ dropdownVisible: false });
  };
  componentDidUpdate = () => {
    if (
      this.props.settings &&
      !this.props.hideHeader &&
      !this.state.QRuri &&
      this.props.settings?.domain
    ) {
      const qrCodeCanvas = document.querySelector("#QR");
      const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
      this.QRCodeUri = qrCodeDataUri;

      this.setState({ QRuri: qrCodeDataUri });
    }
  };

  render() {
    const { t, settings, config, health, hideHeader, username, auth } =
      this.props;

    const DismissMenu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => this.props.dismissVerifyNotification("remind")}
        >
          {t("Remind me later")}
        </Menu.Item>
        <Menu.Item key="3">
          <Divider style={{ margin: 0 }} />
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => this.props.dismissVerifyNotification("dismiss")}
        >
          {t("Do not ask again")}
        </Menu.Item>
      </Menu>
    );

    if (hideHeader) return null;
    const menu = (
      <Menu style={{ width: 200 }}>
        <p className="mb-2 mt-1 text-center">{username}</p>
        <Divider style={{ width: 120, margin: "5px 0px" }} />

        <Menu.Item
          onClick={() => {
            this.setState({ dropdownVisible: false });
            this.props.history.push("/manage/profile");
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            <UserOutlined style={{ fontSize: 16 }} /> {t("Profile")}
          </span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ dropdownVisible: false })}>
          <a
            href={this.state.QRuri}
            key="QR Code"
            download="QR Code"
            style={{ fontWeight: "bold" }}
          >
            <QrcodeOutlined
              style={{
                fontSize: 16,
                [`margin${config.direction === "ltr" ? "Right" : "Left"}`]: 8,
              }}
            />{" "}
            {t("QR Code")}
          </a>
        </Menu.Item>

        <Divider style={{ width: 120, margin: "5px 0px" }} />

        <Menu.Item>
          <span style={{ fontWeight: "bold" }}>
            <GlobalOutlined
              style={{
                fontSize: 16,
                [`margin${config.direction === "ltr" ? "Right" : "Left"}`]: 8,
              }}
            />{" "}
            <Select
              value={config.language}
              style={{ width: 145, position: "relative", right: 0 }}
              onChange={this.onLanguageChange}
              size="small"
              //suffixIcon={<GlobalOutlined />}
            >
              <Option value="english">English</Option>
              <Option value="arabic">عربي</Option>
              <Option value="tagalog">Tagalog</Option>
            </Select>
          </span>
        </Menu.Item>

        <Divider style={{ width: 120, marginBottom: 5, marginTop: 7 }} />
        <Menu.Item
          onClick={() => {
            this.setState({ dropdownVisible: false });
            this.props.logout();
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            <LogoutOutlined style={{ fontSize: 16 }} /> {t("Logout")}
          </span>
        </Menu.Item>
      </Menu>
    );

    if (!settings)
      return (
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            backgroundColor: "#0099cc",
            display: "none",
          }}
        >
          {(localStorage.getItem("token") || this.props.permissionsLoading) && (
            <div
              style={{
                float: config.direction == "ltr" ? "right" : "left",
                marginRight: config.direction == "ltr" && 15,
                marginLeft: config.direction == "rtl" && 15,
                direction: config.direction,
                cursor: "pointer",
              }}
            >
              <Button
                type="link"
                style={{ color: "white" }}
                onClick={() => {
                  this.props.logout();
                  window.location.reload();
                }}
                title={t("Logout")}
              >
                <LogoutOutlined style={{ fontSize: 22 }} />
              </Button>
            </div>
          )}
        </Header>
      );
    return (
      <>
        {settings.domain && (
          <div style={{ display: "none" }}>
            <QRCode
              value={settings ? `https://${settings.domain}` : ``}
              size={500}
              id={"QR"}
            />
          </div>
        )}

        {(!auth.did_verify_email || !auth.did_verify_phone) &&
          moment(auth.dismiss_until, "YYYY-MM-DDTHH:mm:ss").format(
            "YYYY-MM-DD HH:mm:ss"
          ) <= moment().format("YYYY-MM-DD HH:mm:ss") &&
          !auth.did_dismiss_verify && (
            <Alert
              icon={
                <InfoCircleFilled
                  style={{
                    marginRight: 10,
                    marginLeft: 10,
                    top: 1,
                  }}
                />
              }
              message={t("Click here to verify your contact details")}
              type="error"
              onClick={() => {
                this.props.history.replace("/manage/profile");
              }}
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer",
                textAlign: config.direction == "ltr" ? "left" : "right",
                alignItems: "center",
              }}
              action={
                <>
                  <Row gutter={[16, 16]} className="verify_row">
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={24}
                      xs={24}
                      // style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        size="small"
                        // className="mx-2"
                        type="primary"
                        style={{
                          minWidth: 136,
                          minHeight: 36,
                          borderRadius: 3,
                          background: "red",
                          fontWeight: "bold",
                          border: "none",
                        }}
                        onClick={() => {
                          this.props.history.replace("/manage/profile");
                        }}
                      >
                        {t("Verify")}
                      </Button>
                    </Col>
                    {/* <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={24}
                      xs={24}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Dropdown
                        overlay={DismissMenu}
                        placement={"bottomCenter"}
                        arrow
                        trigger={["click"]}
                      >
                      <Button
                        // className="mx-2"
                        style={{
                          color: "gray",
                          minWidth: 90,
                          fontSize: 11,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        type="link"
                        onClick={() =>
                          this.props.dismissVerifyNotification("remind")
                        }
                      >
                        {t("Dismiss")}
                      </Button>
                      </Dropdown>
                    </Col> */}
                  </Row>
                </>
              }
              showIcon
            />
          )}

        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            backgroundColor: "#0099cc",
            display: "none",
          }}
        >
          {this.props.user && !settings?.is_menu && (
            <div
              style={{
                float: config.direction == "ltr" ? "right" : "left",
                marginRight: config.direction == "ltr" ? 20 : 20,
                marginLeft: config.direction == "rtl" ? 20 : 20,
                direction: config.direction,
                cursor: "pointer",
              }}
            >
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                visible={this.state.dropdownVisible}
                onVisibleChange={(e) => this.setState({ dropdownVisible: e })}
                trigger={["click"]}
              >
                <img
                  src={this.props.settings.logo}
                  style={{
                    borderRadius: 3,
                    marginLeft: config.direction == "ltr" && 0,
                    marginRight: config.direction == "rtl" && 0,
                    marginBottom: 3,
                  }}
                  width={48}
                  height={48}
                  onClick={() =>
                    this.setState({
                      dropdownVisible: !this.state.dropdownVisible,
                    })
                  }
                />
              </Dropdown>
            </div>
          )}

          <div
            style={{
              float: config.direction == "ltr" ? "right" : "left",
              marginRight: config.direction == "ltr" && -16,
              marginLeft: config.direction == "rtl" && -16,
              direction: config.direction,
            }}
          >
            {!settings?.is_menu && (
              <Popover
                placement="bottomRight"
                title={
                  <div>
                    <span>
                      <Alert2 style={{ width: 30, height: 40 }} />
                    </span>{" "}
                    <span
                      style={{
                        marginLeft: config.direction == "ltr" && 15,
                        marginRight: config.direction == "rtl" && 15,
                      }}
                    >
                      {t("Attention")}
                    </span>
                  </div>
                }
                content={
                  <div style={{ minWidth: 160 }}>
                    <p>{t("You have urgent issues")}</p>
                    <Row>
                      <Col span={12}>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.props.dismissHealthNotification();
                            this.props.history.replace("/manage/health");
                          }}
                        >
                          {t("View")}
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          onClick={() => this.props.dismissHealthNotification()}
                        >
                          {t("Dismiss")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }
                visible={
                  !health.isDismissed &&
                  health.issues.find((issue) => issue.urgency == 1) &&
                  window.location.pathname != "/manage/health" &&
                  !config.runTutorial
                }
              >
                <Button
                  type="link"
                  style={{
                    color: "white",
                    paddingLeft: config.direction == "ltr" && 0,
                    paddingRight: config.direction == "rtl" && 0,
                  }}
                  onClick={() => this.props.history.replace("/manage/health")}
                >
                  <Badge dot count={health.issues.length > 0 ? 1 : 0}>
                    <MedicineBoxOutlined
                      style={{ fontSize: 30, color: "white" }}
                    />
                  </Badge>
                </Button>
              </Popover>
            )}

            {/* {this.props.user &&
          this.props.permissions &&
          this.props.permissions.can_view_orders &&
          !settings?.is_menu ? (
            <Badge
              dot
              count={
                this.props.bellHasNewOrders ||
                this.props.bellHasNewBusinessOrders
                  ? 1
                  : 0
              }
            >
              <BellOutlined
                style={{
                  color: "white",
                  right: 0,
                  fontSize: 22,
                  height: 10,
                }}
                spin={
                  this.props.bellHasNewOrders ||
                  this.props.bellHasNewBusinessOrders
                }
              />
            </Badge>
          ) : null} */}
          </div>
        </Header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bellHasNewOrders: state.orders.bellHasNewOrders,
    bellHasNewBusinessOrders: state.businessOrders.bellHasNewBusinessOrders,
    user: state.auth.user,
    permissions: state.auth.permissions,
    config: state.config,
    settings: state.auth.settings,
    is_staff: state.auth.is_staff,
    hideHeader: state.pos.hideHeader,
    health: state.health,
    permissionsLoading: state.auth.loadingPermissions,
    username: state.auth.username,
    profile: state.profile.profile,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    switchLanguage: (language) => dispatch(switchLanguage(language)),
    dismissHealthNotification: () => dispatch(dismissHealthNotification()),
    dismissVerifyNotification: (type) =>
      dispatch(dismissVerifyNotification(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(LayoutHeader)));
