import React, { Suspense } from "react";
import Campaigns from "./components/Campaigns";

const Orders = React.lazy(() => import("./components/Orders"));
const KanBan = React.lazy(() => import("./components/Orders/KanBan"));
const Login = React.lazy(() => import("./components/Login"));
const ForgetPassword = React.lazy(() => import("./components/ForgetPassword"));
const ResetPassword = React.lazy(() =>
  import("./components/ForgetPassword/ResetPassword.js")
);
const Products = React.lazy(() => import("./components/Products"));
const FAQs = React.lazy(() => import("./components/FAQs"));
const PDHours = React.lazy(() => import("./components/PDHours"));
const Locations = React.lazy(() => import("./components/Locations"));
const DeliveryRates = React.lazy(() =>
  import("./components/DeliverySettings/Rates")
);
const DeliveryConfig = React.lazy(() =>
  import("./components/DeliverySettings/Configurations")
);
const CustomTexts = React.lazy(() =>
  import("./components/Appearance/CustomTexts")
);
const Identity = React.lazy(() => import("./components/Appearance/Identity"));
const Features = React.lazy(() => import("./components/Appearance/Features"));
const InvoiceSettings = React.lazy(() =>
  import("./components/Appearance/InvoiceSettings")
);
const LayoutTheme = React.lazy(() =>
  import("./components/Appearance/LayoutTheme")
);
const Promotions = React.lazy(() => import("./components/Promotions"));
const WaitLists = React.lazy(() => import("./components/WaitLists"));
const WaitListManage = React.lazy(() =>
  import("./components/WaitLists/WaitListManage")
);
const Permissions = React.lazy(() => import("./components/Permissions"));
const Staff = React.lazy(() => import("./components/Staff"));
const DeliveryIntegration = React.lazy(() =>
  import("./components/Integration/Delivery")
);
const PaymentIntegration = React.lazy(() =>
  import("./components/Integration/Payment")
);
const MarketingIntegration = React.lazy(() =>
  import("./components/Integration/Marketing")
);
const InventoryManagementIntegration = React.lazy(() =>
  import("./components/Integration/InventoryManagement")
);
const FeedBacks = React.lazy(() => import("./components/FeedBack"));
const Customers = React.lazy(() => import("./components/Customers"));
const CustomerDetails = React.lazy(() =>
  import("./components/Customers/CustomerDetails")
);
const Raffle = React.lazy(() => import("./components/Promotions/Raffel"));
const Manifest = React.lazy(() => import("./components/Businesses/Manifest"));
const BusinessReports = React.lazy(() =>
  import("./components/Businesses/Reports")
);
const Businesses = React.lazy(() => import("./components/Businesses"));

const CustomerBookings = React.lazy(() =>
  import("./components/CustomerBookings")
);
const ServiceBookings = React.lazy(() =>
  import("./components/ServiceBookings")
);
const Downloads = React.lazy(() => import("./components/Reports/Downloads"));
const POS = React.lazy(() => import("./components/POS"));
const CustomerReports = React.lazy(() =>
  import("./components/Reports/Customers")
);
const TableReports = React.lazy(() => import("./components/Reports/Tables"));
const SalesByLocation = React.lazy(() =>
  import("./components/Reports/Sales/ByLocation")
);
const ClosingReport = React.lazy(() =>
  import("./components/Reports/Sales/Closing")
);
const SalesByTimeReport = React.lazy(() =>
  import("./components/Reports/Sales/ByTime")
);
const SalesByMonthReport = React.lazy(() =>
  import("./components/Reports/Sales/ByMonth")
);
const SalesByProductReport = React.lazy(() =>
  import("./components/Reports/Sales/ByProduct")
);
const MessagingIntegration = React.lazy(() =>
  import("./components/Integration/Messaging")
);
const Health = React.lazy(() => import("./components/Health"));
const NoticeBanner = React.lazy(() =>
  import("./components/Appearance/Banner/Notice")
);
const PopupBanner = React.lazy(() =>
  import("./components/Appearance/Banner/Popup")
);
const AccountSettings = React.lazy(() =>
  import("./components/AccountSettings")
);
const OrderLimits = React.lazy(() => import("./components/OrderLimits"));
const Profile = React.lazy(() => import("./components/Profile"));
const Import = React.lazy(() => import("./components/Import"));
const ProductImportTemplate = React.lazy(() =>
  import("./components/Products/Import/ProductImportTemplate")
);
const ProductImportTalabat = React.lazy(() =>
  import("./components/Products/Import/ProductImportTalabat")
);
const ProductImportDeliveroo = React.lazy(() =>
  import("./components/Products/Import/ProductImportDeliveroo")
);
const ProductImportZyda = React.lazy(() =>
  import("./components/Products/Import/ProductImportZyda")
);
const ProductImportMnasati = React.lazy(() =>
  import("./components/Products/Import/ProductImportMnasati")
);

const ProductImportMagna = React.lazy(() =>
  import("./components/Products/Import/ProductImportMagna")
);
const ProductImportFineDine = React.lazy(() =>
  import("./components/Products/Import/ProductImportFineDine")
);

const ProductImportOkMenu = React.lazy(() =>
  import("./components/Products/Import/ProductImportOkMenu")
);

const ProductImportUpay = React.lazy(() =>
  import("./components/Products/Import/ProductImportUpay")
);

const CustomerImportTemplate = React.lazy(() =>
  import("./components/Products/Import/CustomerImportTemplate")
);
const AreaImportTemplate = React.lazy(() =>
  import("./components/Products/Import/AreaImportTemplate")
);
const ProductImport = React.lazy(() => import("./components/Products/Import"));
const ServiceProviders = React.lazy(() =>
  import("./components/ServiceProviders")
);
const Pages = React.lazy(() => import("./components/Pages"));
const Home = React.lazy(() => import("./components/Home/NewHome"));
const BusinessOrders = React.lazy(() => import("./components/BusinessOrders"));
const PendingOrders = React.lazy(() => import("./components/PendingOrders"));
const Settings = React.lazy(() => import("./components/Settings"));
const Sales = React.lazy(() => import("./components/Reports/Sales"));
const AddOns = React.lazy(() => import("./components/AddOns/AddOns"));
const PackageDetailPage = React.lazy(() =>
  import("./components/AddOns/AddOns/AddonDetailPage")
);
const Tables = React.lazy(() => import("./components/Tables"));
const Wallet = React.lazy(() => import("./components/Wallet"));
const PreparationSheet = React.lazy(() =>
  import("./components/Reports/Operations/PreparationSheet")
);
const AbandonedCarts = React.lazy(() => import("./components/AbandonedCarts"));
const Driver = React.lazy(() => import("./components/Driver"));
const DriverOrderHistory = React.lazy(() =>
  import("./components/Driver/DriverOrderHistory")
);

const Topups = React.lazy(() => import("./components/Topups"));
const Users = React.lazy(() => import("./components/Users"));

const MobileNotifications = React.lazy(() =>
  import("./components/MobileNotifications")
);

const Cloudflare = React.lazy(() => import("./components/Cloudflare"));
const LandingLinks = React.lazy(() => import("./components/LandingLinks"));

const SurveyDetails = React.lazy(() =>
  import("./components/FeedBack/Survey/SurveyDetails.js")
);
const Survies = React.lazy(() => import("./components/FeedBack/Survey"));

const allRoutes = {
  "/manage/home": Home,

  "/manage/orders": KanBan || Orders,
  "/manage/products": Products,
  "/manage/ServiceProviders": ServiceProviders,
  "/manage/hours": PDHours,
  "/manage/faqs": FAQs,
  "/manage/appearance/CustomTexts": CustomTexts,
  "/manage/appearance/Identity": Identity,
  "/manage/appearance/Features": Features,
  "/manage/appearance/LayoutTheme": LayoutTheme,
  "/manage/appearance/NoticeBanner": NoticeBanner,
  "/manage/appearance/PopupBanner": PopupBanner,
  "/manage/appearance/InvoiceSettings": InvoiceSettings,
  "/manage/DeliveryRates": DeliveryRates,
  "/manage/DeliveryConfig": DeliveryConfig,
  "/manage/permissions": Permissions,
  "/manage/Integration/Delivery": DeliveryIntegration,
  "/manage/Integration/Payment": PaymentIntegration,
  "/manage/Integration/Marketing": MarketingIntegration,
  "/manage/Integration/InventoryManagement": InventoryManagementIntegration,
  "/manage/Integration/Messaging": MessagingIntegration,
  "/manage/reports/downloads": Downloads,
  "/manage/reports/customers": CustomerReports,
  "/manage/reports/tables": TableReports,
  "/manage/reports/smaps": SalesByLocation,
  "/manage/reports/sales": SalesByTimeReport,
  "/manage/reports/products": SalesByProductReport,
  "/manage/reports/sreports": Sales,
  "/manage/reports/operations/prepartion": PreparationSheet,
  "/manage/limits": OrderLimits,
  "/manage/reports/closing": ClosingReport,
  "/manage/bookings": CustomerBookings,
  "/manage/services": ServiceBookings,
  "/manage/profile": Profile,
  "/manage/import/customers/zyda": Import,
  "/manage/import/products/template": ProductImportTemplate,
  "/manage/import/products/talabat": ProductImportTalabat,
  "/manage/import/products/deliveroo": ProductImportDeliveroo,
  "/manage/import/products/zyda": ProductImportZyda,
  "/manage/import/products/mnasati": ProductImportMnasati,
  "/manage/import/products/finedine": ProductImportFineDine,
  "/manage/import/products/okmenu": ProductImportOkMenu,
  "/manage/import/products/upay": ProductImportUpay,
  "/manage/import/products/magna": ProductImportMagna,
  "/manage/import/products": ProductImport,
  "/manage/import/customers/template": CustomerImportTemplate,
  "/manage/import/areas/template": AreaImportTemplate,
  "/manage/health": Health,
  "/manage/staff": Staff,
  "/manage/pages": Pages,
  "/manage/business_orders": BusinessOrders,
  "/manage/locations": Locations,
  "/manage/businesses/manifest": Manifest,
  "/manage/businesses/reports": BusinessReports,
  "/manage/businesses": Businesses,
  "/manage/promotions": Promotions,
  "/manage/coupons": Promotions,
  "/manage/billing": AccountSettings,
  "/manage/waitlists/:slug": WaitListManage,
  "/manage/waitlists": WaitLists,
  "/manage/feedback": FeedBacks,
  "/manage/customers/profile/:slug?": CustomerDetails,
  "/manage/customers": Customers,
  "/manage/raffle": Raffle,
  "/manage/pos": POS,
  "/manage/pending": PendingOrders,
  "/manage/preparation": PreparationSheet,
  "/manage/settings": Settings,
  "/manage/addons/:packageID": PackageDetailPage,
  "/manage/installed/addons/:packageID": PackageDetailPage,
  "/manage/addons": AddOns,
  "/manage/tables": Tables,
  "/manage/wallet": Wallet,
  "/manage/abandoned": AbandonedCarts,
  "/manage/MobileNotifications": MobileNotifications,
  "/manage/dns": Cloudflare,
  "/manage/login": Login,
  "/manage/ForgetPassword": ForgetPassword,
  "/manage/resetPassword/:username/:uuid": ResetPassword,
  "/manage/drivers/:id": DriverOrderHistory,
  "/manage/drivers": Driver,
  "/manage/users": Users,
  "/manage/topups": Topups,
  "/manage/links": LandingLinks,
  "/manage/campaign": Campaigns,
  "/manage/survey/responses/:id/": SurveyDetails,
  "/manage/survey": Survies,
};

const overdueRoutes = ["/manage/billing", "/manage/staff"];

const unauthorizedRoutes = [
  "/manage/login",
  "/manage/ForgetPassword",
  "/manage/resetPassword/:username/:uuid",
];

const menuModeRoutes = [
  "/manage/home",
  "/manage/products",
  "/manage/billing",
  "/manage/appearance/Identity",
  "/manage/appearance/Features",
  "/manage/appearance/LayoutTheme",
  "/manage/Integration/Marketing",
  "/manage/appearance/Identity",
  "/manage/appearance/Features",
  "/manage/appearance/LayoutTheme",
  "/manage/appearance/NoticeBanner",
  "/manage/appearance/PopupBanner",
  "/manage/locations",
  "/manage/feedback",
  "/manage/hours",
  "/manage/staff",
  "/manage/import/products/template",
  "/manage/import/products/talabat",
  "/manage/import/products/deliveroo",
  "/manage/import/products/zyda",
  "/manage/import/products/mnasati",
  "/manage/import/products/magna",
  "/manage/import/customers/template",
  "/manage/import/areas/template",
  "/manage/profile",
  "/manage/import/products",
  "/manage/pages",
  "/manage/settings",
  "/manage/pos",
  "/manage/faqs",
  "/manage/waitlists/:slug",
  "/manage/waitlists",
  "/manage/permissions",
  "/manage/appearance/CustomTexts",
  "/manage/coupons",
  "/manage/raffle",
  "/manage/promotions",
  "/manage/MobileNotifications",
  "/manage/dns",
  "/manage/links",
  "/manage/reports/downloads",
];

export { allRoutes, overdueRoutes, unauthorizedRoutes, menuModeRoutes };
