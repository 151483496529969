import { FETCH_ACCOUNT_INFO, DELETE_INVOICE } from "../actions/actionTypes";

const initialState = {
  info: null,
  invoices: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ACCOUNT_INFO:
      return { ...state, info: payload.info, invoices: payload.invoices };
    case DELETE_INVOICE:
      let newInvoices = state.invoices;
      newInvoices = newInvoices.filter((i) => i.id !== payload);
      return { ...state, invoices: newInvoices };
    default:
      return state;
  }
};
