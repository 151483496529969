import ServiceProviders from "./ServiceProviders";

const trans = {
  ...ServiceProviders,
  "Service Bookings": "حجوزات الخدمات",
  Service: "خدمة",
  Provider: "مقدم الخدمة",
  With: "مع",
  "Calendar Timeline": "الجدول الزمني",
  "Edit booking date and time": "تعديل تاريخ ووقت الحجز",
  Appointments: "الخدمات",
  "All services": "جميع الخدمات",
  "All providers": "جميع الموفرين",
};

export default trans;
