import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { FilterOutlined } from "@ant-design/icons";

// Antd components
import { Modal, Button, Select, Popover, message } from "antd";
import instance from "../../store/actions/instance";
import { setPOSBranchDeliveryCharges } from "../../store/actions/";

import { GetEnglishOrArabic } from "./";
import moment from "moment";

const { Option } = Select;

class GeneralSettings extends Component {
  state = {
    branchId: this.props.branch,
    filterdBranches: null,
  };
  changeBranch = () => {
    this.props.changeBranch(this.state.branchId);
    this.props.onClose();
  };
  saveButtonDisabled = () => {
    if (this.props.branch === this.state.branchId || !this.state.branchId)
      return true;

    return false;
  };

  branchSelect = React.createRef();

  componentDidMount = async () => {
    const { settings, t, branchDeliveryCharges } = this.props;
    if (settings.is_branch_based_ordering && !branchDeliveryCharges) {
      try {
        this.setState({ deliveryChargesLoading: true });
        let res = await instance.post("/fetch_branches_delivery_charges/");
        this.props.setPOSBranchDeliveryCharges(res.data);
      } catch (err) {
        if (err.response && err.response.status == 403)
          message.error(t("AccessDeniedMessage"));
        else message.error(t("SomethingWentWrong"));
      }
      this.setState({ deliveryChargesLoading: false });
    }
  };

  branchBusyCheck = (branch) => {
    const { settings } = this.props;

    let isBusy = 0;

    const now = moment();

    if (settings?.busy_until_active) {
      const busyDate = moment(settings?.busy_until_date);
      const busyTime = moment(settings?.busy_until_time, "HH:mm");
      if (busyDate.isSame(now, "day")) {
        if (busyTime.isAfter(now, "HH:mm")) {
          isBusy += 1;
        }
      } else if (busyDate.isAfter(now)) {
        isBusy += 1;
      }
    }
    if (branch?.busy_until_active) {
      const busyDate = moment(branch?.busy_until_date);
      const busyTime = moment(branch?.busy_until_time, "HH:mm");
      if (busyDate.isSame(now, "day")) {
        if (busyTime.isAfter(now, "HH:mm")) {
          isBusy += 1;
        }
      } else if (busyDate.isAfter(now)) {
        isBusy += 1;
      }
    }
    if (branch && branch.closure_periods) {
      branch.closure_periods
        .filter((period) => period.is_active)
        .some((period) => {
          const start = moment(period.start);
          const end = moment(period.end);
          isBusy = now.isBetween(start, end) ? isBusy + 1 : isBusy;
        });
    }
    return isBusy > 0 ? true : false;
  };

  render() {
    const { t, config, visible, branches, branch, settings } = this.props;
    const { branchId, filterdBranches, deliveryChargesLoading } = this.state;
    let branchDeliveryCharges = this.props.branchDeliveryCharges || [];

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Change Branch")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            block
            disabled={this.saveButtonDisabled()}
            key="submit"
            type="primary"
            onClick={() => this.changeBranch()}
          >
            {t("Change Branch")}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="px-2 mb-3">
          <div className="mt-3 mb-4">
            <p>
              {t("Branch")}{" "}
              {settings.is_branch_based_ordering && (
                <span
                  className={
                    (config.language === "arabic" && "float-left") ||
                    "float-right"
                  }
                >
                  <Popover
                    overlayStyle={{ width: "300px" }}
                    content={
                      <div className="mt-3 mb-4">
                        <p>{t("Areas")}</p>
                        <Select
                          allowClear
                          loading={deliveryChargesLoading}
                          showSearch
                          style={{ width: "100%" }}
                          placeholder={t("Select an area")}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) => {
                            if (!e) {
                              this.setState({ filterdBranches: null });
                              return;
                            }
                            const area = branchDeliveryCharges.find(
                              (a) => a.area_id === e
                            );
                            if (!area.branches.includes(this.state.branchId)) {
                              const firstBranch = branches.find((branch) =>
                                area.branches.includes(branch.id)
                              );
                              console.log(firstBranch);
                              this.setState({ branchId: firstBranch?.id });
                            }
                            this.setState({ filterdBranches: area.branches });
                          }}
                        >
                          {branchDeliveryCharges.map((area) => (
                            <Option value={area.area_id}>
                              {GetEnglishOrArabic(
                                area.area,
                                area.ar_area,
                                config.language
                              )}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    }
                    trigger="hover"
                  >
                    <Button type={"primary"}>
                      {t("Filter By Area")}
                      <FilterOutlined />
                    </Button>
                  </Popover>
                </span>
              )}
            </p>

            <Select
              ref={this.branchSelect}
              showSearch
              value={branchId || undefined}
              style={{ width: "100%" }}
              placeholder={t("Select a branch")}
              optionFilterProp="label"
              filterOption={(input, option) => {
                const optionText = option.label
                  ? option.label.toLowerCase()
                  : "";
                return optionText.indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={(e) => {
                const select = this.branchSelect.current;
                if (select) {
                  select.blur();
                }
                const branch = branches.find((b) => b.id === e);
                this.setState({ branchId: e });
              }}
            >
              {branches.length
                ? branches.map((branch) => {
                    const branchLabel = GetEnglishOrArabic(
                      branch.name,
                      branch.ar_name,
                      config.language
                    );
                    return (
                      <Option
                        key={branch.id}
                        value={branch.id}
                        label={branchLabel} 
                        disabled={
                          filterdBranches &&
                          !filterdBranches.includes(branch.id)
                        }
                      >
                        {branchLabel}
                        <Popover
                          placement={
                            config.direction == "ltr" ? "left" : "right"
                          }
                          content={
                            <div
                              style={{
                                maxWidth: 275,
                                wordWrap: "break-word",
                              }}
                            >
                              {t("Store is closed until ") +
                                branch.busy_until_date +
                                " " +
                                branch.busy_until_time}
                            </div>
                          }
                          trigger="hover"
                        >
                          <span
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                            className={
                              this.branchBusyCheck(branch) > 0 ? "dot-red" : ""
                            }
                          ></span>{" "}
                        </Popover>
                      </Option>
                    );
                  })
                : null}
            </Select>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    branches: state.auth.permissions.all_permitted_branches,
    settings: state.auth.settings,
    branchDeliveryCharges: state.pos.branchDeliveryCharges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPOSBranchDeliveryCharges: (params) =>
      dispatch(setPOSBranchDeliveryCharges(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(GeneralSettings));
