import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { withNamespaces } from "react-i18next";

function Toggle({ isActive, onClick, disabled, loading, tooltipMessage, t }) {
  return (
    <div className="text-center" style={{ maxWidth: 50, display: "inline" }}>
      <Tooltip title={tooltipMessage}>
        <Button
          danger={isActive == false}
          shape="circle"
          disabled={disabled || false}
          loading={loading}
          icon={
            isActive ? (
              <CheckOutlined style={{ top: 0 }} />
            ) : (
              <CloseOutlined style={{ top: 0 }} />
            )
          }
          style={{
            color: "white",
            borderColor: !tooltipMessage
              ? isActive
                ? "#52c41a"
                : "#ff4d4f"
              : "#6c757d",
            backgroundColor: !tooltipMessage
              ? isActive
                ? "#52c41a"
                : "#ff4d4f"
              : "#6c757d",
          }}
          onClick={() => onClick()}
        ></Button>
      </Tooltip>
    </div>
  );
}

export default withNamespaces()(Toggle);
