const trans = {
  Permissions: "الصلاحيات",
  Roles: "الوظائف",
  Users: "المستخدمين",
  "New role": "وظيفة جديدة",
  "Search for a role": "ابحث عن وظيفة",
  "Users count": "عدد المستخدمين",
  Default: "الافتراضي",
  "Role name": "اسم الوظيفة",
  "New user": "مستخدم جديد",
  "First name": "الاسم الاول",
  "Last name": "الاسم الاخير",
  "Min. 6 characters": "6 حروف على الاقل",
  "Select a role": "اختر وظيفة",
  "Edit permissions": "تعديل الصلاحيات",
  "Search for a user": "ابحث عن مستخدم",
  "(optional)": "(اختياري)",
  "Permissions for": "صلاحيات",
  Areas: "المناطق",
  Abilities: "الامكانيات",

  "Reports Permissions": "صلاحيات التقارير",
  "Permissions Permissions": "صلاحيات الصلاحيات",
  "Product and Category Permissions": "صلاحيات المنتجات والاصناف",
  "Orders Permissions": "صلاحيات الطلبات",
  "Promotions Permissions": "صلاحيات العروضات",
  "Customers Permissions": "صلاحيات العملاء",
  "Settings Permissions": "صلاحيات الاعدادات",
  "Production and Delivery Hours Permissions": "صلاحيات اوقات العمل والتوصيل",
  "Branch Locations": "صلاحيات الافرع",
  "Waitlist Permissions": "صلاحيات قائمات الانتظار",
  "Business Permissions": "صلاحيات الشركات",

  "Can edit permissions": "تعديل الصلاحيات",
  "Can view permissions": "رؤية الصلاحيات",
  "Is active": "مفعل",
  "Can download reports": "تحميل التقارير",
  "Can view sales reports": "رؤية تقارير المبيعات",
  "Can view top sellers report": "رؤية تقرير الاكثر مبيعا",
  "Can view closing report": "رؤية تقرير ختام اليوم",
  "Can receive orders": "استلام الطلبات",
  "Can view map report": "رؤية تقرير الخريطة",
  "Can view prep sheet report": "رؤية جدول التحضير",
  "Can view b2b report": "رؤية تقرير مبيعات السركات",
  "Can view customer reports": "رؤية تقارير الزبائن",
  "Can create products categories": "اضافة الأصناف",
  "Can edit products categories": "تعديل الأصناف",
  "Can view products categories": "رؤية الأصناف",
  "Can create products": "اضافة منتج",
  "Can edit products": "تعديل المنتج",
  "Can view products list": "رؤية قائمة المنتجات",
  "Can create orders": "عمل الطلبات",
  "Can edit orders": "تعديل الطلبات",
  "Can view orders": "رؤية الطلبات",
  "Can refund customers": "استرجاع مبالغ الطلبات",
  "Can create promotions": "اضافة عروض",
  "Can view promotions": "رؤية العروض",
  "Can edit customers": "تعديل الزبائن",
  "Can create customers": "اضافة الزبائن",
  "Can view customers": "رؤية الزبائن",
  "Can edit settings": "تعديل الاعدادات",
  "Can view settings": "رؤية الاعدادات",
  "Can view production hours": "رؤية ساعات العمل",
  "Can view delivery hours": "رؤية ساعات التوصيل",
  "Can view delivery rates": "رؤية أسعار التوصيل",
  "Can edit delivery rates": "تعديل أسعار التوصيل",
  "Can edit delivery hours": "تعديل اوقات التوصيل",
  "Can edit production hours": "تعديل ساعات العمل",
  "Can add locations": "اضافة أفرع",
  "Can edit locations": "تعديل الأفرع",
  "Can edit locations settings": "تعديل اعدادات الأفرع",
  "Can view locations": "رؤية الأفرع",
  "Can create waitlists": "اضافه قائمة الانتظار",
  "Can view waitlists": "رؤية قائمة الانتظار",
  "Can edit waitlists": "تعديل قائمة الانتظار",
  "Can delete waitlists": "مسح قائمة الانتظار",
  "Can view businesses": "رؤية الشركات",
  "Can edit business orders": "تعديل طلبات الشركات",
  "Can view business orders": "رؤية طلبات الشركات",
  "Can edit cash payment method": "تعديل طريقة الدفع النقدي",
  "Can edit discounts": "تعديل الخصومات",
  can_edit_wallet: "تعديل المحفظة",
  "Can add businesses": "اضافة شركات",
  "Can edit businesses": "تعديل الشركات",
  "Can reverse order status": "الرجوع بحالة الطلب",
  "Can edit order payment status": "تعديل حالة الدفع",
  "Can edit branch catalog": "تعديل منتجات الأفرع",
  "Can edit branch working hours": "تعديل ساعات عمل الأفرع",
  "Can edit branch delivery hours": "تعديل ساعات توصيل الأفرع",
  "Can edit branch order limits": "تعديل حدود طلبات الأفرع",
  "Can edit branch areas": "تعديل مناطق توصيل الأفرع",
  "Can close own branch": "يمكن إغلاق الفرع الخاص",
  "Can close all branch": "يمكن إغلاق جميع الفروع",
  "Actions on selected users": "التحكم بالمستخدمين المحددين",
  "users selected": "مستخدم محددين",
  "Change role to": "تغيير الوظيفة الى",
  "OGO Email": "ايميل اوجو",
  "The *** role will not receive orders from areas in the left box":
    "الوظيفة (***) لا تسمح بإستقبال الطلبات من المناطق بالجهة اليمنى",
  "The *** role will receive orders from areas in the right box":
    "الوظيفة (***) تسمح بإستقبال الطلبات من المناطق بالجهة اليسرى",
  "Move areas from one side to another using the arrows in the middle":
    "يمكنك تحديد ثم نقل المناطق من جهة الى اخرى بإستخدام الاسهم في المنتصف",
  "The *** role will not be able to manage branches listed in the right box":
    "الوظيفة (***) لا يمكنها التحكم بالافرع المدرجة في الجهة اليمنى",
  "The *** role will be able to manage branches listed in the right box":
    "الوظيفة (***) يمكنها التحكم بالافرع المدرجة في الجهة اليسرى",

  "Role changed successfully": "تم تغيير الوظيفة بنجاح",
  "User created successfully": "تم انشاء المستخدم بنجاح",
  "Role name changed successfully": "تم تغيير اسم الوظيفة بنجاح",
  "Are you sure you want to delete this role? You can't undo this":
    "هل انت متأكد من حذف هذه الوظيفة؟ لا يمكنك الرجوع",
  "Role deleted successfully": "تم حذف الوظيفة بنجاح",
  "User deleted successfully": "تم حذف المستخدم بنجاح",
  "Delete this role?": "حذف هذه الوظيفة؟",
  "Move the associated users to another role and try again":
    "قم بنقل المستخدمين الى وظيفة اخرى ثم حاول الحذف",
  Users: "المستخدمين",
  "No users": "لا يوجد مستخدمين",
  "A user with that username already exists": "يوجد مستخدم آخر بهذا الاسم",
  "Change password": "تغيير كلمة السر",
  "Last Login": "اخر تسجيل دخول",
  "You can increase your users's limit from":
    "يمكنك زيادة الحد الاقصى للمستخدمين من",
  "New password": "كلمة السر الجديدة",
  "Changed password successfully": "تم تغيير كلمة السر بنجاح",
  "Integrations Permissions": "صلاحيات الدمج",
  "Can edit integrations": "تعديل اعدادات الدمج",
  "Can view integrations": "عرض اعدادات الدمج",
  "Can view users last login": "عرض اخر تسجيل دخول للمستخدمين",
  "Can view social section": "عرض قسم ادوات التسويق",
  "can skip address for delivery orders on the POS":
    "تخطي العنوان لطلبات التوصيل في نقطة البيع",
  "Can add users": "اضافة مستخدمين",
  "Add the areas used in permitted branches":
    "اضافة المناطق المستخدمة في الأفرع",
  "Billing Permissions": "صلاحيات الدفع",
  "Can view billing": "يمكن عرض الفواتير",
  "Are you sure you want to delete this user? You can't undo this":
    "هل انت متأكد من حذف هذا المستخدم؟ لا يمكنك الرجوع",
  "Audit Logs Permissions": "سجلات التدقيق",
  "Can view audit logs": "رؤية سجلات التدقيق",
  "Can edit branch closure": "التحكم بإغلاق الأفرع",
  "Can undispatch orders": "الغاء طلب سائق",
  "Can dispatch orders": "ارسال الطلب",
  "Can approve abandoned orders": "موافقة الطلبات المعلقة",
  "Can view abandoned orders": "رؤية قسم الطلبات المعلقة",
  "Feedback Permissions": "صلاحيات التعليقات",
  "Can view feedbacks": "رؤية صفحة التعليقات",
  "Can edit feedbacks": "تعديل اعدادات التعليقات",
  "Can view unpaid orders": "رؤية الطلبات مستحقة الدفع",
  "Can edit other users orders": "تعديل طلبات مستخدمين آخرين",
  "Can transfer orders": " تحويل الطلبات الى أفرع مختلفة",
  "Can copy link orders": "نسخ رابط الطلب",
  Reversals: "المسترجعات",
  "Permissions on reversing the state of orders e.g. payments, status, or dispatches.":
    "صلاحيات تتعلق بتعديل حالة الطلب للدفع او التوصيل",
  "Can refund orders": "استرجاع مبلغ الطلب",
  "Can cancel orders": "الغاء الطلبات",
  "Can list abandoned orders": "رؤية قائمة الطلبات المعلقة",
  "Can view orders limits": "رؤية الحد للطلبات",
  "Can edit orders limits": "تعديل الحد للطلبات",
  "Order Management": "ادارة الطلبات",
  "Permission on managing orders in pending or abandoned state.":
    "صلاحيات ادارة الطلبات المعلقة",
  "Permission on limiting the number of orders per day or time slot.":
    "صلاحيات تعديل الحد للطلبات في اليوم او في فترات العمل",
  "Are you sure you want to logout this user?":
    "هل انت متأكد من تسجيل خروج هذا المستخدم؟",
  "User logged out successfully": "تم تسجيل خروج المستخدم بنجاح",
  "Can edit DNS records": "التحكم بإعدادات ال DNS",
  "Can view DNS records": "رؤية إعدادات ال DNS",
  "DNS Manager": "إدارة ال DNS",
  "Manage your DNS records": "التحكم بإعدادات ال DNS",
  "Top-up your wallet and view transaction history":
    " اشحن محفظتك و اطلع على سجل عمليات الدفع",
  "You can't edit your DNS records. Please contact us for help":
    "لا يمكنك التحكم بإعدادات ال DNS. الرجاء التواصل معنا للمساعدة",
  "Can copy options": "نسخ الخيارات من/الى منتجات اخرى",
  "Please enter your new password": "الرجاء ادخال كلمة المرور الجديدة",
  "Confirm your password": "التأكيد على كلمة المرور",
  "Min 6 characters": "6 أحرف على الأقل",
  "You can change it later from Settings": "يمكنك تغييرها لاحقا من الاعدادات",
  "Can view filters": "رؤية الفلاتر",
  "Can edit filters": "تعديل الفلاتر",
  "Can create filters": "إنشاء الفلاتر",
  "Filters Permissions": "صلاحيات الفلاتر",
  "The *** role will not be able to manage branches listed in the left box":
    "الوظيفة *** لن يمكنها التحكم بالافرع المدرجة في الجهة اليمنى",
  "The *** role will not be able to manage branches listed in the left box":
    "الوظيفة *** لن يمكنها التحكم بالافرع المدرجة في الجهة اليمنى",
  "Marketing Permissions": "صلاحيات التسويق",
  "Can create sms campaigns": "انشاء حملات تسويق SMS",
  "Can only view own orders":
    "عرض الطلبات التي تم انشائها من قبل هذه المستخدم فقط",
  "Can make dashboard payments": "اجراء عمليات شراء في شاشة التحكم",
  "Can edit feedbacks": "يمكنه تعديل التعليقات",
  "Can view product feedbacks": "يمكن عرض التقيمات المنتج",
  "Can edit product feedbacks": "يمكن تعديل تعليقات المنتج",
  "Can make dashboard payments": "اجراء عمليات شراء في شاشة التحكم",
  "scroll for more": "المزيد",
  "Limit Access": "تقييد الصلاحيات",
  "Branch Hours": "اوقات العممل و التوصيل في الأفرع",
  "Permissions related to working and delivery hours of your store locations.":
    "صلاحيات تتعلق باوقات العمل و التوصيل في الأفرع",
  "Branch Settings": "اعدادات الأفرع",
  "Permissions for details of each branch such as catalog, zones served, and order limits.":
    "صلاحيات تتعلق بادارة تفاصيل اعدادات الأفرع مثل قائمة المنتجات، المناطق و المزيد",
  "Permissions on customers in the POS, Orders, or Customer List section.":
    "صلاحيات نحو ادارة معلومات العميل في نقطة البيع، قائمة العملاء او الطلبات",
  "Settings that impact Driver Management on the dashboard or application.":
    "صلاحيات تتعلق بادارة السواق",
  "Driver Management": "ادارة السائقين",
  "Master Areas & Rates": "المناطق و رسوم التوصيل الرئيسية",
  "Store Locations": "الأفرع",
  "Permissions to access areas and delivery rates in settings.":
    "صلاحيات تتعلق بالقائمة الرئيسية لاعدادات لرسوم التوصيل و المناطق ",
  "Settings related to managing store locations and branches":
    "صلاحيات تتعلق بادارة الأفرع",
  "Can make changes to your store's branch locations": "الصلاحية لتعديل الافرع",
  "Can delete locations": "ازالة الأفرع",
  "Can set own branches to busy": "صلاحية لاغلاق الفروع المخصصة للمستخدم ",
  "Can set all branches to busy": "صلاحية لاغلاق جميع الأفرع",
  switch: "تغيير",
  "Are you sure?": "تأكيد العملية؟",
  "Social Settings": "اعدادات ادوات التسويق",
  "Settings related to feedback after placing an order and on products reviews":
    "اعدادات تتعلق بتعليقات العملاء على الطلبات او المنتجات",
  "Permission on marketing tools like Pixels or Tags in Marketing.":
    "صلاحيات تتعلق بأدوات التسويق مثل البكسل ",
  "Permissions related to Point of Sale access and functionality.":
    "صلاحيات قسم نقطة البيع",
  "Can edit product price on the POS": "تعديل سعر المنتجات على نقطة البيع",
  "Can add a discount on the POS": "اضافة خصم على نقطة البيع",
  "Can skip address for delivery": "تخطي ادخال العنوان لطلبات التوصيل",
  "Can create orders for walk-in customers": "انشاء طلبات للزبائن المستلمين",
  Categories: "الفئات",
  Products: "المنتجات",
  "Sales Reporting": "تقارير المبيعات",
  "Billing & Payments": "دفع الاشتراكات",
  "User Management": "ادارة المستخدمين",
  "User Logs": "سجلات المستخدمين ",
  "Permissions to manage the settings page.": "صلاحيات ادارة الاعدادات",
  "Access to DNS management": "صلاحية تعديل اعدادات الDNS",
  "Permissions to access and manage integrations such as payment, delivery or POS.":
    "صلاحيات تعديل الربط مع خدمات الدفع او التوصيل او نقاط البيع",
  "Only use POS & see own orders":
    "  فقط استخدام نقطة البيع و رؤية طلبات المستخدم",
  "Settings here will significantly limit access for this role's user. Only change these settings if you know what you are doing.":
    "الاعدادات في هذا القسم تحد الكثير من صلاحيات المستخدم ، لا تقم بتغيير هذه الإعدادات إلا إذا كنت متأكدًا ",
  "Can edit popup banner": "تعديل اشعارات التنبيه",
  "Can create popup banner": "اضافة اشعارات التنبيه",
  "Can create new sources": "يمكن إنشاء مصادر جديدة",
};

export default trans;
