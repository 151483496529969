import React, { Component } from "react";
import { Radio, Badge, Button } from "antd";
import { connect } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment";
import { fetchPastOrders, cancelPastOrders } from "../../store/actions/orders";
import { withNamespaces } from "react-i18next";
import { Spin } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class RadioFilters extends Component {
  state = {
    rangeStart: moment().subtract(14, "days"),
    rangeEnd: moment(),
    loading: false,
  };

  hasRefund = () => {
    const { settings } = this.props;

    if (
      settings.enable_myfatoorah ||
      settings.enable_tahseeel ||
      settings.enable_tahseeel_credit ||
      settings.enable_tap ||
      settings.enable_sadad ||
      settings.enable_bookey ||
      settings.enable_bookey_credit ||
      settings.enable_tap_v2 ||
      settings.enable_hesabe ||
      settings.enable_upay ||
      settings.enable_upay_v2 ||
      settings.enable_tamara
    ) {
      return true;
    }

    return false;
  };

  monthPickerOnChange = (date, dateStr) => {
    let dateStart = date[0];
    let dateEnd = date[1];

    if (Math.abs(date[1].diff(date[0], "days")) > 30) {
      if (this.state.rangeStart !== date[0]) {
        dateEnd = date[0].clone().add(30, "days");
      } else if (this.state.rangeEnd !== date[1]) {
        dateStart = date[1].clone().subtract(30, "days");
      }
    }

    this.setState({
      rangeStart: dateStart,
      rangeEnd: dateEnd,
    });
    this.setState({ loading: true });
    this.props.fetchPastOrders(
      dateStart.format("YYYY-MM-DD"),
      dateEnd.format("YYYY-MM-DD")
    );
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.radioValue != "past" && this.props.pastOrdersLoading) {
      cancelPastOrders();
    }
    if (
      this.props.pastOrdersLoading == false &&
      prevProps.pastOrdersLoading == true
    ) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { t, settings, orders } = this.props;
    const config = this.props.config;
    function disabledDate(current) {
      return current && current > moment().endOf("day");
    }
    return (
      <>
        <div
          style={{
            margin: "15px 0px",
            textAlign: config.direction == "rtl" ? "right" : "left",
          }}
        >
          <Radio.Group
            defaultValue="all"
            value={this.props.radioValue2}
            buttonStyle="solid"
            onChange={this.props.onChange2}
          >
            <Radio.Button value="search" className="radio-hover round-corner">
              <SearchOutlined />
            </Radio.Button>
            <Radio.Button value="all" className="radio-hover round-corner">
              {t("All")}
            </Radio.Button>
            <Radio.Button value="new" className="radio-hover round-corner">
              <Badge
                count={
                  this.props.bellHasNewOrders
                    ? (orders.promises?.length || 0) +
                      (orders.new_orders?.length || 0)
                    : 0
                }
                style={{ top: -2, right: -8, zIndex: 10, direction: "ltr" }}
              >
                {t("New")}
              </Badge>
            </Radio.Button>

            {settings.enable_table_qr && (
              <Radio.Button value="open" className="radio-hover round-corner">
                <Badge
                  count={
                    orders.orders?.filter((o) => o.status === "Open")?.length
                  }
                  style={{ top: -2, right: -8, zIndex: 10, direction: "ltr" }}
                >
                  {t("Open")}
                </Badge>
              </Radio.Button>
            )}
            {!settings.enable_table_qr && (
              <>
                <Radio.Button
                  value="received"
                  className="radio-hover round-corner"
                >
                  {t("Received")}
                </Radio.Button>
                <Radio.Button
                  value="preparing"
                  className="radio-hover round-corner"
                >
                  {t("Preparing")}
                </Radio.Button>
                <Radio.Button
                  value="delivery"
                  className="radio-hover round-corner"
                >
                  {t("Delivering/Ready")}
                </Radio.Button>
              </>
            )}
            <Radio.Button value="complete" className="radio-hover round-corner">
              {t("Complete")}
            </Radio.Button>
            <Radio.Button
              value="cancelled"
              className="radio-hover round-corner"
            >
              {t("Cancelled")}
            </Radio.Button>
            {this.hasRefund() && (
              <Radio.Button
                value="refunded"
                className="radio-hover round-corner"
              >
                {t("Refunded")}
              </Radio.Button>
            )}
          </Radio.Group>
        </div>
        {!["new", "search", "open"].includes(this.props.radioValue2) && (
          <div
            style={{
              marginBottom: 15,
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            <Radio.Group
              defaultValue="today"
              buttonStyle="solid"
              value={this.props.radioValue}
              onChange={(e) => {
                this.props.onChange1(e);
                if (e.target.value == "past")
                  if (!this.props.didFetchPastOrders) {
                    const start = this.state.rangeStart.format("YYYY-MM-DD");
                    const end = this.state.rangeEnd.format("YYYY-MM-DD");
                    this.props.fetchPastOrders(start, end);
                  }
              }}
            >
              <Radio.Button value="today" className="radio-hover round-corner">
                {t("For Today")}
              </Radio.Button>
              <Radio.Button value="future" className="radio-hover round-corner">
                {t("Future")}
              </Radio.Button>
              <Radio.Button value="past" className="radio-hover round-corner">
                {t("Past")}
              </Radio.Button>
              {this.props.recentlyReceived.length > 0 && (
                <Radio.Button
                  value="recentlyReceived"
                  className="radio-hover round-corner"
                >
                  <Badge dot count={1} style={{ top: -2, right: -8 }}>
                    {t("Recently received")}
                  </Badge>
                </Radio.Button>
              )}
            </Radio.Group>
          </div>
        )}
        {this.props.radioValue == "past" &&
          this.props.radioValue2 != "search" &&
          this.props.radioValue2 != "new" && (
            <div className="my-3" style={{ direction: config.direction }}>
              <RangePicker
                picker="date"
                value={[this.state.rangeStart, this.state.rangeEnd]}
                onChange={this.monthPickerOnChange}
                allowClear={false}
                disabledDate={(current) => current > moment()}
                style={{ marginBottom: 0 }}
              />

              {this.props.pastOrdersLoading && this.state.loading && (
                <span className="mx-3">
                  <Spin indicator={antIcon} />
                </span>
              )}
              <div style={{ marginBottom: 10, color: "gray", fontSize: 10 }}>
                {t("* Max. 30 days")}
              </div>
            </div>
          )}
        {this.props.radioValue == "future" &&
          this.props.radioValue2 != "search" &&
          this.props.radioValue2 != "new" && (
            <div className="my-3" style={{ direction: config.direction }}>
              <RangePicker
                picker="date"
                value={[this.props.futureRangeStart, this.props.futureRangeEnd]}
                onChange={this.props.handleDateChange}
                allowClear={false}
                disabledDate={(current) => current < moment()}
                style={{ marginBottom: 10 }}
              />
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bellHasNewOrders: state.orders.bellHasNewOrders,
    config: state.config,
    pastOrders: state.orders.pastOrders,
    pastOrdersLoading: state.orders.loading,
    recentlyReceived: state.orders.recentlyReceived,
    didFetchPastOrders: state.orders.didFetchPastOrders,
    settings: state.auth.settings,
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPastOrders: (start, end) => dispatch(fetchPastOrders(start, end)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(RadioFilters));
