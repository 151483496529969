import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
// Antd Components
import {
  Modal,
  message,
  Radio,
  Select,
  Input,
  InputNumber,
  Switch,
  Alert,
  Button,
  Space
} from "antd";
import { phoneCodes } from "../Locations/phoneCodes";

// My components
const { Option } = Select;
// Actions

class EditPaymentType extends Component {
  state = {
    saveLoading: false,
    name:
      this.props.selectedOrder.first_name +
      " " +
      this.props.selectedOrder.last_name,
    phoneCode: "",
    phone: this.props.selectedOrder.phone,
    notes: this.props.selectedOrder.customer__notes,
    isBlacklist: this.props.selectedOrder.customer__is_blacklist,
    showMergeCustomerAlert: false,
  };

  save = async (merge) => {
    this.setState({ saveLoading: true });
    const { t, selectedOrder, user } = this.props;
    const { name, phoneCode, phone, notes, isBlacklist } = this.state;
    try {
      const res = await instance.post("/edit_customer_details/", {
        oid: selectedOrder.id,
        name: name,
        phone: phoneCode + phone,
        is_blacklist: isBlacklist,
        notes: notes,
        merge: merge,
      });
      if (res.data.success == true) {
        message.success(t("Edited successfully"));
        this.setState({ saveLoading: false });
        this.props.onClose();
      } else {
        if (res.data.phoneExist)
          this.setState({ showMergeCustomerAlert: true });
        else message.error(res.data.message || t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
  };

  componentDidMount = () => {
    let found = null;
    for (let i = 4; i >= 2; i--) {
      found = phoneCodes.find(
        (c) => c.code === this.props.selectedOrder.phone.substring(0, i)
      );
      if (found) {
        this.setState({
          phoneCode: found.code,
          phone: this.props.selectedOrder.phone.split(found.code)[1],
        });
        break;
      }
    }
  };

  saveButtonDisabled = () => {
    const { selectedOrder } = this.props;
    const { name, phoneCode, phone, isBlacklist, notes } = this.state;
    const prevCustomerName =
      selectedOrder.first_name + " " + selectedOrder.last_name;
    const newPhone = phoneCode + phone;
    if (
      prevCustomerName === name &&
      newPhone === selectedOrder.phone &&
      notes === selectedOrder.customer__notes &&
      isBlacklist === selectedOrder.customer__is_blacklist
    )
      return true;

    if (!newPhone || !name?.trim()) return true;
    if (!newPhone.startsWith("+")) return true;
    return false;
  };

  render() {
    const { config, t, selectedOrder, settings } = this.props;
    const { name, phoneCode, phone, showMergeCustomerAlert } = this.state;
    return (
      <>
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Edit customer details")}
            </div>
          }
          visible={true}
          onCancel={() => this.props.onClose()}
          onOk={() => this.save(false)}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: this.state.saveLoading,
            disabled: this.saveButtonDisabled(),
          }}
          width={300}
        >
          {showMergeCustomerAlert && (
            <Alert
              message={t(
                "Entered number already belongs to a customer, proceed?"
              )}
              type="warning"
              action={
                <Space direction="vertical">
                  <Button
                    size="small"
                    style={{
                      background: "orange",
                      color: "white",
                      borderColor: "white",
                      minWidth: 75,
                      borderRadius: 3,
                    }}
                    onClick={() => this.save(true)}
                  >
                    {t("Accept")}
                  </Button>
                  <Button
                    size="small"
                    type="ghost"
                    style={{
                      color: "orange",
                      borderColor: "orange",
                      minWidth: 75,
                    }}
                    onClick={() =>
                      this.setState({ showMergeCustomerAlert: false })
                    }
                  >
                    {t("Decline")}
                  </Button>
                </Space>
              }
              showIcon
            />
          )}
          <div className="pt-2">
            <p>{t("Name")}</p>
            <Input
              placeholder={t("Customer name")}
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
            />
            <p className="mt-3">{t("Phone")}</p>

            <Select
              showSearch
              style={{ width: "100%", float: "right" }}
              placeholder={t("Select a country")}
              optionFilterProp="children"
              value={this.state.phoneCode}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => this.setState({ phoneCode: e })}
            >
              <Option value={-1}>{t("Select a country")}</Option>
              {phoneCodes.map((code) => (
                <Option value={code.code}>{`${code.name} ${code.code}`}</Option>
              ))}
            </Select>

            <InputNumber
              value={this.state.phone}
              style={{
                width: "100%",
                marginTop: 15,
                marginBottom: 25,
                direction: "ltr",
                textAlign: "left",
              }}
              placeholder={t("Phone number")}
              onChange={(e) => this.setState({ phone: e })}
            />

            {settings.enable_blacklist && (
              <>
                <Switch
                  defaultChecked={this.state.isBlacklist}
                  onChange={(e) => this.setState({ isBlacklist: e })}
                />
                <span className="ml-3">{t("Flag this customer")}</span>

                <p className="mt-3">{t("Notes")}</p>
                <Input
                  style={{
                    marginBottom: 25,
                    direction: "ltr",
                    textAlign: "left",
                  }}
                  placeholder={t("Notes")}
                  onChange={(e) => this.setState({ notes: e.target.value })}
                  value={this.state.notes}
                />
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    user: state.auth.username,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // editPaymentType: (id, paymentType, paymentCType, user) =>
    //   dispatch(editPaymentType(id, paymentType, paymentCType, user)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EditPaymentType));
