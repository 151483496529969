const trans = {
  "SMS Campaigns": "دعايات ال SMS",
  "New Campaign": "حملة جديدة",
  Reach: "عدد العملاء",
  "Campaign name": "اسم الحملة",
  "Campaign purpose": "هدف الحملة",
  "Targeted segment": "الفئة المستهدفة",
  "SMS content": "نص رسالة ال SMS",
  "Ex: Checkout our offer on new products":
    "مثال: استمتع بالعروض على المنتجات الجديدة",
  "Create Campaign": "انشاء الحملة",
  "All customers": "جميع العملاء",
  Customize: "تخصيص",
  "Campaign cost": "تكلفة الحملة",
  "Current balance": "الرصيد الحالي",
  pointss: "نقاط",
  "Once you create this campaign, a SMS message will be sent to *** customers. Proceed?":
    "عند انشائك لهذه الحملة، سيتم ارسال رسالة نصية الى *** عميل. استمرار؟",
  "* Note:": "* ملاحظة:",
  '"From:': '"من :',
  "will be appended to the end of the message": "ستضاف الى نهاية نص الرسالة",
  "Increase retention rate": "زيادة الولاء",
  "Increase average order value": "زيادة متوسط ​​قيمة الطلب",
  "Increase engagement": "زيادة التفاعل",
  "Increase sales": "زيادة المبيعات",
  "Your store domain will be appended to the end of the message":
    "سيتم اضافة رابط متجرك الى نهاية الرسالة النصية",
  "Campaign Details": "تفاصيل الحملة",
  "Reach numbers": "أرقام العملاء",
};

export default trans;
