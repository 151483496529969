const trans = {
  "The last payment attempt": "محاولة الدفع الاخيرة",
  "on ***": "بتاريخ ***",
  "could not be completed": "قد فشلت",
  'Please check your card details. We will try again or you can click "Pay Now" to try now.':
    'الرجاء التحقق من البطاقة المحفوظة. سنقوم بالمحاولة مرة اخرى، او يمكن الضغط على "ادفع الان" لمحاولة الدفع يدويا',
  "Saved Cards": "البطاقات المحفوظة",
  "Add a Card": "اضافة بطاقة",
  "Deleted card successfully": "تم حذف البطاقة بنجاح",
  "Card is now set to backup": "تم جعل البطاقة احتياطية",
  "Card is now set to default": "تم جعل البطاقة رئسيسة",
  "Make a payment": "عملية دفع جديدة",
  "Make default": "جعل افتراضي",
  "Make backup": "جعل افتراضي",
  Expires: "تنتهي",
  "ending in": "بنهاية",
  "New Card": "بطاقة جديدة",
  "Card Details": "بيانات البطاقة",
  "Something wrong happened":"حدث خطأ ما",
  "Accepted Cards":"وسائل الدفع",
  "For for your convenience, we will securely save this as a preferred payment method on your account. We will not save your card details such as the card number, expiration, or security number. By pressing Pay Now, you agree to our terms and conditions.":
    "لراحتك، سنحفظ هذا بأمان كوسيلة دفع افتراضية على حسابك. لن نحفظ تفاصيل بطاقتك مثل رقم البطاقة، تاريخ الانتهاء، أو الرمز الأماني. بالضغط على الدفع الآن، أنت توافق على شروطنا وأحكامنا.",
  Proceed: "المتابعة",
  "Cancel subscription": "الغاء الاشتراك",
  "We are sorry to see you go. Your subscription will remain active until":
    "نأسف لرحيلك. سيبقى اشتراكك نشطًا حتى",
  "You can re-activate it at any time from this page.":
    "يمكنك إعادة تنشيطه في أي وقت من هذه الصفحة.",
  "If you have any concerns or issues": "إذا كان لديك أي مخاوف أو مشاكل",
  "Talk to our success team": "تحدث الى فريق الدعم",
  "Please choose a reason for cancellation to confirm and proceed.":
    "الرجاء اختيار سبب الالغاء ثم المتابعة",
  Reasons: "الاسباب",
  "Did not make sales": "لم يتم إجراء مبيعات",
  "Closing my business": "إغلاق عملي",
  "Not satisfied with customer support": "غير راضٍ عن دعم العملاء",
  "Product does not serve my business needs": "المنتج لا يخدم احتياجات عملي",
  "Price is too high": "السعر مرتفع جدًا",
  "Going to a different provider": "الانتقال إلى مزوِّد آخر",
  "Other reason": "سبب آخر",
  "Please share with us your cancellation reason": "يرجى مشاركتنا سبب إلغاءك",
  "Your cancellation reason": "سبب الالغاء",
  "Canceled your subscription successfully": "تم الغاء الاشتراك بنجاح",
  "Valid until": "صالح لغاية",
  Churned: "منسحب",
  "Resume subscription": "استئناف الاشتراك",
  "Add-ons": "الاضافات",
  "Resumed subscription successfully": "تم استئناف الاشتراك بنجاح",
  "Welcome back - we’re happy to continue serving you. You will be charged":
    "مرحبًا بعودتك - نحن سعداء بمواصلة خدمتك. سيتم خصم",
  "from your default saved card. To confirm, please click Proceed":
    "من بطاقتك المحفوظة الافتراضية. للتأكيد، يرجى الضغط على متابعة",
  "By paying now, you agree to be charged":
    "عند قيامك بالدفع الآن، أنت توافق على خصم",
  "on the": "بتاريخ",
  "Install Add-On": "تفعيل الاضافة",
  "By installing this add-on, you agree to be charged from your default card. Would you like to proceed?":
    "بتثبيت هذه الإضافة، أنت توافق على خصم الرسوم من بطاقتك المحفوظة الافتراضية. هل ترغب في المتابعة؟",
  "The payment will be charged from your default saved card":
    "سيتم خصم المبلغ من بطاقتك المحفوظة الافتراضية",
  "By topping up your wallet, you agree to be charged from your default card. Would you like to proceed?":
    "لتعبئة رصيد محفظتك، أنت توافق على خصم الرسوم من بطاقتك المحفوظة الافتراضية. هل ترغب في المتابعة؟",
  "Pay Invoice": "دفع فاتورة",
  "By paying this invoice, you agree to be charged from your default card. Would you like to proceed?":
    "لدفع هذه الفاتورة، أنت توافق على خصم الرسوم من بطاقتك المحفوظة الافتراضية. هل ترغب في المتابعة؟",
  "Paid invoice successfully": "تم دفع الفاتورة بنجاح",
};

export default trans;
