import { JOIN_SOCKET } from "../actions/actionTypes";

const initialState = {
  socket: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case JOIN_SOCKET:
      return { ...state, socket: payload };

    default:
      return state;
  }
};
