import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../../store/actions/instance";
// Antd components
import {
  Modal,
  Select,
  Radio,
  Button,
  Row,
  Col,
  message,
  Checkbox,
} from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
//My Components

import NewAddressModal from "./NewAddressModal";

//Action
import { setCurrentBranch, setAddressPOS } from "../../../store/actions";
import { setDeliveryChargePOS } from "../../../store/actions/pos";
const { Option } = Select;

class LocationModal extends Component {
  constructor(props) {
    super(props);
    let selectedBranch = null;

    if (props.branch) {
      selectedBranch = props.branches.find((b) => b.id === props.branch);
    }

    let state = {
      branchId: selectedBranch ? selectedBranch.id : null,
      branchName: selectedBranch ? selectedBranch.name : "",
      orderType: selectedBranch ? "Pick Up" : "Delivery",
      deliveryAddress: null,
      billingAddress: null,
      customerAddresses: [],
      newAddressModalVisible: false,
      loading: false,
      editMode: false,
      noAddress: this.props.address?.noAddress || false,
    };

    this.state = props.address
      ? {
          ...props.address,
          loading: false,
          editMode: false,
          branchId:
            props.address?.branchId || selectedBranch
              ? selectedBranch?.id
              : null,
          branchName:
            props.address?.branchName || selectedBranch
              ? selectedBranch?.name
              : "",
        }
      : state;
  }

  buttonDisabled = () => {
    const { orderType, deliveryAddress, billingAddress, branchId, noAddress } =
      this.state;

    if (
      orderType === "Delivery" &&
      (!deliveryAddress || !billingAddress) &&
      !noAddress
    ) {
      return true;
    } else if (orderType === "Pick Up" && !branchId) {
      return true;
    }
    return false;
  };

  setAddress = (address) => {
    this.props.setAddressPOS(address);
  };
  setDeliveryCharge = (deliveryCharge) => {
    const allCharged = this.props.cart.every((product) => !product["chargeDelivery"]);
    this.props.setDeliveryChargePOS({
      deliveryCharge: this.props.cart.length && allCharged ? 0 : deliveryCharge,
      fromPage: "locations",
    });
  };

  openOrderSettingsModal = () => {
    this.props.setStateIndex({ orderSettingsModalVisible: true });
  };
  saveAddressSettings = async () => {
    const { orderType, deliveryAddress, noAddress } = this.state;
    const { branch, branches, deliveryAreas, cart, settings } = this.props;

    this.setAddress({ ...this.state });

    let delivery_charge = 0;
    if (orderType === "Delivery" && !noAddress) {
      if (
        settings.enable_dhl &&
        settings.enable_dhl_dynamic_rate &&
        this.isInternationalDelivery()
      ) {
        delivery_charge = "TBD";
      } else if (branch && settings.is_branch_based_ordering) {
        let deliveryBranchArea = branches
          .find((b) => b.id === branch)
          .areas.find(
            (area) => area.area_id === deliveryAddress.address.area_id
          );
        if (!deliveryBranchArea) {
          this.props.onClose();
          return;
        }
        delivery_charge = deliveryBranchArea?.override_delivery_charge
          ? deliveryBranchArea.override_price
          : deliveryBranchArea.original_price;
      } else {
        let deliveryArea = deliveryAreas.find(
          (area) => area.area_id === deliveryAddress.address.area_id
        );
        if (!deliveryArea) {
          this.props.onClose();
          return;
        }

        if (deliveryArea) {
          delivery_charge = deliveryArea.price;
        }
      }
    }
    this.setDeliveryCharge(delivery_charge);
    if (this.props.ContinueCheckoutAfterModal && cart.length) {
      if (delivery_charge != "TBD") this.openOrderSettingsModal();
    }
    this.props.onClose();
  };
  openNewAddressModal = (editMode = false) => {
    this.setState({
      newAddressModalVisible: true,
      editMode: editMode,
    });
  };
  getAddressBeingEdited = () => {
    const { deliveryAddress, customerAddresses, editMode } = this.state;

    if (!editMode) return {};

    if (deliveryAddress?.type === "old") {
      return (
        customerAddresses.find((ad) => ad.key === deliveryAddress.id) || {}
      );
    }
    return deliveryAddress?.address || {};
  };

  setCreatedAddresses = (createdAddresses) => {
    this.props.setStateIndex({ createdAddresses: createdAddresses });
  };

  isInternationalDelivery = () => {
    console.log(this.state.deliveryAddress.country, this.props.baseCountry);
    if (
      this.state.deliveryAddress.address.country != this.props.baseCountry &&
      this.props.settings.enable_dhl &&
      this.state.orderType === "Delivery"
    ) {
      return true;
    }
    return false;
  };

  componentDidMount = async () => {
    const { customer, createdAddresses, branches, branch, settings } =
      this.props;

    if (customer && customer.customerType === "Old") {
      this.setState({ loading: true });
      let res = await instance.post("get_customer_addresses/", {
        key: customer.customerId,
      }); //Change later to fetch only the addresses
      let addresses = res.data;

      if (branch && settings.is_branch_based_ordering) {
        let deliveryBranchAreas =
          branches.find((b) => b.id === branch)?.areas || [];

        addresses = addresses.filter((area) =>
          deliveryBranchAreas.find((a) => area.area_id === a.area_id)
        );
      }
      this.setState({
        customerAddresses: addresses.concat(createdAddresses),
        loading: false,
      });
    }
  };

  addNewAddress = (newAddress) => {
    let newCustomerAddresses = this.state.customerAddresses;

    const address_object = {
      type: "new",
      id: newAddress.key,
      address: newAddress,
    };
    newCustomerAddresses.push(newAddress);

    this.setState({
      deliveryAddress: address_object,
      billingAddress: address_object,
    });
    this.setState({ customerAddresses: newCustomerAddresses });
  };
  getAddressText = (address) => {
    let keys = [
      "country",
      "area",
      "block",
      "street",
      "building",
      "floor",
      "apartment",
      "avenue",
      "additional",
    ];

    if (this.props.config.language === "arabic") {
      keys[0] = "ar_country";
      keys[1] = "ar_area";
    }
    let addressString = "";
    let value;
    keys.forEach((key) => {
      value = address[key];
      if (value) {
        addressString = addressString.concat(value, ",");
      }
    });

    addressString = addressString.slice(0, -1);

    return addressString;
  };

  deliverySelect = React.createRef();
  billingSelect = React.createRef();
  render() {
    const { t, config, visible, branches, createdAddresses, branch } =
      this.props;
    const {
      orderType,
      fetchingBranches,
      deliveryAddress,
      billingAddress,
      newAddressModalVisible,
      branchId,
      customerAddresses,
      loading,
      editMode,
    } = this.state;

    let pickup_branches = branches;
    if (branch) {
      pickup_branches = pickup_branches.filter((b) => b.id === branch);
    }

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Delivery Details")}
          </div>
        }
        visible={visible}
        cancelText={t("Close")}
        onCancel={this.props.onClose}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
        footer={[
          <Button
            disabled={this.buttonDisabled()}
            block
            key="submit"
            type="primary"
            onClick={this.saveAddressSettings}
            className="posActionButton"
          >
            {t("Ok")}
          </Button>,
        ]}
      >
        {newAddressModalVisible && (
          <NewAddressModal
            visible={newAddressModalVisible}
            addNewAddress={this.addNewAddress}
            setCreatedAddresses={this.setCreatedAddresses}
            createdAddresses={createdAddresses}
            branch={branch}
            editMode={editMode}
            addressBeingEdited={this.getAddressBeingEdited()}
            onClose={() => {
              this.setState({
                newAddressModalVisible: false,
                editMode: false,
              });
            }}
          />
        )}

        <div className="px-2 mb-3 mt-3">
          <div className="text-center">
            <Radio.Group
              defaultValue="products"
              buttonStyle="solid"
              className="my-3  w-100"
              value={orderType}
              onChange={(e) => {
                this.setState({ orderType: e.target.value });
              }}
            >
              {branch && (
                <Radio.Button className="posRadioButton" value="Pick Up">
                  {t("Pickup")}
                </Radio.Button>
              )}
              <Radio.Button className="posRadioButton" value="Delivery">
                {t("Delivery")}
              </Radio.Button>
            </Radio.Group>
          </div>

          {orderType === "Delivery" &&
            this.props.permissions.can_skip_address_pos && (
              <Row style={{ justifyContent: "center", marginTop: 20 }}>
                <Checkbox
                  defaultChecked={this.state.noAddress}
                  onChange={(e) => {
                    this.setState({ noAddress: e.target.checked });
                  }}
                >
                  {t("Skip address")}
                </Checkbox>
              </Row>
            )}

          {orderType === "Delivery" && !this.state.noAddress && (
            <>
              <Row
                justify="space-between"
                className="my-4 pointer"
                onClick={() => this.openNewAddressModal(false)}
              >
                <Col>{t("Add a new address")}</Col>
                <Col>
                  <PlusCircleOutlined
                    style={{ color: "#0099CC", fontSize: 20, top: 5 }}
                    key="new_address"
                  />
                </Col>
              </Row>
              <div className="mt-4">
                <p>
                  {t("Deliver to")}{" "}
                  {deliveryAddress && (
                    <span
                      style={{
                        color: "#0099CC",
                        fontSize: 13,
                        marginTop: 8,
                        cursor: "pointer",
                        marginRight: 2,
                        marginLeft: 2,
                        float:
                          (config.language === "arabic" && "left") || "right",
                      }}
                      onClick={() => this.openNewAddressModal(true)}
                    >
                      <EditOutlined className="px-1" />
                      {t("Edit")}
                    </span>
                  )}
                </p>
                <Select
                  ref={this.deliverySelect}
                  loading={loading}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={t("Select Delivery Address")}
                  optionFilterProp="children"
                  value={deliveryAddress ? deliveryAddress.id : undefined}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    const select = this.deliverySelect.current;
                    if (select) {
                      select.blur();
                    }
                    const address = customerAddresses.find((a) => a.key === e);
                    const address_object = {
                      type: !address.isNewAddress ? "old" : "new",
                      id: e,
                      address: address,
                    };

                    this.setState({
                      deliveryAddress: address_object,
                      billingAddress: address_object,
                    });
                  }}
                >
                  {customerAddresses.length
                    ? customerAddresses.map((address) => (
                        <Option value={address.key}>
                          {this.getAddressText(address)}
                        </Option>
                      ))
                    : null}
                </Select>
              </div>
              <div className="mt-3 mb-4">
                <p>{t("Bill to")} </p>
                <Select
                  loading={loading}
                  ref={this.billingSelect}
                  showSearch
                  value={billingAddress ? billingAddress.id : undefined}
                  style={{ width: "100%" }}
                  placeholder={t("Select Billing Address")}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    const select = this.billingSelect.current;
                    if (select) {
                      select.blur();
                    }
                    const address = customerAddresses.find((a) => a.key === e);

                    this.setState({
                      billingAddress: {
                        type: !address.isNewAddress ? "old" : "new",
                        id: e,
                        address: address,
                      },
                    });
                  }}
                >
                  {customerAddresses.length
                    ? customerAddresses.map((address) => (
                        <Option value={address.key}>
                          {this.getAddressText(address)}
                        </Option>
                      ))
                    : null}
                </Select>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    customers: state.customers.customers,
    branches: state.locations.branches,
    deliveryAreas: state.deliverySettings.areas,
    cart: state.pos.cart,
    settings: state.auth.settings,
    address: state.pos.address,
    customer: state.pos.customer,
    pos: state.pos,
    permissions: state.auth.permissions,
    baseCountry: state.auth.settings.base_country,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentBranch: (branch) => dispatch(setCurrentBranch(branch)),
    setAddressPOS: (params) => dispatch(setAddressPOS(params)),
    setDeliveryChargePOS: (params) => dispatch(setDeliveryChargePOS(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(LocationModal));
