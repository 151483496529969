import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../../store/actions/instance";
import { GetEnglishOrArabic } from "../";
// Antd components
import { Modal, Input, Select, message, Row, Col, Button } from "antd";

// Actions
import {
  saveCountriesAreas,
  fetchStoreBranches,
  fetchBranchDeliveryCharges,
} from "../../../store/actions/locations";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

class NewAddressModal extends Component {
  state = {
    country: this.props.addressBeingEdited?.country || "",
    ar_country: this.props.addressBeingEdited?.ar_country || "",
    area: this.props.addressBeingEdited?.area || "",
    ar_area: this.props.addressBeingEdited?.ar_area || "",
    addressName: "",
    block: this.props.addressBeingEdited?.block || "",
    street: this.props.addressBeingEdited?.street || "",
    avenue: this.props.addressBeingEdited?.avenue || "",
    building: this.props.addressBeingEdited?.building || "",
    floor: this.props.addressBeingEdited?.floor || "",
    apartment: this.props.addressBeingEdited?.apartment || "",
    additional: this.props.addressBeingEdited?.additional || "",
    area_id: this.props.addressBeingEdited?.area_id || null,
    country_id: this.props.addressBeingEdited?.country_id || null,
    loading: false,
    editAddressLoading: false,
  };
  buttonDisabled = () => {
    const {
      area_id,
      country_id,
      block,
      street,
      avenue,
      building,
      floor,
      apartment,
      additional,
    } = this.state;
    const { addressBeingEdited, editMode } = this.props;

    if (!area_id || !country_id) {
      return true;
    }
    if (
      !editMode ||
      addressBeingEdited?.block !== block ||
      addressBeingEdited?.street !== street ||
      addressBeingEdited?.avenue !== avenue ||
      addressBeingEdited?.building !== building ||
      addressBeingEdited?.floor !== floor ||
      addressBeingEdited?.apartment !== apartment ||
      addressBeingEdited?.additional !== additional ||
      addressBeingEdited?.area_id !== area_id ||
      addressBeingEdited?.country_id !== country_id
    ) {
      return false;
    }
    return true;
  };
  componentDidMount = async () => {
    let {
      branch,
      locations,
      branches,
      fromInvoicePage,
      permissions,
      is_staff,
    } = this.props;
    if (!locations.countries.length) {
      this.setState({ loading: true });
      await this.fetchCountries();
      this.setState({ loading: false });
    } else if (!this.state.country_id) {
      let first_country = locations.countries[0];
      this.setState({
        country: first_country.name,
        ar_country: first_country.ar_name,
        country_id: first_country.id,
      });
    }
    this.areas = this.props.locations.areas.filter(
      (a) => permissions.areas.includes(a.id) || is_staff
    );

    if (branch && this.props.settings.is_branch_based_ordering) {
      if (fromInvoicePage && !branches.length) {
        // await
        this.setState({ loading: true });
        await this.props.fetchStoreBranches();
      }
      if (fromInvoicePage) {
        this.setState({ loading: true });
        await this.props.fetchBranchDeliveryCharges(branch);
        branches = this.props.branches;
        this.setState({ loading: false });
      }
      let deliveryBranchAreas =
        branches.find((b) => b.id === branch)?.areas || [];

      this.areas = this.areas.filter((area) =>
        deliveryBranchAreas.find((a) => area.id === a.area_id && a.is_active)
      );
    }

    this.setState({});
  };
  fetchCountries = async () => {
    const { t, fromInvoicePage } = this.props;
    const { country_id } = this.state;
    try {
      const res = await instance.get(`/fetch_countries/`);
      if (res.data.success == true) {
        this.props.saveCountriesAreas(res.data.countries_areas);

        if (res.data.countries_areas.countries.length && !country_id) {
          let first_country = res.data.countries_areas.countries[0];
          this.setState({
            country: first_country.name,
            ar_country: first_country.ar_name,
            country_id: first_country.id,
          });
        }
      } else message.error(t("Could not fetch country list"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
  addNewAddress = async () => {
    if (!this.props.fromInvoicePage) {
      let createdAddresses = this.props.createdAddresses;
      let newAddress = {
        ...this.state,
        key: `CustomAddress${createdAddresses.length}`,
        isNewAddress: true,
      };
      this.props.addNewAddress(newAddress);
      createdAddresses.push(newAddress);
      this.props.setCreatedAddresses(createdAddresses);
      this.props.onClose();
    } else {
      this.setState({ editAddressLoading: true });
      await this.props.addNewAddress({
        address: { ...this.state },
        type: "new",
      });
      this.setState({ editAddressLoading: false });
      this.props.onClose();
    }
  };

  areas = [];

  countrySelect = React.createRef();
  areaSelect = React.createRef();
  render() {
    const { t, config, visible, locations, addressBeingEdited, editMode } =
      this.props;

    const {
      country,
      country_id,
      loading,
      area_id,
      ar_country,
      area,
      ar_area,
      block,
      street,
      avenue,
      building,
      floor,
      apartment,
      additional,
      editAddressLoading,
    } = this.state;
    const areaText = country == "Qatar" ? "District" : "Area";
    const blockText = country == "Qatar" ? "Zone" : "Block";

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {editMode ? t("Edit Address") : t("New Address")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            loading={editAddressLoading}
            className="posActionButton"
            block
            disabled={this.buttonDisabled()}
            key="submit"
            type="primary"
            onClick={this.addNewAddress}
          >
            {t("Ok")}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <Row gutter={15}>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="mt-3"
          >
            <p>{t("Country")}</p>
            <Select
              ref={this.countrySelect}
              loading={loading}
              value={country_id}
              style={{ width: "100%", float: "right" }}
              showSearch
              placeholder={t("Select a country")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                const select = this.countrySelect.current;
                if (select) {
                  select.blur();
                }
                const country = locations.countries.find((c) => c.id === e);
                this.setState({
                  ar_country: country.ar_name,
                  country: country.name,
                  country_id: e,
                  ar_area: "",
                  area: "",
                  area_id: null,
                });
              }}
            >
              {locations.countries.length
                ? locations.countries.map((country) => (
                    <Option value={country.id}>
                      {GetEnglishOrArabic(
                        country.name,
                        country.ar_name,
                        config.language
                      )}
                    </Option>
                  ))
                : null}
            </Select>
          </Col>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="mt-3"
          >
            <p>
              {this.state.country === "International"
                ? t("Country")
                : t(areaText)}
            </p>
            <Select
              ref={this.areaSelect}
              loading={loading}
              showSearch
              value={area_id || undefined}
              style={{ width: "100%", float: "right" }}
              placeholder={
                this.state.country === "International"
                  ? t("Country")
                  : t(areaText)
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                const select = this.areaSelect.current;
                if (select) {
                  select.blur();
                }
                const area = locations.areas.find((a) => a.id === e);
                this.setState({
                  ar_area: area.ar_name,
                  area: area.name,
                  area_id: e,
                });
              }}
            >
              {this.areas.length
                ? this.areas
                    .filter((a) => a.country_id === country_id)
                    .map((area) => (
                      <Option value={area.id}>
                        {config.language == "arabic"
                          ? `${
                              area.province__ar_name
                                ? `${area.province__ar_name} - `
                                : ""
                            }${area.ar_name}`
                          : `${
                              area.province__name
                                ? `${area.province__name} - `
                                : ""
                            }${area.name}`}
                      </Option>
                    ))
                : null}
            </Select>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12} className="mt-3">
            <p className="">
              {this.state.country === "International"
                ? t("Address line 1")
                : t(blockText)}
            </p>
            <Input
              placeholder={
                this.state.country === "International"
                  ? t("Address line 1")
                  : t(blockText)
              }
              value={block}
              onChange={(e) =>
                this.setState({ block: e.target.value.trimStart() })
              }
            />
          </Col>
          <Col span={12} className="mt-3">
            <p className="">
              {this.state.country === "International"
                ? t("Address line 2")
                : t("Street")}
            </p>
            <Input
              value={street}
              placeholder={
                this.state.country === "International"
                  ? t("Address line 2")
                  : t("Street")
              }
              onChange={(e) =>
                this.setState({ street: e.target.value.trimStart() })
              }
            />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12} className="mt-3">
            <p className="">
              {this.state.country === "International"
                ? t("Postcode")
                : t("Avenue")}
            </p>
            <Input
              value={avenue}
              placeholder={
                this.state.country === "International"
                  ? t("Postcode")
                  : t("Avenue")
              }
              onChange={(e) =>
                this.setState({ avenue: e.target.value.trimStart() })
              }
            />
          </Col>
          {this.state.country !== "International" && (
            <Col span={12} className="mt-3">
              <p className="">{t("Building")}</p>
              <Input
                value={building}
                placeholder={t("Building")}
                onChange={(e) =>
                  this.setState({ building: e.target.value.trimStart() })
                }
              />
            </Col>
          )}
        </Row>
        {this.state.country !== "International" && (
          <Row gutter={15}>
            <Col span={12} className="mt-3">
              <p className="">{t("Floor")}</p>
              <Input
                value={floor}
                placeholder={t("Floor")}
                onChange={(e) =>
                  this.setState({ floor: e.target.value.trimStart() })
                }
              />
            </Col>
            <Col span={12} className="mt-3">
              <p className="">{t("Apartment")}</p>
              <Input
                value={apartment}
                placeholder={t("Apartment")}
                onChange={(e) =>
                  this.setState({ apartment: e.target.value.trimStart() })
                }
              />
            </Col>
          </Row>
        )}
        {this.state.country !== "International" && (
          <Row gutter={15} className="mb-4 ">
            <Col span={24} className="mt-3">
              <p className="">{t("Additional")}</p>
              <Input
                value={additional}
                placeholder={t("Additional")}
                onChange={(e) =>
                  this.setState({ additional: e.target.value.trimStart() })
                }
              />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    locations: state.locations,
    branches: state.locations.branches,
    settings: state.auth.settings,
    permissions: state.auth.permissions,
    is_staff: state.auth.is_staff,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBranchDeliveryCharges: (bid) =>
      dispatch(fetchBranchDeliveryCharges(bid)),
    saveCountriesAreas: (countries) => dispatch(saveCountriesAreas(countries)),
    fetchStoreBranches: () => dispatch(fetchStoreBranches()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewAddressModal));
