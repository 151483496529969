let tr;

export default tr = {
  "New promotion": "إضافة عرض",
  "Edit promotion": "تعديل عرض",
  "Search for a promotion": "ابحث عن عرض",
  "Are you sure you want to delete this promotion?":
    "هل انت متأكد من حذف هذا العرض؟",
  "Promotion was deleted successfully": "تم حذف العرض بنجاح",
  "The promotion is already deleted": "تم حذف هذا العرض مسبقا",
  "Promotion created successfully": "تم إنشاء العرض بنجاح",
  "Could not change promotions's image": "لم نقدر على تغير صورة العرض",
  "Promotion updated successfully": "تم تحديث العرض بنجاح",
  "Extend Promotion": "تمديد العرض",
  "Promotion extended successfully": "تم تمديد العرض بنجاح",
  "Edit Promotion": "تعديل العرض",
  "New Promotion": "عرض جديد",
  "Conditions & Discounts": "الشروط و الخصمومات",
  "Finalize Promotion": "اللمسات الأخيرة",
  "Buys any": "يشتري اي",
  items: "منتجات",
  item: "منتج",
  Spends: "يصرف",
  "or more": "او اكثر",
  "Uses promo code": "يستخدم كود",
  Buys: "يشتري",
  Gets: "يحصل على",
  off: "خصم",
  "free delivery": "توصيل مجاني",
  "How many ?": "كم العدد؟",
  "How much ?": "كم المبلغ؟ ",
  "select product:": "اختر منتج:",
  "select product": "اختر منتج",
  "Specify % discount": "حدد النسبة %",
  "Add a condition": "اضف شرط",
  "Add condition": "اضف الشرط",
  "Buying a product": "شراء منتج",
  "Buying a number of items": "شراء عدد من المنتجات",
  "Spending a certain amount": "صرف مبلغ معين",
  "Using promo code": "إستخدام كود",
  "Loading products": "تحميل المنتجات",
  "Select a product:": "اختر المنتج:",
  "Enter promo code": "أدخل الكود",
  "Add discount": "اضف خصم",
  "Add a discount": "اضف الخصم",
  "A percent amount": "نسبة",
  "A fixed amount": "مبلغ محدد",
  "Free delivery": "توصيل مجاني",
  "A free delivery": "توصيل مجاني",
  "A delivery discount": "خصم على التوصيل",
  "off delivery charge": "على سعر التوصيل",
  "A free product": "منتج مجاني",
  "A category discount": "خصم على صنف",
  Nothing: "لا شيء",
  "Uses the website": "يستخدم الموقع",
  "If a customer :": "عندما العميل:",
  "Then a customer will get :": "اذا العميل سوف يحصل على:",
  "You can limit days and time where this promotion is available":
    " يمكنك تحديد الايام والوقات التي سيتم فيها إظهار العرض",
  Next: "التالي",
  Back: "الرجوع",
  "Only apply once?": "إستخدام مرة واحدة؟",
  "English Name": "اللإسم باللإنجليزي",
  "Arabic Name": "اللإسم العربي",
  "Add Promotion": "اضف العرض",
  From: "من",
  To: "الى",
  "Every day at any time": "كل يوم في جميع الأوقات",
  "promotions selected": "عروض محددة",
  "Deleted promotions successfully": "تم حذف العروض بنجاح",
  "active promotions": "عروض مفعلة",
  "inactive promotions": "عروض معطلة",
  "Are you sure you want to delete all selected promotions? You can't undo this action":
    "هل انت متأكد من حذف جميع العروض المحددة؟ لا يمكنك الرجوع",
  "Actions on selected promotions": "التحكم في العروض المحددة",
  "Exclude products from the promotion": "إستبعاد بعض المنتجات من العرض",
  "Excluded Products": "المنتجات المستبعدة",
  "Excluded Modifiers": "الإضافات المستبعدة",
  "Hide expired promotions": "إخفاء العروض المنتهية الصلاحية",
  "Buying from categories": "شراء من أصناف محددة",
  "Using a specific service type": "إستخدام خدمة محددة",
  "Ordering from specific areas": "شراء من مناطق محددة",
  "Ordering from specific branches": "شراء من أفرع محددة",
  "Ordering from specific countries": "شراء من دول محددة",
  "Select a service:": "اختر خدمة:",
  "Select a service": "اختر خدمة",
  Uses: "يستخدم",
  "Select areas:": "اختر المناطق:",
  "Select areas": "اختر المناطق",
  "Select branches:": "اختر الأفرع:",
  "Select branches": "اختر الأفرع",
  "Loading branches": "تحميل الأفرع",
  "Loading areas": "تحميل المناطق",
  pickup: "إستلام",
  "Orders from any of these areas:": "يطلب من احد هذه المناطق:",
  "Orders from any of these branches:": "يطلب من احد هذه الأفرع:",
  "Hide promotion from store": "إخفاء العرض من المتجر",
  "Apply promotion to specific branches": "تفعيل العرض في أفرع محددة",
  "A categories discount": "خصم على أصناف",
  "A products discount": "خصم على منتجات",
  "A modifiers discount": "خصم على إضافات",
  "Select categories:": "إختر الأصناف:",
  "Select categories": "إختر الأصناف",
  "Select products:": "إختر المنتجات:",
  "Select products": "إختر المنتجات",
  "Select modifiers:": "إختر الإضافات:",
  "Select modifiers": "إختر الإضافات",
  "Exclude categories from the promotion": "إستبعاد بعض الأصناف من العرض",
  "Excluded Categories": "الأصناف المستبعدة",
  "Orders from any of these countries:": "يطلب من احد هذه الدول:",
  "Select countries:": "إختر الدول:",
  "Select countries": "إختر الدول",
  Targetting: "المستهدفون",
  "All Customers": "جميع العملاء",
  "Target Customers": "العملاء المستهدفون",
  Filter: "تصفية",
  "Detail Targetting": "تفاصيل المستهدفين",
  "First Order": "الطلب الأول",
  "Last Order": "الطلب الأخير",
  "Pick Customer's Segments": "فئات العملاء",
  "Number Of Orders": "عدد الطلبات",
  "Customers Who Purchased": "الطلبات",
  Spendings: "المبيعات",
  Risk: "في خطر",
  Other: "آخرون",
  "At risk": "في خطر",
  Selected: "محددين",
  Select: "حدد",
  "Send Email to Customers": "إرسال بريد الى العملاء",
  "Email's Body": "مضمون البريد",
  "Exclude modifiers from the promotion": "إستبعاد بعض الإضافات من العرض",
  "Spending loyalty points": "صرف نقاط",
  "Loyalty Points": "نقاط",
  "Maximum number of times each customer can use this promotion":
    "الحد الاقصى للاستخدام لكل عميل",
  "Set the maximum number of times a customer can use this promotion":
    "اضف حد الاقصى للاستخدام لكل عميل",
  "Filling this field will make this promotion only applicable to logged in users":
    "ملء هذه الخانه سوف يجعل العرض فعال للعملاء المسجلين فقط",
  "Points conversion rate": "معدل تحويل النقاط",
  "Top-ups": "تعبئات الرصيد",
  "top-up": "تعبئة",
  "Free products": "منتجات مجانية",
  "free products from": "منتجات مجانية من",
  "Promotion edited successfully": "تم تعديل العرض بنجاح",
  "Applicable to online orders": "متاح لطلبات الموقع",
  "Applicable to pos orders": "متاح لطلبات نقطة البيع",
  "Using a specific delivery/pickup type": "استخدام نوع توصيل/استلام محدد",
  "Select a delivery/pickup type:": "اختر نوع التوصيل/الاستلام:",
  ASAP: "في اقرب وقت",
  Scheduled: "توصيل لاحقا",
  "Apply for cash orders": "تفعيل الخصم للطلبات النقدية",
  "Expiry Date": "تاريخ الانتهاء",
  "Is Expired?": "منتهي الصلاحية؟",
  "Total Redeemed Points": "إجمالي النقاط المستردة",
  "Add Points": "أضف نقاط",
  "Earned/Spent": "كسب/صرف",
  Earned: "كسب",
  Spent: "صرف",
  "Points History": "سجل النقاط",
  "Show More": "إظهار المزيد",
  "Show Less": "إخفاء",
};
