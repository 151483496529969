import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
// Antd Components
import { Modal, message, Radio } from "antd";
import { editPaymentType } from "../../store/actions/orders";
import CustomPaymentModal from "../POS/CustomPaymentModal";

// My components

// Actions

class EditPaymentType extends Component {
  state = {
    saveLoading: false,
    paymentType: this.props.selectedOrder.payment_method,
    paymentCType: this.props.selectedOrder.payment_ctype,
    paymentReference: this.props.selectedOrder.payment_reference || "",
    customPaymentModallVisible: false,
  };

  save = async () => {
    this.setState({ saveLoading: true });
    const { t, selectedOrder, user } = this.props;
    const { paymentType, paymentCType, paymentReference } = this.state;
    try {
      const res = await instance.post("/edit_payment_type/", {
        paymentType: paymentType,
        paymentCType: paymentCType,
        paymentReference: paymentReference,
        id: selectedOrder.id,
      });

      if (res.data.success == true) {
        this.props.editPaymentType(
          selectedOrder.id,
          paymentType,
          paymentCType,
          user
        );
        this.props.refetchLastEditedBy();
        message.success(t("Edited successfully"));
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
    this.props.onClose();
  };

  render() {
    const { visible, config, t, selectedOrder } = this.props;
    const { paymentType, paymentCType, paymentReference } = this.state;

    return (
      <>
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Edit payment type")}
            </div>
          }
          visible={visible}
          onCancel={() => this.props.onClose()}
          onOk={() => this.save()}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: this.state.saveLoading,
            disabled:
              paymentType === selectedOrder.payment_method &&
              paymentCType === selectedOrder.paymentCType &&
              paymentReference === selectedOrder.paymentReference,
          }}
          width={this.props.settings.enable_custom_payments_for_pos ? 360 : 300}
        >
          {this.state.customPaymentModallVisible && (
            <CustomPaymentModal
              visible={this.state.customPaymentModallVisible}
              paymentCType={this.state.paymentCType}
              paymentReference={this.state.paymentReference}
              onClose={(paymentMethod, paymentReference) => {
                console.log(paymentMethod, paymentReference);
                if (paymentMethod) {
                  this.setState({
                    paymentType: "custom",
                    paymentCType: paymentMethod,
                    paymentReference: paymentReference,
                  });
                  // console.log()
                }
                this.setState({ customPaymentModallVisible: false });
              }}
            />
          )}
          <div className="px-2 mb-3 pt-3 text-center">
            <Radio.Group
              defaultValue="products"
              buttonStyle="solid"
              className="my-1  w-100"
              value={paymentType}
              // onChange={(e) => {
              //   this.setState({ paymentType: e.target.value });
              // }}
            >
              <Radio.Button
                onClick={(e) =>
                  this.setState({
                    paymentType: "cash",
                    paymentCType: "",
                  })
                }
                value={"cash"}
              >
                {t("Cash")}
              </Radio.Button>
              <Radio.Button
                onClick={(e) =>
                  this.setState({
                    paymentType: "pos",
                    paymentCType: "knet",
                  })
                }
                value={"pos"}
              >
                {t("Online Payment")}
              </Radio.Button>
              {this.props.settings.enable_custom_payments_for_pos && (
                <Radio.Button
                  value={"custom"}
                  onClick={() =>
                    this.setState({ customPaymentModallVisible: true })
                  }
                >
                  {t("Custom")}
                </Radio.Button>
              )}
            </Radio.Group>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    user: state.auth.username,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editPaymentType: (id, paymentType, paymentCType, user) =>
      dispatch(editPaymentType(id, paymentType, paymentCType, user)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EditPaymentType));
