const trans = {
  "Service Providers": "موفري الخدمات",
  "Search for a provider": "ابحث عن موفر للخدمة",
  Photo: "الصورة",
  "New Service Provider": "موفر خدمة جديد",
  "Edit provider": "تعديل",
  "Are you sure you want to delete this photo?":
    "هل انت متأكد من حذف هذه الصورة؟",
  "providers selected": "موفرين محددين",
  "New service provider": "موفر خدمة جديد",
  "Provider name": "اسم موفر الخدمة",
  "Provider arabic name": "اسم موفر الخدمة بالعربي",
  "Actions on selected providers": "تعديل موفرين الخدمات المحددين",
  "Search for a service": "البحث عن خدمة",
  "Provider's services": "الخدمات التي يوفرها مقدم الخدمة",
  "Add services": "اضافة خدمات",
  "services selected": "خدمة مختارة",
  "Actions on selected services": "تعديل الخدمات المختارة",
  "Break minutes": "وقت راحة بالدقائق بعد كل حجز",
  "All the services have been added to ***": "جميع الخدمات تم اضافتها الى ***",
  "Add availability of the service provider":
    "قم بإضافة اوقات توفر مقدم الخدمة",
  "Search using date, start, or end": "ابحث بواسطة التاريخ أو الوقت",
  "Add slots": "اضافة خانات جديدة",
  Services: "الخدمات",
  "Remove service": "ازالة الخدمة",
  "Select all services": "تحديد جميع الخدمات",
  "Please select the services": "الرجاء اختيار الخدمات",
  "Select the services that you want to add to ***. You can select multiple services at once":
    "اختر الخدمات التي تريد اضافتها الى ***. يمكنك اختيار اكثر من خدمة.",
  "Services added successfully": "تم اضافة الخدمات بنجاح",
  "New Availability Slots": "اضافة خانات جديدة",
  "(For example: *** works from 8 AM to 4 PM on days selected in the range)":
    "(مثال: *** يعمل من الساعة 8 ص الى 4 م في الايام المحددة في الفترة)",
  Providers: "موفري الخدمات",
};

export default trans;
