const trans = {
  "Layout & Theme": "التصميم والنمط",
  "Store layout": "تصميم المتجر",
  "Categories view layout": "تصميم الأصناف",
  Customizations: "تخصيصات",
  "Quantity box location": "مكان صندوق الكمية",
  Top: "في الأعلى",
  Bottom: "في الاسفل",
  "Theme color": "لون النمط",
  "Discount tag color": "لون علامة الخصم",
  "(we recommend picking a dark color)": "(نقترح اختيار لون داكن)",
  "Grid view": "شبكة",
  "Grid view minimal": "شبكة مبسطة",
  "List view": "قائمة عمودية",
  "Scroll view": "قائمة افقية",
  "Hide category name": "اخفاء اسم الصنف",
  "Collapse view": "قائمة يمكن تصغيرها",
  "Bubble view": "قائمة مع دوائر",
  Bubbles: "دوائر",
  "Jumbo view": "قائمة جامبو",
  "Image placement": "مكان صورة المنتج",
  Right: "يمين",
  Left: "يسار",
  "Show variants below the product description (only applies if you have variants)":
    "اظهار الانواع اسفل وصف المنتج (سيتم تفعيلها في حال ان لديك انواع فقط)",
  'Hide "All" category': 'اخفاء صنف "الجميع"',
  "Show one product per row": "عرض منتج واحد في الصف",
  "Hide categories bubbles": "اخفاء دوائر الاصناف",
  "Categories collapsed by default": "تقليص الاصناف تلقائيا",
  "Hide category thumbnails": "إخفاء الصور المصغرة للاصناف",
  None: "بدون",
  Labels: "نصوص",
};

export default trans;
