import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import { Modal, message, Input } from "antd";

// Actions
import { addExternalSource } from "../../store/actions";

class NewExternalSourceModal extends Component {
  state = {
    saveLoading: false,
    englishName: "",
    arabicName: "",
  };

  addExternalSource = async () => {
    this.setState({ saveLoading: true });
    const { t } = this.props;
    const { englishName, arabicName } = this.state;
    try {
      const res = await instance.post("/add_external_source/", {
        arabicName,
        englishName,
      });

      message.success(t("Added successfully"));
      this.props.addExternalSource(res.data);
      this.props.onClose(res.data.id);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
  };

  saveButtonDisabled = () => {
    const s = this.state;
    return !s.englishName || !s.arabicName;
  };

  render() {
    const { visible, t, config } = this.props;

    return (
      <>
        <Modal
          style={{ top: 20 }}
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("New source")}
            </div>
          }
          visible={visible}
          onCancel={() => this.props.onClose()}
          onOk={() => {
            this.addExternalSource();
          }}
          okButtonProps={{
            disabled: this.saveButtonDisabled(),
            loading: this.state.saveLoading,
          }}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
        >
          <div className="px-2 mb-3">
            <div>
              <p className="mt-3">{t("English Name")}</p>
              <Input
                placeholder={t("English Name")}
                onChange={(e) => this.setState({ englishName: e.target.value })}
              />
            </div>

            <div>
              <p className="mt-3">{t("Arabic Name")}</p>
              <Input
                placeholder={t("Arabic Name")}
                onChange={(e) => this.setState({ arabicName: e.target.value })}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return { addExternalSource: (unit) => dispatch(addExternalSource(unit)) };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewExternalSourceModal));
