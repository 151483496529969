//REACT IMPORTS
import { useEffect } from "react";
//REDUX IMPORTS
import { useDispatch, useSelector } from "react-redux";
import { clearDriverHistory, getDrivers } from "../../store/actions/drivers";
//REACT I18NEXT
import { withNamespaces } from "react-i18next";
//ANT DESIGN IMPORTS
import { Select, Space, Spin } from "antd";

const { Option } = Select;

const MyComponent = ({ onChangeDriverSelect, t, selectedDriver }) => {
  //REDUX
  const dispatch = useDispatch();
  const driversList = useSelector((state) => state.driver?.drivers.data);
  const driversListStatus = useSelector(
    (state) => state.driver?.drivers.status
  );
  const direction = useSelector((state) => state.config.direction);
  const settings = useSelector((state) => state.auth.settings);

  //RETURN ACTIVE DRIVERS ONLY
  const driverListActive = driversList?.filter(
    (driver) => driver.is_active === true && driver.id !== selectedDriver?.id
  );

  //COMPONENT LIFECYCLE
  useEffect(() => {
    if (driversListStatus !== "success" && settings.enable_driver) {
      dispatch(getDrivers());
    }
  }, [dispatch]);

  return (
    <Space style={{ display: "flow-root" }} className={"mt-3"}>
      {driversListStatus !== "success" ? (
        <Spin />
      ) : (
        <Select
          style={{ width: 300 }}
          onChange={onChangeDriverSelect}
          placeholder={t("Search to Select Driver")}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.join().toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {driversListStatus !== "success"
            ? ""
            : driverListActive.map((driver) => (
                <Option
                  style={{ textAlign: direction == "rtl" ? "right" : "left" }}
                  value={driver.id}
                >
                  {driver.first_name + " " + driver.last_name} (
                  {driver.phone_number})
                </Option>
              ))}
        </Select>
      )}
    </Space>
  );
};

export default withNamespaces()(MyComponent);
