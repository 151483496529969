import {
  BRANCH_CONTROL_LIMIT,
  BRANCH_CONTROL_PRDOCUT_LIMIT,
  BRANCH_CREATE_NEW_LIMITS,
  BRANCH_PRODUCT_CREATE_NEW_LIMITS,
  BULK_DELETE_BRANCH_DELIVERY_CHARGES,
  BULK_DELETE_BRANCH_PRODUCTS,
  BULK_TOGGLE_BRANCH_DELIVERY_CHARGES,
  BULK_TOGGLE_BRANCH_PRODUCT_OPTIONS,
  BULK_TOGGLE_BRANCH_PRODUCTS,
  CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL_BB,
  COPY_SLOTS_TO_BRANCHES,
  DELETE_BRANCH,
  BULK_TOGGLE_BRANCHES,
  DELETE_BRANCH_DELIVERY_CHARGE,
  DELETE_BRANCH_PRODUCT,
  FETCH_BRANCH_CATALOG,
  FETCH_BRANCH_CATALOG_LOADING,
  FETCH_BRANCH_DELIVERY_CHARGES,
  FETCH_BRANCH_DELIVERY_CHARGES_LOADING,
  FETCH_BRANCH_ZONES,
  FETCH_BRANCH_ZONES_LOADING,
  FETCH_COUNTRIES_AREAS,
  FETCH_STORE_BRANCHES,
  FETCH_STORE_ROLES,
  OVERRIDE_BRANCH_PRODUCT_OPTIONS,
  OVERRIDE_BRANCH_PRODUCT_PREORDERING_STOCK,
  OVERRIDE_BRANCH_PRODUCT_STOCK,
  OVERRIDE_DELIVERY_CHARGE,
  OVERRIDE_DELIVERY_CHARGE_VALUE,
  SAVE_BRANCH_PRODUCT_INVENTORY,
  SAVE_BRANCH_PRODUCT_PREORDERING_INVENTORY,
  SET_STORE_BRACHES_LOADING_TRUE,
  TOGGLE_BRANCH_DELIVERY_CHARGE,
  TOGGLE_BRANCH_PRODUCT,
  TOGGLE_BRANCH_PRODUCT_OPTION,
  UPDATE_BRANCH,
  UPDATE_BRANCH_BANNER,
  UPDATE_BRANCH_BUSY_SOCKET,
  UPDATE_BRANCH_COMPOSITE_INVENTORIES,
  UPDATE_BRANCH_SLOTS_LIMITS,
} from "../actions/actionTypes";
import { isEmpty } from "./pos";

const initialState = {
  branches: [],
  loading: false,
  countries: [],
  areas: [],
  roles: [],
  catalogLoading: false,
  bdcLoading: false,
  zonesLoading: false,
  areaToBranchMaster: [],
};

export default (state = initialState, { type, payload }) => {
  let newBranches = [];
  let index = null;
  let productIndex = null;
  let areaIndex = null;
  let zoneIndex = null;
  let branch, dayLimit;

  switch (type) {
    case FETCH_STORE_BRANCHES:
      return { ...state, branches: payload, loading: false };
    case SET_STORE_BRACHES_LOADING_TRUE:
      return { ...state, loading: true };
    case FETCH_COUNTRIES_AREAS:
      return { ...state, countries: payload.countries, areas: payload.areas };
    case UPDATE_BRANCH:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.id);
      if (index >= 0) {
        let tempProducts = newBranches[index].products;
        let tempOffProducts = newBranches[index].off_products;

        let tempAreas = newBranches[index].areas;
        let tempOffAreas = newBranches[index].off_areas;

        newBranches[index] = payload;
        newBranches[index].products = tempProducts;
        newBranches[index].off_products = tempOffProducts;
        newBranches[index].areas = tempAreas;
        newBranches[index].off_areas = tempOffAreas;
      } else {
        newBranches = [...newBranches, payload];
      }
      return { ...state, branches: newBranches };

    case DELETE_BRANCH:
      newBranches = state.branches;
      newBranches = newBranches.filter((branch) => branch.id != payload);
      return { ...state, branches: newBranches };

    case BULK_TOGGLE_BRANCHES:
      const branchesIds = payload.ids;
      const enableDelivery = payload.enableDelivery;
      const enablePickup = payload.enablePickup;
      const allowPreordering = payload.allowPreordering;

      newBranches = state.branches;
      state.branches.forEach((br, indx) => {
        if (branchesIds.includes(br.id)) {
          if (enableDelivery !== "") {
            newBranches[indx].enable_delivery = enableDelivery;
          }
          if (enablePickup !== "") {
            newBranches[indx].enable_pickup = enablePickup;
          }
          if (allowPreordering !== "") {
            newBranches[indx].allow_preordering = allowPreordering;
          }
        }
      });

      return { ...state, branches: newBranches };

    case FETCH_BRANCH_CATALOG_LOADING:
      return { ...state, catalogLoading: true };

    case FETCH_BRANCH_CATALOG:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      if (index >= 0) {
        newBranches[index].products = payload.products;
        newBranches[index].off_products = payload.off_products;
      }
      return { ...state, branches: newBranches, catalogLoading: false };

    case TOGGLE_BRANCH_PRODUCT:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );

      newBranches[index].products[productIndex][payload.key] = payload.value;
      return { ...state, branches: newBranches };

    case DELETE_BRANCH_PRODUCT:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      newBranches[index].products = newBranches[index].products.filter(
        (product) => product.id != payload.pid
      );
      newBranches[index].off_products = payload.off_products;
      return { ...state, branches: newBranches };

    case OVERRIDE_BRANCH_PRODUCT_STOCK:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      newBranches[index].products[productIndex].override_inventory =
        !newBranches[index].products[productIndex].override_inventory;

      return { ...state, branches: newBranches };
    case OVERRIDE_BRANCH_PRODUCT_PREORDERING_STOCK:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      newBranches[index].products[productIndex].override_preordering_inventory =
        !newBranches[index].products[productIndex]
          .override_preordering_inventory;

      return { ...state, branches: newBranches };

    case OVERRIDE_BRANCH_PRODUCT_OPTIONS:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      newBranches[index].products[productIndex].override_options =
        !newBranches[index].products[productIndex].override_options;
      if (payload.options) {
        newBranches[index].products[productIndex].options = payload.options;
      }
      return { ...state, branches: newBranches };

    case "FETCH_BRANCH_PRODUCT_OPTIONS":
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      if (payload.options) {
        newBranches[index].products[productIndex].options = payload.options;
      }
      return { ...state, branches: newBranches };

    case SAVE_BRANCH_PRODUCT_INVENTORY:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      newBranches[index].products[productIndex].inventory = payload.value;

      return { ...state, branches: newBranches };

    case TOGGLE_BRANCH_PRODUCT_OPTION:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      newBranches[index].products[productIndex].options = payload.options;

      return { ...state, branches: newBranches };

    case BULK_TOGGLE_BRANCH_PRODUCTS:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      payload.pids.forEach((id) => {
        productIndex = newBranches[index].products.findIndex(
          (product) => product.id == id
        );
        if (payload.status != null) {
          newBranches[index].products[productIndex].is_active = payload.status;
        }
        if (payload.notAvailable != "UnAssigned") {
          newBranches[index].products[productIndex].not_available_until =
            payload.notAvailable;
        }
      });
      return { ...state, branches: newBranches };

    case BULK_DELETE_BRANCH_PRODUCTS:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      newBranches[index].products = newBranches[index].products.filter(
        (product) => !payload.pids.includes(product.id)
      );
      newBranches[index].off_products = payload.off_products;

      return { ...state, branches: newBranches };

    case FETCH_BRANCH_DELIVERY_CHARGES_LOADING:
      return { ...state, bdcLoading: true };
    case FETCH_BRANCH_DELIVERY_CHARGES:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      if (index >= 0) {
        newBranches[index].areas = payload.areas;
        newBranches[index].off_areas = payload.off_areas;
      }
      return { ...state, branches: newBranches, bdcLoading: false };

    case TOGGLE_BRANCH_DELIVERY_CHARGE:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      areaIndex = newBranches[index].areas.findIndex(
        (area) => area.id == payload.bdcid
      );

      if (areaIndex >= 0) {
        newBranches[index].areas[areaIndex].is_active =
          !newBranches[index].areas[areaIndex].is_active;
      }
      return { ...state, branches: newBranches };

    case DELETE_BRANCH_DELIVERY_CHARGE: {
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      if (newBranches[index].areas.length === 0) {
        const newAreaToBranch = state.areaToBranchMaster[
          payload.area_id
        ].filter((area) => area.branch_delivery_charge_id !== payload.bdcid);
        const newAreaToBranchMaster = {
          ...state.areaToBranchMaster,
          [payload.area_id]: newAreaToBranch,
        };
        return { ...state, areaToBranchMaster: newAreaToBranchMaster };
      } else {
        newBranches[index].areas = newBranches[index].areas.filter(
          (area) => area.id != payload.bdcid
        );
        newBranches[index].off_areas = payload.off_areas;
        const newAreaToBranch = state.areaToBranchMaster[
          payload.area_id
        ].filter((area) => area.branch_delivery_charge_id !== payload.bdcid);
        const newAreaToBranchMaster = {
          ...state.areaToBranchMaster,
          [payload.area_id]: newAreaToBranch,
        };
        return {
          ...state,
          branches: newBranches,
          areaToBranchMaster: newAreaToBranchMaster,
        };
      }
    }

    case BULK_DELETE_BRANCH_DELIVERY_CHARGES:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      newBranches[index].areas = newBranches[index].areas.filter(
        (area) => !payload.bdcids.includes(area.id)
      );
      newBranches[index].off_areas = payload.off_areas;

      return { ...state, branches: newBranches };

    case BULK_TOGGLE_BRANCH_DELIVERY_CHARGES:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      payload.bdcids.forEach((id) => {
        areaIndex = newBranches[index].areas.findIndex((area) => area.id == id);

        if (payload.status !== "")
          newBranches[index].areas[areaIndex].is_active = payload.status;
        if (payload.rate !== null) {
          if (payload.rate < 0)
            newBranches[index].areas[
              areaIndex
            ].override_delivery_charge = false;
          else {
            newBranches[index].areas[areaIndex].override_delivery_charge = true;
            newBranches[index].areas[areaIndex].override_price = payload.rate;
          }
        }

        if (payload.mov !== null) {
          if (payload.mov < 0)
            newBranches[index].areas[areaIndex].override_minimum_order = false;
          else {
            newBranches[index].areas[areaIndex].override_minimum_order = true;
            newBranches[index].areas[areaIndex].override_minimum_order_value =
              payload.mov;
          }
        }

        if (payload.deliveryCost !== null) {
            newBranches[index].areas[areaIndex].delivery_cost =
              payload.deliveryCost;
          }
        

        if (payload.minutes !== null) {
          if (payload.minutes < 0)
            newBranches[index].areas[
              areaIndex
            ].override_on_demand_delivery_minutes = false;
          else {
            newBranches[index].areas[
              areaIndex
            ].override_on_demand_delivery_minutes = true;
            newBranches[index].areas[
              areaIndex
            ].override_on_demand_delivery_minutes_value = payload.minutes;
          }
        }
      });
      return { ...state, branches: newBranches };

    case OVERRIDE_DELIVERY_CHARGE:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      areaIndex = newBranches[index].areas.findIndex(
        (area) => area.id == payload.bdcid
      );

      if (areaIndex >= 0) {
        if (payload.type == "rate") {
          newBranches[index].areas[areaIndex].override_delivery_charge =
            !newBranches[index].areas[areaIndex].override_delivery_charge;
        } else if (payload.type == "mov") {
          newBranches[index].areas[areaIndex].override_minimum_order =
            !newBranches[index].areas[areaIndex].override_minimum_order;
        } else if (payload.type == "deliveryMinutes") {
          newBranches[index].areas[
            areaIndex
          ].override_on_demand_delivery_minutes =
            !newBranches[index].areas[areaIndex]
              .override_on_demand_delivery_minutes;
        }
      }

      return { ...state, branches: newBranches };

    case OVERRIDE_DELIVERY_CHARGE_VALUE:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);

      areaIndex = newBranches[index].areas.findIndex(
        (area) => area.id == payload.bdcid
      );

      if (areaIndex >= 0) {
        if (payload.type == "rate") {
          newBranches[index].areas[areaIndex].override_price = payload.value;
        } else if (payload.type == "mov") {
          newBranches[index].areas[areaIndex].override_minimum_order_value =
            payload.value;
        } else if (payload.type == "deliveryMinutes") {
          newBranches[index].areas[
            areaIndex
          ].override_on_demand_delivery_minutes_value = payload.value;
        } else if (payload.type == "deliveryCost") {
          newBranches[index].areas[
            areaIndex
          ].delivery_cost = payload.value;
        }
      }

      return { ...state, branches: newBranches };

    case FETCH_STORE_ROLES:
      return { ...state, roles: payload };
    case BRANCH_CREATE_NEW_LIMITS:
      newBranches = state.branches;
      branch = newBranches.find((branch) => branch.id == payload.branch_id);
      if (branch) {
        branch.day_limits = payload.limits;
      }
      return { ...state, branches: newBranches };
    case BRANCH_CONTROL_LIMIT:
      newBranches = state.branches;
      branch = newBranches.find((branch) => branch.id == payload.branch_id);

      if (branch) {
        if (payload.type == "toggle") {
          dayLimit = branch.day_limits.find(
            (slot) => slot.key == payload.limit_id
          );

          if (dayLimit) {
            dayLimit.is_active = !dayLimit.is_active;
          }
        } else if (payload.type == "delete") {
          branch.day_limits = branch.day_limits.filter(
            (slot) => slot.key != payload.limit_id
          );
        } else if (payload.type == "edit max") {
          dayLimit = branch.day_limits.find(
            (slot) => slot.key == payload.limit_id
          );

          if (dayLimit) {
            dayLimit.max = payload.max;
          }
        }
      }
      return { ...state, branches: newBranches };

    case BRANCH_CONTROL_PRDOCUT_LIMIT:
      newBranches = state.branches;
      branch = newBranches.find((branch) => branch.id == payload.branch_id);

      if (branch) {
        let product = branch.products.find(
          (prod) => prod.id === payload.product
        );
        if (product) {
          if (payload.type == "toggle") {
            dayLimit = product.limits.find(
              (slot) => slot.key == payload.limit_id
            );

            if (dayLimit) {
              dayLimit.is_active = !dayLimit.is_active;
            }
          } else if (payload.type == "delete") {
            product.limits = product.limits.filter(
              (slot) => slot.key != payload.limit_id
            );
          } else if (payload.type == "edit max") {
            dayLimit = product.limits.find(
              (slot) => slot.key == payload.limit_id
            );

            if (dayLimit) {
              dayLimit.max = payload.max;
            }
          }
        }
      }
      return { ...state, branches: newBranches };
    case BRANCH_PRODUCT_CREATE_NEW_LIMITS:
      newBranches = state.branches;
      branch = newBranches.find((branch) => branch.id == payload.branch_id);

      if (branch) {
        let product = branch.products.find(
          (prod) => prod.id === payload.product
        );

        if (product) {
          product.limits = payload.limits;
        }
      }
      return { ...state, branches: newBranches };
    case SAVE_BRANCH_PRODUCT_PREORDERING_INVENTORY:
      newBranches = state.branches;
      index = newBranches.findIndex((branch) => branch.id == payload.bid);
      productIndex = newBranches[index].products.findIndex(
        (product) => product.id == payload.pid
      );
      newBranches[index].products[productIndex].preorder_inventory =
        payload.value;

      return { ...state, branches: newBranches };

    case COPY_SLOTS_TO_BRANCHES:
      newBranches = state.branches;
      newBranches.forEach((branch, indx) => {
        if (payload.includes(branch.id)) {
          newBranches[indx].has_loaded = false;
        }
      });
      return { ...state, branches: newBranches };

    case BULK_TOGGLE_BRANCH_PRODUCT_OPTIONS:
      newBranches = state.branches;

      index = newBranches.findIndex((b) => b.id === payload.branch_id);
      productIndex = newBranches[index].products.findIndex(
        (p) => p.id === payload.product
      );
      newBranches[index].products[productIndex].options = payload.options;

      return { ...state, branches: newBranches };

    case UPDATE_BRANCH_COMPOSITE_INVENTORIES:
      newBranches = state.branches;

      let new_composite_products_inventories =
        payload.new_composite_products_inventories;

      branch = newBranches.find((b) => b.id === payload.branchID);

      if (branch) {
        for (let product of branch.products) {
          if (
            product.product.type_of_product == "composite" &&
            new_composite_products_inventories[product.product.id]
          ) {
            product.product.inventory_on_hand =
              new_composite_products_inventories[product.product.id];
            delete new_composite_products_inventories[product.product.id];
            if (isEmpty(new_composite_products_inventories)) break;
          }
        }
      }

      return { ...state, branches: newBranches };

    case UPDATE_BRANCH_SLOTS_LIMITS:
      newBranches = state.branches;
      branch = newBranches.find((branch) => branch.id == payload.branchID);
      if (branch) {
        let slot;
        if (payload.type === "working") {
          slot = branch.slots.find((slot) => slot.id === payload.slotID);
        } else {
          slot = branch.delivery_slots.find(
            (slot) => slot.id === payload.slotID
          );
        }
        if (slot) {
          slot.limit = payload.newLimit;
        }
      }

      return { ...state, branches: newBranches };

    case FETCH_BRANCH_ZONES_LOADING:
      return { ...state, zonesLoading: true };

    case FETCH_BRANCH_ZONES:
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      newBranches[index].zones = payload.zones;
      newBranches[index].areasInZone = payload.areasInZone;
      return { ...state, zonesLoading: false, branches: newBranches };

    case "EDIT_BRANCH_ZONE_NAME":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      zoneIndex = newBranches[index].zones.findIndex(
        (z) => z.id === payload.id
      );
      newBranches[index].zones[zoneIndex].name = payload.value;
      return { ...state, zonesLoading: false, branches: newBranches };

    case "EDIT_BRANCH_ZONE_DELIVERY_RATE":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      zoneIndex = newBranches[index].zones.findIndex(
        (z) => z.id === payload.id
      );
      newBranches[index].zones[zoneIndex].delivery_rate = payload.value;
      return { ...state, zonesLoading: false, branches: newBranches };
    case "EDIT_BRANCH_ZONE_MOV":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      zoneIndex = newBranches[index].zones.findIndex(
        (z) => z.id === payload.id
      );
      newBranches[index].zones[zoneIndex].minimum_order_value = payload.value;
      return { ...state, zonesLoading: false, branches: newBranches };

    case "EDIT_BRANCH_ZONE_DELIVERY_MINUTES":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      zoneIndex = newBranches[index].zones.findIndex(
        (z) => z.id === payload.id
      );
      newBranches[index].zones[zoneIndex].delivery_minutes_value =
        payload.value;
      return { ...state, zonesLoading: false, branches: newBranches };

    case "TOGGLE_BRANCH_ZONE":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      zoneIndex = newBranches[index].zones.findIndex(
        (z) => z.id === payload.id
      );
      newBranches[index].zones[zoneIndex].is_active = payload.value;
      return { ...state, zonesLoading: false, branches: newBranches };

    case "DELETE_BRANCH_ZONE":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      newBranches[index].zones = newBranches[index].zones.filter(
        (z) => z.id !== payload.id
      );
      newBranches[index].areas = newBranches[index].areas.map((area) => {
        if (payload.deletedAreas.includes(area.area_id)) {
          return {
            ...area,
            zone_name: null,
          };
        } else {
          return area;
        }
      });
      return { ...state, zonesLoading: false, branches: newBranches };

    case "DELETE_BRANCH_ZONE_AREA":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.id);
      newBranches[index].areas = newBranches[index].areas.map((area) => {
        if (payload.deletedAreas.includes(area.area_id)) {
          return {
            ...area,
            zone_name: null,
          };
        } else {
          return area;
        }
      });
      return { ...state, zonesLoading: false, branches: newBranches };

    case "ADD_BRANCH_ZONE_AREA":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.id);
      newBranches[index].areas = newBranches[index].areas.map((area) => {
        if (payload.addedAreas.includes(area.area_id)) {
          return {
            ...area,
            zone_name: payload.zoneName,
          };
        } else {
          return area;
        }
      });
      return { ...state, zonesLoading: false, branches: newBranches };

    case "CREATE_BRANCH_ZONE":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      newBranches[index].zones = [...newBranches[index].zones, payload.zone];
      newBranches[index].areas = newBranches[index].areas.map((area) => {
        if (payload.areas.includes(area.area_id)) {
          return {
            ...area,
            zone_name: payload.zone.name,
          };
        } else {
          return area;
        }
      });
      return { ...state, zonesLoading: false, branches: newBranches };

    case "BULK_CONTROL_BRANCH_ZONES":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      if (payload.type === "toggle") {
        newBranches[index].zones = newBranches[index].zones.map((z) =>
          payload.ids.includes(z.id) ? { ...z, is_active: payload.active } : z
        );
      } else if (payload.type === "delete") {
        newBranches[index].zones = newBranches[index].zones.filter(
          (z) => !payload.ids.includes(z.id)
        );
        newBranches[index].areas = newBranches[index].areas.map((area) => {
          if (payload.deletedAreas.includes(area.area_id)) {
            return {
              ...area,
              zone_name: null,
            };
          } else {
            return area;
          }
        });
      }
      return { ...state, branches: newBranches };
    case UPDATE_BRANCH_BANNER:
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.branch_id);
      delete payload["branch_id"];
      newBranches[index] = { ...newBranches[index], ...payload };
      return { ...state, branches: newBranches };

    case "RESET_BRANCH_PRESET":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.branchID);
      if (payload.type === "working") {
        newBranches[index].selected_production_preset = "";
      } else newBranches[index].selected_delivery_preset = "";
      return { ...state, branches: newBranches };

    case "DELETE_BRANCH_PRESET":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.branchID);

      if (payload.type === "working") {
        newBranches[index].production_presets = newBranches[
          index
        ].production_presets.filter((p) => p.name !== payload.name);
        if (payload.name === newBranches[index].selected_production_preset)
          newBranches[index].selected_production_preset = "";
      } else {
        newBranches[index].delivery_presets = newBranches[
          index
        ].delivery_presets.filter((p) => p.name !== payload.name);
        if (payload.name === newBranches[index].selected_delivery_preset)
          newBranches[index].selected_delivery_preset = "";
      }
      return {
        ...state,
        branches: newBranches,
      };

    case CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL_BB:
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.branchID);
      productIndex = newBranches[index].products.findIndex(
        (p) => p.id === payload.id
      );
      newBranches[index].products[productIndex].not_available_until =
        payload.value;
      return {
        ...state,
        branches: newBranches,
      };

    case "EDIT_CLOSURE_PERIOD":
      newBranches = state.branches;
      index = newBranches.findIndex((b) => b.id === payload.bid);
      productIndex = newBranches[index].closure_periods.findIndex(
        (p) => p.id === payload.id
      );

      if (payload.field === "is_deleted") {
        newBranches[index].closure_periods = newBranches[
          index
        ].closure_periods.filter((x) => x.id !== payload.id);
      } else {
        newBranches[index].closure_periods[productIndex][payload.field] =
          payload.value;
      }
      return {
        ...state,
        branches: newBranches,
      };

    case "FETCH_AREA_TO_BRANCH_MASTER":
      return { ...state, areaToBranchMaster: payload };
    case "ASSIGN_AREA_TO_BRANCH_MASTER": {
      const newAreaToBranchMaster = { ...state.areaToBranchMaster };
      const newBranches = state.branches;
      const { areaToBranch, areaId, branchesToDelete } = payload;
      //On assigning area to branch
      if (areaToBranch.length > 0) {
        const areaData = areaToBranch.map((branchDeliveryCharge) => {
          return {
            branch_name: branchDeliveryCharge.branch_name,
            branch_ar_name: branchDeliveryCharge.branch_ar_name,
            branch_id: branchDeliveryCharge.branch,
            branch_delivery_charge_id: branchDeliveryCharge.id,
          };
        });
        if (!newAreaToBranchMaster[areaId]) {
          newAreaToBranchMaster[areaId] = areaData;
        } else {
          newAreaToBranchMaster[areaId] = [
            ...newAreaToBranchMaster[areaId],
            ...areaData,
          ];
        }

        areaToBranch.forEach((branchDeliveryCharge) => {
          const branchIndex = newBranches.findIndex(
            (branch) => branch.id === branchDeliveryCharge.branch
          );
          if (newBranches[branchIndex].areas.length !== 0) {
            newBranches[branchIndex].areas = [
              ...newBranches[branchIndex].areas,
              branchDeliveryCharge,
            ];
          }
          if (newBranches[branchIndex].off_areas.length !== 0) {
            newBranches[branchIndex].off_areas = newBranches[
              branchIndex
            ].off_areas.filter(
              (area) => area.id !== branchDeliveryCharge.area_id
            );
          }
        });
      }
      //on deleting area from branch
      if (branchesToDelete.length > 0) {
        const areasToBeDeleted = newAreaToBranchMaster[areaId].filter(
          (branch) =>
            branchesToDelete.includes(branch.branch_delivery_charge_id)
        );
        newAreaToBranchMaster[areaId] = newAreaToBranchMaster[areaId].filter(
          (branch) =>
            !branchesToDelete.includes(branch.branch_delivery_charge_id)
        );
        areasToBeDeleted.forEach((branchDeliveryCharge) => {
          const branchIndex = newBranches.findIndex(
            (branch) => branch.id === branchDeliveryCharge.branch_id
          );
          if (newBranches[branchIndex].off_areas.length !== 0) {
            const offArea = newBranches[branchIndex].areas.find(
              (area) => area.area_id === areaId
            );
            const offAreaData = {
              id: offArea.area_id,
              area: offArea.area,
              ar_area: offArea.area,
              province_id: offArea.province_id,
              province_name: offArea.province,
              province_ar_name: offArea.ar_province,
              province_country: offArea.province_country,
              country_id: offArea.country_id,
              country_name: offArea.country,
              country_ar_name: offArea.ar_country,
            };
            newBranches[branchIndex].off_areas = [
              ...newBranches[branchIndex].off_areas,
              offAreaData,
            ].sort((a, b) => a.area.localeCompare(b.area));
          }
          if (newBranches[branchIndex].areas.length !== 0) {
            newBranches[branchIndex].areas = newBranches[
              branchIndex
            ].areas.filter(
              (area) =>
                area.id !== branchDeliveryCharge.branch_delivery_charge_id
            );
          }
        });
      }

      return {
        ...state,
        areaToBranchMaster: newAreaToBranchMaster,
        branches: newBranches,
      };
    }
    case "UPDATE_BRANCH_VAT": {
      newBranches = state.branches.map((branch) => {
        return {
          ...branch,
          enable_vat: payload.enableVat,
          vat_rate: payload.vatRate,
          enable_vat_with_product_price: payload.vatWithProduct,
          remove_delivery_charge_from_vat: payload.removeVatFromDelivery,
        };
      });
      return { ...state, branches: newBranches };
    }

    case UPDATE_BRANCH_BUSY_SOCKET:
      newBranches = state.branches;
      payload.branches.forEach((b) => {
        index = newBranches.findIndex((branch) => branch.id == b.id);
        if (index >= 0) {
          newBranches[index] = { ...newBranches[index], ...b };
        }
      });

      return { ...state, branches: newBranches };
    default:
      return state;
  }
};
