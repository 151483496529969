import coupons from "./coupons";
import smartPromotions from "./smartPromotions";
let tr;
export default tr = {
  ...coupons,
  ...smartPromotions,
  Promotions: "العروض",
  Quantity: "الكمية",
  Remaining: "المتبقى",
  "Status has been toggled successfully": "تم تغير الحالة بنجاح",
  "The code already exists": "الكود مستخدم في عرض اخر",
  "English Description": "الوصف بالإنجليزي",
  "Arabic Description": "الوصف بالعربي",
  Update: "تحديث",
  Description: "الوصف",
  "New Expiry Date": "تاريخ الإنتهاء الجديد",
  "Extra Quantity": "الكمية اللإضافية",
  Extend: "تمديد",
  "Please choose the date range for qualified orders":
    "تحديد الفترة للطلبات المؤهلة للسحب",
  Raffle: "السحب",
  "WE HAVE A WINNER!": "لدينا رابح!",
  Manage: "تحكم",
  "Pick a Date Range for Orders": "تحديد الفترة الزمنية للطلبات",
  "Enter a number of copies": "ادخل عدد النسخ",
  "How many do you want?": "كم نسخة تريد؟",
  from: "من",
  "Orders from ": "يطلب من ",

  "Loyalty Program": "برنامج المكافئات",
  "Enable Wallet": "تفعيل المحفظة",
  "Enable Loyalty Points": "تفعيل نقاط المكافئات",
  "Duplicate promotion?": "تكرار العرض؟",
  "Promotions are being created": "يتم إضافة العروض",
  "Number of promotions": "عدد العروض",
  "Customers get": "يحصل العملاء على",
  per: "لكل",
  "Maximum number of users is 1000": "الحد الأقصى للمستخدمين هو 1000",
  "show details": "عرض التفاصيل",
  "hide details": "إخفاء التفاصيل",
  "Created By": "انشأ من قبل",
  "1 Month": "1 شهر",
  "3 Months": "3 شهور",
  "6 Months": "6 شهور",
  "9 Months": " 9 شهور",
  "1 Year": "1 سنة",
  "Expire Loyalty Points After": "إلغاء صلاحية النقاط بعد",
  "No Expiry": "بدون فترة إلغاء",
  "Minimum spent": "الحد الادنى للطلب",
};
