import { FETCH_ACCOUNT_INFO, DELETE_INVOICE } from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchAccountInfo = () => {
  return async (dispatch) => {
    try {
      let res = await instance.get("/fetch_account_info/");
      if (res.data.success) {
        dispatch({ type: FETCH_ACCOUNT_INFO, payload: res.data });
      } else
        message.error(
          "Could not load account info. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view account settings");
      else
        message.error(
          "Could not load account info. Please try again or contact us for help"
        );
    }
  };
};

export const deleteInvoice = (id) => {
  return {
    type: DELETE_INVOICE,
    payload: id,
  };
};
