import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd Components
import {
  Modal,
  Select,
  Input,
  Row,
  Col,
  message,
  Carousel,
  Button,
  Checkbox,
} from "antd";
import { ReactComponent as Logo } from "./launch_go.svg";
import { switchLanguage } from "../../store/actions/config";
import { collapseSideNav } from "../../store/actions/";

// Actions

const { Option } = Select;

class NewBranchModal extends Component {
  render() {
    const { config, t } = this.props;

    return (
      <Modal
        centered
        width={600}
        destroyOnClose={true}
        title={"Welcome"}
        visible={true}
        onCancel={() => this.props.onClose()}
        okText={t("Save")}
        cancelText={t("Close")}
        bodyStyle={{
          minHeight: 600,
          paddingTop: 0,
          width: "auto",
          padding: 0,
          textAlign: config.direction == "ltr" ? "left" : "right",
          position: "relative",
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        footer={null}
        title={null}
        closable={false}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <Button
            style={{
              position: "absolute",
              [config.direction === "ltr" ? "right" : "left"]: 15,
              top: 15,
            }}
            type="link"
            onClick={() =>
              this.props.switchLanguage(
                config.language === "english" ? "arabic" : "english"
              )
            }
          >
            {config.language === "english" ? "عربي" : "English"}
          </Button>
        </div>
        <div className="my-3 text-center">
          <Logo style={{ width: 388, height: 280, marginTop: 50 }} />

          <div
            className={"w-75 ".concat(
              config.direction === "ltr" ? "text-left" : "text-right"
            )}
            style={{ margin: "auto", marginTop: 35 }}
          >
            {/* <h4
              style={{
                fontWeight: "bold",
                fontSize: 24,
                marginBottom: 20,
              }}
            >
              Welcome to your{" "}
              <span
                style={{ color: "#0099cc", fontWeight: "bold", fontSize: 24 }}
              >
                ordable/
              </span>{" "}
              dashboard
            </h4> */}

            <h4
              style={{
                fontWeight: "bold",
                fontSize: 24,
                marginBottom: 20,
              }}
            >
              {t("Let's get started")}
            </h4>

            <p
              className="mb-0"
              style={{ fontWeight: 100, color: "black", fontSize: 16 }}
            >
              {t("Take a quick tour of")}{" "}
              <span style={{ color: "#0099cc" }}>{t("ordable/")}</span>
            </p>
            <p
              className="mb-0"
              style={{ fontWeight: 100, color: "black", fontSize: 16 }}
            >
              {t("You can stop at any time by pressing")} '
              <span style={{ color: "#0099cc" }}>{t("Skip")}</span>'
            </p>
            <p
              className="mb-0"
              style={{ fontWeight: 100, color: "black", fontSize: 16 }}
            >
              {t("To begin, press")} '
              <span style={{ color: "#0099cc" }}>{t("Go")}</span>'
            </p>
          </div>
        </div>

        <div
          className="text-center"
          style={{
            height: 50,
            position: "absolute",
            width: "100%",
            bottom: 0,
            padding: "0px 10px",
          }}
        >
          <Button
            type="link"
            className="mx-2"
            onClick={() => {
              this.props.onClose(true);
            }}
            style={{ float: config.language === "arabic" ? "right" : "left" }}
          >
            {t("Skip")}
          </Button>

          <Button
            className="mx-2"
            type="primary"
            onClick={() => {
              if (this.props.sideNavCollapsed) {
                this.props.collapseSideNav();
              }
              this.props.toggleRunTutorial();
              this.props.onClose(true);
            }}
            style={{
              float: config.language === "arabic" ? "left" : "right",
              borderRadius: 4,
              width: 80,
            }}
          >
            {t("GO")}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    locations: state.locations,
    sideNavCollapsed: state.pos.sideNavCollapsed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleRunTutorial: () => dispatch({ type: "TOGGLE_RUN_TUTORIAL" }),
    switchLanguage: (language) => dispatch(switchLanguage(language)),
    collapseSideNav: () => dispatch(collapseSideNav()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewBranchModal));
