const trans = {
  Warning: "تحذير",
  Check: "تأكد",
  "You're all good! No issues were found": "لا يوجد لديك اي مشاكل!",
  "Health checker": "صحة المتجر",
  "There are orders pending - check that these areas are assigned to at least one user's permissions.":
    "هناك طلبات معلقة - تأكد من وجود المناطق التالية في صلاحيات احد المستخدمين",
  "There are areas which customers can order to, but no staff member will see. Please assign these areas to at least one user's permissions.":
    "هناك مناطق يستطيع العملاء الطلب اليها، لكن لن يتمكن اي مستخدم من رؤية الطلب. الرجاء وضع المناطق التالية في صلاحيات مستخدم واحد على الأقل",
  "There are products that have delivery turned off, but are either stocked or produced. Please check these products' settings.":
    "المنتجات التالية غير مهيأة لطلب وقت التوصيل من العميل. الرجاء التأكد من اعدادات المنتج",
  "The following areas are not available to customers because they are not serviced by any branch":
    "المناطق التالية لا تظهر للعملاء لأنها غير تابعة لأي من الأفرع",
  Attention: "تحذير",
  View: "عرض",
  Dismiss: "اخفاء",
  "You have urgent issues": "يوجد لديك مشاكل طارئة",
  "Both delivery and pickup are turned off. Customers cannot place orders. Please turn one or both on":
    "التوصيل والاستلام معطلين. لن يستطيع الزبائن من وضع الطلبات. قم بتشغيل احد منهما او كلاهما.",
  "Pickup is turned off from one or more branches. Customers cannot place pickup orders from these brances. Please turn it on":
    "الاستلام معطل في واحد من الافرع او اكثر. لن يستطيع الزبائن من وضع طلبات الاستلام. قم بتشغيلها",
  "Delivery is turned off from one or more branches. Customers cannot place delivery orders from these brances. Please turn it on":
    " التوصيل معطل في واحد من الافرع او اكثر. لن يستطيع الزبائن من وضع طلبات التوصيل. قم بتشغيلها",
  "The following products do not have arabic name":
    "المنتجات التالية ليس لديها اسم باللغة العربية",
  "The following branches do not have working hours.":
    "الأفرع التالية ليس لديها ساعات عمل.",
  "The following products are active but not added to any branch":
    "المنتجات التالية مفعلة ولكن لم يتم اضافتها لأي من افرعك",
  "You have pickup enabled in your branches but none of those branches have working hours":
    "لديك افرع تم تفعيل الاستلام فيها، ولكن هذه الافرع لم يتم اضافة ساعات عمل لها",
  "You have delivery enabled in your branches but none of those branches have on demand delivery or scheduled delivery enabled":
    "لديك افرع تم تفعيل التوصيل فيها، ولكن كلا التوصيل في اقرب وقت والتوصيل المجدول غير مفعلين",
  "The following products are low on inventory":
    "المنتجات التالية مخزونها يكاد ان ينفد",
  "Pickup is enabled but the following branches do not have working hours.":
    "الاستلام مفعل لكن لا يوجد ساعات عمل للأفرع التالية",
  "OTP phone verification is enabled but you don't have enough SMS balance. Please top-up your SMS wallet":
    "خاصية التحقق من رقم الهاتف مفعلة ولكن لا يوجد لديك الرصيد الكافي لارسال رسائل التحقق. الرجاء اعادة تعبئة رصيد الSMS",
  "Click here to verify your contact details":
    "انقر هنا لتوثيق بيانات الاتصال الخاصة بك",
  Verify: "توثيق",
  verify: "توثيق",
  Verified:"موثق"
};

export default trans;
