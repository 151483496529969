let tr;
export default tr = {
  Name: "اسم",
  "Phone Number": "رقم التليفون",
  "Vehicle Type": "نوع السيارة",
  Delete: "حذف",
  ordersHistory: "تاريخ الطلبات",
  Driver: "سائق",
  Drivers: "السائقين",
  Username: "اسم االمستخدم",
  password: "كلمه السر",
  "First Name": "الاسم الأول",
  "Last Name": "إسم العائلة",
  email: "البريد الإلكتروني",
  submit: "إرسال",
  addDriverFormTitle: "أضف سائق",
  trackingCode: "كود التتبع",
  deliveryStatus: "حالة التوصيل",
  location: "موقعك",
  latitude: "العرض",
  longitude: "الطول",
  deleteDriver: "حذف سائق",
  "Are you sure you want to delete this user? You can't undo this":
    "هل أنت متأكد أنك تريد حذف هذا المستخدم؟ لا يمكنك التراجع عن هذا ؟",
  deleteDriverOkText: "نعم",
  deleteDriverCancelText: "إلغاء",
  assignOrder: "تعيين طلب",
  selectDriver: "حدد سائق",
  "Search for driver": "البحث عن سائق",
  "New Driver": "سائق جديد",
  Status: "حالة السائق",
  "Order History": "تاريخ الطلب",
  "View Order History": "عرض سجل الطلبات",
  "Tracking Code": "كود التتبع",
  "Search for Tracking Code": "ابحث عن كود التتبع",
  "Dispatch Date-Time": "تاريخ الإرسال - الوقت",
  "Order Delivery Date-Time": "تاريخ ووقت تسليم الطلب",
  "Order Delivered in": "تم تسليم الطلب في",
  "Change Driver": "تغيير السائق",
  "Search to Select Driver": "البحث لتحديد سائق",
  "Driver changed successfully": "تم تغيير السائق بنجاح",
  "Driver status changed successfully": "تم تغيير حالة السائق بنجاح",
  "Driver have been added successfully": "تم اضافة سائق بنجاح",
  "Driver have been deleted successfully": "تم حذف سائق بنجاح", //FORM ERRORS
  "A user with that username already exists.": "يوجد مستخدم بهذا الاسم",
  "Phone Number already exists,please change phone number":
    "رقم الهاتف موجود، من فضلك قم بتغيير رقم الهاتف",
  "The phone number entered is not valid.":
    "رقم الهاتف الذي تم إدخاله غير صالح.",
  "Please enter username": "الرجاء إدخال اسم المستخدم",
  "Please enter password": "الرجاء إدخال كلمة المرور",
  "Please enter first name": "الرجاء إدخال الاسم الأول",
  "Please enter last name": "الرجاء إدخال الاسم الأخير",
  "Please enter phone number": "الرجاء إدخال رقم الهاتف",
  "Please enter vehicle type": "الرجاء إدخال نوع مركبة",
  "Can select driver": "يمكن تحديد السائق",
  "Can delete driver": "يمكن حذف السائق",
  "Can add driver": "يمكن إضافة سائق",
  "Can edit driver": "يمكن تعديل السائق",
  "Can view driver": "يمكن عرض السائق",
  "Change Driver Password": "تغيير كلمة مرور السائق",
  "Driver password changed successfully": "تم تغيير كلمة مرور السائق بنجاح",
  "Profile Picture": "الصوره الشخصيه",
  "Change Password": "تغيير كلمة السر",
  "Order Amount	": "قيمة الطلب",
  "Delivery Rate": "سعر التوصيل",
};
