import {
  FETCH_SERVICE_ORDER_ITEMS_LOADING,
  FETCH_SERVICE_ORDER_ITEMS,
  SAVE_SERVICE_ORDER,
  SOCKET_NEW_SERVICE_ORDER,
  UPDATE_CUSTOMER_SERVICE_STATUS,
  EDIT_SERVICE_ORDER_DATE_TIME,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchServiceBookings = (rangeStart, rangeEnd, withLoading, t) => {
  return async (dispatch) => {
    if (withLoading) dispatch({ type: FETCH_SERVICE_ORDER_ITEMS_LOADING });

    try {
      const res = await instance.post(`/fetch_service_bookings/`, {
        start: rangeStart,
        end: rangeEnd,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_SERVICE_ORDER_ITEMS, payload: res.data });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const saveServiceOrderObj = (bookingID, orderObj) => {
  return {
    type: SAVE_SERVICE_ORDER,
    payload: { bookingID, orderObj },
  };
};

export const socketNewServiceOrder = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_NEW_SERVICE_ORDER,
      payload: data,
    });
  };
};

export const updateCustomerServiceStatus = (order_id, status) => {
  return {
    type: UPDATE_CUSTOMER_SERVICE_STATUS,
    payload: { order_id, status },
  };
};

export const editServiceOrderDateTime = (id, date, start, end) => {
  return {
    type: EDIT_SERVICE_ORDER_DATE_TIME,
    payload: { id, date, start, end },
  };
};
