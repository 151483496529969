import React, { Component } from "react";
import { Spin, Button, Popover, message, List } from "antd";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
import { LoadingOutlined, CarOutlined, BellOutlined } from "@ant-design/icons";
import moment from "moment";
// import "moment/locale/ar";
import { fetchPickupRequests } from "../../store/actions/orders";
// moment.locale("en");
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const translateFromNowTime = (created, lang) => {
  // if (lang === "english")
  return moment(created, "YYYY-MM-DD HH:mm").fromNow();
  // let localLocale = moment(created, "YYYY-MM-DD HH:mm");
  // localLocale.locale("ar");
  // let res = localLocale.fromNow();
  // return res;
};

function PopoverContent({ data, orders, openOrderModal, language, t }) {
  let allOrders = [
    ...(orders.orders || []),
    ...(orders.promises || []),
    ...(orders.new_orders || []),
    ...(orders.recentlyReceived || []),
    ...(orders.pastOrders || []),
  ];

  const getCarDetails = (item) => {
    let tmp = "";
    const r = allOrders.find((o) => o.tracking_id === item.tracking_id);
    if (!r) return tmp;

    if (r.car_color) tmp = r.car_color;
    if (r.car_make || r.car_plate_number) tmp += " ";
    if (r.car_make) tmp += r.car_make;
    if (r.car_plate_number) tmp += " Plate #: " + r.car_plate_number;
    return tmp;
  };
  const getPhoneNumber = (item) => {
    const r = allOrders.find((o) => o.tracking_id === item.tracking_id);
    if (!r) return "";
    return r.phone;
  };
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          key={Math.random() * 9999}
          style={{ cursor: "pointer" }}
          onClick={() => openOrderModal(item.tracking_id, item.customer_name)}
        >
          <List.Item.Meta
            title={
              language === "arabic"
                ? item.customer_name.replace("Table", "طاولة")
                : item.customer_name
            }
            description={
              <>
                <div style={{ fontSize: 12 }}>{getPhoneNumber(item)}</div>
                {!!item.tracking_id && (
                  <div style={{ fontSize: 12 }}>{item.tracking_id}</div>
                )}
                <div style={{ fontSize: 12 }}>{getCarDetails(item)}</div>
              </>
            }
          />
          <span style={{ color: "gray", fontSize: 12 }}>
            {!item.read ? (
              <span
                style={{
                  height: 6,
                  width: 6,
                  display: "inline-block",
                  backgroundColor: "red",
                  borderRadius: "50%",
                }}
              ></span>
            ) : (
              <></>
            )}{" "}
            {translateFromNowTime(item.created, language)}
          </span>
        </List.Item>
      )}
    />
  );
}
class PickupCustomers extends Component {
  state = {
    loading: false,
    visible: false,
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.fetchPickupRequests(this.props.t);
    this.setState({ loading: false });
  };

  markRead = async (tid, customer_name = "") => {
    const { t } = this.props;
    try {
      const res = await instance.post("/mark_pickup_arrival_request_read/", {
        tracking_id: tid,
        customer_name: customer_name,
      });
      if (res.data.success)
        this.props.fetchPickupArrivalRequests(res.data.data);
      else message.error(t("SomethingWentWrong"));
    } catch (e) {
      console.error(e);
      message.error(t("SomethingWentWrong"));
    }
  };

  render() {
    const { t, settings, orders, pickupArrivalRequests, config } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Popover
          overlayClassName="pickupArrivalRequestsContent"
          placement="bottomLeft"
          onVisibleChange={(v) => this.setState({ visible: v })}
          overlayStyle={{
            minWidth: 350,
            maxHeight: 400,
            overflowY: "scroll",
            border: "1px solid #d4d4d4",
            padding: 0,
            background: "white",
            borderRadius: 5,
          }}
          visible={this.state.visible}
          title={<div>{t("Customers' Notifications")}</div>}
          content={
            loading ? (
              <div className="text-center mt-4">
                <Spin indicator={antIcon} />
              </div>
            ) : (
              <div style={{ maxWidth: 400 }}>
                <p
                  style={{
                    marginBottom: 3,
                    color: "gray",
                    fontSize: 12,
                    position: "relative",
                    top: -3,
                  }}
                >
                  {settings.enable_table_qr
                    ? t("These customers are requesting a waiter")
                    : t("These customers are at the pickup location")}
                </p>
                <PopoverContent
                  t={t}
                  data={pickupArrivalRequests}
                  orders={orders}
                  openOrderModal={(tid, customer_name = "") => {
                    this.props.setOpenOrderModal(tid);
                    this.markRead(tid, customer_name);
                    this.setState({ visible: false });
                  }}
                  language={config.language}
                />
              </div>
            )
          }
          trigger="click"
        >
          <Button
            type="default"
            style={{ ...this.props.style }}
            icon={settings.enable_table_qr ? <BellOutlined /> : <CarOutlined />}
            onClick={() => this.setState({ visible: !this.state.visible })}
          >
            {settings.enable_table_qr ? t("Waiter calls") : t("Pickups")}
          </Button>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    orders: state.orders,
    pickupArrivalRequests: state.orders.pickupArrivalRequests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPickupArrivalRequests: (data) =>
      dispatch({ type: "FETCH_PICKUP_ARRIVAL_REQUESTS", payload: data }),
    setOpenOrderModal: (val) =>
      dispatch({ type: "SET_OPEN_ORDER_MODAL", payload: val }),
    fetchPickupRequests: (t) => dispatch(fetchPickupRequests(t)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PickupCustomers));
