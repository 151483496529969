const trans = {
  "Use tracking code, number, or customer name":
    "ابحث بواسطة رقم التتبع، رقم الهاتف، أو اسم العميل",
  "Abandoned Orders": "الطلبات المعلقة",
  Abandoned: "معلق",
  "Approve order": "قبول الطلب",
  "Are you sure you want to approve this order?":
    "هل انت متأكد من قبول هذا الطلب؟",
  "This section allows you to search for orders that have been initiated with online payments, but either have not been paid yet, or paid but not confirmed from the payment gateway":
    "هذا القسم يمكنك من البحث عن طلبات بدأت بعملية دفع الكتروني ولكن لم يتحقق الدفع، أو تحقق الدفع ولكن لم يصل اشعار للإثبات الدفع من بوابة الدفع",
  "Make sure you have got proof of payment before approving an order":
    "الرجاء التأكد من وجود دليل يأكد اتمام الدفع قبل الموافقة على الطلب",
  "Order approved. It will appear in Customer Orders now":
    "تم قبول الطلب. سوف يظهر في قسم طلبات الزبائن",
  BusinessOrders: "الشركات",
  "Paid successfully": "تم الدفع بنجاح",
  "Check payment": "تحقق من الدفع",
  "Order is not paid": "الطلب غير مدفوع",
};

export default trans;
