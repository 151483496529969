const trans = {
  "Cart is Empty": "السلة فارغة",
  Subtotal: "المبلغ اللإجمالي",
  "Are you sure you want to clear this order ?":
    "هل انت متأكد من انك تريد مسح هذا الطلب ؟",
  "Change Branch": "تغير الفرع",
  "Customer Details": "معلومات الزبون",
  Existing: "قديم",
  "Walk-in": "مستلم",
  "Select Customer": "إختر زبون",
  "Select Branch": "إختر فرع",
  Pickup: "إستلام",
  "Pickup from": "إستلام من",
  "Delivery to": "توصيل إلى",
  "Add a new address": "أضف عنوان",
  "Bill to": "توصيل الفاتوره إلى",
  "Select Delivery Address": "إختر عنوان التوصيل",
  "Select Billing Address": "إختر عنوان توصيل الفاتورة",
  "Delivery Details": "معلومات التوصيل",
  "Skip address": "تخطي العنوان",
  Charge: "إتمام الطلب",
  "Out of Stock": "غير متوفر",
  "Add to Cart": "أضف إالى السلة",
  "Special Remarks": "ملاحظات",
  "Override Stock": "تجاوز المخزون",
  Booking: "الحجوزات",
  Time: "الوقت",
  "Select Date": "إختر الوقت",
  "Select Time": "إختر التاريخ",
  to: "الى",
  from: "من",
  Choose: "إختر",
  Optional: "إختياري",
  min: "أدنى",
  max: "أقصى",
  "Payment Method": "طريقة الدفع",
  "Payment Completed ?": "مدفوع ؟",
  "Date and Time": "التاريخ و الوقت",
  "Finalize Order": "انهي الطلب",
  KNET: "كي نيت",
  "Place Order": "إتمام الطلب",
  "All Day": "طوال اليوم",
  "Product Added to Cart": "تمت إضافة المنتج إالى السلة",
  Ok: "تم",
  "Walk-in Customer": "زبون مستلم",
  "Automatically Receive Order": "استلام الطلب تلقائيا",
  "Paid Amount": "المبلغ المدفوع",
  Change: "المتبقي",
  "Calculate Change": "احسب المتبقي",
  "New Sale": "طلب جديد",
  Source: "المصدر",
  "Select a source (optional)": "اختر مصدر (إختياري)",
  "New Source": "مصدر جديد",
  "Select a source": "إختر مصدر",
  "Online Payment": "دفع إلكتروني",
  "Edit Table Number": "تعديل رقم الطاولة",
  "Table Number": "رقم الطاولة",
  "Select a Table Number": "اختر رقم الطاولة",
  "Remarks: ": "ملاحظات: ",
  "Editing ": " تعديل ",
  "New Address": "عنوان جديد",
  "Edit Address": "تعديل العنوان",
  Failed: " فشل",
  Pushed: " نجح",
  Processing: " جار الإرسال",
  "Ocims Status": "حالة Ocims",
  "Added Product": "إضافة منتج",
  "Added Option": "إضافة خيار",
  "Added Discount": "إضافة خصم",
  "Deleted Product": "إزالة منتج",
  "Deleted Option": "إزالة خيار",
  "Deleted Discount": "إزالة خصم",
  "Special Remarks for Product": "ملاحظات للمنتج",
  "Price of Product": "سعر للمنتج",
  "Quantity for Product": "كمية المنتج",
  "Quantity for Option": "كمية الخيار",
  "Ignore Inventory for Product": "تجاوز المخزون للمنتج",
  "Booking Slot for Product": "فترة الحجز للمنتج",
  "New Order": "طلب جديد",
  "Edit Order": "تعديل طلب",
  "Filter By Area": "تصفية حسب المنطقة",
  "More Details": "التفاصيل",
  "Override Total": "تحديد الإجمالي",
  "Override Price": "تحديد السعر",
  "Custom Payment Method": "طريقة دفع مخصصة",
  "Cash and Online Payments are disabled. Please enable one of them from":
    "الدفع نقدا و الدفع إلكتروني معطلين. الرجاء تفعيل أحدهما من",
  "payment settings.": "إعدادات الدفع.",
  "Default Branch": "الفرع الإفتراضي",
  "The order cannot be edited either because you dont have permission or the payment is complete":
    "الطلب لا يمكن تعديله اما بسبب ان ليس لديك صلاحية او لأن الطلب مدفوع",
  Transfer: "تحويل",
  "Transfer orders": "تحويل الطلبات",
  "Error while transferring orders": "حدث خطأ أثناء تحويل الطلبات",
  "could not be transfered because": "لا يمكن تحويله لان",
  "does not have enough inventory": "ليس لديه مخزون كاف",
  "not available": "غير متوفر",
  "Gross Amount": "المبلغ الإجمالي",
  "Select payment method": "اختر طريقة الدفع",
  "Methods List": "القائمة",
  "Add Payment Method": "إضافة طريقة دفع",
  "Payment Methods List": "قائمة طرق الدفع",
  "Payment Method already in the list": "طريقة الدفع موجودة القائمة",
  "Payment Method added successfully": "تم إضافة طريقة الدفع بنجاح",
  "Delivery VAT Excluded": "الضريبة غير مشمولة في التوصيل",
  "Make it a gift": "اجعلها هدية",
  "Gift Details": "تفاصيل الهدية",
  "Is this a gift?": "هل هذا الطلب هدية",
  "Receiver name": "اسم المستلم",
  "Receiver phone": "رقم هاتف الشخص المستلم",
  "Call recipient on arrival": "عند الوصول سيتم التواصل مع المستلم",
  "Send gift anonymously": "إرسال الهدية بشكل مجهول",
  "Gift link": "رابط الهدية",
  "Gift message": "رسالة الاهداء",
  Sources: "المصادر",
  "Add Source": "اضف مصدر",
  "Are you sure you want to delete this source?":
    "هل انت متأكد من حذف هذا المصدر",
  "Show only registered customers": "إظهار العملاء المسجلين فقط",
  "Mark as new order": "وضع علامة كطلب جديد",
  "Gift Wrapping": "تغليف الهدايا",
};

export default trans;
