import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
// Antd components
import { Table, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  promotionIsAvailable,
  setManuallyAppliedPromotion,
} from "../../../store/actions/pos";
import Toggle from "../../common/Toggle";
import { getConditionsText } from "../../Promotions/smartPromotions/PromotionsList";

const { Column } = Table;
class DiscountListTab extends Component {
  state = {};

  renderColumnAmount = (text, record) => {
    const { store_settings, t, config } = this.props;
    const currencey =
      config.language === "arabic"
        ? store_settings.currency_local
        : store_settings.currency_english;

    switch (record.type) {
      case "percent":
        return `${text} %`;

      case "fixed":
        return `${text} ${currencey}`;

      default:
        return text;
    }
  };

  renderColumnDelete = (text, record) => {
    const { manuallyAppliedPromotion } = this.props;

    return (
      <Toggle
        isActive={manuallyAppliedPromotion?.id === record.key}
        onClick={() =>
          this.props.setManuallyAppliedPromotion(
            manuallyAppliedPromotion?.id === record.key
              ? null
              : {
                  type: "coupon",
                  id: record.key,
                }
          )
        }
      />
    );
  };

  renderColumnConditions = (text, record) => {
    const { t, settings, config } = this.props;
    let conditionsText = getConditionsText(
      record.conditions,
      record.code,
      settings,
      config,
      t
    );

    return (
      <div>
        <ul style={{ listStyle: "none", fontSize: "14px" }}>
          {conditionsText.length
            ? conditionsText.map((condition, index) => (
                <li key={index}>{condition}</li>
              ))
            : t("Uses the website")}
        </ul>
      </div>
    );
  };

  render() {
    const { t, config, promotions, currentBranch } = this.props;

    if (!promotions) return null;
    let data = [];
    data = promotions.filter(
      (promotion) =>
        promotion.is_active &&
        !promotion.is_expired &&
        promotion.apply_to_pos_orders &&
        (!promotion.available_in_branches.length ||
          promotion.available_in_branches.includes(currentBranch)) &&
        (!promotion.auto_apply_pos_orders ||
          promotion.conditions.some((condition) =>
            ["coupon", "points"].includes(condition.type)
          )) &&
        promotionIsAvailable(promotion.start, promotion.availability)
    );

    return (
      <div
        style={{
          marginTop: 8,
          textAlign: config.direction == "ltr" ? "left" : "right",
          direction: config.direction,
        }}
      >
        <Table dataSource={data} size="small" rowClassName="text-center">
          <Column title={t("Name")} dataIndex="name" />
          <Column
            title={t("Conditions")}
            dataIndex="conditions"
            render={this.renderColumnConditions}
          />

          <Column title={t("Apply")} render={this.renderColumnDelete} />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    store_settings: state.auth.settings,
    promotions: state.promotions.promotions,
    currentBranch: state.pos.currentBranch,
    manuallyAppliedPromotion: state.pos.manuallyAppliedPromotion,
    settings: state.auth.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setManuallyAppliedPromotion: (payload) =>
      dispatch(setManuallyAppliedPromotion(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(DiscountListTab)));
