import {
  FETCH_FEEDBACKS,
  SET_FEEDBACKS_FETCH_LOADING,
  TOGGLE_FEEDBACK_READBY,
  SOCKET_NEW_FEEDBACK,
  BULK_SET_READ_BY_FEEDBACK,
  SET_SURVIES_FETCH_LOADING,
  FETCH_SURVIES,
  CREATE_SURVIES,
  UPDATE_SURVIES,
  DELETE_SURVEY,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  surveyLoading: false,
  survies: [],
  feedbacks: null,
};

export default (state = initialState, { type, payload }) => {
  let newFeedBacks = state.feedbacks;
  let fetchedPages = fetchedPages;
  switch (type) {
    case SET_FEEDBACKS_FETCH_LOADING:
      return { ...state, loading: true };
    case FETCH_FEEDBACKS:
      return {
        ...state,
        feedbacks: payload["feedbacks"].feed_backs
          ? {
              ...state.feedbacks,
              [payload["pageNumber"]]: payload["feedbacks"].feed_backs,
            }
          : [],
        feedback_exists: payload["feedbacks"].feedback_exists
          ? payload["feedbacks"].feedback_exists
          : false,
        totalPages: payload["feedbacks"].page_count
          ? payload["feedbacks"].page_count
          : 1,
        loading: false,
      };

    case SET_SURVIES_FETCH_LOADING:
      return { ...state, surveyLoading: true };
    case FETCH_SURVIES:
      return {
        ...state,
        survies: payload.data,
        surveyLoading: false,
      };
    case CREATE_SURVIES:
      let newSurvies = state.survies;
      if (payload.isBulk)
        payload.survey.forEach((survey) => {
          newSurvies.unshift(survey);
        });
      else {
        newSurvies.unshift(payload.survey);
      }
      return {
        ...state,
        survies: newSurvies,
        surveyLoading: false,
      };

    case DELETE_SURVEY:
      let newSurvies2 = state.survies;
      const index = newSurvies2.findIndex((survey) => survey.id == payload);
      newSurvies2[index].is_deleted = true;
      return {
        ...state,
        survies: newSurvies2,
      };

    case TOGGLE_FEEDBACK_READBY:
      newFeedBacks[payload.page][payload.index].read =
        !newFeedBacks[payload.page][payload.index].read;
      return {
        ...state,
        feedbacks: newFeedBacks,
      };

    case SOCKET_NEW_FEEDBACK:
      if (!newFeedBacks) return state;

      newFeedBacks.unshift(payload);

      return {
        ...state,
        feedbacks: newFeedBacks,
      };

    case BULK_SET_READ_BY_FEEDBACK:
      let searched_feedback;
      payload.ids.forEach((id) => {
        searched_feedback = newFeedBacks.find(
          (feedback) => feedback.key === id
        );
        if (searched_feedback) {
          searched_feedback.read = payload.status;
        }
      });
      return {
        ...state,
        feedbacks: newFeedBacks,
      };
    case "TOGGLE_SHOW_ON_WEBSITE_FEEDBACK": {
      let newFeedBack = state.feedbacks;
      const indexx = newFeedBack[payload.page].findIndex(
        (feedback) => feedback.key === payload.id
      );
      newFeedBack[payload.page][indexx].show_on_website =
        !newFeedBack[payload.page][indexx].show_on_website;
      return {
        ...state,
        feedbacks: newFeedBack,
      };
    }
    default:
      return state;
  }
};
