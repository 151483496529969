import React, { Component, Suspense } from "react";
import { Menu, Dropdown, Tooltip, Upload } from "antd";
import {
  DownOutlined,
  PrinterOutlined,
  LoadingOutlined,
  EyeOutlined,
  EditOutlined,
  UploadOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Radio } from "antd";
import { Modal, Button, Spin } from "antd";
import { Result } from "antd";
import { Tabs } from "antd";
import CustomerInfo from "./CustomerInfo";
import Invoice from "./Invoice";
import { Popconfirm, message, Checkbox } from "antd";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Input } from "antd";
import moment from "moment";
import instance from "../../store/actions/instance";
import DHL from "./DHL";
import {
  updateOrderNotesLocally,
  dispatchOrderLocally,
  undispatchOrder,
  fetchOrders,
  updateCustomerLatLngLocally,
} from "../../store/actions/orders";
import { Select } from "antd";
import CancellationReasonModal from "./CancellationReasonModal";
import GiftingTemplates from "./GiftingTemplates";
import Talabat from "./Talabat";
import { assignOrdertoDriver, getDrivers } from "../../store/actions/drivers";
import OrderOwnSelect from "./OrderOwnSelect";
import { withRouter } from "react-router-dom";
import { PhoneOutlined } from "@ant-design/icons";
import OrderPayments from "./OrderPayments";
import { fetchInventoryIntegrations } from "../../store/actions/integration";
import QuictActionPrinting from "./QuictActionPrinting";
import Aramex from "./Aramex";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const Revel = React.lazy(() => import("./Revel"));
const Homey = React.lazy(() => import("./Homey"));
const ExternalSourcePush = React.lazy(() => import("./ExternalSourcePush"));

class QuickActionsModal extends Component {
  state = {
    notesSaveLoading: false,
    notes: this.props.selectedOrder.obj.notes,
    currentTab:
      this.props.selectedOrder &&
      !this.props.selectedOrder.promise &&
      (this.props.is_staff ||
        (this.props.permissions && this.props.permissions.can_edit_orders)) &&
      this.props.goToNotes
        ? "5"
        : this.props.selectedOrder.promise &&
          !this.props.permissions.can_edit_orders
        ? "2"
        : "1",
    dispatchRadioValue: "",
    dispatchLoading: false,
    retryData: null, //OGO (from backend)
    retryAreaId: "", //OGO (to be set from dropdown)
    retryBlockNumber: "", //OGO (to be set from dropdown)
    branch_id: null, //Armada
    armadaRetry: null, //Armada,
    armadaRetryArea: "", //Armada (string)
    mirsalRetry: null, //Mirsal
    mirsalRetryArea: "", //Mirsal
    mirsalRetryBlock: "", //Mirsal

    cancelOrderReasonModalOpen: false,

    urwagonAreas: null,
    urwagonBlocks: null,
    selectedUrwagonArea: "",
    selectedUrwagonBlock: "",
    selectedUrwagonLatLng: null,
    renameAreaUrwagon: false,

    deliveryRunnersAreas: null,
    selectedDeliveryRunnersArea: null,

    invoicesLoading: false,
    printableToken: null,

    printLayout: localStorage.getItem("printLayout") || "a4",
    printLanguage: localStorage.getItem("printLanguage") || "english",
    printOptions: localStorage.getItem("printOptions") || "show_options",

    driver: null,
    changeDriverOption: false,

    shouldTopupWallet: false,

    refundWallet: false,

    wiyakFee: null,
    wiyakFeeLoading: false,

    fileList: [],

    newLat: "",
    newLng: "",

    dhlDeliveryRate: null,
  };
  urwagonBlockSelectRef = React.createRef();

  getWiyakDeliveryFee = async () => {
    const { t } = this.props;

    try {
      let branch_id = this.state.branch_id;
      if (this.props.permissions.wiyak_branches.length === 1)
        branch_id = this.props.permissions.wiyak_branches[0].id;
      if (!branch_id) return;
      this.setState({ wiyakFeeLoading: true });
      const res = await instance.post("/get_wiyak_delivery_fee/", {
        oid: this.props.selectedOrder.key,
        branch_id: branch_id,
      });
      if (res.data.success && res.data.fee) {
        this.setState({ wiyakFee: res.data.fee });
      } else {
        if (res.data.message) message.error(res.data.message);
        this.setState({ wiyakFee: -1 });
      }
    } catch (e) {
      console.error(t("SomethingWentWrong"));
      this.setState({ wiyakFee: -1 });
    }
    this.setState({ wiyakFeeLoading: false });
  };
  componentDidUpdate = () => {
    if (
      !this.state.wiyakFee &&
      this.state.currentTab == "4" &&
      !this.isOkButtonDisabled() &&
      (this.state.dispatchRadioValue === "wiyak" ||
        this.state.dispatchRadioValue === "wiyak_custom") &&
      !this.state.wiyakFeeLoading
    ) {
      this.getWiyakDeliveryFee();
    }

    if (
      !this.state.dhlDeliveryRate &&
      this.state.currentTab == "4" &&
      this.state.dispatchRadioValue === "_dhl" &&
      !this.state.dhlLoading
    ) {
      this.getDHLDeliveryRate();
    }
  };
  canRefundOrder = (order, checkRefundWallet = false) => {
    const { settings } = this.props;

    if (order.refund_id) return false;
    if (
      settings.enable_myfatoorah &&
      order.payment_method.toLowerCase().includes("fatoorah")
    )
      return true;

    if (
      settings.enable_tamara &&
      order.payment_method.toLowerCase().includes("tamara")
    )
      return true;

    if (
      (settings.enable_tahseeel || settings.enable_tahseeel_credit) &&
      order.payment_method.toLowerCase().includes("tahseeel")
    )
      return true;

    if (
      settings.enable_tap &&
      order.payment_method.toLowerCase().includes("tap")
    )
      return true;

    if (
      settings.enable_sadad &&
      order.payment_method.toLowerCase().includes("sadad")
    )
      return true;

    if (
      (settings.enable_bookey || settings.enable_bookey_credit) &&
      order.payment_method.toLowerCase().includes("bookey")
    )
      return true;

    if (
      settings.enable_tap_v2 &&
      order.payment_method.toLowerCase().includes("tap_v2")
    )
      return true;

    if (
      settings.enable_hesabe &&
      order.payment_method.toLowerCase().includes("hesabe")
    )
      return true;

    if (
      settings.enable_upay_v2 &&
      order.payment_method.toLowerCase().includes("upay_v2") &&
      !!order.payment_reference
    )
      return true;

    if (
      settings.enable_upay &&
      order.payment_method.toLowerCase().includes("upay") &&
      !order.payment_method.toLowerCase().includes("v2") &&
      !!order.payment_extra1
    )
      return true;

    if (order.payment_method.toLowerCase().includes("cash")) return true;

    if (
      !checkRefundWallet &&
      settings.enable_wallet &&
      settings.enable_wallet_staff &&
      (order.payment_method === "wallet" || order.user)
    ) {
      return true;
    }

    return false;
  };
  translateDateTimeToArabic = (text) => {
    const { config } = this.props;
    if (config.language != "arabic") return text;
    let newText = text;

    while (newText.includes("PM")) {
      newText = newText.replace("PM", "م");
    }
    while (newText.includes("AM")) {
      newText = newText.replace("AM", "ص");
    }
    return newText;
  };

  onChangeDispatchRadio = (e) => {
    this.setState({
      dispatchRadioValue: e.target.value,
      retryData: null,
      retryAreaId: "",
      retryBlockNumber: "",
      branch_id: null,
      armadaRetry: null,
      armadaRetryArea: "",
      mirsalRetry: null,
      mirsalRetryArea: "",
      mirsalRetryBlock: "",
      urwagonAreas: null,
      urwagonBlocks: null,
      selectedUrwagonArea: "",
      selectedUrwagonBlock: "",
      selectedUrwagonLatLng: null,
      renameAreaUrwagon: false,
      fodoAreas: null,
      selectedFodoArea: "",

      logestechsCorrectionData: null,
      logestechsSelectedSender: null,
      logestechsSelectedReceiver: null,
      wiyakFee: null,
      wiyakFeeLoading: false,
      dhlDeliveryRate: null,
      shouldTopupWallet: false,
    });
  };
  onChangeArmadaBranch = (e) => {
    /* This function is being used for other providers as well
     because branch_id is used for all the providers that have
     credentials different from one branch to another
    */
    this.setState({ branch_id: e.target.value });

    if (
      this.state.dispatchRadioValue === "wiyak" ||
      this.state.dispatchRadioValue === "wiyak_custom"
    ) {
      this.getWiyakDeliveryFee();
    } else if (this.state.dispatchRadioValue === "_dhl")
      this.getDHLDeliveryRate();
  };

  getDHLDeliveryRate = async () => {
    this.setState({ dhlLoading: true });
    try {
      const res = await instance.post("/get_international_delivery_quote/", {
        order_id: this.props.selectedOrder.key,
      });

      if (res.data.success) {
        this.setState({
          dhlDeliveryRate: res.data.data.quote_data.shipping_charge,
        });
        if (res.data.should_top_up_wallet)
          this.setState({ shouldTopupWallet: true });
      } else {
        this.setState({ dhlDeliveryRate: null });
        message.error(
          "Could not fetch DHL delivery rate. Please contact us for help"
        );
      }
    } catch (e) {
      console.error("Something went wrong. Please contact us for help");
    }
    this.setState({ dhlLoading: false });
  };
  saveNotes = async () => {
    this.setState({ notesSaveLoading: true });
    const { fileList, notes } = this.state;
    const { t } = this.props;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("order", this.props.selectedOrder.key);
    formData.append("notes", notes);
    try {
      const res = await instance.post(`/save_notes/`, formData);
      this.setState({ notesSaveLoading: false });
      if (res.data.success) {
        this.props.updateOrderNotesLocally(
          this.props.selectedOrder.key,
          this.state.notes,
          res.data.notes_attachment
        );
        if (!!res.data.notes_attachment)
          this.setState({
            fileList: [
              {
                uid: "-1",
                name: res.data.notes_attachment.split("/")[
                  res.data.notes_attachment.split("/").length - 1
                ],
                status: "done",
                url: res.data.notes_attachment,
              },
            ],
          });
        message.success(t("NoteSavedSuccess"));
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };

  dispatchOrder = async (retry) => {
    // retry can be true for OGO, Mirsal, or Armada after filling retry data
    this.setState({ dispatchLoading: true });
    const { t } = this.props;
    const orderObj = this.props.selectedOrder.obj;

    let postData; // postData changes based on the delivery provider
    if (!retry) {
      postData = {
        oid: orderObj.id,
        dispatcher: this.state.dispatchRadioValue,
        driver_id: this.state.driver,
      };
    } else {
      if (this.state.dispatchRadioValue == "OGO Delivery") {
        postData = {
          oid: orderObj.id,
          dispatcher: this.state.dispatchRadioValue,
          retry: true,
          area_id: this.state.retryAreaId, // The area selected from the dropdown
          block_number: this.state.retryBlockNumber,
        };

        this.setState({
          retryData: null,
          retryAreaId: "",
          retryBlockNumber: "",
        });
      } else if (this.state.dispatchRadioValue == "Armada") {
        postData = {
          oid: orderObj.id,
          dispatcher: this.state.dispatchRadioValue,
          retry: true,
          area_name: this.state.armadaRetryArea,
        };
        this.setState({ armadaRetry: null, armadaRetryArea: "" });
      } else if (this.state.dispatchRadioValue == "Mirsal") {
        postData = {
          oid: orderObj.id,
          dispatcher: this.state.dispatchRadioValue,
          retry: true,
          order_area_name: this.state.mirsalRetryArea,
          order_block: this.state.mirsalRetryBlock,
        };
        this.setState({
          mirsalRetry: null,
          mirsalRetryArea: "",
          mirsalRetryBlock: "",
        });
      } else if (
        this.state.dispatchRadioValue == "Flick" ||
        this.state.dispatchRadioValue == "urwagon" ||
        this.state.dispatchRadioValue == "tam" ||
        this.state.dispatchRadioValue == "deliverix" ||
        this.state.dispatchRadioValue == "wasel" ||
        this.state.dispatchRadioValue == "logestechs" ||
        this.state.dispatchRadioValue == "kangaroo" ||
        this.state.dispatchRadioValue == "tookan" ||
        this.state.dispatchRadioValue == "clicks" ||
        this.state.dispatchRadioValue == "Snoonu" ||
        this.state.dispatchRadioValue == "primex" ||
        this.state.dispatchRadioValue == "streetline" ||
        this.state.dispatchRadioValue == "mashkor" ||
        this.state.dispatchRadioValue == "lyve" ||
        this.state.dispatchRadioValue == "_dhl"
      ) {
        postData = {
          oid: orderObj.id,
          dispatcher: this.state.dispatchRadioValue,
        };
      }
    }
    postData["branch"] = this.state.branch_id;

    if (
      this.state.dispatchRadioValue === "logestechs" &&
      !!this.state.logestechsCorrectionData
    ) {
      postData["useData"] = {
        sender: this.state.logestechsSelectedSender,
        receiver: this.state.logestechsSelectedReceiver,
      };
    }
    if (this.state.dispatchRadioValue == "urwagon") {
      postData["latLng"] = this.state.selectedUrwagonLatLng;
      postData["area"] = this.state.selectedUrwagonArea;
      postData["block"] = this.state.selectedUrwagonBlock;
      postData["rename"] = this.state.renameAreaUrwagon;
    }
    if (this.state.dispatchRadioValue == "fodo") {
      postData["area"] = this.state.selectedFodoArea;
    }
    if (this.state.dispatchRadioValue == "Delivery Runners") {
      postData["area"] = this.state.selectedDeliveryRunnersArea;
    }
    if (
      this.state.dispatchRadioValue == "Flick" &&
      this.props.permissions.flick_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.flick_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "Quick Delivery" &&
      this.props.permissions.quick_delivery_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.quick_delivery_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "primex" &&
      this.props.permissions.primex_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.primex_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "streetline" &&
      this.props.permissions.streetline_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.streetline_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "fodo" &&
      this.props.permissions.fodo_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.fodo_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "Delivery Runners" &&
      this.props.permissions.delivery_runners_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] =
        this.props.permissions.delivery_runners_branches[0].id;
    }

    if (
      (this.state.dispatchRadioValue === "wiyak" ||
        this.state.dispatchRadioValue === "wiyak_custom") &&
      this.props.permissions.wiyak_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.wiyak_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "mashkor" &&
      this.props.permissions.mashkor_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.mashkor_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "lyve" &&
      this.props.permissions.lyve_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.lyve_branches[0].id;
    }

    if (
      (this.state.dispatchRadioValue == "Snoonu" ||
        this.state.dispatchRadioValue == "urwagon" ||
        this.state.dispatchRadioValue == "tam" ||
        this.state.dispatchRadioValue == "deliverix" ||
        this.state.dispatchRadioValue == "wasel" ||
        this.state.dispatchRadioValue == "logestechs" ||
        this.state.dispatchRadioValue == "kangaroo" ||
        this.state.dispatchRadioValue == "tookan" ||
        this.state.dispatchRadioValue == "clicks" ||
        this.state.dispatchRadioValue == "verdi" ||
        this.state.dispatchRadioValue == "Parcel" ||
        this.state.dispatchRadioValue == "vdeliver") &&
      this.props.permissions.all_permitted_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.all_permitted_branches[0].id;
    }

    if (
      this.state.dispatchRadioValue == "OGO Delivery" &&
      this.props.permissions.ogo_branches.length == 1 &&
      !this.state.branch_id
    ) {
      postData["branch"] = this.props.permissions.ogo_branches[0].id;
    }

    try {
      const res = await instance.post(`/dispatch_order/`, postData);

      if (res.data.success == true && !res.data.errors) {
        //id
        //dispatcher
        //reference
        let walletCharge = null;
        if (this.state.dispatchRadioValue === "wasel")
          walletCharge = this.props.settings.wasel_delivery_fee;
        else if (this.state.dispatchRadioValue === "wiyak")
          walletCharge = res.data.delivery_fee;
        else if (this.state.dispatchRadioValue === "_dhl")
          walletCharge = res.data.delivery_fee;
        this.props.dispatchOrderLocally(
          orderObj.id,
          this.state.dispatchRadioValue,
          res.data.reference,
          res.data.tracking_link,
          res.data.estimatedDuration,
          res.data.QR,
          walletCharge,
          res.data.driver
        );
        if (this.props.fromPOS) {
          this.props.dispatchPOS(
            this.state.dispatchRadioValue,
            res.data.reference,
            res.data.tracking_link,
            res.data.estimatedDuration,
            res.data.QR,
            walletCharge,
            res.data.driver
          );
        }
        message.success(t("Order dispatched successfully"));
      } else {
        message.error(t(res.data.errors));
        if (
          // res.data.data contains english area name, area id, block list as a string with commas
          this.state.dispatchRadioValue == "OGO Delivery" &&
          res.data.data &&
          res.data.data.length
        ) {
          this.setState({
            retryData: res.data,
          });
        } else if (
          this.state.dispatchRadioValue == "Armada" &&
          res.data.data &&
          res.data.data.length
        ) {
          this.setState({ armadaRetry: res.data });
        } else if (
          this.state.dispatchRadioValue == "Mirsal" &&
          res.data.data &&
          res.data.data.length
        ) {
          this.setState({ mirsalRetry: res.data });
        } else if (this.state.dispatchRadioValue == "urwagon") {
          this.setState({
            urwagonAreas: res.data.area,
            urwagonBlocks: res.data.block,
            selectedUrwagonLatLng: null,
            selectedUrwagonArea: "",
            selectedUrwagonBlock: "",
            renameAreaUrwagon: false,
          });
        } else if (this.state.dispatchRadioValue == "fodo") {
          this.setState({ fodoAreas: res.data.area, selectedFodoArea: "" });
        } else if (
          this.state.dispatchRadioValue === "Delivery Runners" &&
          res.data.area
        ) {
          this.setState({
            deliveryRunnersAreas: res.data.area,
            selectedDeliveryRunnersArea: null,
          });
        } else if (
          this.state.dispatchRadioValue === "logestechs" &&
          res.data.correction
        ) {
          this.setState({ logestechsCorrectionData: res.data.correction });
        }
      }

      if (res.data.should_top_up_wallet) {
        this.setState({ shouldTopupWallet: true });
      }
      this.setState({ dispatchLoading: false });
    } catch (err) {
      console.error(err);
      if (err.response)
        if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };
  didFillInRetryOGO = () => {
    if (
      this.state.dispatchRadioValue == "OGO Delivery" &&
      this.state.retryData &&
      this.state.retryAreaId &&
      this.state.retryBlockNumber
    )
      return true;
    return false;
  };
  didFillInRetryArmada = () => {
    if (
      this.state.dispatchRadioValue == "Armada" &&
      this.state.armadaRetry &&
      this.state.armadaRetryArea
    )
      return true;
    return false;
  };
  didFillInRetryMirsal = () => {
    if (
      this.state.dispatchRadioValue == "Mirsal" &&
      this.state.mirsalRetry &&
      this.state.mirsalRetryArea &&
      this.state.mirsalRetryBlock
    )
      return true;
    return false;
  };
  isOkButtonDisabled = () => {
    const selectedOrder = this.props.selectedOrder;

    if (this.state.currentTab === "3") {
      if (
        !!this.state.newLat &&
        !!this.state.newLng &&
        (String(selectedOrder.obj.customer_lat) !== String(this.state.newLat) ||
          String(selectedOrder.obj.customer_lng) !== String(this.state.newLng))
      )
        return false;
      else return true;
    } else if (this.state.currentTab != "4") {
      if (
        this.props.status == selectedOrder.status &&
        this.props.paymentStatus == selectedOrder.paymentStatus
      )
        return true;
      return false;
    } else if (this.state.currentTab == "4") {
      if (
        (!selectedOrder.obj.phone_number ||
          selectedOrder.obj.phone_number == "") &&
        this.state.dispatchRadioValue !== "Own"
      ) {
        return true;
      }

      if (selectedOrder.obj.shipping.is_dispatched) return true;
      if (this.state.dispatchRadioValue) {
        if (
          this.state.dispatchRadioValue === "_dhl" &&
          (!this.state.dhlDeliveryRate || this.state.shouldTopupWallet)
        )
          return true;
        if (this.state.dispatchRadioValue == "OGO Delivery") {
          if (
            this.state.retryData &&
            this.state.retryAreaId &&
            this.state.retryBlockNumber
          )
            return false;
          if (
            this.props.permissions &&
            this.props.permissions.ogo_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          }
          if (!this.state.retryData) return false;
          return true;
        }
        if (this.state.dispatchRadioValue == "Mirsal") {
          if (!this.state.mirsalRetry) {
            if (
              this.props.permissions &&
              this.props.permissions.all_permitted_branches.length > 1 &&
              !this.state.branch_id
            ) {
              return true;
            }
            return false;
          } else {
            if (this.didFillInRetryMirsal() == true) return false;
            return true;
          }
        }
        if (this.state.dispatchRadioValue == "urwagon") {
          if (!this.state.urwagonAreas && !this.state.urwagonBlocks) {
            if (
              this.props.permissions &&
              this.props.permissions.all_permitted_branches.length > 1
            ) {
              if (this.state.branch_id) {
                return false;
              } else {
                return true;
              }
            } else return false;
          } else {
            if (this.state.selectedUrwagonLatLng) return false;
            return true;
          }
        }

        if (this.state.dispatchRadioValue === "logestechs") {
          if (!this.state.logestechsCorrectionData) {
            if (
              this.props.permissions &&
              this.props.permissions.all_permitted_branches.length > 1
            ) {
              if (this.state.branch_id) {
                return false;
              } else {
                return true;
              }
            } else return false;
          } else {
            if (
              this.state.logestechsCorrectionData.sender &&
              !this.state.logestechsSelectedSender
            )
              return true;
            if (
              this.state.logestechsCorrectionData.receiver &&
              !this.state.logestechsSelectedReceiver
            )
              return true;
            return false;
          }
        }

        if (this.state.dispatchRadioValue == "Flick") {
          if (
            this.props.permissions &&
            this.props.permissions.flick_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue == "Quick Delivery") {
          if (
            this.props.permissions &&
            this.props.permissions.quick_delivery_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue == "Delivery Runners") {
          if (
            !this.state.deliveryRunnersAreas ||
            this.state.selectedDeliveryRunnersArea
          ) {
            if (
              this.props.permissions &&
              this.props.permissions.delivery_runners_branches.length > 1
            ) {
              if (this.state.branch_id) {
                return false;
              } else {
                return true;
              }
            } else return false;
          } else return true;
        }

        if (
          this.state.dispatchRadioValue === "wiyak" ||
          this.state.dispatchRadioValue === "wiyak_custom"
        ) {
          if (
            this.props.permissions &&
            this.props.permissions.wiyak_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue == "fodo") {
          if (!this.state.fodoAreas || this.state.selectedFodoArea) {
            if (
              this.props.permissions &&
              this.props.permissions.fodo_branches.length > 1
            ) {
              if (this.state.branch_id) {
                return false;
              } else {
                return true;
              }
            } else return false;
          } else return true;
        }

        if (this.state.dispatchRadioValue == "mashkor") {
          if (
            this.props.permissions &&
            this.props.permissions.mashkor_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue == "primex") {
          if (
            this.props.permissions &&
            this.props.permissions.primex_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue == "streetline") {
          if (
            this.props.permissions &&
            this.props.permissions.streetline_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue == "lyve") {
          if (
            this.props.permissions &&
            this.props.permissions.lyve_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (
          this.state.dispatchRadioValue == "Snoonu" ||
          this.state.dispatchRadioValue == "tam" ||
          this.state.dispatchRadioValue == "deliverix" ||
          this.state.dispatchRadioValue == "wasel" ||
          this.state.dispatchRadioValue == "logestechs" ||
          this.state.dispatchRadioValue == "kangaroo" ||
          this.state.dispatchRadioValue == "tookan" ||
          this.state.dispatchRadioValue == "clicks" ||
          this.state.dispatchRadioValue == "verdi" ||
          this.state.dispatchRadioValue == "Parcel" ||
          this.state.dispatchRadioValue == "vdeliver"
        ) {
          if (
            this.props.permissions &&
            this.props.permissions.all_permitted_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }

        if (this.state.dispatchRadioValue != "Armada") {
          return false;
        } else {
          if (this.state.armadaRetry && !this.state.armadaRetryArea)
            return true;
          if (
            this.props.permissions &&
            this.props.permissions.armada_branches.length > 1
          ) {
            if (this.state.branch_id) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }
      } else {
        return true;
      }
    }
  };
  invoicePrint = () => {
    this.setState({ currentTab: "1" });
  };
  invoiceDispatch = () => {
    this.setState({ currentTab: "4" });
  };
  showDispatchTab = () => {
    const { selectedOrder } = this.props;
    if (
      !selectedOrder.promise &&
      selectedOrder.obj.is_delivery &&
      selectedOrder.status != "Cancelled" &&
      selectedOrder.status != "Refunded" &&
      (this.props.is_staff ||
        (this.props.permissions &&
          this.props.permissions.can_dispatch_orders)) &&
      this.props.settings &&
      this.props.settings.enable_dispatching
    )
      return true;

    return false;
  };

  getInvoiceToken = async () => {
    this.setState({ loading: true });
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_printable_token/`);
      if (res.data.success) {
        return res.data.token;
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };

  undispatch = async () => {
    const { selectedOrder, t, orders } = this.props;
    const orderObj = selectedOrder.obj;

    let originalOrderObj = [
      ...(orders.orders || []),
      ...(orders.new_orders || []),
      ...(orders.pastOrders || []),
      ...(orders.recentlyReceived || []),
    ].find((o) => o.id === selectedOrder.key);
    if (!originalOrderObj) {
      originalOrderObj = orderObj;
    }

    let url = "/undispatch_order/";
    if (originalOrderObj.shipping.dispatcher === "wiyak") {
      url = "/undispatch_wiyak/";
    }
    this.setState({ undispatchLoading: true });
    try {
      const res = await instance.post(url, {
        id: selectedOrder.obj.id,
      });
      if (res.data.success) {
        this.setState({ driver: null });
        this.props.undispatchOrder(selectedOrder.obj.id);
        message.success(t("Reverted dispatch successfully"));
      } else message.error(res.data.message || t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
    this.setState({ undispatchLoading: false });
  };

  printIframe = async (id, orderType) => {
    this.setState({ invoicesLoading: true });
    const { selectedOrder, settings } = this.props;
    let token;
    const { printOptions } = this.state;

    if (!this.state.printableToken) {
      token = await this.getInvoiceToken();
      this.setState({ printableToken: token });
    }
    token = this.state.printableToken;
    let type = orderType;
    if (selectedOrder.status == "New POS") {
      type = "order";
    }

    let res;
    if (id === "A4English") {
      res = await instance.get(
        `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/${token}/${type}/?printOptions=${printOptions}`,
        {
          printOptions: printOptions,
        }
      );
    } else if (id === "A4Arabic") {
      res = await instance.get(
        `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/${token}/${type}/?lang=ar&printOptions=${printOptions}`
      );
    } else if (id === "ThermalEnglish") {
      res = await instance.get(
        `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/base64/${token}/${type}/?printOptions=${printOptions}`
      );
    } else if (id === "ThermalArabic") {
      res = await instance.get(
        `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/base64/${token}/${type}/?lang=ar&printOptions=${printOptions}`
      );
    } else if (id === "ItemsEnglish") {
      res = await instance.get(
        `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/preparation/base64/${token}/${type}/?printOptions=${printOptions}`
      );
    } else if (id === "ItemsArabic") {
      res = await instance.get(
        `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/preparation/base64/${token}/${type}/?lang=ar&printOptions=${printOptions}`
      );
    }
    const iframe = document.frames
      ? document.frames["printingIframe"]
      : document.getElementById("printingIframe");
    // iframe.srcdoc = undefined;

    let data =
      id === "ThermalEnglish" ||
      id === "ThermalArabic" ||
      id === "ItemsEnglish" ||
      id === "ItemsArabic"
        ? "<div style='width: 500px;'><img style='width: 400px;' src='data:image/png;base64,"
            .concat(res.data)
            .concat("'/></div>")
        : res.data;
    iframe.srcdoc = data;

    return false;
  };

  viewInvoice = async (id, orderType) => {
    const { selectedOrder, settings } = this.props;
    let token;
    const { printOptions } = this.state;
    if (!this.state.printableToken) {
      token = await this.getInvoiceToken();
      this.setState({ printableToken: token });
    }
    token = this.state.printableToken;
    let type = orderType;
    if (selectedOrder.status == "New POS") {
      type = "order";
    }
    let url;
    if (id === "A4English") {
      url = `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/${token}/${type}/?printOptions=${printOptions}`;
    } else if (id === "A4Arabic") {
      url = `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/${token}/${type}/?lang=ar&printOptions=${printOptions}`;
    } else if (id === "ThermalEnglish") {
      url = `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/pdf/${token}/${type}/?printOptions=${printOptions}`;
    } else if (id === "ThermalArabic") {
      url = `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/pdf/${token}/${type}/?lang=ar&printOptions=${printOptions}`;
    } else if (id === "ItemsEnglish") {
      url = `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/preparation/pdf/${token}/${type}/?printOptions=${printOptions}`;
    } else if (id === "ItemsArabic") {
      url = `https://${settings.domain}/manage/invoice/${selectedOrder.key}/print/preparation/pdf/${token}/${type}/?lang=ar&printOptions=${printOptions}`;
    }

    window.open(url, "_blank");
  };

  whatsappInvoice = async (lang) => {
    const { selectedOrder, t } = this.props;

    let invoiceText = "";
    if (lang == "en") {
      let deliveryAddress = `
    ${
      selectedOrder.obj.area.name
        ? "Area: " + selectedOrder.obj.area.name + ", "
        : ""
    }${
        selectedOrder.obj.address.avenue
          ? "Avenue: " + selectedOrder.obj.address.avenue + ", "
          : ""
      } ${
        selectedOrder.obj.address.zone
          ? "Zone: " + selectedOrder.obj.address.zone + ", "
          : ""
      }${
        selectedOrder.obj.address.block
          ? "Block: " + selectedOrder.obj.address.block + ", "
          : ""
      } ${
        selectedOrder.obj.address.street
          ? "Street: " + selectedOrder.obj.address.street + ", "
          : ""
      }${
        selectedOrder.obj.address.building
          ? "Building: " + selectedOrder.obj.address.building + ", "
          : ""
      }${
        selectedOrder.obj.address.floor
          ? "Floor: " + selectedOrder.obj.address.floor + ", "
          : ""
      }${
        selectedOrder.obj.address.apartment
          ? "Apartment: " + selectedOrder.obj.address.apartment + ", "
          : ""
      }${
        selectedOrder.obj.address.additional
          ? "Additional Information: " + selectedOrder.obj.address.additional
          : ""
      }`;
      invoiceText = `Order: ${selectedOrder.tracking}%0aCustomer Name: ${
        selectedOrder.customer ? selectedOrder.customer[0] : ""
      }${
        selectedOrder.customer[1]
          ? "%0aPhone Number: " + selectedOrder.customer[1].slice(1)
          : ""
      }%0aTotal: ${selectedOrder.total}${
        deliveryAddress ? "%0aDelivery Address: " + deliveryAddress : " "
      } 
      ${
        selectedOrder.obj.address.gps_confirmed &&
        selectedOrder.obj.address.lat &&
        selectedOrder.obj.address.lng &&
        selectedOrder.obj.address.lat != "null" &&
        selectedOrder.obj.address.lng != "null"
          ? `%0aGet Direction: https://maps.google.com/maps?daddr=${selectedOrder.obj.address.lat},${selectedOrder.obj.address.lng}`
          : ""
      }
      ${
        selectedOrder.paymentType
          ? "%0aPayment Method: " + selectedOrder.paymentType
          : ""
      } ${
        selectedOrder.paymentStatus
          ? "%0aPayment Status: " +
            (selectedOrder.paymentStatus == 3 ? "Complete" : "Incomplete")
          : ""
      }`;
    } else {
      let deliveryAddress = `
  ${
    selectedOrder.obj.address.additional
      ? ", " + selectedOrder.obj.address.additional + " :معلومات اضافية"
      : ""
  }
  ${
    selectedOrder.obj.address.apartment
      ? ", " + selectedOrder.obj.address.apartment + " :شقة"
      : ""
  }
  ${
    selectedOrder.obj.address.floor
      ? ", " + selectedOrder.obj.address.floor + " :الطابق"
      : ""
  }
  ${
    selectedOrder.obj.address.building
      ? ", " + selectedOrder.obj.address.building + " :المبنى"
      : ""
  }
  ${
    selectedOrder.obj.address.street
      ? ", " + selectedOrder.obj.address.street + " :الشارع"
      : ""
  }
   ${
     selectedOrder.obj.address.block
       ? ", " + selectedOrder.obj.address.block + " :القطعة"
       : ""
   } 
   ${
     selectedOrder.obj.address.zone
       ? ", " + selectedOrder.obj.address.zone + " :المنطقة"
       : ""
   }
   ${
     selectedOrder.obj.address.avenue
       ? " ," + selectedOrder.obj.address.avenue + " :الجادة"
       : ""
   }
  ${selectedOrder.obj.area.name ? " ," + selectedOrder.obj.area.name : ""}`;

      invoiceText = ` ${selectedOrder.tracking} :الطلب%0a${
        selectedOrder.customer
          ? selectedOrder.customer[0] + " :اسم العميل%0a"
          : ""
      }${
        selectedOrder.customer[1]
          ? selectedOrder.customer[1].slice(1) + " :الهاتف%0a"
          : ""
      } ${selectedOrder.total} :الاجمالي%0a${
        deliveryAddress ? "" + deliveryAddress + " :العنوان%0a" : ""
      } 
      ${
        selectedOrder.obj.address.gps_confirmed &&
        selectedOrder.obj.address.lat &&
        selectedOrder.obj.address.lng &&
        selectedOrder.obj.address.lat != "null" &&
        selectedOrder.obj.address.lng != "null"
          ? `https://maps.google.com/maps?daddr=${selectedOrder.obj.address.lat},${selectedOrder.obj.address.lng} :الخريطة%0a`
          : ""
      }
      ${
        selectedOrder.paymentType
          ? selectedOrder.paymentType + " :نوع الدفع%0a"
          : ""
      } ${
        selectedOrder.paymentStatus
          ? "حالة الدفع  : " +
            (selectedOrder.paymentStatus == 3 ? "مدفوع" : " غير مدفوع")
          : ""
      }`;
    }

    window.open(`https://wa.me/?text=${invoiceText}`, "_blank");
  };
  capFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  onChangeDriverSelect = (value) => {
    this.setState({ ...this.state, driver: value });
  };

  onAssignOrder = () => {
    this.setState({ changeDriverOption: !this.state.changeDriverOption });
  };

  checkPayment = async () => {
    const { selectedOrder, t, pending } = this.props;
    this.setState({ checkPaymentLoading: true });
    try {
      const res = await instance.post("/check_payment/", {
        id: selectedOrder.obj.id,
        type: pending ? "promise" : "order",
      });
      if (res.data.success) {
        if (pending) {
          this.props.fetchPendingOrders();
          message.success(t("Paid successfully"));
          this.props.onClose();
        } else {
          this.props.fetchOrders(selectedOrder.obj.tracking_id);
          message.success(t("Paid successfully"));
        }
      } else message.error(t("Order is not paid"));
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }

    this.setState({ checkPaymentLoading: false });
  };

  checkWaselOrderStatus = async () => {
    const { t } = this.props;
    this.setState({ shippingStatusCheckLoading: true });

    try {
      const res = await instance.post("/check_wasel_order_status/", {
        oid: this.props.selectedOrder.obj.id,
      });
      if (res.data.success) message.success(t("Updated status successfully"));
      else message.error(t(res.data.message || "SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
    this.setState({ shippingStatusCheckLoading: false });
  };

  checkWiyakOrderStatus = async () => {
    const { t } = this.props;
    this.setState({ shippingStatusCheckLoading: true });
    try {
      const res = await instance.post("/check_wiyak_order_status/", {
        oid: this.props.selectedOrder.obj.id,
      });
      if (res.data.success) message.success(t("Updated status successfully"));
      else message.error(t(res.data.message || "SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
    this.setState({ shippingStatusCheckLoading: false });
  };

  handleAttachmentChange = (info) => {
    const { fileList } = this.state;
    let newFileList = [...info.fileList]; // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    newFileList = fileList.slice(-2); // 2. Read from response and show file link

    newFileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }

      return file;
    });
    this.setState({ fileList: newFileList });
  };

  componentDidMount = () => {
    const settings = this.props.settings;
    if (
      settings.enable_homey &&
      !this.props.inventoryIntegrations?.homey &&
      this.props.permissions.can_view_integrations
    ) {
      this.props.fetchInventoryIntegrations();
    }

    const { orders } = this.props;
    const orderObj = this.props.selectedOrder.obj;
    const selectedOrder = this.props.selectedOrder;
    let originalOrderObj = [
      ...(orders.orders || []),
      ...(orders.new_orders || []),
      ...(orders.pastOrders || []),
      ...(orders.recentlyReceived || []),
    ].find((o) => o.id === selectedOrder.key);
    if (!originalOrderObj) {
      originalOrderObj = orderObj;
    }

    if (!!originalOrderObj.notes_attachment) {
      this.setState({
        fileList: [
          {
            uid: "-1",
            name: originalOrderObj.notes_attachment.split("/")[
              originalOrderObj.notes_attachment.split("/").length - 1
            ],
            status: "done",
            url: originalOrderObj.notes_attachment,
          },
        ],
      });
    }

    this.onGoBack = (e) => {
      this.props.onClose();
    };
    window.addEventListener("popstate", (e) => this.onGoBack(e));

    if (
      this.props.driversSuccessStatus !== "success" &&
      this.props.permissions.can_view_driver &&
      this.props.selectedOrder.obj.shipping?.driver !== null &&
      this.props.settings.enable_driver
    ) {
      this.props.getDrivers();
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.onGoBack);
    if (window.location.pathname === "/manage/orders") {
      this.props.history.push("/manage/orders");
    }
  };
  editCustomerLocation = async () => {
    const { t, selectedOrder } = this.props;
    try {
      const res = await instance.post("/edit_customer_lat_lng/", {
        oid: selectedOrder.obj.id,
        lat: String(this.state.newLat),
        lng: String(this.state.newLng),
      });
      if (res.data.success) {
        message.success(t("Edited successfully"));
        this.props.updateCustomerLatLngLocally(
          selectedOrder.obj.id,
          String(this.state.newLat),
          String(this.state.newLng)
        );
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (e) {
      console.error(e);
      message.error(t("SomethingWentWrong"));
    }
  };

  handleOk = () => {
    if (this.state.currentTab === "3") {
      this.editCustomerLocation();
      return;
    }
    if (this.state.currentTab !== "4") {
      this.props.onOk();
    } else {
      if (
        this.didFillInRetryOGO() ||
        this.didFillInRetryArmada() ||
        this.didFillInRetryMirsal()
      ) {
        this.dispatchOrder(true);
      } else {
        this.dispatchOrder(false);
      }
    }
  };

  handleCancel = () => {
    this.props.onClose();

    this.setState({
      dispatchRadioValue: "",
      dispatchLoading: false,
      retryAreaId: "",
      retryData: "",
      retryBlockNumber: "",
      armadaRetry: null,
      armadaRetryArea: "",
      mirsalRetry: null,
      mirsalRetryArea: "",
      mirsalRetryBlock: "",
    });
  };
  isInternationalDelivery = () => {
    return !!(
      (this.props.selectedOrder.obj.area.country_name !==
        this.props.baseCountry ||
        this.props.selectedOrder.obj.area.country_name.toLowerCase() ===
          "saudi arabia") &&
      this.props.settings.enable_aramex &&
      this.props.selectedOrder.obj.is_delivery
    );
  };

  render() {
    if (!this.props.selectedOrder) return <></>;
    const { orders } = this.props;
    const orderObj = this.props.selectedOrder.obj;
    const selectedOrder = this.props.selectedOrder;
    let originalOrderObj = [
      ...(orders.orders || []),
      ...(orders.new_orders || []),
      ...(orders.pastOrders || []),
      ...(orders.recentlyReceived || []),
    ].find((o) => o.id === selectedOrder.key);
    if (!originalOrderObj) {
      originalOrderObj = orderObj;
    }

    const { t, config, permissions, settings } = this.props;
    const { currentTab } = this.state;

    const driver_info = this.props.drivers?.find(
      (driver) => driver.id === orderObj.shipping?.driver
    );

    const WhatsappMenu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            this.whatsappInvoice("en");
          }}
        >
          {t("English")}
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            this.whatsappInvoice("ar");
          }}
        >
          {t("Arabic")}
        </Menu.Item>
      </Menu>
    );
    const showChangeOrderSection =
      (!this.props.fromPOS && this.props.permissions.can_cancel_orders) ||
      (settings.enable_pos &&
        !orderObj.payment_complete &&
        (permissions.can_edit_other_users_orders ||
          orderObj.order_placed_by === this.props.username));
    return (
      <Modal
        width={
          currentTab === "payments"
            ? 900
            : settings.show_invoice_images
            ? 750
            : 520
        }
        destroyOnClose={true}
        title={
          <div
            className="ant-modal-title"
            style={{ textAlign: config.direction == "rtl" ? "right" : "left" }}
          >
            {t("Quick actions").concat(
              !selectedOrder.promise
                ? ` - ${t("Order")} #${selectedOrder.key}`
                : ""
            )}
          </div>
        }
        visible={this.props.visible}
        onCancel={this.handleCancel}
        footer={[
          <>
            <Button onClick={this.handleCancel} style={{ margin: "5px" }}>
              {t("Close")}
            </Button>
            <Tooltip
              placement="topLeft"
              title={
                this.state.currentTab === "4" &&
                selectedOrder.obj.phone_number === "" &&
                this.state.dispatchRadioValue !== "Own"
                  ? t("Phone number is missing")
                  : ""
              }
            >
              <Button
                onClick={this.handleOk}
                type="primary"
                loading={
                  this.state.currentTab === "4" && this.state.dispatchLoading
                }
                disabled={this.isOkButtonDisabled()}
                style={{
                  display:
                    (selectedOrder.promise ||
                      selectedOrder.status === "Cancelled" ||
                      selectedOrder.status === "Refunded" ||
                      (!this.props.is_staff &&
                        this.props.permissions &&
                        !this.props.permissions.can_edit_orders) ||
                      this.state.currentTab === "5" ||
                      this.state.currentTab === "payments") &&
                    !(
                      this.state.currentTab === "4" &&
                      !this.props.is_staff &&
                      this.props.permissions &&
                      this.props.permissions.can_dispatch_orders
                    ) &&
                    "none",
                }}
              >
                {this.state.currentTab === "4" ? t("Assign Driver") : t("Save")}
              </Button>
            </Tooltip>
          </>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <iframe
          id="printingIframe"
          style={{ display: "none" }}
          title="Receipt"
          onLoad={() => {
            const iframe = document.frames
              ? document.frames["printingIframe"]
              : document.getElementById("printingIframe");
            if (!this.state.invoicesLoading || !iframe.srcdoc) return;

            const iframeWindow = iframe.contentWindow || iframe;
            iframe.focus();
            iframeWindow.print();
            this.setState({ invoicesLoading: false });
          }}
        />

        {this.state.cancelOrderReasonModalOpen && (
          <CancellationReasonModal
            onClose={() => this.setState({ cancelOrderReasonModalOpen: false })}
            cancelOrder={(
              reason,
              refundCustomer,
              returnInventory,
              refundWallet
            ) => {
              this.props.cancelOrder(
                reason,
                refundCustomer,
                returnInventory,
                refundWallet
              );
              if (!refundCustomer) {
                this.setState({ cancelOrderReasonModalOpen: false });
              }
            }}
            originalOrder={originalOrderObj}
          />
        )}

        <Tabs
          activeKey={this.state.currentTab}
          defaultActiveKey={"1"}
          size={"large"}
          tabPosition={"top"}
          animated={false}
          onTabClick={(key) => this.setState({ currentTab: key })}
        >
          {this.props.is_staff ||
          !selectedOrder.promise ||
          (this.props.permissions && this.props.permissions.can_edit_orders) ? (
            <TabPane tab={t("Actions")} key="1">
              {selectedOrder.promise ? (
                <div className="text-center">
                  {this.props.pending ? (
                    <>
                      <Button
                        className="w-100 mb-3"
                        style={{
                          backgroundColor: "#0099cc",
                          borderColor: "#0099cc",
                          color: "white",
                        }}
                        loading={this.state.checkPaymentLoading}
                        onClick={() => this.checkPayment()}
                      >
                        {t("Check payment")}
                      </Button>
                      <Popconfirm
                        title={t(
                          "Are you sure you want to approve this order?"
                        )}
                        onConfirm={() => {
                          this.props.receivePromise();
                        }}
                        okText={t("Yes")}
                        cancelText={t("No")}
                        disabled={!permissions.can_approve_pending_orders}
                      >
                        {permissions.can_approve_pending_orders ? (
                          <Button
                            loading={this.props.loading}
                            className="w-100"
                            style={{
                              backgroundColor: "#52c41a",
                              borderColor: "#52c41a",
                              color: "white",
                            }}
                          >
                            {t("Approve order")}
                          </Button>
                        ) : (
                          <Tooltip
                            title={t(
                              "You do not have the permission to refund. Please visit the Permissions section"
                            )}
                          >
                            <Button
                              className="w-100"
                              style={{ width: "100%" }}
                              disabled={true}
                            >
                              {t("Approve order")}
                            </Button>
                          </Tooltip>
                        )}
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      {settings.print_before_receive && (
                        // <div
                        //   className="mb-3 "
                        //   style={{
                        //     textAlign:
                        //       config.direction == "ltr" ? "left" : "right",
                        //   }}
                        // >
                        //   <p className="mb-">{t("Print")} </p>
                        //
                        //   <Radio.Group
                        //     onChange={(e) => {
                        //       this.setState({ printLayout: e.target.value });
                        //       localStorage.setItem(
                        //         "printLayout",
                        //         e.target.value
                        //       );
                        //     }}
                        //     defaultValue={this.state.printLayout}
                        //     buttonStyle="solid"
                        //     size="medium"
                        //     className={"mb-2"}
                        //   >
                        //     <Radio.Button value="a4">{t("A4")}</Radio.Button>
                        //     <Radio.Button value="thermal">
                        //       {t("Thermal")}
                        //     </Radio.Button>
                        //     <Radio.Button value="items">
                        //       {t("Preparation")}
                        //     </Radio.Button>
                        //   </Radio.Group>
                        //
                        //   <Radio.Group
                        //     onChange={(e) => {
                        //       this.setState({ printLanguage: e.target.value });
                        //       localStorage.setItem(
                        //         "printLanguage",
                        //         e.target.value
                        //       );
                        //     }}
                        //     defaultValue={this.state.printLanguage}
                        //     //value={}
                        //     buttonStyle="solid"
                        //     size="medium"
                        //     style={{ margin: "0px 6px" }}
                        //   >
                        //     <Radio.Button value="english">
                        //       {t("English")}
                        //     </Radio.Button>
                        //     <Radio.Button value="arabic">
                        //       {t("Arabic")}
                        //     </Radio.Button>
                        //   </Radio.Group>
                        //
                        //   <Radio.Group
                        //     onChange={(e) => {
                        //       this.setState({ printOptions: e.target.value });
                        //       localStorage.setItem(
                        //         "printOptions",
                        //         e.target.value
                        //       );
                        //     }}
                        //     defaultValue={this.state.printOptions}
                        //     buttonStyle="solid"
                        //     size="medium"
                        //   >
                        //     <Radio.Button value="show_options">
                        //       {t("Show options")}
                        //     </Radio.Button>
                        //     <Radio.Button value="hide_options">
                        //       {t("Hide options")}
                        //     </Radio.Button>
                        //   </Radio.Group>
                        //
                        //   {!this.state.invoicesLoading ? (
                        //     <div className="mt-3">
                        //       <Button
                        //         icon={
                        //           <PrinterOutlined style={{ fontSize: 19 }} />
                        //         }
                        //         onClick={() => {
                        //           this.printIframe(
                        //             this.capFirstLetter(
                        //               this.state.printLayout
                        //             ).concat(
                        //               this.capFirstLetter(
                        //                 this.state.printLanguage
                        //               )
                        //             ),
                        //             "promise"
                        //           );
                        //         }}
                        //       >
                        //         {t("Print")}
                        //       </Button>
                        //       <Button
                        //         icon={<EyeOutlined style={{ fontSize: 19 }} />}
                        //         className="mx-1"
                        //         onClick={() =>
                        //           this.viewInvoice(
                        //             this.capFirstLetter(
                        //               this.state.printLayout
                        //             ).concat(
                        //               this.capFirstLetter(
                        //                 this.state.printLanguage
                        //               )
                        //             ),
                        //             "promise"
                        //           )
                        //         }
                        //       >
                        //         {t("View")}
                        //       </Button>
                        //     </div>
                        //   ) : (
                        //     <div className="mt-3">
                        //       <Spin indicator={antIcon} />
                        //     </div>
                        //   )}
                        // </div>
                        <QuictActionPrinting
                          printLayout={this.state.printLayout}
                          printLanguage={this.state.printLanguage}
                          printOptions={this.state.printOptions}
                          invoicesLoading={this.state.invoicesLoading}
                          localStorage={localStorage}
                          onPrint={() => {
                            this.printIframe(
                              this.capFirstLetter(
                                this.state.printLayout
                              ).concat(
                                this.capFirstLetter(this.state.printLanguage)
                              ),
                              "promise"
                            );
                          }}
                          onView={() => {
                            this.viewInvoice(
                              this.capFirstLetter(
                                this.state.printLayout
                              ).concat(
                                this.capFirstLetter(this.state.printLanguage)
                              ),
                              "promise"
                            );
                          }}
                          setState={(state, value) => {
                            this.setState({
                              state: value,
                            });
                          }}
                          antIcon={antIcon}
                        />
                      )}
                      {permissions.can_receive_orders ? (
                        <Button
                          onClick={() => this.props.receivePromise()}
                          loading={this.props.loading}
                          className="w-100 mt-2"
                          style={{
                            backgroundColor: "#52c41a",
                            borderColor: "#52c41a",
                            color: "white",
                          }}
                        >
                          {t("Receive Order")}
                        </Button>
                      ) : (
                        <Tooltip
                          title={t(
                            "You do not have the permission to receive Order. Please visit the Permissions section"
                          )}
                          className="w-100 mt-2"
                        >
                          <Button
                            style={{
                              width: "inherit",
                            }}
                            disabled={true}
                          >
                            {t("Receive Order")}
                          </Button>
                        </Tooltip>
                      )}
                      <div
                        style={{
                          textAlign:
                            config.direction == "ltr" ? "left" : "right",
                        }}
                      >
                        <p className="mt-3 mb-3">{t("Cancel Order")} </p>
                        <div>
                          {!this.props.fromPOS &&
                            this.props.permissions.can_cancel_orders && (
                              <Popconfirm
                                title={`${t("CancelOrderPrompt")} ${
                                  !this.props.is_staff &&
                                  this.props.permissions &&
                                  !this.props.permissions
                                    .can_reverse_order_status
                                    ? t("CancelOrderPromptNoUndo")
                                    : ""
                                }`}
                                onConfirm={() => {
                                  this.setState({
                                    cancelOrderReasonModalOpen: true,
                                  });
                                  //this.props.cancelOrder();
                                  //message.success(t("OrderCancelSuccess"));
                                }}
                                onCancel={() => {}}
                                okText={t("Yes")}
                                cancelText={t("No")}
                              >
                                <Button danger>{t("Cancel")}</Button>
                              </Popconfirm>
                            )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : selectedOrder.status != "Cancelled" &&
                selectedOrder.status != "Refunded" ? (
                <>
                  <div className="mb-3">
                    <p className="mb-1">{t("Print")} </p>

                    <Radio.Group
                      onChange={(e) => {
                        this.setState({ printLayout: e.target.value });
                        localStorage.setItem("printLayout", e.target.value);
                      }}
                      defaultValue={this.state.printLayout}
                      buttonStyle="solid"
                      size="medium"
                      className={"mb-2"}
                    >
                      <Radio.Button value="a4">{t("A4")}</Radio.Button>
                      <Radio.Button value="thermal">
                        {t("Thermal")}
                      </Radio.Button>
                      <Radio.Button value="items">
                        {t("Preparation")}
                      </Radio.Button>
                    </Radio.Group>

                    <Radio.Group
                      onChange={(e) => {
                        this.setState({ printLanguage: e.target.value });
                        localStorage.setItem("printLanguage", e.target.value);
                      }}
                      defaultValue={this.state.printLanguage}
                      //value={}
                      buttonStyle="solid"
                      size="medium"
                      style={{ margin: "0px 6px" }}
                    >
                      <Radio.Button value="english">
                        {t("English")}
                      </Radio.Button>
                      <Radio.Button value="arabic">{t("Arabic")}</Radio.Button>
                    </Radio.Group>

                    <Radio.Group
                      onChange={(e) => {
                        this.setState({ printOptions: e.target.value });
                        localStorage.setItem("printOptions", e.target.value);
                      }}
                      defaultValue={this.state.printOptions}
                      buttonStyle="solid"
                      size="medium"
                    >
                      <Radio.Button value="show_options">
                        {t("Show options")}
                      </Radio.Button>
                      <Radio.Button value="hide_options">
                        {t("Hide options")}
                      </Radio.Button>
                    </Radio.Group>

                    {!this.state.invoicesLoading ? (
                      <div className="mt-3">
                        <Button
                          icon={<PrinterOutlined style={{ fontSize: 19 }} />}
                          onClick={() => {
                            this.printIframe(
                              this.capFirstLetter(
                                this.state.printLayout
                              ).concat(
                                this.capFirstLetter(this.state.printLanguage)
                              ),
                              "order"
                            );
                          }}
                        >
                          {t("Print")}
                        </Button>
                        <Button
                          icon={<EyeOutlined style={{ fontSize: 19 }} />}
                          className="mx-1"
                          onClick={() =>
                            this.viewInvoice(
                              this.capFirstLetter(
                                this.state.printLayout
                              ).concat(
                                this.capFirstLetter(this.state.printLanguage)
                              ),
                              "order"
                            )
                          }
                        >
                          {t("View")}
                        </Button>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <Spin indicator={antIcon} />
                      </div>
                    )}
                  </div>

                  {this.props.is_staff ||
                  (this.props.permissions &&
                    this.props.permissions.can_edit_orders) ? (
                    <>
                      <p className="mt-0 mb-1">{t("Order Status")}</p>
                      <div>
                        <Radio.Group
                          onChange={this.props.onChangeOrderStatus}
                          defaultValue={this.props.status}
                          value={this.props.status}
                          buttonStyle="solid"
                          size="medium"
                        >
                          {this.props.is_staff ||
                          (this.props.permissions &&
                            this.props.permissions.can_reverse_order_status) ? (
                            <>
                              {(!settings.enable_table_qr && (
                                <>
                                  <Radio.Button value="Received">
                                    {t("Received")}
                                  </Radio.Button>
                                  <Radio.Button value="Preparing">
                                    {t("Preparing")}
                                  </Radio.Button>
                                  <Radio.Button value="Out for Delivery">
                                    {t(
                                      this.props.selectedOrder.obj.is_delivery
                                        ? "Delivering"
                                        : "Ready to collect"
                                    )}
                                  </Radio.Button>
                                </>
                              )) || (
                                <Radio.Button value="Open">
                                  {t("Open")}
                                </Radio.Button>
                              )}
                              <Radio.Button value="Complete">
                                {t("Complete")}
                              </Radio.Button>
                            </>
                          ) : (
                            [
                              "Received",
                              "Preparing",
                              "Out for Delivery",
                              "Complete",
                            ].map((status) => (
                              <Radio.Button
                                disabled={status > selectedOrder.status}
                                value={status}
                              >
                                {status == "Out for Delivery"
                                  ? t(
                                      this.props.selectedOrder.obj.is_delivery
                                        ? "Delivering"
                                        : "Ready to collect"
                                    )
                                  : t(status)}
                              </Radio.Button>
                            ))
                          )}
                        </Radio.Group>
                      </div>

                      <p className="mt-3 mb-1">{t("Payment Status")}</p>

                      <div>
                        <Radio.Group
                          onChange={
                            originalOrderObj.number_of_payments > 1
                              ? () => this.setState({ currentTab: "payments" })
                              : this.props.onChangePaymentStatus
                          }
                          defaultValue={
                            originalOrderObj.payment_complete
                              ? "Complete"
                              : "Incomplete"
                          }
                          value={this.props.paymentStatus}
                          buttonStyle="solid"
                          size="medium"
                          disabled={!permissions.can_edit_order_payment_status}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {!permissions.can_edit_order_payment_status ? (
                            <Tooltip
                              title={t(
                                `Payment status is ${
                                  originalOrderObj.payment_complete
                                    ? "complete"
                                    : "incomplete"
                                }. You don't have permission to change the payment status.`
                              )}
                              style={{ maxHeight: "50px" }}
                            >
                              <Radio.Button value="Complete">
                                {t("PaymentComplete")}
                              </Radio.Button>
                              <Radio.Button value="Incomplete">
                                {t("PaymentIncomplete")}
                              </Radio.Button>
                            </Tooltip>
                          ) : (
                            <>
                              <Radio.Button value="Complete">
                                {t("PaymentComplete")}
                              </Radio.Button>
                              <Radio.Button value="Incomplete">
                                {t("PaymentIncomplete")}
                              </Radio.Button>
                            </>
                          )}
                        </Radio.Group>
                      </div>
                      {originalOrderObj.payment_method?.includes("link") &&
                        !originalOrderObj.payment_complete && (
                          <div className="mt-3">
                            <Button
                              type="primary"
                              loading={this.state.checkPaymentLoading}
                              onClick={() => this.checkPayment()}
                            >
                              {t("Check payment")}
                            </Button>
                          </div>
                        )}
                      {showChangeOrderSection && (
                        <div>
                          <p className="mt-3 mb-1">{t("Change Order")} </p>
                          <div style={{ display: "flex", gap: 5 }}>
                            {settings.enable_pos &&
                              !orderObj.payment_complete &&
                              (permissions.can_edit_other_users_orders ||
                                orderObj.order_placed_by ===
                                  this.props.username) && (
                                <Button
                                  icon={
                                    <EditOutlined style={{ fontSize: 19 }} />
                                  }
                                  className="mx-1"
                                  onClick={() =>
                                    this.props.history.push("/manage/pos", {
                                      orderID: orderObj.id,
                                    })
                                  }
                                >
                                  {t("Edit")}
                                </Button>
                              )}
                            {!this.props.fromPOS &&
                              this.props.permissions.can_cancel_orders && (
                                <Popconfirm
                                  title={`${t("CancelOrderPrompt")} ${
                                    !this.props.is_staff &&
                                    this.props.permissions &&
                                    !this.props.permissions
                                      .can_reverse_order_status
                                      ? t("CancelOrderPromptNoUndo")
                                      : ""
                                  }`}
                                  onConfirm={() => {
                                    this.setState({
                                      cancelOrderReasonModalOpen: true,
                                    });
                                    //this.props.cancelOrder();
                                    //message.success(t("OrderCancelSuccess"));
                                  }}
                                  onCancel={() => {}}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                >
                                  <Button danger>{t("Cancel")}</Button>
                                </Popconfirm>
                              )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {(selectedOrder.status === "Refunded" ||
                    selectedOrder.status === "Cancelled") && (
                    <QuictActionPrinting
                      printLayout={this.state.printLayout}
                      printLanguage={this.state.printLanguage}
                      printOptions={this.state.printOptions}
                      invoicesLoading={this.state.invoicesLoading}
                      localStorage={localStorage}
                      onPrint={() => {
                        this.printIframe(
                          this.capFirstLetter(this.state.printLayout).concat(
                            this.capFirstLetter(this.state.printLanguage)
                          ),
                          "order"
                        );
                      }}
                      onView={() => {
                        this.viewInvoice(
                          this.capFirstLetter(this.state.printLayout).concat(
                            this.capFirstLetter(this.state.printLanguage)
                          ),
                          "order"
                        );
                      }}
                      setState={(state, value) => {
                        this.setState({
                          state: value,
                        });
                      }}
                      antIcon={antIcon}
                    />
                  )}
                  <Result
                    status="error"
                    title={t("CancelledResultTitle")}
                    subTitle={t("CancelledResultSubtitle")}
                  />
                  {!!selectedOrder.cancellation_reason && (
                    <p>
                      {t("Cancellation reason:")}{" "}
                      {t(selectedOrder.cancellation_reason)}
                    </p>
                  )}
                  {originalOrderObj.status === "Refunded" &&
                    !!originalOrderObj.refund_id && (
                      <p>
                        {t("Refund reference:")} {t(originalOrderObj.refund_id)}
                      </p>
                    )}
                  {(this.props.is_staff ||
                    (this.props.permissions &&
                      this.props.permissions.can_reverse_order_status)) &&
                  originalOrderObj.status !== "Refunded" &&
                  !this.props.orders.refundLoading ? (
                    <Button onClick={() => this.props.uncancelOrder()}>
                      {t("Uncancel Order")}
                    </Button>
                  ) : null}

                  {this.canRefundOrder(originalOrderObj) &&
                    originalOrderObj.status === "Cancelled" && (
                      <div className="my-3">
                        {this.props.permissions.can_refund_orders ? (
                          <Popconfirm
                            title={
                              <div>
                                <p>
                                  {t(
                                    "Are you sure you want to refund the customer? You can't undo this"
                                  )}
                                </p>
                                {originalOrderObj.payment_method !== "wallet" &&
                                  settings.enable_wallet &&
                                  settings.enable_wallet_staff &&
                                  originalOrderObj.user &&
                                  this.canRefundOrder(
                                    originalOrderObj,
                                    true
                                  ) && (
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto 80px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={(e) =>
                                          this.setState({
                                            refundWallet: e.target.checked,
                                          })
                                        }
                                      >
                                        {t("Refund to wallet")}
                                      </Checkbox>
                                    </div>
                                  )}
                              </div>
                            }
                            onConfirm={() => {
                              this.props.cancelOrder(
                                "",
                                true,
                                false,
                                !this.canRefundOrder(originalOrderObj, true)
                                  ? true
                                  : this.state.refundWallet
                              );
                            }}
                            onCancel={() => {}}
                            okText={t("Yes")}
                            cancelText={t("No")}
                          >
                            <Button loading={this.props.orders.refundLoading}>
                              {t("Refund customer")}
                            </Button>
                          </Popconfirm>
                        ) : (
                          <Tooltip
                            title={t(
                              "You do not have the permission to refund. Please visit the Permissions section"
                            )}
                          >
                            <Button disabled={true}>
                              {t("Refund customer")}
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    )}
                </>
              )}
            </TabPane>
          ) : null}
          <TabPane tab={t("Invoice")} key="2">
            <Invoice
              selectedOrder={selectedOrder}
              order={originalOrderObj}
              print={() => this.invoicePrint()}
              dispatchOrder={() => this.invoiceDispatch()}
              showDispatchButton={this.showDispatchTab()}
              pending={this.props.pending}
              closeOrder={this.props.onClose}
            />
          </TabPane>
          {!selectedOrder.promise && (
            <TabPane tab={t("Payments")} key="payments">
              <OrderPayments order={originalOrderObj} />
            </TabPane>
          )}

          {orderObj.is_delivery &&
          !selectedOrder.promise &&
          orderObj.area.country_name !== "International" ? (
            <TabPane tab={t("Location")} key="3">
              <CustomerInfo
                selectedOrder={orderObj}
                setNewLatLng={(d) => {
                  if (!d) {
                    this.setState({ newLat: "", newLng: "" });
                  } else {
                    this.setState({ newLat: d.lat, newLng: d.lng });
                  }
                }}
              />
            </TabPane>
          ) : null}

          {this.showDispatchTab() &&
            orderObj.area.country_name !== "International" &&
            (!originalOrderObj.shipping?.is_dispatched ||
              originalOrderObj.shipping?.dispatcher != "dhl") && (
              <TabPane tab={t("Assign Driver")} key="4">
                {originalOrderObj.shipping &&
                !originalOrderObj.shipping.is_dispatched ? (
                  <>
                    {originalOrderObj.payment_complete && (
                      <Talabat selectedOrder={originalOrderObj} />
                    )}
                    <div
                      style={{
                        background: "#0099cc45",
                        border: "2px solid #0099cc",
                        borderRadius: 5,
                      }}
                      className="p-4"
                    >
                      <p className="mb-2">{t("Customer's Address")}:</p>
                      <p>
                        {orderObj.area.country_name == "Qatar"
                          ? t("District")
                          : t("Area")}
                        :{" "}
                        {config.language == "arabic"
                          ? orderObj.area.ar_name
                          : orderObj.area.name}
                        {orderObj.is_delivery && (
                          <>
                            {orderObj.address.block &&
                            orderObj.area.country_name != "Qatar" ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Block")}:{" "}
                                {orderObj.address.block}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.zone &&
                            orderObj.area.country_name == "Qatar" ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Zone")}:{" "}
                                {orderObj.address.zone}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.street ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Street")}:{" "}
                                {orderObj.address.street}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.building ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Building")}:{" "}
                                {orderObj.address.building}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.avenue ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Avenue")}:{" "}
                                {orderObj.address.avenue}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.floor ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Floor")}:{" "}
                                {orderObj.address.floor}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.apartment ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Apartment")}:{" "}
                                {orderObj.address.apartment}
                              </>
                            ) : (
                              ""
                            )}
                            {orderObj.address.additional ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;{t("Additional")}:{" "}
                                {orderObj.address.additional}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </p>
                      <p className="mt-3">{t("Select a delivery provider")}</p>
                      <Radio.Group
                        onChange={this.onChangeDispatchRadio}
                        value={this.state.dispatchRadioValue}
                      >
                        {this.props.settings.enable_ogo && (
                          <Radio.Button value="OGO Delivery">Ogo</Radio.Button>
                        )}
                        {this.props.settings.enable_armada &&
                          permissions &&
                          permissions.branches.length > 0 && (
                            <Radio.Button value="Armada">Armada</Radio.Button>
                          )}

                        {this.props.settings.enable_flick &&
                          permissions &&
                          permissions.flick_branches.length > 0 && (
                            <Radio.Button value="Flick">Flick</Radio.Button>
                          )}

                        {this.props.settings.enable_quick_delivery &&
                          permissions &&
                          permissions.quick_delivery_branches.length > 0 && (
                            <Radio.Button value="Quick Delivery">
                              Quick Delivery
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_primex &&
                          permissions &&
                          permissions.primex_branches.length > 0 && (
                            <Radio.Button value="primex">Primex</Radio.Button>
                          )}

                        {this.props.settings.enable_streetline &&
                          permissions &&
                          permissions.streetline_branches.length > 0 && (
                            <Radio.Button value="streetline">
                              Streetline
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_fodo &&
                          permissions &&
                          permissions.fodo_branches.length > 0 && (
                            <Radio.Button value="fodo">Fodo</Radio.Button>
                          )}

                        {this.props.settings.enable_delivery_runners &&
                          permissions &&
                          permissions.delivery_runners_branches.length > 0 && (
                            <Radio.Button value="Delivery Runners">
                              Delivery Runners
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_wiyak &&
                          permissions &&
                          permissions.wiyak_branches.length > 0 && (
                            <Radio.Button value="wiyak">Wiyak</Radio.Button>
                          )}
                        {this.props.settings.enable_wiyak_custom &&
                          permissions &&
                          permissions.wiyak_branches.length > 0 && (
                            <Radio.Button value="wiyak_custom">
                              Wiyak
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_parcel &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="Parcel">Parcel</Radio.Button>
                          )}

                        {this.props.settings.enable_verdi &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="verdi">Verdi</Radio.Button>
                          )}

                        {this.props.settings.enable_vdeliver &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="vdeliver">
                              VDeliver
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_urwagon &&
                          (this.props.settings.enable_urwagon_cash ||
                            orderObj.payment_method != "cash") &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="urwagon">UrWagon</Radio.Button>
                          )}

                        {this.props.settings.enable_tam &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="tam">Tam</Radio.Button>
                          )}

                        {this.props.settings.enable_deliverix &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="deliverix">
                              Deliverix
                            </Radio.Button>
                          )}
                        {this.props.settings.enable_wasel &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="wasel">Wasel</Radio.Button>
                          )}

                        {this.props.settings.enable_logestechs &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="logestechs">
                              LogesTechs
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_kangaroo &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="kangaroo">
                              Quick Kangaroo
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_tookan &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="tookan">Tookan</Radio.Button>
                          )}

                        {this.props.settings.enable_clicks &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="clicks">
                              UseClicks
                            </Radio.Button>
                          )}

                        {this.props.settings.enable_mashkor &&
                          permissions &&
                          permissions.mashkor_branches.length > 0 && (
                            <Radio.Button value="mashkor">Mashkor</Radio.Button>
                          )}

                        {this.props.settings.enable_lyve &&
                          permissions &&
                          permissions.lyve_branches.length > 0 && (
                            <Radio.Button value="lyve">Lyve</Radio.Button>
                          )}

                        {this.props.settings.enable_snoonu &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="Snoonu">Snoonu</Radio.Button>
                          )}

                        {this.props.settings.enable_frontlines && (
                          <Radio.Button value="Frontlines Express">
                            Frontlines
                          </Radio.Button>
                        )}
                        {this.props.settings.enable_mirsal && (
                          <Radio.Button value="Mirsal">Mirsal</Radio.Button>
                        )}
                        {this.props.settings.enable_dhl_v2 &&
                          !this.props.settings.has_custom_shipping_rates &&
                          permissions &&
                          permissions.all_permitted_branches.length > 0 && (
                            <Radio.Button value="_dhl">DHL</Radio.Button>
                          )}
                        <Radio.Button value="Own">{t("Own")}</Radio.Button>
                      </Radio.Group>
                      {this.state.dispatchRadioValue === "Own" &&
                      settings.enable_driver &&
                      permissions.can_view_driver &&
                      permissions.can_select_driver ? (
                        <OrderOwnSelect
                          onChangeDriverSelect={this.onChangeDriverSelect}
                        />
                      ) : (
                        ""
                      )}

                      {this.state.shouldTopupWallet && (
                        <div className="my-4">
                          <Button
                            type="primary"
                            style={{
                              background: "#0099cc",
                              borderColor: "#0099cc",
                            }}
                            onClick={() =>
                              this.props.history.push("/manage/wallet")
                            }
                          >
                            {t("Top-up wallet")}
                          </Button>
                        </div>
                      )}
                      {!!this.state.logestechsCorrectionData &&
                      this.state.dispatchRadioValue === "logestechs" ? (
                        <div>
                          <p className="mt-3" style={{ fontWeight: 600 }}>
                            {t("Please correct address information")}
                          </p>
                          {!!this.state.logestechsCorrectionData.sender && (
                            <div className="w-100">
                              <div className="mb-2">
                                <span
                                  className={
                                    config.direction == "ltr" ? "mr-4" : "ml-4"
                                  }
                                >
                                  {t("Sender area")}:{" "}
                                </span>
                              </div>
                              <div className="mb-2">
                                <Select
                                  showSearch
                                  style={{ width: 300 }}
                                  placeholder={t("Select an area")}
                                  optionFilterProp="children"
                                  value={
                                    this.state.logestechsSelectedSender ||
                                    undefined
                                  }
                                  onChange={(value) => {
                                    this.setState({
                                      logestechsSelectedSender: value,
                                    });
                                  }}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.logestechsCorrectionData.sender.map(
                                    (area) => (
                                      <Option
                                        value={area["name"]}
                                        key={Math.random() * 9999999}
                                        style={{
                                          textAlign:
                                            config.direction === "rtl"
                                              ? "right"
                                              : "left",
                                        }}
                                      >
                                        {config.language === "arabic"
                                          ? area["ar_name"] || area["name"]
                                          : area["name"]}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </div>
                            </div>
                          )}
                          {!!this.state.logestechsCorrectionData.receiver && (
                            <div className="w-100">
                              <div className="my-2">
                                <span
                                  className={
                                    config.direction == "ltr" ? "mr-4" : "ml-4"
                                  }
                                >
                                  {t("Receiver area")}:{" "}
                                </span>
                              </div>
                              <div className="mb-2">
                                <Select
                                  showSearch
                                  style={{ width: 300 }}
                                  placeholder={t("Select an area")}
                                  optionFilterProp="children"
                                  value={
                                    this.state.logestechsSelectedReceiver ||
                                    undefined
                                  }
                                  onChange={(value) => {
                                    this.setState({
                                      logestechsSelectedReceiver: value,
                                    });
                                  }}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.logestechsCorrectionData.receiver.map(
                                    (area) => (
                                      <Option
                                        value={area["name"]}
                                        key={Math.random() * 9999999}
                                        style={{
                                          textAlign:
                                            config.direction === "rtl"
                                              ? "right"
                                              : "left",
                                        }}
                                      >
                                        {config.language === "arabic"
                                          ? area["ar_name"] || area["name"]
                                          : area["name"]}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {!!this.state.fodoAreas &&
                        this.state.dispatchRadioValue === "fodo" && (
                          <div>
                            <p className="mt-3" style={{ fontWeight: 600 }}>
                              {t("Please correct address information")}
                            </p>
                            <div className="row align-items-center w-100">
                              <div className="col">
                                <span
                                  className={
                                    config.direction == "ltr" ? "mr-4" : "ml-4"
                                  }
                                >
                                  {t("Area")}:{" "}
                                </span>
                              </div>
                              <div className="col">
                                <Select
                                  showSearch
                                  style={{ width: 300 }}
                                  placeholder={t("Select an area")}
                                  optionFilterProp="children"
                                  value={
                                    this.state.selectedFodoArea || undefined
                                  }
                                  onChange={(value) => {
                                    this.setState({
                                      selectedFodoArea: value,
                                    });
                                  }}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.fodoAreas.map((area) => (
                                    <Option value={area}>{area}</Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        )}

                      {!!this.state.deliveryRunnersAreas &&
                        this.state.dispatchRadioValue ===
                          "Delivery Runners" && (
                          <div>
                            <p className="mt-3" style={{ fontWeight: 600 }}>
                              {t("Please correct address information")}
                            </p>
                            <div className="row align-items-center w-100">
                              <div className="col">
                                <span
                                  className={
                                    config.direction == "ltr" ? "mr-4" : "ml-4"
                                  }
                                >
                                  {t("Block/Area")}:{" "}
                                </span>
                              </div>
                              <div className="col">
                                <Select
                                  showSearch
                                  style={{ width: 300 }}
                                  placeholder={t("Select a block/area")}
                                  optionFilterProp="children"
                                  value={
                                    this.state.selectedDeliveryRunnersArea ||
                                    undefined
                                  }
                                  onChange={(value) => {
                                    this.setState({
                                      selectedDeliveryRunnersArea: value,
                                    });
                                  }}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.deliveryRunnersAreas.map(
                                    (area) => (
                                      <Option value={area.id}>
                                        {area.name}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </div>
                            </div>
                          </div>
                        )}

                      {(this.state.urwagonAreas || this.state.urwagonBlocks) &&
                        this.state.dispatchRadioValue == "urwagon" && (
                          <div>
                            <p className="mt-3" style={{ fontWeight: 600 }}>
                              {t("Please correct address information")}
                            </p>

                            {!!this.state.urwagonAreas && (
                              <div className="row align-items-center w-100">
                                <div className="col">
                                  <span
                                    className={
                                      config.direction == "ltr"
                                        ? "mr-4"
                                        : "ml-4"
                                    }
                                  >
                                    {t("Area")}:{" "}
                                  </span>
                                </div>
                                <div className="col">
                                  <Select
                                    showSearch
                                    style={{ width: 300 }}
                                    placeholder={t("Select an area")}
                                    optionFilterProp="children"
                                    value={
                                      this.state.selectedUrwagonArea ||
                                      undefined
                                    }
                                    onChange={(value) => {
                                      this.setState({
                                        selectedUrwagonLatLng: null,
                                        selectedUrwagonArea: value,
                                      });
                                    }}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {Object.keys(this.state.urwagonAreas).map(
                                      (area) => (
                                        <Option value={area}>{area}</Option>
                                      )
                                    )}
                                  </Select>
                                </div>
                              </div>
                            )}

                            <div className="mt-3">
                              <div className="row align-items-center w-100">
                                <div className="col">
                                  <span
                                    className={
                                      config.direction == "ltr"
                                        ? "mr-4"
                                        : "ml-4"
                                    }
                                  >
                                    {t("Block")}:{" "}
                                  </span>
                                </div>
                                <div className="col">
                                  <Select
                                    showSearch
                                    style={{ width: 300 }}
                                    placeholder={t("Select a block")}
                                    optionFilterProp="children"
                                    onChange={(value) =>
                                      this.setState({
                                        selectedUrwagonBlock: value,
                                        selectedUrwagonLatLng:
                                          !!this.state.urwagonAreas &&
                                          !!this.state.selectedUrwagonArea
                                            ? this.state.urwagonAreas[
                                                this.state.selectedUrwagonArea
                                              ][value]
                                            : !this.state.urwagonAreas &&
                                              !!this.state.urwagonBlocks
                                            ? this.state.urwagonBlocks[value]
                                            : null,
                                      })
                                    }
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={
                                      !!this.state.urwagonAreas &&
                                      !this.state.selectedUrwagonArea
                                    }
                                    value={
                                      this.state.selectedUrwagonBlock ||
                                      undefined
                                    }
                                  >
                                    {!!this.state.urwagonAreas &&
                                    !!this.state.selectedUrwagonArea ? (
                                      Object.keys(
                                        this.state.urwagonAreas[
                                          this.state.selectedUrwagonArea
                                        ]
                                      ).map((block) => (
                                        <Option value={block}>{block}</Option>
                                      ))
                                    ) : !this.state.urwagonAreas &&
                                      !!this.state.urwagonBlocks ? (
                                      Object.keys(this.state.urwagonBlocks).map(
                                        (block) => (
                                          <Option value={block}>{block}</Option>
                                        )
                                      )
                                    ) : (
                                      <Option value={""}>{"-----"}</Option>
                                    )}
                                  </Select>
                                </div>
                              </div>
                            </div>

                            {!!this.state.urwagonAreas && (
                              <div className="mt-3">
                                <Checkbox
                                  onChange={(e) =>
                                    this.setState({
                                      renameAreaUrwagon: e.target.checked,
                                    })
                                  }
                                >
                                  {t(
                                    "Rename existing area to match your selected area?"
                                  )}
                                </Checkbox>
                              </div>
                            )}
                          </div>
                        )}
                      {this.state.dispatchRadioValue == "Armada" &&
                        permissions &&
                        permissions.armada_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.armada_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "Flick" &&
                        permissions &&
                        permissions.flick_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.flick_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "Quick Delivery" &&
                        permissions &&
                        permissions.quick_delivery_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.quick_delivery_branches.map(
                                (branch) => (
                                  <Radio.Button value={branch.id}>
                                    {config.language == "arabic"
                                      ? branch.ar_name
                                      : branch.name}
                                  </Radio.Button>
                                )
                              )}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "primex" &&
                        permissions &&
                        permissions.primex_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.primex_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "streetline" &&
                        permissions &&
                        permissions.streetline_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.streetline_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "Delivery Runners" &&
                        permissions &&
                        permissions.delivery_runners_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.delivery_runners_branches.map(
                                (branch) => (
                                  <Radio.Button value={branch.id}>
                                    {config.language == "arabic"
                                      ? branch.ar_name
                                      : branch.name}
                                  </Radio.Button>
                                )
                              )}
                            </Radio.Group>
                          </>
                        )}

                      {(this.state.dispatchRadioValue === "wiyak" ||
                        this.state.dispatchRadioValue === "wiyak_custom") &&
                        permissions &&
                        permissions.wiyak_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.wiyak_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {(!!this.state.wiyakFee ||
                        this.state.wiyakFeeLoading) && (
                        <p className="mt-3">
                          {t("Delivery Fee")}:{" "}
                          {this.state.wiyakFeeLoading ? (
                            <Spin indicator={antIcon} className="mx-2" />
                          ) : (
                            <>
                              {this.state.wiyakFee} {t(settings.currency)}
                            </>
                          )}
                        </p>
                      )}

                      {(!!this.state.dhlDeliveryRate ||
                        this.state.dhlLoading) && (
                        <>
                          <p className="mt-3">
                            {t("Delivery Fee")}:{" "}
                            {this.state.dhlLoading ? (
                              <Spin indicator={antIcon} className="mx-2" />
                            ) : (
                              <span
                                className="bold"
                                style={{
                                  color: this.state.shouldTopupWallet && "red",
                                }}
                              >
                                {this.state.dhlDeliveryRate || "-1"}{" "}
                                {t(settings.currency)}
                              </span>
                            )}
                          </p>
                          <p>Notes:</p>
                          <div>
                            * The charge will be deducted from your wallet
                          </div>
                          <div>
                            * Orders will be processed everyday from 3 PM to 5
                            PM
                          </div>
                          <div>
                            * DHL Customer Service Contact: 1808345 / 22246500
                          </div>
                        </>
                      )}
                      {this.state.dispatchRadioValue == "fodo" &&
                        permissions &&
                        permissions.fodo_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.fodo_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "mashkor" &&
                        permissions &&
                        permissions.mashkor_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.mashkor_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "lyve" &&
                        permissions &&
                        permissions.lyve_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.lyve_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {(this.state.dispatchRadioValue == "Snoonu" ||
                        this.state.dispatchRadioValue == "urwagon" ||
                        this.state.dispatchRadioValue == "tam" ||
                        this.state.dispatchRadioValue == "deliverix" ||
                        this.state.dispatchRadioValue == "wasel" ||
                        this.state.dispatchRadioValue == "logestechs" ||
                        this.state.dispatchRadioValue == "kangaroo" ||
                        this.state.dispatchRadioValue == "tookan" ||
                        this.state.dispatchRadioValue == "clicks" ||
                        this.state.dispatchRadioValue == "verdi" ||
                        this.state.dispatchRadioValue == "Parcel" ||
                        this.state.dispatchRadioValue == "vdeliver") &&
                        permissions &&
                        permissions.all_permitted_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.all_permitted_branches.map(
                                (branch) => (
                                  <Radio.Button value={branch.id}>
                                    {config.language == "arabic"
                                      ? branch.ar_name
                                      : branch.name}
                                  </Radio.Button>
                                )
                              )}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "OGO Delivery" &&
                        permissions &&
                        permissions.ogo_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.ogo_branches.map((branch) => (
                                <Radio.Button value={branch.id}>
                                  {config.language == "arabic"
                                    ? branch.ar_name
                                    : branch.name}
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </>
                        )}

                      {this.state.dispatchRadioValue == "Mirsal" &&
                        permissions &&
                        permissions.all_permitted_branches.length > 1 && (
                          <>
                            <p className="mt-3">{t("Select a branch")}</p>
                            <Radio.Group
                              onChange={this.onChangeArmadaBranch}
                              value={this.state.branch_id}
                            >
                              {permissions.all_permitted_branches.map(
                                (branch) => (
                                  <Radio.Button value={branch.id}>
                                    {config.language == "arabic"
                                      ? branch.ar_name
                                      : branch.name}
                                  </Radio.Button>
                                )
                              )}
                            </Radio.Group>
                          </>
                        )}
                      {this.state.mirsalRetry &&
                        this.state.dispatchRadioValue == "Mirsal" && (
                          <div>
                            <p className="mt-3" style={{ fontWeight: 600 }}>
                              {t("Please correct address information")}
                            </p>

                            <div className="row align-items-center w-75">
                              <div className="col">
                                <span
                                  className={
                                    config.direction == "ltr" ? "mr-4" : "ml-4"
                                  }
                                >
                                  {t("Area")}:{" "}
                                </span>
                              </div>
                              <div className="col">
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder={t("Select an area")}
                                  optionFilterProp="children"
                                  onChange={(value) =>
                                    this.setState({ mirsalRetryArea: value })
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.mirsalRetry.data.map((area) => (
                                    <Option value={area.area}>
                                      {area.area}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            {this.state.mirsalRetryArea && (
                              <div className="mt-3">
                                <div className="row align-items-center w-75">
                                  <div className="col">
                                    <span
                                      className={
                                        config.direction == "ltr"
                                          ? "mr-4"
                                          : "ml-4"
                                      }
                                    >
                                      {t("Block")}:{" "}
                                    </span>
                                  </div>
                                  <div className="col">
                                    <Select
                                      showSearch
                                      style={{ width: 200 }}
                                      placeholder={t("Select a block")}
                                      optionFilterProp="children"
                                      onChange={(value) =>
                                        this.setState({
                                          mirsalRetryBlock: value,
                                        })
                                      }
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.mirsalRetry.data
                                        .find(
                                          (entry) =>
                                            entry.area ==
                                            this.state.mirsalRetryArea
                                        )
                                        .blocks.split(",")
                                        .map((block) => (
                                          <Option value={block}>{block}</Option>
                                        ))}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      {this.state.armadaRetry &&
                        this.state.dispatchRadioValue == "Armada" && (
                          <>
                            <p className="mt-3">
                              {t("Please correct the area")}
                            </p>
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder={t("Select an area")}
                              optionFilterProp="children"
                              onChange={(value) =>
                                this.setState({ armadaRetryArea: value })
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.state.armadaRetry.data.map((area) => (
                                <Option value={area}>{area}</Option>
                              ))}
                            </Select>
                          </>
                        )}
                      {this.state.retryData &&
                        this.state.dispatchRadioValue == "OGO Delivery" && (
                          <div>
                            <p className="mt-3" style={{ fontWeight: 600 }}>
                              {t("Please correct address information")}
                            </p>

                            <div className="row align-items-center w-75">
                              <div className="col">
                                <span
                                  className={
                                    config.direction == "ltr" ? "mr-4" : "ml-4"
                                  }
                                >
                                  {t("Area")}:{" "}
                                </span>
                              </div>
                              <div className="col">
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder={t("Select an area")}
                                  optionFilterProp="children"
                                  onChange={(value) =>
                                    this.setState({ retryAreaId: value })
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.retryData.data.map((area) => (
                                    <Option value={area.id}>{area.area}</Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            {this.state.retryAreaId && (
                              <div className="mt-3">
                                <div className="row align-items-center w-75">
                                  <div className="col">
                                    <span
                                      className={
                                        config.direction == "ltr"
                                          ? "mr-4"
                                          : "ml-4"
                                      }
                                    >
                                      {t("Block")}:{" "}
                                    </span>
                                  </div>
                                  <div className="col">
                                    <Select
                                      showSearch
                                      style={{ width: 200 }}
                                      placeholder={t("Select a block")}
                                      optionFilterProp="children"
                                      onChange={(value) =>
                                        this.setState({
                                          retryBlockNumber: value,
                                        })
                                      }
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.retryData.data
                                        .find(
                                          (entry) =>
                                            entry.id == this.state.retryAreaId
                                        )
                                        .blocks.split(",")
                                        .map((block) => (
                                          <Option value={block}>{block}</Option>
                                        ))}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                      {this.state.dispatchRadioValue == "mashkor" && (
                        <p className="mt-3 mb-0">
                          {t("Note: the driver will come on demand")}
                        </p>
                      )}
                    </div>
                  </>
                ) : originalOrderObj.shipping.dispatcher === "talabat" ? (
                  <Talabat selectedOrder={originalOrderObj} />
                ) : (
                  <div>
                    <p>
                      {t(
                        "Order has been dispatched successfully, and below are the details"
                      )}
                      :
                      {originalOrderObj.shipping.dispatcher === "wiyak" ? (
                        <div
                          style={{
                            color: "gray",
                          }}
                        >
                          {t("Support")} <PhoneOutlined />{" "}
                          <span style={{ direction: "ltr" }}>22211221</span>
                        </div>
                      ) : (
                        <br />
                      )}
                      <br />
                      {t("DispatchProvider")}:{" "}
                      <b>
                        {t(
                          originalOrderObj.shipping.dispatcher ===
                            "wiyak_custom"
                            ? "Wiyak"
                            : originalOrderObj.shipping.dispatcher === "_dhl"
                            ? "DHL"
                            : originalOrderObj.shipping.dispatcher
                        )}
                      </b>
                      <br />
                      {t("DispatchTrackingReference")}:{" "}
                      <b>{orderObj.shipping.dispatch_reference}</b>
                      <br />
                      <div>
                        {driver_info !== undefined &&
                          permissions.can_view_driver && (
                            <>
                              {t("Driver")}:{" "}
                              <b>
                                {driver_info.first_name +
                                  " " +
                                  driver_info.last_name +
                                  " "}
                              </b>
                              <span> ({driver_info.phone_number})</span>
                            </>
                          )}
                        {driver_info !== undefined &&
                          settings.enable_driver &&
                          permissions.can_view_driver &&
                          permissions.can_select_driver && (
                            <>
                              <Button
                                className={"ml-2 mt-1"}
                                type="link"
                                disabled={orderObj.status === "Complete"}
                                onClick={() =>
                                  this.setState({
                                    changeDriverOption:
                                      !this.state.changeDriverOption,
                                    driver: null,
                                  })
                                }
                                shape="circle"
                                icon={<EditOutlined />}
                                size={"small"}
                              >
                                {t("Edit")}
                              </Button>
                              {this.state.changeDriverOption ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <OrderOwnSelect
                                    selectedDriver={driver_info}
                                    onChangeDriverSelect={
                                      this.onChangeDriverSelect
                                    }
                                  />
                                  <Button
                                    type={"primary"}
                                    disabled={this.state.driver === null}
                                    onClick={() => {
                                      this.props.assignOrder(
                                        this.state.driver,
                                        orderObj.id
                                      );
                                      this.setState({
                                        changeDriverOption:
                                          !this.state.changeDriverOption,
                                        driver: null,
                                      });
                                    }}
                                  >
                                    {t("Change Driver")}
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                      </div>
                      {!!originalOrderObj.shipping.wallet_charge && (
                        <span>
                          <br />
                          {t("Delivery fee")}:{" "}
                          <b>
                            {originalOrderObj.shipping.wallet_charge}{" "}
                            {t(settings.currency)}
                          </b>
                        </span>
                      )}
                      {originalOrderObj.shipping.dispatcher === "_dhl" && (
                        <span>
                          <br />
                          Commercial Invoice:{" "}
                          <a
                            href={`https://${settings.domain}/manage/invoice/international/${orderObj.id}/${orderObj.shipping.dispatch_reference}/`}
                            target="_blank"
                          >
                            here
                          </a>
                          <br />
                          AWB file:{" "}
                          {!!originalOrderObj.shipping.dhl_info ? (
                            <a
                              href={originalOrderObj.shipping.dhl_info.pdf_file}
                              target="_blank"
                            >
                              here
                            </a>
                          ) : (
                            <span>Will be available here after processing</span>
                          )}
                          <p className="mt-3 mb-2">Notes:</p>
                          <div>
                            * Orders will be processed everyday from 3 PM to 5
                            PM
                          </div>
                          <div>
                            * DHL Customer Service Contact: 1808345 / 22246500
                          </div>
                        </span>
                      )}
                      {originalOrderObj.shipping.dispatcher === "wasel" && (
                        <span>
                          <br />
                          {t("Delivery status")}:{" "}
                          <b>{originalOrderObj.wasel_status || "-"}</b>{" "}
                          <Button
                            type="primary"
                            onClick={() => this.checkWaselOrderStatus()}
                            className="mx-2"
                            loading={this.state.shippingStatusCheckLoading}
                          >
                            {t("Check status")}
                          </Button>
                        </span>
                      )}
                      {(originalOrderObj.shipping.dispatcher === "wiyak" ||
                        originalOrderObj.shipping.dispatcher ===
                          "wiyak_custom") && (
                        <span>
                          <br />
                          {t("Delivery status")}:{" "}
                          <b>{originalOrderObj.wiyak_status || "-"}</b>{" "}
                          <Button
                            type="primary"
                            onClick={() => this.checkWiyakOrderStatus()}
                            className="mx-2"
                            loading={this.state.shippingStatusCheckLoading}
                          >
                            {t("Check status")}
                          </Button>
                        </span>
                      )}
                      {(originalOrderObj.shipping.dispatcher === "wiyak" ||
                        originalOrderObj.shipping.dispatcher ===
                          "wiyak_custom") &&
                        (!!originalOrderObj.shipping.driver_name ||
                          !!originalOrderObj.shipping.driver_phone) && (
                          <p>
                            {t("Driver")}:{" "}
                            <span>
                              {originalOrderObj.shipping.driver_name} (
                              {originalOrderObj.shipping.driver_phone})
                            </span>
                          </p>
                        )}
                      {originalOrderObj.shipping.tracking_link && (
                        <>
                          <br />
                          {t("Tracking link")}:{" "}
                          <a
                            target="_blank"
                            href={originalOrderObj.shipping.tracking_link}
                            style={{ color: "blue" }}
                          >
                            {t("Click here")}
                          </a>
                        </>
                      )}
                      {originalOrderObj.shipping.estimated_duration && (
                        <>
                          <br />
                          {t("Estimated duration")}:{" "}
                          <b>
                            {parseInt(
                              originalOrderObj.shipping.estimated_duration / 60
                            )}{" "}
                            {t("minutes")}
                          </b>
                        </>
                      )}
                      {originalOrderObj.shipping.qr_code && (
                        <>
                          <br />
                          {t("QR Code")}: <br />
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={originalOrderObj.shipping.qr_code}
                              width={200}
                              height={200}
                            />
                          </div>
                        </>
                      )}
                    </p>
                    {originalOrderObj.shipping.dispatcher !== "_dhl" && (
                      <Popconfirm
                        title={
                          !["own", "wiyak"].includes(
                            originalOrderObj.shipping.dispatcher.toLowerCase()
                          )
                            ? t(
                                "Are you sure you want to undo the dispatch? This will NOT cancel it from ****'s dashboard"
                              ).replace(
                                "****",
                                originalOrderObj.shipping.dispatcher
                              )
                            : t("Are you sure you want to undo the dispatch?")
                        }
                        onConfirm={() => {
                          if (permissions.can_undispatch_orders)
                            this.undispatch();
                        }}
                        okText={t("Yes")}
                        cancelText={t("No")}
                        disabled={!permissions.can_undispatch_orders}
                      >
                        {permissions.can_undispatch_orders ? (
                          <Button
                            type="primary"
                            loading={this.state.undispatchLoading}
                          >
                            {t("Undo dispatch")}
                          </Button>
                        ) : (
                          <Tooltip
                            title={t(
                              "You do not have the permission to undo dispatch. Please visit the Permissions section"
                            )}
                          >
                            <Button disabled={true}>
                              {t("Undo dispatch")}
                            </Button>
                          </Tooltip>
                        )}
                      </Popconfirm>
                    )}
                  </div>
                )}
                <p style={{ marginTop: 15, marginBottom: 15 }}>
                  {t("Send Delivery Details")}
                </p>
                <Dropdown overlay={WhatsappMenu}>
                  <Button
                    icon={
                      <WhatsAppOutlined
                        style={{ fontSize: 19, color: "#25D366" }}
                      />
                    }
                  >
                    {t("Whatsapp")}
                  </Button>
                </Dropdown>
              </TabPane>
            )}
          {this.showDispatchTab() &&
            orderObj.area.country_name !== "International" &&
            !this.props.settings.has_custom_shipping_rates &&
            this.props.settings.enable_dhl && (
              <TabPane tab={t("DHL")} key="dhl">
                <DHL selectedOrder={selectedOrder} />
              </TabPane>
            )}
          {this.showDispatchTab() &&
            !this.props.settings.has_custom_shipping_rates &&
            this.props.settings.enable_aramex &&
            this.isInternationalDelivery() && (
              <TabPane tab={t("Aramex")} key="aramex">
                <Aramex selectedOrder={selectedOrder} />
              </TabPane>
            )}
          {!selectedOrder.promise &&
            this.props.settings.enable_revel &&
            !this.props.fromPOS && (
              <TabPane tab={t("Revel")} key="revel">
                <Suspense fallback={<div></div>}>
                  <Revel selectedOrder={selectedOrder} />
                </Suspense>
              </TabPane>
            )}
          {!selectedOrder.promise &&
            this.props.settings.enable_homey &&
            (selectedOrder.obj.is_delivery ||
              this.props.inventoryIntegrations?.homey?.dispatch_pickup_orders ||
              selectedOrder.obj.is_pick_up) && (
              <TabPane tab={t("Homey")} key="homey">
                <Suspense fallback={<div></div>}>
                  <Homey selectedOrder={selectedOrder} />
                </Suspense>
              </TabPane>
            )}
          {!selectedOrder.promise &&
            this.props.settings.enable_foodics &&
            !this.props.fromPOS && (
              <TabPane tab={t("Foodics")} key="foodics">
                <Suspense fallback={<div></div>}>
                  <ExternalSourcePush
                    selectedOrder={selectedOrder}
                    externalSource={"Foodics"}
                    externalSourceKey={"foodics_id"}
                  />
                </Suspense>
              </TabPane>
            )}
          {!selectedOrder.promise &&
            this.props.settings.enable_matix &&
            !this.props.fromPOS && (
              <TabPane tab={t("Matix")} key="matix">
                <Suspense fallback={<div></div>}>
                  <ExternalSourcePush
                    selectedOrder={selectedOrder}
                    externalSource={"Matix"}
                    externalSourceKey={"pos_rocket_id"}
                  />
                </Suspense>
              </TabPane>
            )}
          {!selectedOrder.promise &&
          (this.props.is_staff ||
            (this.props.permissions &&
              this.props.permissions.can_edit_orders)) ? (
            <TabPane tab={t("Notes")} key="5">
              <p>
                {t("Add notes to order")} #{selectedOrder.key}
              </p>
              <TextArea
                rows={4}
                onChange={(e) => this.setState({ notes: e.target.value })}
                defaultValue={orderObj.notes}
              />

              <p className="mt-3">{t("Attachments (max. 1 file)")}</p>
              <div className="w-100">
                <Upload
                  multiple={false}
                  beforeUpload={(file) => {
                    this.setState({ fileList: [file], deleteLogo: false });
                    return false;
                  }}
                  fileList={this.state.fileList}
                  onChange={this.handleAttachmentChange}
                  onRemove={() => {
                    this.setState({ fileList: [] });
                  }}
                >
                  <Button
                    disabled={!!this.state.fileList?.length}
                    icon={<UploadOutlined />}
                  >
                    {t("Upload")}
                  </Button>
                </Upload>
              </div>
              <Button
                className="mt-3"
                type="primary"
                loading={this.state.notesSaveLoading}
                onClick={() => this.saveNotes()}
              >
                {t("Save")}
              </Button>
            </TabPane>
          ) : null}

          {!selectedOrder.promise && settings.enable_gifting_templates ? (
            <TabPane tab={t("Gifting cards")} key="gifting">
              <GiftingTemplates selectedOrder={orderObj} />
            </TabPane>
          ) : null}
        </Tabs>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.auth.permissions,
    settings: state.auth.settings,
    config: state.config,
    is_staff: state.auth.is_staff,
    orders: state.orders,
    drivers: state.driver.drivers.data,
    driversSuccessStatus: state.driver.drivers.status,
    inventoryIntegrations: state.integration.inventory,
    baseCountry: state.auth.settings.base_country,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateOrderNotesLocally: (orderID, notes, attachment) =>
      dispatch(updateOrderNotesLocally(orderID, notes, attachment)),
    updateCustomerLatLngLocally: (orderID, lat, lng) =>
      dispatch(updateCustomerLatLngLocally(orderID, lat, lng)),
    dispatchOrderLocally: (
      id,
      dispatcher,
      reference,
      tracking_link,
      estimatedDuration,
      QR,
      walletCharge,
      driver
    ) =>
      dispatch(
        dispatchOrderLocally(
          id,
          dispatcher,
          reference,
          tracking_link,
          estimatedDuration,
          QR,
          walletCharge,
          driver
        )
      ),
    undispatchOrder: (id) => dispatch(undispatchOrder(id)),
    fetchOrders: (t, trackingID) => dispatch(fetchOrders(t, trackingID)),
    getDrivers: () => dispatch(getDrivers()),
    assignOrder: (driverID, orderID) =>
      dispatch(assignOrdertoDriver(driverID, orderID)),
    fetchInventoryIntegrations: () => dispatch(fetchInventoryIntegrations()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(QuickActionsModal)));
