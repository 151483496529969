import {
  FETCH_STATIC_PAGES,
  TOGGLE_PAGE,
  DELETE_PAGE,
  CHANGE_PAGE_WINDOW_TYPE,
  CREATE_NEW_PAGE,
} from "../actions/actionTypes";

const initialState = {
  pages: [],
};

export default (state = initialState, { type, payload }) => {
  let newPages = [];
  let index;
  switch (type) {
    case FETCH_STATIC_PAGES:
      return { ...state, pages: payload || [] };

    case TOGGLE_PAGE:
      newPages = state.pages;
      index = newPages.findIndex((p) => p.id === payload.id);
      newPages[index].is_active = payload.status;
      return { ...state, pages: newPages };

    case DELETE_PAGE:
      newPages = state.pages.filter((p) => p.id !== payload.id);

      return { ...state, pages: newPages };

    case CHANGE_PAGE_WINDOW_TYPE:
      newPages = state.pages;
      index = newPages.findIndex((p) => p.id === payload.id);
      newPages[index].window_type = payload.type;
      return { ...state, pages: newPages };

    case CREATE_NEW_PAGE:
      newPages = state.pages;
      index = newPages.findIndex((p) => p.id == payload.id);
      if (index >= 0) {
        newPages[index] = payload;
      } else newPages = [payload, ...state.pages];
      return { ...state, pages: newPages };
    default:
      return state;
  }
};
