import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
//Ant
import { Select, Row, Col, Checkbox } from "antd";

const { Option } = Select;

//My Components

//Actions

export class index extends Component {
  state = {
    selectedDate: -1,
    selectedTime: -1,
    hideOldSlots: true,
  };
  setUpBookingSlots = () => {
    const bookingSlots = this.props.bookingSlots;
    let selectedDate = -1;
    let selectedTime = -1;
    if (this.state.hideOldSlots) {
      const today = new Date();
      this.dates = this.removeDups(
        bookingSlots
          .filter(
            (d) =>
              d.booking_date &&
              this.toDate(d.booking_date).setHours(0, 0, 0, 0) >=
                today.setHours(0, 0, 0, 0)
          )
          .map((date) => this.toDate(date.booking_date))
      );
    } else {
      this.dates = this.removeDups(
        bookingSlots.map((date) => this.toDate(date.booking_date))
      );
    }

    if (!this.props.ignoreInventory) {
      this.dates.forEach((date, i) => this.removeEmpty(date, i));
      for (let i = 0; i < this.dates.length; i++) {
        if (this.removeEmpty(this.dates[i], i)) i -= 1;
      }
    }
    if (!this.props.bookingSlot) {
      if (
        this.dates.length &&
        bookingSlots.find((slot) => slot.inventory !== 0)
      ) {
        this.props.SetBookingSlot(-1);
      } else {
        this.props.SetBookingSlot("Out");
      }
    }

    if (this.props.bookingSlot) {
      let date = bookingSlots.find((bs) => bs.id === this.props.bookingSlot);

      if (date) {
        selectedDate = this.dates.findIndex(
          (d) => d == this.toDate(date.booking_date)
        );
        selectedTime = this.props.bookingSlot;

        this.Times = bookingSlots.filter(
          (d) => d.booking_date == date.booking_date
        );
      }
    }
    this.setState({
      selectedDate: selectedDate,
      selectedTime: selectedTime,
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.ignoreInventory !== this.props.ignoreInventory) {
      this.setUpBookingSlots();
    }
  }
  componentDidMount() {
    this.setUpBookingSlots();
  }
  toDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");

    return new Date(year, month - 1, day);
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  removeDups = (names) => {
    let unique = {};
    names.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  };
  removeEmpty = (date, i) => {
    let mydate = this.formatDate(date);
    let Times = this.props.bookingSlots.filter(
      (date) => date.booking_date === mydate && date.inventory > 0
    );

    if (Times.length) return false;

    this.dates.splice(i, 1);

    return true;
  };

  handleDateChange = (date) => {
    const select = this.dateSelect.current;
    if (select) {
      select.blur();
    }
    if (date !== this.state.selectedDate) {
      let mydate = this.formatDate(this.dates[parseInt(date)]);

      this.Times = this.props.bookingSlots.filter(
        (date) => date.booking_date === mydate
      );
      const today = new Date();
      if (
        this.state.hideOldSlots &&
        this.toDate(mydate).setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
      ) {
        const currentTime = new Date().getTime();
        this.Times = this.Times.filter((date) => {
          if (date.all_day) return true;
          if (date.start) {
            const startTime = new Date(
              date.booking_date + " " + date.start
            ).getTime();

            return startTime >= currentTime;
          }
        });
      }
      this.props.SetBookingSlot(-1);
      this.setState({
        selectedDate: parseInt(date),
        selectedTime: -1,
      });
    }
  };
  handleTimeChange = (Time, id) => {
    const select = this.timeSelect.current;
    if (select) {
      select.blur();
    }
    if (Time !== this.state.selectedTime) {
      this.props.SetBookingSlot(Time);
      this.setState({
        selectedTime: parseInt(Time),
        error: false,
      });
    }
  };
  getTimeText = (time, t) => {
    if (time.all_day) return t("All Day");

    let date = `${moment(time.start, "HH:mm:ss").format("hh:mm a")} ${t(
      "to"
    )} ${moment(time.end, "HH:mm:ss").format("hh:mm a")}`;

    if (this.props.config.language === "arabic") {
      let mapObj = {
        pm: "مساء",
        am: "صباحا",
      };
      date = date.replace(/pm|am/gi, function (matched) {
        return mapObj[matched];
      });
    }
    return date;
  };
  Times = [];
  Dates = [];
  timeSelect = React.createRef();
  dateSelect = React.createRef();
  render() {
    const { t } = this.props;
    const { selectedTime, selectedDate } = this.state;
    if (!this.dates) return null;

    return (
      <div className="my-4  ">
        <p style={{ fontSize: 20 }} className=" mt-3 text-center">
          {t("Booking")}
        </p>
        <div className="mt-2 mb-4 mx-4">
          <p>
            <Checkbox
              checked={this.state.hideOldSlots}
              className="mx-3"
              onChange={(e) => {
                this.setState({ hideOldSlots: e.target.checked }, () => {
                  this.setUpBookingSlots();
                });
              }}
            />
            {`${t("Hide old slots")}`}{" "}
          </p>
        </div>

        <Row justify="center">
          <Col span={8}>
            <p>{t("Date")}</p>
          </Col>
          <Col span={12}>
            <Select
              ref={this.dateSelect}
              className="w-100"
              onChange={this.handleDateChange}
              placeholder={t("Select Date")}
              value={selectedDate !== -1 ? selectedDate : undefined}
            >
              {this.dates.map((date, index) => (
                <Option value={index}>
                  {moment(date).format("DD-MM-YYYY")}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-3" justify="center">
          <Col span={8}>
            <p>{t("Time")}</p>
          </Col>
          <Col span={12}>
            <Select
              ref={this.timeSelect}
              disabled={selectedDate === -1 && true}
              className="w-100"
              onChange={this.handleTimeChange}
              placeholder={t("Select Time")}
              value={
                !["Out", -1].includes(selectedTime) ? selectedTime : undefined
              }
            >
              {this.Times.map((time) => (
                <Option value={time.id}>{this.getTimeText(time, t)}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ config: state.config });

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(index));
