const trans = {
  Integrations: "اعدادات الدمج",
  Payments: "الدفع",
  "Add a payment gateway": "فعل بوابة دفع",
  "Frontlines settings": "اعدادات Frontlines",
  "OGO settings": "اعدادات OGO",
  "Mirsal settings": "اعدادات مرسال",
  "Bookeey settings": "اعدادات بوكي",
  "UPay settings": "اعدادات UPay",
  "MyFatoorah settings": "اعدادات MyFatoorah",
  "Tap settings": "اعدادات Tap",
  "Hesabe settings": "اعدادات حسابي",
  "Print QR Code": "اطبع كود QR",
  Messaging: "المراسلة",
  "SMS settings": "اعدادات الرسئل النصية",
  "Enable SMS": "تفعيل الرسائل النصية",
  "Whatsapp settings": "اعدادات واتساب",
  "Enable Whatsapp": "تفعيل الواتساب",
  Balance: "الرصيد",
  "Sender ID": "اسم المرسل",
  points: "نقطة",
  "For delivery": "للتوصيل",
  "For pickup": "للاستلام",
  "Import Cin7 products": "رفع منتجات سين 7",
  "Products are being imported": "يتم حاليا تحميل المنتجات",
  "Could not import products": "لم نتمكن من تحميل المنتجات",
  "Countries with offline payments (Cash/KNET on delivery)":
    "الدول التي تدعم الكاش والكي نت عند الاستلام",
  "Pay with card on delivery": "الدفع بالبطاقة عند الاستلام",
  "Offline payments": "الدفع الغير الكتروني",
  "Online payments": "الدفع الالكتروني",
  "Enabled for the following countries": "مفعل للدول التالية",
  "Enable Debit/Credit Card Transactions": "تفعيل معاملات دفع الاونلاين",
  "Cin7 Branch ID": "سين7 رمز الفرع",
  "Test mode": "الوضع التجريبي",
  "Enable this only when you're provided with test credentials from Benefit. Make sure to turn it off once you're done testing":
    "قم بالتفعيل عند تزويدك بمفاتيح بنفت التجريبية. عند الانتهاء من التجريب، قم بتعطيل الزر حتى يتمكن عملائك من استخدام البوابة",
  "Enable this only when you're provided with test credentials from Credimax. Make sure to turn it off once you're done testing":
    "قم بالتفعيل عند تزويدك بمفاتيح كريدي ماكس التجريبية. عند الانتهاء من التجريب، قم بتعطيل الزر حتى يتمكن عملائك من استخدام البوابة",
  "Enable this only when you're provided with test credentials from TPay. Make sure to turn it off once you're done testing":
    "قم بالتفعيل عند تزويدك بمفاتيح تي باي التجريبية. عند الانتهاء من التجريب، قم بتعطيل الزر حتى يتمكن عملائك من استخدام البوابة",
  "Currency Conversion": "تحويل العملات",
  "Enable currency conversion": "تفعيل تحويل العملات",
  "Enable rounding": "تفعيل التقريب",
  "Rounding rule": "تقريب الى",
  "Currency rates (updated daily)": "أسعار العملات (محدث يوميا)",
  "Last updated:": "آخر تحديث:",
  recommended: "مقترح",
  "Delivery Providers": "شركات التوصيل",
  "Manage delivery providers": "التحكم بإعدادات شركات التوصيل",
  "Add a delivery provider": "اضافة شركة توصيل",
  "Connect your store to your social channels like Instagram and Facebook":
    "اربط متجرك مع شبكات التسويق الاجتماعي مثل انستاغرام و فيسبوك",
  "Social Marketing": "ادوات التسويق الاجتماعي",
  Social: "ادوات التسويق",
  "New to Hesabe? Request a call from a Hesabe representative.":
    "جديد مع حسابي؟ اطلب مكالمة من احد مندوبي حسابي",
  "Contact me": "طلب مكالمة",
  Contacted: "تم التواصل",
  "You can request another call in 12 hours": "يمكنك اعادة الطلب بعد 12 ساعة",
  "Send message on": "ارسال رسالة عند",
  "Order received": "استلام الطلب",
  "Order prepared": "تحضير الطلب",
  "Order out for delivery": "توصيل الطلب",
  "Order canceled": "الغاء الطلب",
  "Order refunded": "ارجاع نقود الطلب",
  "Order completed": "اتمام الطلب",
  "Online order placed": "وضع طلب اونلاين",
  "Enable debit card payments": "تفعيل الدفع ببطاقة الإئتمان",
  "Enable credit card payments": "تفعيل الدفع ببطاقة الكردت (فيزا، ماستر كارد)",
  "POS order placed": "وضع طلب من نقطة البيع",
  "Enable cash for POS": "تفعيل الكاش عند نقطة البيع",
  "Enable online payment for POS": "تفعيل الدفع الالكتروني عند نقطة البيع",
  "Enable low balance notifications": "تفعيل اشعارات الرصيد المنخفض",
  "Once balance goes below": "عندما ينخفض الرصيد اقل من",
  "Email will be sent to": "سوف يتم ارسال الايميل الى",
  "Invalid email": "ايميل غير صحيح",
  "Enable custom payments for POS": "تفعيل خدمات الدفع المخصصه عند نقطة البيع",
  "Standard box size (CM)": "ابعاد صندوق الشحن (سم)",
  "Box maximum weight (KG)": "الوزن الاقصى للصندوق (كجم)",
  "Maximum weight": "الوزن الاقصى",
  Depth: "العمق",
  "You can start selling while Tap reviews your registration details but payouts will not be available until approved. It is recommended to wait until the KYC process is completed and the account is approved by Tap":
    "يمكنك بدء البيع من الآن ولكن لن تتمكن من تحصيل مدفوعاتك حتى يتم التحقق من بياناتك والموافقة من Tap. نقترح الانتظار حتى تصلك الموافقة قبل المباشرة بالبيع",

  "Awaiting approval": "جاري المراجعة",
  Verified: "موثق",
  "Your account is now approved!": "تم توثيق حسابك!",
  "Your account hasn't been approved yet. Check back again later":
    "لم تتم الموافقة حتى الآن. عاود التحقق لاحقا",
  "Show WhatsApp option": "عرض خيار WhatsApp",
  "Block customers from ordering temporarily if the cost of shipping is not provided.":
    "منع العملاء من الطلب مؤقتا اذا لم يتم تقديم تكلفة الشحن",
  "If disabled the default rate for the selected area will be incurred.":
    "اذا تم تعطيل هذا الخيار سيتم احتساب الرسوم الافتراضية للمنطقة المحددة",
  "Cost calculation": "حساب التكلفة",
  "Please put the following URLs in your Sadad's API settings":
    "الرجاء وضع الروابط التالية في اعدادات واجهة برمجة التطبيقات",
  "Payment Success Return URL": "رابط نجاح عملية الدفع",
  "Payment Failed Return URL": "رابط فشل عملية الدفع",
  "Copied successfully": "تم النسخ بنجاح",
  Currencies: "العملات",
  KWD: "دينار كويتي",
  BHD: "دينار بحريني",
  USD: "دولار امريكي",
  SAR: "ريال سعودي",
  QAR: "ريال قطري",
  AED: "درهم اماراتي",
  EUR: "يورو",
  OMR: "ريال عماني",
  "Should customers pay for customs value on arrival?":
    "هل يجب على العملاء دفع قيمة الجمارك عند الوصول؟",
};

export default trans;
