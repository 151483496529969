import {
  FETCH_HEALTH,
  DISMISS_HEALTH,
  FETCH_HEALTH_LOADING,
} from "../actions/actionTypes";

const initialState = {
  isDismissed: false,
  issues: [],
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HEALTH_LOADING:
      return { ...state, loading: true };

    case FETCH_HEALTH:
      return { ...state, issues: payload, loading: false };

    case DISMISS_HEALTH:
      return { ...state, isDismissed: true };
    default:
      return state;
  }
};
