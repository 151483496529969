import instance from "./instance";
import * as types from '../actions/actionTypes'


import {message} from "antd";
import i18n from "i18next";

export const getDrivers = () => {
    return async (dispatch) => {
        try {

            const res = await instance.get("drivers/");
            dispatch({
                type: types.GET_DRIVER, payload: res.data
            })
        } catch (error) {
            message.error(error.response.data.message)
        }
    };
}
export const clearDriverHistory = () => {
    return async (dispatch) => {
        dispatch({
            type: types.CLEAR_DRIVER_ORDER_HISTORY
        })
    };
}
export const getDriverOrderHistory = (id, page = 1) => {
    return async (dispatch) => {
        try {
            const res = await instance.get(`drivers/${id}/orders?page=${page}/`);
            dispatch({
                type: types.GET_DRIVER_ORDER_HISTORY, payload: res.data
            })
        } catch (error) {
            message.error(error.response.data.message)
            dispatch({
                type: types.GET_DRIVER_ORDER_HISTORY_ERROR, payload: error.response.data
            })
        }
    };
}
export const deleteDriver = (id) => {
    return async (dispatch) => {
        try {
            await instance.delete(`drivers/${id}/`);
            message.success(i18n.t("Driver have been deleted successfully"))
            dispatch({
                type: types.DELETE_DRIVER, payload: id
            })
        } catch (error) {
            message.error(error.response.data.message)
        }
    };
}
export const toggleDriverStatus = (id) => {
    return async (dispatch) => {
        try {
            await instance.patch(`drivers/${id}/`);
            message.success(i18n.t("Driver status changed successfully"))
            dispatch({
                type: types.TOGGLE_DRIVER_STATUS, payload: id
            })
        } catch (error) {
            message.error(error.response.data.message)
        }
    };
}

export const createDriver = (data, handleAddModalCancel) => {
    return async (dispatch) => {
        try {
            const res = await instance.post(`drivers/`, {
                username: data.username,
                password: data.password,
                first_name: data.first_name,
                last_name: data.last_name,
                phone_number: data.phone_number,
                vehicle_type: data.vehicle_type,
            });
            handleAddModalCancel()
            message.success(i18n.t("Driver have been added successfully"))
            dispatch({
                type: types.CREATE_DRIVER, payload: res.data
            })
        } catch (error) {
            dispatch({
                type: types.CREATE_DRIVER_ERRORS, payload: error.response.data
            })
        }
    };
}

export const assignOrdertoDriver = (id, orderId) => {
    return async (dispatch) => {
        try {
            await instance.patch(`drivers/${id}/orders/`, {
                order_id: orderId
            });
            message.success(i18n.t("Driver changed successfully"))
            dispatch({
                type: types.CHANGE_DRIVER, payload: {
                    orderId: orderId, driverId: id,
                }
            })
        } catch (error) {
            message.error(error.response.data.message)
        }
    };
}

export const changeProfilePicture = (response) => {
    return {
        type: types.CHANGE_DRIVER_PROFILE_PICTURE,
        payload: response,
    };
};
export const clearDriverError = () => {
    return {
        type: types.CLEAR_DRIVER_ERROR,
    };
};