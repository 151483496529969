import {
  FETCH_DELIVERY_INTEGRATIONS,
  FETCH_INTEGRATION_LOADING,
  FETCH_INVENTORY_INTEGRATIONS,
  FETCH_PAYMENT_INTEGRATIONS,
  SAVE_DELIVERY_INTEGRATION_SETTINGS,
  SAVE_INVENTORY_INTEGRATION_SETTINGS,
  SAVE_PAYMENT_SETTINGS,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  payments: null,
  delivery: null,
  inventory: null,
};

export default (state = initialState, { type, payload }) => {
  let newPayments = null;
  let newDelivery = null;
  switch (type) {
    case FETCH_INTEGRATION_LOADING:
      return { ...state, loading: true };

    case FETCH_PAYMENT_INTEGRATIONS:
      return { ...state, payments: payload, loading: false };

    case SAVE_PAYMENT_SETTINGS:
      newPayments = state.payments;
      newPayments[payload.gateway] = payload.data;
      return { ...state, payments: newPayments };

    case FETCH_DELIVERY_INTEGRATIONS:
      return { ...state, delivery: payload, loading: false };
    case FETCH_INVENTORY_INTEGRATIONS:
      return { ...state, inventory: payload, loading: false };

    case SAVE_DELIVERY_INTEGRATION_SETTINGS:
      newDelivery = state.delivery;
      newDelivery[payload.provider] = payload.data;
      return { ...state, delivery: newDelivery };
    case SAVE_INVENTORY_INTEGRATION_SETTINGS:
      let newInventory = state.inventory;
      newInventory[payload.company] = {
        ...newInventory[payload.company],
        ...payload.data,
      };
      return { ...state, inventory: newInventory };

    case "CREATE_ARAMEX_BOX": {
      const { delivery } = state;
      const updatedDelivery = {
        ...delivery,
        aramex_box: delivery?.aramex_box.concat(payload),
      };

      return {
        ...state,
        delivery: updatedDelivery,
      };
    }

    case "DELETE_ARAMEX_BOX": {
      const { delivery } = state;
      const updatedDelivery = {
        ...delivery,
        aramex_box: delivery?.aramex_box.filter((box) => box.id !== payload),
      };

      return {
        ...state,
        delivery: updatedDelivery,
      };
    }

    case "UPDATE_ARAMEX_BOX": {
      const { id, width, depth, height } = payload;
      const delivery = state.delivery;
      console.log("ree");
      const updatedAramexBox = delivery?.aramex_box.map((box) => {
        if (box.id === id) {
          return {
            ...box,
            box_width: +width !== undefined ? +width : +box.width,
            box_depth: +depth !== undefined ? +depth : +box.depth,
            box_height: +height !== undefined ? +height : +box.height,
          };
        }
        return box;
      });

      return {
        ...state,
        delivery: {
          ...delivery,
          aramex_box: updatedAramexBox,
        },
      };
    }

    default:
      return state;
  }
};
