const trans = {
  Features: "الميزات",
  Language: "اللغة",
  "Enable switching languages": "السماح بتغيير اللغة",
  "Default language": "اللغة الافتراضية",
  "Other settings": "ميزات اخرى",
  "Show prices": "اظهار الاسعار",
  "Ask for birthday": "السؤال عن تاريخ الميلاد",
  "Ask for home image": "السؤال عن صورة المنزل",
  "Enable gift messages": "السماح برسالة الهدية",
  "Enable search": "تفعيل البحث",
  "Enable waiting lists": "تفعيل قائمة الانتظار",
  "Show store status": "اظهار حالة المتجر",
  "Enable feedback": "تفعيل التعليقات",
  "Ask for car details (pickup)": "السؤال عن تفاصيل السيارة (طلبات الاستلام)",
  "Enable make as a required field": "تفعيل النوع كحقل اجباري",
  "Enable color as a required field": "تفعيل اللون كحقل اجباري",
  "Enable plate number as a required field": "تفعيل رقم اللوحة كحقل اجباري",
  "Enable as a required field": "تفعليه كحقل اجباري",
  "Ask for civil ID": "السؤال عن البطاقة المدنية",
  "Enable cash": "تفعيل دفع الكاش",
  "Enable feedbacks": "تفعيل تعليقات الزبائن",
  "Enable KNet machine on delivery": "تفعيل الكي نت عند الاستلام",
  "Show order mode": "اظهار نوعية الطلب",
  "Show store logo": "اظهار شعار المتجر",
  "Show wheelchair accessibility": "اظهار دعم ذوي الاحتياجات الخاصة",
  "Force select location on open": "اجبار اختيار منطقة عند الزيارة",
  "Disable force location for product detail page":
    "تعطيل اجبار اختيار منطقة عند الزيارة لصفحة تفاصيل المنتج",
  "This option will turn off the forced location check when users go to a product detail page. This can be useful if you're running ads that direct users straight to the product page, as it provides a smoother experience for them.":
    "هذا الخيار سيعطّل التحقق الإجباري من الموقع عند وصول المستخدمين إلى صفحة تفاصيل المنتج. يمكن أن يكون هذا مفيدًا إذا كنت تدير إعلانات توجه المستخدمين مباشرةً إلى صفحة المنتج، حيث يوفر لهم تجربة أكثر سلاسة.",
  "Show preparation times": "اظهار اوقات التحضير",
  "Enable quick add to cart": "تفعيل  الإضافة الى السلة السريع",
  "Enable social authentication": "تفعيل تسجيل الدخول الاجتماعي",
  "Enable WhatsApp widget": "تفعيل زر الواتساب",
  "Enable Instagram widget": "تفعيل زر Instagram",
  "Please enter a value between 0 and 100": "الرجاء ادخال قيمة بين 0 و 100",
  "Enable VAT": "تفعيل الضرائب",
  VAT: "َضرائب",
  "VAT rate": "نسبة الضرائب",
  "Hide short descriptions from products": "اخفاء الوصف القصير من المنتجات",
  "Hide earliest arrival/pickup time": "اخفاء وقت اقرب وصول/استلام",
  "Gift message character limit (0 for unlimited)":
    "الحد الأقصى لعدد احرف رسالة الهدية (0 لغير محدود)",
  "Please enter a value greater than 0": "الرجاء ادخال قيمة اكبر من 0",
  "Show prices (POS)": "اظهار الاسعار (نقطة البيع)",
  "Customer email is required": "ايميل العميل اجباري",
  "Enable special remarks": "تفعيل الملاحظات على الطلبات",
  "Enable branch prioritization": "تفعيل أولويات الأفرع",
  "Show Nutritional Facts": "عرض القيم الغذائية",
  "Enable ability to flag a customer": "تفعيل القدرة على اضافة علامة للزبائن",
  "Enable coupons": "تفعيل الكوبونات",
  "Enable product recommendations": "تفعيل اقتراح المنتجات",
  "Import product image to related modifiers":
    "اضافة صورة المنتج الى الاضافات المتعلقة بها",

  "Hide irrelevant products after the order mode is selected":
    "إخفاء المنتجات الغير متعلقة بطريقة الطلب",
  "Show on": "اظهار في",
  "Product detail page": "صفحة تفاصيل المنتج",
  "Cart page": "صفحة سلة المشتريات",
  "Checkout page": "صفحة اتمام الطلب",
  "Product price range": "النطاق السعري للمنتج",
  "This range helps you recommend products within a certain price range.":
    "يساعدك هذا النطاق على التوصية بالمنتجات ضمن نطاق سعري معين.",
  "Make sure the lower price limit is not greater than the upper limit.":
    "تأكد من أن الحد الأدنى للسعر ليس أكبر من الحد الأعلى.",
  "Products that belong to excluded categories will not be included in product recommendations.":
    "لن يتم تضمين المنتجات التي تنتمي إلى الفئات المستبعدة في توصيات المنتج.",
  "Automatically Receive POS Orders": "استلام طلبات نقطة البيع تلقائيا",
  "Make Source Mandatory": "إجعل إضافة المصدر إلزامية",
  "Allow YouTube gift links": "السماح بإهداء روابط يوتيوب",
  "Confirm delivery time before checkout":
    "التأكيد على وقت التوصيل عند اتمام الطلب",
  "Store Features": "ميزات المتجر",
  "Dashboard Features": "ميزات لوحة التحكم",
  "Store Design Elements": "خصائص تصميم المتجر",
  "Address Details": "تفاصيل العنوان",
  "Checkout & Shopping Cart": "سلة المشتريات واتمام الطلب",
  "Survey Settings": "اعدادات الاستبيان",
  "Multiple languages": "لغات متعددة",
  Preferences: "الخصائص",
  "POS Settings": "اعدادات نقطة البيع",
  "Enable changing language": "تمكين تغيير اللغة",
  "Show product recommendations on": "اظهار اقتراحات المنتجات في",
  "Enable sharing carts": "تفعيل مشاركة السلات",
  "Allow only one promotion at a time": "السماح بتفعيل عرض واحد فقط",
  "Enable No Cutlery": "تفعيل دون ادوات الطعام",
  "Enable B2B coupons": " تفعيل الكوبونات الشركات",
  "Enable gift message form": "السماح بنموذج رسالة الهدية",
  "Allow map input": "اظهار الخريطة لوضع الموقع",
  "Require gift receiver name and number": "اجبار ادخال اسم ورقم المستلم",
  "Enable B2B shopping cart": "تفعيل سلة المشتريات للشركات",
  "Enable B2B special remarks": "تفعيل الملاحظات الخاصة للشركات",
  "Exclude VAT from total price":
    "استثناء ضريبة القيمة المضافة من السعر الإجمالي  ",
  "Allow gifts to be sent anonymously": "السماح بإرسال الهدايا بشكل مجهول",
  "Place order directly": "تنفيذ الطلب مباشرة",
  "Enable smart search": "تفعيل البحث الذكي",
  "Enable B2B special remarks": "تفعيل الملاحظات الخاصة للشركات",
  "Show previous orders on homepage":
    "اظهار الطلبات السابقة في الصفحة الرئيسية",
  "Exclude VAT from total price":
    "استثناء ضريبة القيمة المضافة من السعر الإجمالي  ",
  "Allow gifts to be sent anonymously": "السماح بإرسال الهدايا بشكل مجهول",
  "Disable gifting for cash orders": "تعطيل الهدايا للطلبات النقدية",
  "Enable phone OTP verification": "تفعيل خاصية التحقق من رقم الهاتف",
  "OTP for cash orders only": "التحقق من طلبات الكاش فقط",
  "SMS credit is required to send the OTP messages, otherwise orders will proceed without an OTP verification":
    "تتطلب هذه الميزة وجود رصيد كافي في محفظة الرسائل النصية. اذا نفذ رصيدك، سيتم استقبال الطلبات بدون تحقق",
  "Expanded view for gift form details": "تفاصيل نموذج الهدايا",
  "Accept gift orders without receiver address":
    "قبول طلبات الهدايا بدون عنوان المستلم",
  "Enable option groups": "تفعيل مجموعات الخيارات",
  "Show busy until time": "عرض وقت اغلاق المتجر",
  "This allows you to organize your options into groups where each group will have a title and relevant choices underneath it.":
    "تمكنك هذه الخاصية من ترتيب خياراتك عن طريق وضع الخيارات في مجموعات حيث يكون لكل مجموعة عنوان وخيارات فرعية تندرج تحته",
  "To set that up": "لاستخدام هذه الخاصية",
  "1. From Products, navigate to Option Groups and create a new one from Actions":
    "1. من صفحة المنتجات، اذهب الى مجموعات الخيارات حيث يمكنك انشاء مجموعة جديدة من زر التحكم",
  "2. From your Options tab in the product's details window, click the Edit next to the option name.":
    "2. اذهب الى صفحة الخيارات داخل المنتج، ثم اضغط على تعديل بجانب اسم الخيار",
  "3. Assign one or multiple Groups to the selected Option":
    "3. قم باضافة مجموعة او اكثر للخيار المختار",
  "Enable send email for unreceived orders":
    "تفعيل إرسال بريد إلكتروني للطلبات غير المستلمة",
  "Max time to wait for order to be received (in minutes). You will be notified once the order exceeds that time.":
    "أقصى وقت لانتظار استلام الطلب (بالدقائق). سيتم أبلاغك بمجرد أن يتجاوز الطلب ذلك الوقت.",
  "Show product feedbacks": "تفعيل تعليقات الزبائن على المنتجات",
  "Enable option by default": "تفعيل الخيار بشكل افتراضي",
  "Enable products cross selling": "تفعيل المنتجات ذات الصلة",
  "POS Discount Presets": "خصومات نقاط البيع",
  "To make applying discounts easier, you can create a list of presets that will appear on your POS.":
    "لتسهيل عملية تطبيق الخصومات، يمكنك إنشاء قائمة من الخصومات التي ستظهر على نقاط البيع",
  "Enter a comma separated list of numbers. ":
    "أدخل قائمة مفصولة بفواصل من الأرقام",
  "For percentage discounts numbers must be between 1 and 100.":
    "للخصومات النسبية، يجب أن تكون الأرقام بين 1 و 100",
  "To apply different discounts to different items, enter multiple numbers separated by commas":
    "لتطبيق خصومات مختلفة على عناصر مختلفة، أدخل أرقامًا متعددة مفصولة بفواصل",
  "Example: entering 10, 20, 30 will create presets for 10%, 20% and 30% or 10 ":
    "مثال: إدخال 10، 20، 30 سينشئ خصومات لـ 10٪ و 20٪ و 30٪ أو 10",
  "Please enter a list of numbers": "الرجاء إدخال قائمة من الأرقام",
  "New order": "طلب جديد",
  "Customer adds to order": "اضافة الى طلب سابق",
  Sounds: "الاصوات",
  "Allow tipping": "تفعيل الاكراميات",
  "Enable random products order": "تفعيل ترتيب المنتجات العشوائية",
  "Sort products by inventory stock": "فرز المنتجات حسب المخزون",
  "Due soon setting (minutes). Minimum value for this field is 30 minutes.":
    "الإعداد المستحق قريبًا (بالدقائق). الحد الأدنى لقيمة هذا الحقل هو 30 دقيقة.",
  "The 'Due soon' value allows you to define when an order is considered 'due soon' or 'later' based on the time remaining until the order's expected arrival, with 'soon' indicating a shorter wait and 'later' a longer one.":
    'تتيح لك القيمة "مستحق قريبًا" تحديد متى يعتبر الطلب "مستحقًا قريبًا" أو "لاحقًا" استنادًا إلى الوقت المتبقي حتى الوصول المتوقع للطلب، حيث تشير كلمة "قريبًا" إلى انتظار أقصر و"لاحقًا" إلى انتظار أطول.',
  "Allow partial payments using wallet": "الدفع الجزئي مع مبلغ المحفظة",
  "If enabled, customers can use their wallet balance to pay for the order. If the amount in the wallet is not enough, they will pay the remaining amount with another payment method. If disabled, customers will have to pay the full amount using the wallet or any available payment method.":
    "اذا تم التفعيل، يمكن للعملاء استخدام رصيد محفظتهم لدفع قيمة الطلب. إذا لم يكن المبلغ المتوفر في المحفظة كافيًا، يجب على العملاء دفع المبلغ المتبقي باستخدام وسيلة دفع أخرى. اذا لم يتم التفعيل، سيتعين على العملاء دفع المبلغ الكامل باستخدام المحفظة أو أي وسيلة دفع أخرى متاحة.",
  "Enable log in with phone": "تفعيل تسجيل الدخول باستخدام رقم الهاتف",
  "The 'Sign in with Phone' option will be available if SMS is enabled and there is a sufficient balance in your SMS wallet to send verification codes.":
    "سيتوفر خيار 'تسجيل الدخول بالهاتف' إذا تم تمكين خدمة الرسائل النصية (SMS) وإذا كان لديك رصيد كافٍ في محفظتك لإرسال رموز التحقق.",

  "Allow users to add products in their personal favorites list.":
    "السماح للمستخدمين بإضافة المنتجات إلى قائمة المفضلة الشخصية الخاصة بهم.",
  "Favorite Products": "المنتجات المفضلة",
  Maximum: "الحد الاقصى",
  Minimum: "الحد الأدنى",

  "This feature helps you understand how soon you should expect a delivery based on a time you know.":
    "تساعدك هذه الميزة على فهم الوقت الذي يجب أن تتوقع فيه التسليم بناءً على الوقت الذي تعرفه.",
  "If your delivery is more than {{dueSoonSetting}} minutes away, it's categorized as 'later.' This means it's a bit far in the future, and you have some time to get ready.":
    '"إذا كان التسليم بعد أكثر من {{dueSoonSetting}} دقيقة، فسيتم تصنيفه على أنه "لاحقًا". هذا يعني أن المستقبل بعيد بعض الشيء، ولديك بعض الوقت للاستعداد."',
  "If it's closer, between expected delivery time and {{dueSoonSetting}} minutes away, it's labeled as 'soon.' This is when you should start preparing, but it's not right around the corner.":
    'إذا كان أقرب، بين وقت التسليم المتوقع و{{dueSoonSetting}} دقيقة، فسيتم تصنيفه على أنه "قريبًا". هذا هو الوقت الذي يجب أن تبدأ فيه الاستعداد، لكنه ليس قاب قوسين أو أدنى.',
  "If your delivery expected time is less than 15 minutes away, it falls into the 'due soon' category. This means it's almost here, so be prepared to prepare or receive it.":
    'إذا كان الوقت المتوقع للتسليم أقل من 15 دقيقة، فإنه يقع ضمن فئة "مستحق قريبًا". هذا يعني أنه على وشك الوصول، لذا كن مستعدًا لإعداده أو استلامه.',
  "If none of the above situations apply, it's simply 'due.' This indicates that the order is overdue.":
    'إذا لم ينطبق أي من الحالات المذكورة أعلاه، فهو ببساطة "مستحق". يشير هذا إلى أن الطلب قد تأخر.',
  Later: "لاحقاً",
  Soon: "قريبًا",
  "Due soon": "مستحق قريبًا",
  "Automatically mark transferred orders as new orders":
    "وضع علامة على الطلبات المحولة تلقائيًا على أنها طلبات جديدة",
  "Enable product tag": "تمكين علامة المنتج",
  "Enable gift wrapping": "تمكين تغليف الهدايا",
  "To make gift wrapping chargeable, please specify the price in the input field. If you prefer not to charge for gift wrapping, simply set the price to 0.":
    "لجعل تغليف الهدايا قابلاً للرسوم، يرجى تحديد السعر في حقل الإدخال. إذا كنت تفضل عدم فرض رسوم على تغليف الهدايا، فما عليك سوى ضبط السعر على 0.",
  "Verified purchases only": "المشتريات التي تم التحقق منها فقط",
};

export default trans;
