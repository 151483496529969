import {
  FETCH_ROLES_USERS,
  FETCH_ROLES_USERS_LOADING,
  CREATE_STORE_ROLE,
  DELETE_STORE_ROLE,
  CHANGE_ROLE_NAME,
  SWITCH_PERMISSIONS,
  CHANGE_PERMISSION_AREAS,
  CHANGE_PERMISSION_BRANCHES,
  CHANGE_USER_ROLE,
  BULK_CHANGE_USER_ROLE,
  CREATE_NEW_USER,
  UPDATE_PERMISSIONS,
  COPY_BRANCHES_AREAS_TO_PERMISSIONS,
  DELETE_STORE_USER_ROLE,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchRolesUsers = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ROLES_USERS_LOADING });
    try {
      let res = await instance.get("/fetch_roles_users/");
      if (res.data.success) {
        dispatch({ type: FETCH_ROLES_USERS, payload: res.data });
      } else
        message.error(
          "Could not load permissions. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view permissions");
      else
        message.error(
          "Could not load permissions. Please try again or contact us for help"
        );
    }
  };
};

export const createStoreRole = (role) => {
  return {
    type: CREATE_STORE_ROLE,
    payload: role,
  };
};

export const deleteStoreRole = (id) => {
  return {
    type: DELETE_STORE_ROLE,
    payload: id,
  };
};

export const changeRoleName = (id, value) => {
  return {
    type: CHANGE_ROLE_NAME,
    payload: { id: id, value: value },
  };
};

export const switchPermissions = (roleID, field, value) => {
  return {
    type: SWITCH_PERMISSIONS,
    payload: { roleID: roleID, field: field, value: value },
  };
};

export const changePermissionAreas = (roleID, additions, deletions) => {
  return {
    type: CHANGE_PERMISSION_AREAS,
    payload: { roleID: roleID, additions: additions, deletions: deletions },
  };
};

export const changePermissionBranches = (roleID, additions, deletions) => {
  return {
    type: CHANGE_PERMISSION_BRANCHES,
    payload: { roleID: roleID, additions: additions, deletions: deletions },
  };
};

export const changeUserRole = (userID, newRole) => {
  return {
    type: CHANGE_USER_ROLE,
    payload: { userID: userID, newRole: newRole },
  };
};

export const bulkChangeUserRole = (ids, newRole) => {
  return {
    type: BULK_CHANGE_USER_ROLE,
    payload: { ids: ids, newRole: newRole },
  };
};

export const createNewUser = (user) => {
  return {
    type: CREATE_NEW_USER,
    payload: user,
  };
};

export const updatePermissions = () => {
  return async (dispatch) => {
    try {
      let res = await instance.get("/update_permissions/");
      if (res.data.success) {
        dispatch({ type: UPDATE_PERMISSIONS, payload: res.data });
        dispatch({ type: "UPDATE_PERMISSIONS_ROLES", payload: res.data });
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const copyBranchAreasToPermissions = (role, areas) => {
  return {
    type: COPY_BRANCHES_AREAS_TO_PERMISSIONS,
    payload: { role, areas },
  };
};

export const deleteStoreUserRole = (id) => {
  return {
    type: DELETE_STORE_USER_ROLE,
    payload: { id },
  };
};
