import React, { Component } from "react";

import { Badge, Divider, Dropdown, Layout, Menu, Select } from "antd";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { ReactComponent as SpeakerON } from "../../assets/speaker.svg";
import { ReactComponent as SpeakerOFF } from "../../assets/mute.svg";
import { toggleSound } from "../../store/actions/orders";
import { collapseSideNav } from "../../store/actions/";
import { ReactComponent as Bag } from "../../assets/icons/shopping-bag.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Marketing } from "../../assets/icons/marketing.svg";
import { ReactComponent as Invoice } from "../../assets/icons/invoice.svg";
import { ReactComponent as POS } from "../../assets/icons/calculator.svg";
import { ReactComponent as Pie } from "../../assets/icons/pie-chart.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Briefcase } from "../../assets/icons/briefcase.svg";
import { ReactComponent as BranchesIcon } from "../../assets/icons/shop.svg";
import { ReactComponent as CustomersIcon } from "../../assets/icons/user.svg";
import { ReactComponent as OrdableLogo } from "../../assets/ordable.svg";
import { ReactComponent as BookingIcon } from "../../assets/icons/timetable.svg";
import { ReactComponent as AddOnsIcon } from "../../assets/icons/add.svg";
import { ReactComponent as Tables } from "../../assets/icons/table7.svg";
import {
  CloseOutlined,
  DesktopOutlined,
  GlobalOutlined,
  LeftOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactComponent as QuestionIcon } from "../../assets/icons/Help.svg";
import { ReactComponent as ExternalIcon } from "../../assets/icons/Redirect.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/Support2.svg";
import { ReactComponent as WaitingListIcon } from "../../assets/icons/Waitlisting.svg";
import { ReactComponent as DriverIcon } from "../../assets/icons/truck_icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/help-tawk.svg";
import QRCode from "qrcode.react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { switchLanguage } from "../../store/actions/config";
import { logout } from "../../store/actions/auth";
import { Crisp } from "crisp-sdk-web";
import axios from "axios";
import QRScanDrawer from "../Orders/QRScanDrawer";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;

const IconStyle = {
  height: "16px",
  verticalAlign: "bottom",
  marginRight: "10px",
  fill: "#0099cc",
};

class SideNav extends Component {
  state = {
    // isHelpOpen: false,
    dropdownVisible: false,
  };
  getSelectedMenuKey = () => {
    switch (window.location.pathname) {
      case "/manage/orders":
        return ["1"];
      case "/manage/business_orders":
        return ["2"];
      case "/manage/products":
        return ["3"];
      case "/manage/locations":
        return ["4"];
      case "/manage/hours":
        return ["5"];
      case "/manage/DeliveryRates":
        return ["6"];
      case "/manage/DeliveryConfig":
        return ["7"];
      case "/manage/appearance/Identity":
        return ["8"];
      case "/manage/appearance/Features":
        return ["9"];
      case "/manage/appearance/LayoutTheme":
        return ["10"];
      case "/manage/appearance/InvoiceSettings":
        return ["11"];
      case "/manage/appearance/CustomTexts":
        return ["12"];
      case "/manage/appearance/NoticeBanner":
        return ["noticebanner"];
      case "/manage/appearance/PopupBanner":
        return ["popupBanner"];
      case "/manage/promotions":
        return ["13"];
      case "/manage/coupons":
        return ["coupons"];
      case "/manage/permissions":
        return ["14"];
      case "/manage/Integration/Delivery":
        return ["15"];
      case "/manage/Integration/Payment":
        return ["16"];
      case "/manage/Integration/Marketing":
        return ["17"];
      case "/manage/businesses":
        return ["18"];
      case "/manage/bookings":
        return ["events"];
      case "/manage/services":
        return ["appointments"];
      case "/manage/reports/downloads":
        return ["downloads"];
      case "/manage/reports/customers":
        return ["customersReports"];
      case "/manage/waitlists":
        return ["waiting-lists"];
      case "/manage/feedback":
        return ["23"];
      case "/manage/survey":
        return ["survey-list"];
      case "/manage/customers":
        return ["24"];
      case "/manage/raffle":
        return ["25"];
      case "/manage/reports/customers":
        return ["26"];
      case "/manage/reports/smaps":
        return ["sales"];
      case "/manage/reports/closing":
        return ["28"];
      case "/manage/reports/tables":
        return ["table"];
      case "/manage/reports/products":
        return ["byProductReport"];
      case "/manage/Integration/Messaging":
        return ["30"];
      case "/manage/limits":
        return ["limits"];
      case "/manage/pos":
        return ["pos"];
      case "/manage/billing":
        return ["98"];
      case "/manage/staff":
        return ["99"];
      case "/manage/ServiceProviders":
        return ["serviceProviders"];
      case "/manage/Integration/InventoryManagement":
        return ["InventoryManagement"];
      case "/manage/home":
        return ["home"];
      case "/manage/faqs":
        return ["faqs"];
      case "/manage/pending":
        return ["pending"];
      case "/manage/settings":
        return ["settings"];
      case "/manage/addons":
        return ["addons"];
      case "/manage/InstalledAddons":
        return ["installedAddons"];
      case "/manage/tables":
        return ["tables"];
      case "/manage/users":
        return ["users"];
      case "/manage/topups":
        return ["topups"];
      case "/manage/wallet":
        return ["wallet"];

      case "/manage/MobileNotifications":
        return ["mobileNotifications"];
    }
  };

  showDeliveryTab = () => {
    const { settings } = this.props;
    if (
      (settings.enable_ogo ||
        settings.enable_mirsal ||
        settings.enable_frontlines ||
        settings.enable_flick ||
        settings.enable_snoonu ||
        settings.enable_urwagon ||
        settings.enable_tam ||
        settings.enable_deliverix ||
        settings.enable_kangaroo ||
        settings.enable_dhl ||
        settings.enable_quick_delivery ||
        settings.enable_parcel ||
        settings.enable_fodo ||
        settings.enable_delivery_runners) &&
      settings.enable_dispatching
    )
      return true;
    return false;
  };

  showInventoryTab = () => {
    const { settings } = this.props;
    if (
      settings.enable_inflow ||
      settings.enable_cin7 ||
      settings.enable_vend ||
      settings.enable_pos_rocket ||
      settings.enable_revel ||
      settings.enable_ocims ||
      settings.enable_axanta ||
      settings.enable_ribox ||
      settings.enable_deliverect ||
      settings.enable_hike ||
      settings.enable_focus ||
      settings.enable_foodics ||
      settings.enable_matix ||
      settings.enable_invo ||
      settings.enable_urban_piper ||
      settings.enable_homey ||
      settings.enable_grubtech ||
      settings.enable_allow ||
      settings.enable_microsys ||
      settings.enable_paydo
    )
      return true;
    return false;
  };

  onCollapse = () => {
    this.props.collapseSideNav();
  };

  shouldShowBillingBadge = () => {
    const { info } = this.props;
    if (!info) return false;
    if (!info.next_payment_date) return false;
    if (info.package === 0 && info.commission_value + info.addons_fee === 0)
      return false;

    const diff =
      moment(info.next_payment_date, "YYYY-MM-DD").diff(moment(), "days") + 1;
    if (diff < info.notify_before) return true;
    return false;
  };

  onLanguageChange = (value) => {
    this.props.switchLanguage(value);
    this.setState({ dropdownVisible: false });
  };

  onStoreChange = (value) => {
    if (!value.includes(window.location.hostname)) window.location.href = value;
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.settings && !this.state.QRuri && !this.props.hideHeader) {
      const qrCodeCanvas = document.querySelector("#QR");
      const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
      this.QRCodeUri = qrCodeDataUri;
      this.setState({ QRuri: qrCodeDataUri });
    }

    const associatedStores = JSON.parse(
      JSON.stringify(this.props.associated_stores)
    );
    const prevAssociatedStores = JSON.parse(
      JSON.stringify(prevProps.associated_stores)
    );
    const { settings } = this.props;
    const nowHasNewOrders = !!associatedStores.filter(
      (x) =>
        x.has_pending_orders?.count &&
        x.store__sites__domain !== settings.domain
    ).length;

    const didHaveNewOrders = !!prevAssociatedStores.filter(
      (x) =>
        x.has_pending_orders?.count &&
        x.store__sites__domain !== settings.domain
    ).length;

    if (!didHaveNewOrders && nowHasNewOrders) {
      this.props.setNewOrderBellAggregate(true);
    } else if (didHaveNewOrders && !nowHasNewOrders) {
      this.props.setNewOrderBellAggregate(false);
    }
  };

  fetchAssociatedStoresPendingOrders = async (domain) => {
    try {
      const res = await axios.post(
        `https://${domain}/get_orders/`,
        {
          returnPromiseCount: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (res.data.success) {
        this.props.updateAssociatedStore(domain, res.data.count);
        // if (domain !== this.props.settings.domain && res.data.count)
        //   this.props.setNewOrderBellAggregate();
      }
    } catch (e) {
      console.error(e);
    }
  };

  componentDidMount = () => {
    if (
      this.props.settings.enable_aggregate_dash_notifications &&
      this.props.associated_stores.length > 1
    ) {
      const domains = this.props.associated_stores.filter(
        (x) =>
          !x.has_pending_orders &&
          x.store__sites__domain !== this.props.settings.domain
      );
      if (domains.length) {
        domains.forEach((d) =>
          this.fetchAssociatedStoresPendingOrders(d.store__sites__domain)
        );
      }
      const { settings } = this.props;
      const associatedStores = this.props.associated_stores;
      const nowHasNewOrders = !!associatedStores.filter(
        (x) =>
          x.has_pending_orders?.count &&
          x.store__sites__domain !== settings.domain
      ).length;

      if (!this.props.bellHasNewAggregate && !!nowHasNewOrders)
        this.props.setNewOrderBellAggregate(true);
    }
  };

  associatedStoresHaveOrders = () => {
    const { associated_stores, settings } = this.props;
    if (associated_stores.length <= 1) return false;

    return !!associated_stores.filter(
      (x) =>
        x.has_pending_orders?.count &&
        x.store__sites__domain !== settings.domain
    ).length;
  };
  render() {
    const {
      t,
      config,
      permissions,
      feedback_exists,
      hideHeader,
      sideNavCollapsed,
      settings,
      username,
    } = this.props;
    // const settings = { ...this.props.settings, go_store: true };
    const menu = (
      <Menu style={{ width: 200 }}>
        <CloseOutlined
          style={{
            position: "absolute",
            top: 12,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            this.setState({ dropdownVisible: !this.state.dropdownVisible })
          }
        />
        <p
          className="mb-2 mt-1 text-center"
          style={{
            margin: "auto",
            maxWidth: 150,
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: 11,
          }}
        >
          {username}
        </p>
        <Divider style={{ width: 120, margin: "5px 0px" }} />

        {this.props.associated_stores.length > 1 && (
          <>
            <Menu.Item>
              <span>
                {this.associatedStoresHaveOrders() && (
                  <span
                    className="dot-red"
                    style={{
                      position: "absolute",
                      top: 4,
                      [config.direction === "rtl" ? "right" : "left"]:
                        config.direction === "rtl" ? 24 : 28,
                    }}
                  ></span>
                )}
                <DesktopOutlined
                  style={{
                    fontSize: 16,
                    [`margin${
                      config.direction === "ltr" ? "Right" : "Left"
                    }`]: 8,
                  }}
                />{" "}
                <Select
                  value={`https://${
                    window.location.hostname
                  }/manage?token=${localStorage.getItem("token")}`}
                  style={{ width: 145, position: "relative", right: 0 }}
                  onChange={this.onStoreChange}
                  size="small"
                >
                  {this.props.associated_stores.map((s) => (
                    <Option
                      value={`https://${
                        s.store__sites__domain
                      }/manage?token=${localStorage.getItem("token")}`}
                      style={{
                        textAlign:
                          config.direction === "ltr" ? "left" : "right",
                      }}
                    >
                      {s.store__sites__domain !== settings.domain &&
                        !!s.has_pending_orders?.count && (
                          <span
                            className="dot-red"
                            style={{
                              [config.direction === "rtl"
                                ? "margin-left"
                                : "margin-right"]: 7,
                            }}
                          ></span>
                        )}
                      {config.language === "arabic"
                        ? s.store__ar_name || s.store__name
                        : s.store__name}
                    </Option>
                  ))}
                </Select>
              </span>
            </Menu.Item>
            <Divider style={{ width: 120, margin: "5px 0px" }} />
          </>
        )}
        <Menu.Item
          onClick={() => {
            this.setState({ dropdownVisible: false });
            this.props.history.push("/manage/profile");
          }}
        >
          <span>
            <UserOutlined
              style={{
                fontSize: 16,
                [`margin${config.direction === "ltr" ? "Right" : "Left"}`]: 8,
              }}
            />{" "}
            {t("Profile")}
          </span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ dropdownVisible: false })}>
          <a href={this.state.QRuri} download="QR Code">
            <QrcodeOutlined
              style={{
                fontSize: 16,
                [`margin${config.direction === "ltr" ? "Right" : "Left"}`]: 8,
              }}
            />{" "}
            {t("QR Code")}
          </a>
        </Menu.Item>

        <Divider style={{ width: 120, margin: "5px 0px" }} />

        <Menu.Item>
          <span>
            <GlobalOutlined
              style={{
                fontSize: 16,
                [`margin${config.direction === "ltr" ? "Right" : "Left"}`]: 8,
              }}
            />{" "}
            <Select
              value={config.language}
              style={{ width: 145, position: "relative", right: 0 }}
              onChange={this.onLanguageChange}
              size="small"
              //suffixIcon={<GlobalOutlined />}
            >
              <Option value="english">English</Option>
              <Option value="arabic">عربي</Option>
            </Select>
          </span>
        </Menu.Item>

        <Divider style={{ width: 120, marginBottom: 5, marginTop: 7 }} />
        <Menu.Item
          onClick={() => {
            this.setState({ dropdownVisible: false });
            this.props.logout();
          }}
        >
          <span>
            <LogoutOutlined
              style={{
                fontSize: 16,
                [`margin${config.direction === "ltr" ? "Right" : "Left"}`]: 8,
              }}
            />{" "}
            {t("Logout")}
          </span>
        </Menu.Item>
      </Menu>
    );
    const MenuItem = {
      backgroundColor: "#F3F4F8",
      color: "#555555",
      direction: config.direction,
    };
    const IconStyleB = {
      height: "16px",
      width: "16px",
      verticalAlign: "middle",
      marginRight: "10px",
      fill: "#0099cc",
      position: "relative",
      bottom: "3px",

      marginLeft: config.direction == "ltr" ? "0px" : "15px",
      marginRight: config.direction == "rtl" ? "0px" : "15px",
    };

    if (hideHeader) return null;

    const dividerStyle = {
      background: "#AAAAAA",
      width: "70%",
      margin: "20px auto",
      height: 1,
    };

    const sectionTitlesStyle = {
      color: "#333333",
      [`padding${config.direction === "ltr" ? "Left" : "Right"}`]: 24,
      textAlign: config.direction === "ltr" ? "left" : "right",
      lineHeight: "35px",
      marginBottom: 0,
      backgroundColor: "#f3f4f8",
    };
    return (
      <>
        {this.state.scanDrawerOpen && (
          <QRScanDrawer
            onClose={() => this.setState({ scanDrawerOpen: false })}
          />
        )}

        <Sider
          collapsible
          collapsed={sideNavCollapsed}
          onCollapse={this.onCollapse}
          breakpoint="md"
          collapsedWidth="0"
          zeroWidthTriggerStyle={{
            marginTop: 4,
            [config.direction === "ltr" ? "left" : "right"]: "100%",
            top: -4,
            position: "absolute",
            backgroundColor: "#0099cc",
            width: 20,
            height: 28,
            [`borderTop${config.direction === "ltr" ? "Right" : "Left"}Radius`]:
              "50%",
            [`borderBottom${
              config.direction === "ltr" ? "Right" : "Left"
            }Radius`]: "50%",
          }}
          style={{
            backgroundColor: "#F3F4F8",
            borderRight: "none",
            position: window.screen.width < 500 && "absolute",
            zIndex: 200,
          }}
          trigger={
            <div>
              {sideNavCollapsed ? (
                config.direction === "ltr" ? (
                  <RightOutlined
                    style={{
                      top: -11,
                      left: -1,
                      fontSize: 12,
                    }}
                  />
                ) : (
                  <LeftOutlined
                    style={{
                      top: -11,
                      right: -1,
                      fontSize: 12,
                    }}
                  />
                )
              ) : config.direction === "ltr" ? (
                <LeftOutlined
                  style={{
                    top: -12,
                    left: -3,
                    fontSize: 12,
                  }}
                />
              ) : (
                <RightOutlined
                  style={{
                    top: -12,
                    right: -3,
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          }
        >
          <div style={{ display: "none" }}>
            <QRCode
              value={settings ? `https://${settings.domain}` : ``}
              size={500}
              id={"QR"}
            />
          </div>
          <div
            className="text-center row"
            style={{
              height: 74,
              width: "100%",
              direction: config.direction,
              backgroundColor: "#0099cc",
              padding: "2px",
              margin: "0px",
            }}
          >
            <div className="col-12 p-0">
              <span
                style={{
                  color: "white",
                  marginTop: 14,
                  marginBottom: 0,
                  direction: config.direction,
                  fontSize: 25,
                  wordBreak: "break-word",
                  lineHeight: 2.5,
                  display:
                    window.location.hostname?.includes("enable.qa") ||
                    settings.dashboard_is_whitelabel
                      ? "none"
                      : "inline",
                }}
              >
                <OrdableLogo
                  style={{ width: 125, height: 50, marginBottom: 10 }}
                />
              </span>
            </div>
          </div>

          <Menu
            theme="dark"
            defaultSelectedKeys={this.getSelectedMenuKey()}
            mode="inline"
            style={{
              [`border${config.direction === "ltr" ? "Right" : "Left"}`]:
                "1px solid #ccc",
              background: "#F3F4F8",
              height: "calc(100% - 64px)",
              boxShadow: `${
                config.direction === "ltr" ? "1" : "-1"
              }px 3px 9px 0px #e4e4e4`,
            }}
            onClick={() => {
              if (window.screen.width < 500) this.props.collapseSideNav();
            }}
          >
            {!settings.is_menu && (
              <>
                {" "}
                <div
                  style={{
                    position: "absolute",
                    left: -90,
                    right: 0,
                    top: 57,
                    zIndex: 2,
                    width: 35,
                    height: 35,
                    borderRadius: "50%",
                    background: "white",
                    margin: "auto",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                    display: sideNavCollapsed ? "none" : "grid",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  onClick={() => this.props.toggleSound()}
                >
                  {this.props.soundEnabled ? (
                    <SpeakerON
                      style={{
                        width: 14,
                        height: 14,
                        fill: "#52c41a",
                      }}
                    />
                  ) : (
                    <SpeakerOFF
                      style={{
                        width: 14,
                        height: 14,
                        fill: "#ff4d4f",
                      }}
                    />
                  )}
                </div>
              </>
            )}

            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 51,
                zIndex: 2,
                width: 45,
                height: 45,
                borderRadius: "50%",
                background: "white",
                margin: "auto",
                border: "1px solid #ccc",
                cursor: "pointer",
                display: sideNavCollapsed ? "none" : "grid",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                visible={this.state.dropdownVisible}
                //onVisibleChange={(e) => {
                //  if (!this.state.dropdownVisible)
                //   this.setState({ dropdownVisible: e });
                //}}
                //trigger={["click", "hover"]}
              >
                <>
                  {this.associatedStoresHaveOrders() && (
                    <span
                      className="dot-red"
                      style={{
                        position: "absolute",
                        top: 3,
                        [config.direction === "rtl" ? "left" : "right"]:
                          config.direction === "rtl" ? 1 : -2,
                      }}
                    ></span>
                  )}
                  {this.props.userPhoto ? (
                    <img
                      src={this.props.userPhoto}
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: "50%",
                        border: "1px solid #ccc",
                      }}
                      onClick={() => {
                        this.setState({
                          dropdownVisible: !this.state.dropdownVisible,
                        });
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: 45,
                        height: 45,
                        display: "grid",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        this.setState({
                          dropdownVisible: !this.state.dropdownVisible,
                        });
                      }}
                    >
                      <CustomersIcon style={{ width: 14, height: 14 }} />
                    </div>
                  )}
                </>
              </Dropdown>
            </div>
            {(!settings.linked_to_store ||
              settings.linked_to_store === "foodics.ordable.com") && (
              <div
                style={{
                  position: "absolute",
                  left: 90,
                  right: 0,
                  top: 57,
                  zIndex: 2,
                  width: 35,
                  height: 35,
                  borderRadius: "50%",
                  background: "white",
                  margin: "auto",
                  border: "1px solid #ccc",
                  cursor: "pointer",
                  display: sideNavCollapsed ? "none" : "grid",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={() => {
                  if (this.props.isHelpOpen) {
                    Crisp.chat.hide();
                  } else {
                    Crisp.chat.show();
                    Crisp.chat.open();
                  }
                  this.props.toggleHelp();
                }}
              >
                <SupportIcon style={{ width: 20, height: 20 }} />
              </div>
            )}

            <Divider style={{ width: 120, margin: "5px 0px", marginTop: 40 }} />

            {(!settings.linked_to_store ||
              settings.linked_to_store === "foodics.ordable.com") && (
              <Menu.Item
                className={"MenuItem"}
                key="help"
                style={{
                  margin: 0,
                  backgroundColor: "#006391",
                  ...MenuItem,
                }}
                onClick={() => {
                  if (this.props.isHelpOpen) {
                    Crisp.chat.hide();
                  } else {
                    Crisp.chat.show();
                    Crisp.chat.open();
                  }
                  this.props.toggleHelp();
                }}
              >
                <HelpIcon style={{ ...IconStyleB, bottom: 0 }} />
                <span
                  className="no-user-selection"
                  style={{
                    color: this.props.isHelpOpen && "#47B02C",
                    position: "relative",
                    top: 1,
                  }}
                >
                  {t(this.props.isHelpOpen ? "Hide chat" : "Chat Support")}
                </span>
              </Menu.Item>
            )}

            <Divider style={{ width: 120, margin: "5px 0px" }} />

            {permissions.can_only_view_own_orders ? (
              <>
                <Menu.Item
                  className="MenuItem home-menu-item"
                  key="home"
                  style={{
                    margin: 0,
                    marginTop: 10,
                    backgroundColor: "#fff",
                    ...MenuItem,
                  }}
                  onClick={() => this.props.history.push("/manage/home")}
                >
                  <>
                    <Home style={{ ...IconStyleB }} />
                    {settings.is_menu && <> {t("Home")} </>}
                    {!settings.is_menu && (
                      <Badge
                        dot
                        count={this.props.urgentIssues ? 1 : 0}
                        style={{
                          right: config.direction == "ltr" && -5,
                          left: config.direction == "rtl" && -5,
                        }}
                      >
                        {t("Home")}
                      </Badge>
                    )}
                  </>
                </Menu.Item>
                {settings.enable_pos && permissions.can_create_orders && (
                  <Menu.Item
                    className="MenuItem pos-menu-item"
                    key="pos"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => this.props.history.push("/manage/pos")}
                  >
                    <POS style={{ ...IconStyleB }} />

                    <span>{t("POS")}</span>
                  </Menu.Item>
                )}
                {!settings.is_menu && (
                  <SubMenu
                    className="SubMenu orders-menu-item"
                    key="subOrders"
                    title={
                      <>
                        <Invoice style={{ ...IconStyleB }} />
                        <Badge
                          dot
                          count={
                            this.props.bellHasNewOrders ||
                            this.props.bellHasNewBusinessOrders
                              ? 1
                              : 0
                          }
                          style={{
                            right: config.direction == "ltr" && -5,
                            left: config.direction == "rtl" && -5,
                          }}
                        >
                          {t("Orders")}
                        </Badge>
                      </>
                    }
                    style={{
                      backgroundColor: "#006391",
                      margin: 0,
                      ...MenuItem,
                    }}
                  >
                    {!settings.is_menu &&
                      (settings.enable_b2c || settings.enable_pos) && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="1"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/orders")
                          }
                        >
                          <Badge
                            dot
                            count={this.props.bellHasNewOrders ? 1 : 0}
                            style={{
                              right: config.direction == "ltr" && -8,
                              left: config.direction == "rtl" && -8,
                              top: 8,
                            }}
                          >
                            <span>{t("Online")}</span>
                          </Badge>
                        </Menu.Item>
                      )}
                    {settings.enable_businesses &&
                      permissions.can_view_business_orders &&
                      !settings.is_menu &&
                      settings.package !== 0 && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="2"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/business_orders")
                          }
                        >
                          <Badge
                            dot
                            count={this.props.bellHasNewBusinessOrders ? 1 : 0}
                            style={{
                              right: config.direction == "ltr" && -8,
                              left: config.direction == "rtl" && -8,
                            }}
                          >
                            <span>{t("BusinessOrders")}</span>
                          </Badge>
                        </Menu.Item>
                      )}

                    {!settings.is_menu &&
                      settings.enable_b2c &&
                      permissions.can_view_pending_orders && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="pending"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/pending")
                          }
                        >
                          <span>{t("Abandoned")}</span>
                        </Menu.Item>
                      )}
                  </SubMenu>
                )}
              </>
            ) : (
              <>
                <Menu.Item
                  className="MenuItem home-menu-item"
                  key="home"
                  style={{
                    margin: 0,
                    marginTop: 10,
                    backgroundColor: "#fff",
                    ...MenuItem,
                  }}
                  onClick={() => this.props.history.push("/manage/home")}
                >
                  <>
                    <Home style={{ ...IconStyleB }} />
                    {settings.is_menu && <> {t("Home")} </>}
                    {!settings.is_menu && (
                      <Badge
                        dot
                        count={this.props.urgentIssues ? 1 : 0}
                        style={{
                          right: config.direction == "ltr" && -5,
                          left: config.direction == "rtl" && -5,
                        }}
                      >
                        {t("Home")}
                      </Badge>
                    )}
                  </>
                </Menu.Item>
                {/* <div style={dividerStyle}></div> */}
                {/* <p style={sectionTitlesStyle}>{t("Sales Channels")}</p> */}
                {settings.enable_pos && permissions.can_create_orders && (
                  <Menu.Item
                    className="MenuItem pos-menu-item"
                    key="pos"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => this.props.history.push("/manage/pos")}
                  >
                    <POS style={{ ...IconStyleB }} />

                    <span>{t("POS")}</span>
                  </Menu.Item>
                )}
                <Menu.Item
                  className="MenuItem branches-menu-item"
                  key="4"
                  style={{ margin: 0, backgroundColor: "#006391", ...MenuItem }}
                  onClick={() => this.props.history.push("/manage/locations")}
                >
                  <BranchesIcon style={{ ...IconStyleB }} />
                  <span>{t("Branches")}</span>
                </Menu.Item>
                {settings.enable_driver && permissions.can_view_driver && (
                  <Menu.Item
                    className="MenuItem branches-menu-item"
                    key="drivers"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => this.props.history.push("/manage/drivers")}
                  >
                    <DriverIcon
                      style={{
                        ...IconStyleB,
                        height: "22px",
                        width: "22px",
                        marginLeft:
                          config.direction === "ltr" ? "-2px" : "10px",
                        marginRight:
                          config.direction === "rtl" ? "-2px" : "10px",
                        transform:
                          config.direction === "rtl" ? "rotateY(180deg)" : "",
                      }}
                    />
                    <span>{t("Drivers")}</span>
                  </Menu.Item>
                )}
                {settings.enable_table_qr && (
                  <Menu.Item
                    className="MenuItem settings-menu-item"
                    key="tables"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => this.props.history.push("/manage/tables")}
                  >
                    <Tables style={{ ...IconStyleB }} />
                    <span>{t("Dine-In")}</span>
                  </Menu.Item>
                )}
                {settings.enable_businesses && !settings.is_menu && (
                  <Menu.Item
                    className="MenuItem businesses-menu-item"
                    key="18"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() =>
                      this.props.history.push("/manage/businesses")
                    }
                  >
                    <Briefcase style={{ ...IconStyleB }} />
                    <span>{t("Businesses")}</span>
                  </Menu.Item>
                )}
                {/* <div style={dividerStyle}></div> */}
                {/* <p style={sectionTitlesStyle}>{t("Manage")}</p> */}
                <Menu.Item
                  className="MenuItem products-menu-item"
                  key="3"
                  style={{ margin: 0, backgroundColor: "#006391", ...MenuItem }}
                  onClick={() => this.props.history.push("/manage/products")}
                >
                  <Bag style={{ ...IconStyleB }} />
                  <span>{t("Products")}</span>
                </Menu.Item>
                {settings.enable_waiting_lists && (
                  <Menu.Item
                    className="MenuItem products-menu-item"
                    key="waiting-lists"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => this.props.history.push("/manage/waitlists")}
                  >
                    <WaitingListIcon style={{ ...IconStyleB }} />
                    <span>{t("Waiting Lists")}</span>
                  </Menu.Item>
                )}
                {!settings.is_menu && (
                  <SubMenu
                    className="SubMenu orders-menu-item"
                    key="subOrders"
                    title={
                      <>
                        <Invoice style={{ ...IconStyleB }} />
                        <Badge
                          dot
                          count={
                            this.props.bellHasNewOrders ||
                            this.props.bellHasNewBusinessOrders
                              ? 1
                              : 0
                          }
                          style={{
                            right: config.direction == "ltr" && -5,
                            left: config.direction == "rtl" && -5,
                          }}
                        >
                          {t("Orders")}
                        </Badge>
                      </>
                    }
                    style={{
                      backgroundColor: "#006391",
                      margin: 0,
                      ...MenuItem,
                    }}
                  >
                    {!settings.is_menu &&
                      (settings.enable_b2c || settings.enable_pos) && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="1"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/orders")
                          }
                        >
                          <Badge
                            dot
                            count={this.props.bellHasNewOrders ? 1 : 0}
                            style={{
                              right: config.direction == "ltr" && -8,
                              left: config.direction == "rtl" && -8,
                              top: 8,
                            }}
                          >
                            <span>{t("Online")}</span>
                          </Badge>
                        </Menu.Item>
                      )}
                    {settings.enable_businesses &&
                      permissions.can_view_business_orders &&
                      !settings.is_menu &&
                      settings.package !== 0 && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="2"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/business_orders")
                          }
                        >
                          <Badge
                            dot
                            count={this.props.bellHasNewBusinessOrders ? 1 : 0}
                            style={{
                              right: config.direction == "ltr" && -8,
                              left: config.direction == "rtl" && -8,
                            }}
                          >
                            <span>{t("BusinessOrders")}</span>
                          </Badge>
                        </Menu.Item>
                      )}

                    {!settings.is_menu &&
                      settings.enable_b2c &&
                      permissions.can_view_pending_orders && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="pending"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/pending")
                          }
                        >
                          <span>{t("Abandoned")}</span>
                        </Menu.Item>
                      )}
                  </SubMenu>
                )}
                {(permissions.can_view_customers ||
                  settings.enable_survey ||
                  (settings.enable_feedback &&
                    permissions.can_view_feedbacks)) && (
                  <SubMenu
                    className="SubMenu customers-menu-item"
                    key="subCustomers"
                    title={
                      <>
                        <Badge
                          dot
                          count={feedback_exists ? 1 : 0}
                          style={{
                            right: config.direction == "ltr" && -5,
                            left: config.direction == "rtl" && -5,
                            top: 0,
                          }}
                        >
                          <CustomersIcon style={{ ...IconStyleB }} />

                          {t("Customers")}
                        </Badge>
                      </>
                    }
                    style={{
                      backgroundColor: "#006391",
                      margin: 0,
                      ...MenuItem,
                    }}
                  >
                    {!settings.is_menu && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="24"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/customers")
                        }
                      >
                        <span>{t("Customer List")}</span>
                      </Menu.Item>
                    )}

                    {settings.enable_social_auth_staff && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="users"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() => this.props.history.push("/manage/users")}
                      >
                        <span>{t("Users List")}</span>
                      </Menu.Item>
                    )}
                    {settings.enable_wallet_staff && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="topups"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/Topups")
                        }
                      >
                        <span>{t("Top-ups")}</span>
                      </Menu.Item>
                    )}

                    {settings.enable_feedback &&
                      permissions.can_view_feedbacks && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="23"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/feedback")
                          }
                        >
                          <Badge
                            dot
                            count={feedback_exists ? 1 : 0}
                            style={{
                              right: config.direction == "ltr" && -8,
                              left: config.direction == "rtl" && -8,
                              top: 8,
                            }}
                          >
                            <span>{t("Feedback")}</span>
                          </Badge>
                        </Menu.Item>
                      )}
                    {!settings.is_menu && settings.enable_survey && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="survey-list"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/survey")
                        }
                      >
                        <span>{t("Survey")}</span>
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}
                {(settings.enable_resource_based_booking &&
                  !settings.is_menu) ||
                (settings.enable_booking &&
                  !settings.is_menu &&
                  settings.package !== 0) ? (
                  <SubMenu
                    className="SubMenu bookings-menu-item"
                    key="subBooking"
                    title={
                      <Badge
                        dot
                        count={this.props.showCustomerBookingBadge ? 1 : 0}
                        style={{
                          right: config.direction == "ltr" && -8,
                          left: config.direction == "rtl" && -8,
                        }}
                      >
                        <BookingIcon style={{ ...IconStyleB }} />

                        {t("Booking")}
                      </Badge>
                    }
                    style={{
                      backgroundColor: "#006391",
                      margin: 0,
                      ...MenuItem,
                    }}
                  >
                    {settings.enable_booking &&
                      !settings.is_menu &&
                      settings.package !== 0 && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="events"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/bookings")
                          }
                        >
                          <span>{t("Events")}</span>
                        </Menu.Item>
                      )}

                    {settings.enable_booking &&
                      settings.enable_attendance_tracking &&
                      !settings.is_menu &&
                      settings.package !== 0 && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="scanqr"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.setState({ scanDrawerOpen: true })
                          }
                        >
                          <span>{t("Take attendance")}</span>
                        </Menu.Item>
                      )}

                    {settings.enable_resource_based_booking &&
                      !settings.is_menu && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="appointments"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/services")
                          }
                        >
                          <span>{t("Appointments")}</span>
                        </Menu.Item>
                      )}

                    {settings.enable_resource_based_booking &&
                      !settings.is_menu && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="providers"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/ServiceProviders")
                          }
                        >
                          <span>{t("Providers")}</span>
                        </Menu.Item>
                      )}
                  </SubMenu>
                ) : null}

                <SubMenu
                  className="SubMenu reports-menu-item"
                  key="sub0"
                  title={
                    <Badge
                      dot
                      count={this.props.showDownloadsBadge ? 1 : 0}
                      style={{
                        right: config.direction == "ltr" && -5,
                        left: config.direction == "rtl" && -5,
                      }}
                    >
                      <Pie style={{ ...IconStyleB }} />

                      {t("Reports")}
                    </Badge>
                  }
                  style={{ backgroundColor: "#006391", margin: 0, ...MenuItem }}
                >
                  {!settings.is_menu && (
                    <>
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="sales"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/reports/sreports")
                        }
                      >
                        <span>{t("Sales")}</span>
                      </Menu.Item>

                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="26"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/reports/customers")
                        }
                      >
                        <span>{t("Customers")}</span>
                      </Menu.Item>
                      {settings.enable_table_qr && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="tables"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/reports/tables")
                          }
                        >
                          <span>{t("Tables")}</span>
                        </Menu.Item>
                      )}
                    </>
                  )}
                  <Menu.Item
                    className={
                      config.direction === "ltr"
                        ? "SubMenuItem"
                        : "SubMenuItemAr"
                    }
                    key="20"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() =>
                      this.props.history.push("/manage/reports/downloads")
                    }
                  >
                    <Badge
                      dot
                      count={this.props.showDownloadsBadge ? 1 : 0}
                      style={{
                        right: config.direction == "ltr" && -8,
                        left: config.direction == "rtl" && -8,
                      }}
                    >
                      <span>{t("Downloads")}</span>
                    </Badge>
                  </Menu.Item>
                </SubMenu>

                {(((settings.enable_promotions || settings.enable_coupons) &&
                  permissions.can_view_promotions &&
                  !settings.is_menu) ||
                  permissions.can_view_social_section) && (
                  <SubMenu
                    className="SubMenu marketing-menu-item"
                    key="sub_promotions"
                    title={
                      <>
                        <Marketing style={{ ...IconStyleB }} />
                        {t("Marketing")}
                      </>
                    }
                    style={{
                      backgroundColor: "#006391",
                      margin: 0,
                      ...MenuItem,
                    }}
                  >
                    {settings.enable_coupons && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="coupons"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/coupons")
                        }
                      >
                        <span>{t("Coupons")}</span>
                      </Menu.Item>
                    )}
                    {settings.enable_promotions && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="13"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/promotions")
                        }
                      >
                        <span>{t("Promotions")}</span>
                      </Menu.Item>
                    )}

                    {settings.enable_popup_banner_staff && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="popupBanner"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push(
                            "/manage/appearance/PopupBanner"
                          )
                        }
                      >
                        <span>{t("Popup Banner")}</span>
                      </Menu.Item>
                    )}

                    {permissions.can_view_social_section && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="17"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push(
                            "/manage/Integration/Marketing"
                          )
                        }
                      >
                        <span>{t("Social")}</span>
                      </Menu.Item>
                    )}

                    {settings.enable_promotions &&
                      permissions.can_view_customers &&
                      permissions.can_create_promotions && (
                        <Menu.Item
                          className={
                            config.direction === "ltr"
                              ? "SubMenuItem"
                              : "SubMenuItemAr"
                          }
                          key="25"
                          style={{
                            margin: 0,
                            backgroundColor: "#006391",
                            ...MenuItem,
                          }}
                          onClick={() =>
                            this.props.history.push("/manage/raffle")
                          }
                        >
                          <span>{t("Raffle")}</span>
                        </Menu.Item>
                      )}

                    {settings.enable_abandoned_carts && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="abandoned"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/abandoned")
                        }
                      >
                        <span>{t("Abandoned Carts")}</span>
                      </Menu.Item>
                    )}

                    {settings.enable_sms_campaigns && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="abandoned"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/campaign")
                        }
                      >
                        <span>{t("SMS Campaigns")}</span>
                      </Menu.Item>
                    )}

                    {settings.has_mobile_app && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="mobileNotifications"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/MobileNotifications")
                        }
                      >
                        <span>{t("Mobile Notifications")}</span>
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}
                <Menu.Item
                  className="MenuItem settings-menu-item"
                  key="settings"
                  style={{ margin: 0, backgroundColor: "#006391", ...MenuItem }}
                  onClick={() => this.props.history.push("/manage/settings")}
                >
                  <Settings style={{ ...IconStyleB }} />
                  <span>{t("Settings")}</span>
                </Menu.Item>
                {!settings.is_menu &&
                  (!settings.created_by_reseller ||
                    settings.linked_to_store === "foodics.ordable.com") &&
                  settings.base_country !== "Jordan" && (
                    <>
                      {/* <div style={dividerStyle}></div> */}
                      {/* <p style={sectionTitlesStyle}>{t("Add-on")}</p> */}
                      <Menu.Item
                        className="MenuItem addons-menu-item"
                        key="addons"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push("/manage/addons")
                        }
                      >
                        <AddOnsIcon style={{ ...IconStyleB }} />
                        <span>{t("Add-Ons")}</span>
                      </Menu.Item>
                    </>
                  )}
                {!settings.is_pos && (
                  <Menu.Item
                    className="MenuItem"
                    key="openStore"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() =>
                      window.open(`https://${settings.domain}/`, "_blank")
                    }
                  >
                    <ExternalIcon style={{ ...IconStyleB }} />
                    <span>{t("Open store")}</span>
                  </Menu.Item>
                )}
                {/* <SubMenu
            className="SubMenu"
            key="subHelp"
            title={t("Help")}
            style={{ backgroundColor: "#006391", margin: 0, ...MenuItem }}
          >
            <Menu.Item
              className={
                config.direction === "ltr" ? "SubMenuItem" : "SubMenuItemAr"
              }
              key="help"
              style={{ margin: 0, backgroundColor: "#006391", ...MenuItem }}
              onClick={() => {
                if (window.Tawk_API.isChatHidden()) {
                  window.Tawk_API.showWidget();
                  window.Tawk_API.maximize();
                } else {
                  window.Tawk_API.minimize();
                  window.Tawk_API.hideWidget();
                }
                this.setState({ isHelpOpen: !this.state.isHelpOpen });
              }}
            >
              <span>
                {!this.state.isHelpOpen ? t("Live chat") : t("Hide live chat")}
              </span>
            </Menu.Item> */}
                {(!settings.linked_to_store ||
                  settings.linked_to_store === "foodics.ordable.com") && (
                  <Menu.Item
                    className={"MenuItem"}
                    key="faqs"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => {
                      this.props.history.push("/manage/faqs");
                    }}
                  >
                    <QuestionIcon style={{ ...IconStyleB, bottom: 0 }} />
                    <span>{t("Q&A")}</span>
                  </Menu.Item>
                )}

                {/* </SubMenu> */}
                {this.props.is_staff && (
                  <Menu.Item
                    className="MenuItem"
                    key="99"
                    style={{
                      margin: 0,
                      backgroundColor: "#006391",
                      ...MenuItem,
                    }}
                    onClick={() => this.props.history.push("/manage/staff")}
                  >
                    <span>{t("Staff")}</span>
                  </Menu.Item>
                )}
                {this.props.is_staff && (
                  <SubMenu
                    className="SubMenu"
                    key="sub5"
                    title={
                      <Badge
                        dot
                        count={this.props.showDownloadsBadge ? 1 : 0}
                        style={{
                          right: config.direction == "ltr" && -5,
                          left: config.direction == "rtl" && -5,
                        }}
                      >
                        {t("Import")}
                      </Badge>
                    }
                    style={{
                      backgroundColor: "#006391",
                      margin: 0,
                      ...MenuItem,
                    }}
                  >
                    {(this.props.username.includes("salem") ||
                      this.props.username.includes("Naser") ||
                      this.props.username.includes("khara") ||
                      this.props.username.includes("mona")) && (
                      <Menu.Item
                        className={
                          config.direction === "ltr"
                            ? "SubMenuItem"
                            : "SubMenuItemAr"
                        }
                        key="import-zyda"
                        style={{
                          margin: 0,
                          backgroundColor: "#006391",
                          ...MenuItem,
                        }}
                        onClick={() =>
                          this.props.history.push(
                            "/manage/import/customers/zyda"
                          )
                        }
                      >
                        <span>{t("Zyda Customers")}</span>
                      </Menu.Item>
                    )}

                    <Menu.Item
                      className={
                        config.direction === "ltr"
                          ? "SubMenuItem"
                          : "SubMenuItemAr"
                      }
                      key="import-products"
                      style={{
                        margin: 0,
                        backgroundColor: "#006391",
                        ...MenuItem,
                      }}
                      onClick={() =>
                        this.props.history.push(
                          "/manage/import/products/template"
                        )
                      }
                    >
                      <span>{t("Products")}</span>
                    </Menu.Item>
                    <Menu.Item
                      className={
                        config.direction === "ltr"
                          ? "SubMenuItem"
                          : "SubMenuItemAr"
                      }
                      key="import-customers-t"
                      style={{
                        margin: 0,
                        backgroundColor: "#006391",
                        ...MenuItem,
                      }}
                      onClick={() =>
                        this.props.history.push(
                          "/manage/import/customers/template"
                        )
                      }
                    >
                      <span>{t("Customers")}</span>
                    </Menu.Item>
                    <Menu.Item
                      className={
                        config.direction === "ltr"
                          ? "SubMenuItem"
                          : "SubMenuItemAr"
                      }
                      key="import-customers"
                      style={{
                        margin: 0,
                        backgroundColor: "#006391",
                        ...MenuItem,
                      }}
                      onClick={() =>
                        this.props.history.push("/manage/import/areas/template")
                      }
                    >
                      <span>{t("Delivery areas")}</span>
                    </Menu.Item>
                  </SubMenu>
                )}
              </>
            )}
          </Menu>
        </Sider>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.auth.settings,
    permissions: state.auth.permissions,
    config: state.config,
    username: state.auth.username,
    soundEnabled: state.orders.soundEnabled,
    is_staff: state.auth.is_staff,
    bellHasNewOrders: state.orders.bellHasNewOrders,
    bellHasNewBusinessOrders: state.businessOrders.bellHasNewBusinessOrders,
    feedback_exists: state.feedbacks.feedback_exists,
    showCustomerBookingBadge: state.customerBookings.newItem,
    showServiceBookingBadge: state.serviceBookings.newItem,
    showDownloadsBadge: state.reports.newDownloadItems.length,
    hideHeader: state.pos.hideHeader,
    sideNavCollapsed: state.pos.sideNavCollapsed,
    info: state.account.info,
    urgentIssues:
      state.health?.issues?.filter((i) => i.urgency === 1)?.length || false,
    userPhoto: state.profile.profile?.photo,
    associated_stores: JSON.parse(JSON.stringify(state.auth.associated_stores)),
    profile: state.profile,
    isHelpOpen: state.orders.isHelpOpen,
    bellHasNewAggregate: state.orders.bellHasNewAggregate,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleSound: () => dispatch(toggleSound()),
    collapseSideNav: () => dispatch(collapseSideNav()),
    switchLanguage: (language) => dispatch(switchLanguage(language)),
    logout: () => dispatch(logout()),
    toggleHelp: () => dispatch({ type: "TOGGLE_HELP" }),
    updateAssociatedStore: (domain, count) =>
      dispatch({
        type: "UPDATE_ASSOCIATED_STORE",
        payload: { domain, count },
      }),
    setNewOrderBellAggregate: (v) =>
      dispatch({ type: "SET_NEW_ORDER_BELL_AGGREGATE", payload: v }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(SideNav)));
