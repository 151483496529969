const trans = {
  "Distributor created successfully": "تم انشاء الشركة بنجاح",
  "Modified products successfully": "تم تعديل المنتجات بنجاح",
  "Deleted branches successfully": "تم حذف الأفرع بنجاح",
  "Branch created successfully": "تم انشاء الفرع بنجاح",
  "Branch edited successfully": "تم حفظ الفرع بنجاح",
  "Invalid phone number": "رقم الهاتف غير صحيح",
  "A branch with this phone number already exists":
    "يوجد فرع آخر بنفس رقم الهاتف",
};

export default trans;
