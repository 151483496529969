import {
  LIMIT_CONTROL,
  FETCH_ORDER_LIMTIS,
  FETCH_ORDER_LIMTIS_LOADING,
} from "../actions/actionTypes";

const initialState = {
  dayLimits: null,
  loading: false,
};

export default (state = initialState, { type, payload, asyncDispatch }) => {
  switch (type) {
    case LIMIT_CONTROL:
      let newDayLimits = state.dayLimits;
      let index = -1;

      if (payload.actionType == "toggle") {
        index = newDayLimits.findIndex((slot) => slot.id == payload.sid);
        newDayLimits[index].is_active = !newDayLimits[index].is_active;
      } else if (payload.actionType == "delete") {
        newDayLimits = newDayLimits.filter((slot) => slot.id != payload.sid);
      } else if (payload.actionType == "edit max") {
        index = newDayLimits.findIndex((slot) => slot.id == payload.sid);
        newDayLimits[index].max = payload.max;
      }

      return {
        ...state,
        dayLimits: newDayLimits,
      };

    case FETCH_ORDER_LIMTIS:
      return { ...state, loading: false, dayLimits: payload.limits };

    case FETCH_ORDER_LIMTIS_LOADING:
      return { ...state, loading: true };

    default:
      return state;
  }
};
