import React, { Component } from "react";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

class index extends Component {
  state = {
    steps: [
      {
        target: ".home-menu-item",
        content:
          "Home - A quick reference for an overview of your ordable/ shop",
      },
      {
        target: ".pos-menu-item",
        content: "POS - Accept orders from customers",
      },
      {
        target: ".branches-menu-itemm",
        content:
          "Set up your branches and configure them to match your business details",
      },
      {
        target: ".businesses-menu-item",
        content:
          "Manage the business you sell your products to and their details",
      },
      {
        target: ".products-menu-item",
        content:
          "Products - Manage your catalog including categories, modifiers, options, stock, and more",
      },

      {
        target: ".orders-menu-item",
        content: "Orders - Accept and manage your orders",
      },

      {
        target: ".customers-menu-item",
        content:
          "Customers - See everything related to your customers including feedback, addresses, numbers, and orders",
      },
      {
        target: ".bookings-menu-item",
        content: "Bookings - View and manage bookings your customers have made",
      },
      {
        target: ".reports-menu-item",
        content:
          "Reports - Learn about how your business is doing using reports on Sales, Customers, Segments, and more",
      },
      {
        target: ".marketing-menu-item",
        content:
          "Marketing - Run campaigns and promotions to boost sales online",
      },
      {
        target: ".settings-menu-item",
        content:
          "Settings - Change different settings related to your store including themes, delivery, permissions, and more",
      },
      {
        target: ".billing-menu-item",
        content:
          "Billing - You can manage your subscription details from the billing section",
      },
      {
        target: ".addons-menu-item",
        content:
          "Add-on's - Add and manage features in your shop to help you grow and manage your business online",
      },
    ],
  };

  handleJoyrideCallback = (e) => {
    if (e.action === "reset") {
      this.props.toggleRunTutorial();
    }
  };
  render() {
    const { t, config } = this.props;
    const steps = this.state.steps.map((step) => ({
      ...step,
      content: t(step.content),
      disableBeacon: true,
    }));
    return (
      <div>
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.props.runTutorial}
          steps={steps}
          styles={{
            options: {
              arrowColor: "white",
              backgroundColor: "white",
              primaryColor: "#0099cc",
              textColor: "#555",
              zIndex: 1000,
            },
            tooltip: { direction: config.direction },
            buttonSkip: {
              float: config.direction === "rtl" ? "right" : "left",
            },
          }}
          showSkipButton={true}
          //showProgress={true}
          scrollToFirstStep={true}
          continuous={true}
          locale={{
            back: t("Previous"),
            next: t("Next"),
            skip: t("Skip"),
            last: t("Done"),
          }}
          disableOverlayClose={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.auth.settings,
    config: state.config,
    runTutorial: state.config.runTutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRunTutorial: () => dispatch({ type: "TOGGLE_RUN_TUTORIAL" }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(index)));
