const tr = {
  "Waiting Lists": "لوائح الإنتظار",
  "Waiting list was deleted successfully": "تم حذف لائحة الإنتظار بنجاح",
  "New waiting list": "اضف لائحة إنتظار",
  "Search for a waiting list": "إبحث عن لائحة إنتظار",
  "Are you sure you want to delete this waiting list?":
    "هل انت متأكد من حذف لائحة الإنتظار؟",
  "Waiting list created successfully": "تم إنشاء لائحة اللإنتظار بنجاح",
  "New Waiting List": "اضف لائحة إنتظار",
  "Edit Waiting List": "تعديل لائحة الإنتظار",
  "Customer's Name": "اسم الزبون",
  "Number of people": "عدد الأشخاص",
  "Customer was added to the waiting list successfully":
    "تمت إضافة الزبون الى قائمة الإنتظار بنجاح",
  "Customer is already on the waiting list": "الزبون موجود في قائمة الإنتظار",
  Pregnant: "حامل",
  "Special Needs": "ذوي الاحتياجات الخاصة",
  "Waiting Time": "وقت الإنتظار",
  Rank: "مرتبة",
  "Change Rank": "تغير المرتبة",
  "You don't have any waiting lists": "ليس لديك لوائح إنتظار",
  "Are you sure you want to delete this waiting list? You can't undo this":
    "هل انت متأكد من حذف لائحة الإنتظار؟ لا يمكنك الرجوع",
  "Edit Waiting List": "تعديل لائحة الإنتظار",
  "Waiting List updated successfully": "تم تحديث لائحة الإنتظار بنجاح",
  "Manage Waiting List": "تحكم في قائمة الإنتظار",
  Activate: "تفعيل",
  Deactivate: "تعطيل",
  "Managing Waiting List": "التحكم في قائمة إنتظار",
  "New customer": "أضف زبون",
  "New Customer": "أضف زبون",
  "Search for a customer": "إبحث عن زبون",
  "Edit Entry for": "تعديل سجل",
  Waiting: "إنتظار",
  Notified: "تم الإعلام",
  Served: "تمت الخدمة",
  Cancelled: "إلغاء",
  waiting: "إنتظار",
  notified: "تم الإعلام",
  served: "تمت الخدمة",
  cancelled: "إلغاء",
  "Customer entry was updated successfully": "تم تعديل دخول الزبون بنجاح",
  "Created Date": "تاريخ الدخول",
  "Modified Date": "تاريخ التعديل",
  "Tracking ID": "رمز التتبع",
  Mins: "دقيقة",
  "customers selected": "زبون محدد",
  "Elapsed Time": "الوقت المنقضي",
  "waiting customers": "زبون بنتظر",
  "served customers": "زبون تمت خدمتة",
  "notified customers": "زبون تم إعلامه",
  "cancelled customers": "زبون تم إلغائه",
  "Actions on selected customers": "التحكم في الزبائن المحددين",
  "Maximum Number of Seats": "العدد الاٌقصى للمقاعد",
};

export default tr;
