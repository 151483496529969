import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
//Ant
import { Input } from "antd";

export class ProductTextField extends Component {
  render() {
    const { config, extraField, selectedExtraField, key1, group } = this.props;
    const value = selectedExtraField?.value || "";
    return (
      <div className="mt-3 mb-4">
        <h4
          className="mb-3 mt-2 mx-1 border-top border-bottom p-3"
          style={{
            fontSize: "1rem",
          }}
        >
          {config.language === "arabic"
            ? group
              ? group.ar_name + " - " + extraField.ar_name
              : extraField.ar_name
            : group
            ? group.name + " - " + extraField.name
            : extraField.name}
        </h4>
        <Input
          value={value}
          placeholder={
            (config.language === "arabic" && extraField.ar_name) ||
            extraField.name
          }
          onChange={(e) =>
            this.props.addExtraFieldsToProduct(
              extraField,
              e.target.value.trimStart(),
              key1
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    usedComponents: state.pos.usedComponents,
  };
};

export default connect(mapStateToProps)(withNamespaces()(ProductTextField));
