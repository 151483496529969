import React, { Component } from "react";
import { Result } from "antd";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

class NoAccess extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Result status="403" title="403" subTitle={t("403")} />
      </div>
    );
  }
}

export default connect()(withNamespaces()(NoAccess));
