import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
import moment from "moment";

// Antd Components
import { Modal, message, Table, Spin, Dropdown, Button, Menu } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import NewPaymentModal from "./NewPaymentModal";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Column } = Table;

// Actions

class OrderPaymentsModal extends Component {
  state = { loading: true, payments: [], newPaymentModalVisible: false };
  componentDidMount = async () => {
    this.setState({ loading: true });
    const { t, order } = this.props;
    try {
      const res = await instance.post("/get_order_payments/", {
        id: order.id,
      });
      this.setState({ payments: res.data });
    } catch (err) {
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      } else {
        message.error(t("SomethingWentWrong"));
      }
    }
    this.setState({ loading: false });
  };
  renderColumnDate = (text) => {
    if (!text) return "-";
    return <div>{moment(text).format("DD-MM-YYYY  hh:mm a")}</div>;
  };

  renderColumnPaymentStatus = (text, record) => {
    const { t } = this.props;
    if (record.refund_id) return <div>{t("Refunded")}</div>;
    return <div>{(text && <>{t("Paid")}</>) || t("Unpaid")}</div>;
  };
  editPayment = async (record, action) => {
    const { t } = this.props;
    const newPayments = this.state.payments;

    try {
      newPayments[record.index].loading = true;
      this.setState({ payments: newPayments });

      const res = await instance.post(`/edit_order_payment/`, {
        pid: record.id,
        action: action,
      });

      newPayments[record.index] = res.data;
      this.setState({ payments: newPayments });

      message.success(t("Edited successfully"));
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      newPayments[record.index].loading = false;
      this.setState({ payments: newPayments });
    }
  };
  actionsOverlay = (record) => {
    const { t, config } = this.props;
    return (
      <Menu>
        <Menu.Item
          className={config.direction == "ltr" ? "mr-1" : "ml-1"}
          type="link"
          onClick={() =>
            this.editPayment(
              record,
              record.payment_complete ? "mark_unpaid" : "mark_paid"
            )
          }
        >
          {record.payment_complete ? t("Mark unpaid") : t("Mark paid")}
        </Menu.Item>
      </Menu>
    );
  };

  renderColumnActions = (text, record) => {
    const { t, config } = this.props;
    const OfflinePaymentsMethods = [
      "cash",
      "kod",
      "benefit-transfer",
      "pos",
      "payment days",
    ];
    const isOffline = OfflinePaymentsMethods.includes(record.payment_method);
    return (
      <Dropdown
        trigger={["click", "hover"]}
        overlay={() => this.actionsOverlay(record)}
        disabled={!isOffline}
      >
        <Button
          loading={record.loading}
          className={"remove-float margin-top-action"}
          onClick={(e) => e.preventDefault()}
          style={{
            float: config.direction == "ltr" ? "right" : "left",
          }}
          disabled={!isOffline}
        >
          {t("Actions")}
          <PlusOutlined />
        </Button>
      </Dropdown>
    );
  };

  render() {
    const { visible, config, t, order, settings } = this.props;
    const { payments, loading, newPaymentModalVisible } = this.state;
    const total = payments.reduce((p, a) => p + a.amount, 0);
    const paidTotal = payments
      .filter((a) => a.payment_complete && !a.refund_id)
      .reduce((p, a) => p + a.amount, 0);
    return (
      <div>
        <div>
          <p>{t("Payments")}</p>
          <div className="mb-3">
            {loading ? (
              <div className="mt-3 text-center">
                <Spin indicator={antIcon} className="mx-2" />
              </div>
            ) : (
              <>
                {/* <Button
                  className={`mb-3 ${
                    config.language === "arabic" ? "float-left" : "float-right"
                  }`}
                  type="primary"
                  icon={
                    <PlusOutlined style={{ position: "relative", top: -2 }} />
                  }
                  onClick={() =>
                    this.setState({ newPaymentModalVisible: true })
                  }
                >
                  {t("New payment")}
                </Button>
                {newPaymentModalVisible && (
                  <NewPaymentModal
                    visible={newPaymentModalVisible}
                    orderID={order.id}
                    onClose={() => {
                      this.setState({ newPaymentModalVisible: false });
                    }}
                  />
                )} */}

                <Table
                  dataSource={payments.map((p, index) => {
                    return { ...p, index };
                  })}
                  size="small"
                  scroll={{ x: 500 }}
                  rowClassName="text-center"
                  footer={() => (
                    <div>
                      <div>
                        {t("Total")}:{" "}
                        {total.toFixed(settings.currency_decimals)}{" "}
                        {t(settings.currency_english)}
                      </div>
                      <div>
                        {t("Total Paid")}:{" "}
                        <span className="bold">
                          {paidTotal.toFixed(settings.currency_decimals)}{" "}
                          {t(settings.currency_english)}
                        </span>
                      </div>
                    </div>
                  )}
                >
                  <Column
                    title={t("Amount")}
                    key="amount"
                    dataIndex={"amount"}
                    render={(text, record) => (
                      <span>
                        {parseFloat(text).toFixed(settings.currency_decimals)}{" "}
                        {t(settings.currency_english)}
                      </span>
                    )}
                  />
                  <Column
                    title={t("Payment Method")}
                    key="payment_method"
                    dataIndex={"payment_method"}
                  />
                  <Column
                    title={t("Invoice Date")}
                    key="created"
                    dataIndex={"created"}
                    render={this.renderColumnDate}
                  />
                  <Column
                    title={t("Paid Date")}
                    key="paid_date"
                    dataIndex={"paid_date"}
                    render={this.renderColumnDate}
                  />
                  <Column
                    title={t("Payment Status")}
                    key="payment_complete"
                    dataIndex={"payment_complete"}
                    render={this.renderColumnPaymentStatus}
                  />
                  <Column
                    title={t("Actions")}
                    render={this.renderColumnActions}
                  />
                </Table>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OrderPaymentsModal));
