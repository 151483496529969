import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DatePicker, message, Radio } from "antd";
import { withNamespaces } from "react-i18next";
import moment from "moment/moment";
import instance from "../../store/actions/instance";
import AramexShipmentDetail from "../Integration/Delivery/AramexShipmentDetail";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as AramexLogo } from "../../assets/icons/aramex-logo.svg";

// Generic api function created using chatGPT, maybe later use this instead HttpClient.js
const apiCall = async (
  url,
  method = "GET",
  data = null,
  headers = {},
  onSuccess = null,
  onError = null
) => {
  try {
    const options = {
      url,
      method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      data,
    };

    const response = await instance(options);
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Error status:", error.response.status);
      console.error("Error headers:", error.response.headers);

      const errorMessage = `Error: ${error.response.status} ${error.response.statusText} - ${error.response.data}`;

      if (onError) {
        onError(new Error(errorMessage));
      } else {
        throw new Error(errorMessage);
      }
    } else if (error.request) {
      console.error("Error request:", error.request);
      const errorMessage = "No response received from the server.";
      if (onError) {
        onError(new Error(errorMessage));
      } else {
        throw new Error(errorMessage);
      }
    } else {
      console.error("Error message:", error.message);
      const errorMessage = "Error in setting up the request: " + error.message;
      if (onError) {
        onError(new Error(errorMessage));
      } else {
        throw new Error(errorMessage);
      }
    }
  }
};
const Aramex = ({ t, selectedOrder }) => {
  const { permissions, language, direction, orders, settings } = useSelector(
    (state) => ({
      permissions: state.auth.permissions,
      language: state.config.language,
      direction: state.config.direction,
      orders: state.orders,
      settings: state.auth.settings,
    })
  );

  const [branchID, setBranchID] = useState(selectedOrder.obj.for_branch_id);
  const [rateLoading, setRateLoading] = useState(false);
  const [dispatchLoading, setDispatchLoading] = useState(false);
  const [pickupDateTime, setPickupDateTime] = useState(null);
  const [rateDetails, setRateDetails] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [aramexShipmentInfo, setAramexShipmentInfo] = useState(null);
  const [aramexShipmentBoxInfo, setAramexShipmentBoxInfo] = useState(null);
  const [aramexShipmentInfoLoading, setAramexShipmentInfoLoading] =
    useState(false);

  // let orderObj =
  //   orders.orders?.find((o) => o.id === selectedOrder.obj.id) ||
  //   orders.pastOrders?.find((o) => o.id === selectedOrder.obj.id) ||
  //   orders.recentlyReceived?.find((o) => o.id === selectedOrder.obj.id) ||
  //   orders.new_orders?.find((o) => o.id === selectedOrder.obj.id);
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const getCalculateRate = () => {
    if (branchID === null) {
      message.error(t("Please select a branch first"));
      return;
    }
    setRateLoading(true);
    setRateDetails(null);
    setShippingDetails(null);
    apiCall(
      "/aramex/calculate_rate/",
      "POST",
      {
        orderID: selectedOrder.obj.id,
        branch: branchID,
        fromDashboard: true,
      },
      {},
      (data) => {
        if (data.data.HasErrors === true || data.success === false) {
          message.error("Something went wrong. Please try again later");
        } else if (data.data.HasErrors === false) {
          setRateDetails({
            ...data.data.RateDetails,
            ...data.data.TotalAmount,
            total_value: data.data.total_value,
          });

          setShippingDetails({
            ...data.shipping_details,
          });
          message.success("Fetched shipping rate successfully.");
        }
        setRateLoading(false);
      },
      (error) => {
        message.error("Something went wrong. Please try again later");
        console.error("Error:", error.message);
        setRateLoading(false);
        setRateDetails(null);
        setShippingDetails(null);
      }
    );
  };
  const createPickupAndShipment = () => {
    if (branchID === null) {
      message.error(t("Please select a branch first"));
      return;
    }
    setDispatchLoading(true);
    apiCall(
      "/aramex/create_pickup_and_shipment/",
      "POST",
      {
        orderID: selectedOrder.obj.id,
        shipping_id: selectedOrder.obj.shipping__id,
        branch: branchID,
        dateTime: pickupDateTime.format("YYYY-MM-DD HH:mm:ss"),
        fromDashboard: true,
        rateDetails: rateDetails,
      },
      {},
      (data) => {
        if (data.data.HasErrors === true || data.success === false) {
          message.error("Something went wrong. Please try again later");
        } else if (
          data.data.HasErrors === false &&
          data.data?.ProcessedPickup?.ProcessedShipments[0]?.HasErrors === false
        ) {
          message.success("Created shipment successfully");
          setAramexShipmentInfo(data.aramex_shipping_info_data);
        }
        setDispatchLoading(false);
      },
      (error) => {
        message.error("Something went wrong. Please try again later");
        console.error("Error:", error.message);
        setDispatchLoading(false);
      }
    );
  };
  const getShipmentInfo = () => {
    setAramexShipmentInfoLoading(true);
    apiCall(
      "/aramex/get_shipment_info/",
      "POST",
      {
        shipping_id: selectedOrder.obj.shipping__id,
        orderID: selectedOrder.obj.id,
      },
      {},
      (data) => {
        if (data.success && data.data) {
          setAramexShipmentInfo(data.data);
        }
        if (data.success) {
          setAramexShipmentBoxInfo(data.box_details);
        }
        setAramexShipmentInfoLoading(false);
      },
      (error) => {
        setAramexShipmentInfoLoading(false);
      }
    );
  };
  const pickupDateTimeIsInvalid = () => {
    if (!pickupDateTime) return false;
    if (pickupDateTime.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD"))
      return true;
    if (pickupDateTime.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
      if (pickupDateTime.format("HH:mm") < moment().format("HH:mm"))
        return true;
    }
    if (
      pickupDateTime.format("HH:mm") < "09:00" ||
      pickupDateTime.format("HH:mm") > "16:59"
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (aramexShipmentInfo === null) {
      getShipmentInfo();
    }
  }, []);

  if (aramexShipmentInfoLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  }

  return (
    <div>
      <AramexLogo
        style={{
          width: 70,
          height: 70,
          padding: "0px 2px",
          marginRight: 20,
          display: "inline-block",
          float: language === "arabic" ? "left" : "right",
        }}
      />
      {aramexShipmentInfo ? (
        <div>
          <AramexShipmentDetail
            aramexShipmentInfo={aramexShipmentInfo}
            branches={permissions.all_permitted_branches}
            settings={settings}
          />
        </div>
      ) : (
        <div>
          {permissions.all_permitted_branches.length > 1 ? (
            <>
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                1 - {t("Select a branch")}
              </p>
              <Radio.Group
                onChange={(e) => setBranchID(e.target.value)}
                value={branchID}
                className="mb-3"
              >
                {permissions.all_permitted_branches.map((branch) => (
                  <Radio.Button value={branch.id}>
                    {language === "arabic" ? branch.ar_name : branch.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </>
          ) : null}
          <div>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              2 - {t("Get a quote to view details about the shipment")}
            </p>
            <div className="my-3">
              <Button onClick={() => getCalculateRate()} loading={rateLoading}>
                {t("Get quote")}
              </Button>

              {rateDetails && shippingDetails && (
                <div className={"mt-3"}>
                  <p className="mb-0">
                    <span
                      style={{
                        fontWeight: "bold",
                        width: 200,
                        display: "inline-block",
                      }}
                    >
                      {t("Shipping charge:")}
                    </span>
                    {rateDetails.total_value.toFixed(
                      settings.currency_decimals
                    )}{" "}
                    {rateDetails.CurrencyCode}
                  </p>
                </div>
              )}
            </div>
            <div className="mt-4">
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                3 - {t("Select a pickup date")}:{" "}
              </span>
              <DatePicker
                onChange={(value, dateString) => {
                  if (value.add(10, "seconds") >= moment())
                    setPickupDateTime(value);
                }}
                value={pickupDateTime}
                allowClear={false}
                style={{ maxWidth: 180 }}
                className={direction === "ltr" ? "ml-2" : "mr-2"}
                format={"YYYY-MM-DD HH:mm"}
                showTime={{ format: "HH:mm" }}
                disabledDate={(current) => {
                  const today = moment().startOf("day");
                  const nextWeek = moment().add(4, "days").endOf("day");
                  return (
                    current &&
                    (current.isBefore(today) ||
                      current.isAfter(nextWeek) ||
                      current.isoWeekday() === 5)
                  );
                }}
                disabledTime={(current) => {
                  return {
                    disabledHours: () => range(0, 9).concat(range(17, 24)),
                  };
                }}
              />

              {pickupDateTimeIsInvalid() && (
                <p className="mt-4" style={{ color: "red" }}>
                  {t("This pickup date or time is invalid")}
                </p>
              )}
              <p className="my-2 mt-3">
                {t(
                  "Note: pickup time should be between 9 AM and 4 PM, Saturday to Thursday."
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      <hr />
      {aramexShipmentBoxInfo && (
        <div className="mt-4">
          <p
            style={{
              fontWeight: "bold",
              width: 200,
              display: "inline-block",
            }}
          >
            {t("Boxes that will be used")}{" "}
          </p>
          <div className="mt-2">
            <p
              style={{
                fontWeight: "bold",
                width: 170,
                display: "inline-block",
              }}
            >
              {t("Number of boxes:")}{" "}
            </p>
            {aramexShipmentBoxInfo?.number_of_pieces}
          </div>
          {aramexShipmentBoxInfo?.packed_boxes_info?.map((box, index) => (
            <div className="mt-3" key={index}>
              <p
                style={{
                  fontWeight: "bold",
                  width: 170,
                  display: "inline-block",
                }}
              >
                {t("Box")} {index + 1}
              </p>
              <div className={"mt-2"}>
                <p
                  style={{
                    fontWeight: "bold",
                    width: 170,
                    display: "inline-block",
                  }}
                >
                  {t("-Dimensions:")}
                </p>
                <span>
                  [{box.box_depth} x {box.box_height} x {box.box_width}] cm
                </span>
              </div>
              <div className={"mt-2"}>
                <p
                  style={{
                    fontWeight: "bold",
                    width: 170,
                    display: "inline-block",
                  }}
                >
                  {t("-Products:")}
                </p>
                {box.items.map((item) => item.name).join(", ")}
              </div>
            </div>
          ))}
          <hr />
        </div>
      )}
      {!aramexShipmentInfo && (
        <Button
          type="primary"
          className="mt-3"
          disabled={
            !pickupDateTime || pickupDateTimeIsInvalid() || rateDetails === null
          }
          onClick={() => createPickupAndShipment()}
          loading={dispatchLoading}
        >
          {t("Dispatch order")}
        </Button>
      )}
      {aramexShipmentInfo && (
        <>
          {aramexShipmentInfo.pdf_url && (
            <Button
              type="outlined"
              className="mt-3"
              onClick={() => {
                window.open(aramexShipmentInfo.pdf_full_url, "_blank");
              }}
            >
              {t("View Label")}
            </Button>
          )}
          {aramexShipmentInfo.commercial_invoice_url && (
            <Button
              type="outlined"
              className="mt-3 ml-3"
              onClick={() => {
                window.open(
                  aramexShipmentInfo.commercial_invoice_full_url,
                  "_blank"
                );
              }}
            >
              {t("View Commercial Invoice")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default withNamespaces()(Aramex);
