import ProductsTranslation from "./Products/products.js";
import Locations from "./Locations/locations.js";
import PDHours from "./PDHours/index";
import DeliverySettings from "./DeliverySettings/index";
import Appearance from "./Appearance/index";
import Promotions from "./Promotions";
import WaitList from "./Locations/waitLists";
import Permissions from "./Permissions";
import Integrations from "./Integrations";
import FeedBacks from "./FeedBack";
import Customers from "./Customers";
import Businesses from "./Businesses";
import CustomerBookings from "./CustomerBookings";
import Downloads from "./Reports/Downloads";
import POS from "./POS";
import CustomerReports from "./Reports/Customers";
import Sales from "./Reports/Sales";
import Health from "./Health";
import Billing from "./Billing";
import Profile from "./Profile";
import ServiceBookings from "./ServiceBookings";
import Pages from "./Pages";
import Import from "./Import";
import Home from "./Home";
import PendingOrders from "./PendingOrders";
import AddOns from "./AddOns";
import Tables from "./Tables";
import Intro from "./Intro";
import Premium from "./Premium";
import Wallet from "./Wallet";
import Talabat from "./Talabat";
import AbandonedCarts from "./AbandonedCarts";
import Driver from "./Driver";
import MobileNotifications from "./MobileNotifications";
import Links from "./Links";
import Campaigns from "./Campaigns";
import QRScanner from "./QRScanner";

let tr = {
  ...Driver,
  ...ProductsTranslation,
  ...Locations,
  ...PDHours,
  ...DeliverySettings,
  ...Appearance,
  ...Promotions,
  ...WaitList,
  ...Permissions,
  ...Integrations,
  ...FeedBacks,
  ...Customers,
  ...Businesses,
  ...CustomerBookings,
  ...Downloads,
  ...POS,
  ...CustomerReports,
  ...Sales,
  ...Health,
  ...Billing,
  ...Profile,
  ...ServiceBookings,
  ...Pages,
  ...Import,
  ...Home,
  ...PendingOrders,
  ...AddOns,
  ...Tables,
  ...Intro,
  ...Premium,
  ...Wallet,
  ...Talabat,
  ...AbandonedCarts,
  ...MobileNotifications,
  ...Links,
  ...Campaigns,
  ...QRScanner,
  403: "عذرا، لا يمكنك عرض محتوى هذه الصفحة",
  Key: "Arabic",
  All: "الكل",
  New: "جديد",
  Received: "مستلم",
  Preparing: "يتم تحضيره",
  Delivering: "توصيل",
  "Out for Delivery": "يتم توصيله",
  Complete: "مكتمل",
  More: "المزيد",
  Cancelled: "ملغي",
  "For Today": "لليوم",
  Future: "طلبات مسبقة",
  Past: "طلبات سابقة",
  "Customer Orders": "طلبات الزبائن",
  Order: "طلب",
  "All Orders": "جميع الطلبات",
  Apply: "تطبيق",
  ordersSearchPlaceholder:
    "ابحث عن طريق رقم الطلب، اسم الزبون، رقم الهاتف، المنطقة، الخ..",
  "Perform Action": "تغيير",
  Logout: "تسجيل الخروج",
  Cancel: "الغاء",
  Save: "حفظ",
  Close: "اغلاق",
  Login: "تسجيل الدخول",
  Username: "اسم المستخدم",
  Password: "كلمة السر",
  "Log in": "دخول",
  UsernameError: "الرجاء ادخال اسم المستخدم",
  PasswordError: "الرجاء ادخال كلمة السر",
  Type: "النوع",
  "Tracking Code": "رقم التتبع",
  Status: "الحالة",
  "POS Status": "حالة نقطة البيع",
  Customer: "الزبون",
  Area: "المنطقة",
  "Expected Date": "تاريخ التسليم",
  "Due Status": "حالة الاستحقاق",
  "Invoice Printed by": "الفاتورة طبعت من قبل",
  "Expected Time": "وقت التسليم",
  "Expected Delivery": "تاريخ التسليم",
  Placed: "تاريخ وضع الطلب",
  Recieved: "مستلم",
  "Payment Type": "نوع الدفع",
  "Payment Status": "حالة الدفع",
  "Payment status is complete. You don't have permission to change the payment status.":
    "تم دفع الطلب. ليس لديك إذن لتغيير حالة الدفع.",
  "Payment status is incomplete. You don't have permission to change the payment status.":
    "لم يتم دفع الطلب. ليس لديك إذن لتغيير حالة الدفع .",

  "Payment Reference": "رقم مرجع الدفع",
  Delivery: "توصيل",
  "Pick up": "استلام",
  PaymentComplete: "مدفوع",
  PaymentIncomplete: "غير مدفوع",
  Actions: "التحكم",
  Invoice: "الفاتورة",
  English: "انجليزي",
  Arabic: "عربي",
  "Quick actions": "تحكم سريع",
  Print: "طباعة",
  "Order Status": "حالة الطلب",
  Thermal: "حراري",
  CancelOrderQuestion: "الغاء/ارجاع نقود الطلب؟",
  Preparation: "تحضير",
  ReceivedInvoice: "تاريخ استلام الطلب",
  "Deliver to": "تسليم الى",
  "Your Order": "تفاصيل الطلب",
  ProductInvoiceTable: "المنتج",
  PriceInvoiceTable: "السعر",
  QuantityInvoiceTable: "الكمية",
  TotalsInvoiceTable: "الاجمالي",
  KD: "د.ك",
  Block: "قطعة",
  Street: "شارع",
  Avenue: "جادة",
  Building: "مبنى",
  Floor: "دور",
  Apartment: "شقة",
  Additional: "عنوان اضافي",
  CancelledResultTitle: "لقد تم الغاء الطلب",
  CancelledResultSubtitle: "لا يمكنك التحكم بطلب قد تم الغاؤه",
  "Uncancel Order": "ارجاع الطلب",
  CancelOrderPrompt: "هل انت متأكد من الغاء الطلب؟",
  CancelOrderPromptNoUndo: "لا يمكنك ارجاع الطلب بعد الغائه",
  Yes: "نعم",
  No: "لا",
  PopoverTitleBulkActions: "تحكم بالطلبات المحددة",
  SelectedBulk: "طلبات محددة",
  Payment: "الدفع",
  "Change status of": "تغيير حالة",
  "Change to": "تغيير الى",
  "Send Surveys by SMS": "ارسل الاستبيان عن طريق الرسائل النصية",
  PopoverChangePaymentTitle: "تغيير حالة الدفع للطلبات المحددة",
  PopoverChangeOrderStatusTitle: "نغيير حالة الطلب للطلبات المحددة",
  "Receive Order": "استلام الطلب",
  PickupFrom: "استلام من",
  OrderCancelSuccess: "تم الغاء الطلب بنجاح",
  SomethingWentWrong: "حدث خطأ ما. الرجاء المحاولة مرة اخرى",
  "Something went wrong": "حدث خطأ ما. الرجاء المحاولة مرة اخرى",
  BulkActionSuccess: "تم تغيير الطلبات المحددة بنجاح",
  AccessDeniedMessage: "ليس لديك صلاحية فعل ذلك",
  "Disable Sound": "تعطيل الصوت",
  "Enable Sound": "تشغيل الصوت",
  LoggedAs: "المستخدم",
  NoteSavedSuccess: "تم حفظ الملاحظة بنجاح",
  "Add notes to order": "اضف ملاحظات لطلب",
  Notes: "ملاحظات",
  Dispatch: "طلب سائق",
  "Assign Driver": "طلب سائق",
  "Could not dispatch because of a setting misconfiguration.":
    "لم نتمكن من طلب سائق بسبب وجود خطأ في اعدادات اوجو",
  "Could not find location in OGO location list":
    "لم تتمكن اوجو من ايجاد العنوان",
  Own: "توصيل خاص",
  "Select a delivery provider": "اختر شركة توصيل",
  "Please correct address information": "الرجاء تصحيح معلومات العنوان",
  "Order dispatched successfully": "تم طلب سائق بنجاح",
  "Order has been dispatched successfully, and below are the details":
    "تم طلب سائق بنجاح. ادناه تفاصيل الطلب",
  DispatchProvider: "شركة التوصيل",
  DispatchTrackingReference: "الرقم المرجعي",
  "Select an area": "اخنر المنطقة",
  "Select a block": "اختر القطعة",
  Location: "موقع الزبون",
  "Customer's Address": "عنوان الزبون",
  "Select a branch": "اختر الفرع",
  Cash: "كاش",
  "Please correct the area": "الرجاء تصحيح المنطقة",
  "Tracking link": "رابط التتبع",
  "Click here": "اضغط هنا",
  minutes: "دقيقة",
  "Estimated duration": "المدة المتوقعة",
  "Could not find location in Armada location list":
    "لم تتمكن ارمادا من ايجاد المنطقة",
  "Loading your permissions": "جاري تحميل الصلاحيات",
  "Could not find location in Mirsal location list":
    "لم يتمكن مرسال من ايجاد العنوان",
  BetaLink: "نظام تجريبي (اضغط هنا لاستخدام النظام القديم)",
  "clear selection": "الغاء التحديد",
  "Invoice link copied successfully": "تم نسخ رابط الفاتورة بنجاح",
  "Copy link": "نسخ الرابط",
  Whatsapp: "واتساب",
  "Downloads page": "صفحة التحميل",
  "Recently received": "تم استلامه مؤخرا",
  "Image link copied successfully": "تم نسخ رابط الصورة",
  "Copy image link": "نسخ رابط الصورة",
  "Customer's home picture": "صورة منزل العميل",
  "Customer's location": "موقع العميل",
  District: "محافظة",
  Country: "الدولة",
  Kuwait: "الكويت",
  Qatar: "قطر",
  Zone: "منطقة",
  PACI: "الرقم المدني للمبنى",
  Products: "المنتجات",
  Expected: "متوقع في",
  Created: "تاريخ الطلب",
  "Special remarks": "ملاحظات",
  "Edit Special Remarks": "تعديل الملاحظات",
  "Gift details": "تفاصيل الهدية",
  Name: "الاسم",
  Phone: "الهاتف",
  Message: "الرسالة",
  Address: "العنوان",
  "Business Orders": "طلبات الشركات",
  "Made via business portal. For": "تم عن طريق منصة الشركات لشركة",
  "Search for a business order": "ابحث في طلبات الشركات",
  "Changed status successfully": "تم تغيير الحالة بنجاح",
  Business: "الشركة",
  Branch: "الفرع",
  "You can increase your branch's limit from":
    "يمكنك زيادة الحد الاقصى للافرع من",
  "Updated orders successfully": "تم تعديل الطلبات بنجاح",
  FAQs: "الأسئلة المتكررة",
  "Pickup Address": "عنوان الاستلام",
  "Car details": "تفاصيل السيارة",
  Make: "النوع",
  Color: "اللون",
  "Plate number": "رقم اللوحة",
  "Car details were not provided": "لا توجد تفاصيل عن السيارة",
  "Civil ID #": "رقم البطاقة المدنية",
  "Not provided": "غير متوفر",
  "KNET on delivery": "كي نت عند التوصيل",
  "Booked for": "محجوز لتاريخ",
  orders: "طلبات",
  "Edit expected delivery": "تعديل تاريخ التسليم",
  "Please select a date": "الرجاء اختيار تاريخ",
  "Please select start and end": "الرجاء اختيار بداية ونهاية",
  "Last edited by": "آخر تعديل بواسطة",
  "Welcome back": "مرحبا",
  "Reset Password": "تغيير كلمة السر",
  Reset: "تغيير",
  "Confirm Password": "تأكيد كلمة السر",
  "Send Reset Password Link": "ارسال رابط لتغيير كلمة السر",
  "Rest link sent successfully": "تم ارسال رابط تغيير كلمة السر بنجاح",
  "Changed password successfully": "تم تغيير كلمة السر بنجاح",
  "Forgot your password?": "نسيت كلمة السر الخاصة بك؟",
  POS: "نقطة البيع",

  "Incoming orders": "الطلبات القادمة",
  "For today": "طلبات لليوم",
  "Pre-orders (Future)": "طلبات مسبقة (للمستقبل)",
  "Past orders": "طلبات سابقة",
  "results found": "نتيجة بحث",
  "No results found": "لا يوجد نتائج",

  "For branch": "لفرع",
  "Note: the driver will come on demand": "ملاحظة: سيأتي السائق في اقرب وقت",
  "Cancel order": "الغاء الطلب",
  "Please select a reason": "الرجاء اختيار السبب",
  "Select a reason": "اختر السبب",
  "Problem with address": "مشكلة مع العنوان",
  "Problem with contact info": "مشكلة مع معلومات التواصل",
  "Problem with delivery": "مشكلة مع التوصيل",
  "Problem with order": "مشكلة مع الطلب",
  "Problem with payment": "مشكلة مع الدفع",
  Another: "آخر",
  "Enter the reason": "ادخل السبب",
  "Cancellation reason:": "سبب الالغاء:",
  Help: "المساعدة",
  "Hide help": "اخفاء المساعدة",
  "Get a quote to view details about the shipment":
    "احصل على التعرفة لعرض تفاصيل عن الشحن",
  "Get quote": "احصل على التعرفة",
  "Please select a branch first": "الرجاء اختيار فرع",
  "Quote details": "تفاصيل التعرفة",
  "Currency code": "رمز العملة",
  "Local product name": "اسم المنتج المحلي",
  "Weight unit": "وحدة الوزن",
  "Dimensional Weight": "الوزن البعدي",
  "Shipping charge": "تكلفة الشحن",
  "Dispatch info": "تفاصيل طلب الشحن",
  "Open PDF file": "فتح ملف PDF",
  "Shipment date": "تاريخ الشحن",
  "Pickup from branch": "الاستلام من فرع",
  "Pickup date & time": "تاريخ ووقت الاستلام",
  Errors: "الأخطاء",
  "Please contact your account manager to resolve the errors, or you can try to dispatch again":
    "الرجاء التحدث مع مدير حساب لحل المشاكل، أو حاول ارسال الطلب مرة اخرى",
  "Select a pickup date": "اختر وقت للاستلام",
  "Dispatch order": "ارسال الطلب",
  "Phone number is missing": "رقم الهاتف غير متوفر",
  "Note: pickup time should be between 9 AM and 3 PM, Sunday to Thursday. For GCC shipping, the order will take 2 working days to be delivered after pickup.":
    "ملاحظة: يجب ان يكون وقت الاستلام من الساعة 9 صباحا حتى ال 3 مساء. يستغرق التوصيل يومين داخل الخليج من بعد استلامه",
  "Quote generated successfully": "تم انشاء التعرفة بنجاح",
  "This pickup date or time is invalid": "هذا التاريخ او الوقت غير متاح",
  "Ready to collect": "جاهز للتسليم",
  "Delivering/Ready": "يتم توصيله/جاهز",
  "You can't perform actions on refunded orders":
    "لا يمكنك التعديل على طلبات تم استرجاعها",
  "Refund customer": "ارجاع النقود الى العميل",
  Refunded: "مسترجع",
  "Are you sure you want to refund the customer? You can't undo this":
    "هل انت متأكد من انك تريد استرجاع المبلغ للعميل؟ لا يمكنك الرجوع",
  "You do not have the permission to refund. Please visit the Permissions section":
    "ليس لديك الصلاحية لاسترجاع المبلغ. الرجاء زيارة قسم الصلاحيات",
  "Open store": "فتح المتجر",
  "Additional fee": "رسوم اضافية",
  "Benefit Transfer": "تحويل بينيفت",
  "Return inventory": "إرجاع المخزون",
  "Return inventory / points": "إرجاع المخزون/ النقاط ",
  "Would you like to be alerted for new orders?":
    "هل تريد سماع صوت تنبيه عند وصول طلبات جديدة؟",
  "Open DHL invoice file": "فتح فاتورة DHL",
  "Open AWB file": "فتح ملف AWB",
  "Rename existing area to match your selected area?":
    "تغيير اسم المنطقة الاصلي للاسم الجديد؟",
  "Date last edited by": "آخر تعديل للتاريخ بواسطة",
  "Time last edited by": "آخر تعديل للوقت بواسطة",
  "Order log": "سجل تغييرات الطلب",
  Dispatched: "طلب سائق",
  "Search for a topic": "ابحث عن موضوع",
  "Show all FAQs": "اظهار جميع العناوين",
  "Live chat": "تواصل مباشر",
  "Hide live chat": "اغلاق نافذة التواصل",
  "Q&A": "أسئلة وأجوبة",

  "Gifting cards": "بطاقات الإهداء",
  "Choose your template": "اختر التصميم",
  "Select a template": "اختر تصميم",
  Width: "العرض",
  Height: "الطول",
  Columns: "الأعمدة",
  Rows: "الصفوف",
  "Font color": "لون الخط",
  "Font size": "حجم الخط",
  "Font family": "نوع الخط",
  "Gift message": "رسالة الإهداء",
  "Print gift card": "اطبع البطاقة",
  "Are you sure you want to delete this template?":
    "هل انت متأكد من حذف هذا التصميم؟",
  "New template": "تصميم جديد",
  "Template name": "اسم التصميم",
  "Template created successfully": "تم انشاء التصميم بنجاح",
  "Are you sure you want to undo the dispatch?":
    "هل انت متأكد من الغاء طلب السائق؟",
  "Are you sure you want to undo the dispatch? This will NOT cancel it from ****'s dashboard":
    "هل انت متأكد من الغاء طلب السائق؟ الغاء الطلب من هنا لن يلغي الطلب من ****",
  "Reverted dispatch successfully": "تم الغاء طلب السائق بنجاح",
  "Undo dispatch": "الغاء طلب السائق",
  "Send Delivery Details": "ارسل تفاصيل التوصيل",
  "You do not have the permission to undo dispatch. Please visit the Permissions section":
    "ليس لديك الصلاحية لالغاء طلب السائق. الرجاء زيارة قسم الصلاحيات",
  Previous: "السابق",
  "Sales Channels": "قنوات البيع",
  Skip: "تخطي",
  "Block/Area": "القطعة/المنطقة",
  "Select a block/area": "اختر القطعة/المنطقة",
  "Order has been pushed successfully, and below are the details":
    "تم رفع الطلب بنجاح. ادناه تفاصيل الطلب",
  "Revel Order ID": "رمز الطلب ريفيل",
  "Order has not been pushed yet": "لم يتم رفع الطلب",
  "Push Order": "ارفع الطلب",
  "Show gift QR code": "اظهار رمز QR",
  "Table #": "طاولة #",
  Online: "اونلاين",
  "Sent SMS successfully": "تم ارسال الرسالة النصية بنجاح",
  "Facing problems? Click": "تواجه مشاكل؟ اضغط",
  here: "هنا",
  "to logout": "لتسجيل الخروج",
  WhatsApp: "واتساب",
  "By Date": "حسب تاريخ التوصيل",
  "By Options Groups": "حسب مجموعات الخيارات",
  "By customer + options groups": "حسب العميل + مجموعات الخيارات",
  "No Grouping": "بدون تجميع",
  "All branches": "جميع الفروع",
  "Order Type": "نوعية الطلبات",
  "Group By": "تجميع حسب",
  "Order Prep. Sheet": "جدول التحضير",
  "Prepration sheet for orders": "تقرير و جدول تحضير الطلبات",
  "Group By": "تجميع حسب",
  "Enable buy now button": "تفعيل زر الشراء الآن",
  "Show customer email field": "إظهار حقل ايميل للعميل",
  "Show warning banner when store is busy": "اظهار شعار انذار عند اغلاق المتجر",
  "Delivery status": "حالة التوصيل",
  "Check status": "تحقق من الحالة",
  "Updated status successfully": "تم تحديث الحالة بنجاح",
  "No Cutlery": "دون ادوات الطعام",
  "Sender area": "منطقة المرسل",
  "Receiver area": "منطقة المستلم",
  Pickups: "اشعارات الاستلام",
  "is here to pickup their order": "وصل لاستلام طلبه",
  "Customers' Notifications": "اشعارات العملاء",
  "These customers are at the pickup location":
    "هؤلاء العملاء قد وصلوا لاستلام طلباتهم",
  "Attachments (max. 1 file)": "المرفقات (ملف واحد كحد اقصى)",
  "Waiter calls": "طلبات الخدمة",
  "These customers are requesting a waiter":
    "هؤلاء الزبائن يحتاجون الى موظف خدمة لمساعدتهم",
  "Enable calling a waiter": "تفعيل ميزة طلب موظف خدمة",
  "Gift will be sent anonymously": "الهدية سترسل بشكل مجهول",
  "Gift link": "رابط الهدية",
  "Drag the map to edit the location": "قم بالسحب على الخريطة لتغيير الموقع",
  "Gift Recipient": "مستلم الهدية",
  "Please call recipient number on arrival":
    "الرجاء الاتصال على المستلم عند الوصول",
  "Gift Sender": "مرسل الهدية",
  "Sender Number": "رقم هاتف المرسل",
  "Gift sender": "مرسل الهدية",
  "Sender phone number": "رقم هاتف المرسل",
  "Gift should be sent anonymously": "الهدية يجب ان ترسل بشكل مجهول",
  "When enabled, only orders below the inputted amount will allow cash orders.":
    "عند تفعيل هذه الخاصية، سيتم السماح فقط بالطلبات التي تقل عن المبلغ المدخل.",
  "Only for orders below": "فقط للطلبات التي تقل عن",
  "Invoice Date": "تاريخ الانشاء",
  "Paid Date": "تاريخ اتمام الدفع",
  "Mark paid": "تغيير الى مدفوع",
  "Mark unpaid": "تغيير الى غير مدفوع",
  Unpaid: "غير مدفوع",
  "Total Paid": "اجمالي المدفوع",
  "Recipient Location is unknown.": "موقع المستلم غير معروف.",
  "Send whatsapp message.": "ارسل رسالة واتساب.",
  "Receiver Name": "اسم المستلم",
  "Receiver Number": "رقم هاتف المستلم",
  "Receiver Address": "عنوان المستلم",
  "Address details unknown.": "تفاصيل العنوان غير معروفة.",
  "Contact receiver": "تواصل مع المستلم",
  Acknowledge: "استلام",
  "Acknowledge All": "استلام الكل",
  "Change Order": "تعديل الطلب",

  Tip: "اكرامية",
  Tips: "اكراميات",

  "English (Hide product price)": "انجليزي (إخفاء سعر المنتج)",
  "Arabic (Hide product price)": "العربية (إخفاء سعر المنتج)",

  hours: "ساعات",
  hour: "ساعة",
  mins: "دقائق",
  min: "دقيقة",

  "Chat Support": "الدعم الفني",
  "Hide chat": "اخفاء الدعم",

  "Show options": "إظهار الخيارات",
  "Hide options": "إخفاء الخيارات",
  "Thank you for your feedback!": "شكرا لك على تقييمك!",
  "Extremely likely": "سأفعل بالتأكيد",
  "Not at all likely": "لن أفعل أبداً",
  "How likely are you to recommend ordable/ to a friend?":
    "ما مدى إحتمال أن تنصح زملائك بإستخدام أوردبل؟",
  "Note: pickup time should be between 9 AM and 4 PM, Saturday to Thursday.":
    "ملحوظة: وقت الاستلام يجب أن يكون بين الساعة 9 صباحًا و 4 مساءً، من السبت إلى الخميس.",
  "Boxes that will be used": "الصناديق التي سيتم استخدامها",
  "Number of boxes:": "عدد من صناديق:",
  Box: "صندوق",
  "-Dimensions:": "-أبعاد:",
  "-Products:": "-منتجات:",
  "Shipping charge:": "رسوم الشحن:",
  "Shipment Details": "تفاصيل الشحنة",
  "Shipment ID:": "رقم الشحنة:",
  "Pickup ID:": "رقم الاستلام:",
  "Reference ID:": "رقم المرجعي:",
  "Pickup Branch:": "فرع الاستلام:",
  "Pickup Date:": "تاريخ الاستلام:",
  "Country Code:": "رمز الدولي:",
  "From:": "من:",
  "To:": "إلى:",
  "Manage Aramex boxes": "إدارة صناديق أرامكس",
  "Add box": "إضافة صندوق",
  "Update box": "صندوق التحديث",
  "New box": "صندوق جديد",
  "Are you sure you want to delete this box? You can't undo this":
    "هل أنت متأكد أنك تريد حذف هذا الصندوق؟ لا يمكنك التراجع عن هذا",
  "You are offline": "غير متصل بالانترنت",
  Refresh: "تحديث",
  "* Max. 30 days": "* 30 يوم كحد اقصى",
};
export default tr;
