import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance"; // Antd Components
import { Checkbox, List, message, Modal, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { transferOrders } from "../../store/actions/orders";
import { GetEnglishOrArabic } from "../POS"; // My components
// My components

// Actions

const { Option } = Select;

class EditBranchModal extends Component {
  state = {
    branchID: this.props.originalBranchIDs,
    errorList: [],
    ignoreProducts: false,
    showIgnoreProduct: true,
    markAsNewOrderCheck: this.props.settings.notify_transferred_order,
  };

  save = async () => {
    this.setState({ saveLoading: true });
    const {
      t,
      selectedOrders,
      orderType,
      isFromBulkActions,
      settings,
      branches,
    } = this.props;
    const { branchID } = this.state;
    try {
      const res = await instance.post("/edit_branch/", {
        ids: selectedOrders,
        newBranchID: branchID,
        type: orderType,
        markAsNewOrderCheck: this.state.markAsNewOrderCheck,
      });
      const newErrorList = res.data.filter((x) => !x.success);
      const successList = res.data.filter((x) => x.success);
      let hasPermission = true;
      if (
        settings.is_branch_based_permissioned &&
        !branches.includes(branchID)
      ) {
        hasPermission = false;
      }
      if (successList.length || !newErrorList.length) {
        this.props.transferOrders(successList, hasPermission);
        if (!isFromBulkActions && hasPermission) {
          this.props.refetchLastEditedBy();
        }
        if (!newErrorList.length) {
          this.props.onClose(true);
          message.success(t("Edited successfully"));
        }
      }
      if (newErrorList.length) {
        this.setState({ errorList: newErrorList });
      }
    } catch (err) {
      if (err.response && err.response.status == 403) {
        message.error(t("AccessDeniedMessage"));
      }
    }
    this.setState({ saveLoading: false });
  };

  render() {
    const {
      visible,
      config,
      t,
      switchable_branches,
      originalBranchID,
      orderType,
    } = this.props;
    const { branchID, errorList } = this.state;
    return (
      <Modal
        destroyOnClose={true}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {errorList.length > 0
              ? t("Error while transferring orders")
              : t("Transfer orders")}
          </div>
        }
        visible={visible}
        onCancel={() => this.props.onClose()}
        onOk={() => this.save()}
        okText={t("Transfer")}
        cancelText={t("Close")}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
        okButtonProps={{
          loading: this.state.saveLoading,
          hidden: errorList.length && true,
          disabled: !branchID || branchID === originalBranchID,
        }}
        width={430}
      >
        <div className="px-2 mb-3">
          {errorList.length > 0 ? (
            <div className="mt-3 mb-4">
              <List
                itemLayout="horizontal"
                dataSource={errorList}
                renderItem={(error) => (
                  <List.Item>
                    <List.Item.Meta
                      style={{ alignItems: "center" }}
                      avatar={
                        <CloseCircleOutlined
                          color={"red"}
                          style={{
                            fontSize: 25,
                            color: "red",
                          }}
                        />
                      }
                      title={`${error.tracking_id} ${t(
                        "could not be transfered because"
                      )} ${
                        (config.language === "arabic" &&
                          error.product_ar_name) ||
                        error.product_name
                      } ${t(error.message)}`}
                    />
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <div className="mt-3 mb-4">
              <Select
                showSearch
                value={branchID || undefined}
                style={{ width: "100%" }}
                placeholder={t("Select a branch")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  this.setState({ branchID: e });
                }}
              >
                {switchable_branches.length
                  ? switchable_branches.map((branch) => (
                      <Option value={branch.id}>
                        {GetEnglishOrArabic(
                          branch.name,
                          branch.ar_name,
                          config.language
                        )}
                      </Option>
                    ))
                  : null}
              </Select>
              {/* {orderType === "Order" && (
                <Checkbox
                  style={{
                    marginTop: 5,
                  }}
                  defaultChecked={this.state.markAsNewOrderCheck}
                  onChange={(e) => {
                    this.setState({
                      markAsNewOrderCheck: e.target.checked,
                    });
                  }}
                >
                  {t("Mark as new order")}
                </Checkbox>
              )} */}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    switchable_branches: state.auth.permissions.switchable_branches,
    settings: state.auth.settings,
    branches: state.auth.permissions.branches,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    transferOrders: (orders, hasPermission) =>
      dispatch(transferOrders(orders, hasPermission)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EditBranchModal));
