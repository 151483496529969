const messages = {
  "Branch created successfully": "تم انشاء الفرع بنجاح",
  "Branch deleted successfully": "تم حذف الفرع بنجاح",
  "Branch saved successfully": "تم حفظ الفرع بنجاح",
  "Products added successfully": "تم اضافة المنتجات بنجاح",
  "Removed products successfully": "تم ازالة المنتجات بنجاح",
  "Edited inventory successfully": "تم تعديل المخزون بنجاح",
  "Slots created successfully": "تم انشاء الخانات بنجاح",
  "Removed areas successfully": "تم ازالة المناطق بنجاح",
  "Areas added successfully": "تم اضافة المناطق بنجاح",
  "Deleted slots successfully": "تم حذف الخانات بنجاح",
  "Slots copied successfully": "تم نسخ الخانات بنجاح",
};

export default messages;
