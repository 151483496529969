const config = {
  "General settings": "الاعدادات العامة",
  "Enable delivery": "تفعيل التوصيل",
  "Enable pickup": "تفعيل الاستلام",
  "Scheduled delivery": "التوصيل المجدول",
  "Enable scheduled delivery": "تفعيل التوصيل المجدول",
  "On demand delivery": "الطلب في اقرب وقت",
  "Enable same day": "السماح بالطلب في نفس اليوم",
  "Enable on demand delivery": "تفعيل التوصيل في اقرب وقت",
  "Compound minutes": "تجميع الوقت",
  "On demand delivery minutes": "وقت التوصيل في اقرب وقت بالدقائق",
  "Enable tracking": "تفعيل تتبع الطلب",
  "Allow pre-ordering": "تفعيل الطلب المسبق",
  "Minimum order": "الحد الادنى للطلب",
  "Minimum lead time (minutes)": " الوقت المستغرق لأي طلب (دقائق)",
  "Minimum lead time (POS)": "الوقت المستغرق لأي طلب (نفطة البيع)",
  "Delivery Type": "نوع التوصيل",
  "Pickup intervals (minutes)": "مدة فترات الاستلام (بالدقائق)",
  "Scheduled days": "ايام الجدولة",
  "International shipping lead days": "ايام الشحن الدولي",
  "When enabled, customers will not be able to place orders when the store is not operating within the production slots":
    "عند التفعيل، لن يتمكن زبائنك من وضع الطلبات خارج ساعات عمل المتجر",
  "Enable auto close": "تفعيل الإغلاق التلقائي",
  "will apply to all non-cash orders": "سوف يطبق على جميع طلبات دفع الاونلاين",
  "Delivery/Pick-up time 24hr format":
    "استخدام تنسيق 24 ساعة لوقت التوصيل/الاستلام",
  "Force address map input": "اجبار ادخال عنوان على الخريطة",
  "Show pickup directions":"عرض اتجاهات الاستلام",
  "Enable on demand pickup": "تفعيل الاستلام في اقرب وقت",
  "Enable scheduled pickup": "تفعيل الاستلام المجدول",
  "On demand pickup minutes": "وقت الاستلام في اقرب وقت بالدقائق",
  "Delivery Settings": "اعدادات التوصيل",
  "Pickup Settings": "اعدادات الاستلام",
  "Enable auto receive orders": "استلام الطلبات تلقائيا",
  "Enable DHL dynamic rate": "تفعيل التسعير التلقائي مع DHL",
  "Enable on demand custom string": "نص مخصص للتوصيل والاستلام بأقرب وقت",
  "On demand custom string in English":
    "نص (التوصيل، الاستلام) بأقرب وقت بالانجليزي",
  "On demand custom string in Arabic":
    "نص (التوصيل، الاستلام) بأقرب وقت بالعربي",
  "Minimum order for pickup": "الحد الادنى لطلبات الإستلام",
  "Enable pickup arrival notifications": "تفعيل اشعارات وصول طلبات الاستلام",
  "Default Payment Method": "طريقة الدفع الافتراضية",
  "When enabled, compound minutes will add the lead time/preparation time to the delivery time when calculating it":
    " عند تفعيلها, خاصية تجميع الوقت سوف تضيف الوقت المستغرق/وقت التحضير عند حساب وقت التوصيل",
  "Enabling this would set the store to busy/closed outside of the working hours":
    "عند التفعيل، سيتم اغلاق المتجر تلقائيا خارج اوقات العمل",
  "Enabling this would set the branch to busy/closed outside of the working hours":
    "عند التفعيل، سيتم اغلاق الفرع تلقائيا خارج اوقات العمل",
  "Enable auto busy": "تفعيل الإغلاق التلقائي",
  "If 'Allow pre-ordering' is enabled, customers will still be able to place scheduled orders. Otherwise, customers will only be able to order within the working hours":
    "اذا كان 'تفعيل الطلب المسبق' مفعلا، سيتمكن عملاؤك من وضع الطلبات المجدولة. اذا لم يكن مفعلا، ستصلك الطلبات اثناء ساعات العمل فقط",
  "Hide Deliver To/Pick up from storefront":
    "إخفاء التوصيل إلى/الاستلام من واجهة المتجر",
  "(50 characters max)": "(50 حرف كحد اقصى)",
};

export default config;
