import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import PlaceholderImage from "../../../assets/placeholder.png";
import { withRouter } from "react-router-dom";
import instance from "../../../store/actions/instance";
// Antd components
import {
  Table,
  Button,
  Popconfirm,
  message,
  Upload,
  Popover,
  Menu,
  Dropdown,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined, 
  DeleteOutlined,
  PrinterOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";

// Actions
import {
  fetchPromotions,
  deletePromotion,
  togglePromotionStatus,
  quickChangePromotionImage,
} from "../../../store/actions";

//My Components
import PromotionBulkPopoverContent from "./PromotionBulkPopoverContent";
import PromotionModal from "./PromotionModal";
import PrintCountModal from "../PrintCountModal";
import Toggle from "@/components/common/Toggle";

const { Column } = Table;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("Image must smaller than 5MB!");
  }
  return isJpgOrPng && isLt2M;
}

const uploadButton = (t) => (
  <div>
    <PlusOutlined />
    <div className="ant-upload-text">{t("Upload")}</div>
  </div>
);

export const getConditionsText = (conditions, code, settings, config, t) => {
  const currency =
    config.language === "arabic"
      ? settings.currency_local
      : settings.currency_english;
  let conditionsText = [];
  conditions.forEach((condition) => {
    if (condition.type === "number") {
      conditionsText.push(
        `${t("Buys any")} ${condition.value} ${
          condition.value > 1 ? t("items") : t("item")
        }`
      );
    } else if (condition.type === "coupon") {
      conditionsText.push(`${t("Uses promo code")} ${code}`);
    } else if (condition.type === "value") {
      conditionsText.push(
        `${t("Spends")} ${condition.value} ${t("or more")} ${currency}`
      );
    } else if (condition.type === "products") {
      conditionsText.push(
        `${t("Buys any")} ${condition.value} ${
          condition.value > 1 ? t("items") : t("item")
        } ${t("from")} ${condition.products.map((product) =>
          config.language === "arabic"
            ? product.ar_name || product.name
            : product.name
        )}`
      );
    } else if (condition.type === "category_number") {
      conditionsText.push(
        `${t("Buys any")} ${condition.value} ${
          condition.value > 1 ? t("items") : t("item")
        } ${t("from")} ${condition.categories.map((category) =>
          config.language === "arabic"
            ? category.ar_name || category.name
            : category.name
        )}`
      );
    } else if (condition.type === "category_value") {
      conditionsText.push(
        `${t("Spends")} ${condition.value} ${t("or more")} ${currency} ${t(
          "from"
        )} ${condition.categories.map((category) =>
          config.language === "arabic"
            ? category.ar_name || category.name
            : category.name
        )}`
      );
    } else if (condition.type === "service_type") {
      conditionsText.push(`${t("Uses")} ${t(condition.value)}`);
    } else if (condition.type === "delivery_arrival_time") {
      const delivery_arrival_time = {
        asap: "ASAP",
        scheduled: "Scheduled",
      };
      conditionsText.push(
        `${t("Uses")} ${t(delivery_arrival_time[condition.value])}`
      );
    } else if (condition.type === "delivery_area") {
      conditionsText.push(
        t("Orders from any of these areas:") +
          " " +
          condition.value?.map(
            (area) =>
              (config.language === "arabic" && area.ar_name) || area.name
          )
      );
    } else if (condition.type === "branch") {
      conditionsText.push(
        t("Orders from any of these branches:") +
          " " +
          condition.value?.map(
            (branch) =>
              (config.language === "arabic" && branch.ar_name) || branch.name
          )
      );
    } else if (condition.type === "country") {
      conditionsText.push(
        t("Orders from any of these countries:") +
          " " +
          condition.value?.map(
            (country) =>
              (config.language === "arabic" && country.ar_name) || country.name
          )
      );
    } else if (condition.type === "points") {
      conditionsText.push(
        `${t("Spends")} ${condition.value} ${t("Loyalty Points")}`
      );
    } else if (condition.type === "orders") {
      conditionsText.push(
        `${t("Number of orders is equal to")} ${condition.value}`
      );
    } else if (condition.type === "orders_more") {
      conditionsText.push(
        `${t("Number of orders is more than")} ${condition.value}`
      );
    } else if (condition.type === "orders_less") {
      conditionsText.push(
        `${t("Number of orders is less than")} ${condition.value}`
      );
    } else if (condition.type === "first_time") {
      conditionsText.push(t("Register for the first time"));
    }
  });

  return conditionsText;
};

const getDiscountsText = (discounts, settings, config, t) => {
  let product = null;
  const currency =
    config.language === "arabic"
      ? settings.currency_local
      : settings.currency_english;
  let discountsText = [];

  discounts.forEach((discount) => {
    if (discount.type === "fixed") {
      discountsText.push(`${discount.value} ${currency} ${t("off")}`);
    } else if (discount.type === "percent") {
      discountsText.push(`${discount.value} % ${t("off")}`);
    } else if (discount.type === "products") {
      product = discount.products[0];
      if (product) {
        discountsText.push(
          `${t("Gets")} ${product.quantity} × ${
            config.language === "arabic"
              ? product.ar_name || product.name
              : product.name
          }`
        );
      }
    } else if (discount.type === "delivery") {
      discountsText.push(`${t("Free delivery")}`);
    } else if (discount.type === "delivery_percent") {
      discountsText.push(`${discount.value} % ${t("off delivery charge")}`);
    } else if (discount.type === "category") {
      discountsText.push(
        `${discount.value} % ${t("off")} ${discount.categories.map((category) =>
          config.language === "arabic"
            ? category.ar_name || category.name
            : category.name
        )}`
      );
    } else if (discount.type === "products_percent") {
      discountsText.push(
        `${discount.value} % ${t("off")} ${discount.products.map((product) =>
          config.language === "arabic"
            ? product.ar_name || product.name
            : product.name
        )}`
      );
    } else if (discount.type === "modifier") {
      discountsText.push(
        `${discount.value} % ${t("off")} ${discount.modifiers.map((modifier) =>
          config.language === "arabic"
            ? modifier.ar_value || modifier.value
            : modifier.value
        )}`
      );
    } else if (discount.type === "category_free") {
      discountsText.push(
        `${discount.value} ${t("free products from")} ${discount.categories.map(
          (product) =>
            config.language === "arabic"
              ? product.ar_name || product.name
              : product.name
        )}`
      );
    } else if (discount.type === "cashback_percent") {
      discountsText.push(
        `${discount.value} % ${t("of the order's total")} ${t(
          "added to wallet"
        )}`
      );
    } else if (discount.type === "cashback_fixed") {
      discountsText.push(
        `${discount.value} ${currency}  ${t("added to wallet")}`
      );
    }
  });

  return discountsText;
};
class PromotionsList extends Component {
  state = {
    selectedRowKeys: [],
    PromotionModalVisible: false,
    selectedPromotion: null,
    requstThermalPrintCountCode: null,
    hideExpiredPromotions: true,
    showMoreProductsRows: {
      excluded_categories: [],
      excluded_products: [],
      excluded_modifiers: [],
    },
  };
  componentDidMount() {
    if (!this.props.promotions) {
      this.props.fetchPromotions(this.props.t);
    }
  }
  handleImageChange = (info, index) => {
    const t = this.props.t;
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.success == true) {
        this.props.quickChangePromotionImage(index, info.file.response.photo);
        message.success(t("Image was changed successfully"));
      } else message.error(t("Could not change promotions's image"));
    }
  };
  renderColumnActive = (is_active, record) => {
    const { permissions, t } = this.props;
    return (
      <div className="text-center">
        <Toggle
          isActive={is_active}
          onClick={() =>
            this.props.togglePromotionStatus(record.index, record.key, t)
          }
        />
      </div>
    );
  };
  renderColumnBanner = (text, record) => {
    const { permissions, t } = this.props;

    return {
      props: {
        style: { padding: 0 },
      },
      children: (
        <div
          style={{ padding: 5 }}
          onClick={() => this.setState({ selectedImageProduct: record.key })}
        >
          {permissions.can_create_promotions ? (
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              data={{ id: record.key, isSmartPromo: true }}
              onChange={(info) => this.handleImageChange(info, record.index)}
              action={`/change_promotion_image/`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
            >
              {text ? (
                <img
                  src={text}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton(t)
              )}
            </Upload>
          ) : (
            <img
              src={text ? text : PlaceholderImage}
              alt="avatar"
              style={{
                width: 95,
                height: 95,
              }}
            />
          )}
        </div>
      ),
    };
  };

  renderColumnDelete = (text, record) => {
    const { permissions, t } = this.props;
    return (
      <Popconfirm
        disabled={!permissions.can_create_promotions}
        title={t("Are you sure you want to delete this promotion?")}
        onConfirm={() =>
          this.props.deletePromotion(record.index, record.key, t)
        }
        okText={t("Yes")}
        cancelText={t("No")}
      >
        <Button
          disabled={!permissions.can_create_promotions}
          danger
          shape="circle"
          icon={<DeleteOutlined style={{ top: -4 }} />}
        ></Button>
      </Popconfirm>
    );
  };

  renderColumnClickEditModal = (text, record) => {
    const { t } = this.props;
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.openPromotionModal(record.index)}
      >
        {t(text)}
      </div>
    );
  };
  handleShowMoreProductsRows = (index, type) => {
    this.setState((prevState) => {
      const newShowMoreProductsRows = {
        ...prevState.showMoreProductsRows,
        [type]: {
          ...prevState.showMoreProductsRows[type],
          [index]: !prevState.showMoreProductsRows[type][index],
        },
      };
      return { showMoreProductsRows: newShowMoreProductsRows };
    });
  };
  renderColumnExcludedObjects = (type) => (text, record) => {
    const { config, t } = this.props;
    const showMore = this.state.showMoreProductsRows[type][record.index];

    const textToShow = showMore ? text : text.slice(0, 5);

    return (
      <>
        <ul
          style={{ cursor: "pointer" }}
          onClick={() => this.openPromotionModal(record.index)}
          className="px-4"
        >
          {textToShow.map((object, index) => (
            <li key={index}>
              {config.language === "arabic"
                ? object.ar_name || object.name
                : object.name}
            </li>
          ))}
        </ul>
        {text.length > 5 && (
          <Button
            type="link"
            style={{ paddingInline: 0 }}
            icon={
              showMore ? (
                <MinusOutlined style={{ position: "relative", top: -2 }} />
              ) : (
                <PlusOutlined style={{ position: "relative", top: -2 }} />
              )
            }
            onClick={(e) => {
              e.stopPropagation();
              this.handleShowMoreProductsRows(record.index, type);
            }}
          >
            {showMore ? t("Show Less") : t("Show More")}
          </Button>
        )}
      </>
    );
  };
  renderColumnQuantity = (text, record) => {
    const { t } = this.props;
    let returnValue = record.quantity;
    if (!record.is_limited) returnValue = t(`unlimited`);

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.openPromotionModal(record.index)}
      >
        {returnValue}
      </div>
    );
  };

  renderColumnRemaining = (text, record) => {
    const { t } = this.props;
    let returnValue = record.remaining;
    if (!record.is_limited) returnValue = "-";

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.openPromotionModal(record.index)}
      >
        {returnValue}
      </div>
    );
  };

  getPrintableToken = async () => {
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_printable_token/`);
      if (res.data.success) {
        return res.data.token;
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };

  renderColumnPrint = (text, record) => {
    const { t, history, settings, config } = this.props;

    const printMenu = (
      <Menu>
        <Menu.Item
          onClick={async () => {
            const token = await this.getPrintableToken();
            window.open(
              `https://${settings.domain}/manage/coupon/print/${record.code}/${token}/`,
              "_blank"
            );
          }}
        >
          {t("A4")}
        </Menu.Item>

        <Menu.Item
          onClick={() =>
            this.setState({ requstThermalPrintCountCode: record.code })
          }
        >
          <span>{t("Thermal")}</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <div style={{ maxWidth: 115 }}>
        {record.code && (
          <Dropdown
            overlay={printMenu}
            className={config.direction == "ltr" ? "mr-4" : "ml-4"}
          >
            <Button
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              icon={
                <PrinterOutlined className="mr-2" style={{ fontSize: 19 }} />
              }
            >
              {t("Type")} <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  };
  renderColumnConditionsDiscounts = (text, record) => {
    const { t, settings, config } = this.props;
    let conditionsText = getConditionsText(
      record.conditions,
      record.code,
      settings,
      config,
      t
    );
    let discountsText = getDiscountsText(record.discounts, settings, config, t);

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.openPromotionModal(record.index)}
      >
        <p className="mr-3">{t("If a customer :")}</p>
        <ul style={{ listStyle: "none", fontSize: "14px" }}>
          {" "}
          {conditionsText.length
            ? conditionsText.map((condition, index) => (
                <li key={index}>{condition}</li>
              ))
            : t("Uses the website")}
        </ul>
        <p className="mr-3">{t("Then a customer will get :")}</p>
        <ul style={{ listStyle: "none", fontSize: "14px" }}>
          {" "}
          {discountsText.length
            ? discountsText.map((discount, index) => (
                <li key={index}>{discount}</li>
              ))
            : t("Nothing")}
        </ul>
      </div>
    );
  };
  renderColumneEdit = (text, record) => {
    const { permissions, t } = this.props;
    return (
      <Button
        onClick={() => this.props.setEditPromotion(record.index)}
        disabled={!permissions.can_create_promotions}
        shape="circle"
        icon={<EditOutlined style={{ top: -4 }} />}
      ></Button>
    );
  };
  mapDayToWeekDay = (day) => {
    const t = this.props.t;
    if (day == null) return null;
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
      "Every day",
    ];
    if (typeof day == "number") return t(days[day]);
    else {
      return days.indexOf(day);
    }
  };

  renderColumnAvailability = (text, record) => {
    const { t } = this.props;

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.openPromotionModal(record.index)}
      >
        {record.availability.length
          ? record.availability.map((av) => (
              <li>{`${this.mapDayToWeekDay(av.day)} ${t("From")} ${
                av.start
              } ${t("To")} ${av.end}`}</li>
            ))
          : t("Every day at any time")}
      </div>
    );
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  openPromotionModal = (index) => {
    this.setState({
      selectedPromotion: index,
      PromotionModalVisible: true,
    });
  };

  render() {
    const { t, promotions, search, permissions, config, settings } = this.props;
    const {
      selectedRowKeys,
      PromotionModalVisible,
      selectedPromotion,
      requstThermalPrintCountCode,
      hideExpiredPromotions,
    } = this.state;
    if (!promotions) return null;
    let data = [];
    promotions.forEach((promotion, index) => {
      if (
        !promotion.is_deleted &&
        promotion.name.toLowerCase().includes(search.toLowerCase()) &&
        (!hideExpiredPromotions || !promotion.is_expired)
      )
        data.push({
          ...promotion,
          index: index,
          name:
            config.language == "arabic"
              ? promotion.ar_name || promotion.name
              : promotion.name,
        });
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div
        style={{
          marginTop: 8,
          textAlign: config.direction == "ltr" ? "left" : "right",
          direction: config.direction,
        }}
      >
        <div className="mb-3">
          <Checkbox
            className="m-0"
            onChange={(e) =>
              this.setState({ hideExpiredPromotions: e.target.checked })
            }
            defaultChecked={hideExpiredPromotions}
          >
            {t("Hide expired promotions")}
          </Checkbox>
        </div>
        <>
          {requstThermalPrintCountCode && (
            <PrintCountModal
              visible={!!requstThermalPrintCountCode}
              direction={config.direction}
              onPrint={async (count) => {
                const token = await this.getPrintableToken();
                window.open(
                  `https://${settings.domain}/manage/coupon/print/${requstThermalPrintCountCode}/pdf/${count}/${token}/`
                );
                this.setState({ requstThermalPrintCountCode: null });
              }}
              onCancel={() =>
                this.setState({ requstThermalPrintCountCode: null })
              }
            />
          )}
          {selectedPromotion !== null && PromotionModalVisible && (
            <PromotionModal
              visible={PromotionModalVisible}
              selectedPromotion={promotions[selectedPromotion]}
              selectedPromotionIndex={selectedPromotion}
              onClose={() => {
                this.setState({
                  selectedPromotion: null,
                  PromotionModalVisible: false,
                });
              }}
            />
          )}
          {hasSelected && (
            <div style={{ marginBottom: 13 }}>
              <Popover
                content={
                  <PromotionBulkPopoverContent
                    selectedKeys={selectedRowKeys}
                    setPopoverInvisible={() =>
                      this.setState({
                        selectedRowKeys: [],
                      })
                    }
                  />
                }
                title={t("Actions on selected promotions")}
                trigger="click"
              >
                <Button type="primary" disabled={!hasSelected}>
                  {t("Perform Action")}
                </Button>
              </Popover>
              <span
                style={{
                  marginLeft: config.direction == "ltr" && 8,
                  marginRight: config.direction == "rtl" && 8,
                }}
              >
                {hasSelected
                  ? `${selectedRowKeys.length} ${t("promotions selected")}`
                  : ""}
              </span>
            </div>
          )}
        </>
        <Table
          scroll={{ x: 1500 }}
          dataSource={data}
          size="small"
          rowSelection={permissions.can_create_promotions && rowSelection}
          onRow={(record, index) => ({
            style: { backgroundColor: record.is_expired && "#ffcaca" },
          })}
        >
          <Column
            title={t("Status")}
            dataIndex="is_active"
            key="Active"
            render={this.renderColumnActive}
            filters={[
              { text: "On", value: true },
              { text: "Off", value: false },
            ]}
            onFilter={(value, record) => record.is_active === value}
          />
          <Column
            title={t("Image")}
            dataIndex="banner"
            key="banner"
            render={this.renderColumnBanner}
            filters={[
              {
                text: "With image",
                value: true,
              },
              {
                text: "without image",
                value: null,
              },
            ]}
            onFilter={(value, record) => {
              if (value == true)
                return (
                  typeof record.banner === "string" && record.banner !== ""
                );
              else return record.banner == null;
            }}
          />
          <Column
            title={t("Name")}
            dataIndex="name"
            key="Name"
            render={this.renderColumnClickEditModal}
          />

          <Column
            title={t("Conditions & Discounts")}
            key="Conditions and  Discounts"
            render={this.renderColumnConditionsDiscounts}
          />
          <Column
            title={t("Availability")}
            key="Availability"
            render={this.renderColumnAvailability}
          />
          <Column
            title={t("Start")}
            dataIndex="start"
            key="start"
            sorter={{
              compare: (a, b) => new Date(a.start) - new Date(b.start),
            }}
            width={150}
            align="center"
            render={this.renderColumnClickEditModal}
          />
          <Column
            title={t("End")}
            dataIndex="expiry"
            key="expiry"
            sorter={{
              compare: (a, b) => new Date(a.expiry) - new Date(b.expiry),
            }}
            width={150}
            align="center"
            render={this.renderColumnClickEditModal}
          />
          <Column
            title={t("Quantity")}
            dataIndex="quantity"
            key="Quantity"
            sorter={{ compare: (a, b) => a.quantity - b.quantity }}
            render={this.renderColumnQuantity}
          />
          <Column
            title={t("Remaining")}
            dataIndex="remaining"
            key="remaining"
            sorter={{ compare: (a, b) => a.remaining - b.remaining }}
            render={this.renderColumnRemaining}
          />

          <Column
            title={t("Excluded Categories")}
            dataIndex="excluded_categories"
            key="excluded_categories"
            render={this.renderColumnExcludedObjects("excluded_categories")}
          />
          <Column
            title={t("Excluded Products")}
            dataIndex="excluded_products"
            key="excluded_products"
            render={this.renderColumnExcludedObjects("excluded_products")}
          />
          {settings.enable_modifiers && (
            <Column
              title={t("Excluded Modifiers")}
              dataIndex="excluded_modifiers"
              key="excluded_modifiers"
              render={this.renderColumnExcludedObjects("excluded_modifiers")}
            />
          )}

          <Column title={t("Delete")} render={this.renderColumnDelete} />
          <Column
            title={t("Print")}
            onHeaderCell={() => {
              return {
                style: {
                  paddingLeft: config.direction == "ltr" && 46,
                  paddingRight: config.direction == "rtl" && 46,
                },
              };
            }}
            render={this.renderColumnPrint}
          />
          <Column title={t("Edit")} render={this.renderColumneEdit} />
          <Column
            align={"center"}
            title={t("Created By")}
            render={(text, record) => <div>{record.created_by || "-"}</div>}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    config: state.config,
    permissions: state.auth.permissions,
    promotions: state.promotions.promotions,
    settings: state.auth.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPromotions: (t) => dispatch(fetchPromotions(true, t)),
    deletePromotion: (index, id, t) =>
      dispatch(deletePromotion(index, id, true, t)),
    togglePromotionStatus: (index, id, t) =>
      dispatch(togglePromotionStatus(index, id, true, t)),
    quickChangePromotionImage: (index, photo) =>
      dispatch(quickChangePromotionImage(index, photo, true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(PromotionsList)));
