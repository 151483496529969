import {
  FETCH_STORE_BRANCHES,
  SET_STORE_BRACHES_LOADING_TRUE,
  FETCH_COUNTRIES_AREAS,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  FETCH_BRANCH_CATALOG_LOADING,
  FETCH_BRANCH_CATALOG,
  TOGGLE_BRANCH_PRODUCT,
  DELETE_BRANCH_PRODUCT,
  OVERRIDE_BRANCH_PRODUCT_STOCK,
  OVERRIDE_BRANCH_PRODUCT_OPTIONS,
  SAVE_BRANCH_PRODUCT_INVENTORY,
  TOGGLE_BRANCH_PRODUCT_OPTION,
  BULK_TOGGLE_BRANCH_PRODUCTS,
  BULK_DELETE_BRANCH_PRODUCTS,
  FETCH_BRANCH_DELIVERY_CHARGES_LOADING,
  FETCH_BRANCH_DELIVERY_CHARGES,
  TOGGLE_BRANCH_DELIVERY_CHARGE,
  DELETE_BRANCH_DELIVERY_CHARGE,
  BULK_TOGGLE_BRANCH_DELIVERY_CHARGES,
  BULK_DELETE_BRANCH_DELIVERY_CHARGES,
  OVERRIDE_DELIVERY_CHARGE,
  OVERRIDE_DELIVERY_CHARGE_VALUE,
  BRANCH_BASED_ORDERING_SWITCH,
  FETCH_STORE_ROLES,
  ADD_TO_LOCATIONS_COUNT,
  BRANCH_CREATE_NEW_LIMITS,
  BRANCH_CONTROL_LIMIT,
  BRANCH_CONTROL_PRDOCUT_LIMIT,
  BRANCH_PRODUCT_CREATE_NEW_LIMITS,
  OVERRIDE_BRANCH_PRODUCT_PREORDERING_STOCK,
  SAVE_BRANCH_PRODUCT_PREORDERING_INVENTORY,
  COPY_SLOTS_TO_BRANCHES,
  UPDATE_BRANCH_COMPOSITE_INVENTORIES,
  BULK_TOGGLE_BRANCH_PRODUCT_OPTIONS,
  UPDATE_BRANCH_SLOTS_LIMITS,
  FETCH_BRANCH_ZONES_LOADING,
  FETCH_BRANCH_ZONES,
  UPDATE_BRANCH_BANNER,
  CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL_BB,
  UPDATE_PERMITTED_BRANCH_BUSY_SOCKET,
  UPDATE_BRANCH_BUSY_SOCKET,
  BULK_TOGGLE_BRANCHES,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";
import i18n from "i18next";

export const fetchStoreBranches = () => {
  return async (dispatch) => {
    dispatch({ type: SET_STORE_BRACHES_LOADING_TRUE });
    try {
      let res = await instance.get("/fetch_store_branches/");

      if (res.data.success) {
        dispatch({ type: FETCH_STORE_BRANCHES, payload: res.data.branches });
      } else
        message.error(
          "Could not load branches. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view locations");
      else
        message.error(
          "Could not load branches. Please try again or contact us for help"
        );
    }
  };
};

export const fetchRoles = () => {
  return async (dispatch) => {
    try {
      let res = await instance.get("/fetch_roles_locations/");

      if (res.data.success) {
        dispatch({ type: FETCH_STORE_ROLES, payload: res.data.roles });
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const saveCountriesAreas = (countriesAreas) => {
  return {
    type: FETCH_COUNTRIES_AREAS,
    payload: countriesAreas,
  };
};

export const updateBranch = (branch) => {
  return {
    type: UPDATE_BRANCH,
    payload: branch,
  };
};

export const deleteBranch = (bid) => {
  return {
    type: DELETE_BRANCH,
    payload: bid,
  };
};

export const bulkToggleBranches = (
  ids,
  allowPreordering,
  enableDelivery,
  enablePickup
) => {
  return {
    type: BULK_TOGGLE_BRANCHES,
    payload: {
      ids: ids,
      allowPreordering: allowPreordering,
      enableDelivery: enableDelivery,
      enablePickup: enablePickup,
    },
  };
};

export const saveBranchBanner = (state) => {
  return {
    type: UPDATE_BRANCH_BANNER,
    payload: state,
  };
};

export const fetchBranchCatalog = (bid) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BRANCH_CATALOG_LOADING });
    try {
      let res = await instance.post("/fetch_branch_catalog/", { bid: bid });

      if (res.data.success) {
        dispatch({
          type: FETCH_BRANCH_CATALOG,
          payload: {
            bid: bid,
            products: res.data.products,
            off_products: res.data.off_products,
          },
        });
      } else
        message.error(
          "Could not load catalog. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to do that");
      else
        message.error(
          "Could not load catalog. Please try again or contact us for help"
        );
    }
  };
};

export const fetchBranchDeliveryCharges = (bid) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BRANCH_DELIVERY_CHARGES_LOADING });
    try {
      let res = await instance.post("/fetch_branch_delivery_charges/", {
        bid: bid,
      });

      if (res.data.success) {
        dispatch({
          type: FETCH_BRANCH_DELIVERY_CHARGES,
          payload: {
            bid: bid,
            areas: res.data.areas,
            off_areas: res.data.off_areas,
          },
        });
      } else
        message.error(
          "Could not load areas. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to do that");
      else
        message.error(
          "Could not load areas. Please try again or contact us for help"
        );
    }
  };
};

export const fetchBranchZones = (bid, skipLoading) => {
  return async (dispatch) => {
    if (!skipLoading) dispatch({ type: FETCH_BRANCH_ZONES_LOADING });
    try {
      let res = await instance.post("/fetch_zones/", {
        bid: bid,
      });

      if (res.data.success) {
        dispatch({
          type: FETCH_BRANCH_ZONES,
          payload: {
            bid: bid,
            zones: res.data.zones,
            areasInZone: res.data.area_ids,
          },
        });
      } else
        message.error(
          "Could not load zones. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to do that");
      else
        message.error(
          "Could not load zones. Please try again or contact us for help"
        );
    }
  };
};

export const toggleBranchProduct = (bid, pid, key, value) => {
  return {
    type: TOGGLE_BRANCH_PRODUCT,
    payload: { bid: bid, pid: pid, key, value },
  };
};
export const deleteBranchProduct = (bid, pid, off_products) => {
  return {
    type: DELETE_BRANCH_PRODUCT,
    payload: { bid: bid, pid: pid, off_products },
  };
};

export const overrideStock = (bid, pid) => {
  return {
    type: OVERRIDE_BRANCH_PRODUCT_STOCK,
    payload: { bid: bid, pid: pid },
  };
};

export const overridePreorderingStock = (bid, pid) => {
  return {
    type: OVERRIDE_BRANCH_PRODUCT_PREORDERING_STOCK,
    payload: { bid: bid, pid: pid },
  };
};

export const fetchOptions = (bid, pid, options) => {
  return {
    type: "FETCH_BRANCH_PRODUCT_OPTIONS",
    payload: { bid: bid, pid: pid, options: options },
  };
};

export const overrideOptions = (bid, pid, options) => {
  return {
    type: OVERRIDE_BRANCH_PRODUCT_OPTIONS,
    payload: { bid: bid, pid: pid, options: options },
  };
};

export const saveBranchProductInventory = (bid, pid, value) => {
  return {
    type: SAVE_BRANCH_PRODUCT_INVENTORY,
    payload: { bid: bid, pid: pid, value: value },
  };
};

export const saveBranchProductPreorderingInventory = (bid, pid, value) => {
  return {
    type: SAVE_BRANCH_PRODUCT_PREORDERING_INVENTORY,
    payload: { bid: bid, pid: pid, value: value },
  };
};

export const toggleBranchProductOption = (bid, pid, options) => {
  return {
    type: TOGGLE_BRANCH_PRODUCT_OPTION,
    payload: { bid: bid, pid: pid, options: options },
  };
};

export const bulkToggleBranchProducts = (bid, pids, status, notAvailable) => {
  return {
    type: BULK_TOGGLE_BRANCH_PRODUCTS,
    payload: {
      bid: bid,
      pids: pids,
      status: status,
      notAvailable: notAvailable,
    },
  };
};

export const bulkDeleteBranchProducts = (bid, pids, off_products) => {
  return {
    type: BULK_DELETE_BRANCH_PRODUCTS,
    payload: { bid: bid, pids: pids, off_products: off_products },
  };
};

// Branch delivery charge control

export const toggleBranchDeliveryCharge = (bid, bdcid) => {
  return {
    type: TOGGLE_BRANCH_DELIVERY_CHARGE,
    payload: { bid: bid, bdcid: bdcid },
  };
};

export const deleteBranchDeliveryCharge = (bid, bdcid, off_areas, area_id) => {
  return {
    type: DELETE_BRANCH_DELIVERY_CHARGE,
    payload: { bid: bid, bdcid: bdcid, off_areas, area_id },
  };
};

export const bulkToggleBranchDeliveryCharges = (
  bid,
  bdcids,
  status,
  rate,
  mov,
  minutes,
  deliveryCost
) => {
  return {
    type: BULK_TOGGLE_BRANCH_DELIVERY_CHARGES,
    payload: {
      bid: bid,
      bdcids: bdcids,
      status: status,
      rate: rate,
      mov: mov,
      minutes: minutes,
      deliveryCost: deliveryCost,
    },
  };
};

export const bulkDeleteBranchDeliveryCharges = (bid, bdcids, off_areas) => {
  return {
    type: BULK_DELETE_BRANCH_DELIVERY_CHARGES,
    payload: { bid: bid, bdcids: bdcids, off_areas: off_areas },
  };
};

export const overrideDeliveryCharge = (type, bid, bdcid) => {
  return {
    type: OVERRIDE_DELIVERY_CHARGE,
    payload: { type: type, bid: bid, bdcid: bdcid },
  };
};

export const overrideDeliveryChargeValue = (type, bid, bdcid, value) => {
  return {
    type: OVERRIDE_DELIVERY_CHARGE_VALUE,
    payload: { type: type, bid: bid, bdcid: bdcid, value: value },
  };
};

export const branchBasedOrderingSwitch = () => {
  return {
    type: BRANCH_BASED_ORDERING_SWITCH,
  };
};

export const addToLocationsCount = (value) => {
  return {
    type: ADD_TO_LOCATIONS_COUNT,
    payload: value,
  };
};

// export const updatePermissions = (permissions) => {
//   return {
//     type: UPDATE_PERMISSIONS,
//     payload: permissions,
//   };
// };

export const createNewBranchLimit = (payload) => {
  return {
    type: BRANCH_CREATE_NEW_LIMITS,
    payload: payload,
  };
};
export const controlBranchLimit = (payload) => {
  return {
    type: BRANCH_CONTROL_LIMIT,
    payload: payload,
  };
};

export const controlBranchProductLimit = (payload) => {
  return {
    type: BRANCH_CONTROL_PRDOCUT_LIMIT,
    payload: payload,
  };
};

export const createNewBranchProductLimits = (payload) => {
  return {
    type: BRANCH_PRODUCT_CREATE_NEW_LIMITS,
    payload: payload,
  };
};

export const copySlotsToBranches = (branches) => {
  return {
    type: COPY_SLOTS_TO_BRANCHES,
    payload: branches,
  };
};

export const updateBranchCompositeInventories = (payload) => {
  return {
    type: UPDATE_BRANCH_COMPOSITE_INVENTORIES,
    payload: payload,
  };
};

export const bulkToggleBranchProductOptions = (data) => {
  return {
    type: BULK_TOGGLE_BRANCH_PRODUCT_OPTIONS,
    payload: data,
  };
};

export const updateBranchSlotsLimits = (payload) => {
  return {
    type: UPDATE_BRANCH_SLOTS_LIMITS,
    payload: payload,
  };
};

export const changeProductNotAvailableUntilBranchBased = (payload) => {
  return {
    type: CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL_BB,
    payload: payload,
  };
};

export const fetchAreaToBranchMaster = () => {
  return async (dispatch) => {
    try {
      const res = await instance.post("/fetch_branches_areas_master/");
      if (res.data.success === true) {
        dispatch({
          type: "FETCH_AREA_TO_BRANCH_MASTER",
          payload: res.data.data,
        });
      } else message.error(i18n.t("SomethingWentWrong"));
    } catch (err) {
      if (err.response && err.response.status === 403)
        message.error(i18n.t("AccessDeniedMessage"));
      else message.error(i18n.t("SomethingWentWrong"));
    }
  };
};

export const saveAreaToBranchMaster = (
  selectedBranches,
  deliveryChargeId,
  areaId,
  addToPermissions
) => {
  return async (dispatch) => {
    try {
      const res = await instance.post("/assign_branches_areas_master/", {
        selectedBranches: selectedBranches,
        deliveryChargeId: deliveryChargeId,
        addToPermissions: addToPermissions,
      });
      if (res.data.success === true) {
        dispatch({
          type: "ASSIGN_AREA_TO_BRANCH_MASTER",
          payload: {
            selectedBranches: selectedBranches,
            areaToBranch: res.data.area_to_branch,
            branchesToDelete: res.data.branches_to_delete,
            areaId: areaId,
          },
        });
        message.success(i18n.t("Saved successfully"));
      } else {
        message.error(i18n.t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 403)
        message.error(i18n.t("AccessDeniedMessage"));
      else message.error(i18n.t("SomethingWentWrong"));
    }
  };
};

export const editClosurePeriod = (payload) => {
  return {
    type: "EDIT_CLOSURE_PERIOD",
    payload: payload,
  };
};

export const updateBranchVat = (payload) => {
  return {
    type: "UPDATE_BRANCH_VAT",
    payload: payload,
  };
};

export const updateBranchBusySocket = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PERMITTED_BRANCH_BUSY_SOCKET,
      payload: payload,
    });
    dispatch({
      type: UPDATE_BRANCH_BUSY_SOCKET,
      payload: payload,
    });
  };
};
