import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import { Modal, Tabs, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SmartPromotionsTab from "./SmartPromotionsTab";
import { setOrderPOS } from "../../../store/actions/pos";

// Actions

// Tabs

const DiscountListTab = React.lazy(() => import("./DiscountListTab"));
const NewDiscountTab = React.lazy(() => import("./NewDiscountTab"));

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class DiscountModal extends Component {
  state = { currentTab: "New" };

  setDiscounts = (discount) => {
    let newDiscounts = [...this.props.discounts];
    newDiscounts.push(discount);
    this.props.setOrderPOS({ appliedDiscounts: newDiscounts });
  };
  deleteDiscount = (index) => {
    let newDiscounts = [...this.props.discounts];
    newDiscounts.splice(index, 1);
    this.props.setOrderPOS({ appliedDiscounts: newDiscounts });
  };

  render() {
    const { t, config, visible, discounts, settings } = this.props;
    const { currentTab } = this.state;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Discounts")}
          </div>
        }
        visible={visible}
        cancelText={t("Close")}
        onCancel={this.props.onClose}
        footer={null}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <Tabs
          tabBarStyle={{
            display: "flex",
            justifyContent: "center",
          }}
          size={"large"}
          tabPosition={"top"}
          animated={false}
          onTabClick={(key) => this.setState({ currentTab: key })}
          activeKey={currentTab}
        >
          <TabPane tab={t("New Discount")} key={"New"}>
            <Suspense
              fallback={
                <div className="my-4 text-center">
                  <Spin indicator={antIcon} />
                </div>
              }
            >
              <NewDiscountTab setDiscounts={this.setDiscounts} />
            </Suspense>
          </TabPane>
          {settings.enable_promotions && (
            <TabPane tab={t("Smart Promotions")} key={"SmartPromotions"}>
              <Suspense
                fallback={
                  <div className="my-4 text-center">
                    <Spin indicator={antIcon} />
                  </div>
                }
              >
                <SmartPromotionsTab />
              </Suspense>
            </TabPane>
          )}
          <TabPane tab={t("Manage Discounts")} key={"Manage"}>
            <Suspense
              fallback={
                <div className="my-4 text-center">
                  <Spin indicator={antIcon} />
                </div>
              }
            >
              <DiscountListTab
                discounts={discounts}
                deleteDiscount={this.deleteDiscount}
              />
            </Suspense>
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    discounts: state.pos.appliedDiscounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setOrderPOS: (params) => dispatch(setOrderPOS(params)) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(DiscountModal));
