const trans = {
  "Sales by location": "المبيعات بالمنطقة",
  Map: "خريطة",
  "Area name": "اسم المنطقة",
  "without Address": "بدون عنوان",
  "Province name": "اسم المحافظة",
  "Payment type": "نوع الدفع",
  "Delivery fees": "سعر التوصيل",
  "Additional fees": "رسوم اضافية",
  Revenue: "الربح",
  Not: "غير",
  "Payment status": "حالة الدفع",
  "Orders and payments by status": "الطلبات والدفع حسب حالة الطلب",
  "Products sold by order status": "المنتجات المباعة حسب حالة الطلب",
  "Closing Report": "تقرير الختام اليومي",
  ar_prod: "اسم المنتج",
  "Sales based on": "المبيعات حسب",
  "Closing for": "ختامية المستخدم",
  "Placed by (POS)": "وضعت بواسطة (نقطة البيع)",
  "Order Received": "الطلبات المستلمة",
  "Expected Delivery": "تاريخ الاستلام",
  "No available data": "لا يوجد بيانات",
  from: "من",
  to: "الى",
  "Sales Data Table": "جدول المبيعات الشهرية",
  Revenues: "الايرادات",
  "Sales Discounts": "الخصومات",
  Shipping: "تكاليف التوصيل",
  "Avg. Order Size": "متوسط حجم الطلب",
  "Send Closing Report Daily": "ارسال التقرير الختامي بشكل يومي",
  "Avg. Spend": "متوسط الصرف",
  "Orders in Arabic": "طلبات باللغة العربية",
  "Orders in English": "طلبات باللغة الانجليزية",
  "Monthly Sales": "المبيعات الشهرية",
  "Sales by month": "المبيعات حسب الشهر",
  Highlights: "أبرز الإحصائيات",
  "Sales by product": "المبيعات حسب المنتج",
  "Product Name": "اسم المنتج",
  Count: "العدد",
  "Total value": "القيمة الاجمالية",
  "Daily Sales": "المبيعات اليومية",
  "Sales by day": "المبيعات اليومية",
  Sales: "المبيعات",
  "Sales by hour": "المبيعات بالساعه",
  "New POS": "جديد من نقطة البيع",
  "Reports > Sales": "التقارير > المبيعات",
  Quicksand: "Cairo",
  Saturday: "السبت",
  "Hourly Sales": "المبيعات بالساعة",
  "Sales by Hour": "المبيعات بالساعه",
  "All branches": "جميع الفروع",
  Hourly: "حسب الساعة",
  Monthly: "شهري",
  Daily: "يومي",
  Weekly: "اسبوعي",
  Quarterly: "ربع سنوي",
  Yearly: "سنوي",
  Orders: "الطلبات",
  Sales: "المبيعات",
  "Avg. Basket": "متوسط الطلبات",
  Customers: "العملاء",
  "Avg. Spend": "متوسط المبيعات",
  "* Excludes POS orders": "* لا يشمل مبيعات عبر نقطة البيع POS",
  Contribution: "النسبه",
  "Quantity sold": "الكمية المباعة",
  "Top Products": "المنتجات الاكثر مبيعا",
  "Top Categories": "الفئات الاكثر مبيعا",
  "All Data": "جدول المعلومات",
  Results: "النتائج",
  "Sales Report": "تقرير المبيعات",
  "Top Sellers": "الأكثر مبيعا",
  "View sales by location on a map": "توزيع المبيعات حسب المناطق",
  "Sales over a period of time: by month, week":
    "المبيعات على مدى فترات مختلفة: شهريا، اسبوعيا، الخ..",
  "Show sales by product and category": "المنتجات والاصناف الأكثر مبيعا",
  "Closing report by user and branch": "التقرير الختامي الخاص بفرعك",
  "Reports > Map": "التقارير > خريطة",
  "Reports > Sales Report": "التقارير > تقرير المبيعات",
  "B2B Sales": "مبيعات الشركات",
  "Sales over a period of time for business orders":
    "المبيعات على فترات مختلفة لطلبات الشركات",
  "All Channels": "جميع القنوات",
  "Web Store": "المتجر الالكتروني",
  B2B: "الشركات",
  Channels: "القنوات",
  "Mobile App": "التطبيق",
  "QR scan count over selected time": "عدد مسح QR خلال الوقت المحدد",
  "Overall scan count": "إجمالي عدد المسحات",
  Scans: "المسحات",
  Excelsheet: "اكسل",
};

export default trans;
