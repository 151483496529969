const trans = {
    "File Upload": "رفع ملف الاصناف",
    "Instructions": "التعليمات", 
    "Select an Excel file": "اختر ملف EXCEL", 
    "Verify the file":"تاكد من صلاحية المعلومات",
    "Pick a file":"اختر",
    "Check":"تاكد",
    "Edit Products": "تعديل المنتجات",
    "Edit Options": "تعديل الخيارات",
    "Submit the file": "رفع الملف",
    "Send": "رفع",
    "Edit Errors": "تعديل (اختياري)",
    "File Upload Successful": "تم رفع الملف",
    "You will receive an email when done.": "سنرسل لك بريد الكتروني بعد ان يتم رفع المعلومات",
    "You can upload another file in the meantime.": "يمكنك رفع ملف آخر",
    "Processing ...": "جاري ...",


  };
  
  export default trans;
  