import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Redirect } from "react-router-dom";
import instance from "../../store/actions/instance";
import moment from "moment";
// Antd Components
import { Col, message, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Actions
import { fetchDeliveryRatesAndAreas } from "../../store/actions/deliverySettings";
import { updateOrderStatusAndPayment } from "../../store/actions/orders";
import {
  addProductToCart,
  checkPromotionsPOS,
  clearCart,
  collapseSideNav,
  fetchBranchProducts,
  fetchExternalSources,
  fetchPromotions,
  hideHeader,
  resetPromotionsPOS,
  setAddressPOS,
  setCurrentBranch,
  setCustomerPOS,
  setEditOrder,
  updateUsedComponents,
  setGiftPOS,
} from "../../store/actions";
import { fetchAllProducts } from "../../store/actions/products";
import {
  fetchBranchDeliveryCharges,
  fetchStoreBranches,
} from "../../store/actions/locations";
import {
  isEmpty,
  preciseNumber,
  reduceComponentsInventories,
  reduceCompositeInventories,
} from "../../store/reducers/pos";
//My Components
import NoAccess from "../Orders/NoAccess";
import CustomerModal from "./CustomerModal";
import DiscountModal from "./DiscountModal";
import GiftModal from "./GiftModal";
import OverrideTotalModal from "./OverrideTotalModal";
import ProductVariant from "./ProductVariant";
import OrderSettingsModal from "./OrderSettingsModal";
import DeliveryChargeModal from "./DeliveryChargeModal";
import GeneralSettings from "./GeneralSettings";
import LocationModal from "./LocationModal";
import ProductModal from "./ProductModal";
import QuickActionsModal from "../Orders/QuickActionsModal";
import ChargeModal from "./ChargeModal";
import OpenOrdersModal from "./OpenOrdersModal";
import {
  getOrderDiscount,
  getOrderDiscountSmartPromotions,
} from "./DiscountModal/helpers";
import {
  setDeliveryChargePOS,
  setOrderPOS,
  setOrderSettingsPOS,
} from "../../store/actions/pos";

const ProductList = React.lazy(() => import("./ProductList"));
const POSCart = React.lazy(() => import("./POSCart"));

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function GetEnglishOrArabic(english, arabic, language) {
  if (language === "arabic") return arabic || english;
  return english;
}

class index extends Component {
  state = {
    productModalVisible: false,
    selectedProduct: null,
    customerModalVisible: false,
    locationModalVisible: false,
    discountModalVisible: false,
    variantModalVisible: false,
    deliveryChargeModalVisible: false,
    orderSettingsModalVisible: false,
    generalSettingsModalVisible: false,
    chargeModalVisible: false,
    overrideTotalModalVisible: false,
    overriddenProduct: null,
    giftModalVisible: false,
    ContinueCheckoutAfterModal: false,
    createdAddresses: [], // redux?

    //Invoice
    loading: false,
    quickActionModal: false,
    selectedOrder: null,
    orderStatusValueInModal: "",
    paymentStatusValueInModal: "",
    orderReceivedLoading: false,
    EditedProduct: null,
    OpenOrdersModalVisible: false,

    //Branch not used
    branch: null,
  };

  //taking from Order.js

  createOrderObj(order) {
    const config = this.props.config;
    return {
      key: order.id,
      index: order.type == "Order" ? order.id : "-",
      status: "Received",
      type: order.is_delivery ? "Delivery" : "Pick up",
      placed: moment(order.placed, "YYYY-MM-DDTHH:mm:ss").format(
        "DD-MM-YYYY h:mm A"
      ),
      received: order.received
        ? moment(order.received, "YYYY-MM-DDTHH:mm:ss").format(
            "DD-MM-YYYY h:mm A"
          )
        : "-",
      customer: [`${order.first_name} ${order.last_name}`, order.phone],
      tracking: order.tracking_id,
      expectedDate: order.expected_delivery_date
        ? moment(order.expected_delivery_date, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )
        : "-",
      expectedTime: `${
        order.expected_delivery_slot_start
          ? moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
              "h:mm A"
            )
          : ""
      } - ${
        order.expected_delivery_slot_end
          ? moment(order.expected_delivery_slot_end, "HH:mm:ss").format(
              "h:mm A"
            )
          : ""
      }`,
      area: order.area
        ? config.language == "arabic"
          ? order.area.ar_name
            ? order.area.ar_name
            : "-"
          : order.area.name
          ? order.area.name
          : "-"
        : "-",
      paymentType:
        order.payment_method == "cash"
          ? "Cash"
          : `Online (${
              ["0", "2", "5"].includes(order.payment_ctype)
                ? "credit"
                : order.payment_ctype === "1"
                ? "Knet"
                : order.payment_ctype
            })`,
      paymentStatus: order.payment_complete == true ? "Complete" : "Incomplete",
      paymentReference: order.payment_reference ? order.payment_reference : "-",
      promise: false,
      obj: order,
    };
  }
  handleStatusChangeInQuickActionsModal = (e) => {
    this.setState({ orderStatusValueInModal: e.target.value });
  };

  handlePaymentStatusValueInModalChange = (e) => {
    this.setState({ paymentStatusValueInModal: e.target.value });
  };

  //If performed on an order, the value of the order status and payment status will be sent to the backend
  //to update the selected order. If a promise is selected, the promise will be received first and then the the status
  // will change along with the payment status
  updateOrderStatusAndPayment = (status) => {
    if (!this.props.is_staff) {
      if (!this.props.permissions.can_edit_orders) return;
    }
    const selectedOrder = this.state.selectedOrder;

    const orderType = selectedOrder.obj.type == "Promise" ? "promise" : "order";
    const id = selectedOrder.key;
    let orderStatus = ["New", "New POS"].includes(
      this.state.orderStatusValueInModal
    )
      ? "Received"
      : this.state.orderStatusValueInModal;
    if (orderStatus == "") orderStatus = "Received";
    if (status) orderStatus = status;
    this.props.updateOrderStatusAndPayment(
      orderType,
      id,
      orderStatus,
      this.state.paymentStatusValueInModal
    );
  };
  quickModalOkAction = () => {
    this.setState({ quickActionModal: false });
    this.updateOrderStatusAndPayment();
  };
  quickModalCancelAction = () => {
    this.setState({
      quickActionModal: false,
      orderStatusValueInModal: "",
      paymentStatusValueInModal: "",
      orderReceivedLoading: false,
    });
  };

  receivePromise = () => {
    this.updateOrderStatusAndPayment();
    this.setState({
      orderReceivedLoading: true,
      orderStatusValueInModal: "Received",
    });
  };
  cancelOrder = (
    reason,
    refundCustomer,
    returnInventory,
    refundWallet = false
  ) => {
    this.updateOrderStatusAndPayment(
      refundCustomer ? "Refunded" : "Cancelled",
      reason,
      refundCustomer,
      returnInventory,
      refundWallet
    );
    this.setState({
      selectedOrder: {
        ...this.state.selectedOrder,
        status: refundCustomer ? "Refunded" : "Cancelled",
        cancellation_reason: reason,
      },
    });

    // if (
    //   this.props.customerBookingsItems.length &&
    //   this.props.customerBookingsItems.find(
    //     (item) => item.order_id == this.state.selectedOrder.obj.id
    //   )
    // ) {
    //   this.props.updateCustomerBookingStatus(
    //     this.state.selectedOrder.obj.id,
    //     refundCustomer ? "Refunded" : "Cancelled"
    //   );
    // }

    // if (
    //   this.props.serviceBookingsItems.length &&
    //   this.props.serviceBookingsItems.find(
    //     (item) => item.order_id == this.state.selectedOrder.obj.id
    //   )
    // ) {
    //   this.props.updateCustomerServiceStatus(
    //     this.state.selectedOrder.obj.id,
    //     refundCustomer ? "Refunded" : "Cancelled"
    //   );
    // }
  };
  // cancelOrder = () => {
  //   this.updateOrderStatusAndPayment("Cancelled");
  //   this.setState({
  //     selectedOrder: { ...this.state.selectedOrder, status: "Cancelled" },
  //   });
  // };
  uncancelOrder = () => {
    this.updateOrderStatusAndPayment("Received");
    this.setState({
      selectedOrder: { ...this.state.selectedOrder, status: "Received" },
    });
  };
  // end of Order.js

  setStateIndex = (newState) => {
    this.setState(newState);
  };

  calculateTotalDiscout = (subtotal) => {
    let totalDiscountAmount = 0;
    const { cart, smartPromotionsDiscounts, smartPromotions, branchProducts } =
      this.props;
    totalDiscountAmount += getOrderDiscount(
      subtotal,
      cart,
      this.props.discounts,
      smartPromotions,
      branchProducts?.categories || [],
      this.props.deliveryCharge
    );
    if (!this.props.editOrder) {
      totalDiscountAmount += getOrderDiscountSmartPromotions(
        subtotal,
        cart,
        smartPromotionsDiscounts,
        smartPromotions,
        branchProducts?.categories || [],
        this.props.deliveryCharge
      );
    }
    return totalDiscountAmount;
  };

  checkOutOfStock = (product) => {
    const { store_products, settings, branchProducts } = this.props;
    const products = settings.is_branch_based_ordering
      ? branchProducts
      : store_products;

    let store_product = products.allProducts.find(
      (p) => p.id === product.object_id
    );

    if (!store_product) {
      return true;
    }
    const ignoreInventory = product.ignoreInventory;
    let type_of_product = store_product.type_of_product;
    let bookingSlot;
    if (type_of_product === "bookable") {
      let bookingSlotId = product.booking_slot_details.id;
      if (bookingSlotId && !["OUT", -1].includes(bookingSlotId)) {
        bookingSlot = store_product.booking_slots.find(
          (slot) => slot.id === bookingSlotId
        );
      }
    }
    if (
      !ignoreInventory &&
      ((["stocked", "composite"].includes(type_of_product) &&
        store_product.inventory_on_hand <= 0) ||
        (type_of_product === "bookable" &&
          (!bookingSlot || bookingSlot.inventory <= 0)))
    ) {
      return true;
    }
    return false;
  };

  getVat = (subtotal) => {
    let excludedItemsPrice = 0;
    let excludeVat = false;
    let branchObj = null;
    let enableVat = false;
    const giftWrappingPrice =
      this.props.gift?.gift_wrapping &&
      this.props.gift?.is_gift &&
      this.props.settings.enable_gifts &&
      this.props.settings.enable_gifts_staff &&
      this.props.settings.enable_gift_wrapping &&
      this.props.settings.gift_wrapping_price > 0
        ? this.props.settings.gift_wrapping_price
        : 0;

    if (this.props.settings.is_branch_based_ordering) {
      branchObj = (this.props.branches || []).find(
        (b) => b.id === this.props.currenctBranch
      );
      enableVat = branchObj?.enable_vat;
    } else {
      enableVat = this.props.settings.enable_vat;
    }

    if (
      enableVat &&
      this.props.settings.enable_vat_staff &&
      this.getVatRate() > 0
    ) {
      //Check if any item is excluded from vat
      excludeVat = this.props.cart.some((item) => item.excludeVat === true);
      if (excludeVat) {
        excludedItemsPrice = this.props.cart.reduce((acc, item) => {
          if (item.excludeVat) {
            return (
              acc +
              (item.forcedPrice != null
                ? item.forcedPrice * item.quantity
                : item.total_price * item.quantity +
                  item.quantityExcludedOptionsTotal)
            );
          }
          return acc;
        }, 0);
      }
      excludedItemsPrice = subtotal - excludedItemsPrice;
      if (excludeVat) {
        const discountWithoutVatItem =
          this.calculateTotalDiscout(excludedItemsPrice);
        let total = excludedItemsPrice - discountWithoutVatItem;

        if (total <= 0) total = 0;

        total += (
          this.props.settings.is_branch_based_ordering
            ? branchObj?.remove_delivery_charge_from_vat
            : this.props.settings.remove_delivery_charge_from_vat
        )
          ? 0
          : this.props.deliveryCharge;

        return (
          this.props.settings.is_branch_based_ordering
            ? branchObj?.enable_vat_with_product_price
            : this.props.settings.enable_vat_with_product_price
        )
          ? total - total / (1 + this.getVatRate())
          : parseFloat(parseFloat(total) * parseFloat(this.getVatRate()));
      } else {
        const discount = this.calculateTotalDiscout(subtotal);
        let total = subtotal + giftWrappingPrice - discount;
        if (total <= 0) total = 0;
        total += (
          this.props.settings.is_branch_based_ordering
            ? branchObj?.remove_delivery_charge_from_vat
            : this.props.settings.remove_delivery_charge_from_vat
        )
          ? 0
          : this.props.deliveryCharge;
        return (
          this.props.settings.is_branch_based_ordering
            ? branchObj?.enable_vat_with_product_price
            : this.props.settings.enable_vat_with_product_price
        )
          ? total - total / (1 + this.getVatRate())
          : parseFloat(parseFloat(total) * parseFloat(this.getVatRate()));
      }
    }
    return 0;
  };

  placeOrder = async (orderSettings) => {
    if (this.props.deliveryCharge === "TBD") {
      // this.setState({
      //   deliveryCharge: Number(
      //     this.props.pos.dhl_data.data.quote_data.shipping_charge
      //   ),
      // });

      this.props.setDeliveryChargePOS({
        deliveryCharge: Number(this.props.pos.dhl_data.data.quote_data.shipping_charge),
        fromPage: "placeOrder",
      });
    }

    let res;
    let branchObj = null;
    if (this.props.settings.is_branch_based_ordering) {
      branchObj = (this.props.branches || []).find(
        (b) => b.id === this.props.currenctBranch
      );
    }
    if (this.props.editOrder) {
      res = await instance.post("update_open_order/", {
        forcedSubtotal: this.props.forcedSubtotal,
        order_id: this.props.editOrder,
        customer: this.props.customer,
        gift: this.props.gift,
        tableName: this.props.customer.displayName,
        orderSettings: orderSettings,
        cart: this.props.cart,
        discounts: this.props.discounts,
        address: { ...this.props.address, customerAddresses: undefined },
        deliveryCharge: this.props.deliveryCharge,
        branch:
          (this.props.settings.is_branch_based_ordering &&
            this.props.currenctBranch) ||
          null,
        exclude_delivery_charge_from_vat: this.props.settings
          .is_branch_based_ordering
          ? branchObj?.remove_delivery_charge_from_vat
          : this.props.settings.remove_delivery_charge_from_vat,
      });
    } else {
      res = await instance.post(
        "create_new_order/",
        {
          forcedSubtotal: this.props.forcedSubtotal,
          customer: this.props.customer,
          gift: this.props.gift,
          tableName: this.props.customer.displayName,
          orderSettings: orderSettings,
          cart: this.props.cart,
          discounts: this.props.discounts,
          smartPromotionsDiscounts: this.props.smartPromotionsDiscounts,
          manuallyAppliedPromotion: this.props.manuallyAppliedPromotion,

          address: !this.props.settings.enable_table_qr
            ? { ...this.props.address, customerAddresses: undefined }
            : {
                branchId: this.props.currenctBranch,
                orderType: "Pick Up",
                deliveryAddress: null,
                billingAddress: null,
                customerAddresses: [],
              },
          deliveryCharge: this.props.deliveryCharge,
          branch:
            (this.props.settings.is_branch_based_ordering &&
              this.props.currenctBranch) ||
            null,
          exclude_delivery_charge_from_vat: this.props.settings
            .is_branch_based_ordering
            ? branchObj?.remove_delivery_charge_from_vat
            : this.props.settings.remove_delivery_charge_from_vat,
        },
        { "axios-retry": { retries: 0 } }
      );
    }

    if (!res.data.message) {
      this.clearOrder(false);
    }

    return res;
  };
  clearOrder = async (resetInventory = true) => {
    this.setState({
      productModalVisible: false,
      selectedProduct: null,
      customerModalVisible: false,
      locationModalVisible: false,
      discountModalVisible: false,
      variantModalVisible: false,
      deliveryChargeModalVisible: false,
      orderSettingsModalVisible: false,
      overrideTotalModalVisible: false,
      overriddenProduct: null,
      createdAddresses: [],
      // deliveryCharge: 0,
      // forcedSubtotal: null,
      // discounts: [],
      // orderSettings: {
      //   paymentComplete: false,
      //   paymentType: this.props.settings.default_payment_method_pos,
      //   paymentCType: "",
      //   paymentReference: "",
      //   specialRemarks: "",
      //   autoReceive: this.props.settings.automatically_receive_pos_orders,
      //   source: null,
      // },

      //Invoice
      loading: false,
      quickActionModal: false,
      selectedOrder: null,
      orderStatusValueInModal: "",
      paymentStatusValueInModal: "",

      //Height
      height: null,
      variantWidth: 230,

      EditedProduct: null,
    });

    if (resetInventory) {
      this.resetInventory();
      this.props.resetPromotionsPOS();
    }
    this.props.setAddressPOS(null);
    this.props.setCustomerPOS(null);
    this.props.setGiftPOS(null);
     this.props.setDeliveryChargePOS({
       deliveryCharge: 0,
       fromPage: "clearOrder",
     });

    this.props.setOrderPOS({
      appliedDiscounts: [],
      forcedSubtotal: null,
      orderSettings: {
        paymentComplete: false,
        paymentType: this.props.settings.default_payment_method_pos,
        paymentCType: "",
        paymentReference: "",
        specialRemarks: "",
        autoReceive: this.props.settings.automatically_receive_pos_orders,
        source: null,
      },
    });
    await this.props.clearCart(false);
    const { settings } = this.props;
    if (settings.enable_promotions) {
      this.props.checkPromotionsPOS();
    }
  };

  resetInventory = () => {
    const { store_products, settings, cart, branchProducts } = this.props;
    let store_product;
    const products = settings.is_branch_based_ordering
      ? branchProducts
      : store_products;
    let usedComponents = { ...this.props.usedComponents };
    cart.forEach((product) => {
      if (!product.ignoreInventory) {
        store_product = products.allProducts.find((p) => p.id === product.id);
        if (store_product) {
          Object.entries(product.usedComponents || {}).forEach(
            ([optionId, optionQuantity]) => {
              usedComponents[optionId] =
                (usedComponents[optionId] || 0) -
                optionQuantity *
                  ((optionId.toString().includes("Q") && 1) ||
                    product.quantity);
            }
          );
          if (
            ["stocked", "composite"].includes(store_product.type_of_product)
          ) {
            store_product.inventory_on_hand = preciseNumber(
              store_product.inventory_on_hand + product.quantity
            );
            if (!isEmpty(store_product.composed_products)) {
              usedComponents = reduceCompositeInventories(
                store_product,
                -product.quantity,
                products.allProducts,
                usedComponents
              );
            } else if (store_product.type_of_product === "stocked") {
              usedComponents[store_product.id] =
                (usedComponents[store_product.id] || 0) - product.quantity;
            }
            if (!isEmpty(store_product.components_dict)) {
              usedComponents = reduceComponentsInventories(
                store_product,
                -product.quantity,
                products.allProducts,
                usedComponents
              );
            }
          } else if (store_product.type_of_product === "bookable") {
            const bs = store_product.booking_slots.find(
              (slot) => slot.id === product.booking_slot
            );
            if (bs) {
              bs.inventory += product.quantity;
            }
          }
        }
      }
    });
    this.props.updateUsedComponents(usedComponents);
  };
  checkCartAfterSwitchingBranch = () => {
    const {
      store_products,
      settings,
      cart,
      branchProducts,
      currenctBranch,
      branches,
    } = this.props;
    const { address } = this.props;
    if (address?.orderType === "Delivery") {
      const branch = branches.find((b) => b.id === currenctBranch);
      if (branch) {
        if (
          !branch.areas.some(
            (area) => area.area_id === address.deliveryAddress.address.area_id
          ) ||
          !branch.areas.some(
            (area) => area.area_id === address.billingAddress.address.area_id
          )
        ) {
          this.setState({
            createdAddresses: [],
          });
          this.props.setDeliveryChargePOS({
       deliveryCharge: 0,
       fromPage: "switchBranch",
     });
          this.props.setAddressPOS(address);
        }
      }
    } else {
      if (address) {
        this.setState({
          createdAddresses: [],
        });
        this.props.setDeliveryChargePOS({
       deliveryCharge: 0,
       fromPage: "switchBranch",
     });

        this.props.setAddressPOS({ ...address, branchId: currenctBranch });
      }
    }

    let store_product;
    const products = settings.is_branch_based_ordering
      ? branchProducts
      : store_products;
    let usedComponents = {};
    let shouldUpdateComponents = false;
    cart.forEach((product) => {
      store_product = products.allProducts.find((p) => p.id === product.id);
      product.hasError = false;
      if (!store_product) {
        product.hasError = true;
      } else {
        if (!product.ignoreInventory) {
          Object.entries(product.usedComponents || {}).forEach(
            ([optionId, optionQuantity]) => {
              shouldUpdateComponents = true;
              usedComponents[optionId] =
                (usedComponents[optionId] || 0) +
                optionQuantity *
                  ((optionId.toString().includes("Q") && 1) ||
                    product.quantity);
            }
          );
          if (
            ["stocked", "composite"].includes(store_product.type_of_product)
          ) {
            store_product.inventory_on_hand = preciseNumber(
              store_product.inventory_on_hand - product.quantity
            );
            if (!isEmpty(store_product.composed_products)) {
              usedComponents = reduceCompositeInventories(
                store_product,
                product.quantity,
                products.allProducts,
                usedComponents
              );
              shouldUpdateComponents = true;
            } else if (store_product.type_of_product === "stocked") {
              usedComponents[store_product.id] =
                (usedComponents[store_product.id] || 0) + product.quantity;
              shouldUpdateComponents = true;
            }
            if (!isEmpty(store_product.components_dict)) {
              usedComponents = reduceComponentsInventories(
                store_product,
                product.quantity,
                products.allProducts,
                usedComponents
              );
              shouldUpdateComponents = true;
            }
            if (store_product.inventory_on_hand < 0) product.hasError = true;
          } else if (store_product.type_of_product === "bookable") {
            const bookingSlot = store_product.booking_slots.find(
              (slot) => slot.id === product.booking_slot
            );
            if (bookingSlot) {
              bookingSlot.inventory -= product.quantity;
              if (bookingSlot.inventory < 0) product.hasError = true;
            }
          }
        }
      }

      if (shouldUpdateComponents) {
        this.props.updateUsedComponents(usedComponents);
      }
    });
  };

  changeBranch = async (branch) => {
    this.props.setCurrentBranch(branch);
    this.props.setAddressPOS(null);
    if (this.props.settings.is_branch_based_ordering) {
      await Promise.all([
        this.props.fetchBranchProducts(branch),
        this.props.fetchBranchDeliveryCharges(branch),
      ]);

      this.checkCartAfterSwitchingBranch();
      if (this.props.settings.enable_table_qr) {
        if (this.props.customer?.tableId) {
          const tablei = this.props.tables.find(
            (table) => table.id == this.props.customer.tableId
          );
          if (tablei.branchId != this.props.currenctBranch) {
            this.props.setCustomerPOS({
              ...this.props.customer,
              displayName: null,
              tableName: null,
              tableId: null,
            });
          }
        }
      }
    }
  };
  dispatchPOS = (
    dispatcher,
    reference,
    trackingLink,
    estimatedDuration,
    QR,
    walletCharge,
    driver
  ) => {
    this.setState({
      selectedOrder: {
        ...this.state.selectedOrder,
        status: "Out for Delivery",
        obj: {
          ...this.state.selectedOrder.obj,
          shipping: {
            dispatch_reference: reference,
            is_dispatched: true,
            dispatcher: dispatcher,
            tracking_link: trackingLink,
            estimated_duration: estimatedDuration,
            qr_code: QR,
            wallet_charge: walletCharge,
            driver: driver,
          },
        },
      },
    });
  };
  componentDidUpdate(prevProps) {
    if (
      !this.state.height &&
      this.POSCart.current &&
      this.POSCart.current.clientHeight
    ) {
      this.setState({
        height: this.POSCart.current.clientHeight,
      });
    }

    if (
      this.props.recentlyReceived != prevProps.recentlyReceived &&
      prevProps.recentlyReceived != null &&
      this.state.quickActionModal
    ) {
      let order = this.props.recentlyReceived.find(
        (ord) => ord.from_promise == this.state.selectedOrder.key
      );
      if (order) {
        this.setState({
          selectedOrder: this.createOrderObj(this.state.selectedOrder.obj),
        });
      }
    }
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    if (!this.props.branches.length) {
      this.setState({ fetchingBranches: true });
      await this.props
        .fetchStoreBranches()
        .then(() => this.setState({ fetchingBranches: false }));
    }
    if (!this.props.deliveryAreas.length) {
      this.props.fetchDeliveryRatesAndAreas();
    }
    const is_branch_based = this.props.settings.is_branch_based_ordering;

    if (!is_branch_based) {
      const branch =
        this.props.permissions.all_permitted_branches.length &&
        this.props.permissions.all_permitted_branches[0].id;

      if (!this.props.store_products.allProducts.length) {
        await this.props.fetchAllProducts();
      }

      if (branch) {
        this.props.setCurrentBranch(branch);
      }
    } else if (is_branch_based && !this.props.currenctBranch) {
      const defaultBranch = this.props.defaultBranchPOSID;

      let branch = this.props.permissions.all_permitted_branches[0]?.id;
      if (defaultBranch) {
        if (this.props.permissions.branches.includes(defaultBranch)) {
          branch = defaultBranch;
        }
      }
      if (branch) {
        this.props.setCurrentBranch(branch);
        this.props.fetchBranchDeliveryCharges(branch);
        await this.props.fetchBranchProducts(branch);
      }
    }
    if (!this.props.externalSources) {
      this.props.fetchExternalSources();
    }
    if (this.props.history.location.state?.orderID) {
      const orderID = this.props.history.location.state?.orderID;
      await this.getOrderDetails(orderID, "edit");
      this.props.history.replace();
    }
    const { settings } = this.props;
    // switch to more optimized fetch
    if (settings.enable_promotions) {
      if (!this.props.smartPromotions) {
        await this.props.fetchPromotions(this.props.t);
      }
      this.props.checkPromotionsPOS();
    }
  };

  // componentWillUnmount = () => {
  //   const { settings } = this.props;
  //   if (settings.enable_promotions) {
  //     this.props.resetPromotionsPOS();
  //   }
  //   this.resetInventory();
  //   this.props.setAddressPOS(null);
  //   this.props.setCustomerPOS(null);
  //   this.props.clearCart();
  // };

  fillOpenOrder = async (order, type = "edit") => {
    this.clearOrder(true);
    const { settings, currenctBranch, branches } = this.props;

    if (
      settings.is_branch_based_ordering &&
      order.branch &&
      currenctBranch != order.branch
    ) {
      await this.changeBranch(order.branch);
    }
    const { store_products, branchProducts, deliveryAreas } = this.props;
    const products = settings.is_branch_based_ordering
      ? branchProducts
      : store_products;
    order.items.forEach((item) => {
      const product = products.allProducts.find(
        (store_product) => item.object_id === store_product.id
      );

      this.props.addProductToCart({
        product: product || {
          name: item.name,
          ar_name: item.ar_name,
          photo_small: item.photo,
        },
        quantity: item.quantity,
        total_price: item.price,
        quantityExcludedOptionsTotal: item.quantity_excluded_price,
        options: item.options,
        extraFields: item.extra_fields,
        booking_slot: item.booking_original_slot_id,
        bookingSlotDetails: item.booking_slot_details,
        ignoreInventory: item.ignore_inventory,
        specialRemarks: item.special_remarks,
        resource: item.resource,
        resourceSlot: item.resource_slot,
        order_item_id: item.id,
        hasError: type === "re-order" ? this.checkOutOfStock(item) : !product,
      });
    });
    const discounts = order.discounts.map((discount) => {
      return {
        type: discount.discount_type,
        reason: discount.discount_description,
        amount: discount.discount_amount,
        id: discount.id,
        smart_promotion: discount.smart_promotion_id,
        excludedProducts: discount.excluded_products,
        excludedCateogires: discount.excluded_categories,
        excludedModifiers: discount.excluded_modifiers,

        discountedCateogires: discount.discounted_cateogires,
        discountedProducts: discount.discounted_products,
        discountedModifiers: discount.discounted_modifiers,
      };
    });

    let delivery_charge = 0;
    let resetAddress = false;

    if (type == "re-order") {
      if (order.type !== "pickup") {
        try {
          if (order.branch && settings.is_branch_based_ordering) {
            let deliveryBranchArea = branches
              .find((b) => b.id === order.branch)
              .areas.find(
                (area) =>
                  area.area_id === order.address.deliveryAddress.address.area_id
              );

            delivery_charge = deliveryBranchArea?.override_delivery_charge
              ? deliveryBranchArea.override_price
              : deliveryBranchArea.original_price;
          } else {
            let deliveryArea = deliveryAreas.find(
              (area) =>
                area.area_id === order.address.deliveryAddress.address.area_id
            );
            delivery_charge = deliveryArea.price;
          }
        } catch (err) {
          resetAddress = true;
        }
      }
    } else {
      delivery_charge = order.delivery_charge;
    }

    this.props.setAddressPOS(resetAddress ? this.props.address : order.address);
    this.props.setCustomerPOS({
      displayName: order.customer_name,
      customerId: order.customer,
      customerType: "Old",
      email: "",
      loading: false,
      name: "",
      phoneCode: "+965",
      phoneNumber: null,
      tableName: order.table_name,
    });
    this.props.setGiftPOS(order.gift ? order.gift : null);
    this.props.setDeliveryChargePOS({
      deliveryCharge: resetAddress
        ? this.props.deliveryCharge
        : parseFloat(delivery_charge),
      fromPage: "fillOpenOrder",
    });
    // this.setState({
    //   // deliveryCharge: resetAddress
    //   //   ? this.props.deliveryCharge
    //   //   : parseFloat(delivery_charge),

    //   orderSettings:
    //     type == "re-order"
    //       ? this.state.orderSettings
    //       : {
    //           ...order.order_settings,
    //           date: moment(order.order_settings.date, "YYYY-MM-DD"),
    //           start: moment(order.order_settings.start, "HH:mm"),
    //           end: moment(order.order_settings.end, "HH:mm"),
    //         },
    //   appliedDiscounts: discounts,
    // });
    this.props.setOrderPOS({
      appliedDiscounts: discounts,
      orderSettings:
        type == "re-order"
          ? this.props.orderSettings
          : {
              ...order.order_settings,
              date: moment(order.order_settings.date, "YYYY-MM-DD"),
              start: moment(order.order_settings.start, "HH:mm"),
              end: moment(order.order_settings.end, "HH:mm"),
            },
    });
    if (type !== "re-order") {
      this.props.setEditOrder(order.tracking_id);
    }
  };

  getRawTotal = () => {
    return this.props.cart.reduce((totalPrice, item) => {
      return (
        totalPrice +
        (item.forcedPrice != null
          ? item.forcedPrice * item.quantity
          : item.total_price * item.quantity +
            item.quantityExcludedOptionsTotal)
      );
    }, 0);
  };

  getOrderDetails = async (id, type) => {
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_open_order_details/${id}/${type}`);
      this.fillOpenOrder(res.data, type);
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
  getVatRate = () => {
    const { settings, currenctBranch, branches } = this.props;
    if (!settings.enable_vat_staff) return 0;
    if (settings.is_branch_based_ordering) {
      const branchObj = (branches || []).find((b) => b.id === currenctBranch);

      if (branchObj?.enable_vat) return branchObj.vat_rate || 0;
      return 0;
    } else {
      return settings.enable_vat ? settings.vat_rate || 0 : 0;
    }
  };

  POSCart = React.createRef();

  render() {
    const {
      branchProducts,
      t,
      permissions,
      user,
      totalPrice,
      store_products,
      settings,
      currenctBranch,
      branches,
      editOrder,
      deliveryCharge,
      forcedSubtotal,
    } = this.props;
    const {
      productModalVisible,
      selectedProduct,
      customerModalVisible,
      locationModalVisible,
      discountModalVisible,
      overrideTotalModalVisible,
      overriddenProduct,
      variantModalVisible,
      deliveryChargeModalVisible,
      orderSettingsModalVisible,
      generalSettingsModalVisible,
      chargeModalVisible,
      createdAddresses,
      quickActionModal,
      orderReceivedLoading,

      ContinueCheckoutAfterModal,
      height,
      cartWidth,
      variantWidth,
      orderSettings,
      EditedProduct,
      OpenOrdersModalVisible,

      branch,
      giftModalVisible,
    } = this.state;

    if (!user || !permissions) {
      return <Redirect to="/manage/login" />;
    }

    if (!permissions || !permissions.can_create_orders) {
      return <NoAccess />;
    }

    if (!settings.enable_pos) return <Redirect to="/manage/home" />;

    const products = settings.is_branch_based_ordering
      ? branchProducts
      : store_products;

    const subtotal = this.getRawTotal();
    const totalDiscount = this.calculateTotalDiscout(subtotal);
    const vat = this.getVat(subtotal);
    const giftWrappingPrice =
      this.props.gift?.gift_wrapping &&
      this.props.gift?.is_gift &&
      settings.enable_gifts &&
      settings.enable_gifts_staff &&
      settings.enable_gift_wrapping &&
      settings.gift_wrapping_price > 0
        ? settings.gift_wrapping_price
        : 0;

    const orderTotalPrice = forcedSubtotal
      ? forcedSubtotal.toFixed(settings.currency_decimals)
      : deliveryCharge != "TBD"
      ? Math.max(
          subtotal - totalDiscount + deliveryCharge + vat + giftWrappingPrice,
          0
        ).toFixed(settings.currency_decimals)
      : Math.max(subtotal - totalDiscount + deliveryCharge + vat, 0);

    return (
      <Row style={{ paddingTop: 0 }}>
        {chargeModalVisible && (
          <ChargeModal
            visible={chargeModalVisible}
            orderTotal={this.state.selectedOrder?.obj?.total || 0}
            onClose={() => {
              this.setState({
                chargeModalVisible: false,
                quickActionModal: true,
              });
            }}
          />
        )}
        {OpenOrdersModalVisible && (
          <OpenOrdersModal
            fillOpenOrder={(order) => this.getOrderDetails(order, "edit")}
            visible={OpenOrdersModalVisible}
            onClose={() => {
              this.setState({
                OpenOrdersModalVisible: false,
              });
            }}
          />
        )}

        {productModalVisible && selectedProduct && (
          <ProductModal
            visible={productModalVisible}
            product={selectedProduct}
            EditedProduct={EditedProduct}
            originalProduct={products.allProducts.find(
              (p) => p.id === selectedProduct.id
            )}
            products={products.allProducts}
            onClose={() => {
              this.setState({
                productModalVisible: false,
                EditedProduct: null,
              });
            }}
            branch={settings.is_branch_based_ordering && currenctBranch}
          />
        )}
        {customerModalVisible && (
          <CustomerModal
            visible={customerModalVisible}
            ContinueCheckoutAfterModal={ContinueCheckoutAfterModal}
            openOrderSettingsModal={this.openOrderSettingsModal}
            setStateIndex={this.setStateIndex}
            deliveryCharge={this.props.deliveryCharge}
            branch={this.props.currenctBranch}
            onClose={() => {
              this.setState({
                customerModalVisible: false,
                ContinueCheckoutAfterModal: false,
              });
            }}
          />
        )}
        {giftModalVisible && (
          <GiftModal
            visible={giftModalVisible}
            setStateIndex={this.setStateIndex}
            onClose={() => {
              this.setState({
                giftModalVisible: false,
              });
            }}
          />
        )}
        {locationModalVisible && (
          <LocationModal
            visible={locationModalVisible}
            branch={currenctBranch}
            createdAddresses={createdAddresses}
            ContinueCheckoutAfterModal={ContinueCheckoutAfterModal}
            openOrderSettingsModal={this.openOrderSettingsModal}
            deliveryCharge={this.props.deliveryCharge}
            setStateIndex={this.setStateIndex}
            onClose={() => {
              this.setState({
                locationModalVisible: false,
                ContinueCheckoutAfterModal: false,
              });
            }}
          />
        )}
        {discountModalVisible && (
          <DiscountModal
            visible={discountModalVisible}
            setStateIndex={this.setStateIndex}
            deleteDiscount={this.deleteDiscount}
            onClose={() => {
              this.setState({
                discountModalVisible: false,
              });
            }}
          />
        )}
        {variantModalVisible && (
          <ProductVariant
            visible={variantModalVisible}
            selectedProduct={selectedProduct}
            setStateIndex={this.setStateIndex}
            products={products}
            width={variantWidth}
            onClose={() => {
              this.setState({
                variantModalVisible: false,
              });
            }}
          />
        )}
        {deliveryChargeModalVisible && (
          <DeliveryChargeModal
            visible={deliveryChargeModalVisible}
            deliveryCharge={deliveryCharge}
            setStateIndex={this.setStateIndex}
            onClose={() => {
              this.setState({
                deliveryChargeModalVisible: false,
              });
            }}
          />
        )}
        {overrideTotalModalVisible && (
          <OverrideTotalModal
            visible={overrideTotalModalVisible}
            overriddenProduct={overriddenProduct}
            setStateIndex={this.setStateIndex}
            onClose={() => {
              this.setState({
                overrideTotalModalVisible: false,
                overriddenProduct: null,
              });
            }}
          />
        )}
        {orderSettingsModalVisible && (
          <OrderSettingsModal
            visible={orderSettingsModalVisible}
            orderSettings={this.props.orderSettings}
            deliveryCharge={this.props.deliveryCharge}
            setStateIndex={this.setStateIndex}
            placeOrder={this.placeOrder}
            editOrder={editOrder}
            orderTotalPrice={orderTotalPrice}
            onClose={() => {
              this.setState({
                orderSettingsModalVisible: false,
              });
            }}
          />
        )}
        {generalSettingsModalVisible && (
          <GeneralSettings
            visible={generalSettingsModalVisible}
            changeBranch={this.changeBranch}
            branch={currenctBranch}
            onClose={() => {
              this.setState({
                generalSettingsModalVisible: false,
              });
            }}
          />
        )}

        {quickActionModal && (
          <QuickActionsModal
            visible={quickActionModal}
            onOk={() => this.quickModalOkAction()}
            onClose={() => this.quickModalCancelAction()}
            receivePromise={() => this.receivePromise()}
            loading={false}
            selectedOrder={this.state.selectedOrder}
            onChangeOrderStatus={this.handleStatusChangeInQuickActionsModal}
            status={this.state.orderStatusValueInModal}
            onChangePaymentStatus={this.handlePaymentStatusValueInModalChange}
            paymentStatus={this.state.paymentStatusValueInModal}
            cancelOrder={(
              reason,
              refundCustomer,
              returnInventory,
              refundOrder
            ) =>
              this.cancelOrder(
                reason,
                refundCustomer,
                returnInventory,
                refundOrder
              )
            }
            uncancelOrder={() => this.uncancelOrder()}
            loading={orderReceivedLoading}
            fromPOS={true}
            dispatchPOS={this.dispatchPOS}
          />
        )}

        <Col lg={16} md={14} sm={13} xs={24}>
          <Suspense
            fallback={
              <div className="mt-4 text-center">
                <Spin indicator={antIcon} />
              </div>
            }
          >
            <ProductList
              height={height}
              cartWidth={cartWidth}
              setStateIndex={this.setStateIndex}
              products={products}
              productModalVisible={productModalVisible}
              variantModalVisible={variantModalVisible}
            />
          </Suspense>
        </Col>
        <Col lg={8} md={10} sm={11} xs={24}>
          <Suspense
            fallback={
              <div className="mt-4 text-center">
                <Spin indicator={antIcon} />
              </div>
            }
          >
            <div ref={this.POSCart}>
              <POSCart
                vat={vat}
                getOrderDetails={this.getOrderDetails}
                products={products}
                branch={currenctBranch}
                setStateIndex={this.setStateIndex}
                subtotal={subtotal}
                totalPrice={orderTotalPrice}
                rawTotalDiscount={totalDiscount}
                rawDeliveryCharge={deliveryCharge}
                totalDiscount={totalDiscount.toFixed(
                  settings.currency_decimals
                )}
                deliveryCharge={
                  deliveryCharge != "TBD"
                    ? deliveryCharge.toFixed(settings.currency_decimals)
                    : deliveryCharge
                }
                forcedSubtotal={forcedSubtotal}
                clearOrder={this.clearOrder}
                branchObj={(branches || []).find(
                  (b) => b.id === currenctBranch
                )}
                vat_rate={this.getVatRate()}
                editOrder={editOrder}
                giftWrappingPrice={giftWrappingPrice}
              />
            </div>
          </Suspense>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
    user: state.auth.user,
    cart: state.pos.cart,
    smartPromotions: state.promotions.promotions,
    smartPromotionsDiscounts: state.pos.discounts,
    manuallyAppliedPromotion: state.pos.manuallyAppliedPromotion,
    totalPrice: state.pos.totalPrice,
    deliveryAreas: state.deliverySettings.areas,
    settings: state.auth.settings,
    store_products: state.products,
    branches: state.locations.branches,
    branchProducts: state.pos.branchProducts,
    currenctBranch: state.pos.currentBranch,
    tables: state.pos.tables,
    sideNavCollapsed: state.pos.sideNavCollapsed,
    recentlyReceived: state.orders.recentlyReceived,
    usedComponents: state.pos.usedComponents,
    externalSources: state.pos.externalSources,
    address: state.pos.address,
    customer: state.pos.customer,
    gift: state.pos.gift,
    editOrder: state.pos.editOrder,
    pos: state.pos,
    defaultBranchPOSID: state.profile?.profile?.default_branch_pos_id,
    deliveryCharge: state.pos.deliveryCharge,
    orderSettings: state.pos.orderSettings,
    discounts: state.pos.appliedDiscounts,
    forcedSubtotal: state.pos.forcedSubtotal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeliveryRatesAndAreas: () => dispatch(fetchDeliveryRatesAndAreas()),
    updateOrderStatusAndPayment: (type, id, status, paymentStatus) =>
      dispatch(updateOrderStatusAndPayment(type, id, status, paymentStatus)),
    clearCart: (resetComponents) => dispatch(clearCart(resetComponents)),
    fetchAllProducts: () => dispatch(fetchAllProducts()),
    fetchBranchDeliveryCharges: (bid) =>
      dispatch(fetchBranchDeliveryCharges(bid)),
    fetchStoreBranches: () => dispatch(fetchStoreBranches()),
    hideHeader: () => dispatch(hideHeader()),
    setCurrentBranch: (branch) => dispatch(setCurrentBranch(branch)),
    fetchBranchProducts: (branch) => dispatch(fetchBranchProducts(branch)),
    collapseSideNav: () => dispatch(collapseSideNav()),
    fetchExternalSources: () => dispatch(fetchExternalSources()),
    addProductToCart: (payload) => dispatch(addProductToCart(payload)),
    updateUsedComponents: (usedComponents) =>
      dispatch(updateUsedComponents(usedComponents)),
    checkPromotionsPOS: () => dispatch(checkPromotionsPOS()),
    fetchPromotions: (t) => dispatch(fetchPromotions(true, t)),
    setAddressPOS: (params) => dispatch(setAddressPOS(params)),
    setCustomerPOS: (params) => dispatch(setCustomerPOS(params)),
    resetPromotionsPOS: (params) => dispatch(resetPromotionsPOS(params)),
    setEditOrder: (params) => dispatch(setEditOrder(params)),
    setGiftPOS: (params) => dispatch(setGiftPOS(params)),

    setDeliveryChargePOS: (params) => dispatch(setDeliveryChargePOS(params)),
    setOrderSettingsPOS: (params) => dispatch(setOrderSettingsPOS(params)),
    setOrderPOS: (params) => dispatch(setOrderPOS(params)), // Should use this for everything
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(index));
