export const JOIN_SOCKET = "JOIN_SOCKET";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const SET_ORDERS_LOADING_TRUE = "SET_ORDERS_LOADING_TRUE";
export const SET_SIDE_COLLAPSED = "SET_SIDE_COLLAPSED";
export const UPDATE_ORDER_STATUS_PAYMENT = "UPDATE_ORDER_STATUS_PAYMENT";
export const RECEIVE_PROMISE = "RECEIVE_PROMISE";
export const UPDATE_ORDERS_AFTER_BULK = "UPDATE_ORDERS_AFTER_BULK";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ERROR = "SET_ERROR";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const PERMISSIONS_LOADING = "PERMISSIONS_LOADING";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const UPDATE_ORDER_NOTES = "UPDATE_ORDER_NOTES";
export const DISPATCH_ORDER = "DISPATCH_ORDER";
export const TOGGLE_SOUND = "TOGGLE_SOUND";
export const FETCH_PAST_ORDERS = "FETCH_PAST_ORDERS";
export const EDIT_EXPECTED_DELIVERY = "EDIT_EXPECTED_DELIVERY";
export const EDIT_PAYMENT_TYPE = "EDIT_PAYMENT_TYPE";
export const EDIT_SPECIAL_REMARKS = "EDIT_SPECIAL_REMARKS";
export const SET_ORDERS_LOADING_FALSE = "SET_ORDERS_LOADING_FALSE";
export const CLEAR_PAST_ORDERS = "CLEAR_PAST_ORDERS";
export const STORE_SEARCHED_ORDERS = "STORE_SEARCHED_ORDERS";
export const CHANGE_ORDERS_LAYOUT = "CHANGE_ORDERS_LAYOUT";
export const SOCKET_MASHKOR_UPDATE_ORDER_STATUS =
  "SOCKET_MASHKOR_UPDATE_ORDER_STATUS";
export const SET_DHL_INFO = "SET_DHL_INFO";
export const SET_DHL_ORDER_DISPATCHED = "SET_DHL_ORDER_DISPATCHED";
export const UNDISPATCH_ORDER = "UNDISPATCH_ORDER";
export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const FETCH_TOPUPS = "FETCH_TOPUPS";
export const EDIT_BRANCH = "EDIT_BRANCH";
export const EDIT_TABLE_NUMBER = "EDIT_TABLE_NUMBER";
export const TRANSFER_ORDERS = "TRANSFER_ORDERS";
export const CANCEL_TOPUP = "CANCEL_TOPUP";
export const UPDATE_CUSTOMER_LAT_LNG = "UPDATE_CUSTOMER_LAT_LNG";

// Products
export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";
export const SET_PRODUCTS_FETCH_LOADING = "SET_PRODUCTS_FETCH_LOADING";
export const TOGGLE_PRODUCT_STATUS = "TOGGLE_PRODUCT_STATUS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UNDELETE_PRODUCT = "UNDELETE_PRODUCT";
export const CHANGE_INVENTORY = "CHANGE_INVENTORY";
export const QUICK_CHANGE_PRODUCT_IMAGE = "QUICK_CHANGE_PRODUCT_IMAGE";
export const ADD_PHOTO_TO_GALLERY = "ADD_PHOTO_TO_GALLERY";
export const DELETE_GALLERY_IMAGE = "DELETE_GALLERY_IMAGE";
export const DELETE_PRODUCT_IMAGE = "DELETE_PRODUCT_IMAGE";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_ASSOCIATED_BRANCHES = "UPDATE_ASSOCIATED_BRANCHES";
export const UPDATE_BRANCH_PRODUCT_MASTER = "UPDATE_BRANCH_PRODUCT_MASTER";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY_AVAILABILITY = "UPDATE_CATEGORY_AVAILABILITY";
export const TOGGLE_CATEGORY_STATUS = "TOGGLE_CATEGORY_STATUS";
export const CHANGE_CATEGORY_NAME = "CHANGE_CATEGORY_NAME";
export const CHANGE_CATEGORY_ORDER = "CHANGE_CATEGORY_ORDER";
export const CHANGE_CATEGORY_MINIMUM = "CHANGE_CATEGORY_MINIMUM";
export const QUICK_CHANGE_CATEGORY_IMAGE = "QUICK_CHANGE_CATEGORY_IMAGE";
export const QUICK_CHANGE_AUTOMATED_CATEGORY_IMAGE =
  "QUICK_CHANGE_AUTOMATED_CATEGORY_IMAGE";
export const DELETE_CATEGORY_IMAGE = "DELETE_CATEGORY_IMAGE";
export const DELETE_AUTOMATED_CATEGORY_IMAGE =
  "DELETE_AUTOMATED_CATEGORY_IMAGE";
export const TOGGLE_AUTOMATED_CATEGORY = "TOGGLE_AUTOMATED_CATEGORY";
export const SAVE_AUTOMATED_CATEGORY = "SAVE_AUTOMATED_CATEGORY";
export const DELETE_PRODUCT_AVAILABILITY = "DELETE_PRODUCT_AVAILABILITY";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const CREATE_OPTION = "CREATE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";
export const DUPLICATE_PRODUCT = "DUPLICATE_PRODUCT";
export const DELETE_VARIANT_KEY = "DELETE_VARIANT_KEY";
export const REPLACE_PRODUCTS = "REPLACE_PRODUCTS";
export const BULK_TOGGLE_CATEGORIES = "BULK_TOGGLE_CATEGORIES";
export const BULK_DELETE_CATEGORIES = "BULK_DELETE_CATEGORIES";
export const UPDATE_BOOKING_SLOT_LOCALLY = "UPDATE_BOOKING_SLOT_LOCALLY";
export const CHANGE_CATEGORY_PARENT = "CHANGE_CATEGORY_PARENT";
export const CHANGE_CATEGORY_PASSWORD = "CHANGE_CATEGORY_PASSWORD";
export const CHANGE_CATEGORY_BRAND_ID = "CHANGE_CATEGORY_BRAND_ID";
export const SAVE_BULK_OPTIONS_IN_PRODUCTS = "SAVE_BULK_OPTIONS_IN_PRODUCTS";
export const ADD_PRODUCT_COMPONENT = "ADD_PRODUCT_COMPONENT";
export const CONTROL_PRODUCT_COMPONENT = "CONTROL_PRODUCT_COMPONENT";
export const ADD_PRODUCT_UNIT = "ADD_PRODUCT_UNIT";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const CONTROL_UNIT = "CONTROL_UNIT";
export const BULK_DELETE_UNITS = "BULK_DELETE_UNITS";
export const CONTROL_SUPPLIER = "CONTROL_SUPPLIER";
export const BULK_DELETE_SUPPLIERS = "BULK_DELETE_SUPPLIERS";
export const BULK_UPDATE_COMPOSITE_INVENTORIES =
  "BULK_UPDATE_COMPOSITE_INVENTORIES";
export const CONTROL_EXTRA_FIELD = "CONTROL_EXTRA_FIELD";
export const ADD_EXTRA_FIELD = "ADD_EXTRA_FIELD";
export const UPDATE_PRODUCTS_EXTRA_FIELDS = "UPDATE_PRODUCTS_EXTRA_FIELDS";
export const UPDATE_PRODUCTS_OPTIONS = "UPDATE_PRODUCTS_OPTIONS";
export const UPDATE_EXTRA_FIELD = "UPDATE_EXTRA_FIELD";
export const UPDATE_PRODUCT_BARCODE = "UPDATE_PRODUCT_BARCODE";
export const RESET_PRODUCT_LIST = "RESET_PRODUCT_LIST";
export const ADD_MODIFIER = "ADD_MODIFIER";
export const UPDATE_MODIFIER = "UPDATE_MODIFIER";
export const CONTROL_MODIFIER = "CONTROL_MODIFIER";
export const FETCH_OPTIONS_GROUPS = "FETCH_OPTIONS_GROUPS";
export const SET_OPTIONS_GROUPS_LOADING = "SET_OPTIONS_GROUPS_LOADING";
export const ADD_OPTIONS_GROUP = "ADD_OPTIONS_GROUP";
export const CONTROL_OPTIONS_GROUP = "CONTROL_OPTIONS_GROUP";
export const TOGGLE_CATEGORY_MINGLING = "TOGGLE_CATEGORY_MINGLING";
export const CHANGE_PRICE = "CHANGE_PRICE";
export const CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL =
  "CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL";
export const UPDATE_CATEGORY_DESCRIPTION = "UPDATE_CATEGORY_DESCRIPTION";
export const RESTORE_PRODUCTS = "RESTORE_PRODUCTS";
export const FETCH_SECTIONS = "FETCH_SECTIONS";
export const SET_SECTIONS_LOADING = "SET_SECTIONS_LOADING";
export const ADD_SECTION = "ADD_SECTION";
export const CONTROL_SECTION = "CONTROL_SECTION";

// Locations
export const FETCH_STORE_BRANCHES = "FETCH_STORE_BRANCHES";
export const SET_STORE_BRACHES_LOADING_TRUE = "SET_STORE_BRACHES_LOADING_TRUE";
export const FETCH_COUNTRIES_AREAS = "FETCH_COUNTRIES_AREAS";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const UPDATE_BRANCH_BUSY_SOCKET = "UPDATE_BRANCH_BUSY_SOCKET";
export const UPDATE_PERMITTED_BRANCH_BUSY_SOCKET =
  "UPDATE_PERMITTED_BRANCH_BUSY_SOCKET";
export const BULK_TOGGLE_BRANCHES = "BULK_TOGGLE_BRANCHES";

export const FETCH_BRANCH_CATALOG = "FETCH_BRANCH_CATALOG";
export const FETCH_BRANCH_CATALOG_LOADING = "FETCH_BRANCH_CATALOG_LOADING";
export const TOGGLE_BRANCH_PRODUCT = "TOGGLE_BRANCH_PRODUCT";
export const DELETE_BRANCH_PRODUCT = "DELETE_BRANCH_PRODUCT";
export const OVERRIDE_BRANCH_PRODUCT_STOCK = "OVERRIDE_BRANCH_PRODUCT_STOCK";
export const OVERRIDE_BRANCH_PRODUCT_OPTIONS =
  "OVERRIDE_BRANCH_PRODUCT_OPTIONS";
export const SAVE_BRANCH_PRODUCT_INVENTORY = "SAVE_BRANCH_PRODUCT_INVENTORY";
export const TOGGLE_BRANCH_PRODUCT_OPTION = "TOGGLE_BRANCH_PRODUCT_OPTION";
export const BULK_TOGGLE_BRANCH_PRODUCTS = "BULK_TOGGLE_BRANCH_PRODUCTS";
export const BULK_DELETE_BRANCH_PRODUCTS = "BULK_DELETE_BRANCH_PRODUCTS";
export const FETCH_BRANCH_DELIVERY_CHARGES_LOADING =
  "FETCH_BRANCH_DELIVERY_CHARGES_LOADING";
export const FETCH_BRANCH_DELIVERY_CHARGES = "FETCH_BRANCH_DELIVERY_CHARGES";
export const TOGGLE_BRANCH_DELIVERY_CHARGE = "TOGGLE_BRANCH_DELIVERY_CHARGE";
export const DELETE_BRANCH_DELIVERY_CHARGE = "DELETE_BRANCH_DELIVERY_CHARGE";
export const BULK_TOGGLE_BRANCH_DELIVERY_CHARGES =
  "BULK_TOGGLE_BRANCH_DELIVERY_CHARGES";
export const BULK_DELETE_BRANCH_DELIVERY_CHARGES =
  "BULK_DELETE_BRANCH_DELIVERY_CHARGES";
export const OVERRIDE_DELIVERY_CHARGE = "OVERRIDE_DELIVERY_CHARGE";
export const OVERRIDE_DELIVERY_CHARGE_VALUE = "OVERRIDE_DELIVERY_CHARGE_VALUE";
export const BRANCH_BASED_ORDERING_SWITCH = "BRANCH_BASED_ORDERING_SWITCH";
export const FETCH_STORE_ROLES = "FETCH_STORE_ROLES";
export const ADD_TO_LOCATIONS_COUNT = "ADD_TO_LOCATIONS_COUNT";
export const OVERRIDE_BRANCH_PRODUCT_PREORDERING_STOCK =
  "OVERRIDE_BRANCH_PRODUCT_PREORDERING_STOCK";
export const SAVE_BRANCH_PRODUCT_PREORDERING_INVENTORY =
  "SAVE_BRANCH_PRODUCT_PREORDERING_INVENTORY";
export const COPY_SLOTS_TO_BRANCHES = "COPY_SLOTS_TO_BRANCHES";
export const UPDATE_BRANCH_COMPOSITE_INVENTORIES =
  "UPDATE_BRANCH_COMPOSITE_INVENTORIES";
export const BULK_TOGGLE_BRANCH_PRODUCT_OPTIONS =
  "BULK_TOGGLE_BRANCH_PRODUCT_OPTIONS";
export const UPDATE_BRANCH_SLOTS_LIMITS = "UPDATE_BRANCH_SLOTS_LIMITS";
export const FETCH_BRANCH_ZONES_LOADING = "FETCH_BRANCH_ZONES_LOADING";
export const FETCH_BRANCH_ZONES = "FETCH_BRANCH_ZONES";
export const FETCH_ZONES_LOADING = "FETCH_ZONES_LOADING";
export const FETCH_ZONES = "FETCH_ZONES";
export const UPDATE_BRANCH_BANNER = "UPDATE_BRANCH_BANNER";
export const CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL_BB =
  "CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL_BB";

// Business Orders
export const FETCH_BUSINESS_ORDERS = "FETCH_BUSINESS_ORDERS";
export const SET_BUSINESS_ORDERS_LOADING = "SET_BUSINESS_ORDERS_LOADING";
export const NEW_BUSINESS_ORDERS_RING = "NEW_BUSINESS_ORDERS_RING";
export const UPDATE_BUSINESS_ORDER = "UPDATE_BUSINESS_ORDER";
export const SAVE_BUSINESS_ORDER_NOTES = "SAVE_BUSINESS_ORDER_NOTES";
export const DISPATCH_BUSINESS_ORDER = "DISPATCH_BUSINESS_ORDER";
export const SAVE_PAST_ORDERS = "SAVE_PAST_ORDERS";
export const PERFORM_BUSINESS_BULK_ACTION = "PERFORM_BUSINESS_BULK_ACTION";
export const EDIT_BUSINESS_EXPECTED = "EDIT_BUSINESS_EXPECTED";
export const UNDISPATCH_ORDER_B2B = "UNDISPATCH_ORDER_B2B";

// FAQs
export const FETCH_FAQS = "FETCH_FAQS";
export const FETCH_FAQS_LOADING = "FETCH_FAQS_LOADING";

// Production and delivery slots
export const FETCH_PRODUCTION_WORKING_HOURS = "FETCH_PRODUCTION_WORKING_HOURS";
export const FETCH_PRODUCTION_WORKING_HOURS_LOADING =
  "FETCH_PRODUCTION_WORKING_HOURS_LOADING";
export const SLOT_CONTROL = "SLOT_CONTROL";
export const CHANGE_PD_DAY_START_END = "CHANGE_PD_DAY_START_END";
export const BULK_TOGGLE_SLOTS = "BULK_TOGGLE_SLOTS";
export const BULK_DELETE_SLOTS = "BULK_DELETE_SLOTS";
export const CREATE_NEW_SLOTS = "CREATE_NEW_SLOTS";
export const SWITCH_STORE_BUSY = "SWITCH_STORE_BUSY";
export const UPDATE_STORE_BUSY_DATE_TIME = "UPDATE_STORE_BUSY_DATE_TIME";
export const UPDATE_STORE_BUSY_DATE_TIME_SOCKET =
  "UPDATE_STORE_BUSY_DATE_TIME_SOCKET";

// Delivery Settings
export const FETCH_DELIVERY_RATES_AREAS = "FETCH_DELIVERY_RATES_AREAS";
export const FETCH_DELIVERY_RATES_AREAS_LOADING =
  "FETCH_DELIVERY_RATES_AREAS_LOADING";
export const TOGGLE_DELIVERY_CHARGE = "TOGGLE_DELIVERY_CHARGE";
export const DELETE_DELIVERY_CHARGE = "DELETE_DELIVERY_CHARGE";
export const CHANGE_DELIVERY_CHARGE_PRICE = "CHANGE_DELIVERY_CHARGE_PRICE";
export const ADD_AREA = "ADD_AREA";
export const DELIVERY_CHARGES_BULK_CONTROL = "DELIVERY_CHARGES_BULK_CONTROL";
export const DELIVERY_CHARGES_BULK_DELETE = "DELIVERY_CHARGES_BULK_DELETE";
export const CHANGE_DELIVERY_CONFIG = "CHANGE_DELIVERY_CONFIG";
export const CHANGE_AREA_OCIMS_ID = "CHANGE_AREA_OCIMS_ID";
export const ADD_PROVINCE = "ADD_PROVINCE";

// Appearance

// Custom texts
export const FETCH_CUSTOM_TEXTS_LOADING = "FETCH_CUSTOM_TEXTS_LOADING";
export const FETCH_CUSTOM_TEXTS = "FETCH_CUSTOM_TEXTS";
export const SAVE_CUSTOM_TEXTS = "SAVE_CUSTOM_TEXTS";
export const SAVE_DASHBOARD_CUSTOM_MESSAGES = "SAVE_DASHBOARD_CUSTOM_MESSAGES";
export const FETCH_DASHBOARD_CUSTOM_TEXTS = "FETCH_DASHBOARD_CUSTOM_TEXTS";

// Identity
export const CHANGE_STORE_LOGO = "CHANGE_STORE_LOGO";
export const CHANGE_STORE_COVER = "CHANGE_STORE_COVER";
export const CHANGE_STORE_FAVICON = "CHANGE_STORE_FAVICON";
export const SAVE_IDENTITY = "SAVE_IDENTITY";
export const DELETE_STORE_LOGO_COVER = "DELETE_STORE_LOGO_COVER";
export const SAVE_INVOICE_SETTINGS = "SAVE_INVOICE_SETTINGS";
export const SAVE_LAYOUT_THEME = "SAVE_LAYOUT_THEME";
export const CHANGE_FEATURES_FIELD = "CHANGE_FEATURES_FIELD";
export const CONTROL_STORE_GALLERY = "CONTROL_STORE_GALLERY";

//Promotions
export const CREATE_PROMOTION = "CREATE_PROMOTION";
export const FETCH_PROMOTIONS = "FETCH_PROMOTIONS";
export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const UNDELETE_PROMOTION = "UNDELETE_PROMOTION";
export const TOGGLE_PROMOTION_STATUS = "TOGGLE_PROMOTION_STATUS";
export const TOGGLE_COUPON_PRIVATE = "TOGGLE_COUPON_PRIVATE";
export const SET_PROMOTIONS_FETCH_LOADING = "SET_PROMOTIONS_FETCH_LOADING";
export const UPDATE_PROMOTION_DETAILS = "UPDATE_PROMOTION_DETAILS";
export const QUICK_CHANGE_PROMOTION_IMAGE = "QUICK_CHANGE_PROMOTION_IMAGE";
export const BULK_DELETE_PROMOTIONS = "BULK_DELETE_PROMOTIONS";
export const BULK_TOGGLE_PROMOTIONS = "BULK_TOGGLE_PROMOTIONS";
export const EXTEND_PROMOTION = "EXTEND_PROMOTION";
export const EDIT_PROMOTION = "EDIT_PROMOTION";

//WaitList
export const FETCH_WAITLISTS = "FETCH_WAITLISTS";
export const FETCH_WAITLISTS_ENTRIES = "FETCH_WAITLISTS_ENTRIES";
export const SET_WAITLISTS_FETCH_LOADING = "SET_WAITLISTS_FETCH_LOADING";
export const DELETE_WAITLIST = "DELETE_WAITLIST";
export const UNDELETE_WAITLIST = "UNDELETE_WAITLIST";
export const TOGGLE_WAITLIST_STATUS = "TOGGLE_WAITLIST_STATUS";
export const CREATE_WAITLIST = "CREATE_WAITLIST";
export const CHANGE_ENTRY_ORDER = "CHANGE_ENTRY_ORDER";
export const UPDATE_WAITLIST_NAMES = "UPDATE_WAITLIST_NAMES";
export const CREATE_WAITLIST_ENTRY = "CREATE_WAITLIST_ENTRY";
export const UPDATE_WAITLIST_ENTRY = "UPDATE_WAITLIST_ENTRY";
export const BULK_TOGGLE_WAITLIST_ENTRIES = "BULK_TOGGLE_WAITLIST_ENTRIES";
export const SOCKET_NEW_WAITLIST_ENTRY = "SOCKET_NEW_WAITLIST_ENTRY";
export const SOCKET_UPDATE_WAITLIST_ENTRY = "SOCKET_UPDATE_WAITLIST_ENTRY";
export const SOCKET_BULK_UPDATE_WAITLIST_ENTRY =
  "SOCKET_BULK_UPDATE_WAITLIST_ENTRY";
export const SOCKET_CHANGE_ENTRY_ORDER = "SOCKET_CHANGE_ENTRY_ORDER";
export const QUICK_CHANGE_WAITLIST_ENTRY = "QUICK_CHANGE_WAITLIST_ENTRY";

// Permissions
export const FETCH_ROLES_USERS = "FETCH_ROLES_USERS";
export const FETCH_ROLES_USERS_LOADING = "FETCH_ROLES_USERS_LOADING";
export const CREATE_STORE_ROLE = "CREATE_STORE_ROLE";
export const DELETE_STORE_ROLE = "DELETE_STORE_ROLE";
export const CHANGE_ROLE_NAME = "CHANGE_ROLE_NAME";
export const SWITCH_PERMISSIONS = "SWITCH_PERMISSIONS";
export const CHANGE_PERMISSION_AREAS = "CHANGE_PERMISSION_AREAS";
export const CHANGE_PERMISSION_BRANCHES = "CHANGE_PERMISSION_BRANCHES";
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";
export const BULK_CHANGE_USER_ROLE = "BULK_CHANGE_USER_ROLE";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";
export const COPY_BRANCHES_AREAS_TO_PERMISSIONS =
  "COPY_BRANCHES_AREAS_TO_PERMISSIONS";
export const DELETE_STORE_USER_ROLE = "DELETE_STORE_USER_ROLE";

// Staff
export const CHANGE_STAFF_SETTINGS = "CHANGE_STAFF_SETTINGS";

// Integrations
export const FETCH_INTEGRATION_LOADING = "FETCH_INTEGRATION_LOADING";
export const FETCH_PAYMENT_INTEGRATIONS = "FETCH_PAYMENT_INTEGRATIONS";
export const SAVE_PAYMENT_SETTINGS = "SAVE_PAYMENT_SETTINGS";
export const FETCH_DELIVERY_INTEGRATIONS = "FETCH_DELIVERY_INTEGRATIONS";
export const SAVE_DELIVERY_INTEGRATION_SETTINGS =
  "SAVE_DELIVERY_INTEGRATION_SETTINGS";
export const SAVE_FRONTLINES_MERCHANT_CODE = "SAVE_FRONTLINES_MERCHANT_CODE";
export const SAVE_MARKETING_INTEGRATION_SETTINGS =
  "SAVE_MARKETING_INTEGRATION_SETTINGS";
export const TOGGLE_SMS_ACTIVE = "TOGGLE_SMS_ACTIVE";
export const SAVE_BENEFIT_TRANSFER_SETTINGS = "SAVE_BENEFIT_TRANSFER_SETTINGS";
export const FETCH_INVENTORY_INTEGRATIONS = "FETCH_INVENTORY_INTEGRATIONS";
export const SAVE_INVENTORY_INTEGRATION_SETTINGS =
  "SAVE_INVENTORY_INTEGRATION_SETTINGS";

//Feedback
export const FETCH_FEEDBACKS = "FETCH_FEEDBACKS";
export const SET_FEEDBACKS_FETCH_LOADING = "SET_WAITLISTS_FETCH_LOADING";
export const TOGGLE_FEEDBACK_READBY = "TOGGLE_FEEDBACK_READBY";
export const SOCKET_NEW_FEEDBACK = "SOCKET_NEW_FEEDBACK";
export const BULK_SET_READ_BY_FEEDBACK = "BULK_SET_READ_BY_FEEDBACK";
export const SET_SURVIES_FETCH_LOADING = "SET_SURVIES_FETCH_LOADING";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const FETCH_SURVIES = "FETCH_SURVIES";
export const CREATE_SURVIES = "CREATE_SURVIES";
export const UPDATE_SURVIES = "UPDATE_SURVIES";

//Customers
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const SET_CUSTOMERS_FETCH_LOADING = "SET_CUSTOMERS_FETCH_LOADING";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const FETCH_USERS = "FETCH_USERS";
export const SET_CUSTOMER_WALLET = "SET_CUSTOMER_WALLET";
export const SET_CUSTOMER_POINTS = "SET_CUSTOMER_POINTS";

// Businesses
export const FETCH_BUSINESS_SALES_OVER_TIME = "FETCH_BUSINESS_SALES_OVER_TIME";
export const FETCH_BUSINESS_SALES_OVER_TIME_LOADING =
  "FETCH_BUSINESS_SALES_OVER_TIME_LOADING";
export const FETCH_DISTRIBUTORS_LOADING = "FETCH_DISTRIBUTORS_LOADING";
export const FETCH_MANIFEST = "FETCH_MANIFEST";
export const FETCH_MANIFEST_LOADING = "FETCH_MANIFEST_LOADING";
export const FETCH_DISTRIBUTORS = "FETCH_DISTRIBUTORS";
export const UPDATE_DISTRIBUTOR = "UPDATE_DISTRIBUTOR";
export const DELETE_DISTRIBUTOR = "DELETE_DISTRIBUTOR";
export const CREATE_DISTRIBUTOR = "CREATE_DISTRIBUTOR";
export const FETCH_DISTRIBUTOR_PRODUCTS = "FETCH_DISTRIBUTOR_PRODUCTS";
export const TOGGLE_DISTRIBUTOR_PRODUCT = "TOGGLE_DISTRIBUTOR_PRODUCT";
export const SAVE_DISTRIBUTOR_PRODUCT_FIELDS =
  "SAVE_DISTRIBUTOR_PRODUCT_FIELDS";
export const DELETE_DISTRIBUTOR_PRODUCT = "DELETE_DISTRIBUTOR_PRODUCT";
export const BULK_DELETE_DISTRIBUTOR_PRODUCTS =
  "BULK_DELETE_DISTRIBUTOR_PRODUCTS";
export const BULK_CONTROL_DISTRIBUTOR_PRODUCTS =
  "BULK_CONTROL_DISTRIBUTOR_PRODUCTS";
export const FETCH_DISTRIBUTOR_BRANCHES = "FETCH_DISTRIBUTOR_BRANCHES";
export const CREATE_DISTRIBUTOR_BRANCH = "CREATE_DISTRIBUTOR_BRANCH";
export const UPDATE_DISTRIBUTOR_BRANCH = "UPDATE_DISTRIBUTOR_BRANCH";
export const DELETE_DISTRIBUTOR_BRANCH = "DELETE_DISTRIBUTOR_BRANCH";
export const BULK_DELETE_DISTRIBUTOR_BRANCHES =
  "BULK_DELETE_DISTRIBUTOR_BRANCHES";
export const BULK_SET_DIST_BRANCH_DELIVERY_RATE =
  "BULK_SET_DIST_BRANCH_DELIVERY_RATE";
export const CHANGE_ORDERS_LAYOUT_B2B = "CHANGE_ORDERS_LAYOUT_B2B";
export const FETCH_DISTRIBUTOR_DISCOUNTS = "FETCH_DISTRIBUTOR_DISCOUNTS";
export const TOGGLE_DISTRIBUTOR_DISCOUNTS = "TOGGLE_DISTRIBUTOR_DISCOUNTS";
export const CREATE_DISTRIBUTOR_DISCOUNT = "CREATE_DISTRIBUTOR_DISCOUNT";
export const DELETE_DISTRIBUTOR_DISCOUNT = "DELETE_DISTRIBUTOR_DISCOUNT";

// Customer bookings
export const FETCH_ORDER_ITEMS_LOADING = "FETCH_ORDER_ITEMS_LOADING";
export const FETCH_ORDER_ITEMS = "FETCH_ORDER_ITEMS";
export const SAVE_BOOKING_ORDER = "SAVE_BOOKING_ORDER";
export const SOCKET_NEW_BOOKING_ORDER = "SOCKET_NEW_BOOKING_ORDER";
export const SOCKET_REFETCH_BOOKING_ORDER = "SOCKET_REFETCH_BOOKING_ORDER";
export const UPDATE_CUSTOMER_BOOKING_STATUS = "UPDATE_CUSTOMER_BOOKING_STATUS";
export const EDIT_BOOKING_DATE_AND_TIME = "EDIT_BOOKING_DATE_AND_TIME";

// Reports

// Downloads
export const SOCKET_FILE_IS_READY = "SOCKET_FILE_IS_READY";
export const FETCH_DOWNLOADS = "FETCH_DOWNLOADS";
export const FETCH_DOWNLOADS_LOADING = "FETCH_DOWNLOADS_LOADING";

//POS
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const EDIT_PRODUCT_QUANTITY = "EDIT_PRODUCT_QUANTITY";
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const HIDE_HEADER = "HIDE_HEADER";
export const SET_BRANCH_PRODUCTS_FETCH_LOADING =
  "SET_BRANCH_PRODUCTS_FETCH_LOADING";
export const FETCH_ALL_BRANCH_PRODUCTS = "FETCH_ALL_BRANCH_PRODUCTS";
export const SET_CURRET_BRANCH = "SET_CURRET_BRANCH";
export const COLLAPSE_SIDENAV = "COLLAPSE_SIDENAV";
export const UPDATE_USED_COMPONENTS = "UPDATE_USED_COMPONENTS";
export const UPDATE_POS_BRANCH_PRODUCT = "UPDATE_POS_BRANCH_PRODUCT";
export const FETCH_EXTERNAL_SOURCES = "FETCH_EXTERNAL_SOURCES";
export const SET_EXTERNAL_SOURCE_FETCH_LOADING =
  "SET_EXTERNAL_SOURCE_FETCH_LOADING";
export const ADD_EXTERNAL_SOURCE = "ADD_EXTERNAL_SOURCE";
export const CONTROL_EXTERNAL_SOURCE = "CONTROL_EXTERNAL_SOURCE";
export const EDIT_PRODUCT_FROM_CART = "EDIT_PRODUCT_FROM_CART";
export const SET_POS_BRANCH_DELIVERY_CHARGES =
  "SET_POS_BRANCH_DELIVERY_CHARGES";
export const OVERRIDE_ITEM_PRICE = "OVERRIDE_ITEM_PRICE";
export const FETCH_TABLES = "FETCH_TABLES";
export const POS_CHECK_PROMOTIONS = "POS_CHECK_PROMOTIONS";
export const POS_SET_CUSTOMER = "POS_SET_CUSTOMER";
export const POS_SET_ADDRESS = "POS_SET_ADDRESS";
export const POS_RESET_PROMOTIONS = "POS_RESET_PROMOTIONS";
export const POS_SET_EDIT_ORDER = "POS_SET_EDIT_ORDER";
export const POS_SET_GIFT = "POS_SET_GIFT";
export const POS_SET_MANUALLY_APPLIED_PROMOTION =
  "POS_SET_MANUALLY_APPLIED_PROMOTION";

export const POS_SET_DELIVERY_CHARGE = "POS_SET_DELIVERY_CHARGE";
export const POS_SET_ORDER_SETTINGS = "POS_SET_ORDER_SETTINGS";
export const POS_SET_ORDER = "POS_SET_ORDER";

// Customer Growth
export const FETCH_CUSTOMER_GROWTH = "FETCH_CUSTOMER_GROWTH";
export const FETCH_CUSTOMER_GROWTH_LOADING = "FETCH_CUSTOMER_GROWTH_LOADING";

// RFM
export const FETCH_RFM = "FETCH_RFM";

// Sales by location
export const FETCH_SALES_BY_LOCATION = "FETCH_SALES_BY_LOCATION";

// Closing report
export const FETCH_CLOSING_REPORT = "FETCH_CLOSING_REPORT";

// Sales by month
export const FETCH_SALES_BY_MONTH = "FETCH_SALES_BY_MONTH";

// Sales by day
export const FETCH_SALES_BY_DAY = "FETCH_SALES_BY_DAY";

// Sales by hour
export const FETCH_SALES_BY_HOUR = "FETCH_SALES_BY_HOUR";
export const FETCH_SALES_OVER_TIME = "FETCH_SALES_OVER_TIME";

// Sales by product
export const FETCH_SALES_BY_PRODUCT = "FETCH_SALES_BY_PRODUCT";

// Health
export const FETCH_HEALTH_LOADING = "FETCH_HEALTH_LOADING";
export const FETCH_HEALTH = "FETCH_HEALTH";
export const DISMISS_HEALTH = "DISMISS_HEALTH";

//Limit Orders
export const LIMIT_CONTROL = "LIMIT_CONTROL";
export const FETCH_ORDER_LIMTIS = "FETCH_ORDER_LIMTIS";
export const ADD_PRODUCT_LIMIT = "ADD_PRODUCT_LIMIT";
export const CONTROL_PRODUCT_LIMIT = "CONTROL_PRODUCT_LIMIT";
export const BRANCH_CREATE_NEW_LIMITS = "BRANCH_CREATE_NEW_LIMITS";
export const BRANCH_CONTROL_LIMIT = "BRANCH_CONTROL_LIMIT";
export const FETCH_ORDER_LIMTIS_LOADING = "FETCH_ORDER_LIMTIS_LOADING";
export const BRANCH_CONTROL_PRDOCUT_LIMIT = "BRANCH_CONTROL_PRDOCUT_LIMIT";
export const BRANCH_PRODUCT_CREATE_NEW_LIMITS =
  "BRANCH_PRODUCT_CREATE_NEW_LIMITS";

// Account settings
export const FETCH_ACCOUNT_INFO = "FETCH_ACCOUNT_INFO";
export const DELETE_INVOICE = "DELETE_INVOICE";

// Profile
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";

// Service Providers
export const FETCH_SERVICE_PROVIDERS = "FETCH_SERVICE_PROVIDERS";
export const CHANGE_PROVIDER_PHOTO = "CHANGE_PROVIDER_PHOTO";
export const DELETE_PROVIDER_PHOTO = "DELETE_PROVIDER_PHOTO";
export const EDIT_PROVIDER_FIELD = "EDIT_PROVIDER_FIELD";
export const CHANGE_PROVIDER_BRANCH = "CHANGE_PROVIDER_BRANCH";
export const TOGGLE_PROVIDER = "TOGGLE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const CREATE_NEW_PROVIDER = "CREATE_NEW_PROVIDER";
export const BULK_TOGGLE_PROVIDERS = "BULK_TOGGLE_PROVIDERS";
export const BULK_DELETE_PROVIDERS = "BULK_DELETE_PROVIDERS";
export const FETCH_PROVIDER_SERVICES = "FETCH_PROVIDER_SERVICES";
export const TOGGLE_PROVIDER_SERVICE = "TOGGLE_PROVIDER_SERVICE";
export const DELETE_PROVIDER_SERVICE = "DELETE_PROVIDER_SERVICE";
export const BULK_DELETE_PROVIDER_SERVICES = "BULK_DELETE_PROVIDER_SERVICES";
export const BULK_TOGGLE_PROVIDER_SERVICES = "BULK_TOGGLE_PROVIDER_SERVICES";
export const FETCH_PROVIDER_SLOTS = "FETCH_PROVIDER_SLOTS";
export const DELETE_RESOURCE_AVAILABILITY_SLOT =
  "DELETE_RESOURCE_AVAILABILITY_SLOT";
export const TOGGLE_RESOURCE_AVAILABILITY_SLOT =
  "TOGGLE_RESOURCE_AVAILABILITY_SLOT";
export const CREATE_RESOURCE_AVAILABILITY_SLOTS =
  "CREATE_RESOURCE_AVAILABILITY_SLOTS";
export const BULK_DELETE_RESOURCE_AVAILABILITIES =
  "BULK_DELETE_RESOURCE_AVAILABILITIES";
export const EDIT_RESOURCE_AVAILABILITY_INVENTORY =
  "EDIT_RESOURCE_AVAILABILITY_INVENTORY";
export const EDIT_RESOURCE_AVAILABILITY = "EDIT_RESOURCE_AVAILABILITY";
export const EDIT_BREAK_MINUTES = "EDIT_BREAK_MINUTES";

// Service bookings
export const FETCH_SERVICE_ORDER_ITEMS_LOADING =
  "FETCH_SERVICE_ORDER_ITEMS_LOADING";
export const FETCH_SERVICE_ORDER_ITEMS = "FETCH_SERVICE_ORDER_ITEMS";
export const SAVE_SERVICE_ORDER = "SAVE_SERVICE_ORDER";
export const SOCKET_NEW_SERVICE_ORDER = "SOCKET_NEW_SERVICE_ORDER";
export const UPDATE_CUSTOMER_SERVICE_STATUS = "UPDATE_CUSTOMER_SERVICE_STATUS";
export const EDIT_SERVICE_ORDER_DATE_TIME = "EDIT_SERVICE_ORDER_DATE_TIME";

// Pages
export const FETCH_STATIC_PAGES = "FETCH_STATIC_PAGES";
export const TOGGLE_PAGE = "TOGGLE_PAGE";
export const DELETE_PAGE = "DELETE_PAGE";
export const CHANGE_PAGE_WINDOW_TYPE = "CHANGE_PAGE_WINDOW_TYPE";
export const CREATE_NEW_PAGE = "CREATE_NEW_PAGE";

// Popup Banners
export const FETCH_POPUP_BANNERS = "FETCH_POPUP_BANNERS";
export const CREATE_POPUP_BANNER = "CREATE_POPUP_BANNER";
export const TOGGLE_POPUP_BANNER = "TOGGLE_POPUP_BANNER";
export const DELETE_POPUP_BANNER = "DELETE_POPUP_BANNER";
export const CHANGE_POPUP_BANNER_WINDOW_TYPE =
  "CHANGE_POPUP_BANNER_WINDOW_TYPE";

// Home page
export const FETCH_NEWS_POSTS = "FETCH_NEWS_POSTS";
export const LIKE_POST = "LIKE_POST";
export const DISLIKE_POST = "DISLIKE_POST";
export const WRITE_COMMENT = "WRITE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_POST = "DELETE_POST";
export const TOGGLE_POST = "TOGGLE_POST";

// Go
export const HIDE_GO_INTRO_CAROUSEL = "HIDE_GO_INTRO_CAROUSEL";

// Home
export const FETCH_HIGHLIGHTS_LOADING = "FETCH_HIGHLIGHTS_LOADING";
export const FETCH_HIGHLIGHTS = "FETCH_HIGHLIGHTS";

// Currency converter
export const SAVE_CURRENCY_CONVERTER_SETTINGS =
  "SAVE_CURRENCY_CONVERTER_SETTINGS";

//Driver
export const GET_DRIVER = "GET_DRIVER";
export const GET_DRIVER_ORDER_HISTORY = "GET_DRIVER_ORDER_HISTORY";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const TOGGLE_DRIVER_STATUS = "TOGGLE_DRIVER_STATUS";
export const CREATE_DRIVER = "CREATE_DRIVER";
export const CREATE_DRIVER_ERRORS = "CREATE_DRIVER_ERRORS";
export const GET_DRIVER_ORDER_HISTORY_ERROR = "GET_DRIVER_ORDER_HISTORY_ERROR";
export const CLEAR_DRIVER_ORDER_HISTORY = "CLEAR_DRIVER_ORDER_HISTORY";
export const CHANGE_DRIVER = "CHANGE_DRIVER";
export const CHANGE_DRIVER_PROFILE_PICTURE = "CHANGE_DRIVER_PROFILE_PICTURE";
export const CLEAR_DRIVER_ERROR = "CLEAR_DRIVER_ERROR";

export const HIDE_PASSWORD_CHANGE_MODAL = "HIDE_PASSWORD_CHANGE_MODAL";
export const SET_DISSMISS_DATE = "SET_DISSMISS_DATE";
