import {
  ADD_PRODUCT_TO_CART,
  EDIT_PRODUCT_QUANTITY,
  REMOVE_PRODUCT_FROM_CART,
  CLEAR_CART,
  HIDE_HEADER,
  SET_BRANCH_PRODUCTS_FETCH_LOADING,
  FETCH_ALL_BRANCH_PRODUCTS,
  SET_CURRET_BRANCH,
  COLLAPSE_SIDENAV,
  UPDATE_USED_COMPONENTS,
  UPDATE_POS_BRANCH_PRODUCT,
  ADD_EXTERNAL_SOURCE,
  CONTROL_EXTERNAL_SOURCE,
  FETCH_EXTERNAL_SOURCES,
  SET_EXTERNAL_SOURCE_FETCH_LOADING,
  EDIT_PRODUCT_FROM_CART,
  SET_POS_BRANCH_DELIVERY_CHARGES,
  OVERRIDE_ITEM_PRICE,
  FETCH_TABLES,
  POS_CHECK_PROMOTIONS,
  POS_SET_CUSTOMER,
  POS_SET_ADDRESS,
  POS_RESET_PROMOTIONS,
  POS_SET_EDIT_ORDER,
  POS_SET_GIFT,
  POS_SET_MANUALLY_APPLIED_PROMOTION,
  POS_SET_DELIVERY_CHARGE,
  POS_SET_ORDER_SETTINGS,
  POS_SET_ORDER,
} from "./actionTypes";

import instance from "../actions/instance";
import { message } from "antd";
import moment from "moment";
export const addProductToCart = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: ADD_PRODUCT_TO_CART,
      payload: params,
    });

    dispatch(checkPromotionsPOS());
  };
};

export const removeProductFromCart = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: REMOVE_PRODUCT_FROM_CART,
      payload: params,
    });

    dispatch(checkPromotionsPOS());
  };
};

export const editProductQuantity = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: EDIT_PRODUCT_QUANTITY,
      payload: params,
    });

    dispatch(checkPromotionsPOS());
  };
};

export const clearCart = (resetComponents = true) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_CART,
    });
    if (resetComponents) {
      dispatch({
        type: UPDATE_USED_COMPONENTS,
        payload: {},
      });
    }
  };
};

export const hideHeader = () => {
  return {
    type: HIDE_HEADER,
  };
};

export const collapseSideNav = () => {
  return {
    type: COLLAPSE_SIDENAV,
  };
};

export const setCurrentBranch = (branch) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CURRET_BRANCH,
      payload: branch,
    });
    dispatch(checkPromotionsPOS());
  };
};

export const fetchBranchProducts = (branch) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_BRANCH_PRODUCTS_FETCH_LOADING,
    });
    try {
      let page = 1;
      let numberOfPages = 0; //Any initial value

      do {
        let currentBranch = getState().pos.currentBranch;
        if (branch !== currentBranch) {
          break;
        }
        let res = await instance.post("/get_branch_product_list/", {
          page: page,
          branch: branch,
        });

        currentBranch = getState().pos.currentBranch;
        if (branch !== currentBranch) {
          break;
        }
        const products = res.data;

        numberOfPages = products.pages_count;
        page += 1;
        dispatch({ type: FETCH_ALL_BRANCH_PRODUCTS, payload: products });
      } while (page <= numberOfPages);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view products");
      else
        message.error("Something went wrong. Refresh the page and try again");
    }
  };
};

export const updateUsedComponents = (usedComponents) => {
  return {
    type: UPDATE_USED_COMPONENTS,
    payload: usedComponents,
  };
};

export const updatePOSBranchProduct = (product) => {
  return {
    type: UPDATE_POS_BRANCH_PRODUCT,
    payload: product,
  };
};

export const fetchExternalSources = () => {
  return async (dispatch) => {
    dispatch({ type: SET_EXTERNAL_SOURCE_FETCH_LOADING });
    try {
      let res = await instance.get("/fetch_external_sources/");

      dispatch({ type: FETCH_EXTERNAL_SOURCES, payload: res.data });
    } catch (err) {
      message.error("SomethingWentWrong");
    }
  };
};
export const addExternalSource = (source) => {
  return {
    type: ADD_EXTERNAL_SOURCE,
    payload: source,
  };
};

export const controlExternalSource = (payload) => {
  return {
    type: CONTROL_EXTERNAL_SOURCE,
    payload: payload,
  };
};

export const editProductFromCart = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: EDIT_PRODUCT_FROM_CART,
      payload: params,
    });
    dispatch(checkPromotionsPOS());
  };
};

export const setPOSBranchDeliveryCharges = (params) => {
  return {
    type: SET_POS_BRANCH_DELIVERY_CHARGES,
    payload: params,
  };
};

export const overrideItemPrice = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: OVERRIDE_ITEM_PRICE,
      payload: params,
    });
    dispatch(checkPromotionsPOS());
  };
};

export const fetchTables = (params) => {
  return {
    type: FETCH_TABLES,
    payload: params,
  };
};
export const setCustomerPOS = (params) => {
  return async (dispatch, getState) => {
    dispatch({
      type: POS_SET_CUSTOMER,
      payload: params,
    });
    dispatch(checkPromotionsPOS());
  };
};

export const setGiftPOS = (params) => {
  return {
    type: POS_SET_GIFT,
    payload: params,
  };
};

export const setEditOrder = (params) => {
  return {
    type: POS_SET_EDIT_ORDER,
    payload: params,
  };
};

export const setAddressPOS = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: POS_SET_ADDRESS,
      payload: params,
    });

    dispatch(checkPromotionsPOS());
  };
};

export const checkPromotionsPOS = () => {
  return async (dispatch, getState) => {
    const isEditMode = getState().pos.editOrder;
    const storeSettings = getState().auth.settings;
    if (!storeSettings.enable_promotions || isEditMode) {
      return;
    }
    const promotions = getState().promotions?.promotions?.filter(
      (promo) =>
        promo.is_active &&
        !promo.is_expired &&
        promo.apply_to_pos_orders &&
        (!promo.available_in_branches.length ||
          promo.available_in_branches.includes(getState().pos.currentBranch)) &&
        promotionIsAvailable(promo.start, promo.availability)
    );

    const storeCategories = getState().pos?.branchProducts?.categories || [];

    dispatch({
      type: POS_CHECK_PROMOTIONS,
      payload: {
        promotions: promotions || [],
        storeSettings: storeSettings,
        storeCategories: storeCategories,
      },
    });
  };
};

export const resetPromotionsPOS = () => {
  return async (dispatch, getState) => {
    const promotions = getState().promotions?.promotions?.filter(
      (promo) => promo.is_active && !promo.is_expired
    );
    const storeSettings = getState().auth.settings;
    dispatch({
      type: POS_RESET_PROMOTIONS,
      payload: {
        promotions: promotions || [],
        storeSettings: storeSettings,
      },
    });
  };
};

export function promotionIsAvailable(start, availabilites) {
  const now = moment();
  let isAvailable = now.isSameOrAfter(moment(start, "YYYY-MM-DD HH:mm"));
  if (availabilites.length > 0) {
    let format = "hh:mm:ss";
    const currentTime = moment(now, format);
    const currentDay = now.day() === 0 ? 6 : now.day() - 1;
    isAvailable = availabilites.some(
      (av) =>
        (av.day === 7 || currentDay === av.day) &&
        currentTime.isBetween(moment(av.start, format), moment(av.end, format))
    );
  }
  return isAvailable;
}

export const getDHLQuote = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_SHIPPING_LOADING" });
    const address = getState().pos.address.deliveryAddress.address;
    const cart = getState().pos.cart;
    try {
      const res = await instance.post("/get_dhl_quote/", {
        cart: cart,
        area: address.area_id,
        bdc: getState().pos.currentBranch,
        country_code: getState().auth.settings.currency,
        from_page: "pos",
      });

      dispatch({ type: "FETCH_SHIPPING_DATA", payload: res.data });
      if (res.data.result === false) {
        dispatch({ type: "SET_SHIPPING_ERROR", payload: true });
        return res.data;
      }
      return res.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
};

export const setManuallyAppliedPromotion = (params) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: POS_SET_MANUALLY_APPLIED_PROMOTION,
      payload: params,
    });

    dispatch(checkPromotionsPOS());
  };
};

export const setDeliveryChargePOS = (params) => {
  return {
    type: POS_SET_DELIVERY_CHARGE,
    payload: params,
  };
};

export const setOrderSettingsPOS = (params) => {
  return {
    type: POS_SET_ORDER_SETTINGS,
    payload: params,
  };
};

export const setOrderPOS = (params) => {
  return {
    type: POS_SET_ORDER,
    payload: params,
  };
};
