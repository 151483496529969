import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

// Antd Components
import { withRouter } from "react-router-dom";
import "./TableScroller.css";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
// Actions
let t = undefined;
let start = 100;

class TableScroller extends Component {
  state = { stickyHeader: false };
  scrollRight = (x = 12) => {
    let antTable = document
      .getElementById(this.props.tableName)
      ?.getElementsByClassName("ant-table-content");
    if (antTable) antTable[0].scrollBy(x, 0);
  };
  repeatRight() {
    this.scrollRight();
    t = setTimeout(() => this.repeatRight(), start);
    start = start / 1.2;
  }
  scrollLeft = (x = 12) => {
    let antTable = document
      .getElementById(this.props.tableName)
      ?.getElementsByClassName("ant-table-content");
    if (antTable) antTable[0].scrollBy(-x, 0);
  };
  repeatLeft() {
    this.scrollLeft();
    t = setTimeout(() => this.repeatLeft(), start);
    start = start / 1.2;
  }

  onMouseDownRight = () => this.repeatRight();

  onMouseDownLeft = () => this.repeatLeft();

  onMouseUp() {
    clearTimeout(t);
    start = 50;
  }

  componentDidMount() {
    // we can safely store these values in instance variable
    // so that we just have to query the DOM once

    window.onscroll = () => {
      let tablePlace = document.querySelector(`#${this.props.tableName}`);
      if (window.pageYOffset > tablePlace?.offsetTop + 250) {
        this.setState({ stickyHeader: true });
      } else {
        this.setState({ stickyHeader: false });
      }
    };
  }

  render() {
    const { direction } = this.props;
    const divStyle =
      this.state.stickyHeader && this.props.fixate
        ? {
            position: "fixed",
            float: direction == "ltr" ? "right" : "left",
            marginBottom: 10,
            marginLeft: direction == "ltr" ? 10 : 0,
            marginRight: direction == "ltr" ? 0 : 10,
            top: 20,
            right: 21,
            zIndex: 100000,
            direction: "ltr",
          }
        : {
            float: direction == "ltr" ? "right" : "left",
            position: "relative",
            marginBottom: 10,
            marginLeft: direction == "ltr" ? 10 : 0,
            marginRight: direction == "ltr" ? 0 : 10,
            direction: "ltr",
          };

    return (
      <div style={divStyle} className="TableScroller">
        <Button
          onMouseUp={this.onMouseUp}
          onMouseDown={this.onMouseDownLeft}
          onClick={() => this.scrollLeft(20)}
          icon={
            <>
              <LeftOutlined />
            </>
          }
        />{" "}
        <Button
          onMouseUp={this.onMouseUp}
          onMouseDown={this.onMouseDownRight}
          onClick={() => this.scrollRight(20)}
          icon={
            <>
              <RightOutlined />
            </>
          }
        />
      </div>
    );
  }
}

export default withRouter(withNamespaces()(TableScroller));
