import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";

// Antd components
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import NewExternalSourceModal from "./NewExternalSourceModal";
import CustomPaymentModal from "./CustomPaymentModal";
// Actions
import { createNewCustomer } from "../../store/actions";
import { GetEnglishOrArabic } from "./";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getDHLQuote } from "../../store/actions/pos";
import instance from "../../store/actions/instance";

const { RangePicker } = TimePicker;
const { Option } = Select;

class OrderSettingsModal extends Component {
  state = {
    ...this.props.orderSettings,
    newExternalSourceModalVisible: false,
    customPaymentModallVisible: false,
    autoReceive: this.props.settings.automatically_receive_pos_orders,
    date:
      this.props.orderSettings.date ||
      moment().add(this.props.settings.minimum_lead_time_pos, "minutes"),
    start:
      this.props.orderSettings.start ||
      moment().add(this.props.settings.minimum_lead_time_pos, "minutes"),
    end:
      this.props.orderSettings.end ||
      moment().add(this.props.settings.minimum_lead_time_pos, "minutes"),

    paymentType:
      this.props.orderSettings.paymentType ||
      this.props.settings.default_payment_method_pos ||
      "cash",
  };

  createOrderObj(order) {
    const { t, config } = this.props;
    return {
      key: order.id,
      index: order.type == "Order" ? order.id : "-",
      status: order.status,
      type: order.is_delivery ? "Delivery" : "Pick up",
      placed: moment(order.placed, "YYYY-MM-DDTHH:mm:ss").format(
        "DD-MM-YYYY h:mm A"
      ),
      received: order.received
        ? moment(order.received, "YYYY-MM-DDTHH:mm:ss").format(
            "DD-MM-YYYY h:mm A"
          )
        : "-",
      customer: [`${order.first_name} ${order.last_name}`, order.phone],
      tracking: order.tracking_id,
      expectedDate: order.expected_delivery_date
        ? moment(order.expected_delivery_date, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )
        : "-",
      expectedTime: `${
        order.expected_delivery_slot_start
          ? moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
              "h:mm A"
            )
          : ""
      } - ${
        order.expected_delivery_slot_end
          ? moment(order.expected_delivery_slot_end, "HH:mm:ss").format(
              "h:mm A"
            )
          : ""
      }`,
      area: order.area
        ? config.language == "arabic"
          ? order.area.ar_name
            ? order.area.ar_name
            : "-"
          : order.area.name
          ? order.area.name
          : "-"
        : "-",
      paymentType:
        order.payment_method == "cash"
          ? "Cash"
          : order.payment_method == "pos"
          ? `POS`
          : order.payment_method == "benefit-transfer"
          ? t("Benefit Transfer")
          : order.payment_method == "wallet"
          ? t("Wallet")
          : order.payment_method == "custom"
          ? `Custom (${order.payment_ctype})`
          : `Online (${
              order.payment_method?.includes("link") && !order.payment_complete
                ? this.capitalizeFirstLetter(
                    order.payment_method.split(" link")[0].split("_")[0]
                  )
                : order.payment_ctype === "1"
                ? "Knet"
                : ["0", "2", "5"].includes(order.payment_ctype)
                ? "credit"
                : order.payment_ctype
            })`,
      paymentStatus: order.payment_complete == true ? "Complete" : "Incomplete",
      paymentReference: order.payment_reference ? order.payment_reference : "-",
      promise:
        order.type == "Promise" || order.status === "New POS" ? true : false,
      obj: order,
    };
  }

  openInvoiceModal = (selectedOrder, status, paid) => {
    const openChargeModal =
      selectedOrder.paymentType === "Cash" && paid === "Complete";
    this.props.setStateIndex({
      quickActionModal: !openChargeModal,
      chargeModalVisible: openChargeModal,
      selectedOrder: selectedOrder,
      orderStatusValueInModal: status,
      paymentStatusValueInModal: paid,
    });
  };
  placeOrder = async (editOrder) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    try {
      let res = await this.props.placeOrder({
        ...this.state,
        date: this.state.date.format("YYYY-MM-DD").toString(),
        start: this.state.start.format("HH:mm").toString(),
        end: this.state.end.format("HH:mm").toString(),
      });
      if (res?.data?.message) {
        message.error(
          res?.data?.message ||
            "Error while creating the order, please try again"
        );
        this.setState({ loading: false });
      } else {
        this.props.onClose();
        if (!editOrder) {
          this.openInvoiceModal(
            this.createOrderObj(res.data.order),
            res.data.order.status,
            res.data.order.payment_complete ? "Complete" : "Incomplete"
          );
        } else {
          message.success("Edited Successfully");
        }
        if (res.data.new_customer) {
          this.props.createNewCustomer(res.data.new_customer);
        }
      }
    } catch (err) {
      message.error(
        err.response?.data?.message ||
          "Error while creating the order, please try again"
      );

      this.setState({ loading: false });

      instance.post("log_error/", {
        title: "Place Order POS",
        store: this.props.settings?.domain,
        err: err.toString(),
        msg:
          err.response?.data?.message ||
          "Error while creating the order, please try again",
      });
    }
  };

  componentDidMount() {
    if (this.props.settings.enable_cash_for_pos === false) {
      this.setState({ paymentType: "online" });
    } else if (this.props.settings.enable_online_payments_for_pos === false) {
      this.setState({ paymentType: "cash" });
    }
    if (this.props.deliveryCharge === "TBD") {
      this.props.getDHLQuote();
    }
  }

  render() {
    const {
      t,
      config,
      visible,
      externalSources,
      externalSourcesLoading,
      editOrder,
      settings,
      customer,
      orderTotalPrice,
    } = this.props;
    const {
      paymentComplete,
      paymentType,
      date,
      start,
      end,
      loading,
      autoReceive,
      newExternalSourceModalVisible,
      customPaymentModallVisible,
      source,
      specialRemarks,
      paymentCType,
      paymentReference,
    } = this.state;

    const checkIfCashAndOnlineDisabled =
      this.props.settings.enable_cash_for_pos === false &&
      this.props.settings.enable_online_payments_for_pos === false;

    const disableButton =
      this.checkIfCashAndOnlineDisabled ||
      (this.props.settings.make_source_mandatory && this.state.source == null);
    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Finalize Order")}
          </div>
        }
        visible={visible}
        onCancel={!loading && this.props.onClose}
        footer={[
          <Button
            block
            loading={loading}
            className="posActionButton"
            style={{
              backgroundColor: disableButton ? "#f5f5f5" : "#009900",
              borderColor: disableButton ? "#d9d9d9" : "#009900",
            }}
            key="submit"
            type="primary"
            disabled={disableButton}
            onClick={() => this.placeOrder(editOrder)}
          >
            {t("Place Order")}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        {newExternalSourceModalVisible && (
          <NewExternalSourceModal
            visible={newExternalSourceModalVisible}
            onClose={(newSource) => {
              this.setState({
                newExternalSourceModalVisible: false,
                source: newSource ? newSource : source,
              });
            }}
          />
        )}
        {customPaymentModallVisible && (
          <CustomPaymentModal
            visible={customPaymentModallVisible}
            paymentCType={paymentCType}
            paymentReference={paymentReference}
            onClose={(paymentMethod, paymentReference) => {
              if (paymentMethod) {
                this.setState({
                  paymentType: "custom",
                  paymentCType: paymentMethod,
                  paymentReference,
                });
              }
              this.setState({ customPaymentModallVisible: false });
            }}
          />
        )}
        {!settings.enable_table_qr && (
          <div className="my-3 text-center">
            <p style={{ fontSize: 15 }}>{t("Date and Time")}</p>
            <Row justify="center">
              <Col span={8}>
                <p>{t("Date")}</p>
              </Col>
              <Col span={12}>
                <DatePicker
                  inputReadOnly={true}
                  value={date}
                  onChange={(e) => this.setState({ date: e })}
                />
              </Col>
            </Row>
            <Row className="mt-3" justify="center">
              <Col span={8}>
                <p>{t("Time")}</p>
              </Col>
              <Col span={12}>
                <RangePicker
                  order={false}
                  inputReadOnly={true}
                  allowClear={false}
                  format="h:mm a"
                  value={[start, end]}
                  use12Hours
                  onChange={(e) => this.setState({ start: e[0], end: e[1] })}
                />
              </Col>
            </Row>
          </div>
        )}
        <div className="px-2 my-4  text-center">
          <p style={{ fontSize: 15 }}>{t("Payment Method")}</p>

          {checkIfCashAndOnlineDisabled ? (
            <span
              style={{
                color: "#ff4d4f",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {t(
                `Cash and Online Payments are disabled. Please enable one of them from`
              )}{" "}
              <Link
                style={{
                  color: "#ff4d4f",
                  fontSize: 15,
                  fontWeight: 500,
                  textDecoration: "underline",
                }}
                to="/manage/Integration/Payment"
              >
                {t("payment settings.")}
              </Link>
            </span>
          ) : (
            <Radio.Group
              defaultValue="products"
              buttonStyle="solid"
              className="my-2  w-100"
              value={paymentType}
              onChange={(e) => {
                if (e.target.value !== "custom") {
                  this.setState({
                    paymentType: e.target.value,
                    paymentCType: "",
                    paymentReference: "",
                  });
                }
              }}
            >
              {this.props.settings.enable_cash_for_pos && (
                <Radio.Button className="posRadioButton" value={"cash"}>
                  {t("Cash")}
                </Radio.Button>
              )}
              {this.props.settings.enable_online_payments_for_pos && (
                <Radio.Button className="posRadioButton" value={"online"}>
                  {t("Online")}
                </Radio.Button>
              )}
              {settings.enable_wallet &&
                settings.enable_wallet_staff &&
                customer?.userID &&
                customer?.userDetails?.wallet >= orderTotalPrice && (
                  <Radio.Button className="posRadioButton" value={"wallet"}>
                    {t("Wallet")}
                    <span
                      style={{
                        position: "absolute",
                        fontSize: 10,
                        bottom: -1,
                        width: "100%",
                        right: 0,
                      }}
                    >
                      {customer?.userDetails?.wallet.toFixed(
                        settings.currency_decimals
                      )}
                    </span>
                  </Radio.Button>
                )}
              {this.props.settings.enable_custom_payments_for_pos && (
                <Radio.Button
                  className="posRadioButton"
                  value={"custom"}
                  onClick={() =>
                    this.setState({ customPaymentModallVisible: true })
                  }
                >
                  {t("Custom")}
                </Radio.Button>
              )}
            </Radio.Group>
          )}
        </div>
        {paymentType !== "wallet" && (
          <div className="px-2 my-4 text-center">
            <p style={{ fontSize: 15 }}>{t("Payment Completed ?")}</p>
            <Radio.Group
              defaultValue="products"
              buttonStyle="solid"
              className="my-2  w-100"
              value={paymentComplete}
              onChange={(e) => {
                this.setState({ paymentComplete: e.target.value });
              }}
            >
              <Radio.Button className="posRadioButton" value={true}>
                {t("Yes")}
              </Radio.Button>
              <Radio.Button className="posRadioButton" value={false}>
                {t("No")}
              </Radio.Button>
            </Radio.Group>
          </div>
        )}

        <div className="mt-3 mb-4 text-center">
          <p style={{ fontSize: 15 }}>{t("Special Remarks")}</p>
          <Input
            value={specialRemarks}
            placeholder={t("Special Remarks")}
            onChange={(e) =>
              this.setState({ specialRemarks: e.target.value.trimStart() })
            }
          />
        </div>

        <div className="mt-3 mb-4 text-center">
          <p style={{ fontSize: 15 }}>
            {" "}
            {t("Source")}{" "}
            {this.props.settings.make_source_mandatory && (
              <span
                style={{
                  color: "#ff4d4f",
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                {"*"}
              </span>
            )}
          </p>
          <Select
            allowClear
            clearIcon={
              <CloseCircleFilled
                onClick={(e) => {
                  this.setState({ source: null });
                }}
              />
            }
            showSearch
            loading={externalSourcesLoading}
            style={{ width: "100%", textAlign: "left" }}
            optionFilterProp="children"
            placeholder={
              this.props.settings.make_source_mandatory
                ? t("Select a source")
                : t("Select a source (optional)")
            }
            filterOption={(input, option) => {
              if (typeof option.children == "object") return false;

              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            value={source || undefined}
            onChange={(e) => {
              if (e === "new source") {
                this.setState({ newExternalSourceModalVisible: true });
              } else {
                this.setState({ source: e });
              }
            }}
          >
            {!externalSourcesLoading &&
              this.props.permissions.can_create_sources && (
                <Option key={"new source"} value={"new source"}>
                  <span style={{ whiteSpace: "break-spaces" }}>
                    {t("New Source")} <PlusOutlined className="mx-1" />
                  </span>
                </Option>
              )}
            {externalSources?.map((s) => (
              <Option key={s.id} value={s.id}>
                {GetEnglishOrArabic(s.name, s.ar_name, config.language)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mt-3 mb-4 text-center ">
          <p>
            <Checkbox
              className="mx-3"
              checked={autoReceive}
              onChange={(e) => {
                this.setState({ autoReceive: e.target.checked });
              }}
            />
            {`${t("Automatically Receive Order")}`}
          </p>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
    externalSources: state.pos.externalSources,
    externalSourcesLoading: state.pos.externalSourcesLoading,
    is_staff: state.auth.is_staff,
    settings: state.auth.settings,
    paymentMethods: state.pos.paymentMethods,
    customer: state.pos.customer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createNewCustomer: (customer) => dispatch(createNewCustomer(customer)),
    getDHLQuote: () => dispatch(getDHLQuote()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OrderSettingsModal));
