import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
// Antd Components
import { Modal } from "antd";
import { withRouter } from "react-router-dom";
import { ReactComponent as Caution } from "./assets/caution.svg";
import { ReactComponent as Bird } from "./assets/renewal.svg";
import { ReactComponent as Bird5 } from "./assets/renewal5.svg";
// Actions

class PaymentOverdueModal extends Component {
  state = {};

  render() {
    const { config, t, info, permissions, settings } = this.props;
    if (!info?.next_payment_date) return <></>;
    const remaining =
      moment(info.next_payment_date, "YYYY-MM-DD").diff(moment(), "days") + 1;
    let message =
      remaining < info.notify_before && remaining > 0
        ? t("Your account is due in *** days. ").replace("***", remaining)
        : t("Your account is overdue. ");
    if (permissions.can_view_billing)
      message = message.concat(t("Please visit the billing section to renew"));
    else message = message.concat(t("Please inform your manager to renew"));
    return (
      <Modal
        width={480}
        destroyOnClose={true}
        visible={true}
        onCancel={() => this.props.onClose()}
        okText={t("Go to billing")}
        cancelText={t("Close")}
        bodyStyle={{
          width: "auto",
          padding: "0px 18px",
          paddingTop: 20,
        }}
        okButtonProps={{
          style: { display: !permissions.can_view_billing && "none" },
        }}
        onOk={() => {
          this.props.history.replace("/manage/billing");
          this.props.onClose();
        }}
      >
        {settings.billing_fee_multiplier < 1 &&
        !!info.next_payment_date &&
        info.notify_before === 30 ? (
          <div className="p-2 pb-0 my-3 text-center">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {settings.billing_fee_multiplier === 0.9 ? (
                <Bird
                  width="1280"
                  height="1280"
                  style={{ width: 200, height: "100%" }}
                  className="hideOnMobile"
                />
              ) : settings.billing_fee_multiplier === 0.95 ? (
                <Bird5
                  width="1280"
                  height="1280"
                  style={{ width: 200, height: "100%" }}
                  className="hideOnMobile"
                />
              ) : (
                <></>
              )}
              <div
                style={{
                  textAlign: config.language === "arabic" ? "right" : "left",
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  {t("Your renewal is due in *** days")
                    .replace("***", remaining)
                    .replace(
                      "يوم",
                      remaining >= 3 && remaining <= 10 ? "أيام" : "يوم"
                    )}
                </p>
                <p
                  style={{
                    fontSize: 28,
                    color: "#0099cc",
                    marginBottom: 5,
                    lineHeight: 1,
                  }}
                >
                  {t("Renew early")}
                </p>
                <p
                  style={{
                    fontSize: 28,
                    color: "#0099cc",
                    lineHeight: 1,
                    marginBottom: 10,
                  }}
                >
                  <span style={{ color: "rgba(0, 0, 0, 0.65)" }}>{t("&")}</span>{" "}
                  {t("save")}{" "}
                  {(
                    info.fee -
                    +(settings.billing_fee_multiplier * info.fee).toFixed(1)
                  ).toFixed(3)}{" "}
                  {config.language == "arabic"
                    ? settings.currency_local
                    : settings.currency}
                </p>
                <p>
                  {t("This offer expires in *** days")
                    .replace("***", remaining - 15)
                    .replace(
                      "يوم",
                      remaining - 15 >= 3 && remaining - 15 <= 10
                        ? "أيام"
                        : "يوم"
                    )}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-2 pb-0 my-3 text-center">
            <Caution style={{ width: 100, height: 100 }} />
            <p className="mt-3">{message}</p>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    locations: state.locations,
    info: state.account.info,
    permissions: state.auth.permissions,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNamespaces()(PaymentOverdueModal))
);
