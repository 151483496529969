const isNotExcludedProuduct = (
  product,
  excludedProducts,
  excludedCategories
) => {
  if (
    excludedCategories.map((cat) => cat.id).includes(product.category_id) ||
    excludedProducts
      .map((p) => p.id)
      .some(
        (excludedProduct) =>
          product.id === excludedProduct || product.parent === excludedProduct
      )
  ) {
    return false;
  }
  return true;
};
let getParentCategories = (
  category,
  storeCategories,
  parentCategories = []
) => {
  const parent = storeCategories.find((cat) =>
    cat.sub_categories_ids?.split(",").includes(category?.toString())
  );
  if (parent) {
    parentCategories.push(parent.id);
    if (parent.is_child)
      return getParentCategories(parent.id, storeCategories, parentCategories);
  }
  return parentCategories;
};

////

let getOrderDiscountSmartPromotions = (
  price,
  cart,
  discounts,
  smartPromos,
  storeCategories,
  deliveryCharge
) => {
  let discount_amount = 0;

  if (discounts) {
    if (discounts.delivery.length) {
      discount_amount +=
        deliveryCharge * ((discounts.delivery[0].value || 100) / 100);
    }
    if (discounts.fixed[0]) {
      discount_amount += discounts.fixed[0].value;
    }
    if (discounts.percent[0]) {
      let store_discount = smartPromos.find(
        (promo) => promo.key === discounts.percent[0].promo
      );

      if (
        store_discount &&
        (store_discount.excluded_products.length ||
          store_discount.excluded_categories.length ||
          store_discount.excluded_modifiers.length)
      ) {
        discount_amount += getDiscountExcludeProducts(
          cart,
          store_discount.excluded_products,
          store_discount.excluded_categories,
          store_discount.excluded_modifiers,
          discounts.percent[0].value
        );
      } else {
        discount_amount += (price * discounts.percent[0].value) / 100;
      }
    }
    if (discounts.category.length) {
      discount_amount += getCategoryDiscountsAmount(
        cart,
        discounts.category,
        smartPromos,
        storeCategories
      );
    }
    if (discounts.products_percent.length) {
      discount_amount += getProductsDiscountsAmount(
        cart,
        discounts.products_percent,
        smartPromos
      );
    }
    if (discounts.modifier.length) {
      discount_amount += getModifiersDiscountsAmount(
        cart,
        discounts.modifier,
        smartPromos
      );
    }
  }

  return discount_amount;
};

let getDiscountExcludeProducts = (
  cart,
  excluded_products,
  excluded_categories,
  excludedModifiers,
  value
) => {
  let discount_amount = 0;
  cart.forEach((product) => {
    if (
      isNotExcludedProuduct(product, excluded_products, excluded_categories)
    ) {
      if (!excludedModifiers.length) {
        discount_amount +=
          ((product.total_price * product.quantity +
            product.quantityExcludedOptionsTotal) *
            value) /
          100;
      } else {
        let productTotalPrice =
          product.total_price * product.quantity +
          product.quantityExcludedOptionsTotal;

        // check for modifiers

        Object.entries(product.options).forEach(([optionID, option]) => {
          Object.entries(option.selectedOptions).forEach(
            ([choiceID, choice]) => {
              if (
                excludedModifiers.map((mod) => mod.id).includes(choice.modifier)
              ) {
                productTotalPrice -=
                  choice.price *
                  choice.quantity *
                  ((option.quantityExclusive && 1) || product.quantity);
              }
            }
          );
        });

        discount_amount += (productTotalPrice * value) / 100;
      }
    }
  });
  return discount_amount;
};
let getCategoryDiscountsAmount = (
  cart,
  categoryDiscounts,
  smartPromos,
  categories,
  discountExclustions = null
) => {
  let discountAmount = 0;
  cart.forEach((product) => {
    let parentCategories = [];
    if (product.category_is_child) {
      parentCategories = getParentCategories(product.category_id, categories);
    }
    categoryDiscounts.forEach((categoryDiscount) => {
      const categoriesIDs = categoryDiscount.categories.map((cat) => cat.id);
      if (
        categoriesIDs.includes(product.category_id) ||
        (parentCategories.length &&
          categoriesIDs.some((c) => parentCategories.includes(c)))
      ) {
        let store_discount =
          discountExclustions ||
          smartPromos.find((promo) => promo.key === categoryDiscount.promo);

        if (
          store_discount &&
          isNotExcludedProuduct(
            product,
            store_discount.excluded_products,
            store_discount.excluded_categories
          )
        ) {
          if (!store_discount.excluded_modifiers.length) {
            discountAmount +=
              ((product.total_price * product.quantity +
                product.quantityExcludedOptionsTotal) *
                categoryDiscount.value) /
              100;
          } else {
            let productTotalPrice =
              product.total_price * product.quantity +
              product.quantityExcludedOptionsTotal;

            // check for modifiers

            Object.entries(product.options).forEach(([optionID, option]) => {
              Object.entries(option.selectedOptions).forEach(
                ([choiceID, choice]) => {
                  if (
                    store_discount.excluded_modifiers
                      .map((mod) => mod.id)
                      .includes(choice.modifier)
                  ) {
                    productTotalPrice -=
                      choice.price *
                      choice.quantity *
                      ((option.quantityExclusive && 1) || product.quantity);
                  }
                }
              );
            });

            discountAmount +=
              (productTotalPrice * categoryDiscount.value) / 100;
          }
        }
      }
    });
  });

  return discountAmount;
};
let getProductsDiscountsAmount = (
  cart,
  productDiscounts,
  smartPromos,
  discountExclustions = null
) => {
  let discountAmount = 0;

  cart.forEach((product) => {
    productDiscounts.forEach((productDiscount) => {
      const productsIDs = productDiscount.products.map((cat) => cat.id);

      if (
        productsIDs.includes(product.id) ||
        productsIDs.includes(product.parent)
      ) {
        let store_discount =
          discountExclustions ||
          smartPromos.find((promo) => promo.key === productDiscount.promo);
        if (
          store_discount &&
          isNotExcludedProuduct(
            product,
            store_discount.excluded_products,
            store_discount.excluded_categories
          )
        ) {
          if (!store_discount.excluded_modifiers.length) {
            discountAmount +=
              ((product.total_price * product.quantity +
                product.quantityExcludedOptionsTotal) *
                productDiscount.value) /
              100;
          } else {
            let productTotalPrice =
              product.total_price * product.quantity +
              product.quantityExcludedOptionsTotal;

            // check for modifiers

            Object.entries(product.options).forEach(([optionID, option]) => {
              Object.entries(option.selectedOptions).forEach(
                ([choiceID, choice]) => {
                  if (
                    store_discount.excluded_modifiers
                      .map((mod) => mod.id)
                      .includes(choice.modifier)
                  ) {
                    productTotalPrice -=
                      choice.price *
                      choice.quantity *
                      ((option.quantityExclusive && 1) || product.quantity);
                  }
                }
              );
            });

            discountAmount += (productTotalPrice * productDiscount.value) / 100;
          }
        }
      }
    });
  });
  return discountAmount;
};

let getModifiersDiscountsAmount = (
  cart,
  modifiersDiscount,
  smartPromos,
  discountExclustions = null
) => {
  let discountAmount = 0;

  cart.forEach((product) => {
    modifiersDiscount.forEach((modifierDiscount) => {
      let store_discount =
        discountExclustions ||
        smartPromos.find((promo) => promo.key === modifierDiscount.promo);

      if (
        store_discount &&
        isNotExcludedProuduct(
          product,
          store_discount.excluded_products,
          store_discount.excluded_categories
        )
      ) {
        const modifiersIDs = modifierDiscount.modifiers.map((mod) => mod.id);
        Object.entries(product.options).forEach(([optionID, option]) => {
          Object.entries(option.selectedOptions).forEach(
            ([choiceID, choice]) => {
              if (
                modifiersIDs.includes(choice.modifier) &&
                !store_discount.excluded_modifiers
                  .map((mod) => mod.id)
                  .includes(choice.modifier)
              ) {
                discountAmount +=
                  (choice.price *
                    choice.quantity *
                    ((option.quantityExclusive && 1) || product.quantity) *
                    modifierDiscount.value) /
                  100;
              }
            }
          );
        });
      }
    });
  });
  return discountAmount;
};

export const preciseNumber = (number) => {
  return parseFloat(number.toPrecision(7));
};
function getItemTotalExcludeModifiers(product, excludedModifiers) {
  let options = product.options;
  let extras = product.extraFields;
  let productTotal = product.price * product.quantity;
  Object.values(options).forEach((option) => {
    Object.values(option.selectedOptions).forEach((choice) => {
      if (!excludedModifiers.map((mod) => mod.id).includes(choice.modifier)) {
        productTotal +=
          choice.quantity *
          choice.price *
          (option.quantityExclusive ? 1 : product.quantity);
      }
    });
  });

  Object.values(extras || {}).forEach((extra) => {
    productTotal +=
      extra.price * (extra.quantityExclusive ? 1 : product.quantity);
  });
  return preciseNumber(productTotal);
}

function getTotalCart(
  cart,
  excludedProducts,
  excludedCategories,
  excludedModifiers
) {
  let total = 0;
  let quantity = 0;

  cart.forEach((item) => {
    if (
      !item.discount &&
      (!excludedProducts ||
        isNotExcludedProuduct(item, excludedProducts, excludedCategories))
    ) {
      if (excludedModifiers?.length) {
        total += getItemTotalExcludeModifiers(item, excludedModifiers);
      } else {
        total +=
          (item.forcedPrice != null ? item.forcedPrice : item.total_price) *
            item.quantity +
          item.quantityExcludedOptionsTotal;
      }
      quantity += item.quantity;
    }
  });
  return [total, quantity];
}

function GetCartQuantity(product, cart) {
  let quantity = 0;
  cart.forEach((p) => {
    if (
      !p.discount &&
      (p.id === product.product_id || p.parent === product.product_id)
    ) {
      quantity += p.quantity;
    }
  });
  return quantity;
}

function CheckPromoProducts(products, cart, multiplier, conditionQuantity) {
  let cartQuantity = products.reduce(
    (quantity, product) => GetCartQuantity(product, cart) + quantity,
    0
  );
  if (conditionQuantity * multiplier > cartQuantity) {
    return [false, parseInt(cartQuantity / conditionQuantity)];
  }
  return [true, parseInt(cartQuantity / conditionQuantity)];
}

function getCategoryTotal(
  cart,
  categories_ids,
  excludedProducts,
  excludedCategories,
  excludedModifiers,
  storeCategories
) {
  let total = 0;
  let quantity = 0;
  cart
    .filter((item) => !item.discount)
    .forEach((item) => {
      let parentCategories = [];
      if (item.category_is_child) {
        parentCategories = getParentCategories(
          item.category_id,
          storeCategories
        );
      }
      if (
        (categories_ids.includes(item.category_id) ||
          (parentCategories.length &&
            categories_ids.some((c) => parentCategories.includes(c)))) &&
        isNotExcludedProuduct(item, excludedProducts, excludedCategories)
      ) {
        if (excludedModifiers.length) {
          total += getItemTotalExcludeModifiers(item, excludedModifiers);
        } else {
          total +=
            item.total_price * item.quantity +
            item.quantityExcludedOptionsTotal;
        }
        quantity += item.quantity;
      }
    });
  return [total, quantity];
}

const isApplicableCategeoryPromo = (categories, product, storeCategories) => {
  if (categories.includes(product.category_id)) return true;
  if (product.category_is_child) {
    const parentCategories = getParentCategories(
      product.category_id,
      storeCategories
    ).parentCategories;
    return categories.some((c) => parentCategories.includes(c));
  }
  return false;
};

////

let getOrderDiscount = (
  price,
  cart,
  discounts,
  smartPromos,
  storeCategories,
  deliveryCharge
) => {
  let discount_amount = 0;

  discounts.forEach((d) => {
    if (["delivery", "delivery_percent"].includes(d.type)) {
      discount_amount +=
        deliveryCharge *
        (((d.type === "delivery_percent" && d.amount) || 100) / 100);
    } else if (d.type == "fixed") {
      discount_amount += d.amount;
    } else if (d.type == "percent") {
      if (
        d.excludedProducts?.length ||
        d.excludedCategories?.length ||
        d.excludedModifiers?.length
      ) {
        discount_amount += getDiscountExcludeProducts(
          cart,
          d.excludedProducts,
          [],
          [],
          d.amount
        );
      } else {
        discount_amount += (price * d.amount) / 100;
      }
    } else if (d.type == "category") {
      discount_amount += getCategoryDiscountsAmount(
        cart,
        [d.discountedCateogires],
        smartPromos,
        storeCategories,
        {
          excluded_categories: d.excludedCateogires,
          excluded_products: d.excludedProducts,
          excluded_modifiers: d.excludedModifiers,
        }
      );
    } else if (d.type == "products_percent") {
      discount_amount += getProductsDiscountsAmount(
        cart,
        [d.discountedProducts],
        smartPromos,
        {
          excluded_categories: d.excludedCateogires,
          excluded_products: d.excludedProducts,
          excluded_modifiers: d.excludedModifiers,
        }
      );
    } else if (d.type == "modifier") {
      discount_amount += getModifiersDiscountsAmount(
        cart,
        [d.discountedModifiers],
        smartPromos,
        {
          excluded_categories: d.excludedCateogires,
          excluded_products: d.excludedProducts,
          excluded_modifiers: d.excludedModifiers,
        }
      );
    }
  });

  return discount_amount;
};

export {
  getOrderDiscount,
  getTotalCart,
  CheckPromoProducts,
  getCategoryTotal,
  isApplicableCategeoryPromo,
  isNotExcludedProuduct,
  getOrderDiscountSmartPromotions,
};
