const trans = {
  Tables: "الطاولات",
  "Dine-In": "الطاولات",
  "New table": "اضافة طاولة",
  "Scan count": "عدد المسحات",
  "Please assign a branch": "الرجاء اختيار فرع",
  Table: "طاولة",
  "Link copied to clipboard!": "تم نسخ الرابط للطاولة!",
  "Failed to copy link": "فشل نسخ الرابط",
  "Actions on selected tables": "التحكم بالطاولات المختارة",
  "Table name/number": "اسم/رقم الطاولة",
  "Table toggled successfully": "تم تغيير حالة الطاولة بنجاح",
  "Table deleted successfully": "تم حذف الطاولة بنجاح",
  "Table created successfully": "تم اضافة الطاولة بنجاح",
};

export default trans;
