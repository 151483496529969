import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import { Modal, Input, Button } from "antd";
import { setDeliveryChargePOS } from "../../store/actions/pos";

class DeliveryChargeModal extends Component {
  state = { deliveryCharge: this.props.deliveryCharge };

  setDeliveryCharge = () => {
    this.props.setDeliveryChargePOS({deliveryCharge: parseFloat(this.state.deliveryCharge),fromPage:"deliveryModal"});
    this.props.onClose();
  };

  render() {
    const { t, config, visible, settings } = this.props;
    const { deliveryCharge } = this.state;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Adjust delivery charge")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            className="posActionButton"
            block
            key="submit"
            type="primary"
            onClick={() => this.setDeliveryCharge()}
          >
            {t("Add Delivery Charge")}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="px-2 mb-3">
          <div className="mt-3">
            <p className="">{t("Delivery Charge")}</p>
            <Input
              type="number"
              autoFocus
              suffix={
                config.language === "english"
                  ? settings.currency_english
                  : settings.currency_local
              }
              className="w-100"
              onBlur={(e) => {
                e.target.value = Math.abs(e.target.value);
              }}
              min={0}
              placeholder={t("Enter delivery amount")}
              onChange={(e) =>
                this.setState({
                  deliveryCharge: e.target.value,
                })
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return { config: state.config, settings: state.auth.settings };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryChargePOS: (params) => dispatch(setDeliveryChargePOS(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(DeliveryChargeModal));
