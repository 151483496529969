import { HIDE_GO_INTRO_CAROUSEL } from "./actionTypes";

import instance from "./instance";
import { message } from "antd";

export const hideIntro = (closeForever) => {
  return async (dispatch) => {
    dispatch({ type: HIDE_GO_INTRO_CAROUSEL });

    if (closeForever) {
      try {
        const res = await instance.post("/hide_intro/");
      } catch (err) {
        console.error(err);
      }
    }
  };
};
