const trans = {
  "Customer bookings": "حجوزات العملاء",
  "Customer Bookings": "حجوزات العملاء",
  "Date range": "تحديد التاريخ",
  Product: "المنتج",
  "Search for a booking": "ابحث عن حجز",
  "Booking date": "موعد الحجز",
  "Booking time": "وقت الحجز",
  Due: "غير مكتمل",
  "Booking details": "تفاصيل الحجز",
  Paid: "مدفوع",
  bookings: "حجوزات",
  "Please select the new date and time": "الرجاء اختيار التاريخ والوقت الجديد",
  "Edit booking date and time": "تعديل تاريخ ووقت الحجز",
  Events: "الحجوزات",
  "The total quantities of products booked. For example if you have Product A booked 2 times by Customer X, and Product B booked 3 Times by Customer Y, you have 5 bookings.":
  "الكمية الاجمالية للحجوزات. مثلا لمنتج أ مع ٣ حجوزات لعميل ب اجمالي الحجوزات ٣",

  "The number of unique products that have been booked. For example, if you have Product A booked 2 times by Customer A, and 3 times by Customer B, you have 1 total event.":
  "الحجوزات للمنتجات الفردية. مثلا لمنتج أ مع ٣ حجوزات لعميل ب و ٤ للعميل ج لنفس المنتج، اجمالي الحجوزات ١",


  "The unique number of customers. For example, if Customer A booked Product X and Product Y, and Customer B booked Product X, then the total customers is 2.":
  "عدد العملاء، مثلا اذا عميل أ حجز منتج ب و عميل ج حجز منتج ب اجمالي العملاء ٢",

  "The unique number of orders. For example, if Customer A booked Product X for April 1st 2022, and Product Y for April 2nd 2022 in the same Order M, the total invoices is 1.":
  "عدد الفواتير. مثلا اذا العميل أ حجز منتج ب لتاريخ ٢٤-٤-٢٠٢٢ و منتج ب لتاريخ ٢٩-٤-٢٠٢٢ في نفس الطلب، اجمالي الفواتير ١",

  "Get Bookings":"تحميل الحجوزات",
  "Bookings":"الكمية",
  "Time":"الوقت",
  "Detail":"التفاصيل",
};

export default trans;
