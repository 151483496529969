import React, { Component } from "react";
import { connect } from "react-redux";
import instance from "../../../../store/actions/instance";
import ResourceBookingSlots from "./ResourceBookingSlots";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { withNamespaces } from "react-i18next";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ResourceCell({
  resource,
  config,
  selectedResource,
  setSelectedResource,
}) {
  return (
    <div
      className="col-6 col-xl-4 col-lg-4"
      style={{
        width: "40%",
        paddingRight: 8,
        paddingLeft: 8,
        minHeight: "20vh",
        marginBottom: 15,
        borderRadius: 7,
        transform: !!selectedResource
          ? selectedResource.id == resource.id
            ? "scale(1.05)"
            : "scale(0.8)"
          : "none",
        filter: !!selectedResource
          ? selectedResource.id == resource.id
            ? "none"
            : "grayscale(100%)"
          : "none",
        transition: "all .1s ease",
      }}
      onClick={() => {
        setSelectedResource(resource);
      }}
    >
      <div
        style={{
          background: `url(${
            resource.photo_small || resource.photo
          }) no-repeat center center`,
          borderRadius: 7,
          backgroundSize: "cover",
          width: "100%",
          display: "block",
          cursor: "pointer",
          minHeight: "20vh",
        }}
      />
      <div
        style={{
          padding: 5,
          marginTop: 7,
          color:
            !!selectedResource && selectedResource.id != resource.id
              ? "#b9b9b9"
              : "rgba(0,0,0,.87)",
        }}
      >
        <p
          className={"mb-0 cut-text-one-line ".concat(
            config.direction == "ltr" ? "text-left pl-1" : "text-right pr-1"
          )}
          style={{ fontWeight: "bold" }}
        >
          {config.language == "arabic"
            ? resource.ar_name || resource.name
            : resource.name}
        </p>
        <p
          className={"mt-0 mb-0 cut-text-one-line ".concat(
            config.direction == "ltr" ? "text-left pl-1" : "text-right pr-1"
          )}
        >
          {config.language == "arabic"
            ? resource.branch.ar_name || resource.branch.name
            : resource.branch.name}
        </p>
      </div>
    </div>
  );
}

class ProductResources extends Component {
  selectTimeRef = React.createRef();

  state = {
    resources: [],
    resourcesLoading: true,
    selectedResource: null,

    slots: [],
    slotsLoading: false,
    selectedSlot: null,

    selectedDate: null,
  };

  componentDidMount = async () => {
    await this.fetchResources();
    this.setState({ resourcesLoading: false });
  };

  fetchResources = async () => {
    let res = null;
    const { settings, cart, pos } = this.props;
    if (settings.is_branch_based_ordering && !pos.currentBranch) return;

    res = await instance.post(`/get_product_resources_for_product/`, {
      service_id: this.props.current_product.id,
      branch: settings.is_branch_based_ordering ? pos.currentBranch : null,
      additional_slots: cart
        .filter((item) => !!item.resource)
        .map((item) => item.resourceSlot),
    });
    if (!res.data.success) {
      return;
    }
    if (res.data.resources.length == 0) this.props.setHasResources(false);
    else this.props.setHasResources(true);
    this.setState({ resources: res.data.resources });
    if (res.data.resources?.length === 1) {
      this.setState({ selectedResource: res.data.resources[0] });
      this.props.setResource(res.data.resources[0]);
      this.fetchSlots(res.data.resources[0].id);
      window.scrollTo({
        top: this.selectTimeRef.current.offsetTop + 20,
        behavior: "smooth",
      });
    }
  };

  fetchSlots = async (r) => {
    let res = null;
    const { cart } = this.props;
    this.setState({ slotsLoading: true });
    res = await instance.post(`/get_product_resource_slots/`, {
      pid: this.props.current_product.id,
      resource: r,
      additional_slots: cart
        .filter((item) => !!item.resource && item.resource.id == r)
        .map((item) => {
          return {
            date: item.resourceSlot.split(" ")[0],
            start: item.resourceSlot.split(" ")[1],
            end: item.resourceSlot.split(" ")[2],
            product: item.id,
            resource: item.resource.id,
          };
        }),
    });

    if (res.data.slots?.length) this.props.setHasResourceSlots(true);
    else this.props.setHasResourceSlots(false);

    this.setState({ slots: res.data.slots });
    this.setState({ slotsLoading: false });
  };

  render() {
    const { resourcesLoading, resources, slotsLoading, slots, selectedSlot } =
      this.state;
    const { cart, current_product, config, t } = this.props;

    // const itemInCart = cart.find(
    //   (item) => item.id == this.props.current_product.id
    // );
    // if (itemInCart) {
    //   let [date, start, end] = itemInCart.resourceSlot.split(" ");
    //   date = moment(date, "YYYY-MM-DD").format("LL");
    //   start = moment(start, "HH:mm:ss").format("h:mm A");
    //   end = moment(end, "HH:mm:ss").format("h:mm A");

    //   const dateAndTime = `${date} (${start} - ${end})`;
    //   return (
    //     <div
    //       className="border mt-4 pt-4"
    //       style={{
    //         backgroundColor: "white",
    //         paddingBottom: 30,
    //         scrollBehavior: "smooth",
    //         direction: language == "english" ? "ltr" : "rtl",
    //       }}
    //     >
    //       <p style={{ fontWeight: "bold", marginTop: 10 }}>
    //         {language == "english"
    //           ? "This service is in your cart already"
    //           : "هذا الحجز موجود في سلة مشترياتك"}
    //       </p>
    //       <p
    //         className={"text-muted mt-2 mb-3 ".concat(
    //           this.props.language == "english"
    //             ? "ml-4 text-left"
    //             : "mr-4 text-right"
    //         )}
    //         style={{ fontWeight: "bold" }}
    //       >
    //         {language == "english" ? "Your booking details" : "تفاصيل حجزك"}:
    //       </p>
    //       <p
    //         className={"mb-0 ".concat(
    //           this.props.language == "english"
    //             ? "ml-4 text-left"
    //             : "mr-4 text-right"
    //         )}
    //       >
    //         {language == "english" ? "With" : "مع"}:{" "}
    //         {language == "english"
    //           ? itemInCart.resource.name
    //           : itemInCart.resource.ar_name}
    //       </p>
    //       <p
    //         className={" ".concat(
    //           this.props.language == "english"
    //             ? "ml-4 text-left"
    //             : "mr-4 text-right"
    //         )}
    //       >
    //         {language == "english" ? "Date & time" : "التاريخ والوقت"}:{" "}
    //         {dateAndTime}
    //       </p>
    //     </div>
    //   );
    // }
    return (
      <div
        className="border mt-4 pt-4 mb-4"
        style={{
          backgroundColor: "white",
          paddingBottom:
            current_product.service_booking_style == "calendar" ? 10 : 30,
          scrollBehavior: "smooth",
        }}
      >
        <p
          className={"text-muted mt-2 mb-4 ".concat(
            config.direction == "ltr" ? "ml-4 text-left" : "mr-4 text-right"
          )}
          style={{ fontWeight: "bold" }}
        >
          {t("Select a service provider")}{" "}
          {this.props.resourceError && !this.state.selectedResource ? (
            <span style={{ color: "red" }}>
              - {t("Please select a provider")}
            </span>
          ) : null}
        </p>
        {!resourcesLoading ? (
          <div>
            {resources?.length ? (
              <div
                className="row"
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                  direction: config.direction,
                }}
              >
                {resources.map((resource) => (
                  <ResourceCell
                    resource={resource}
                    config={config}
                    selectedResource={this.state.selectedResource}
                    setSelectedResource={(r) => {
                      if (r.id != this.state.selectedResource?.id) {
                        this.setState({
                          selectedResource: r,
                          selectedDate: null,
                          selectedSlot: null,
                        });
                        this.props.setResource(r);
                        this.props.setResourceSlot(null);
                        this.fetchSlots(r.id);
                      }
                      window.scrollTo({
                        top: this.selectTimeRef.current.offsetTop + 20,
                        behavior: "smooth",
                      });
                    }}
                  />
                ))}
              </div>
            ) : (
              <p className="mx-3" style={{ color: "red" }}>
                {t(
                  "There are no available providers at the moment. Please try again later"
                )}
              </p>
            )}
          </div>
        ) : (
          <div className="mt-4 text-center">
            <Spin indicator={antIcon} />
          </div>
        )}
        <span ref={this.selectTimeRef}></span>
        {!!this.state.selectedResource ? (
          !slotsLoading ? (
            <>
              <p
                className={"mt-3 mb-0 ".concat(
                  config.direction == "ltr"
                    ? "ml-4 text-left"
                    : "mr-4 text-right"
                )}
                style={{
                  fontWeight: "bold",
                  direction: config.direction,
                }}
              >
                {t("Selected provider")}:{" "}
                {config.language == "arabic"
                  ? this.state.selectedResource.ar_name ||
                    this.state.selectedResource.name
                  : this.state.selectedResource.name}
              </p>
              <p
                className={"text-muted mb-4  ".concat(
                  config.direction == "ltr"
                    ? "ml-4 text-left"
                    : "mr-4 text-right"
                )}
                style={{
                  fontWeight: "bold",
                  direction: config.direction,
                }}
              >
                {t("Select a date and time")}{" "}
                {this.props.resourceError &&
                (!this.state.selectedSlot || this.state.selectedSlot == -1) ? (
                  <p style={{ color: "red" }}>
                    {t("Please select a date and time")}
                  </p>
                ) : null}
              </p>
              {slots?.length ? (
                <>
                  {current_product.service_booking_style === "calendar" && (
                    <Calendar
                      onChange={(date) => {
                        this.setState({
                          selectedDate: date
                            .addHours(3)
                            .toISOString()
                            .slice(0, 10),
                          selectedSlot: null,
                        });
                        this.props.setResourceSlot(null);
                        window.scrollTo({
                          top: this.selectTimeRef.current.offsetTop + 240,
                          behavior: "smooth",
                        });
                      }}
                      calendarType="Arabic"
                      tileDisabled={({ activeStartDate, date, view }) => {
                        return !slots
                          .map((slot) => slot.date)
                          .includes(
                            date.addHours(3).toISOString().slice(0, 10)
                          );
                      }}
                      locale={config.language == "arabic" ? "ar-KW" : "en"}
                    />
                  )}

                  <ResourceBookingSlots
                    current_product={current_product}
                    slots={slots}
                    selectedDate={this.state.selectedDate}
                    SetBookingSlot={(slotStr) => {
                      this.setState({ selectedSlot: slotStr });
                      this.props.setResourceSlot(slotStr);
                    }}
                    SetBookingError={() => {}}
                  />
                </>
              ) : (
                <p>{t("This provider is fully booked. Try again later")}</p>
              )}
            </>
          ) : (
            <div className="mt-4 text-center">
              <Spin indicator={antIcon} />
            </div>
          )
        ) : null}
      </div>
    );
  }
}

Date.prototype.addHours = function (h) {
  var copiedDate = new Date(this.getTime());
  copiedDate.setHours(copiedDate.getHours() + h);
  return copiedDate;
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    cart: state.pos.cart,
    settings: state.auth.settings,
    pos: state.pos,
  };
};

export default connect(mapStateToProps)(withNamespaces()(ProductResources));
