import {
  FETCH_BUSINESS_ORDERS,
  SET_BUSINESS_ORDERS_LOADING,
  UPDATE_BUSINESS_ORDER,
  SAVE_BUSINESS_ORDER_NOTES,
  DISPATCH_BUSINESS_ORDER,
  SAVE_PAST_ORDERS,
  PERFORM_BUSINESS_BULK_ACTION,
  EDIT_BUSINESS_EXPECTED,
  CHANGE_ORDERS_LAYOUT_B2B,
  UNDISPATCH_ORDER_B2B,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchBusinessOrders = (t) => {
  return async (dispatch) => {
    dispatch({ type: SET_BUSINESS_ORDERS_LOADING });

    try {
      const res = await instance.get(`/get_business_orders/`);
      if (res.data.success == true) {
        dispatch({ type: FETCH_BUSINESS_ORDERS, payload: res.data });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const updateBusinessOrder = (order) => {
  return {
    type: UPDATE_BUSINESS_ORDER,
    payload: order,
  };
};

export const saveBussinessOrderNotes = (oid, notes) => {
  return {
    type: SAVE_BUSINESS_ORDER_NOTES,
    payload: { oid: oid, notes: notes },
  };
};

export const savePastOrders = (pastOrders) => {
  return {
    type: SAVE_PAST_ORDERS,
    payload: pastOrders,
  };
};

export const dispatchBusinessOrderLocally = (
  id,
  dispatcher,
  reference,
  trackingLink,
  estimatedDuration,
  QR,
  walletCharge
) => {
  return {
    type: DISPATCH_BUSINESS_ORDER,
    payload: {
      id: id,
      dispatcher: dispatcher,
      reference: reference,
      trackingLink: trackingLink,
      estimatedDuration: estimatedDuration,
      QR: QR,
      walletCharge: walletCharge,
    },
  };
};

export const performBusinessBulkAction = (ids, status, paymentStatus) => {
  return {
    type: PERFORM_BUSINESS_BULK_ACTION,
    payload: { ids: ids, status: status, paymentStatus: paymentStatus },
  };
};

export const editBusinessExpectedDelivery = (id, date, username) => {
  return {
    type: EDIT_BUSINESS_EXPECTED,
    payload: { id, date, username },
  };
};

export const changeOrdersLayoutB2B = (layout) => {
  return {
    type: CHANGE_ORDERS_LAYOUT_B2B,
    payload: layout,
  };
};

export const undispatchOrderB2B = (id) => {
  return {
    type: UNDISPATCH_ORDER_B2B,
    payload: id,
  };
};
