import messages from "./messages";

let productsTranslation = {
  ...messages,
  Categories: "الاصناف",
  "New product": "اضافة منتج",
  "Search for a product": "ابحث عن منتج",
  "Hang tight while we load your products":
    "الرجاء الانتظار بينما نقوم بتجهيز منتجاتك",
  "Almost there...": "قاربنا على الانتهاء",
  Image: "الصورة",
  Category: "الصنف",
  Price: "السعر",
  Inventory: "المخزون",
  Import: "تحميل",
  Talabat: "طلبات",
  Deliveroo: "ديليفرو",
  Vthru: "فثرو",
  Cari: "كاري",
  Jahez: "جاهز",
  Snapchat: "سنابشات",
  Instagram: "انستجرام",
  Youtube: "يوتيوب",
  Spotify: "سبوتيفي",
  Other: "اخر",
  "Google play": "متجر جوجل",
  "App Store": "اب ستور",
  "Google maps": "خرائط جوجل",
  "Product Template": "ملف نموذجي",
  "Import Talabat Catalog": "تحميل كتلوج طلبات",
  "Import Deliveroo Catalog": "تحميل كتلوج ديليفرو",
  "Import Zyda Catalog": "تحميل كتلوج زيدا",
  "Import Mnasati Catalog": "تحميل كتلوج منصتي",
  "Import Magna Catalog": "تحميل كتلوج ماجنا",
  "Import with ordable/'s template": "تحميل منتجات من ملف اوردبل",
  '& click on "All Restaurants"': "و اختر جميع المطاعم",
  Visit: "تصفح الى",
  Importing: "جاري التحميل",
  "Find your restaurant & location until you see your menu":
    "اختر متجرك و حدد موقعك حتى تصل الى القائمة ",
  "& enter your location": "و حدد موقعك",
  "Copy your menu's URL (link) that looks like:":
    "انسخ رابط الصفحة المشابه للنموذج التالي:",
  "It contains: country, store ID, store name, & area ID":
    "يحتوي الرابط على الدولة، رمز المتجر، اسم المتجر، و رمز المنطقة",
  "Paste the link in the box below and press Import":
    "استخدم الرابط في الخانة في الاسفل و انقر على زر التحميل",
  "Copy and Paste your zyda's store link in the box below and press Import":
    "انسخ و الصق رابط متجر زيدا الخاص بك في الخانة في الاسفل و انقر على زر التحميل",
  "Copy and Paste your mnasati's store link in the box below and press Import":
    "انسخ و الصق رابط متجر منصتي الخاص بك في الخانة في الاسفل و انقر على زر التحميل",
  Zyda: "زيدا",
  Mnasati: "منصتي",
  "Once the import is complete you will receive an email":
    "بعد اكتمال عملية تحميل المنتجات، سوف نقوم بارسال بريد إلكتروني",
  "Please enter an email in your profile":
    "يرجى تزويدنا ببريد الكتروني في ملفك الشخصي",
  "Import your Talabat catalog": "تحميل كتلوج من طلبات",
  "Import your Deliveroo catalog": "تحميل كتلوج من ديليفرو",
  "Import your Zyda catalog": "تحميل كتلوج من زيدا",
  "Import your Mnasati catalog": "تحميل كتلوج من منصتي",
  "Enter Talabat Store Link": "رابط متجرك في طلبات",
  "Enter Deliveroo Store Link": "رابط متجرك في ديليفرو",
  "Enter Zyda Store Link": "رابط متجرك في زيدا",
  "Enter Mnasati Store Link": "رابط متجرك في منصتي",
  "Enter Store's Link": "رابط متجرك",

  "Your import will begin shortly. We will send you an email once complete if you have an email on your profile.":
    "ستبدأ عملية نقل المنتجات قريبا. عند اكمال العملية، سيتم ارسال بريد الكتروني على العنوان المسجل في ملفك الشخصي.",
  "Upload another menu": "حمل قائمة مختلفه",
  "Visit your catalog and refresh to see changes": "تصفح الى قائمة المنتجات",
  "Something went wrong. Please check the link and try again.":
    "حدث خطأ، تاكد من الرابط و حاول مرة اخرى",
  "Are you sure you want to delete this product?":
    "هل انت متأكد من حذف هذا المنتج؟",
  "Are you sure you want to delete this image?":
    "هل انت متأكد من حذف هذه الصورة؟",
  "Are you sure you want to delete this category?":
    "هل انت متأكد من حذف هذا الصنف؟",
  "Prep. Mins": "وقت التحضير",
  "Sort order": "الترتيب",
  "Has options": "يحتوي على خيارات",
  "Has variants": "يحتوي على انواع",
  Delete: "حذف",
  Item: "عام",
  Settings: "الاعدادات",
  "Search for settings": "بحث عن الاعدادات",
  Availability: "اوقات العرض",
  Options: "الخيارات",
  Variations: "الانواع",
  "General Info": "معلومات عامة",
  "Product Type": "نوع المنتج",
  "Select a product type": "اختر نوع المنتج",
  Stocked: "له مخزون",
  Digital: "رقمي",
  Bookable: "قابل للحجز",
  Produced: "محضر",
  "Main category": "الصنف الاساسي",
  "Other categories": "اصناف اخرى",
  "Other categories (optional)": "اصناف اخرى (اختياري)",
  "Discounted from": "السعر قبل التخفيض",
  "Product english name": "اسم المنتج بالانجليزي",
  "Short description": "وصف قصير",
  "Short english english": "وصف قصير باللغة الانجليزية",
  "Full description": "وصف كامل",
  "Arabic name": "الاسم بالعربي",
  "English name": "الاسم بالانجليزي",
  "Product arabic name": "اسم المنتج بالعربي",
  "Short arabic description": "وصف قصير باللغة العربية",
  "Main image": "الصورة الاساسية",
  Gallery: "معرض الصور",
  Upload: "رفع صورة",
  "Click or drag to change": "اضغط او اسحب صورة الى المربع لرفع صورة جديدة",
  Duplicate: "تكرار المنتج",
  "Inventory on hand": "المخزون المتاح حاليا",
  "Minimum holding inventory": "الحد الادنى من المخزون",
  Sku: "وحدة حفظ المخزون",
  "Prep. time in minutes": "وقت التحضير بالدقائق",
  "Advanced prep. time": "وقت تحضيز متقدم",
  "For every": "لكل",
  products: "منتج",
  "the product's prep. time is": "وقت تصضير المنتج سوف يكون",

  "General settings": "الاعدادات العامة",
  "Stretch photo": "تمديد الصورة",
  "Hide quantity box": "اخفاء صندوق الكمية",
  "Charge delivery": "تكليف التوصيل",
  "Product is delivered": "المنتج يمكن توصيله",
  "Allow special remarks": "السماح بالملاحظات",
  "No mingling": "منع اختلاط المنتج مع منتجات اخرى في سلة المشتريات",
  "Display when out of stock": "عرض عند انتهاء المخزون",
  "Hide buy button": "اخفاء زر اضافة المنتج لسلة الشراء",
  "You can limit days and time where this product is available":
    "يمكنك تحديد الايام و الاوقات التي سيتم فيها عرض المنتج",
  "Currently shows on all days": "حاليا يتم عرضه كل يوم",
  "Currently shows on days listed below":
    "حاليا يتم عرضه في الايام المدرجة ادناه",
  "Add limits": "اضافة قيود",
  Day: "يوم",
  Start: "البداية",
  End: "النهاية",
  Controls: "التحكم",
  "Every day": "كل يوم",
  Saturday: "السبت",
  Sunday: "الاحد",
  Monday: "الاثنين",
  Tuesday: "الثلاثاء",
  Wednesday: "الاربعاء",
  Thursday: "الخميس",
  Friday: "الجمعة",
  "Select a day": "اختر يوم",
  "Select a start": "اختر بداية",
  "Select an end": "اختر نهاية",
  "New category": "اضافة صنف",
  "Smart Categories": "الاصناف الذكية",
  "Search for a category": "ابحث في الاصناف",
  Icon: "الصورة",
  "Category english name": "اسم الصنف بالانجليزي",
  "Category arabic name": "اسم الصنف بالعربي",
  "You can enable smart categories that automatically show top sellers, new products and discounted products":
    "يمكنك تفعيل الاصناف الذكية التي بإمكانها عرض احدث المنتجات ،الاكثر مبيعا و المخفضة تلقائيا",
  "On/Off": "تشغيل/تعطيل",
  Limit: "عدد المنتجات",
  "Most Selling": "الاكثر مبيعا",
  "Newest Products": "احدث المنتجات",
  On: "مفعل",
  Off: "معطل",
  "categories selected": "اصناف محددة",
  "active categories": "اصناف مفعلة",
  "inactive categories": "اصناف معطلة",
  "Set status to": "تغيير الحالة الى",
  "Actions on selected categories": "التحكم في الاصناف المحددة",
  "Select a category": "اختر الصنف",
  "Product price": "سعر المنتج",
  "You can add the basic information of a product here.":
    "يمكنك اضافة المعلومات الاساسية للمنتج هنا",
  "Once you create your product you will be able to add more details.":
    "بعد القيام بإنشاء المنتج, سوف تتمكن من التحكم بالمزيد من التفاصيل",
  "There are different types of products": "هنالك عدة انواع من المنتجات",
  ProducedDescription: "محضر: يمكن تحضيره بدون قيود المخزون",
  StockedDescription: "له مخزون: منتجات لها مخزون محدود",
  BookableDescription:
    "قابل للحجز: منتجات يمكن حجزها (مثال: فعاليات او منتجات قابلة للتأجير)",
  DigitalDescription: "رقمي: منتجات تحتوي على ملفات يمكن تحميلها",
  RBBDescription: "خدمة: منتج يمكن حجزه مع موفر للخدمة لمدة معينة",
  "Product options can be added and removed here":
    "يمكن اضافة خيارات المنتج هنا",
  "Please enter the details for your option, and then enter the first choice of that option below.":
    "الرجاء ادخال تفاصيل الخيار ثم الخيار الاولى للخيار",
  "Is Required?": "مطلوب؟",
  "Allow Multiple?": "السماح بالتكرار؟",
  "Option Settings": "اعدادات الخيارات",
  "Arabic option name": "عنوان للخيارات بالعربي",
  "English option name": "عنوان للخيارات بالانجليزي",
  Minimum: "العدد الادنى",
  Maximum: "العدد الاقصى",
  "First Choice": "الخيار الاولى",
  "Add Option": "اضافة خيار",
  Option: "الخيار",
  option: "خيار",
  "Option order": "ترتيب الخيار",
  "English choice name": "اسم الخيار بالانجليزي",
  "Arabic choice name": "اسم الخيار بالعربي",
  Edit: "تعديل",
  Min: "ادنى",
  Max: "أقصى",
  Required: "مطلوب",
  Multiple: "يمكن تكراره",
  "Are you sure you want to delete this choice? You can't undo this":
    "هل انت متأكد من حذف هذه القيمة؟ لا يمكنك الرجوع بعد الحذف",
  "Fill in choice details": "املأ بيانات القيمة",
  "Edit Option": "تعديل الخيار",

  "Add availability for booking this product":
    "قم بإضافة مواعيد حجز هذا المنتج",
  "Search using date, start, end, inventory":
    "ابحث عن طريق التاريخ, البداية, النهاية, او كمية المخزون",
  "Are you sure you want delete the selected slots?":
    "هل انت متأكد من حذف الخانات المحددة؟",
  "slots selected": "خانات محددة",
  "All day": "طوال اليوم",
  "Turn off this slot": "تعطيل الخانة",
  "Duplicate this slot": "تكرار الخانة",
  "Delete this slot": "حذف الخانة",
  "Add new slot": "اضافة خانة جديدة",
  "Booking slots": "خانات الحجز",
  Date: "التاريخ",
  Total: "الاجمالي",
  "out of": "من اصل",
  "in your plan": "في باقتك",
  "You can increase your product's limit from":
    "يمكنك زيادة الحد الاقصى للمنتجات من",
  slot: "خانة",
  "File upload": "رفع الملفات",
  "Nutritional Facts": "القيم الغذائية",
  Calories: "السعرات الحرارية",
  "Protein (g)": "البروتين (جم)",
  "Fats (g)": "الدهون (جم)",
  "Carbs (g)": "الكربوهيدرات (جم)",
  "Dietary information": "المعلومات الغذائية",
  "Dairy free": "خالي من منتجات الألبان",
  "Gluten free": "خالي من الغلوتين",
  "Sugar free": "خالي من السكر",
  "Nut free": "خالي من المكسرات",
  Vegan: "فيغن",
  Vegetarian: "نباتي",
  Orgenic: "عضوي",
  Original: "الاسم الاصلي",
  Files: "الملفات",
  "Here you can upload files for your digital product":
    "هنا يمكنك رفع ملفات لمنتجك الرقمي",
  "Click or drag file to this area to upload":
    "اضغط او اسحب الملفات الى هذه الخانة لرفعهم",
  "Support for a single or bulk upload": "يدعم رفع ملف فردي او عدة ملفات",
  "Download this file": "حمل الملف",
  "Delete this file": "احذف هذا الملف",
  "Product variants": "انواع المنتج",
  "Add new key / column": "أضف قيمة/عامود",
  "Add new variant / row": "أضف نوع جديد/صف",
  "Add bulk variants with keys": "أضف أنواع جديدة مع عواميد",
  Instructions: "تعليمات",
  "Click on any cell to change its values": "اضغط على اي خانة لتغيير قيمتها",
  "Add a column by pressing": "قم بإضافة عمود بالضغط على",
  "Add key": "اضافة عمود",
  'to add keys like "size", or "colour" that apply to each row.':
    'لإضافة قيم مثل "قياس" او "لون"',
  "Additionally, by pressing on": "يمكنك أيضاً عند الضغط على",
  "you can add generate multiple variants with keys and values.":
    "أن تنسخ منتجات مع إضافة عواميد و قيم.",
  "Each row represents a variation of this product with different values in the columns. (E.g. T-Shirt Small/Black, T-Shirt Medium/Black)":
    "كل صف يمثل نوع مختلف من المنتج بقيم مختلفة في كل خانة يحتويها (مثال: قميص صغير/اسود)",
  "Separate English and Arabic values with a comma (eg. Large, كبير)":
    "قم بفصل قيم اللغة العربية والانجليزية بفاصلة (مثال: Large, كبير)",
  "Add new variant": "نوع جديد",
  "Duplicate product": "إنسخ المنتج",
  "New key": "عمود جديد",
  "Eg. color, size, style, material, etc..": "مثال: لون، قياس، شكل، نوعية",
  "Edit key": "تعديل العمود",
  "Duplicate current product": "تكرار المنتج الحالي",
  "Create a new product": "انشاء منتج جديد",
  "Copy from existing products": "نسخ من منتج موجود",
  "Bulk variant creation of current product":
    "إنشاء العديد من المتغيرات للمنتج الحالي مع عواميد و قيم.",
  "Select what you want to copy into the new variant":
    "اختر ما تريد نسخه الى النوع الجديد",
  "Bulk create keys and variant options":
    "أنسخ العديد من منتجك بعواميد و قيم جديدة",
  "Key created successfully": "تمت إضافةالمتغير",
  "New Key": "عمود جديد",
  Remove: "إزالة",
  "Add values": "أضف قيم للعمود",
  "Eg. for key color: blue, green, yellow, etc..":
    "مثال: لعمود لون: أزرق، أخضر، أصفر، إلخ...",

  "General info (name, description, image, etc..)":
    "المعلومات العامة (الاسم، الوصف، الصورة، الخ..)",
  pictures: "صور",
  "Generate bulk variants": "أضف كمية من الأنواع",
  "Choose variants to add": "إختر الأنواع التي تريد إضافتها",
  "Up to ": "يمكنك إضافة ",
  " of variants can be added.": "من الأنواع.",
  "Variant created successfully": "تم إنشاء النوع بنجاح",
  "Starting creation, please wait": "يتم الإنشاء، يرجى الإنتظار",
  "Cannot Proceed": "لا يمكن الإنشاء",
  "Key does not exist in the rows": "العمود غير موجود في الجدول",
  "Product availabilities": "اوقات عرض المنتج",
  "Digital downloads": "ملفات التحميل",
  "Fill in the following details": "قم بتعبئة البيانات التالية",
  "Select a product": "اختر منتج",
  "With image": "بصورة",
  "Without image": "بدون صورة",
  "Not assigned": "غير محدد",
  "products selected": "منتجات محددة",
  "Bulk edit products": "تعديل المنتجات جماعيا",
  "Change one or more properties of selected products":
    "بإمكانك تعديل اكثر من قيمة للمنتجات المحددة",
  "Change status to": "تغيير الحالة الى",
  "Change pos status to": "تغيير حالة نقطة البيع الى",
  Clear: "الغاء التحديد",
  "Are you sure you want to delete all selected products? You can't undo this action":
    "هل انت متأكد من حذف جميع المنتجات المحددة؟ لا يمكنك الرجوع",
  "Delete all": "حذف الجميع",
  "Detach from parent product (make own separate product)":
    "فصل عن المنتج الاساسي (جعله منتج منفصل)",
  "Are you sure you want to delete this variant? You can't undo this":
    "هل انت متأكد من حذف هذا النوع؟ لا يمكنك الرجوع",
  "variant of": "نوع من",
  "Minimum Order Value": "الحد الأدنى",
  "Copy options from another product": "نسخ خيارات من منتج آخر",
  "Select a product to copy options from": "اختر منتج لنسخ خياراته",
  Copy: "نسخ",
  products: "منتج",
  "You have reached your limit of products":
    "لقد وصلت العدد المتاح لديك من المنتجات. الرجاء التواصل معنا للمساعدة",
  "Auto replenish count": "الحد الادنى لإسترداد المخزون",
  "Auto replenish time": "وقت استرداد المخزون",
  "Pickup only": "لطلبات اللإستلام ففط",
  "Edit Options": "تعديل الخيارات",
  "Bulk edit options": "تعديل الخيارات جماعيا",
  "Couldn't find any option with this search value":
    "لا توجد خيارات بقيمة البحث",
  "Search for an option": "ابحث عن خيار",
  "Has products": "يوجد لديه منتجات",
  "Does not have a parent": "ليس لديه صنف رئيسي",
  Parent: "رئيسي",
  Subcategory: "الصنف الفرعي",
  "Review categories": "مراجعة الأصناف",
  "Maximum Quantity": "الحد الأقصى",
  "Minimum Quantity": "الحد الأدنى",
  "- Leave empty if you don't want to set a maximum limit":
    "- إبقيه فارغ إذا لا تريد ان تضع حد أقصى ",
  "- Put zero incase you want the product to appear as out of stock":
    "- ضع صفر إذا تريد ان تظهر المنتج على انه غير متوفر",
  "Product height (CM)": "ارتفاع المنتج (سم)",
  "Product depth (CM)": "عمق المنتج (سم)",
  "Product width (CM)": "عرض المنتج (سم)",
  "Product weight (KG)": "وزن المنتج (كج)",
  Attributes: "الخصائص",
  "Allow preordering": "السماح بالطلب المسبق",
  "Allow orders outside of the promotion's period":
    "السماح للطلب خارج فتره العرض",
  "Preorder inventory": "مخزون الطلب المسبق",
  "Preordering leadtime (Max 13 day)":
    "الايام المستغرقة للطلب المسبق (الحد الأقصى 13 يوم)",
  "Duplicate slot": "تكرار الخانة",
  "You are copying the slot for ": "سيتم نسخ الخانة ليوم ",
  "This slot is available all day": "هذه الخانة متاحة طوال اليوم",
  "This slot is available from *** to ***": "هذه الخانة متاحة من *** الى ***",
  "This slot will be copied with an inventory of ": "سيتم نسخ الخانة بمخزون ",
  "Days to skip (optional)": "تخطي ايام (اختياري)",
  "Bulk add slots": "اضافة خانات بالجملة",
  "Is Active": "تفعيل؟",
  "New Booking Slots": "خانات جديدة",
  "Hide old slots": "اخفاء الخانات القديمة",
  "New Limits": "حدود جديدة",
  Units: "وحدات القياس",
  Suppliers: "الموردين",
  Unit: "وحدة القياس",
  Supplier: "المورد",
  "Unit (Optional)": "وحدة القياس",
  "Supplier (Optional)": "المورد",
  "Supplier's Price": "سعر المورد",
  "Search for a unit": "ابحث عن وحدة قياس",
  "Search for a supplier": "ابحث عن مورد",
  "New unit": "اضافة وحدة قياس",
  "New supplier": "اضافة مورد",
  "Are you sure you want to delete this unit?":
    "هل انت متأكد من حذف هذه الوحدة؟",
  "Are you sure you want to delete this supplier?":
    "هل انت متأكد من حذف هذا المورد؟",
  "Are you sure you want to delete these units?":
    "هل انت متأكد من حذف هذه الوحدات؟",
  "Are you sure you want to delete these suppliers?":
    "هل انت متأكد من حذف هذا الموردات؟",
  "units selected": "وحدة قياس مختارة",
  "suppliers selected": "مورد مختار",
  Components: "المكونات",
  CompositeDescription: "مركب: منتجات تتكون من منتجات أخرى",
  Composite: "مركب",
  "Required Amount": "الكمية المطلوبة",
  "Are you sure you want to delete this component?":
    "هل انت متأكد من حذف هذا المكون؟",
  "Width to height": "العرض للطول",
  "Date selection style": "تصميم اختيار التاريخ",
  Calendar: "رزنامة",
  Scroll: "تمرير",
  "Duration in minutes": "المدة بالدقائق",
  "POS Sort order": "الترتيب في نقطة البيع",
  "Parent category": "الصنف الاساسي",
  "This product cannot be added as a component": "هذا  المنتج لا يمكن إضافته",
  "New component": "مكون جديد",
  "slots selected out of": "خانة محددة من اصل",
  "Set inventory for all": "تغيير الخزون الى",
  "Leaving the field empty will keep the inventories as is":
    "ترك هذه الخانة فارغة سيحافظ على القيم الاصلية",
  "Set inventory to": "تغيير المخزون الى",
  Layout: "التصميم",
  "List View": "قائمة عامودية",
  "Grid View": "شبكه",
  "Scroll View": "قائمة أفقية",
  "Dense View": "قائمة مكثفة",
  "Maximum booking days in advance": "عدد الأيام المتاحة للحجز",
  "Show preparation time": "اظهار وقت التحضير",
  "if prep. time is greater than 0": "اذا كان وقت التحضير اكبر من 0",
  hidden: "مخفي",
  Barcode: "الباركود",
  Extras: "اللإضافات",
  "Add extra field": "إضافة جديدة",
  "Copy extra fields": "نسخ من منتج آخر",
  "Copy extra fields to other products": "نسخ إلى منتجات أخرى",
  "Copy options to other products": "نسخ خيارات إلى منتجات أخرى",
  Text: "نص",
  File: "ملف",
  "Select a product to copy extra fields from": "إختر منتج لنسخ إضافاته",
  "Select the products that you want to copy extra fields to":
    "إختر المنتجات التى تريد ان تنسخ الإضافات إليها",
  "Select the products that you want to copy options to":
    "إختر المنتجات التى تريد ان تنسخ الخيارات إليها",
  "Remove the original extra fields from the products":
    "إزالة الإضافات الأصلية من المنتجات",
  "Remove the original options from the products":
    "إزالة الخيارات الأصلية من المنتجات",
  "New Extra Field": "إضافة جديدة",
  "Copied extra fields successfully": "تم نسخ الإضافات بنجاح",
  "Are you sure you want to delete this extra field?":
    "هل انت متأكد من حذف هذه الإضافة؟",
  "Updated successfully": "تم التحديث بنجاح",
  "Edit extra field": "تعديل الإضافة",
  "A child of": "فرع ينتمي الى",
  "Has subcategories": "يحتوي على افرع",
  "Has subcategories and is child of": "يحتوي على افرع وينتمي الى",
  "Download now": "تحميل",
  "File was removed": "تمت إزالة الملف",
  "Exclusive Quantity?": "كمية الخيار غير متصلة بالمنتج؟",
  "Exclusive Quantity": "كمية الخيار غير متصلة بالمنتج",
  Generate: "إصدار",
  "Advanced toggle": "تشغيل/تعطيل متقدم",
  "Advanced delete": "حذف متقدم",
  "Confirm toggle": "تأكيد التشغيل/التعطيل",
  "Confirm delete": "تأكيد الحذف",
  "Select other products to apply this change on. (optional)":
    "اختر منتجات اخرى لتطبيق التغيير عليهم (اختياري)",
  "Select other products (optional)": "اختر منتجات اخرى (اختياري)",
  "Turn ON all slots on ***": "تشغيل جميع الخانات بتاريخ ***",
  "Turn OFF all slots on ***": "تعطيل جميع الخانات بتاريخ ***",
  "Delete all slots on ***": "حذف جميع الخانات بتاريخ ***",
  "Turn ON *** - *** to ***": "تشغيل *** - *** الى ***",
  "Turn OFF *** - *** to ***": "تعطيل *** - *** الى ***",
  "Delete *** - *** to ***": "حذف *** - *** الى ***",
  History: "السجل",
  "Product change log": "سجل تغيير المنتج",
  "You can limit days and time where this category is available":
    "يمكنك تحديد الايام و الاوقات التي سيتم فيها عرض الصنف",
  "Product is displayed on": "يتم عرض المنتج في",
  "Category is displayed on": "يتم عرض الصنف في",
  "Both mobile app and website": "كل في التطبيق و الموقع",
  "Mobile app only": "فقط في التطبيق",
  "Website only": "فقط في الموقع",
  "Display category when it's not available": "عرض الصنف خارج الاوقات المحددة",
  "When enabled, the category will not disappear from your store outside of the limits. Customer will be able to order the category's products within the set limits":
    "عند التفعيل، ستظهر منجات الصنف دائما لكن يمكن طلبهم خلال الاوقات المحددة فقط",
  "Assign to branches (optional)": "اضافة الى الأفرع (اختياري)",
  "Confirmation Message": "رسالة تأكيد",
  "Show confirmation message": "اظهار رسالة تأكيد",
  "Arabic message": "الرسالة بالعربي",
  "Agree button text": "نص زر الموافقة",
  "Agree button arabic text": "نص زر الموافقة بالعربي",
  "Cancel button text": "نص زر الالغاء",
  "Cancel button arabic text": "نص زر الالغاء بالعربي",
  "A confirmation message is a pop-up message that shows to the customer before adding the product to cart":
    "رسالة التأكيد هي نافذة تظهر للعملاء عند اضافة المنتج الى سلة المشتريات",
  "Sold Individually": "تباع بشكل فردي",
  "On/Off": "تشغيل/إغلاق",
  Modifiers: "اللإضافات",
  "Related Product": "المنتج",
  "Edit Modifier": "تعديل اللإضافة",
  "New Modifier": "إضافة جديده",
  "Product (optional)": "منتج (إختياري)",
  Modifier: "إضافة",
  "No Modifier": "لا توجد إضافة",
  "Select modifier (optional)": "إختر إضافة (إختياري)",
  "modifier selected": "إضافة محددة",
  "Search for a modifier": "إبحث عن إضافة",
  "Actions on selected modifiers": "التحكم في الإضافات المحددة",
  "active modifiers": "إضافة مفعلة",
  "inactive modifiers": "إضافة معطلة",
  "Delete options": "إزالة الخيارات",
  "Are you sure you want to delete this modifier?":
    "هل انت متأكد من حذف هذه الإضافة؟",
  Preselected: "مختار تلقائيا",
  "Booking time slots style": "تصميم خانات أوقات الحجوزات",
  "Variations style": "تصميم اختيار الأنواع",
  "Hide service provider if there's only one":
    "اخفاء موفر الخدمة ان وجد واحد فقط",
  Horizontal: "افقي",
  Vertical: "عمودي",
  "No Split": "غير مقسم",
  Split: "مقسم",
  Dropdown: "قائمة عمودية",
  "Scroll (with images)": "قائمة افقية (مع صور)",
  "Improve your discoverability on search engines by providing meta names and descriptions":
    "حسن ترتيبك على محركات البحث (SEO) بتزويد معلومات اسم المنتج و الوصف",

  "Meta Name": "الاسم",
  "Meta Description": "الوصف",

  "Publish Date": "تاريخ النشر",
  "Preordering Leadtime (Days)": "الايام المستغرقة للطلب المسبق",
  "Preordering Leadtime (Hours)": "الساعات المستغرقة للطلب المسبق",
  "Show not available for": "إظهار غير متاح لمدة",
  "Not available until": "غير متاح الى",
  Clearr: "الغاء",
  "Please select options to copy": "الرجاء تحديد الاختيارات التي تريد نسخها",
  "Select the options you want to copy":
    "الرجاء تحديد الاختيارات التي تريد نسخها",
  "Duplicate options": "خيارات مكررة",
  "Copy Options": "خيارات النسخ",
  "From product": "من المنتج",
  "To other products": "لمنتجات أخرى",
  "Select All": "اختر الكل",
  "Clear All": "امسح الكل",
  "Are you sure you want to detach this variant?":
    "هل انت متأكد من فصل هذا المنتج؟",
  "Manufacturing country code": "رمز الدولة المصنعة",
  codes: "الرموز",
  "Collapsible?": "قابل للإخفاء؟",
  Collapsible: "قابل للإخفاء",
  "Collapsed by default": "اخفاء بشكل تلقائي",
  "Collapsed by default?": "اخفاء بشكل تلقائي؟",
  "Update Product Catalog": "تحديث المنتجات",
  "Download Product's Template": "تحميل نموذج المنتجات",
  "Upload The File": "رفع الملف",
  "Update According to": "تحديث المنتجات حسب",
  "Submit The File": "ارسال الملف",
  Submit: "إرسال",
  "Products are beings updated, please refresh the page":
    "جاري تحديث المنتجات, الرجاء إعادة تحميل الصفحة",
  "Available for sale on": "متوفر للبيع في",
  "Only show on": "اظهار المنتج في",
  "When this is enabled, the product will be available for scheduling only during the specified time period":
    "عند تفعيل هذا الخيار, سيتم السماح بجدولة هذا المنتج فقط في الفترة المحددة",
  "When this is enabled, the category will be available for scheduling only during the specified time period":
    "عند تفعيل هذا الخيار, سيتم السماح بجدولة منتجات هذا الصنف فقط في الفترة المحددة",
  "Edit description": "تعديل الوصف",
  "Add description": "إضافة وصف",
  "English description": "الوصف بالانجليزي",
  "Arabic description": "الوصف بالعربي",
  description: "وصف",
  "With description": "مع وصف",
  "Without description": "بدون وصف",
  "Assigned to branches": "متاح بالأفرع",
  "Display product when it's not available": "عرض المنتج خارج الاوقات المحددة",
  "When enabled, the product will not disappear from your store outside of the limits. Customer will be able to order the product within the set limits":
    "عند التفعيل، سيظهر المنتج دائما لكن يمكن طلبه خلال الاوقات المحددة فقط",
  "Select a period": "اختر الفترة",
  "Separate multiple search words with commas. For example: burger,salads":
    "يمكنك فصل كلمات البحث بفاصلة، مثال: برغر، سلطات",
  "Not available": "غير متاح",
  "Sync Foodics": "تزامن مع فودكس",
  "Standard Filters": "الفلاتر الاساسية",
  "Sort By": "ترتيب حسب",
  "Custom Filters": "الفلاتر المخصصة",
  "Search for a filter": "بحث عن التصفية",
  "New filter": "تصفية جديد",
  "Filter Options": "خيارات التصفية",
  filters: "فلاتر",
  "View Options": "خيارات العرض",
  Number: "نطاق",
  "Edit filter": "تعديل التصفية",
  "Are you sure you want to delete this filter? You can't undo this":
    "هل انت متأكد من حذف هذا التصفية؟ لا يمكن التراجع عن هذا الإجراء",
  "Select filter type": "اختر نوع التصفية",
  options: "خيارات",
  "New option": "خيار جديد",
  "View Products": "عرض المنتجات",
  "Edit filter option": "تعديل خيار التصفية",
  "Are you sure you want to delete this filter option? You can't undo this":
    "هل انت متأكد من حذف هذا الخيار؟ لا يمكن التراجع عن هذا الإجراء",
  "New filter option": "خيار تصفية جديد",
  "Select filter": "اختر التصفية",
  "Select option": "اختر الخيار",
  "Are you sure you want to delete this product filter? You can't undo this":
    "هل انت متأكد من حذف هذا التصفية؟ لا يمكن التراجع عن هذا الإجراء",
  "Filter created successfully": "تم إنشاء التصفية بنجاح",
  "Filter deleted successfully": "تم حذف التصفية بنجاح",
  "Filter updated successfully": "تم تحديث التصفية بنجاح",
  "Filter status changed successfully": "تم تغيير حالة التصفية بنجاح",
  "Filter option created successfully": "تم إنشاء خيار التصفية بنجاح",
  "Filter option deleted successfully": "تم حذف خيار التصفية بنجاح",
  "Filter option updated successfully": "تم تحديث خيار التصفية بنجاح",
  "Filter option status changed successfully":
    "تم تغيير حالة خيار التصفية بنجاح",
  "Product filter created successfully": "تم إنشاء تصفية المنتج بنجاح",
  "Product filter option assigned successfully":
    "تم تعيين خيار تصفية المنتج بنجاح",
  "Product filter deleted successfully": "تم حذف تصفية المنتج بنجاح",
  "Product filter status changed successfully":
    "تم تغيير حالة تصفية المنتج بنجاح",

  "Auto reset count": "الحد الادنى إعادة تعيين المخزون",
  "Auto reset time": "الوقت إعادة تعيين المخزون",
  Increments: "الخطوات",
  Value: "القيمة",
  Filters: "تصفية",
  "Bulk Action": "إجراءات جماعية",
  "New Filter": "تصفية جديدة",
  "Smart Filters": "تصفية ذكية",
  "Enable sorting": "تفعيل الترتيب",
  "Price filter": "تصفية السعر",
  "Delivery only": "لطلبات التوصيل فقط",
  "Enable available products filter": "تفعيل تصفية المنتجات المتاحة",
  "Enable nutritional fact filter": "تفعيل تصفية القيمة الغذائية",
  Available: "متاح",
  "Not Available": "غير متاح",
  "Product for display": "منتج للعرض",
  "can't be purchased": "لا يمكن شراء هذا المنتج",
  "Hide buy now button": "إخفاء زر الشراء الآن",
  "Hide quick add to cart button": "إخفاء زر الإضافة الى السلة السريع",
  "Cash only": "الدفع نقداً فقط",
  "Credit only": "الدفع بالبطاقة فقط",
  "Show warning message": "عرض رسالة تحذير",
  "The amount available after which a warning is shown to customers about the product's availability":
    "الكمية المتاحة بعد ذلك يتم عرض تنبيه للعملاء حول توفر المنتج",
  "The warning that is shown to customers on the storefront to create urgency":
    "التنبيه الذي يظهر للعملاء على المنتج عندما يصل إلى الحد الأدنى من القيمة المذكورة أعلاه",
  "Few items left!": "متبقي القليل!",
  " left": " متبقي",
  "Are you sure you want to delete all selected categories? You can't undo this action":
    "هل انت متأكد من حذف جميع الأصناف المحددة؟ لا يمكنك الرجوع",
  "Please set date and time": "يرجى تحديد التاريخ والوقت",
  "Exclude from VAT": "استثناء من الضريبة",
  "Options groups": "مجموعات الخيارات",
  "Options Groups": "مجموعات الخيارات",
  "Add Group": "إضافة مجموعة",
  "Edit Group": "تعديل مجموعة",
  "Are you sure you want to delete this group?":
    "هل أنت متأكد أنك تريد حذف هذه المجموعة؟",
  Groups: "المجموعات",

  "Customer Reviews": "مراجعات المستخدمين",
  star: "نجوم",
  "When “Product for display” is checked then this option will be disabled":
    "عند تحديد خيار “منتج للعرض”، سيتم تعطيل هذا الخيار",
  "When “Product for display” is checked then this option will be enabled":
    "عند تحديد خيار “منتج للعرض”، سيتم تفعيل هذا الخيار",
  "Add product": "إضافة منتج",
  Replace: "استبدال",
  "Replace product": "استبدال المنتج",
  "Are you sure you want to delete this product? You can't undo this":
    " هل أنت متأكد أنك تريد حذف هذا المنتج؟ لا يمكنك التراجع عن هذا الإجراء",
  "Total number of products": "إجمالي عدد المنتجات",
  "Cross Selling": "المنتجات ذات الصلة",
  "Category SEO": "تحسين محركات البحث للصنف",
  Restore: "استرجاع",
  "Restore deleted products": "استرجاع المنتجات المحذوفة",
  "Deleted by": "حذفت من قبل",
  "Deletion Date": "تاريخ الحذف",
  "Filtered results": "اجمالي المفلتر",
  "You have used": "قمت باستخدام",
  "of your": "من اصل",
  limit: "منتج مسموح",
  "You can increase it from": "يمكنك رفع القيد من",
  with: "مع",
  variants: "نوع",
  "Please add category icon to enable this feature":
    "الرجاء إضافة صورة الفئة لتمكين هذه الميزة",
  "Text English": "النص الانجليزي",
  "Text Arabic": "النص العربية",
  "Show tag until": "إظهار العلامة حتى",
  Tag: "علامة",
  Tags: "العلامات",
  tags: "العلامات",
  "New Tag": "علامة جديدة",
  "Edit Tag": "تعديل العلامة",
  "Enable until": "تمكين حتى",
  "English Text": "النص الانجليزي",
  "Arabic Text": "النص العربي",
  "Show tag until?": "إظهار العلامة حتى؟",
};

export default productsTranslation;
