import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import { Modal, Button, InputNumber, Row, Col } from "antd";

class ChargeModal extends Component {
  state = { paidAmount: this.props.orderTotal, isCharged: false };

  render() {
    let { t, config, visible, orderTotal, settings } = this.props;
    const { paidAmount, isCharged } = this.state;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Change")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            block
            className="posActionButton"
            style={{
              backgroundColor: isCharged ? "rgb(0, 153, 204)" : "#009900",
              borderColor: isCharged ? "rgb(0, 153, 204)" : "#009900",
            }}
            key="submit"
            type="primary"
            disabled={!isCharged && paidAmount < orderTotal}
            onClick={() =>
              isCharged
                ? this.props.onClose()
                : this.setState({ isCharged: true })
            }
          >
            {isCharged ? t("New Sale") : t("Calculate Change")}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="mt-3 mb-4 text-center">
          {isCharged ? (
            <div className="mt-3 mb-4 disable-select text-center">
              <p style={{ fontSize: 30 }}>{t("Change")}</p>
              <p style={{ fontSize: 25 }}>
                {`${(paidAmount - orderTotal).toFixed(
                  settings.currency_decimals
                )} ${
                  (config.language === "arabic" && settings.currency_local) ||
                  settings.currency
                }`}
              </p>
            </div>
          ) : (
            <>
              <p style={{ fontSize: 30 }}>{`${orderTotal.toFixed(
                settings.currency_decimals
              )} ${
                (config.language === "arabic" && settings.currency_local) ||
                settings.currency
              }`}</p>
              <div className="mt-3 mb-4 disable-select text-center">
                <p style={{ fontSize: 20 }}>{t("Paid Amount")}</p>

                <InputNumber
                  inputmode="numeric"
                  pattern="[0-9]*"
                  className="product-input-number border"
                  value={paidAmount}
                  min={orderTotal}
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    border: "none",
                    width: 150,
                  }}
                  onChange={(e) => {
                    this.setState({ paidAmount: e });
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
    settings: state.auth.settings,

    is_staff: state.auth.is_staff,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(ChargeModal));
