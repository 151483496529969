import {
  FETCH_DELIVERY_INTEGRATIONS,
  FETCH_INTEGRATION_LOADING,
  FETCH_INVENTORY_INTEGRATIONS,
  FETCH_PAYMENT_INTEGRATIONS,
  SAVE_BENEFIT_TRANSFER_SETTINGS,
  SAVE_CURRENCY_CONVERTER_SETTINGS,
  SAVE_DELIVERY_INTEGRATION_SETTINGS,
  SAVE_FRONTLINES_MERCHANT_CODE,
  SAVE_INVENTORY_INTEGRATION_SETTINGS,
  SAVE_MARKETING_INTEGRATION_SETTINGS,
  SAVE_PAYMENT_SETTINGS,
  TOGGLE_SMS_ACTIVE,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";
import i18next from "i18next";

export const fetchPaymentIntegrations = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_INTEGRATION_LOADING });
    try {
      let res = await instance.get("/fetch_payment_integrations/");
      if (res.data.success) {
        dispatch({ type: FETCH_PAYMENT_INTEGRATIONS, payload: res.data.data });
      } else
        message.error(
          "Could not load payment integrations. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view integrations");
      else
        message.error(
          "Could not load payment integrations. Please try again or contact us for help"
        );
    }
  };
};

export const fetchDeliveryIntegrations = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_INTEGRATION_LOADING });
    try {
      let res = await instance.get("/fetch_delivery_integrations/");
      if (res.data.success) {
        dispatch({ type: FETCH_DELIVERY_INTEGRATIONS, payload: res.data.data });
      } else
        message.error(
          "Could not load delivery integrations. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view integrations");
      else
        message.error(
          "Could not load delivery integrations. Please try again or contact us for help"
        );
    }
  };
};

export const fetchInventoryIntegrations = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_INTEGRATION_LOADING });
    try {
      let res = await instance.get("/fetch_inventory_integrations/");

      dispatch({
        type: FETCH_INVENTORY_INTEGRATIONS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view integrations");
      else
        message.error(
          "Could not load inventory integrations. Please try again or contact us for help"
        );
    }
  };
};
export const savePaymentSettings = (gateway, data) => {
  return {
    type: SAVE_PAYMENT_SETTINGS,
    payload: { gateway: gateway, data: data },
  };
};

export const saveDeliveryIntegrationSettings = (provider, data) => {
  return {
    type: SAVE_DELIVERY_INTEGRATION_SETTINGS,
    payload: { provider: provider, data: data },
  };
};

export const saveFrontlinesMerchantCode = (code) => {
  return {
    type: SAVE_FRONTLINES_MERCHANT_CODE,
    payload: code,
  };
};

export const saveMarketingIntegrationSettings = (data) => {
  return {
    type: SAVE_MARKETING_INTEGRATION_SETTINGS,
    payload: data,
  };
};
export const saveInventoryIntegrationSettings = (data) => {
  return {
    type: SAVE_INVENTORY_INTEGRATION_SETTINGS,
    payload: data,
  };
};

export const toggleSMSActive = (field, value) => {
  return {
    type: TOGGLE_SMS_ACTIVE,
    payload: { field, value },
  };
};

export const saveBenefitTransferSettings = (data) => {
  return {
    type: SAVE_BENEFIT_TRANSFER_SETTINGS,
    payload: data,
  };
};

export const saveCurrencyConversionSettings = (data) => {
  return {
    type: SAVE_CURRENCY_CONVERTER_SETTINGS,
    payload: data,
  };
};

export const createAramexBox = (width, height, depth, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/aramex/create_box/", {
        width: width,
        height: height,
        depth: depth,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "CREATE_ARAMEX_BOX",
          payload: res.data.data,
        });
        message.success(i18next.t("Box created successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const updateAramexBox = (id, width, height, depth, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/aramex/update_box/", {
        box_id: id,
        width: width,
        height: height,
        depth: depth,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "UPDATE_ARAMEX_BOX",
          payload: {
            id: id,
            width: width,
            height: height,
            depth: depth,
          },
        });
        message.success(i18next.t("Box updated successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};
export const deleteAramexBox = (id, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/aramex/delete_box/", {
        box_id: id,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "DELETE_ARAMEX_BOX",
          payload: id,
        });
        message.success(i18next.t("Box deleted successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};
