import React, { Component, Suspense } from "react";
import "./App.less";
import { Layout, Menu, Breadcrumb } from "antd";
import moment from "moment";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Orders from "./components/Orders";
import KanBan from "./components/Orders/KanBan";
import SideNav from "./components/Layout/SideNav";
import Header from "./components/Layout/Header";
import { connect } from "react-redux";
import { joinSocket } from "./store/actions/socket";
import { fetchOrders } from "./store/actions/orders";
import { fetchStoreHealth } from "./store/actions/health";
import {
  LoadingOutlined,
  LogoutOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";
import { Spin, message, Button } from "antd";
import { ConfigProvider } from "antd";
import ArabicLocale from "antd/es/locale/ar_EG";
import EnglishLocale from "antd/es/locale/en_US";
import { withNamespaces } from "react-i18next";
import { fetchFeedbacks } from "./store/actions";
import { fetchAccountInfo } from "./store/actions/account";
import PaymentOverdueModal from "./components/AccountSettings/PaymentOverdueModal";
import { Crisp } from "crisp-sdk-web";
import { fetchUserProfile } from "./store/actions/profile";
import InteractForSoundModal from "./components/common/InteractForSoundModal";
import { logout } from "./store/actions/auth";
import silence from "./silence.mp3";
import {
  allRoutes,
  overdueRoutes,
  unauthorizedRoutes,
  menuModeRoutes,
} from "./Routes";
import GoIntroCarousel from "./components/GoIntroCarousel";
import IntroModal from "./components/IntroModal";
import { hideIntro } from "./store/actions/go";
import { switchLanguage } from "./store/actions/config";
import Tour from "./components/Tour";
import PasswordResetModal from "./components/PasswordResetModal";
import { hidePasswordChangeModal } from "./store/actions/auth";
import getAudioFile from "./sounds.js";
import Nps from "./components/Layout/NpsFile";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Content } = Layout;

class App extends Component {
  state = {
    audioPlay: false,
    showOverdueModal: false,
    showSoundModal: false,
    isConnectedToNetwork: true,
  };
  // audio = new Audio(sound);

  showPaymentReminder = () => {
    const { info } = this.props;
    if (!info) return false;
    if (!info.next_payment_date) return false;
    if (info.package === 0 && info.commission_value + info.addons_fee === 0)
      return false;

    const diff =
      moment(info.next_payment_date, "YYYY-MM-DD").diff(moment(), "days") + 1;
    if (diff < info.notify_before) return true;
    return false;
  };

  prepareRoutes = () => {
    let tempRoutes = [];
    const {
      user,
      settings,
      canOpenAllSections,
      is_staff,
      ordersLayout,
      excluded_routes,
    } = this.props;
    if (!user) {
      if (window.location.pathname !== "/manage/login") {
        localStorage.setItem("nextRoute", window.location.pathname);
      }
      tempRoutes = unauthorizedRoutes;
    } else if (!canOpenAllSections && this.props.info) {
      if (is_staff) tempRoutes = Object.keys(allRoutes);
      else tempRoutes = overdueRoutes.filter((r) => !r.includes("staff"));
    } else if (settings?.is_menu) {
      if (is_staff) {
        tempRoutes = menuModeRoutes;
        if (excluded_routes?.length)
          excluded_routes.forEach((r) => {
            delete tempRoutes[r];
          });
      } else {
        tempRoutes = menuModeRoutes.filter((r) => !r.includes("staff"));
        if (!settings?.enable_static_pages)
          tempRoutes = tempRoutes.filter((r) => r !== "/manage/pages");
      }
    } else {
      tempRoutes = { ...allRoutes };
      tempRoutes["/manage/orders"] = ordersLayout === "new" ? Orders : KanBan;
      if (!settings?.enable_resource_based_booking)
        delete tempRoutes["/manage/ServiceProviders"];
      if (!settings?.enable_resource_based_booking)
        delete tempRoutes["/manage/services"];
      if (!settings?.enable_static_pages) delete tempRoutes["/manage/pages"];
      if (!settings?.enable_popup_banner_staff)
        delete tempRoutes["/manage/appearance/PopupBanner"];
      if (!settings?.enable_promotions) delete tempRoutes["/manage/promotions"];
      if (!settings?.enable_pos) delete tempRoutes["/manage/pos"];
      if (!settings?.enable_driver) delete tempRoutes["/manage/drivers"];
      if (!settings?.enable_businesses) {
        delete tempRoutes["/manage/businesses"];
        delete tempRoutes["/manage/businesses/reports"];
        delete tempRoutes["/manage/businesses/manifest"];
      }
      if (!settings?.enable_booking) delete tempRoutes["/manage/bookings"];

      if (!settings?.enable_social_auth_staff) {
        delete tempRoutes["/manage/users"];
      }
      if (!settings?.enable_wallet_staff) {
        delete tempRoutes["/manage/topups"];
      }
      if (!settings?.enable_sms_campaigns) {
        delete tempRoutes["/manage/campaign"];
      }
      if (!settings?.enable_landing_links_staff) {
        delete tempRoutes["/manage/links"];
      }
      if (
        settings?.created_by_reseller &&
        !["enableqa.ordable.com", "foodics.ordable.com"].includes(
          settings.linked_to_store
        )
      )
        delete tempRoutes["/manage/billing"];

      if (
        (settings?.created_by_reseller &&
          settings?.linked_to_store !== "foodics.ordable.com") ||
        settings?.base_country === "Jordan"
      )
        delete tempRoutes["/manage/addons"];
      if (!settings?.has_mobile_app)
        delete tempRoutes["/manage/MobileNotifications"];

      if (settings?.linked_to_store === "shoplink.ordable.com") {
        delete tempRoutes["/manage/faqs"];
        delete tempRoutes["/manage/addons/:packageID"];
        delete tempRoutes["/manage/installed/addons/:packageID"];
      }
      if (!settings?.enable_abandoned_carts)
        delete tempRoutes["/manage/abandoned"];
      if (!settings?.can_edit_dns) delete tempRoutes["/manage/dns"];

      if (!is_staff) {
        delete tempRoutes["/manage/ProductImport"];
        delete tempRoutes["/manage/staff"];
        delete tempRoutes["/manage/import"];
        delete tempRoutes["/manage/import/customers/template"];
      } else {
        if (excluded_routes?.length)
          excluded_routes.forEach((r) => {
            delete tempRoutes[r];
          });
      }
      let finalRoutes = Object.keys(tempRoutes).map((r) => (
        <Route path={r} component={tempRoutes[r]} />
      ));
      finalRoutes.push(
        <Redirect from="/manage" to={Object.keys(tempRoutes)[0]} />
      );
      return finalRoutes;
    }
    let finalRoutes = {};
    tempRoutes.forEach((r) => {
      finalRoutes[r] = allRoutes[r];
    });
    finalRoutes = tempRoutes.map((r) => (
      <Route path={r} component={allRoutes[r]} />
    ));
    finalRoutes.push(<Redirect from="/manage" to={tempRoutes[0]} />);

    return finalRoutes;
  };

  componentDidMount = async () => {
    Crisp.configure("a3de3415-66a5-42ee-8c5c-4c7587e3a907");
    // Crisp.load();
    if (!this.props.isHelpOpen) Crisp.chat.hide();
    else Crisp.chat.show();

    if (this.props.config.language === "arabic") Crisp.setPosition("left");
    else Crisp.setPosition("right");
    message.config({
      maxCount: 3,
    });
    const params = new URLSearchParams(window.location.search);
    let token = params.get("token") || null;
    if (token) {
      this.props.history.replace(window.location.pathname);
    }
    if (params.get("lang") === "ar") {
      this.props.history.replace(window.location.pathname);
      this.props.switchLanguage("arabic");
    }
    if (
      this.props.user &&
      this.props.permissions &&
      !this.state.fetchingFirstFetch
    ) {
      // It almost never goes here. Kept it just in case
      this.setState({ fetchingFirstFetch: true });

      if (!this.props.socket.socket) {
        // Crisp.user.setEmail(this.props.username);
        this.props.joinSocket(this.props.t, this.props.history);
      }
      //At this point, the permissions are not done fetching
      await this.props.fetchAccountInfo();
      this.setState({ showSoundModal: true });
      if (!this.props.orders.orders) {
        this.props.fetchOrders(this.props.t);
      }
      if (!this.props.feedbacks) {
        this.props.fetchFeedbacks(this.props.t, "app");
      }
      setTimeout(this.fetchOrders, 1000 * 60 * 10);

      this.props.fetchStoreHealth();
      setInterval(() => {
        this.props.fetchStoreHealth();
      }, 1000 * 60 * 10);

      await this.props.fetchUserProfile();
      try {
        let pkg = "unknown";

        try {
          switch (this.props.settings.package) {
            case 0:
              pkg = "go";
              break;
            case 1:
              pkg = "basic";
              break;
            case 2:
              pkg = "advanced";
              break;
            case 3:
              pkg = "enterprise";
              break;
            default:
              pkg = "custom";
          }
        } catch (error) {
          console.log(error);
        }
        let countryCodes = {
          Kuwait: "KW",
          Bahrain: "BH",
          "Saudi Arabia": "SA",
          Qatar: "QA",
          Oman: "OM",
          UAE: "AE",
        };

        const { profile } = this.props.profile;
        if (profile.key) {
          let email = (profile.email || "").trim() || this.props.username;
          Crisp.user.setCompany(this.props.settings.name, {
            url: "https://" + this.props.settings.domain,
            geolocation: {
              country: countryCodes[this.props.settings.base_country] || [],
            },
          });
          if (profile.phone) Crisp.user.setPhone(profile.phone);
          Crisp.user.setEmail(email);
          let crispData = {
            user_id: this.props.username,
            plan: pkg,
            language: this.props.config.language,
            country: this.props.settings.base_country,
            domain: this.props.settings.domain,
            used_domain: window.location.hostname,
          };

          if (this.props.settings.hs_deal_id)
            crispData[
              "deal"
            ] = `https://app.hubspot.com/contacts/8324097/deal/${this.props.settings.hs_deal_id}/`;
          Crisp.session.setData(crispData);

          Crisp.session.onLoaded((sessionId) => {
            // Executed once the Crisp session is loaded
            let email = (profile.email || "").trim() || this.props.username;
            Crisp.user.setCompany(this.props.settings.name, {
              url: "https://" + this.props.settings.domain,
              geolocation: {
                country: countryCodes[this.props.settings.base_country] || [],
              },
            });
            if (profile.phone) Crisp.user.setPhone(profile.phone);
            Crisp.user.setEmail(email);
            let crispData = {
              user_id: this.props.username,
              plan: pkg,
              language: this.props.config.language,
              country: this.props.settings.base_country,
              domain: this.props.settings.domain,
              used_domain: window.location.hostname,
            };

            if (this.props.settings.hs_deal_id)
              crispData[
                "deal"
              ] = `https://app.hubspot.com/contacts/8324097/deal/${this.props.settings.hs_deal_id}/`;
            Crisp.session.setData(crispData);
          });

          // if (window.Tawk_API?.setAttributes) {
          //   window.Tawk_API.setAttributes(
          //     {
          //       name: this.props.username + " - package: " + pkg,
          //       language: this.props.config.language,
          //       email: email,
          //       hash: profile.key,
          //     },
          //     (error) => {
          //       if (error) console.log(error);
          //     }
          //   );
          // }
        }
      } catch (error) {
        console.log(error);
      }
    }

    window.addEventListener("online", () =>
      this.setState({ isConnectedToNetwork: true })
    );
    window.addEventListener("offline", () =>
      this.setState({ isConnectedToNetwork: false })
    );
  };
  fetchOrders = () => {
    this.props.fetchOrders(this.props.t);

    setTimeout(this.fetchOrders, 1000 * 60 * 10);
  };

  playAudio = () => {
    const { settings } = this.props;
    if (!this.props.didInteract || !settings) {
      return;
    }
    if (
      (this.props.bellHasNewOrders ||
        this.props.bellHasNewBusinessOrders ||
        this.props.bellHasNewAggregate) &&
      this.props.soundEnabled
    ) {
      let audio;
      if (
        !this.props.audio ||
        localStorage.getItem("soundName") !== settings.new_order_sound
      ) {
        const filename = settings.new_order_sound;
        const f = getAudioFile(filename);
        if (this.props.audio || !localStorage.getItem("soundName")) {
          localStorage.setItem("soundName", filename);
        }
        audio = new Audio(f);
        this.props.setAudio(audio);
      }
      audio = audio || this.props.audio;
      if (!this.props.audioPaused) {
        audio.autoplay = true;
        audio.play();
      }

      setTimeout(this.playAudio, 1500);
    }
  };
  componentDidUpdate = async (prevProps) => {
    const { info, user, settings } = this.props;

    if (
      info !== prevProps.info &&
      prevProps.info == null &&
      this.showPaymentReminder() &&
      window.location.pathname !== "/manage/billing"
    ) {
      if (
        !(info.package === 0 && info.commission_value + info.addons_fee === 0)
      )
        this.setState({ showOverdueModal: true });
    }
    if (
      ((this.props.bellHasNewOrders && !prevProps.bellHasNewOrders) ||
        (this.props.bellHasNewBusinessOrders &&
          !prevProps.bellHasNewBusinessOrders) ||
        (this.props.bellHasNewAggregate && !prevProps.bellHasNewAggregate)) &&
      this.props.soundEnabled
    ) {
      this.playAudio();
    } else if (
      (this.props.bellHasNewOrders ||
        this.props.bellHasNewBusinessOrders ||
        this.props.bellHasNewAggregate) &&
      this.props.soundEnabled &&
      !prevProps.soundEnabled
    ) {
      this.playAudio();
    } else if (
      this.props.soundEnabled &&
      !prevProps.soundEnabled &&
      (!this.props.bellHasNewOrders ||
        !this.props.bellHasNewBusinessOrders ||
        !this.props.bellHasNewAggregate)
    ) {
      this.playAudio();
    } else if (this.props.didInteract != prevProps.didInteract)
      this.playAudio();

    if (
      this.props.settings &&
      prevProps.settings &&
      this.props.settings.new_order_sound !==
        localStorage.getItem("soundName") &&
      this.props.audio &&
      localStorage.getItem("soundName")
    ) {
      this.props.audio.pause();
    }
    if (
      ((this.props.user !== null &&
        prevProps.user === null &&
        !!this.props.permissions) ||
        (this.props.user &&
          this.props.permissions !== null &&
          prevProps.permissions === null)) &&
      !this.state.fetchingFirstFetch
    ) {
      // This is the part that fetches all the necessary things (orders, feedbacks, profile, etc.) when you refresh the page or you log in
      this.setState({ fetchingFirstFetch: true });
      if (!this.props.socket.socket) {
        this.props.joinSocket(this.props.t, this.props.history);
      }
      //At this point, the permissions are not done fetching
      await this.props.fetchAccountInfo();
      this.setState({ showSoundModal: true });
      if (!this.props.orders.orders) {
        this.props.fetchOrders(this.props.t);
      }
      if (!this.props.feedbacks) {
        this.props.fetchFeedbacks(this.props.t, "app");
      }
      setTimeout(this.fetchOrders, 1000 * 60 * 10);

      this.props.fetchStoreHealth();
      setInterval(() => {
        this.props.fetchStoreHealth();
      }, 1000 * 60 * 10);

      await this.props.fetchUserProfile();

      try {
        let pkg = "unknown";

        try {
          switch (this.props.settings.package) {
            case 0:
              pkg = "go";
              break;
            case 1:
              pkg = "basic";
              break;
            case 2:
              pkg = "advanced";
              break;
            case 3:
              pkg = "enterprise";
              break;
            default:
              pkg = "custom";
          }
        } catch (error) {
          console.log(error);
        }

        let countryCodes = {
          Kuwait: "KW",
          Bahrain: "BH",
          "Saudi Arabia": "SA",
          Qatar: "QA",
          Oman: "OM",
          UAE: "AE",
        };

        const { profile } = this.props.profile;
        if (profile.key) {
          let email = (profile.email || "").trim() || this.props.username;
          Crisp.user.setCompany(this.props.settings.name, {
            url: "https://" + this.props.settings.domain,
            geolocation: {
              country: countryCodes[this.props.settings.base_country] || "",
            },
          });
          if (profile.phone) Crisp.user.setPhone(profile.phone);
          Crisp.user.setEmail(email);
          let crispData = {
            user_id: this.props.username,
            plan: pkg,
            language: this.props.config.language,
            country: this.props.settings.base_country,
            domain: this.props.settings.domain,
            used_domain: window.location.hostname,
          };
          if (this.props.settings.hs_deal_id)
            crispData[
              "deal"
            ] = `https://app.hubspot.com/contacts/8324097/deal/${this.props.settings.hs_deal_id}/`;
          Crisp.session.setData(crispData);

          Crisp.session.onLoaded((sessionId) => {
            // Executed once the Crisp session is loaded
            let email = (profile.email || "").trim() || this.props.username;
            Crisp.user.setCompany(this.props.settings.name, {
              url: "https://" + this.props.settings.domain,
              geolocation: {
                country: countryCodes[this.props.settings.base_country] || "",
              },
            });
            if (profile.phone) Crisp.user.setPhone(profile.phone);
            Crisp.user.setEmail(email);
            let crispData = {
              user_id: this.props.username,
              plan: pkg,
              language: this.props.config.language,
              country: this.props.settings.base_country,
              domain: this.props.settings.domain,
              used_domain: window.location.hostname,
            };
            if (this.props.settings.hs_deal_id)
              crispData[
                "deal"
              ] = `https://app.hubspot.com/contacts/8324097/deal/${this.props.settings.hs_deal_id}/`;
            Crisp.session.setData(crispData);
          });

          // if (window.Tawk_API?.setAttributes) {
          //   window.Tawk_API.setAttributes(
          //     {
          //       name: this.props.username + " - package: " + pkg,
          //       language: this.props.config.language,
          //       email: email,
          //       hash: profile.key,
          //     },
          //     (error) => {
          //       if (error) console.log(error);
          //     }
          //   );
          // }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (localStorage.getItem("nextRoute") && user) {
      this.props.history.replace(localStorage.getItem("nextRoute"));
      localStorage.removeItem("nextRoute");
    }
  };

  render() {
    const {
      t,
      config,
      ordersLayout,
      canOpenAllSections,
      settings,
      displayNPS,
    } = this.props;
    const { showOverdueModal, showSoundModal } = this.state;
    const isInPos = window.location.pathname.toLowerCase().includes("pos");

    const didFinishLoadingPermissions =
      this.props.user &&
      (this.props.permissions || this.props.is_staff) &&
      !this.props.loadingPermissions;

    const showDashboardIntro =
      didFinishLoadingPermissions && !settings?.did_view_intro;

    const showPasswordResetModal =
      didFinishLoadingPermissions && !settings?.did_set_password;

    return (
      <ConfigProvider
        direction={config.direction}
        locale={config.language == "arabic" ? ArabicLocale : EnglishLocale}
      >
        {!this.state.isConnectedToNetwork && (
          <div
            id="networkstatus"
            style={{
              height: 30,
              backgroundColor: "red",
              textAlign: "center",
              color: "white",
              paddingTop: 4,
              fontWeight: "bold",
              direction: config.direction,
              position: "fixed",
              width: "100%",
              zIndex: 10000000,
            }}
          >
            <DisconnectOutlined style={{ margin: "0px 5px" }} />
            {t("You are offline")}
          </div>
        )}
        {showPasswordResetModal && (
          <PasswordResetModal
            onClose={() => {
              this.props.hidePasswordChangeModal();
            }}
          />
        )}
        {showDashboardIntro && !showPasswordResetModal && (
          <IntroModal
            onClose={(closeForever) => {
              this.props.hideIntro(closeForever);
            }}
          />
        )}
        {showOverdueModal &&
          this.props.permissions &&
          !showDashboardIntro &&
          !config.runTutorial && (
            <PaymentOverdueModal
              onClose={() => {
                this.props.setDidInteract();
                this.setState({ showOverdueModal: false });
              }}
            />
          )}
        {showSoundModal &&
          this.props.permissions &&
          !this.props.didInteract &&
          !showOverdueModal &&
          !showDashboardIntro &&
          !config.runTutorial &&
          !showPasswordResetModal &&
          !settings.is_menu && (
            <InteractForSoundModal
              onClose={() => {
                this.props.setDidInteract();
                const aud = new Audio(silence);
                aud.play();
                this.setState({ showSoundModal: false });
              }}
            />
          )}
        <div
          className=""
          style={
            !this.state.isConnectedToNetwork
              ? { position: "relative", top: 30 }
              : {}
          }
        >
          <Layout
            style={{
              minHeight: "100vh",
              height: !this.props.user && "100vh",
            }}
          >
            {this.props.user &&
              (this.props.permissions || this.props.is_staff) && <SideNav />}

            <Layout
              className="site-layout"
              style={{ backgroundColor: !this.props.user && "#0099cc" }}
            >
              {this.props.user && <Header />}
              {displayNPS && <Nps />}
              <Content
                style={{
                  margin: "0 7px",
                }}
              >
                <div
                  className="site-layout-background site-layout-background-sider"
                  style={{
                    padding: !isInPos ? "20px 0px" : "7px 7px",
                    minHeight: 360,
                    height: !this.props.user && "100vh",
                  }}
                >
                  {this.props.loadingPermissions ? (
                    <div className="text-center">
                      <Spin indicator={antIcon} />
                      <p className="mt-3">{t("Loading your permissions")}</p>
                      <div
                        className="text-center"
                        style={{
                          width: "100%",
                          position: "absolute",
                          bottom: 10,
                          fontSize: 12,
                        }}
                      >
                        {t("Facing problems? Click")}{" "}
                        <span
                          onClick={() => {
                            this.props.logout();
                            window.location.reload();
                          }}
                          style={{ color: "#0099cc", cursor: "pointer" }}
                        >
                          {t("here")}
                        </span>{" "}
                        {t("to logout")}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Tour />
                      <Suspense
                        fallback={
                          <div className="text-center mt-5">
                            <Spin indicator={antIcon} />
                          </div>
                        }
                      >
                        <Switch>{this.prepareRoutes()}</Switch>
                      </Suspense>
                    </>
                  )}
                </div>
              </Content>
            </Layout>
            {/* {this.props.user &&
            (this.props.permissions || this.props.is_staff) &&
            config.direction == "rtl" ? (
              <SideNav />
            ) : null} */}
          </Layout>
        </div>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    socket: state.socket,
    orders: state.orders,
    bellHasNewOrders: state.orders.bellHasNewOrders,
    bellHasNewBusinessOrders: state.businessOrders.bellHasNewBusinessOrders,
    user: state.auth.user,
    permissions: state.auth.permissions,
    loadingPermissions: state.auth.loadingPermissions,
    settings: state.auth.settings,
    config: state.config,
    soundEnabled: state.orders.soundEnabled,
    is_staff: state.auth.is_staff,
    displayNPS: state.auth.display_nps,

    feedbacks: state.feedbacks.feedbacks,
    ordersLayout: state.orders.ordersLayout,
    info: state.account.info,
    canOpenAllSections: state.account.info
      ? state.account.info.next_payment_date
        ? moment(state.account.info.next_payment_date, "YYYY-MM-DD").diff(
            moment(),
            "days"
          ) +
            state.account.info.grace_period +
            1 >
          0
        : true
      : false,
    profile: state.profile,
    username: state.auth.username,
    didInteract: state.orders.didInteract,
    excluded_routes: state.auth.excluded_routes,
    audio: state.orders.audio,
    audioPaused: state.orders.audioPaused,
    isHelpOpen: state.orders.isHelpOpen,
    bellHasNewAggregate: state.orders.bellHasNewAggregate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    joinSocket: (t, history) => dispatch(joinSocket(t, history)),
    fetchOrders: (t) => dispatch(fetchOrders(t)),
    fetchFeedbacks: (t, page) => dispatch(fetchFeedbacks(t, page)),
    fetchStoreHealth: () => dispatch(fetchStoreHealth()),
    fetchAccountInfo: () => dispatch(fetchAccountInfo()),
    fetchUserProfile: () => dispatch(fetchUserProfile()),

    setDidInteract: () => dispatch({ type: "SET_DID_INTERACT" }),
    hideIntro: (closeForever) => dispatch(hideIntro(closeForever)),
    switchLanguage: (lan) => dispatch(switchLanguage(lan)),
    logout: () => dispatch(logout()),
    hidePasswordChangeModal: () => dispatch(hidePasswordChangeModal()),
    setAudio: (p) => dispatch({ type: "SET_AUDIO", payload: p }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(App)));
