import {
  FETCH_BUSINESS_ORDERS,
  SET_BUSINESS_ORDERS_LOADING,
  NEW_BUSINESS_ORDERS_RING,
  UPDATE_BUSINESS_ORDER,
  SAVE_BUSINESS_ORDER_NOTES,
  DISPATCH_BUSINESS_ORDER,
  SAVE_PAST_ORDERS,
  PERFORM_BUSINESS_BULK_ACTION,
  EDIT_BUSINESS_EXPECTED,
  CHANGE_ORDERS_LAYOUT_B2B,
  UNDISPATCH_ORDER_B2B,
} from "../actions/actionTypes";
import moment from "moment";

const initialState = {
  orders: [],
  pastOrders: [],
  distributors: [],
  branches: [],
  loading: false,
  bellHasNewBusinessOrders: false,
  ordersLayout: localStorage.getItem("orders_layout_b2b")
    ? localStorage.getItem("orders_layout_b2b")
    : "new",
};

export default (state = initialState, { type, payload }) => {
  let newOrders = [];
  let newPastOrders = [];
  let index = null;
  let hasNewOrders = null;
  switch (type) {
    case CHANGE_ORDERS_LAYOUT_B2B:
      return { ...state, ordersLayout: payload };

    case SET_BUSINESS_ORDERS_LOADING:
      return { ...state, loading: true };
    case NEW_BUSINESS_ORDERS_RING:
      return { ...state, bellHasNewBusinessOrders: true };

    case FETCH_BUSINESS_ORDERS:
      hasNewOrders = payload.orders.some((order) => order.status == "New");
      return {
        ...state,
        orders: payload.orders,
        bellHasNewBusinessOrders: hasNewOrders,
        distributors: payload.distributors,
        branches: payload.branches,
        loading: false,
      };

    case SAVE_PAST_ORDERS:
      return { ...state, pastOrders: payload };
    case UPDATE_BUSINESS_ORDER:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      index = newOrders.findIndex((order) => order.id == payload.id);
      // Couldn't find it in orders, search for it in past orders
      if (index == -1 && newPastOrders.length) {
        index = newPastOrders.findIndex((order) => order.id == payload.id);
        newPastOrders[index] = payload;
      } else newOrders[index] = payload;

      hasNewOrders = newOrders.some((order) => order.status == "New");

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        bellHasNewBusinessOrders: hasNewOrders,
      };

    case SAVE_BUSINESS_ORDER_NOTES:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      index = newOrders.findIndex((order) => order.id == payload.oid);
      if (index == -1 && newPastOrders.length) {
        index = newPastOrders.findIndex((order) => order.id == payload.oid);
        newPastOrders[index].notes = payload.notes;
      } else newOrders[index].notes = payload.notes;
      return { ...state, orders: newOrders, pastOrders: newPastOrders };

    case DISPATCH_BUSINESS_ORDER:
      const {
        id,
        dispatcher,
        reference,
        trackingLink,
        estimatedDuration,
        QR,
        walletCharge,
      } = payload;
      newOrders = state.orders;
      newPastOrders = state.pastOrders;

      index = newOrders.findIndex((order) => order.id == id);
      if (index == -1 && state.pastOrders.length) {
        index = newPastOrders.findIndex((order) => order.id == id);

        newPastOrders[index]["shipping"] = {
          ...newPastOrders[index]["shipping"],
          dispatch_reference: reference,
          is_dispatched: true,
          dispatcher: dispatcher,
          tracking_link: trackingLink,
          estimated_duration: estimatedDuration,
          qr_code: QR,
          wallet_charge: walletCharge,
        };
        newPastOrders[index].status = "Out for Delivery";
      } else {
        newOrders[index]["shipping"] = {
          ...newOrders[index]["shipping"],
          dispatch_reference: reference,
          is_dispatched: true,
          dispatcher: dispatcher,
          tracking_link: trackingLink,
          estimated_duration: estimatedDuration,
          qr_code: QR,
          wallet_charge: walletCharge,
        };
        newOrders[index].status = "Out for Delivery";
      }
      hasNewOrders = newOrders.some((order) => order.status == "New");

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
        bellHasNewBusinessOrders: hasNewOrders,
      };

    case UNDISPATCH_ORDER_B2B:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;

      index = newOrders.findIndex((order) => order.id == payload);
      if (index == -1 && state.pastOrders.length) {
        index = newPastOrders.findIndex((order) => order.id == payload);

        newPastOrders[index]["shipping"] = {
          ...newPastOrders[index]["shipping"],
          dispatch_reference: null,
          is_dispatched: false,
          dispatcher: null,
          tracking_link: null,
          estimated_duration: null,
          qr_code: null,
          wallet_charge: 0,
        };
      } else {
        newOrders[index]["shipping"] = {
          ...newOrders[index]["shipping"],
          dispatch_reference: null,
          is_dispatched: false,
          dispatcher: null,
          tracking_link: null,
          estimated_duration: null,
          qr_code: null,
          wallet_charge: 0,
        };
      }

      return {
        ...state,
        orders: newOrders,
        pastOrders: newPastOrders,
      };

    case PERFORM_BUSINESS_BULK_ACTION:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;
      payload.ids.forEach((id) => {
        index = newOrders.findIndex((order) => order.id == id);
        if (index >= 0) {
          // It's in orders
          if (payload.status) {
            newOrders[index].status = payload.status;
          }
          if (payload.paymentStatus !== "") {
            newOrders[index].payment_complete = payload.paymentStatus;
          }
        } else {
          // It's in past orders
          index = newPastOrders.findIndex((order) => order.id == id);
          if (payload.status) {
            newPastOrders[index].status = payload.status;
          }
          if (payload.paymentStatus !== "") {
            newPastOrders[index].payment_complete = payload.paymentStatus;
          }
        }
      });
      return { ...state, orders: newOrders, pastOrders: newPastOrders };

    case EDIT_BUSINESS_EXPECTED:
      newOrders = state.orders;
      newPastOrders = state.pastOrders;

      index = newOrders.findIndex((order) => order.id == payload.id);
      if (index >= 0) {
        // It's in orders
        newOrders[index].expected_delivery_date = payload.date;
        newOrders[index].invoice_date = payload.date;
        newOrders[index].date_edited_by = payload.username;
        if (payload.date < moment().format("YYYY-MM-DD")) {
          if (newPastOrders.length) {
            newPastOrders = [newOrders[index], ...newPastOrders];
          }
          newOrders = newOrders.filter((ord) => ord.id != payload.id);
        }
      } else {
        // It's in past orders
        index = newPastOrders.findIndex((order) => order.id == payload.id);
        newPastOrders[index].expected_delivery_date = payload.date;
        newPastOrders[index].invoice_date = payload.date;
        newPastOrders[index].date_edited_by = payload.username;

        if (payload.date >= moment().format("YYYY-MM-DD")) {
          newOrders = [newPastOrders[index], ...newOrders];
          newPastOrders = newPastOrders.filter((ord) => ord.id != payload.id);
        }
      }

      return { ...state, orders: newOrders, pastOrders: newPastOrders };

    default:
      return state;
  }
};
