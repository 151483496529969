import {
  ADD_EXTRA_FIELD,
  ADD_MODIFIER,
  ADD_OPTIONS_GROUP,
  ADD_PHOTO_TO_GALLERY,
  ADD_PRODUCT_COMPONENT,
  ADD_PRODUCT_LIMIT,
  ADD_PRODUCT_UNIT,
  ADD_SECTION,
  ADD_SUPPLIER,
  BULK_DELETE_CATEGORIES,
  BULK_DELETE_SUPPLIERS,
  BULK_DELETE_UNITS,
  BULK_TOGGLE_CATEGORIES,
  BULK_UPDATE_COMPOSITE_INVENTORIES,
  CHANGE_CATEGORY_BRAND_ID,
  CHANGE_CATEGORY_MINIMUM,
  CHANGE_CATEGORY_NAME,
  CHANGE_CATEGORY_ORDER,
  CHANGE_CATEGORY_PARENT,
  CHANGE_CATEGORY_PASSWORD,
  CHANGE_INVENTORY,
  CHANGE_PRICE,
  CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL,
  CONTROL_EXTRA_FIELD,
  CONTROL_MODIFIER,
  CONTROL_OPTIONS_GROUP,
  CONTROL_PRODUCT_COMPONENT,
  CONTROL_PRODUCT_LIMIT,
  CONTROL_SECTION,
  CONTROL_SUPPLIER,
  CONTROL_UNIT,
  CREATE_CATEGORY,
  CREATE_OPTION,
  CREATE_PRODUCT,
  DELETE_AUTOMATED_CATEGORY_IMAGE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_IMAGE,
  DELETE_GALLERY_IMAGE,
  DELETE_OPTION,
  DELETE_PRODUCT,
  DELETE_PRODUCT_AVAILABILITY,
  DELETE_PRODUCT_IMAGE,
  DELETE_VARIANT_KEY,
  DUPLICATE_PRODUCT,
  FETCH_ALL_PRODUCTS,
  FETCH_OPTIONS_GROUPS,
  FETCH_SECTIONS,
  QUICK_CHANGE_AUTOMATED_CATEGORY_IMAGE,
  QUICK_CHANGE_CATEGORY_IMAGE,
  QUICK_CHANGE_PRODUCT_IMAGE,
  REPLACE_PRODUCTS,
  RESTORE_PRODUCTS,
  SAVE_AUTOMATED_CATEGORY,
  SAVE_BULK_OPTIONS_IN_PRODUCTS,
  SET_OPTIONS_GROUPS_LOADING,
  SET_PRODUCTS_FETCH_LOADING,
  SET_SECTIONS_LOADING,
  TOGGLE_AUTOMATED_CATEGORY,
  TOGGLE_CATEGORY_MINGLING,
  TOGGLE_CATEGORY_STATUS,
  TOGGLE_PRODUCT_STATUS,
  UNDELETE_PRODUCT,
  UPDATE_ASSOCIATED_BRANCHES,
  UPDATE_BOOKING_SLOT_LOCALLY,
  UPDATE_BRANCH_PRODUCT_MASTER,
  UPDATE_CATEGORY_AVAILABILITY,
  UPDATE_CATEGORY_DESCRIPTION,
  UPDATE_EXTRA_FIELD,
  UPDATE_MODIFIER,
  UPDATE_OPTION,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_BARCODE,
  UPDATE_PRODUCTS_EXTRA_FIELDS,
  UPDATE_PRODUCTS_OPTIONS,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";
import { isEmpty } from "../reducers/pos";
import { updateBranchCompositeInventories } from "./locations";
import i18next from "i18next";

export const fetchAllProducts = () => {
  return async (dispatch) => {
    dispatch({ type: SET_PRODUCTS_FETCH_LOADING });
    try {
      let page = 1;
      let numberOfPages = 0; //Any initial value
      do {
        let res = await instance.post("/get_product_list/", { page: page });

        const products = res.data;
        numberOfPages = products.pages_count;
        page += 1;
        dispatch({ type: FETCH_ALL_PRODUCTS, payload: products });
      } while (page <= numberOfPages);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view products");
      else
        message.error("Something went wrong. Refresh the page and try again");
    }
  };
};
export const toggleProductStatus = (id, type, t) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_PRODUCT_STATUS, payload: { id, type } });

    try {
      let res = await instance.post("/toggle_product_status/", {
        id,
        type,
      });

      const success = res.data.success;
      const messageRes = res.data.message;
      if (success) {
        message.success(i18next.t(messageRes));
      } else {
        dispatch({ type: TOGGLE_PRODUCT_STATUS, payload: { id, type } });
        message.error(i18next.t(messageRes));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(i18next.t("AccessDeniedMessage"));
      else message.error(i18next.t("SomethingWentWrong"));
      dispatch({ type: TOGGLE_PRODUCT_STATUS, payload: { id, type } });
    }
  };
};

export const deleteProduct = (id, t) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT, payload: id });

    try {
      let res = await instance.post("/delete_product/", { id: id });

      const success = res.data.success;
      const messageRes = res.data.message;
      if (success) {
        message.success(t(messageRes));

        if (!isEmpty(res.data.new_composite_products_inventories)) {
          dispatch({
            type: BULK_UPDATE_COMPOSITE_INVENTORIES,
            payload: {
              compositeProducts: res.data.new_composite_products_inventories,
              deletedComponents: true,
              deletedComponentsIds: [id],
            },
          });
        }
      } else {
        dispatch({ type: UNDELETE_PRODUCT, payload: id });
        message.error(t(messageRes));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({ type: UNDELETE_PRODUCT, payload: id });
    }
  };
};

export const changeInventory = (id, newInventory, oldInventory, t) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_INVENTORY,
      payload: { id: id, newInventory: newInventory },
    });

    try {
      let res = await instance.post("/quick_modify_inventory/", {
        id: id,
        newInventory: newInventory,
      });

      const success = res.data.success;
      const messageRes = res.data.message;
      if (success) {
        message.success(t(messageRes));

        if (!isEmpty(res.data.new_composite_products_inventories)) {
          dispatch({
            type: BULK_UPDATE_COMPOSITE_INVENTORIES,
            payload: {
              compositeProducts: res.data.new_composite_products_inventories,
            },
          });
        }
      } else {
        dispatch({
          type: CHANGE_INVENTORY,
          payload: { id: id, newInventory: oldInventory },
        });
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const changePrice = (id, newPrice, oldPrice, t) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_PRICE,
      payload: { id: id, newPrice: newPrice },
    });

    try {
      let res = await instance.post("/quick_modify_price/", {
        id: id,
        newPrice: newPrice,
      });

      const success = res.data.success;
      const messageRes = res.data.message;
      if (success) {
        message.success(t(messageRes));
      } else {
        dispatch({
          type: CHANGE_PRICE,
          payload: { id: id, newPrice: oldPrice },
        });
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const quickChangeProductImage = (response) => {
  return {
    type: QUICK_CHANGE_PRODUCT_IMAGE,
    payload: response,
  };
};

export const addPhotoToGallery = (response) => {
  return {
    type: ADD_PHOTO_TO_GALLERY,
    payload: response,
  };
};

export const deleteImageFromGallery = (id, url, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_gallery_image/", {
        pid: id, //product id
        pmid: url, //product media id
      });
      if (res.data.success == true) {
        message.success(t("Image deleted successfully"));
        dispatch({
          type: DELETE_GALLERY_IMAGE,
          payload: {
            pid: id,
            pmid: url,
          },
        });
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const sortImageFromGallery = (id, newSortOrder, t) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SORT_ORDER_GALLERY_IMAGE",
        payload: {
          pid: id,
          newSortOrder: newSortOrder,
        },
      });
      let res = await instance.post("/sort_gallery_image/", {
        pid: id,
        newSortOrder: newSortOrder,
      });
      if (res.data.success === true) {
        message.success(t("Changed successfully"));
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const deleteMainProductImage = (id, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_main_product_photo/", {
        pid: id, //product id
      });
      if (res.data.success == true) {
        message.success(t("Image deleted successfully"));
        dispatch({
          type: DELETE_PRODUCT_IMAGE,
          payload: {
            pid: id,
          },
        });
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const updateProduct = (product) => {
  return {
    type: UPDATE_PRODUCT,
    payload: product,
  };
};

export const updateAssociatedBranches = (id, associated_branches) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ASSOCIATED_BRANCHES,
      payload: { pid: id, associated_branches: associated_branches },
    });
  };
};

export const update_branch_product_master = (id, bid, key, value, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/update_branch_product_master/", {
        id: id,
        bid: bid,
        key: key,
        value: value,
      });
      if (res.data.success == true) {
        if (res.data.newValue != "") {
          value = res.data.newValue;
        }
        message.success(t("Updated successfully"));

        dispatch({
          type: UPDATE_BRANCH_PRODUCT_MASTER,

          payload: {
            id: id,
            bid: bid,
            key: key,
            value: value,
          },
        });
        if (res.data.context != "") {
          dispatch(
            updateBranchCompositeInventories({
              branchID: bid,
              new_composite_products_inventories: res.data.context,
            })
          );
        }
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const createProduct = (product) => {
  return {
    type: CREATE_PRODUCT,
    payload: product,
  };
};

export const restoreProducts = (product) => {
  return {
    type: RESTORE_PRODUCTS,
    payload: product,
  };
};

export const saveInventoryDetails = (
  id,
  inventory,
  minimumHoldingInventory,
  sku,
  replenish_count,
  replenish_time,
  allow_preordering,
  preorder_inventory,
  preordering_leadtime,
  reset_inventory_count,
  reset_inventory_time,
  warning_item_availability_count,
  warning_item_availability_message_value,
  show_warning_item_availability,
  t
) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/save_inventory_details/", {
        pid: id,
        inventory_on_hand: inventory,
        minimum_holding_inventory: minimumHoldingInventory,
        sku: sku,
        replenish_count: replenish_count,
        replenish_time: replenish_time,
        allow_preordering,
        preorder_inventory,
        preordering_leadtime,
        reset_inventory_count,
        reset_inventory_time,
        warning_item_availability_count,
        warning_item_availability_message_value,
        show_warning_item_availability,
      });
      if (res.data.success == true) {
        message.success(t("Updated inventory successfully"));
        dispatch({
          type: UPDATE_PRODUCT,
          payload: res.data.product,
        });
        dispatch({
          type: CHANGE_INVENTORY,
          payload: {
            id: res.data.product.id,
            newInventory: res.data.product.inventory_on_hand,
          },
        });
        if (!isEmpty(res.data.new_composite_products_inventories)) {
          dispatch({
            type: BULK_UPDATE_COMPOSITE_INVENTORIES,
            payload: {
              compositeProducts: res.data.new_composite_products_inventories,
            },
          });
        }
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const createCategory = (categoryObj) => {
  return {
    type: CREATE_CATEGORY,
    payload: categoryObj,
  };
};

export const deleteCategory = (id, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_category/", {
        id: id,
      });
      if (res.data.success == true) {
        message.success(t("Category deleted successfully"));
        dispatch({
          type: DELETE_CATEGORY,
          payload: id,
        });
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const toggleCategoryStatus = (id, t) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_CATEGORY_STATUS, payload: id });

    try {
      let res = await instance.post("/toggle_category_status/", { id: id });

      if (res.data.success) {
        message.success(t("Category has been toggled successfully"));
      } else {
        dispatch({ type: TOGGLE_CATEGORY_STATUS, payload: id });
        message.error(t("Couldn't toggle category status"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({ type: TOGGLE_CATEGORY_STATUS, payload: id });
    }
  };
};

export const changeCategoryName = (language, id, newName, oldName, t) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_CATEGORY_NAME,
      payload: { id: id, newName: newName, language: language },
    });

    try {
      let res = await instance.post("/change_category_name/", {
        id: id,
        name: newName,
        language: language,
      });

      const success = res.data.success;
      if (success) {
        message.success(t("Name has changed successfully"));
      } else {
        dispatch({
          type: CHANGE_CATEGORY_NAME,
          payload: { id: id, newName: oldName, language: language },
        });
        message.error(t("Couldn't change category name"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));

      dispatch({
        type: CHANGE_CATEGORY_NAME,
        payload: { id: id, newName: oldName, language: language },
      });
    }
  };
};

export const changeCategoryOrder = (id, newOrder, oldOrder, t) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_CATEGORY_ORDER,
      payload: { id: id, newOrder: newOrder },
    });

    try {
      let res = await instance.post("/change_category_order/", {
        id: id,
        order: newOrder,
      });

      const success = res.data.success;
      if (success) {
        message.success(t("Order has changed successfully"));
      } else {
        dispatch({
          type: CHANGE_CATEGORY_ORDER,
          payload: { id: id, newOrder: oldOrder },
        });
        message.error(t("Couldn't change category order"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));

      dispatch({
        type: CHANGE_CATEGORY_ORDER,
        payload: { id: id, newOrder: oldOrder },
      });
    }
  };
};
export const changeCategoryMinimum = (
  id,
  newCategeryMin,
  oldCategeryMin,
  t
) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_CATEGORY_MINIMUM,
      payload: { id: id, newCategeryMin: newCategeryMin },
    });

    try {
      let res = await instance.post("/change_category_minimum_order/", {
        id: id,
        newCategeryMin: newCategeryMin,
      });

      const success = res.data.success;
      if (success) {
        message.success(t("Minimum order value has changed successfully"));
      } else {
        dispatch({
          type: CHANGE_CATEGORY_MINIMUM,
          payload: { id: id, newCategeryMin: oldCategeryMin },
        });
        message.error(t("Couldn't change category minimum order value"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));

      dispatch({
        type: CHANGE_CATEGORY_MINIMUM,
        payload: { id: id, newCategeryMin: oldCategeryMin },
      });
    }
  };
};

export const quickChangeCategoryImage = (response) => {
  return {
    type: QUICK_CHANGE_CATEGORY_IMAGE,
    payload: response,
  };
};

export const quickChangeAutomatedCategoryImage = (response) => {
  return {
    type: QUICK_CHANGE_AUTOMATED_CATEGORY_IMAGE,
    payload: response,
  };
};

export const deleteCategoryImage = (id, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_category_image/", {
        id: id,
      });
      if (res.data.success == true) {
        message.success(t("Image deleted successfully"));
        dispatch({
          type: DELETE_CATEGORY_IMAGE,
          payload: {
            id: id,
          },
        });
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};
export const deleteAutomatedCategoryImage = (id, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_automated_category_image/", {
        id: id,
      });
      if (res.data.success == true) {
        message.success(t("Image deleted successfully"));
        dispatch({
          type: DELETE_AUTOMATED_CATEGORY_IMAGE,
          payload: {
            id: id,
          },
        });
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const toggleAutomatedCategory = (type) => {
  return {
    type: TOGGLE_AUTOMATED_CATEGORY,
    payload: type,
  };
};
export const toggleAutomatedCategoryShowCover = (type) => {
  return {
    type: "TOGGLE_AUTOMATED_CATEGORY_SHOW_COVER",
    payload: type,
  };
};

export const saveAutomatedCategory = (type, order, limit) => {
  return {
    type: SAVE_AUTOMATED_CATEGORY,
    payload: { type: type, order: order, limit: limit },
  };
};

export const deleteProductAvailability = (pid, paid) => {
  return {
    type: DELETE_PRODUCT_AVAILABILITY,
    payload: { pid: pid, paid: paid },
  };
};

export const updateOption = (option) => {
  return {
    type: UPDATE_OPTION,
    payload: option,
  };
};

export const createOption = (option) => {
  return {
    type: CREATE_OPTION,
    payload: option,
  };
};

export const deleteOption = (pid, id) => {
  return {
    type: DELETE_OPTION,
    payload: { pid: pid, id: id },
  };
};

export const duplicateProduct = (product) => {
  return {
    type: DUPLICATE_PRODUCT,
    payload: product,
  };
};

export const deleteVariantKey = (variants_to_delete) => {
  return {
    type: DELETE_VARIANT_KEY,
    payload: variants_to_delete,
  };
};

export const replaceProducts = (products) => {
  return {
    type: REPLACE_PRODUCTS,
    payload: products,
  };
};

export const bulkToggleCategories = (ids, status) => {
  return {
    type: BULK_TOGGLE_CATEGORIES,
    payload: { ids: ids, status: status },
  };
};

export const bulkDeleteCategories = (ids) => {
  return {
    type: BULK_DELETE_CATEGORIES,
    payload: ids,
  };
};

export const updateBookingSlotLocally = (params) => {
  return {
    type: UPDATE_BOOKING_SLOT_LOCALLY,
    payload: params,
  };
};

export const changeCategoryParent = (categories) => {
  return {
    type: CHANGE_CATEGORY_PARENT,
    payload: { categories },
  };
};

export const addProductLimit = (payload) => {
  return {
    type: ADD_PRODUCT_LIMIT,
    payload: payload,
  };
};

export const controlProductLimit = (payload) => {
  return {
    type: CONTROL_PRODUCT_LIMIT,
    payload: payload,
  };
};
export const changeCategoryPassword = (id, password) => {
  return {
    type: CHANGE_CATEGORY_PASSWORD,
    payload: { id, password },
  };
};
export const changeCategoryBrandID = (id, brandID) => {
  return {
    type: CHANGE_CATEGORY_BRAND_ID,
    payload: { id, brandID },
  };
};

export const saveBulkOptionsInProducts = (options) => {
  return {
    type: SAVE_BULK_OPTIONS_IN_PRODUCTS,
    payload: { options },
  };
};
export const addProductComponent = (payload) => {
  return {
    type: ADD_PRODUCT_COMPONENT,
    payload: payload,
  };
};

export const controlProductComponent = (payload) => {
  return {
    type: CONTROL_PRODUCT_COMPONENT,
    payload: payload,
  };
};

export const addProductUnit = (unit) => {
  return {
    type: ADD_PRODUCT_UNIT,
    payload: unit,
  };
};

export const addSupplier = (supplier) => {
  return {
    type: ADD_SUPPLIER,
    payload: supplier,
  };
};

export const updateProductInventory = (product) => {
  return {
    type: CHANGE_INVENTORY,
    payload: product,
  };
};

export const controlUnit = (payload) => {
  return {
    type: CONTROL_UNIT,
    payload: payload,
  };
};

export const bulkDeleteUnits = (payload) => {
  return {
    type: BULK_DELETE_UNITS,
    payload: payload,
  };
};

export const controlSupplier = (payload) => {
  return {
    type: CONTROL_SUPPLIER,
    payload: payload,
  };
};

export const bulkDeleteSuppliers = (payload) => {
  return {
    type: BULK_DELETE_SUPPLIERS,
    payload: payload,
  };
};

export const bulkUpdateCompositeInventories = (payload) => {
  return {
    type: BULK_UPDATE_COMPOSITE_INVENTORIES,
    payload: payload,
  };
};

export const extraFieldControl = (actionType, pid, fid) => {
  return {
    type: CONTROL_EXTRA_FIELD,
    payload: { actionType, pid, fid },
  };
};

export const addExtraField = (payload) => {
  return {
    type: ADD_EXTRA_FIELD,
    payload: payload,
  };
};

export const updateProductsExtraFields = (payload) => {
  return {
    type: UPDATE_PRODUCTS_EXTRA_FIELDS,
    payload: payload,
  };
};

export const updateProductsOptions = (payload) => {
  return {
    type: UPDATE_PRODUCTS_OPTIONS,
    payload: payload,
  };
};

export const updateExtraField = (option) => {
  return {
    type: UPDATE_EXTRA_FIELD,
    payload: option,
  };
};

export const updateProductBarcode = (payload) => {
  return {
    type: UPDATE_PRODUCT_BARCODE,
    payload: payload,
  };
};
export const updateProductSKU = (ids, data, isBulk) => {
  return {
    type: "UPDATE_PRODUCT_SKU",
    payload: {
      productID: ids,
      data: data,
      isBulk: isBulk,
    },
  };
};

export const updateModifier = (payload) => {
  return {
    type: UPDATE_MODIFIER,
    payload: payload,
  };
};

export const addModifier = (payload) => {
  return {
    type: ADD_MODIFIER,
    payload: payload,
  };
};

export const controlModifier = (payload) => {
  return {
    type: CONTROL_MODIFIER,
    payload: payload,
  };
};
export const toggleCategoryMingling = (id, t) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_CATEGORY_MINGLING, payload: id });

    try {
      let res = await instance.post("/toggle_category_mingling/", { id: id });

      message.success(t("Toggled successfully"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({ type: TOGGLE_CATEGORY_MINGLING, payload: id });
    }
  };
};
export const toggleCategoryProductShowCover = (
  id,
  isCategory,
  type,
  storeCoverID,
  t
) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_category_show_cover/", {
        id: id,
        isCategory: isCategory,
      });
      if (type === "add") {
        dispatch({
          type: "CONTROL_STORE_GALLERY_CATEGORY_PRODUCT",
          payload: {
            type: "add",
            file: res.data,
          },
        });
      } else {
        dispatch({
          type: "CONTROL_STORE_GALLERY_CATEGORY_PRODUCT",
          payload: {
            type: "delete",
            storeCoverID: storeCoverID,
          },
        });
      }

      message.success(t("Toggled successfully"));
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const changeProductNotAvailableUntil = (payload) => {
  return {
    type: CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL,
    payload: payload,
  };
};
export const changeCategoryDescription = (payload) => {
  return {
    type: UPDATE_CATEGORY_DESCRIPTION,
    payload: payload,
  };
};

export const updateCategoryAvailability = (id, value) => {
  return {
    type: UPDATE_CATEGORY_AVAILABILITY,
    payload: { id: id, value: value },
  };
};

export const updateCategorySEO = (id, value) => {
  return {
    type: "UPDATE_CATEGORY_SEO",
    payload: { id: id, value: value },
  };
};

//Filters
export const fetchFilter = () => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/fetch_filter_with_options/");
      dispatch({
        type: "FETCH_FILTER",
        payload: res.data.data,
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const createFilter = (name, arName, type, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/create_filter/", {
        name: name,
        ar_name: arName,
        type: type,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "CREATE_FILTER",
          payload: res.data.data,
        });
        message.success(i18next.t("Filter created successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const deleteFilter = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_filter/", { id: id });
      if (res.data.success === true) {
        dispatch({
          type: "DELETE_FILTER",
          payload: id,
        });
        message.success(i18next.t("Filter deleted successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const updateFilter = (id, name, arName, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/update_filter/", {
        id: id,
        name: name,
        ar_name: arName,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "UPDATE_FILTER",
          payload: res.data.data,
        });
        message.success(i18next.t("Filter updated successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const toggleFilterStatus = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_filter_status/", {
        id: id,
      });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_FILTER_STATUS",
          payload: id,
        });
        message.success(i18next.t("Filter status changed successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

//Filter Options

export const fetchFilterOptions = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/fetch_filter_options/", { id: id });
      dispatch({
        type: "FETCH_FILTER_OPTIONS",
        payload: {
          id: id,
          options: res.data.data,
        },
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const createFilterOption = (
  id,
  name,
  arName,
  setLoading,
  type,
  min,
  max,
  step
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/create_filter_option/", {
        id: id,
        name: name,
        ar_name: arName,
        type: type,
        min: min,
        max: max,
        step: step,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "CREATE_FILTER_OPTION",
          payload: {
            id: id,
            option:
              res.data.type === "number" ? res.data.data : res.data.data[0],
            type: res.data.type,
          },
        });
        message.success(i18next.t("Filter option created successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const deleteFilterOption = (id, optionId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_filter_option/", {
        option_id: optionId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "DELETE_FILTER_OPTION",
          payload: {
            id: id,
            optionId: optionId,
          },
        });
        message.success(i18next.t("Filter option deleted successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const updateFilterOption = (
  id,
  optionId,
  name,
  arName,
  setLoading,
  sortOrder
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/update_filter_option/", {
        option_id: optionId,
        name: name,
        ar_name: arName,
        sort_order: sortOrder,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "UPDATE_FILTER_OPTION",
          payload: {
            id: id,
            option: res.data.data,
          },
        });
        message.success(i18next.t("Filter option updated successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const toggleFilterOptionStatus = (id, optionId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_filter_option_status/", {
        option_id: optionId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_FILTER_OPTION_STATUS",
          payload: {
            id: id,
            optionId: optionId,
          },
        });
        message.success(i18next.t("Filter option status changed successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const fetchFilterOptionsProducts = (id, optionId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/fetch_filter_options_products/", {
        option_id: optionId,
      });
      dispatch({
        type: "FETCH_FILTER_OPTIONS_PRODUCTS",
        payload: {
          id: id,
          optionId: optionId,
          products: res.data.data,
        },
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        console.log(err);
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

//PRODUCTS FILTERS
export const fetchProductsFilters = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/fetch_product_filter/", {
        product_id: id,
      });
      dispatch({
        type: "FETCH_PRODUCTS_FILTERS",
        payload: {
          id: id,
          filters: res.data.data,
        },
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const createProductFilter = (productId, option_id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/create_product_filter/", {
        product_id: productId,
        option_id: option_id,
      });
      if (res.data.success === true) {
        dispatch({
          type: "CREATE_PRODUCT_FILTER",
          payload: {
            id: productId,
            filter: res.data.data,
          },
        });
        message.success(i18next.t("Product filter created successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const assignProductFilterOption = (optionId, filterId, productList) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/assign_product_filter/", {
        products_list: productList,
        option_id: optionId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "ASSIGN_PRODUCT_FILTER_OPTION",
          payload: {
            filterId: filterId,
            optionId: optionId,
            productList: productList,
          },
        });
        message.success(
          i18next.t("Product filter option assigned successfully")
        );
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const deleteProductFilter = (
  productId,
  productFilterId,
  filterId,
  optionId
) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_product_filter/", {
        id: productFilterId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "DELETE_PRODUCT_FILTER",
          payload: {
            id: productId,
            productFilterId: productFilterId,
            filterId: filterId,
            optionId: optionId,
          },
        });
        message.success(i18next.t("Product filter deleted successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const toggleProductFilterStatus = (productId, filterId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_product_filter_status/", {
        id: filterId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_PRODUCT_FILTER_STATUS",
          payload: {
            id: productId,
            filterId: filterId,
          },
        });
        message.success(
          i18next.t("Product filter status changed successfully")
        );
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const bulkFilterStatus = (filterIds, status) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/bulk_filter_status/", {
        ids: filterIds,
        status: status,
      });
      if (res.data.success === true) {
        dispatch({
          type: "BULK_FILTER_STATUS_CHANGE",
          payload: {
            filterIds: filterIds,
            status: status,
          },
        });
        message.success(
          i18next.t("Product filter status changed Successfully")
        );
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const bulkDeleteFilter = (filterIds) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/bulk_delete_filter/", {
        ids: filterIds,
      });
      if (res.data.success === true) {
        dispatch({
          type: "BULK_DELETE_FILTER",
          payload: {
            filterIds: filterIds,
          },
        });
        message.success(i18next.t("Product filter deleted Successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};
export const bulkDeleteFilterOptions = (filterId, filterOptionsIds) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/bulk_delete_filter_options/", {
        ids: filterOptionsIds,
        filterId: filterId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "BULK_DELETE_FILTER_OPTIONS",
          payload: {
            filterId: filterId,
            filterOptionsIds: filterOptionsIds,
          },
        });
        message.success(i18next.t("Product filter deleted Successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};
export const bulkStatusFilterOptions = (filterId, filterOptionsIds, status) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/bulk_status_filter_options/", {
        filterId: filterId,
        status: status,
        ids: filterOptionsIds,
      });
      if (res.data.success === true) {
        dispatch({
          type: "BULK_FILTER_OPTIONS_STATUS_CHANGE",
          payload: {
            filterId: filterId,
            filterOptionsIds: filterOptionsIds,
            status: status,
          },
        });
        message.success(
          i18next.t("Product filter status changed Successfully")
        );
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const toggleProductBuyNow = (id, t) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_buy_now/", {
        id,
      });
      if (res.data.success) {
        message.success(t("Toggled successfully"));
        dispatch({ type: "TOGGLE_PRODUCT_BUY_NOW", payload: { id } });
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchOptionsGroups = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_OPTIONS_GROUPS_LOADING,
        payload: true,
      });
      let res = await instance.get(`/fetch_options_group/`);
      dispatch({
        type: FETCH_OPTIONS_GROUPS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response && err.response.status == 403) {
      } else message.error(i18next.t("SomethingWentWrong"));
    }
    dispatch({
      type: SET_OPTIONS_GROUPS_LOADING,
      payload: false,
    });
  };
};
export const addOptionGroup = (payload) => {
  return {
    type: ADD_OPTIONS_GROUP,
    payload: payload,
  };
};

export const controlOptionGroup = (payload) => {
  return {
    type: CONTROL_OPTIONS_GROUP,
    payload: payload,
  };
};

export const fetchSections = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_SECTIONS_LOADING,
        payload: true,
      });
      let res = await instance.get(`/fetch_sections/`);
      dispatch({
        type: FETCH_SECTIONS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response && err.response.status == 403) {
      } else message.error(i18next.t("SomethingWentWrong"));
    }
    dispatch({
      type: SET_SECTIONS_LOADING,
      payload: false,
    });
  };
};
export const addSection = (payload) => {
  return {
    type: ADD_SECTION,
    payload: payload,
  };
};

export const controlSection = (payload) => {
  return {
    type: CONTROL_SECTION,
    payload: payload,
  };
};

// CROSS SELL ITEMS

export const fetchCrossSellItems = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/fetch_cross_sell_items/", {
        product_id: id,
      });
      dispatch({
        type: "FETCH_CROSS_SELL_ITEMS",
        payload: {
          id: id,
          data: res.data.data,
        },
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const toggleCrossSellItemsStatus = (productId, id, isActive) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_cross_sell_status/", {
        id: id,
        isActive: isActive,
      });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_CROSS_SELL_STATUS",
          payload: {
            productId: productId,
            id: id,
            isActive: isActive,
          },
        });
        message.success(i18next.t("Status changed successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const editCrossSellItemsSortOrder = (productId, id, value) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/edit_cross_sell_sort_order/", {
        id: id,
        sortOrder: value,
      });
      if (res.data.success === true) {
        dispatch({
          type: "EDIT_CROSS_SELL_SORT_ORDER",
          payload: {
            productId: productId,
            id: id,
            value: value,
          },
        });
        message.success(i18next.t("Order changed successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const deleteCrossSellItems = (productId, id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_cross_sell/", {
        id: id,
      });
      if (res.data.success === true) {
        dispatch({
          type: "DELETE_CROSS_SELL",
          payload: {
            id: id,
            productId: productId,
          },
        });
        message.success(i18next.t("Deleted successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const addCrossSellItems = (productId, relatedProductId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/create_cross_sell/", {
        productId: productId,
        relatedProductId: relatedProductId,
      });
      if (res.data.success === true) {
        dispatch({
          type: "ADD_CROSS_SELL",
          payload: {
            productId: productId,
            data: res.data.data,
          },
        });
        message.success(i18next.t("Created successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const replaceCrossSellItems = (
  productId,
  selectedProduct,
  selectedCrossSellItem
) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/replace_cross_sell/", {
        id: selectedCrossSellItem,
        newRelatedProductId: selectedProduct,
      });
      if (res.data.success === true) {
        dispatch({
          type: "REPLACE_CROSS_SELL",
          payload: {
            productId: productId,
            data: res.data.data,
            id: selectedCrossSellItem,
          },
        });
        message.success(i18next.t("Replaced successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const fetchProductFeedback = (id) => {
  return async (dispatch) => {
    try {
      let page = 1;
      let res;
      let numberOfPages = 0; //Any initial value

      do {
        let res = await instance.post(`/fetch_product_feedback/`, {
          id: id,
          page: page,
        });
        numberOfPages = res.data.page_count;
        page += 1;
        dispatch({
          type: "FETCH_PRODUCT_FEEDBACK",
          payload: { data: res.data.data, id: id },
        });
      } while (page <= numberOfPages);
    } catch (err) {
      if (err.response && err.response.status === 403) {
      } else message.error(i18next.t("SomethingWentWrong"));
    }
  };
};
export const fetchAllProductFeedback = (id) => {
  return async (dispatch) => {
    try {
      let page = 1;
      let res;
      let numberOfPages = 0; //Any initial value

      do {
        let res = await instance.post(`/fetch_all_product_feedback/`, {
          id: id,
          page: page,
        });
        numberOfPages = res.data.page_count;
        page += 1;
        dispatch({
          type: "FETCH_ALL_PRODUCTS_FEEDBACKS",
          payload: { data: res.data.data },
        });
      } while (page <= numberOfPages);
    } catch (err) {
      if (err.response && err.response.status === 403) {
      } else message.error(i18next.t("SomethingWentWrong"));
    }
  };
};

export const toggleProductFeedback = (id, productId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post(`/toggle_product_feedback/`, {
        id: id,
      });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_PRODUCT_FEEDBACK",
          payload: { id: id, productId: productId },
        });
        message.success(i18next.t("Changed successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
      } else message.error(i18next.t("SomethingWentWrong"));
    }
  };
};

export const setReadByProductFeedback = (id, productId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post(`/set_ready_product_feedback/`, {
        id: id,
      });
      if (res.data.success === true) {
        dispatch({
          type: "SET_READ_BY_PRODUCT_FEEDBACK",
          payload: { id: id, productId: productId },
        });
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
      } else message.error(i18next.t("SomethingWentWrong"));
    }
  };
};

// Tags

export const fetchTag = () => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/fetch_tags/");
      dispatch({
        type: "FETCH_PRODUCT_TAG",
        payload: res.data.data,
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const createTag = (
  textEnglish,
  textArabic,
  tagColor,
  showTagUntil,
  enableTagUntil,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/create_product_tag/", {
        tag_color: tagColor,
        text_ar: textArabic,
        text_en: textEnglish,
        enable_tag_until: enableTagUntil,
        show_tag_until: showTagUntil,
      });
      setLoading(false);
      if (res.data.success === true) {
        dispatch({
          type: "CREATE_PRODUCT_TAG",
          payload: res.data.data,
        });
        message.success(i18next.t("Tag created successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const editTag = (
  id,
  textEnglish,
  textArabic,
  tagColor,
  showBannerUntil,
  enableTagUntil,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      let res = await instance.post("/edit_product_tag/", {
        id: id,
        tag_color: tagColor,
        text_ar: textArabic,
        text_en: textEnglish,
        enable_tag_until: enableTagUntil,
        show_tag_until: showBannerUntil,
      });
      setLoading(false);
      if (res.data.success === true) {
        console.log("here");
        dispatch({
          type: "UPDATE_PRODUCT_TAG",
          payload: res.data.data,
        });
        message.success(i18next.t("Tag edited successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
      setLoading(false);
    }
  };
};

export const deleteTag = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/delete_product_tag/", { id: id });
      if (res.data.success === true) {
        dispatch({
          type: "DELETE_PRODUCT_TAG",
          payload: id,
        });
        message.success(i18next.t("Tag deleted successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const toggleTag = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/toggle_product_tag/", { id: id });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_PRODUCT_TAG",
          payload: id,
        });
        message.success(i18next.t("Tag toggled successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const bulkTagStatus = (tagIds, status) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/bulk_tag_status/", {
        ids: tagIds,
        status: status,
      });
      if (res.data.success === true) {
        dispatch({
          type: "BULK_TAG_STATUS_CHANGE",
          payload: {
            tagIds: tagIds,
            status: status,
          },
        });
        message.success(i18next.t("Tags status changed successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};

export const bulkTagDelete = (tagIds) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/bulk_delete_tag/", {
        ids: tagIds,
      });
      if (res.data.success === true) {
        dispatch({
          type: "BULK_DELETE_TAG",
          payload: {
            tagIds: tagIds,
          },
        });
        message.success(i18next.t("Tags deleted Successfully"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(i18next.t("AccessDeniedMessage"));
      } else {
        message.error(i18next.t("SomethingWentWrong"));
      }
    }
  };
};
