import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { Button, Dropdown, Menu, message } from "antd";
import instance from "../../store/actions/instance";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import EditSpecialRemarks from "./EditSpecialRemarks";

export class InvoiceTable extends Component {
  state = {
    printableToken: null,
    loading: false,
    editRemarks: false,
  };
  getInvoiceToken = async () => {
    this.setState({ loading: true });
    const { t } = this.props;
    try {
      const res = await instance.get(`/get_printable_token/`);
      if (res.data.success) {
        this.setState({ loading: false });

        return res.data.token;
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
    }
  };
  getDiscountedProductName = (itemID) => {
    const { config, orderDetails } = this.props;
    const product = orderDetails.items.find((item) => item.id == itemID);
    if (product) {
      return (config.language === "arabic" && product.name_ar) || product.name;
    }
    return "";
  };
  markServed = async (itemsIDs) => {
    const { t } = this.props;
    try {
      this.props.updateOrderDetails(itemsIDs, "Complete");
      const res = await instance.post(`/mark_item_serverd_dine_in/`, {
        itemsIDs,
      });
    } catch (err) {
      if (err?.response?.status == 403) message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      this.props.updateOrderDetails(itemsIDs, "New");
    }
  };
  viewInvoice = async (lang) => {
    const { order, settings } = this.props;
    let token;
    if (!this.state.printableToken) {
      token = await this.getInvoiceToken();
      this.setState({ printableToken: token });
    }
    token = this.state.printableToken;
    let type = order.type?.toLowerCase();
    if (order?.status == "New POS") {
      type = "order";
    }
    let url;

    url = `https://${settings.domain}/manage/invoice/${order.id}/print/preparation/pdf/${token}/${type}/?lang=${lang}&print_new_items=1`;

    window.open(url, "_blank");
  };

  render() {
    const translateStartEnd = (str) => {
      if (config.language != "arabic") return str;
      return str
        .replace("AM", "ص")
        .replace("AM", "ص")
        .replace("PM", "م")
        .replace("PM", "م")
        .replace("to", "الى");
    };

    const { t, settings, config, orderDetails, order, isUnservedTable } =
      this.props;
    const { loading, editRemarks } = this.state;
    const currency =
      config.language == "arabic"
        ? settings.currency_local
        : settings.currency_english;

    const PrintMenu = (
      <Menu onClick={(e) => this.viewInvoice(e.key)}>
        <Menu.Item key="en">{t("Englsh")}</Menu.Item>
        <Menu.Item key="ar">{t("Arabic")}</Menu.Item>
      </Menu>
    );

    const checkIfAramexOrDhl = () => {
      if (order.using_dhl_rate && settings.enable_dhl) {
        return "(DHL)";
      } else if (order.using_dhl_rate && settings.enable_aramex) {
        return "(Aramex)";
      }
    };

    return (
      <>
        <div className="row">
          {editRemarks &&
            this.props.permissions.can_edit_orders &&
            !this.props.pending && (
              <EditSpecialRemarks
                visible={editRemarks}
                selectedOrder={order}
                onClose={() => this.setState({ editRemarks: false })}
                refetchLastEditedBy={() => this.props.refetchLastEditedBy()}
              />
            )}
          <div className="col-xs-12 col-12" style={{ overflowX: "scroll" }}>
            <h5>
              {isUnservedTable ? t("Upcoming Order") : t("Your Order")}:
              {isUnservedTable && (
                <span className="mx-3">
                  <Dropdown overlay={PrintMenu}>
                    <Button
                      loading={loading}
                      size="small"
                      className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                    >
                      {t("Print")}
                    </Button>
                  </Dropdown>
                  <Button
                    onClick={() =>
                      this.markServed(
                        orderDetails.items
                          .filter((i) => i.status === "New")
                          .map((i) => i.id)
                      )
                    }
                    size="small"
                    className={config.direction == "ltr" ? "mr-1" : "ml-1"}
                  >
                    {t("Acknowledge All")}
                  </Button>
                </span>
              )}
            </h5>
            <table className="table table-hover">
              <thead>
                <tr>
                  {settings.show_invoice_images && (
                    <th scope="col" style={{ width: 70 }}></th>
                  )}
                  <th scope="col">{t("ProductInvoiceTable")}</th>
                  <th
                    scope="col"
                    style={{ display: order.hide_price && "none" }}
                  >
                    {t("PriceInvoiceTable")}
                  </th>
                  <th scope="col">{t("QuantityInvoiceTable")}</th>
                  <th scope="col">{t("TotalsInvoiceTable")}</th>
                  {isUnservedTable && <th scope="col">{t("Actions")}</th>}
                </tr>
              </thead>
              <tbody
                id={
                  isUnservedTable ? "invoice-products-new" : "invoice-products"
                }
              >
                {orderDetails.items
                  .filter((i) =>
                    isUnservedTable
                      ? i.status === "New"
                      : i.status === "Complete"
                  )
                  .map((item) => (
                    <span
                      style={{
                        display: "contents",
                      }}
                    >
                      <tr style={{ display: order.hide_price && "none" }}>
                        {settings.show_invoice_images && (
                          <td style={{ width: 70, textAlign: "center" }}>
                            {!!item.photo && (
                              <img
                                src={item.photo}
                                style={{
                                  width: 70,
                                  height: 70,
                                  [`margin${
                                    config.direction === "ltr"
                                      ? "Right"
                                      : "Left"
                                  }`]: 10,
                                }}
                              />
                            )}
                          </td>
                        )}
                        <td>
                          <strong>
                            {config.language == "arabic"
                              ? item.name_ar
                              : item.name}{" "}
                            {item.variant_keys?.length ? (
                              item.variant_keys.map((vk) => (
                                <div style={{ color: "grey", fontSize: 12 }}>
                                  {config.language === "arabic"
                                    ? vk.variant_key_ar
                                    : vk.variant_key}
                                  :{" "}
                                  {config.language === "arabic"
                                    ? vk.variant_value_ar
                                    : vk.variant_value}
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                            {settings.print_skus && item.sku
                              ? `(${item.sku})`
                              : ""}
                            {item.type_of_product == "bookable" ? (
                              <span style={{ direction: config.direction }}>
                                <br />
                                <small>
                                  {t("Booked for")}: {item.booking_date}{" "}
                                  {item.booking_is_all_day
                                    ? "- all day"
                                    : `(${translateStartEnd(
                                        `${moment(
                                          item.booking_start,
                                          "HH:mm:ss"
                                        ).format("h:mm A")} - ${moment(
                                          item.booking_end,
                                          "HH:mm:ss"
                                        ).format("h:mm A")}`
                                      )})`}
                                </small>
                              </span>
                            ) : null}
                            {item.type_of_product == "service" ? (
                              <span style={{ direction: config.direction }}>
                                <br />
                                <small>
                                  {t("Booked for")}: {item.booking_date}{" "}
                                  {`(${translateStartEnd(
                                    `${moment(
                                      item.booking_start,
                                      "HH:mm:ss"
                                    ).format("h:mm A")} - ${moment(
                                      item.booking_end,
                                      "HH:mm:ss"
                                    ).format("h:mm A")}`
                                  )})`}
                                </small>
                                <br />
                                <span>
                                  {t("With")}:{" "}
                                  {config.language == "arabic"
                                    ? item.resource?.ar_name ||
                                      item.resource?.name
                                    : item.resource?.name}
                                </span>
                              </span>
                            ) : null}
                            {item.special_remarks ? (
                              <>
                                <br />
                                <small>remarks: {item.special_remarks}</small>
                              </>
                            ) : null}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {item.price.toFixed(settings.currency_decimals)}{" "}
                            {t(currency)}
                          </strong>
                        </td>
                        <td>
                          <strong>x {item.quantity}</strong>
                        </td>
                        <td>
                          <strong>
                            {" "}
                            {(
                              (Number.isInteger(item.quantity)
                                ? parseInt(item.quantity)
                                : parseFloat(item.quantity)) *
                              parseFloat(item.price)
                            ).toFixed(settings.currency_decimals)}{" "}
                            {t(currency)}
                          </strong>
                        </td>
                        {isUnservedTable && (
                          <td>
                            <Button
                              onClick={() => this.markServed([item.id])}
                              size="small"
                              className={
                                config.direction == "ltr" ? "mr-1" : "ml-1"
                              }
                            >
                              {t("Acknowledge")}
                            </Button>
                          </td>
                        )}
                      </tr>
                      {item.options.map((option) => (
                        <tr style={{ display: order.hide_price && "none" }}>
                          {settings.show_invoice_images && (
                            <td
                              style={{
                                width: 200,
                                textAlign:
                                  config.language === "english"
                                    ? "right"
                                    : "left",
                              }}
                            >
                              {!!option.product_option.photo && (
                                <img
                                  src={option.product_option.photo}
                                  style={{
                                    width: 70,
                                    height: 70,
                                    [`margin${
                                      config.direction === "ltr"
                                        ? "Right"
                                        : "Left"
                                    }`]: 10,
                                  }}
                                />
                              )}
                            </td>
                          )}
                          <td
                            className={
                              config.direction == "ltr" ? "pl-5" : "pr-5"
                            }
                          >
                            {config.language == "arabic"
                              ? option.product_option.option_ar_name
                              : option.product_option.option_name}
                            :{" "}
                            {config.language == "arabic"
                              ? option.product_option.ar_value
                              : option.product_option.value}
                            {settings.print_skus && option?.sku
                              ? ` (${option?.sku})`
                              : ""}
                          </td>
                          <td>
                            {option.price.toFixed(settings.currency_decimals)}
                          </td>
                          <td>x {option.quantity}</td>
                          <td>
                            {(
                              (Number.isInteger(option.quantity)
                                ? parseInt(option.quantity)
                                : parseFloat(option.quantity)) *
                              parseFloat(option.price)
                            ).toFixed(settings.currency_decimals)}{" "}
                            {t(currency)}
                          </td>
                          {isUnservedTable && <td></td>}
                        </tr>
                      ))}
                      {item.extra_fields.map((extra_field) => (
                        <tr style={{ display: order.hide_price && "none" }}>
                          {settings.show_invoice_images && <td></td>}
                          <td
                            className={
                              config.direction == "ltr" ? "pl-5" : "pr-5"
                            }
                          >
                            {config.language == "arabic"
                              ? extra_field.ar_name
                              : extra_field.name}
                            :{" "}
                            {extra_field.type === "checkbox" ? (
                              t("Yes")
                            ) : extra_field.type === "file" ? (
                              extra_field.file_url ? (
                                <a
                                  href={extra_field?.file_url}
                                  target={"_blank"}
                                  download
                                >
                                  {t("Download now")}
                                </a>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {t("File was removed")}
                                </span>
                              )
                            ) : (
                              extra_field.value
                            )}
                          </td>
                          <td>
                            {extra_field.price.toFixed(
                              settings.currency_decimals
                            )}
                          </td>
                          <td>x {extra_field.quantity}</td>
                          <td>
                            {(
                              parseInt(extra_field.quantity) *
                              parseFloat(extra_field.price)
                            ).toFixed(settings.currency_decimals)}{" "}
                            {t(currency)}
                          </td>
                          {isUnservedTable && <td></td>}
                        </tr>
                      ))}
                    </span>
                  ))}
                {!isUnservedTable && (
                  <>
                    {orderDetails.discounts.length ? (
                      <>
                        <tr>
                          <td style={{ whiteSpace: "nowrap" }}>
                            <strong>{t("Discounts")}</strong>{" "}
                            {orderDetails.discounts.some(
                              (d) => d.discounted_items_ids?.length
                            ) && (
                              <span
                                style={{
                                  color: "blue",
                                  fontSize: "0.8rem",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() => {
                                  this.setState({
                                    showDiscountDetails:
                                      !this.state.showDiscountDetails,
                                  });
                                }}
                                className={
                                  config.direction === "ltr" ? "ml-2" : "mr-2"
                                }
                              >
                                {t(
                                  `${
                                    (this.state.showDiscountDetails &&
                                      "hide") ||
                                    "show"
                                  } details`
                                )}
                              </span>
                            )}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          {settings.show_invoice_images && <td></td>}
                        </tr>

                        {orderDetails.discounts.map((ord) => (
                          <>
                            <tr>
                              <td>{ord.description}</td>
                              <td></td>
                              <td></td>
                              {settings.show_invoice_images && <td></td>}
                              <td
                                style={{ display: order.hide_price && "none" }}
                              >
                                -{" "}
                                {order.vat_with_product_price
                                  ? (ord.value / (1 + order.vat_rate)).toFixed(
                                      settings.currency_decimals
                                    )
                                  : ord.value.toFixed(
                                      settings.currency_decimals
                                    )}{" "}
                                {t(currency)}
                              </td>
                            </tr>
                            {this.state.showDiscountDetails &&
                              ord?.discounted_items_ids?.map((product) => (
                                <tr>
                                  <td
                                    className={
                                      config.direction == "ltr"
                                        ? "pl-5"
                                        : "pr-5"
                                    }
                                  >
                                    {this.getDiscountedProductName(product)}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ))}
                          </>
                        ))}
                      </>
                    ) : null}
                    {order.gift_wrapping_price > 0 &&
                      order.gift_wrapping &&
                      order.is_gift && (
                        <tr style={{ display: order.hide_price && "none" }}>
                          <td>{t("Gift Wrapping")}</td>
                          {settings.show_invoice_images && <td></td>}
                          <td></td>
                          <td></td>
                          <td>
                            {order.gift_wrapping_price.toFixed(
                              settings.currency_decimals
                            )}{" "}
                            {t(currency)}
                          </td>
                        </tr>
                      )}
                    {order.delivery_rate > 0 && (
                      <tr style={{ display: order.hide_price && "none" }}>
                        <td>
                          {t("Delivery")} {checkIfAramexOrDhl()}
                        </td>
                        {settings.show_invoice_images && <td></td>}
                        <td></td>
                        <td></td>
                        <td>
                          {order.delivery_rate.toFixed(
                            settings.currency_decimals
                          )}{" "}
                          {t(currency)}
                        </td>
                      </tr>
                    )}
                    {order.vat_rate > 0 && (
                      <tr style={{ display: order.hide_price && "none" }}>
                        <td>
                          {t("VAT")}{" "}
                          {order.vat_with_product_price &&
                            `@ ${(order.vat_rate * 100).toFixed(
                              settings.currency_decimals
                            )} %`}
                        </td>
                        {settings.show_invoice_images && <td></td>}
                        {!order.vat_with_product_price && (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        )}
                        <td>
                          {order.vat_amount.toFixed(settings.currency_decimals)}{" "}
                          {t(currency)}
                        </td>
                        {order.vat_with_product_price && (
                          <>
                            <td>{t("Gross Amount")}</td>
                            <td>
                              {(
                                orderDetails.total /
                                (1 + order.vat_rate)
                              ).toFixed(settings.currency_decimals)}{" "}
                              {t(currency)}
                            </td>
                          </>
                        )}
                      </tr>
                    )}

                    {order.additional_fee > 0 && (
                      <tr style={{ display: order.hide_price && "none" }}>
                        <td>
                          {settings.enable_table_qr
                            ? t("Tip")
                            : t("Additional fee")}
                        </td>
                        {settings.show_invoice_images && <td></td>}
                        <td></td>
                        <td></td>
                        <td>
                          {order.additional_fee.toFixed(
                            settings.currency_decimals
                          )}{" "}
                          {t(currency)}
                        </td>
                      </tr>
                    )}

                    <tr style={{ display: order.hide_price && "none" }}>
                      <td>{t("TotalsInvoiceTable")}</td>
                      {settings.show_invoice_images && <td></td>}
                      <td></td>
                      <td></td>
                      <td style={{ display: order.hide_price && "none" }}>
                        {orderDetails.total.toFixed(settings.currency_decimals)}{" "}
                        {t(currency)}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <>
          <h6>
            <b>{t("Special remarks:")}</b>{" "}
            {!order.promise && this.props.permissions.can_edit_orders && (
              <>
                <Button
                  type="link"
                  className="px-2"
                  icon={
                    order.special_remarks ? <EditOutlined /> : <PlusOutlined />
                  }
                  onClick={() => this.setState({ editRemarks: true })}
                >
                  {order.special_remarks ? t("Edit") : t("Add")}
                </Button>
                {!!this.props.lastEditedBy.remarks_edited_by && (
                  <p
                    style={{
                      color: "#aaaaaa",
                      fontStyle: "italic",
                      fontSize: "0.75rem",
                    }}
                  >
                    {t("Last edited by")}{" "}
                    {this.props.lastEditedBy.remarks_edited_by}
                  </p>
                )}
              </>
            )}
          </h6>
          <p>{order.special_remarks}</p>
        </>
        {order.file_url && (
          <div>
            <h6>
              <b>{t("Special remarks file:")}</b>
            </h6>

            <a href={order.file_url} target={"_blank"} download>
              {t("Download now")}
            </a>
          </div>
        )}
        {order.is_gift ? (
          <div className={"mb-3"}>
            <h6>{t("Gift details")}:</h6>
            <p className="mb-0">
              {t("Message")}: {order.gift_message}
            </p>
            <p className="mb-0">
              {t("Gift sender")}: {order.first_name} {order.last_name}
            </p>
            <p className="mb-0">
              {t("Sender phone number")}:{" "}
              {order.phone.startsWith("+965")
                ? `${order.phone.slice(0, 4)}-${order.phone.slice(4)}`
                : order.phone}
            </p>
            {!!order.gift_link && (
              <p className="mb-0">
                {t("Gift link")}:{" "}
                {order.gift_link.startsWith("http") ? (
                  <a href={order.gift_link} target="_blank">
                    {order.gift_link}
                  </a>
                ) : (
                  order.gift_link
                )}
              </p>
            )}
            {order.send_gift_anonymously && (
              <p
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("Gift should be sent anonymously")}
              </p>
            )}
            {order.gift_wrapping && (
              <p
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("Please add wrapping to the gift")}
              </p>
            )}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
  domain: state.auth.settings.domain,
  settings: state.auth.settings,
  permissions: state.auth.permissions,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(InvoiceTable));
