const trans = {
  "Let's get started": "ابدأ باستخدام شاشة التحكم",
  "Take a quick tour of": "ابدأ بجولة ارشادية سريعة عن ميزات شاشة تحكم",
  "ordable/": "اوردبل/",
  "You can stop at any time by pressing":
    "يمكنك تخطي هذه الجوله في اي وقت باختيار",
  "To begin, press": "لتشغيل الجولة الارشادية، اضغط",
  Go: "استمرار",
  GO: "استمرار",
  Skip: "تخطى",
  Next: "التالي",
  Previous: "رجوع",
  "Home - A quick reference for an overview of your ordable/ shop":
    "الرئيسيية - مركز لحالة اعمالك المتعلقه بمتجرك مع اوردبل/",
  "POS - Accept orders from customers":
    "نقطة البيع لاستقبال طلبات العملاء من مصادر مختلفة",
  "Products - Manage your catalog including categories, modifiers, options, stock, and more":
    "ادارة المنتجات و جميع ما يتعلق بهم مثل الاقسام، الخيارات، المخزون و المزيد",
  "Orders - Accept and manage your orders":
    " قم باستقبال وادارة الطلبات من قسم الطلبات ",
  "Customers - See everything related to your customers including feedback, addresses, numbers, and orders":
    " تطلع على جميع التفاصيل المتعلقة بعملائك مثل عناوينهم، طلباتهم، و تعليقاتهم",
  "Bookings - View and manage bookings your customers have made":
    " قم بادارة حجوزات العملاء من قسم الحجوزات",
  "Reports - Learn about how your business is doing using reports on Sales, Customers, Segments, and more":
    "تطلع على تقارير مفصلة عن حالة المبيعات، تصنيف العملاء، والمزيد",
  "Marketing - Run campaigns and promotions to boost sales online":
    "استخدم ادوات التسويق المختلفة لزيادة المبيعات على منصتك",
  "Settings - Change different settings related to your store including themes, delivery, permissions, and more":
    "قم بادارة اعدادات مختلفة المتعلقة بمنصتك مثل الصلاحيات، ساعات العمل، شكل المنصة و المزيد",
  "Billing - You can manage your subscription details from the billing section":
    "قم بادارة تفاصيل اشتراك منصتك، دفع الفواتير",
  "Add-on's - Add and manage features in your shop to help you grow and manage your business online":
    "فعل او غير ميزات على منصتك لتسهيل ادارة اعمالك",
};

export default trans;
