import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { ReactComponent as Marker } from "./assets/map-pin.svg";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  render() {
    if (!this.props.lat || !this.props.lng) return <></>;
    return (
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCmyfpG7qTrGjmxnbEzY4MYD_NInKdh_IA" }}
          defaultCenter={{
            lat: parseFloat(this.props.lat),
            lng: parseFloat(this.props.lng),
          }}
          defaultZoom={11}
          distanceToMouse={() => {}}
        >
          <AnyReactComponent
            lat={parseFloat(this.props.lat)}
            lng={parseFloat(this.props.lng)}
            text={<Marker style={{ width: 20, height: 20 }} />}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
