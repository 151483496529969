import React, { Component } from "react";
import instance from "../../../store/actions/instance";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import { Radio, Button, message, Popconfirm } from "antd";

// Actions
import {
  bulkDeletePromotions,
  bulkTogglePromotions,
} from "../../../store/actions";
class PromotionBulkPopoverContent extends Component {
  state = {
    radioValue: "",

    saveLoading: false,
    deleteLoading: false,
  };
  async toggle() {
    this.setState({ saveLoading: true });
    const { t, selectedKeys } = this.props;
    const radioValue = this.state.radioValue;
    try {
      const res = await instance.post(`/promotions_bulk_toggle/`, {
        ids: selectedKeys,
        status: radioValue,
        isSmartPromo: true,
      });
      if (res.data.success) {
        this.props.bulkTogglePromotions(res.data.promotions, radioValue);
        message.success(t("Changed status successfully"));
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }

    this.setState({ saveLoading: false, radioValue: "" });
    this.props.setPopoverInvisible();
  }

  async delete() {
    this.setState({ deleteLoading: true });
    const { t, selectedKeys } = this.props;
    try {
      const res = await instance.post(`/promotions_bulk_delete/`, {
        ids: selectedKeys,
        isSmartPromo: true,
      });
      if (res.data.success) {
        this.props.bulkDeletePromotions(res.data.promotions);
        message.success(t("Deleted promotions successfully"));
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }

    this.setState({ deleteLoading: false, radioValue: "" });
    this.props.setPopoverInvisible();
  }

  render() {
    const { t, promotions, selectedKeys, config } = this.props;

    const activeCount = promotions.filter(
      (promotion) =>
        selectedKeys.includes(promotion.key) && promotion.is_active == true
    ).length;
    const inactiveCount = promotions.filter(
      (promotion) =>
        selectedKeys.includes(promotion.key) && promotion.is_active == false
    ).length;
    const { radioValue } = this.state;

    return (
      <div>
        <p className="mb-0">
          {activeCount} {t("active promotions")}
        </p>
        <p>
          {inactiveCount} {t("inactive promotions")}
        </p>
        <span className={config.direction == "ltr" ? "mr-2" : "ml-2"}>
          {t("Set status to")}:{" "}
        </span>
        <Radio.Group
          defaultValue=""
          value={this.state.radioValue}
          buttonStyle="solid"
          onChange={(e) => this.setState({ radioValue: e.target.value })}
        >
          <Radio.Button value={true}>{t("On")}</Radio.Button>
          <Radio.Button value={false}>{t("Off")}</Radio.Button>
        </Radio.Group>
        <div className="mt-3 text-right">
          <Button
            className="mx-1"
            onClick={() => this.props.setPopoverInvisible()}
          >
            {t("Close")}
          </Button>
          <Popconfirm
            title={t(
              "Are you sure you want to delete all selected promotions? You can't undo this action"
            )}
            onConfirm={() => this.delete()}
            okText={t("Yes")}
            cancelText={t("No")}
          >
            <Button className="mx-1" danger loading={this.state.deleteLoading}>
              {t("Delete")}
            </Button>
          </Popconfirm>
          <Button
            loading={this.state.saveLoading}
            disabled={radioValue === ""}
            className="mx-1"
            type="primary"
            onClick={() => this.toggle()}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    promotions: state.promotions.promotions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bulkDeletePromotions: (ids) => dispatch(bulkDeletePromotions(ids, true)),
    bulkTogglePromotions: (ids, status) =>
      dispatch(bulkTogglePromotions(ids, status, true)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PromotionBulkPopoverContent));
