import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [
        {
          name: "Current position",
          position: {
            lat: this.props.lat,
            lng: this.props.lng,
          },
        },
      ],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.lat !== prevProps.lat || this.props.lng !== prevProps.lng)
      this.setState({
        markers: [
          {
            name: "Current position",
            position: {
              lat: this.props.lat,
              lng: this.props.lng,
            },
          },
        ],
      });
  };
  onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    this.setState((prevState) => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers };
    });
    this.props.setLatLng(lat, lng);
  };

  getLocation = () => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;

        let newState = Object.assign({}, this.state);
        newState.markers[0].position.lat = coords.latitude;
        newState.markers[0].position.lng = coords.longitude;

        this.setState(newState);
      });
    }
  };

  componentDidMount() {
    this.getLocation();
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const location = {
      latitude: this.state.markers[0].position.lat,
      longitude: this.state.markers[0].position.lng,
    };
  };

  render() {
    return (
      <div style={{ width: "100%", height: "300px", position: "relative" }}>
        <Map
          google={this.props.google}
          style={{
            width: "100%",
            height: "300px",
          }}
          zoom={14}
          initialCenter={{
            lat: this.props.lat,
            lng: this.props.lng,
          }}
        >
          {this.state.markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.position}
              draggable={true}
              onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
              name={marker.name}
            />
          ))}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDj9YkL-5AgRtS4SWACFFlSQsRxrrLbPXk",
})(Maps);
