import {
  FETCH_WAITLISTS,
  FETCH_WAITLISTS_ENTRIES,
  DELETE_WAITLIST,
  CREATE_WAITLIST,
  TOGGLE_WAITLIST_STATUS,
  SET_WAITLISTS_FETCH_LOADING,
  CHANGE_ENTRY_ORDER,
  UPDATE_WAITLIST_NAMES,
  CREATE_WAITLIST_ENTRY,
  UPDATE_WAITLIST_ENTRY,
  BULK_TOGGLE_WAITLIST_ENTRIES,
  SOCKET_NEW_WAITLIST_ENTRY,
  SOCKET_UPDATE_WAITLIST_ENTRY,
  SOCKET_BULK_UPDATE_WAITLIST_ENTRY,
  SOCKET_CHANGE_ENTRY_ORDER,
  QUICK_CHANGE_WAITLIST_ENTRY,
} from "../actions/actionTypes";

import { FetchWaitListEntries } from "../actions";
import moment from "moment";

const initialState = { loading: false };

export default (state = initialState, { type, payload, asyncDispatch }) => {
  let newWaitLists = state.waitLists;
  let searched_entry;
  let searched_waitList;
  let currentEntry;
  let nextEntry;
  switch (type) {
    case FETCH_WAITLISTS:
      return { ...state, waitLists: payload, loading: false };
    case CREATE_WAITLIST:
      newWaitLists.unshift(payload);
      return { ...state, waitLists: newWaitLists };
    case DELETE_WAITLIST:
      newWaitLists[payload].is_deleted = true;
      return { ...state, waitLists: newWaitLists };
    case TOGGLE_WAITLIST_STATUS:
      newWaitLists[payload].is_active = !newWaitLists[payload].is_active;
      return { ...state, waitLists: newWaitLists };
    case SET_WAITLISTS_FETCH_LOADING:
      return { ...state, loading: true };

    case CHANGE_ENTRY_ORDER:
      const { entryIndex, waitListIndex, direction } = payload;
      currentEntry = newWaitLists[waitListIndex].entries[entryIndex];

      nextEntry = newWaitLists[waitListIndex].entries[entryIndex - direction];
      let tmp = { ...currentEntry };
      currentEntry.sort_order = nextEntry.sort_order;
      nextEntry.sort_order = tmp.sort_order;
      newWaitLists[waitListIndex].entries[entryIndex] = nextEntry;
      newWaitLists[waitListIndex].entries[
        entryIndex - direction
      ] = currentEntry;

      currentEntry.modified = payload.modified_date;
      nextEntry.modified = payload.modified_date;

      return { ...state, waitLists: newWaitLists };

    case UPDATE_WAITLIST_NAMES:
      newWaitLists[payload.index].name = payload.englishName;
      newWaitLists[payload.index].name_arabic = payload.arabicName;
      newWaitLists[payload.index].maximum_number_of_seats =
        payload.maximumNumberOfSeats;

      return { ...state, waitLists: newWaitLists };

    case CREATE_WAITLIST_ENTRY:
      newWaitLists[payload.waitListIndex].entries.push(payload.entry);
      return { ...state, waitLists: newWaitLists };
    case UPDATE_WAITLIST_ENTRY:
      const entry = newWaitLists[payload.waitListIndex].entries.find(
        (e) => e.key === payload.entry
      );
      if (entry) {
        entry.customer_size = payload.quantity;
        entry.customer_name = payload.customerName;
        entry.customer_pregnant = payload.pregnant;
        entry.customer_special_needs = payload.specialNeeds;
        entry.estimated_wait_minutes = payload.waitTime;
        entry.status = payload.status;
        entry.phone_code = payload.phoneCode;
        entry.phone_number = payload.phoneNumber;
        entry.customer_phone =
          "+" +
          entry.phone_code.toString() +
          "" +
          entry.phone_number.toString();

        entry.modified = moment().format("YYYY-MM-DDTHH:mm:ss.S").toString();
      }
      return { ...state, waitLists: newWaitLists };

    case BULK_TOGGLE_WAITLIST_ENTRIES:
      payload.ids.forEach((id) => {
        searched_entry = newWaitLists[payload.waitListIndex].entries.find(
          (e) => e.key === id
        );
        if (searched_entry) {
          searched_entry.status = payload.status;
          searched_entry.modified = payload.modified_date;
        }
      });
      return { ...state, waitLists: newWaitLists };
    case QUICK_CHANGE_WAITLIST_ENTRY:
      newWaitLists[payload.waitListIndex].entries[
        payload.waitListEntryIndex
      ].status = payload.status;
      return { ...state, waitLists: newWaitLists };

    case SOCKET_NEW_WAITLIST_ENTRY:
      if (newWaitLists) {
        searched_waitList = newWaitLists.find(
          (w) => w.key === payload.waitlist
        );

        if (searched_waitList) {
          searched_entry = searched_waitList.entries.find((e) => {
            return e.key === payload.key;
          });

          if (!searched_entry) {
            searched_waitList.entries.push(payload);
          }
        }
      }
      return { ...state, waitLists: newWaitLists };
    case SOCKET_UPDATE_WAITLIST_ENTRY:
      if (newWaitLists) {
        searched_waitList = newWaitLists.find(
          (w) => w.key === payload.waitlist
        );

        if (searched_waitList) {
          let searched_entry_index = searched_waitList.entries.findIndex(
            (e) => {
              return e.key === payload.key;
            }
          );

          if (searched_entry_index >= 0) {
            searched_waitList.entries[searched_entry_index] = payload;
          } else {
            asyncDispatch(FetchWaitListEntries(payload.waitlist));
          }
        }
      }
      return { ...state, waitLists: newWaitLists };

    case SOCKET_BULK_UPDATE_WAITLIST_ENTRY:
      searched_waitList = newWaitLists.find(
        (w) => w.key === payload.waitlist_id
      );
      if (searched_waitList) {
        for (let i = 0; i < payload.entries.length; i++) {
          searched_entry = searched_waitList.entries.find(
            (e) => e.key === payload.entries[i]
          );
          if (searched_entry) {
            searched_entry.status = payload.status;
            searched_entry.modified = payload.modified_date;
          } else {
            asyncDispatch(FetchWaitListEntries(payload.waitlist_id));
            break;
          }
        }
      }
      return { ...state, waitLists: newWaitLists };

    case SOCKET_CHANGE_ENTRY_ORDER:
      searched_waitList = newWaitLists.find(
        (w) => w.key === payload.waitlist_id
      );
      if (searched_waitList) {
        currentEntry = searched_waitList.entries[payload.current_index];
        nextEntry = searched_waitList.entries[payload.next_index];
        if (
          currentEntry.key === payload.current_id &&
          nextEntry.key === payload.next_id
        ) {
          let tmp = { ...currentEntry };
          currentEntry.sort_order = nextEntry.sort_order;
          nextEntry.sort_order = tmp.sort_order;
          searched_waitList.entries[payload.current_index] = nextEntry;
          searched_waitList.entries[payload.next_index] = currentEntry;
        } else {
          asyncDispatch(FetchWaitListEntries(payload.waitlist_id));
        }
      }

      currentEntry.modified = payload.modified_date;
      nextEntry.modified = payload.modified_date;

      return { ...state, waitLists: newWaitLists };

    case FETCH_WAITLISTS_ENTRIES:
      searched_waitList = newWaitLists.find(
        (w) => w.key === payload.waitlist_id
      );
      if (searched_waitList) {
        searched_waitList.entries = payload.entries;
      }
      return { ...state, waitLists: newWaitLists };

    default:
      return state;
  }
};
