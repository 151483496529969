const trans = {
  Identity: "هوية المتجر",
  "Logo and cover": "الشعار والخلفية",
  Logo: "الشعار",
  "Store logo. Show?": "شعار المتجر. إظهار؟",
  "Store cover": "خلفية المتجر",
  "Name and slogan": "الاسم والشعار",
  "Email Sender ID": "اسم البريد الالكتروني",
  "Current email: ***.": "الايميل الحالي ***.",
  "Enter a new email below.": "ادخل الايميل الجديد ادناه",
  Gallery: "صور اضافية",
  Cover: "الخلفية",
  "Call center": "رقم خدمة العملاء",
  "Speed (seconds)": "سرعة تغيير الخلفية",
  "Show logo": "تفعيل الشعار",
  "Arabic Logo": "الشعار العربي",
  "Show arabic logo": "تفعيل الشعار العربي",
  "Business Information": "معلومات المتجر",
  "View and update your business information":
    "التطلع و تعديل معلومات تخص متجرك",
  "Manage countries and areas you service, and their rates":
    "ادارة المناطق و اسعار التوصيل",
  "Users & Permissions": "المستخدمين و الصلاحيات",
  "Manage the way your printer invoices look":
    "حدد طريقة عرض الايصال تفاصيل الايصال",
  "Create and manage users and what they can do":
    "تحكم بتفاصيل صلاحيات و اضافة المستخدمين",
  "Manage your subscription and renewal or other bills":
    "ادارة تفاصيل اشتراكك و تسديد الفواتير",
  SMS: "رسائل نصيه SMS",
  "Manage the way your printed invoices look": "حدد شكل الايصال المطبور",
  "Display a small message at the top of your store":
    "شعار تنبيهي يظهر على الصفحة الرئيسيه لمتجرك",
  "Customize various texts on your store": "تغيير نصوص مختلفة على متجرك",
  Hours: "ساعات",
  "Alternative domain": "الدوماين البديل",
  "Efada Certificate": "ختم إفادة",
  "Upload Certificate": "رفع الملف",
  "Check Certificate": "التحقق من الملف",
  "An alternative link to your store": "رابط بديل لمتجرك",
  "Order Limits": "حد عدد الطلبات",
  "Limit the number of orders received on specific days":
    "حد عدد الطلبات التي تستقبلها في ايام محدده",
  "Manage your payment methods": "تعديل تفاصيل طرق الدفع",
  "Manage your delivery and working hours": "تغيير ساعات التوصيل و العمل",
  "Check your SMS balance and enable/disable messaging":
    "تفعيل الرسائل النصية و الرصيد المتوفر",
  "Change your store's theme color and products layout":
    "تغيير شكل و الوان المتجر و شكل عرض المنتجات",

  "Change preferences for your store and enable/disable features":
    "تغيير خصائص المتجر و تفعيل ميزات لمتجرك",
  "Change settings related to logistics and shipping": "تغيير تفاصيل التوصيل",
  "e.g. +96551005801": "+96551005801 مثال ",
  "e.g. 96551005801": "مثال: 96551005801",
  "e.g. https://www.instagram.com/its.ordable/":
    "مثال https://www.instagram.com/its.ordable",
  "e.g. https://www.itsordable.com/": "مثال https://www.itsordable.com",
  Cover: "خلفية المتجر",
  "Name in english": "الاسم بالانجليزي",
  "Name in arabic": "الاسم بالعربي",
  Slogan: "الشعار",
  "Slogan in english": "الشعار بالانجليزي",
  "Slogan in arabic": "الشعار بالعربي",
  "Contact information": "معلومات التواصل",
  "Phone number (format: +96551005801)": "رقم الهاتف (مع رمز الدولة و +)",
  "Call center (format: +96551005801)": "رقم خدمة العملاء (مع رمز الدولة و +)",
  "Contact number": "رقم التواصل",
  "Call center number": "رقم خدمة العملاء",
  "Whatsapp number": "رقم الواتساب",
  "Instagram link": "رابط الانستجرام",
  "Website link": "رابط الموقع",
  "Whatsapp Number (format: 96599922211)":
    "رقم الواتساب (مع رمز الدولة بدون +)",
  "Instagram (format: https://www.instagram.com/username/)": "رابط الانستجرام",
  "Website (format: http://www.yourdomain.com/)": "رابط الموقع الخاص",
  "Changed logo successfully": "تم تغيير الشعار بنجاح",
  "Changed cover successfully": "تم تغيير الخلفية بنجاح",
  "Superb link": "رابط Superb",
  "Whatsapp Number": "رقم الواتساب",
  "Reset to logo": "اعادة الى الشعار",
  "Recommended ratio": "الابعاد المقترحة",
  "The ratio is width to height": "الأبعاد هي نسبة العرض للطول",
  "Current email: ***. Enter the new one below":
    "الايميل الحالي ***. ادخل الايميل الجديد ادناه",
  "Sender of emails": "مصدر الايميلات",
  "Auto-Play Delay (0 to turn off)": "(فترة التشغيل التلقائي (0 للإيقافه",
  "Gallery Settings": "إعدادات المعرض",
  "SEO Name": "اسم SEO",
  "SEO Description": "وصف SEO",
  "Format your number with the international code prefix and a plus e.g. +96551005801":
    "ادخل رقم هاتف مع رمز الدوله. مثال: +96551005801",
  "Format your number with the international code prefix e.g. 96599922211":
    "ادخل رقم هاتف مع رمز الدوله. مثال: 96551005801",
  "Please use a full instagram link e.g. https://www.instagram.com/its.ordable/":
    "ادخل رابط الانستاغرام بالكامل. مثال: https://www.instagram.com/its.ordable/",
  "Link to an external site. Please input a full web link e.g. https://www.itsordable.com/":
    "ادخل رابط لموقع آخر او موقع رئيسي. مثال: https://www.itsordable.com/",
  "Recommended ratio: 1 x 1": "الحجم المقترح  1x1",
  "Recommended size: 600 width x 600 height":
    "الحجم المقترح به: 600 عرض × 600 ارتفاع",
  "Recommended size: 1080 width x 860 height":
    "الحجم المقترح به: 1080 عرض × 860 ارتفاع",
  "Recommended size: 860 width x 1080 height":
    "الحجم المقترح به: 860 عرض × 1080 ارتفاع",
  "Your product is currently (not delivered), want to make your product deliverable?":
    "المنتج حالياً (لا يتم توصيله) هل تريد جعل منتجك قابل للتوصيل؟",
  "Will this product be delivered?": "هل سيكون هذا المنتج قابل للتوصيل؟",

  "Max image size: 2.5 MB": "الحجم الأقصى لحجم الصورة: 2.5 ميجابايت",
  "Recommended ratio: 1.25 x 1": "الحجم المقترح  1.25x1",
  "Create information pages for your business": "صفحات خاصة لمعلومات عن منصتك ",
  "POS & Inventory": "نقاط البيع و ادارة المخزون",
  "Manage your POS & Inventory integrations": "إدارة نقاط البيع وتكامل المخزون",
  "Show Cover": "تفعيل خلفية المتجر",
  "Enable E-butler widget": "تفعيل زر E-butler",
  "E-butler Company ID": "ID الشركة  E-butler",
  "Show Category or Product Cover": "إظهار غلاف الفئة أو المنتج",
  "This feature enhances your mobile viewing experience by showing category or product icons to store cover image gallery. Now, customers can simply click on an image to swiftly navigate to the corresponding category page. This streamlined approach ensures a faster and more efficient browsing experience for users.":
    "تعمل هذه الميزة على تحسين تجربة المشاهدة على هاتفك المحمول من خلال عرض أيقونات الفئات أو المنتج لتخزين معرض صور الغلاف. يمكن للعملاء ببساطة النقر على الصورة للانتقال بسرعة إلى صفحة الفئة المقابلة. ويضمن هذا النهج المبسط تجربة تصفح أسرع وأكثر كفاءة للمستخدمين.",
};

export default trans;
