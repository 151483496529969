import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd Components
import {
  Modal,
  Select,
  Input,
  Row,
  Col,
  message,
  Carousel,
  Button,
  Checkbox,
} from "antd";
import { ReactComponent as Logo } from "./rotate.svg";
import { switchLanguage } from "../../store/actions/config";
import instance from "../../store/actions/instance";

// Actions

const { Option } = Select;

class PasswordResetModal extends Component {
  state = {
    password: "",
    confirmPassword: "",
    saveLoading: false,
  };
  passwordValid = () => {
    const { password, confirmPassword } = this.state;
    if (password === confirmPassword && password.trim().length >= 6)
      return true;
    return false;
  };

  resetPassword = async () => {
    const { t } = this.props;
    this.setState({ saveLoading: true });
    try {
      const res = await instance.post("/set_password/", {
        password: this.state.password,
      });
      if (res.data.success) {
        message.success(t("Changed password successfully"));
        this.props.onClose();
      } else {
        message.error(t("SomethingWentWrong"));
      }
    } catch (e) {
      console.error(e);
      message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
  };
  render() {
    const { config, t } = this.props;

    return (
      <Modal
        centered
        width={600}
        destroyOnClose={true}
        visible={true}
        onCancel={() => this.props.onClose()}
        okText={t("Save")}
        cancelText={t("Close")}
        bodyStyle={{
          minHeight: 400,
          paddingTop: 0,
          width: "auto",
          padding: 0,
          textAlign: config.direction == "ltr" ? "left" : "right",
          position: "relative",
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        footer={null}
        title={null}
        closable={false}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <Button
            style={{
              position: "absolute",
              [config.direction === "ltr" ? "right" : "left"]: 15,
              top: 15,
            }}
            type="link"
            onClick={() =>
              this.props.switchLanguage(
                config.language === "english" ? "arabic" : "english"
              )
            }
          >
            {config.language === "english" ? "عربي" : "English"}
          </Button>
        </div>
        <div className="my-3 text-center">
          <Logo style={{ width: 250, marginTop: 60 }} />

          <div
            className={config.direction === "ltr" ? "text-left" : "text-right"}
            style={{ margin: "auto", marginTop: 20, width: "75%" }}
          >
            <p style={{ textAlign: "center", fontSize: 20 }}>
              {t("Please enter your new password")}
            </p>
            <p
              style={{
                marginTop: 4,
                color: "gray",
                fontSize: 13,
                marginBottom: 8,
              }}
            >
              * {t("Min 6 characters")}
            </p>
            <p
              style={{
                marginTop: 4,
                color: "gray",
                fontSize: 13,
                marginBottom: 17,
              }}
            >
              * {t("You can change it later from Settings")}
            </p>
            <Input.Password
              placeholder={t("New password")}
              style={{ width: "100%", marginBottom: 17 }}
              onChange={(e) => this.setState({ password: e.target.value })}
              autoComplete="new-password"
            />

            <Input.Password
              placeholder={t("Confirm your password")}
              style={{ width: "100%", marginBottom: 17 }}
              onChange={(e) =>
                this.setState({ confirmPassword: e.target.value })
              }
              autoComplete="new-password"
            />
            <div className="text-center">
              <Button
                type="primary"
                size="large"
                disabled={!this.passwordValid()}
                loading={this.state.saveLoading}
                onClick={() => this.resetPassword()}
              >
                {t("Save")}
              </Button>
            </div>
          </div>

          <div
            className="text-center"
            style={{
              height: 50,
              // position: "absolute",
              width: "100%",
              bottom: 0,
              padding: "0px 10px",
            }}
          >
            <Button
              type="link"
              className="mx-2"
              onClick={() => {
                this.props.onClose();
              }}
              style={{ float: config.language === "arabic" ? "right" : "left" }}
            >
              {t("Skip")}
            </Button>{" "}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    switchLanguage: (language) => dispatch(switchLanguage(language)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(PasswordResetModal));
