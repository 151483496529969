import {
  SET_CURRENT_USER,
  SET_ERROR,
  SET_PERMISSIONS,
  PERMISSIONS_LOADING,
  TOGGLE_AUTOMATED_CATEGORY,
  SAVE_AUTOMATED_CATEGORY,
  QUICK_CHANGE_AUTOMATED_CATEGORY_IMAGE,
  DELETE_AUTOMATED_CATEGORY_IMAGE,
  BRANCH_BASED_ORDERING_SWITCH,
  SWITCH_STORE_BUSY,
  UPDATE_STORE_BUSY_DATE_TIME,
  CHANGE_DELIVERY_CONFIG,
  CHANGE_STORE_LOGO,
  CHANGE_STORE_COVER,
  CHANGE_STORE_FAVICON,
  SAVE_IDENTITY,
  DELETE_STORE_LOGO_COVER,
  SAVE_INVOICE_SETTINGS,
  SAVE_LAYOUT_THEME,
  CHANGE_FEATURES_FIELD,
  UPDATE_PERMISSIONS,
  CHANGE_STAFF_SETTINGS,
  SAVE_FRONTLINES_MERCHANT_CODE,
  SAVE_MARKETING_INTEGRATION_SETTINGS,
  ADD_TO_LOCATIONS_COUNT,
  TOGGLE_SMS_ACTIVE,
  SAVE_BENEFIT_TRANSFER_SETTINGS,
  CONTROL_STORE_GALLERY,
  HIDE_GO_INTRO_CAROUSEL,
  SAVE_CURRENCY_CONVERTER_SETTINGS,
  HIDE_PASSWORD_CHANGE_MODAL,
  SET_DISSMISS_DATE,
  UPDATE_STORE_BUSY_DATE_TIME_SOCKET,
  UPDATE_PERMITTED_BRANCH_BUSY_SOCKET,
} from "../actions/actionTypes";

const initialState = {
  user: null,
  permissions: null,
  errors: null,
  settings: null,
  loadingPermissions: false,
  username: null,
  is_staff: false,
  is_default_user: false,
  excluded_routes: [],
  can_view_features: false,
  associated_stores: [],
  did_verify_email: false,
  did_verify_phone: false,
  dismiss_until: null,
  did_dismiss_verify: false,
};

export default (state = initialState, { type, payload }) => {
  let newSettings = null;
  let newPermissions = null;
  let newAssociatedStores = null;
  let index = null;
  switch (type) {
    case SET_CURRENT_USER:
      return { ...state, user: payload, errors: null };
    case SET_ERROR:
      return { ...state, errors: payload.non_field_errors };
    case SET_PERMISSIONS:
      newPermissions = payload.permissions;

      if (payload.is_staff && newPermissions) {
        Object.keys(newPermissions).forEach((key) => {
          if (key.startsWith("can")) {
            newPermissions[key] = true;
          }
        });
        newPermissions["can_only_view_own_orders"] = false;
      }
      return {
        ...state,
        permissions: newPermissions,
        settings: payload.settings,
        loadingPermissions: false,
        username: payload.username,
        is_staff: payload.is_staff,
        is_default_user: payload.is_default_user,
        user_role_id: payload.user_role_id,
        excluded_routes: payload.excluded_routes || [],
        can_view_features: payload.can_view_features,
        associated_stores: payload.associated_stores,
        did_verify_email: payload.did_verify_email,
        did_verify_phone: payload.did_verify_phone,
        dismiss_until: payload.dismiss_until,
        did_dismiss_verify: payload.did_dismiss_verify,
        display_nps: payload.display_nps,
        last_session_id: payload.last_session_id,
      };
    case PERMISSIONS_LOADING:
      return { ...state, loadingPermissions: true };

    case SET_DISSMISS_DATE:
      return {
        ...state,
        dismiss_until: payload.newDate,
        did_dismiss_verify: payload.did_dismiss_verify,
      };

    case TOGGLE_AUTOMATED_CATEGORY:
      if (state.settings) {
        let tempSettings = state.settings;
        const type = payload;

        if (type == "mostSelling") {
          tempSettings.enable_most_selling = !tempSettings.enable_most_selling;
        } else if (type == "newest") {
          tempSettings.enable_newest_products =
            !tempSettings.enable_newest_products;
        } else if (type == "discount") {
          tempSettings.enable_discount_product_smart_category =
            !tempSettings.enable_discount_product_smart_category;
        }
        return { ...state, settings: tempSettings };
      }
    case "TOGGLE_AUTOMATED_CATEGORY_SHOW_COVER":
      if (state.settings) {
        let tempSettings = state.settings;
        const type = payload;

        if (type == "mostSelling") {
          tempSettings.most_selling_show_cover =
            !tempSettings.most_selling_show_cover;
        } else if (type == "newest") {
          tempSettings.newest_products_show_cover =
            !tempSettings.newest_products_show_cover;
        } else if (type == "discount") {
          tempSettings.discount_product_show_cover =
            !tempSettings.discount_product_show_cover;
        }
        return { ...state, settings: tempSettings };
      }

    case SAVE_AUTOMATED_CATEGORY:
      if (state.settings) {
        let tempSettings2 = state.settings;
        const type2 = payload.type;
        const order = payload.order;
        const limit = payload.limit;

        if (type2 == "mostSelling") {
          tempSettings2.most_selling_sort_order = order;
          tempSettings2.most_selling_limit = limit;
        } else if (type2 == "newest") {
          tempSettings2.newest_sort_order = order;
          tempSettings2.newest_limit = limit;
        } else if (type2 == "discount") {
          tempSettings2.discount_product_sort_order = order;
          tempSettings2.discount_product_limit = limit;
        }
        return { ...state, settings: tempSettings2 };
      }

    case QUICK_CHANGE_AUTOMATED_CATEGORY_IMAGE:
      let tempSettings3 = state.settings;

      if (payload.id === "mostSelling") {
        tempSettings3.photo_most_selling = payload.photo;
      } else if (payload.id === "newest") {
        tempSettings3.photo_newest_items = payload.photo;
      } else if (payload.id === "discount") {
        tempSettings3.photo_discount_products = payload.photo;
      }
      return { ...state, settings: tempSettings3 };
    case DELETE_AUTOMATED_CATEGORY_IMAGE:
      let tempSettings4 = state.settings;
      if (payload.id === "mostSelling") {
        tempSettings4.photo_most_selling = null;
      } else if (payload.id === "newest") {
        tempSettings4.photo_newest_items = null;
      } else if (payload.id === "discount") {
        tempSettings4.photo_discount_products = null;
      }
      return { ...state, settings: tempSettings4 };

    case BRANCH_BASED_ORDERING_SWITCH:
      newSettings = state.settings;
      newSettings.is_branch_based_ordering =
        !newSettings.is_branch_based_ordering;
      return { ...state, settings: newSettings };

    case SWITCH_STORE_BUSY:
      newSettings = state.settings;
      newSettings.busy_until_active = !newSettings.busy_until_active;

      return { ...state, settings: newSettings };

    case UPDATE_STORE_BUSY_DATE_TIME:
      newSettings = state.settings;
      newSettings.busy_until_date = payload.date;
      newSettings.busy_until_time = payload.time;
      newSettings.busy_until_active = true;

      return { ...state, settings: newSettings };

    case UPDATE_STORE_BUSY_DATE_TIME_SOCKET:
      newSettings = state.settings;
      newSettings.busy_until_date = payload.date;
      newSettings.busy_until_time = payload.time;
      newSettings.busy_until_active = payload.active;

      return { ...state, settings: newSettings };

    case CHANGE_DELIVERY_CONFIG:
      newSettings = state.settings;
      newSettings[payload.field] = payload.value;
      return { ...state, settings: newSettings };

    case CHANGE_STORE_LOGO:
      newSettings = state.settings;
      if (newSettings.favicon == newSettings.logo)
        newSettings.favicon = payload.url;
      newSettings.logo = payload.url;
      return { ...state, settings: newSettings };

    case "CHANGE_STORE_LOGO_ARABIC":
      newSettings = state.settings;
      newSettings.logo_ar = payload.url;
      return { ...state, settings: newSettings };

    case CHANGE_STORE_COVER:
      newSettings = state.settings;
      newSettings.cover = payload.url;
      return { ...state, settings: newSettings };

    case CHANGE_STORE_FAVICON:
      newSettings = state.settings;
      newSettings.favicon = payload.url;
      return { ...state, settings: newSettings };

    case SAVE_IDENTITY:
      newSettings = state.settings;
      newSettings = { ...newSettings, ...payload };
      return { ...state, settings: newSettings };

    case DELETE_STORE_LOGO_COVER:
      newSettings = state.settings;
      if (payload == "logo") {
        if (newSettings.favicon == newSettings.logo) newSettings.favicon = null;
        newSettings.logo = null;
      } else if (payload == "cover") {
        newSettings.cover = null;
      } else if (payload == "favicon") {
        newSettings.favicon = newSettings.logo;
      } else if (payload == "logo_ar") {
        newSettings.logo_ar = null;
      }
      return { ...state, settings: newSettings };

    case SAVE_INVOICE_SETTINGS:
      newSettings = state.settings;
      newSettings = { ...newSettings, ...payload };
      return { ...state, settings: newSettings };

    case SAVE_LAYOUT_THEME:
      newSettings = state.settings;
      newSettings = { ...newSettings, ...payload };
      return { ...state, settings: newSettings };

    case CHANGE_FEATURES_FIELD:
      newSettings = state.settings;
      newSettings[payload.field] = payload.value;
      if (
        payload.field === "enable_force_gift_name_number" &&
        payload.value === false
      ) {
        newSettings["enable_unknown_gift_recipient_location"] = false;
      }
      return { ...state, settings: newSettings };

    case UPDATE_PERMISSIONS:
      newPermissions = state.permissions;
      newPermissions = payload.permissions;
      if (payload.is_staff && newPermissions) {
        Object.keys(newPermissions).forEach((key) => {
          if (key.startsWith("can")) {
            newPermissions[key] = true;
          }
        });
        newPermissions["can_only_view_own_orders"] = false;
      }
      return { ...state, permissions: newPermissions };

    case CHANGE_STAFF_SETTINGS:
      newSettings = state.settings;
      if (payload.field == "enable_sms") {
        if (newSettings.sms_balance == null) {
          newSettings.sms_balance = 0;
        }
        if (newSettings.sms_sender_id == null) {
          newSettings.sms_sender_id = "";
        }
      }
      newSettings[payload.field] = payload.value;
      return { ...state, settings: newSettings };

    case SAVE_FRONTLINES_MERCHANT_CODE:
      newSettings = state.settings;
      newSettings.frontlines_merchant_code = payload;
      return { ...state, settings: newSettings };

    case SAVE_MARKETING_INTEGRATION_SETTINGS:
      newSettings = state.settings;
      Object.keys(payload).forEach((key) => {
        newSettings[key] = payload[key];
      });
      return { ...state, settings: newSettings };

    case ADD_TO_LOCATIONS_COUNT:
      newSettings = state.settings;
      newSettings.locations_count += payload;
      return { ...state, settings: newSettings };

    case TOGGLE_SMS_ACTIVE:
      newSettings = state.settings;
      if (payload.field === "enable_sms")
        newSettings.sms_active = payload.value;
      if (payload.field === "send_on_receive")
        newSettings.sms_send_on_receive = payload.value;
      if (payload.field === "send_on_place")
        newSettings.sms_send_on_place = payload.value;
      if (payload.field === "send_on_pos")
        newSettings.sms_send_on_pos = payload.value;
      if (payload.field === "send_on_change_status")
        newSettings.sms_send_on_change_status = payload.value;
      if (payload.field === "enable_low_balance_notification")
        newSettings.enable_sms_low_balance_notification = payload.value;
      if (payload.field === "notify_threshold")
        newSettings.sms_notify_threshold = payload.value;
      if (payload.field === "notify_on_email")
        newSettings.sms_notify_on_email = payload.value;
      return { ...state, settings: newSettings };

    case SAVE_BENEFIT_TRANSFER_SETTINGS:
      newSettings = state.settings;
      newSettings = { ...newSettings, ...payload };
      return { ...state, settings: newSettings };
    case CONTROL_STORE_GALLERY:
      newSettings = state.settings;
      let gallery = newSettings.gallery;
      if (payload.type === "add") {
        gallery.push(payload.file);
      } else if (payload.type === "delete") {
        gallery = gallery.filter((photo) => photo.id !== payload.id);
      }
      return { ...state, settings: { ...newSettings, gallery: gallery } };

    case "CONTROL_STORE_GALLERY_CATEGORY_PRODUCT": {
      newSettings = state.settings;
      let gallery = newSettings.gallery;
      if (payload.type === "add") {
        gallery.push(payload.file);
      } else if (payload.type === "delete") {
        gallery = gallery.filter((photo) => photo.id !== payload.storeCoverID);
      }
      return { ...state, settings: { ...newSettings, gallery: gallery } };
    }
    case HIDE_GO_INTRO_CAROUSEL:
      return {
        ...state,
        settings: { ...state.settings, did_view_intro: true },
      };

    case HIDE_PASSWORD_CHANGE_MODAL:
      return {
        ...state,
        settings: { ...state.settings, did_set_password: true },
      };
    case "UNINSTALL_ADDON":
      newSettings = state.settings;
      newSettings[payload] = false;
      return { ...state, settings: newSettings };

    case "INSTALL_ADDON":
      newSettings = state.settings;
      if (payload.field === "extra_branches") {
        let quantityToAdd = payload.extras.quantity;
        if (payload.extras.reduce) quantityToAdd = -quantityToAdd;
        newSettings.extra_branches += quantityToAdd;
        newSettings.limit_branches += quantityToAdd;
      } else if (payload.field === "extra_b2b_branches") {
        let quantityToAdd = payload.extras.quantity;
        if (payload.extras.reduce) quantityToAdd = -quantityToAdd;
        newSettings.extra_b2b_branches += quantityToAdd;
        newSettings.limit_b2b_branches += quantityToAdd;
      } else if (payload.field === "extra_tables") {
        let quantityToAdd = payload.extras.quantity;
        if (payload.extras.reduce) quantityToAdd = -quantityToAdd;
        newSettings.extra_tables += quantityToAdd;
        newSettings.limit_tables += quantityToAdd;
      } else if (payload.field === "extra_users") {
        let quantityToAdd = payload.extras.quantity;
        if (payload.extras.reduce) quantityToAdd = -quantityToAdd;
        newSettings.extra_users += quantityToAdd;
        newSettings.limit_users += quantityToAdd;
      } else newSettings[payload.field] = true;
      return { ...state, settings: newSettings };

    case SAVE_CURRENCY_CONVERTER_SETTINGS:
      return { ...state, settings: { ...state.settings, ...payload } };

    case "DISMISS_GUIDE":
      newSettings = state.settings;
      newSettings.did_dismiss_guide = true;
      return { ...state, settings: newSettings };

    case "SET_DID_CONTACT_HESABE":
      newSettings = state.settings;
      newSettings.did_contact_hesabe = true;
      newSettings.did_contact_hesabe_at = payload;
      return { ...state, settings: newSettings };
    case "SET_SIGNED_UP_WITH_TAP":
      newSettings = state.settings;
      newSettings.did_sign_up_with_tap = payload;
      newSettings.enable_tap_v2 = payload;
      newSettings.enable_tap_v2_charges = payload;
      return { ...state, settings: newSettings };

    case "CANCEL_SUBSCRIPTION":
      newSettings = state.settings;
      if (payload === "churning") newSettings.is_churning = true;
      else if (payload === "churned") newSettings.store_status = "churned";
      return { ...state, settings: newSettings };

    case "RESUME_SUBSCRIPTION":
      newSettings = state.settings;
      newSettings.is_churning = false;
      newSettings.store_status = "live";
      newSettings.tap_payment_retry_count = 0;
      newSettings.last_tap_payment_retry = null;
      return { ...state, settings: newSettings };

    case UPDATE_PERMITTED_BRANCH_BUSY_SOCKET:
      const allPermittedBranches = state.permissions.all_permitted_branches;
      payload.branches.forEach((b) => {
        index = allPermittedBranches.findIndex((branch) => branch.id == b.id);
        if (index >= 0) {
          allPermittedBranches[index] = {
            ...allPermittedBranches[index],
            ...b,
          };
        }
      });

      return {
        ...state,
        permissions: {
          ...state.permissions,
          all_permitted_branches: allPermittedBranches,
        },
      };

    case "UPDATE_ASSOCIATED_STORE":
      newAssociatedStores = state.associated_stores;
      index = state.associated_stores.findIndex(
        (s) => s.store__sites__domain === payload.domain
      );
      if (index >= 0) {
        newAssociatedStores[index].has_pending_orders = {
          count: payload.count,
        };
      }
      return {
        ...state,
        associated_stores: newAssociatedStores,
      };

    case "NEW_ORDER_FOR_ASSOCIATED_STORE":
      newAssociatedStores = state.associated_stores;
      index = state.associated_stores.findIndex(
        (s) => s.store__sites__domain === payload
      );
      if (index >= 0) {
        newAssociatedStores[index].has_pending_orders = {
          count: newAssociatedStores[index].has_pending_orders?.count + 1 || 1,
        };
      }
      return {
        ...state,
        associated_stores: newAssociatedStores,
      };

    case "REMOVE_ORDER_FOR_ASSOCIATED_STORE":
      newAssociatedStores = state.associated_stores;
      index = state.associated_stores.findIndex(
        (s) => s.store__sites__domain === payload
      );
      if (index >= 0) {
        newAssociatedStores[index].has_pending_orders = {
          count: Math.max(
            newAssociatedStores[index].has_pending_orders?.count - 1,
            0
          ),
        };
      }
      return {
        ...state,
        associated_stores: newAssociatedStores,
      };

    default:
      return state;
  }
};
