import {
  FETCH_NEWS_POSTS,
  LIKE_POST,
  DISLIKE_POST,
  WRITE_COMMENT,
  DELETE_COMMENT,
  DELETE_POST,
  TOGGLE_POST,
} from "../actions/actionTypes";

const initialState = {
  posts: [],
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  let newPosts;
  let index;
  switch (type) {
    case FETCH_NEWS_POSTS:
      return { ...state, posts: payload, loading: false };

    case LIKE_POST:
      newPosts = state.posts;
      index = state.posts.findIndex((post) => post.id === payload);
      if (index >= 0) {
        newPosts[index].likes_count = newPosts[index].is_liked ? 0 : 1;
        newPosts[index].dislikes_count = 0;
        newPosts[index].is_liked = !newPosts[index].is_liked;
        newPosts[index].is_disliked = false;
      }
      return { ...state, posts: newPosts };

    case DISLIKE_POST:
      newPosts = state.posts;
      index = state.posts.findIndex((post) => post.id === payload);
      if (index >= 0) {
        newPosts[index].dislikes_count = newPosts[index].is_disliked ? 0 : 1;
        newPosts[index].likes_count = 0;
        newPosts[index].is_disliked = !newPosts[index].is_disliked;
        newPosts[index].is_liked = false;
      }
      return { ...state, posts: newPosts };

    case WRITE_COMMENT:
      newPosts = state.posts;
      index = state.posts.findIndex((post) => post.id === payload.post_id);
      newPosts[index].comments = [...newPosts[index].comments, payload];
      return { ...state, posts: newPosts };

    case DELETE_COMMENT:
      newPosts = state.posts;
      index = state.posts.findIndex((post) => post.id === payload.post_id);
      newPosts[index].comments = newPosts[index].comments.filter(
        (comment) => comment.id !== payload.id
      );
      return { ...state, posts: newPosts };

    case DELETE_POST:
      newPosts = state.posts;
      newPosts = newPosts.filter((post) => post.id !== payload);
      return { ...state, posts: newPosts };

    case TOGGLE_POST:
      newPosts = state.posts;
      index = state.posts.findIndex((post) => post.id === payload);
      newPosts[index].is_active = !newPosts[index].is_active;
      return { ...state, posts: newPosts };
    default:
      return state;
  }
};
