import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Button, Drawer, message } from "antd";
import { QrReader } from "react-qr-reader";
import QRScanOrderModal from "./QRScanOrderModal";
import instance from "../../store/actions/instance";

class QRScanDrawer extends Component {
  state = {
    order: null,
  };

  getOrderDetails = async (tid) => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      const res = await instance.post("/get_orders/", { tracking_id: tid });
      const tempOrders = [
        ...res.data.orders,
        ...res.data.promises,
        ...res.data.new_orders,
      ];
      if (!tempOrders.length) message.error(t("Booking can't be found"));
      this.setState({ order: tempOrders[0] });
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  };
  handleScan = (result, error) => {
    if (this.state.order || this.state.loading || !result) return;
    try {
      if (!!result && result.text.includes("/track/order/")) {
        const trackingID = result.text
          .split("/track/order/")[1]
          .replace("/", "");
        if (trackingID) {
          this.getOrderDetails(trackingID);
        }
      }
    } catch (e) {
      console.error(e, error);
    }
  };
  render() {
    const { settings, config, t } = this.props;
    return (
      <Drawer
        title={t("Scan QR Code")}
        placement={"bottom"}
        closable={true}
        onClose={() => this.props.onClose()}
        open={true}
        key={"bottom"}
        height={"100%"}
      >
        {!!this.state.order && (
          <QRScanOrderModal
            onClose={() => this.setState({ order: null })}
            order={this.state.order}
          />
        )}
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          onResult={this.handleScan}
          style={{ width: "100%", height: "100%" }}
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    permissions: state.auth.permissions,
    config: state.config,

    settings: state.auth.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(QRScanDrawer));
