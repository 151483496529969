import {
  FETCH_ORDERS,
  SET_ORDERS_LOADING_TRUE,
  UPDATE_ORDER_STATUS_PAYMENT,
  UPDATE_ORDERS_AFTER_BULK,
  UPDATE_ORDER_NOTES,
  DISPATCH_ORDER,
  TOGGLE_SOUND,
  FETCH_PAST_ORDERS,
  EDIT_EXPECTED_DELIVERY,
  SET_ORDERS_LOADING_FALSE,
  CLEAR_PAST_ORDERS,
  STORE_SEARCHED_ORDERS,
  CHANGE_ORDERS_LAYOUT,
  EDIT_PAYMENT_TYPE,
  SOCKET_MASHKOR_UPDATE_ORDER_STATUS,
  SET_DHL_INFO,
  SET_DHL_ORDER_DISPATCHED,
  RESET_PRODUCT_LIST,
  UNDISPATCH_ORDER,
  EDIT_ADDRESS,
  FETCH_TOPUPS,
  EDIT_BRANCH,
  EDIT_TABLE_NUMBER,
  TRANSFER_ORDERS,
  CANCEL_TOPUP,
  UPDATE_CUSTOMER_LAT_LNG,
  EDIT_SPECIAL_REMARKS,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";
import { fetchBusinessOrders } from "./businessOrders";
import Axios from "axios";
import getAudioFile from "../../sounds";

export const fetchOrders = (t, tracking_id = null) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ORDERS_LOADING_TRUE });
    try {
      let res = await instance.post("/get_orders/", {
        tracking_id: tracking_id,
      });

      const orders = res.data;
      const settings = getState().auth.settings;
      if (
        !!tracking_id &&
        settings.enable_table_qr &&
        settings.new_order_sound !== settings.new_dine_in_items_sound &&
        orders.new_orders?.length &&
        orders.new_orders[0].status === "New POS" &&
        orders.new_orders[0].promise__type?.startsWith("dine-in")
      ) {
        // play sound
        const currentAudio = getState().orders.audio;
        const bellHasNewOrders = getState().orders.bellHasNewOrders;
        const soundEnabled = getState().orders.soundEnabled;
        if (currentAudio && soundEnabled && bellHasNewOrders) {
          currentAudio.pause();
          dispatch({ type: "PAUSE_AUDIO" });
        }
        const filename = settings.new_dine_in_items_sound;
        const f = getAudioFile(filename);
        const audio = new Audio(f);
        audio.play();
        audio.addEventListener("ended", () => {
          if (currentAudio && soundEnabled && bellHasNewOrders) {
            currentAudio.play();
            dispatch({ type: "PAUSE_AUDIO" });
          }
        });
      }
      dispatch({
        type: FETCH_ORDERS,
        payload: { ...orders, specific: !!tracking_id },
      });
      if (orders.business_orders) {
        dispatch(fetchBusinessOrders(t));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view orders");
    }
  };
};

export const fetchPickupRequests = (t) => {
  return async (dispatch) => {
    try {
      const res = await instance.get("/get_pickup_arrival_requests/");
      if (res.data.success)
        dispatch({
          type: "FETCH_PICKUP_ARRIVAL_REQUESTS",
          payload: res.data.data,
        });
      else message.error(t("SomethingWentWrong"));
    } catch (e) {
      console.error(e);
      message.error(t("SomethingWentWrong"));
    }
  };
};
let source = null;
let isRunningPastOrderRequest = false;
export const fetchPastOrders = (start,end) => {
  return async (dispatch) => {
    dispatch({ type: SET_ORDERS_LOADING_TRUE });
    dispatch({ type: CLEAR_PAST_ORDERS });
    try {
      let res;
      if (start && end) {
        let page = 1;
        let numberOfPages = 0; //Any initial value
        do {
          isRunningPastOrderRequest = true;
          source = Axios.CancelToken.source();
          res = await instance.post(
            "/get_past_orders_dash/",
            {
              start: start,
              end:end,
              page: page,
            },
            { cancelToken: source.token }
          );
          const orders = res.data;
          numberOfPages = orders.pages_count;
          page += 1;
          dispatch({ type: FETCH_PAST_ORDERS, payload: orders });
        } while (page <= numberOfPages);
      }
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view orders");
    }
    isRunningPastOrderRequest = false;

    dispatch({ type: SET_ORDERS_LOADING_FALSE });
  };
};

export const cancelPastOrders = () => {
  if (isRunningPastOrderRequest && source) {
    source.cancel();
  }
};

export const updateOrderStatusAndPayment = (
  type,
  id,
  status,
  paymentStatus,
  cancelReason,
  refundCustomer,
  returnInventory,
  refundWallet = false
) => {
  return async (dispatch) => {
    try {
      if (refundCustomer) dispatch({ type: "REFUND_LOADING" });
      const res = await instance.post("/update_order_status/", {
        type: type,
        id: id,
        status: status,
        paymentStatus: paymentStatus,
        cancelReason: cancelReason || "",
        refundCustomer: refundCustomer || false,
        returnInventory: returnInventory || false,
        refundWallet: refundWallet,
      });
      const newOrder = res.data.order;
      if (res.data.success) {
        dispatch({ type: UPDATE_ORDER_STATUS_PAYMENT, payload: newOrder });
        if (res.data.message) message.info(res.data.message);
        if (refundCustomer) dispatch({ type: "REFUND_LOADING" });
        if (res.data.resetProductList) dispatch({ type: RESET_PRODUCT_LIST });
      } else {
        if (refundCustomer) dispatch({ type: "REFUND_LOADING" });
        message.error(
          res.data.message ||
            "Something wrong happened. Please contact us for help"
        );
      }
      return newOrder;
    } catch (err) {
      if (err.response && err.response.status == 403) {
        message.error("You do not have permission to perform this action");
        setTimeout(() => window.location.reload(), 1000);
      }
    }
  };
};

export const updateOrdersLocallyAfterBulkActions = (
  type,
  keys,
  status,
  paymentStatus,
  newOrders
) => {
  return {
    type: UPDATE_ORDERS_AFTER_BULK,
    payload: {
      otype: type,
      keys: keys,
      status: status,
      paymentStatus: paymentStatus,
      newFetchedOrders: newOrders,
    },
  };
};

export const updateOrderNotesLocally = (orderID, notes, attachment) => {
  return {
    type: UPDATE_ORDER_NOTES,
    payload: { orderID: orderID, notes: notes, attachment: attachment },
  };
};

export const updateCustomerLatLngLocally = (orderID, lat, lng) => {
  return {
    type: UPDATE_CUSTOMER_LAT_LNG,
    payload: { orderID: orderID, lat, lng },
  };
};

export const dispatchOrderLocally = (
  id,
  dispatcher,
  reference,
  trackingLink,
  estimatedDuration,
  QR,
  walletCharge,
  driver
) => {
  return {
    type: DISPATCH_ORDER,
    payload: {
      id: id,
      dispatcher: dispatcher,
      reference: reference,
      trackingLink: trackingLink,
      estimatedDuration: estimatedDuration,
      QR: QR,
      driver_id: driver,
      walletCharge: walletCharge,
    },
  };
};

export const toggleSound = () => {
  return {
    type: TOGGLE_SOUND,
  };
};

export const editExpectedDelivery = (id, start, end, date, user) => {
  return {
    type: EDIT_EXPECTED_DELIVERY,
    payload: { id, start, end, date, user },
  };
};

export const editPaymentType = (id, paymentType, paymentCType, user) => {
  return {
    type: EDIT_PAYMENT_TYPE,
    payload: { id, paymentType, paymentCType, user },
  };
};

export const editSpecialRemarks = (id, specialRemarks, user) => {
  return {
    type: EDIT_SPECIAL_REMARKS,
    payload: { id, specialRemarks, user },
  };
};

export const storeSearchedOrders = (orders) => {
  return {
    type: STORE_SEARCHED_ORDERS,
    payload: { orders },
  };
};

export const changeOrdersLayout = (layout) => {
  return {
    type: CHANGE_ORDERS_LAYOUT,
    payload: layout,
  };
};

export const socketUpdateOrderStatusMashkor = (data) => {
  return {
    type: SOCKET_MASHKOR_UPDATE_ORDER_STATUS,
    payload: data,
  };
};

export const setDHLInfo = (data) => {
  return {
    type: SET_DHL_INFO,
    payload: data,
  };
};

export const setDHLOrderDispatched = (oid) => {
  return {
    type: SET_DHL_ORDER_DISPATCHED,
    payload: oid,
  };
};

export const resetProductList = () => {
  return {
    type: RESET_PRODUCT_LIST,
  };
};

export const undispatchOrder = (id) => {
  return {
    type: UNDISPATCH_ORDER,
    payload: id,
  };
};

export const editAddress = (payload) => {
  return {
    type: EDIT_ADDRESS,
    payload: payload,
  };
};

export const fetchTopups = (payload) => {
  return {
    type: FETCH_TOPUPS,
    payload: payload,
  };
};
export const transferOrders = (orders, hasPermission) => {
  return {
    type: TRANSFER_ORDERS,
    payload: { orders, hasPermission },
  };
};

export const cancelTopup = (payload) => {
  return {
    type: CANCEL_TOPUP,
    payload: payload,
  };
};
export const editTable = (id, tableNumber, user) => {
  return {
    type: EDIT_TABLE_NUMBER,
    payload: { id, tableNumber, user },
  };
};
