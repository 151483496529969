import {
  FETCH_DISTRIBUTORS_LOADING,
  FETCH_DISTRIBUTORS,
  UPDATE_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
  CREATE_DISTRIBUTOR,
  FETCH_DISTRIBUTOR_PRODUCTS,
  TOGGLE_DISTRIBUTOR_PRODUCT,
  SAVE_DISTRIBUTOR_PRODUCT_FIELDS,
  DELETE_DISTRIBUTOR_PRODUCT,
  BULK_DELETE_DISTRIBUTOR_PRODUCTS,
  BULK_CONTROL_DISTRIBUTOR_PRODUCTS,
  FETCH_DISTRIBUTOR_BRANCHES,
  CREATE_DISTRIBUTOR_BRANCH,
  UPDATE_DISTRIBUTOR_BRANCH,
  DELETE_DISTRIBUTOR_BRANCH,
  BULK_DELETE_DISTRIBUTOR_BRANCHES,
  BULK_SET_DIST_BRANCH_DELIVERY_RATE,
  FETCH_MANIFEST,
  FETCH_MANIFEST_LOADING,
  FETCH_BUSINESS_SALES_OVER_TIME,
  FETCH_BUSINESS_SALES_OVER_TIME_LOADING,
  FETCH_DISTRIBUTOR_DISCOUNTS,
  TOGGLE_DISTRIBUTOR_DISCOUNTS,
  CREATE_DISTRIBUTOR_DISCOUNT, DELETE_DISTRIBUTOR_DISCOUNT
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  distributors: [],
  manifest:[],
  phones: [],
  reports: {loading: false},
};

export default (state = initialState, { type, payload }) => {
  let newDistributors;
  let index;
  let productIndex;
  let branchIndex;
  switch (type) {
    case FETCH_DISTRIBUTORS_LOADING:
      return { ...state, loading: true };

    case FETCH_DISTRIBUTORS:
      return {
        ...state,
        distributors: payload.distributors,
        phones: payload.phones,
        loading: false,
      };
    
      case FETCH_MANIFEST_LOADING:
        return { ...state, loading: true };
  
      case FETCH_MANIFEST:
        return {
          ...state,
          manifest: payload.data,
          loading: false,
        };

        case FETCH_BUSINESS_SALES_OVER_TIME_LOADING:
          return { ...state, reports: {...state.reports, loading: true} };
    
        case FETCH_BUSINESS_SALES_OVER_TIME:
          return {
            ...state,
            reports: { loading: false, ...payload.data},
            loading: false,
          };




    case UPDATE_DISTRIBUTOR:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.id);
      newDistributors[index] = payload;
      return { ...state, distributors: newDistributors };

    case DELETE_DISTRIBUTOR:
      newDistributors = state.distributors;
      newDistributors = newDistributors.filter((d) => d.id != payload);
      return { ...state, distributors: newDistributors };

    case CREATE_DISTRIBUTOR:
      newDistributors = [...state.distributors, payload.distributor];
      newDistributors = newDistributors.sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      return {
        ...state,
        distributors: newDistributors,
        phones: payload.phones,
      };

    case FETCH_DISTRIBUTOR_PRODUCTS:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.id);
      newDistributors[index].products = payload.products;
      return {
        ...state,
        distributors: newDistributors,
      };

    case TOGGLE_DISTRIBUTOR_PRODUCT:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      productIndex = newDistributors[index].products.findIndex(
        (prod) => prod.id == payload.prodID
      );
      newDistributors[index].products[
        productIndex
      ].is_active = !newDistributors[index].products[productIndex].is_active;
      return {
        ...state,
        distributors: newDistributors,
      };

    case SAVE_DISTRIBUTOR_PRODUCT_FIELDS:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      productIndex = newDistributors[index].products.findIndex(
        (prod) => prod.id == payload.prodID
      );
      newDistributors[index].products[productIndex][payload.field] =
        payload.value;
      return {
        ...state,
        distributors: newDistributors,
      };

    case DELETE_DISTRIBUTOR_PRODUCT:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      newDistributors[index].products = newDistributors[index].products.filter(
        (prod) => prod.id != payload.prodID
      );

      return {
        ...state,
        distributors: newDistributors,
      };

    case BULK_DELETE_DISTRIBUTOR_PRODUCTS:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      newDistributors[index].products = newDistributors[index].products.filter(
        (prod) => !payload.ids.includes(prod.id)
      );

      return {
        ...state,
        distributors: newDistributors,
      };

    case BULK_CONTROL_DISTRIBUTOR_PRODUCTS:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      payload.ids.forEach((id) => {
        productIndex = newDistributors[index].products.findIndex(
          (prod) => prod.id == id
        );
        Object.keys(payload.data).forEach((key) => {
          if (payload.data[key] !== "" && payload.data[key] !== null)
            newDistributors[index].products[productIndex][key] =
              payload.data[key];
        });
      });
      return {
        ...state,
        distributors: newDistributors,
      };

    case FETCH_DISTRIBUTOR_BRANCHES:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      newDistributors[index].branches = payload.branches;
      return {
        ...state,
        distributors: newDistributors,
      };

    case CREATE_DISTRIBUTOR_BRANCH:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      newDistributors[index].branches = [
        payload.branch,
        ...newDistributors[index].branches,
      ];
      return {
        ...state,
        distributors: newDistributors,
      };

    case UPDATE_DISTRIBUTOR_BRANCH:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      branchIndex = newDistributors[index].branches.findIndex(
        (branch) => branch.id == payload.branch.id
      );
      newDistributors[index].branches[branchIndex] = payload.branch;

      return {
        ...state,
        distributors: newDistributors,
      };

    case DELETE_DISTRIBUTOR_BRANCH:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      newDistributors[index].branches = newDistributors[index].branches.filter(
        (branch) => branch.id != payload.branchID
      );

      return {
        ...state,
        distributors: newDistributors,
      };

    case BULK_DELETE_DISTRIBUTOR_BRANCHES:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);
      newDistributors[index].branches = newDistributors[index].branches.filter(
        (branch) => !payload.ids.includes(branch.id)
      );

      return {
        ...state,
        distributors: newDistributors,
      };

    case BULK_SET_DIST_BRANCH_DELIVERY_RATE:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id == payload.distID);

      payload.ids.forEach((id) => {
        branchIndex = newDistributors[index].branches.findIndex(
          (br) => br.id == id
        );
        newDistributors[index].branches[branchIndex].delivery_rate =
          payload.delivery_rate;
      });

      return {
        ...state,
        distributors: newDistributors,
      };

      case FETCH_DISTRIBUTOR_DISCOUNTS:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id === payload.id);
      newDistributors[index].discounts = payload.discounts;
      return {
        ...state,
        distributors: newDistributors,
      };
      case TOGGLE_DISTRIBUTOR_DISCOUNTS:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id === payload.id);
      const discountIndex = newDistributors[index].discounts.findIndex(
        (discount) => discount.id === payload.discountID
      );
      newDistributors[index].discounts[
        discountIndex
      ].is_active = !newDistributors[index].discounts[discountIndex].is_active;
      return {
        ...state,
        distributors: newDistributors,
      };

      case CREATE_DISTRIBUTOR_DISCOUNT:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id === payload.id);
      newDistributors[index].discounts = [
        payload.discount,
        ...newDistributors[index].discounts,
      ];
      return {
        ...state,
        distributors: newDistributors,
      };

      case DELETE_DISTRIBUTOR_DISCOUNT:
      newDistributors = state.distributors;
      index = newDistributors.findIndex((dist) => dist.id === payload.id);
      newDistributors[index].discounts = newDistributors[index].discounts.filter(
        (discount) => discount.id !== payload.discountID
      );
      return {
        ...state,
        distributors: newDistributors,
      };
    default:
      return state;
  }
};
