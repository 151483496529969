import jwt_decode from "jwt-decode";
import {
  SET_CURRENT_USER,
  SET_ERROR,
  SET_PERMISSIONS,
  PERMISSIONS_LOADING,
  FETCH_WHATSAPP_CUSTOM_TEXTS,
  FETCH_DASHBOARD_CUSTOM_TEXTS,
  HIDE_PASSWORD_CHANGE_MODAL,
  SET_DISSMISS_DATE,
} from "./actionTypes";
import instance from "./instance";
import axios from "axios";
import { message } from "antd";
import { batch } from "react-redux";

export const login = (userData) => {
  return async (dispatch) => {
    try {
      const res = await instance.post("/api/login/", userData);

      const user = res.data;
      if (user.tst) localStorage.setItem("tst", user.tst);
      dispatch({ type: "SET_DID_INTERACT" });
      dispatch(setCurrentUser(user.access));
    } catch (err) {
      const response = err.response;
      message.error("Something went wrong. Please try again");
      if (response) dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };
};

const setCurrentUser = (token) => {
  return async (dispatch) => {
    let user;

    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;

      user = jwt_decode(token);

      dispatch({
        type: SET_CURRENT_USER,
        payload: user,
      });
      dispatch({
        type: PERMISSIONS_LOADING,
      });

      try {
        const res = await instance.post("/get_user_permissions/", {
          token: localStorage.getItem("tst"),
        });

        if (res.data.logout) {
          localStorage.removeItem("token");
          delete instance.defaults.headers.common.Authorization;
          delete axios.defaults.headers.common.Authorization;
          window.location.reload();
          user = null;
          dispatch({
            type: SET_CURRENT_USER,
            payload: user,
          });
        } else {
          const permissions = res.data;
          const dashboard_custom_texts = res.data.dashboard_custom_texts[0];
          if (permissions.tst) localStorage.setItem("tst", permissions.tst);
          batch(() => {
            dispatch({ type: SET_PERMISSIONS, payload: permissions });
            dispatch({
              type: FETCH_DASHBOARD_CUSTOM_TEXTS,
              payload: dashboard_custom_texts,
            });
          });
        }
      } catch (err) {
        const response = err.response;
        if (response) dispatch({ type: SET_ERROR, payload: err.response.data });
      }
    } else {
      localStorage.removeItem("token");
      delete instance.defaults.headers.common.Authorization;
      delete axios.defaults.headers.common.Authorization;

      user = null;
      dispatch({
        type: SET_CURRENT_USER,
        payload: user,
      });
      dispatch({
        type: "RESET_DID_INTERACT",
      });
    }
  };
};

export const logout = () => setCurrentUser();

export const checkForExpiredToken = () => {
  // Check for token expiration
  let token = localStorage.getItem("token");

  const params = new URLSearchParams(window.location.search);
  token = params.get("token") || token || null;

  let user = null;
  if (token) {
    const currentTimeInSeconds = Date.now() / 1000;

    // Decode token and get user info
    try {
      user = jwt_decode(token);
    } catch (e) {
      console.log("Invalid token");
      return logout();
    }

    // Check token expiration
    if (user.exp >= currentTimeInSeconds) {
      // Set user
      return setCurrentUser(token);
    }
  }
  return logout();
};

export const hidePasswordChangeModal = () => {
  return {
    type: HIDE_PASSWORD_CHANGE_MODAL,
  };
};

export const dismissVerifyNotification = (type) => {
  return async (dispatch) => {
    try {
      const res = await instance.post("/dismiss_verification/", { type: type });

      dispatch({ type: SET_DISSMISS_DATE, payload: res.data });
    } catch (err) {
      message.error("Something went wrong. Please try again");
    }
  };
};
