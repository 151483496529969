let tr;
export default tr = {
  Customers: "العملاء",
  "New Customer": "أضف عميل",
  "Search for a customer": "إبحث عن عميل",
  Email: "الإيميل",
  "Full Name": "الاسم الكامل",
  "Instagram Account": "حساب الانستقرام",
  "Phone number": "رقم الهاتف",
  "Customer created successfully": "تم إضافة العميل بنجاح",
  Birthdate: "تاريخ الميلاد",
  Flagged: "مقيّد",
  "Order History": "تاريخ الطلبات",
  Addresses: "العناوين",
  "Search for an order": "إبحث عن طلب",
  "Search for an address": "إبحث عن عنوان",
  "Total spent": "المبلغ الإجمالي",
  "Number of orders": "عدد الطلبات",
  "Customer updated successfully": "تم تحديث العميل بنجاح",
  Discounts: "الخصومات",
  "Total Before Discounts": "المبلغ الإجمالى قبل الخصومات",
  "Delivery Charge": "خدمة التوصيل",
  Call: "اتصل",
  customers: "عملاء",
  "Go Back": "الرجوع للخلف",
  "Edit Customer": "تعديل العميل",
  "Are you sure you want to delete this address?":"هل انت متاكد من حذف هذا العنوان ؟",
  orders: "طلب",
  addresses: "عنوان",
  Orders: "الطلبات",
  Spend: "الملبغ الإجمالي",
  "Users List": "قائمة المستخدمين",
  users: "مستخدمين",
  "Total Points": "إجمالي النقاط",
  Points: "النقاط",
  "Created by": "بواسطة",
  "This Week": "هذا الاسبوع",
  "This Month": "هذا الشهر",
  "Edit customer details": "تعديل بيانات الزبون",
  "Entered number already belongs to a customer, proceed?":
    "الرقم الذي تم إدخاله ينتمي إلى العميل اخر ، هل ما زلت تريد المتابعة؟",
  "Accept":"موافق",
  "Decline":"الغاء",
  "Flag this customer": "اضف علامة لهذا الزبون",
  "To top-up, please enable wallets from Settings -> Preferences":
    "لاضافة رصيد، الرجاء تفعيل ميزة المحفظة من الاعدادات -> الخصائص",
};
