import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import { Modal, Input, Table, Button, message } from "antd";

// Actions
import moment from "moment";

const { Column } = Table;
const { Search } = Input;

class OpenOrdersModal extends Component {
  state = { orders: [], loading: true, search: "" };
  componentDidMount = async () => {
    const { t } = this.props;
    try {
      const res = await instance.get("/get_open_orders/");
      this.setState({ loading: false, orders: res.data });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };

  saveButtonDisabled = () => {
    const s = this.state;
    return !s.englishName || !s.arabicName;
  };
  fillOpenOrder = async (id) => {
    await this.props.fillOpenOrder(id);
    this.props.onClose();
  };

  render() {
    const { visible, t, config } = this.props;
    const { loading, orders, search } = this.state;
    const data = [];
    orders.forEach((order) => {
      if (order.tracking_id.toLowerCase().includes(search.toLowerCase()))
        data.push({ ...order, key: order.id });
    });

    return (
      <>
        <Modal
          style={{ top: 20 }}
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Orders")}
            </div>
          }
          footer={null}
          visible={visible}
          onCancel={() => this.props.onClose()}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
        >
          <div className="px-2 my-3">
            <Search
              className="mb-3"
              placeholder={t("Search for an order")}
              enterButton
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <Table
              loading={loading}
              dataSource={data}
              size="small"
              rowClassName={() => "text-center"}
              pagination={{
                defaultPageSize: 10,

                pageSizeOptions: ["10", "25", "50", "100"],
              }}
            >
              <Column
                title={t("Tracking ID")}
                key="tracking_id"
                dataIndex={"tracking_id"}
              />
              <Column title={t("Total")} key="total" dataIndex={"total"} />
              <Column
                title={t("Placed")}
                key="created"
                dataIndex={"created"}
                sorter={{
                  compare: (a, b) =>
                    moment(a.created, "DD-MM-YYYY h:mm A") -
                    moment(b.created, "DD-MM-YYYY h:mm A"),
                }}
                render={(value) =>
                  moment(value, "YYYY-MM-DDTHH:mm:ss").format(
                    "DD-MM-YYYY h:mm A"
                  )
                }
              />
              <Column
                title={t("Edit")}
                key="edit"
                dataIndex={"edit"}
                render={(text, record) => (
                  <div className="text-center">
                    <Button
                      type="primary"
                      onClick={() => this.fillOpenOrder(record.id)}
                    >
                      {t("Edit")}
                    </Button>
                  </div>
                )}
              />
            </Table>
            <span style={{ position: "relative", top: -37 }}>
              {t("Total")}: {data.length} {t("orders")}
            </span>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OpenOrdersModal));
