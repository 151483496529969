let tr;
export default tr = {
  Coupons: "الكوبونات",
  "New coupon": "إضافة كوبون",
  "Search for a coupon": "إبحث عن كورون",
  Code: "الكود",
  FREE: "مجانا",
  "Discount type": "نوع الخصم",
  "Discount amount": "قيمة الخصم",
  "Discount Amount": "قيمة الخصم",
  "Discount Type": "نوع الخصم",
  Percent: "نسبة",
  Delivery: "توصيل",
  Fixed: "مبلغ محدد",
  percent: "نسبة",
  delivery: "توصيل",
  fixed: "مبلغ محدد",
  "Coupon created successfully": "تم إنشاء الكوبون بنجاح",
  "Are you sure you want to delete this coupon?":
    "هل انت متأكد من حذف هذا الكوبون؟",
  "Coupon was deleted successfully": "تم حذف الكوبون بنجاح",
  "The coupon is already deleted": "تم حذف هذا الكوبون مسبقا",
  "Could not change coupons's image": "لم نقدر على تغير صورة الكوبون",
  "Coupon updated successfully": "تم تحديث الكوبون بنجاح",
  "Extend Coupon": "تمديد الكوبون",
  "Coupon extended successfully": "تم تمديد الكوبون بنجاح",
  "Edit Coupon": "تعديل الكوبون",
  "New Coupon": "كوبون جديد",
  "Start and Expiry Dates": "تاريخ البدء والإنتهاء",
  "Coupon Code": "كود الكوبون",
  "Enter Code": "ادخل الكود",
  "Fixed Amount": "مبلغ محدد",
  Private: "مخفى",
  Active: "فعال",
  "coupons selected": "كوبونات محددة",
  "Deleted coupons successfully": "تم حذف الكوبونات بنجاح",
  "active coupons": "كوبونات مفعلة",
  "inactive coupons": "كوبونات معطلة",
  "Are you sure you want to delete all selected coupons? You can't undo this action":
    "هل انت متأكد من حذف جميع الكوبونات المحددة؟ لا يمكنك الرجوع",
  "Actions on selected coupons": "التحكم في الكوبونات المحددة",
  Details: "التفاصيل",
  "Hide expired coupons": "إخفاء الكوبونات المنتهية الصلاحية",
  unlimited: "غير محدود",
  "Set the total times this promotion can be used":
    "حدد عدد المرات التي يمكن استخدام هذا العرض ",
  B2B: "للشركات",
  Normal: "عادي",
  "Coupon Type": "نوع الكوبون",
  B2B: "للشركات",
  Normal: "عادي",
  "Coupon Type": "نوع الكوبون",
  "added to wallet": "تضاف الى المحفظة",
  "of the order's total": "من قيمة الطلب",
  "A cashback": "قيمة مسترجعة",
};
