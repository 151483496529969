import customTexts from "./customTexts";
import identity from "./identity";
import invoiceSettings from "./invoiceSettings";
import features from "./features";
import layoutTheme from "./layoutTheme";
import banner from "./banner";

const trans = {
  ...customTexts,
  ...identity,
  ...invoiceSettings,
  ...features,
  ...layoutTheme,
  ...banner,
  Appearance: "المظهر",
  "Custom texts": "نصوص المتجر",
  "Custom Texts": "نصوص المتجر",
};

export default trans;
