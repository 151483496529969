import * as types from "../actions/actionTypes";

const initialState = {
    drivers: {}, error: {}, driver_order_history: {}, driver_order_history_error: {},
};

const driver = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DRIVER:
            return {
                ...state, drivers: action.payload, driver_order_history_error: {}
            };
        case types.GET_DRIVER_ORDER_HISTORY:
            return {
                ...state, driver_order_history: action.payload, driver_order_history_error: {}
            };
        case types.CLEAR_DRIVER_ORDER_HISTORY:
            return {
                ...state, driver_order_history: {}
            };
        case types.GET_DRIVER_ORDER_HISTORY_ERROR:
            return {
                ...state, driver_order_history_error: action.payload, driver_order_history: {}
            };
        case types.DELETE_DRIVER:
            return {
                ...state,
                drivers: {data: state.drivers.data.filter((driver) => driver.id !== action.payload), status: "success"}
            }
        case types.TOGGLE_DRIVER_STATUS:
            const index = state.drivers.data.findIndex(driver => driver.id === action.payload)
            let newDriverState = state.drivers
            newDriverState.data[index].is_active = !newDriverState.data[index].is_active
            return {
                ...state, drivers: newDriverState
            }
        case types.CREATE_DRIVER:
            return {
                ...state,
                drivers: {data: [...state.drivers.data, action.payload.data], status: action.payload.status},
            }
        case types.CREATE_DRIVER_ERRORS:
            return {
                ...state, error: action.payload
            }
        case types.CLEAR_DRIVER_ERROR:
            return {
                ...state, error: null
            }
        case types.CHANGE_DRIVER_PROFILE_PICTURE:
            const driverIndex = state.drivers.data.findIndex(driver => driver.id === +action.payload.id)
            const newDrivers = state.drivers;
            newDrivers.data[driverIndex].profile_pic = action.payload.profile_pic;
            return {
                ...state, drivers: newDrivers
            };
        default:
            return state;
    }
};

export default driver;
