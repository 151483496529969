import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import {
  Modal,
  Select,
  Input,
  Row,
  Col,
  message,
  Carousel,
  Button,
  Checkbox,
} from "antd";
import Slide from "./Slide";

// Actions

const { Option } = Select;

const slidesData = [
  {
    photo: "",
    title: "Welcome to your dashboard",
    body:
      "We'll take you on a 3-minute tour to show you how you can use this portal to manage your orders, products, locations, etc..",
  },
  {
    photo: "",
    title: "Receive and manage your orders",
    body:
      "You can access your customer orders section either from the quick links on your home page or from your navigation links on the side of you screen",
  },
  {
    photo:
      "https://general-ordable.ams3.digitaloceanspaces.com/Products%20Intro%20test.png",
    title: "Take full control of your products",
    body:
      "In your products section, you can add, delete, and configure your products according to your business needs. The possibilities are endless!",
  },
];
class NewBranchModal extends Component {
  state = {
    currentPage: 0,
  };
  carousel = React.createRef();

  render() {
    const { config, t } = this.props;

    return (
      <Modal
        centered
        width={800}
        destroyOnClose={true}
        title={"Welcome"}
        visible={true}
        onCancel={() => this.props.onClose()}
        okText={t("Save")}
        cancelText={t("Close")}
        bodyStyle={{
          minHeight: 600,
          paddingTop: 0,
          width: "auto",
          padding: 0,
          textAlign: config.direction == "ltr" ? "left" : "right",
          position: "relative",
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        footer={null}
        title={null}
        closeIcon={null}
      >
        <div className="my-3">
          <Carousel
            afterChange={(currentPage) => this.setState({ currentPage })}
            ref={(slider) => (this.carousel = slider)}
            dotPosition="top"
          >
            {slidesData.map((slide) => (
              <Slide
                photo={slide.photo}
                title={slide.title}
                body={slide.body}
              />
            ))}
          </Carousel>
        </div>

        <div
          className="text-center"
          style={{ height: 50, position: "absolute", width: "100%", bottom: 0 }}
        >
          <Button
            type="link"
            className="mx-2"
            onClick={() => {
              this.setState({ currentPage: this.state.currentPage - 1 });
              this.carousel.prev();
            }}
            disabled={this.state.currentPage === 0}
            style={{ float: config.language === "arabic" ? "right" : "left" }}
          >
            {t("Previous")}
          </Button>

          <Button
            className="mx-2"
            type="link"
            onClick={() => {
              if (this.state.currentPage === slidesData.length - 1) {
                this.props.onClose(true);
              } else {
                this.setState({ currentPage: this.state.currentPage + 1 });
                this.carousel.next();
              }
            }}
            style={{ float: config.language === "arabic" ? "left" : "right" }}
          >
            {t(
              this.state.currentPage === slidesData.length - 1
                ? "Get started"
                : "Next"
            )}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    locations: state.locations,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewBranchModal));
