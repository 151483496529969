const trans = {
  Downloads: "التحميلات",
  Reports: "التقارير",
  "Request a report download": "اطلب تقرير جديد",
  "Here you can find a list of readily available downloadable reports for your convenience to manipulate using your favourite spreadsheet software. Once your download is ready you will get a notification in the downloads tab":
    "هنا بإمكانك طلب تقرير من الانواع المدرجة ادناه، ثم بإمكانك عرض و تعديل التقرير بإستخدام برنامجك المفضل لعرض التقارير. عندما يصبح ملفك جاهزا، ستجده في قسم التحميلات",
  Request: "طلب",
  Sales: "المبيعات",
  Customers: "الزبائن",
  "Customer List": "قائمة الزبائن",
  "Stock Levels": "مستويات المخزون",
  "Sales Summary": "ملخص المبيعات",
  "Line Item Report": "تقرير المبيعات - فواتير مع تفاصيل المنتجات",
  "Order-wise Report": "تقرير المبيعات - ملخص الفواتير",
  "Shows sales at a line-item level (each product in the invoice)":
    "تقرير المبيعات مع تفاصيل كل منتج في كل فاتورة",
  "A report that shows sales at an order level":
    "تقرير يعرض لك المبيعات على مستوى الطلب",
  "A report that shows stock levels by product":
    "تقرير يعرض لك مستوى مخزون كل منتج",
  "A report that shows sales according to payment methods and order types":
    "تقرير يعرض لك المبيعات بناء على طرق الدفع و نوع الطلب",
  "A report that shows all your customers by name and phone number":
    "تقرير يعرض لك جميع زبائنك بأسمائهم، أرقام هواتفهم وبريدهم الالكتروني",
  "Your downloads": "تحميلاتك",
  "The files you have requested will show here once done. Files here get deleted every 24 hours.":
    "الملفات التي طلبتها ستظهر هنا. سيتم حذف الملفات بعد 24 ساعة من ظهورها",
  "Requested at": "تم الطلب في",
  Download: "تحميل",
  "The file is being prepared now. It might take a few minutes":
    "يتم الآن تجهيز الملف. قد يستغرق بضع دقائق..",
  "Please select a date range": "الرجاء اختيار الفترة",
  "Your file is ready to download": "الملف جاهز للتحميل",
  "Could not download the file. Please try again or contact us for help":
    "لم نتمكن من تجهيز الملف. الرجاء المحاولة مرة اخرى او تواصل معنا",
  "Product Catalog": "قائمة المنتجات",
  "Two reports for products and options":
    "تقريرين يعرضان منتجاتك وخيارات كل منتج",
  "A report that shows all the feedbacks that have been sent to you by customers":
    "تقرير يعرض جميع التعليقات التي تم ارسالها من قبل عملائك",
  "A report that shows customers who attempted to order but never ordered":
    "تقرير يعرض كل الزبائن الذي أقدموا على الطلب و لم يكملوا عملية الشراء.",
  "Product Catalog Import": "قائمة المنتجات لنقل المعلومات",
  "Two reports for products and options for importing":
    "ملفين للمنتجات و الخيارات لنقل معلومات المتجر.",

  "Customer Feedbacks": "تعليقات العملاء",
  "Product Sales for a Period": "مبيعات المنتجات لفترة معينة",
  "A report for product level sales by date range":
    "تقرير يعرض مبيعات المنتجات خلال فترة زمنية معينة",
  "B2B Line Item Report": "تقرير مبيعات الشركات - فواتير مع تفاصيل المنتجات",
  "B2B Preparation Report": "تقرير مبيعات الشركات - سجل التحضير",

  "Shows sales at a line-item level for B2B orders (each product in the invoice)":
    "تقرير مبيعات الشركات مع تفاصيل كل منتج في كل فاتورة",
  "Bookings Report": "تقرير الحجوزات",
  "Shows bookings sales details": "يعرض تفاصيل الحجوزات",
  "B2B Order-wise Report": "تقرير مبيعات الشركات - ملخص الفواتير",
  "A report that shows B2B sales at an order level":
    "تقرير يعرض لك مبيعات الشركات على مستوى الطلب",
  "A reports thats shows B2B's Preperation sheet":
    "تقرير يعرض لك سجل التحضير لمبيعات الشركات",
  "Promotions Order-wise Report": "تقرير تفاصيل العروض",
  "A report that shows Promotions details at an order level":
    "تقرير يعرض لك تفاصيل العروض على مستوى الطلب",
  "Promotions Usage Report": "تقرير استخدام العروض",
  "A report that shows the amount of times a promotion was used":
    "تقرير يعرض لك مدى استخدام العروض",
  "Reports > Downloads": "التقارير > التحميلات",
  "Reports > Customers Reports": "التقارير > تقارير العملاء",
  "Select sources (optional)": "اختر المصادر (إختياري)",
  "Select users (optional)": "اختر المستخدمين (اختياري)",
  Website: "الموقع",
  "Select channels (optional)": "اختر القنوات (إختياري)",
  Cost: "التكلفة",
  "Cost of goods sold by supplier": "تكلفة البضائع حسب المورد",
  "A report that shows cost of goods sold by supplier":
    "تقرير يعرض  تكلفة البضائع حسب المورد",
  "Customer Survey Responses": "ردود الاستبيان للعملاء",
  "A report that shows the responses and ratings to the surveys conducted":
    "تقرير يوضح الردود والتصنيفات على الاستبيانات التي تم إجراؤها",
  Cost: "التكلفه",
  "Gross Revenue": "صافي الأرباح",
  "Bookings Report Split By Options": "تقرير الحجوزات حسب الخيارات",
  "A report for each bookable invoice line item with a column for each option":
    "تقرير يعرض لك الحجوزات مقسمين حسب الخيارات مع وجود عمود لكل خيار",
  "Customer Segments Report": "تقرير فئات العملاء",

  "Delivery Areas and Rates": "مناطق التوصيل و اسعارها",
  "A report that shows the your delivery areas and corresponding rates":
    "تقرير يوضح مناطق التوصيل و اسعارها",
  "A report that shows customers and the segment they belong to":
    "تقرير لجميع العملاء و فئاتهم",
  "Date Received": "تاريخ الطلب",
  "Date based on": "التاريخ حسب",
  "Select Business (optional)": "إختار شركة (إختياري)",
  "Include Deleted": "أضف الكوبونات المحذوفة",
  "Show all": "إختار الكل",
  "Wallet transactions": "عمليات المحفظة",
  "A report that shows the transactions completed from your wallet":
    "تقرير يوضح العمليات التي تمت من محفظتك",
  "Collected emails for out of stock products":
    "الايميلات التي تم جمعها للاعلام عن المنتجات الغير المتوفرة في المخزون",
  "Report that displays all the emails collected on your store for customers that want to be notified about when a product is restocked":
    "تقرير يعرض جميع الايميلات التي تم جمعها في متجرك للعملاء الذين يريدون أن يتم إعلامهم عند إعادة تخزين المنتج",
  "Loyalty points report": "تقرير نقاط المكافآت",
  "A report that shows the users loyalty points amount and spend":
    "تقرير يوضح نقاط المكافآت للمستخدمين و النقاط المستخدمة",
  "A report that provides a record of when your store and branches were marked as 'busy,' along with the corresponding users and time periods.": `تقرير يوفر سجلًا للفترة التي تم وضع "مشغول" على متجرك وفروعه، بالإضافة إلى المستخدم المسؤول والمدة الزمنية.`,
  "Store/branch closure history": "سجل إغلاق المتجر/الفرع",
  "A report that provides a how many dine table QR was scanned within a period of time.":
    "تقرير يوفر عدد طاولات الطعام التي تم مسحها ضوئيًا QR خلال فترة زمنية.",
  "Dine tables QR scan history": "سجل مسح QR الطاولات",
  "Dine-in": "الطاولات",
};

export default trans;
