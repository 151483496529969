const trans = {
  "Invoice settings": "اعدادات الايصال",
  "Thermal Layout": "تنسيق الايصال الحراري",
  "A4 Print Layout": "تنسيق طابعة A4",
  "Original ": "الافتراضي",
  Compact: "مصغر",
  Dense: "مكثف",
  Footer: "عبارة اضافية على اسفل الايصال",
  "Footer Arabic": "عبارة اضافية على اسفل الايصال بالعربي",
  Header: "عبارة اضافية على اعلى الايصال",
  "Header Arabic": "عبارة اضافية على اعلى الايصال بالعربي",
  "With images": "مع صور",
  Plain: "من غير صور",
  "Show SKUs on Invoices": "عرض وحدات حفظ المخزون",
  "Show product description":"عرض وصف المنتج",
  "Print Barcodes": "طباعة الباركود",
  "Print directions QR": "طباعة رمز QR لعنوان العميل على الخريطة",
  "When enabled, a QR code with Google Maps directions will appear on your invoices":
    "عند التفعيل، سيتم طباعة رمز QR على الفواتير للاستدلال على عنوان العميل على خريطة جوجل",
  "Note: Collected location coordinates can sometimes be inaccurate if placed improperly by customers":
    "ملاحظة: احداثيات العنوان على الخريطة قد تكون غير صحيحة احيانا اذا لم يتم ادخالها بصورة صحيحة من قبل العميل",
  "Show product images": "اظهار صور المنتجات",
  "When enabled, you'll see the product's image next to its name in the dashboard's order invoice":
    "اظهار صورة المنتج بجانب اسمه في فاتورة شاشة التحكم",
  "Show branch address": "اظهار عنوان الفرع",
  "Tax ID": "رمز التسجيل للضريبة",
  "Print Invoice QR": "طباعة رمز QR لبيانات الفاتورة",
  "Hide Option Name": "اخفاء اسم الخيار",
  "Show delivery cost": "إظهار تكلفة التوصيل",
  "Enable printing before receiving an order":
    "تفعيل طباعة الطلبات قبل استلامها",
  "Text Size": "حجم الخط",
  "Show on demand text": "إظهار وقت التوصيل بأقرب وقت",
  "Show order received by": "إظهار اسم مستلم الطلب",
  "Hide gift orders' prices": "إخفاد سعر الهدايا",
  "Current store QR link:-": "رابط QR للمتجر الحالي:-",
  "Print Store QR": "طباعة رمز QR للمتجر",
  "Store link": "رابط المتجر",
  "Hide prices from thermal invoices": "إخفاء الأسعار من الفواتير الحرارية",
  "When enabled, a QR code that allows the person who scans it to view the invoice details (customer contact details, address, and order) will appear on your invoices.":
    "عند التمكين،سيظهر رمز QR الذي يسمح للشخص الذي يقوم بمسحه ضوئيًا بعرض تفاصيل الفاتورة (تفاصيل الاتصال بالعميل والعنوان والطلب) في فواتيرك",
  "When enabled, a QR code with store link will appear on your invoices":
    "عند التفعيل، سيظهر رمز QR مع رابط المتجر على فواتيرك",
  "Show province name on invoices": "اظهار اسم المحافظة في الفواتير",
  "Override invoice settings": "تجاوز إعدادات الفاتورة",
  "Please enter text size": "الرجاء إدخال حجم النص",
};

export default trans;
