import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
//Ant
import { Modal, Col, Row } from "antd";

//My Components
import Product from "./ProductList/Product";

export class index extends Component {
  render() {
    const { t, config, selectedProduct, visible, products, width } = this.props;
    let displayedProducts = [];

    products.allProducts.forEach((product, index) => {
      if (product.parent === selectedProduct.id) {
        displayedProducts.push(
          <Col xl={8} lg={8} md={12} sm={24} xs={24} key={index}>
            <Product
              products={products.allProducts}
              width={width}
              setStateIndex={this.props.setStateIndex}
              product={product}
            />
          </Col>
        );
      }
    });
    return (
      <Modal
        width={800}
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {selectedProduct.name}
          </div>
        }
        visible={visible}
        cancelText={t("Close")}
        onCancel={this.props.onClose}
        footer={null}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <Row className="mt-4 pb-4" gutter={[30, 20]}>
          {displayedProducts}
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(index));
