import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import posed from "react-pose";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10,
      duration: 4,
    },
  },
});
class ProductBookingSlot2 extends Component {
  constructor(props) {
    super(props);
    this.Dates_Container = React.createRef();
    this.Times_Container = React.createRef();
  }

  state = {
    selectedDate: -1,
    selectedTime: -1,
    ScrollAbleDate: false,
    ScrollAbleTime: false,
    error: false,
  };

  SetBookingError = () => {
    this.setState({ error: true });
  };

  ar_days = {
    Sun: "اﻷحد",
    Mon: "اﻷثنين",
    Tue: "الثلاثاء",
    Wed: "اﻷربعاء",
    Thu: "الخميس",
    Fri: "الجمعة",
    Sat: "السبت",
  };
  ar_months = {
    Jan: "يناير ",
    Feb: "فبراير",
    Mar: "مارس",
    Apr: "أبريل",
    May: "مايو",
    Jun: "يونيو",
    Jul: "يوليو",
    Aug: "أغسطس",
    Sep: "سبتمبر",
    Oct: "أكتوبر",
    Nov: "نوفمبر",
    Dec: "ديسمبر",
  };

  removeDups = (names) => {
    let unique = {};
    names.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  };
  check_out_of_stock = (date) => {
    let mydate = this.formatDate(this.dates[date]);
    let Times = this.props.slots.filter(
      (date) => date.date === mydate && date.inventory > 0
    );
    if (Times.length) return true;
    return false;
  };

  removeEmpty = (date, i) => {
    let mydate = this.formatDate(date);
    let Times = this.props.slots.filter(
      (date) => date.date === mydate && date.inventory > 0
    );

    if (Times.length) return false;

    this.dates.splice(i, 1);

    return true;
  };
  handleDateChange = (date) => {
    let selectedTime = -1;
    let newError = this.state.error;
    if (date !== this.state.selectedDate) {
      let mydate = this.formatDate(this.dates[parseInt(date)]);

      let Times = this.props.slots.filter((date) => date.date === mydate);

      if (Times.length === 1) {
        selectedTime = Times[0].id;
        newError = false;
      }

      this.props.SetBookingSlot(selectedTime);
      this.setState({
        selectedDate: parseInt(date),
        selectedTime: selectedTime,
        error: newError,
      });
    }
  };
  handleTimeChange = (Time, id) => {
    if (id !== this.state.selectedTime) {
      this.props.SetBookingSlot(id);
      this.setState({
        selectedTime: id,
        error: false,
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.current_product.id !== this.props.current_product.id) {
      this.setUpBookingSlots(true);
    }
    if (this.Dates_Container.current && this.Dates_Container.current) {
      let CheckScrollAbleDate =
        this.Dates_Container.current.container.current.scrollWidth >
        this.Dates_Container.current.container.current.clientWidth;
      let CheckScrollAbleTime =
        this.Times_Container.current.container.current.scrollWidth >
        this.Times_Container.current.container.current.clientWidth;
      if (
        CheckScrollAbleDate !== this.state.ScrollAbleDate ||
        CheckScrollAbleTime !== this.state.ScrollAbleTime
      ) {
        this.setState({
          ScrollAbleDate: CheckScrollAbleDate,
          ScrollAbleTime: CheckScrollAbleTime,
        });
      }
    }
    if (this.props.current_product.service_booking_style === "calendar") {
      if (prevProps.selectedDate != this.props.selectedDate) {
        let selectedTime = -1;
        let Times = this.props.slots.filter(
          (date) => date.date === this.props.selectedDate
        );

        if (Times.length === 1) {
          selectedTime = Times[0].id;
          this.props.SetBookingSlot(selectedTime);
        }
        this.setState({ selectedTime: selectedTime });
      }
    }
  }
  setUpBookingSlots(errorFuncionSet = false) {
    this.dates = this.removeDups(
      this.props.slots.map((date) => this.toDate(date.date))
    );

    this.dates.forEach((date, i) => this.removeEmpty(date, i));
    for (let i = 0; i < this.dates.length; i++) {
      if (this.removeEmpty(this.dates[i], i)) i -= 1;
    }

    if (this.dates.length) {
      this.props.SetBookingSlot(
        this.props.slots.find((slot) => slot.inventory !== 0).id
      );

      this.props.SetBookingSlot(-1);
    } else {
      this.props.SetBookingSlot("Out");
    }

    if (!errorFuncionSet) {
      this.props.SetBookingError(() => this.SetBookingError());
    }
    this.setState({
      selectedDate: -1,
    });
  }
  componentDidMount() {
    this.setUpBookingSlots();
  }
  toDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");

    return new Date(year, month - 1, day);
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  translateSlotText = (text) => {
    const { config } = this.props;
    if (config.language !== "arabic") return text;

    let temp = text.replace(/PM/g, "م");
    temp = temp.replace(/AM/g, "ص");
    return temp;
  };
  render() {
    let DateItems = [];
    let Times = [];
    let dateError = false;
    let timeError = false;
    if (this.state.error) {
      if (this.state.selectedDate === -1) {
        dateError = true;
      } else {
        timeError = true;
      }
    }
    const themeColor = this.props.themeColor
      ? this.props.themeColor
      : "#0099cc";

    const { config, current_product, t } = this.props;
    if (this.dates) {
      DateItems = this.dates.map((date, i) => {
        date = date.split(" ");
        return config.language !== "arabic" ? (
          <span
            key={i}
            className={` pointer mx-4 ${
              this.state.selectedDate === i ? "active" : ""
            }`}
            onClick={() => this.handleDateChange(i)}
            style={{
              color: this.state.selectedDate === i && themeColor,
              border: `1px solid ${
                this.state.selectedDate === i ? themeColor : "#99999999"
              }`,
              borderRadius: 5,
              padding: 15,
            }}
          >
            {`${date[0]}  ${parseInt(date[2], 10)}  ${date[1]}`}
          </span>
        ) : (
          <span
            key={i}
            className={` pointer mx-4 ${
              this.state.selectedDate === i ? "active" : ""
            }`}
            onClick={() => this.handleDateChange(i)}
            style={{
              color: this.state.selectedDate === i && themeColor,
              border: `1px solid ${
                this.state.selectedDate === i ? themeColor : "#99999999"
              }`,
              borderRadius: 5,
              padding: 15,
            }}
          >
            {`${this.ar_days[date[0]]}  ${parseInt(date[2], 10)} ${
              this.ar_months[date[1]]
            }`}
          </span>
        );
      });

      let mydate = this.formatDate(this.dates[this.state.selectedDate]);
      if (current_product.service_booking_style == "calendar") {
        mydate = this.props.selectedDate;
      }
      Times = this.props.slots
        .filter((date) => date.date === mydate && date.inventory > 0)
        .map((time, i) => (
          <span
            key={i}
            value={time.id}
            onClick={() => this.handleTimeChange(i, time.id)}
            className={` pointer mx-4 ${
              this.state.selectedTime === time.id ? "active" : ""
            }`}
            style={{
              color: this.state.selectedTime === time.id && themeColor,
              border: `1px solid ${
                this.state.selectedTime === time.id ? themeColor : "#99999999"
              }`,
              borderRadius: 5,
              padding: 15,
              direction: config.direction,
            }}
          >
            {this.translateSlotText(
              `${moment(time.start, "HH:mm").format("h:mm A")} - ${moment(
                time.end,
                "HH:mm"
              ).format("h:mm A")}`
            )}
          </span>
        ));
    }

    if (this.dates && this.dates.length)
      return (
        <div
          id="BookingSlots"
          className={
            current_product.service_booking_style === "scroll" &&
            "border-top border-bottom"
          }
          style={{ width: "100%", backgroundColor: "white" }}
        >
          {current_product.service_booking_style === "scroll" && (
            <>
              {" "}
              <ShakePose
                pose={[`${dateError ? "shake" : ""}`]}
                poseKey={dateError === false}
              >
                <h4
                  className={`mt-4  ${
                    config.direction === "ltr" ? "text-left" : "text-right"
                  } mx-4`}
                  style={{
                    fontFamily:
                      config.language !== "arabic" ? "Quicksand" : "Cairo",
                    color: dateError ? "red" : "",
                    fontSize: 14,
                  }}
                >
                  {t("Select date")}
                </h4>
              </ShakePose>
              <div
                className={`d-flex mt-4 border-top border-bottom mx-auto align-items-center ${
                  this.state.ScrollAbleDate
                    ? "justify-content-start"
                    : "justify-content-center"
                }   `}
                style={{
                  width: "90%",
                  heigth: "70px",
                  color: "#999",
                  fontSize: "15px",
                  fontFamily: "",
                  fontWeight: "600",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <div style={{ height: "auto" }}>
                  {this.state.ScrollAbleDate && (
                    <LeftOutlined
                      className="pointer"
                      onClick={() =>
                        (this.Dates_Container.current.container.current.scrollLeft += -100)
                      }
                    />
                  )}
                </div>
                <ScrollContainer
                  className={` d-flex  py-3   ${
                    config.direction === "rtl" ? "flex-row-reverse" : ""
                  }`}
                  ref={this.Dates_Container}
                >
                  {DateItems}
                </ScrollContainer>
                {this.state.ScrollAbleDate && (
                  <div style={{ height: "auto" }}>
                    <RightOutlined
                      className="ml-1 my-auto"
                      className="pointer"
                      onClick={() =>
                        (this.Dates_Container.current.container.current.scrollLeft += 100)
                      }
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <ShakePose
            pose={[`${timeError ? "shake" : ""}`]}
            poseKey={timeError === false}
          >
            {" "}
            <h4
              className={`mt-4  ${
                config.direction === "ltr" ? "text-left" : "text-right"
              } mx-4`}
              style={{
                fontFamily:
                  config.language !== "arabic" ? "Quicksand" : "Cairo",
                color: timeError ? "red" : "",
                fontSize: 14,
              }}
            >
              {t("Select time")}
            </h4>
          </ShakePose>
          <div
            className={`d-flex mt-4 mx-auto align-items-center ${
              current_product.service_booking_style == "scroll"
                ? "mb-5"
                : "mb-4"
            } ${
              this.state.ScrollAbleTime
                ? "justify-content-start"
                : "justify-content-center"
            } 
           `.concat(
              current_product.service_booking_style === "scroll"
                ? " border-top border-bottom"
                : ""
            )}
            style={{
              width: "90%",
              heigth: "70px",
              color: "#999",
              fontSize: "15px",
              fontFamily: "",
              fontWeight: "600",
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ height: "auto" }}>
              {((current_product.service_booking_style === "calendar" &&
                this.props.selectedDate &&
                Times.length > 1) ||
                (current_product.service_booking_style === "scroll" &&
                  this.state.ScrollAbleTime &&
                  this.state.selectedDate !== -1)) && (
                <LeftOutlined
                  className="pointer"
                  onClick={() =>
                    (this.Times_Container.current.container.current.scrollLeft += -100)
                  }
                />
              )}
            </div>

            <ScrollContainer
              className={` d-flex  py-3 ${
                config.direction === "rtl" ? "flex-row-reverse" : ""
              } `}
              ref={this.Times_Container}
            >
              {Times.length ? Times : t("Select your date first")}
            </ScrollContainer>
            {((current_product.service_booking_style === "calendar" &&
              this.props.selectedDate &&
              Times.length > 1) ||
              (current_product.service_booking_style === "scroll" &&
                this.state.ScrollAbleTime &&
                this.state.selectedDate !== -1)) && (
              <div style={{ height: "auto" }}>
                <RightOutlined
                  style={{}}
                  className="ml-1 my-auto"
                  className="pointer"
                  onClick={() =>
                    (this.Times_Container.current.container.current.scrollLeft += 100)
                  }
                />
              </div>
            )}
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default connect(mapStateToProps)(withNamespaces()(ProductBookingSlot2));
