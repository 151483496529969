import {
  FETCH_SERVICE_ORDER_ITEMS_LOADING,
  FETCH_SERVICE_ORDER_ITEMS,
  SAVE_SERVICE_ORDER,
  SOCKET_NEW_SERVICE_ORDER,
  UPDATE_CUSTOMER_SERVICE_STATUS,
  EDIT_SERVICE_ORDER_DATE_TIME,
} from "../actions/actionTypes";

const initialState = {
  orderItems: [],
  products: [],
  resources: [],
  loading: false,
  didFullyLoad: false,
  newItem: null,
};

export default (state = initialState, { type, payload, asyncDispatch }) => {
  let newOrderItems = null;
  let index = null;

  switch (type) {
    case FETCH_SERVICE_ORDER_ITEMS_LOADING:
      return { ...state, loading: true };

    case FETCH_SERVICE_ORDER_ITEMS:
      return {
        ...state,
        orderItems: payload.items,
        products: payload.products,
        resources: payload.resources,
        loading: false,
        didFullyLoad: true,
      };

    case SAVE_SERVICE_ORDER:
      newOrderItems = state.orderItems;
      index = newOrderItems.findIndex((oi) => oi.id == payload.bookingID);
      newOrderItems[index].order_obj = payload.orderObj;
      return {
        ...state,
        orderItems: newOrderItems,
      };

    case SOCKET_NEW_SERVICE_ORDER:
      newOrderItems = state.orderItems;
      if (state.didFullyLoad) newOrderItems = [payload, ...newOrderItems];
      if (window.location.pathname == "/manage/services")
        window.setTimeout(() => {
          asyncDispatch((dispatch) =>
            dispatch({ type: "RESET_NEW_SERVICE_ITEM" })
          );
        }, 7000);
      return {
        ...state,
        orderItems: newOrderItems,
        newItem: payload.id,
      };

    case "RESET_NEW_SERVICE_ITEM":
      return { ...state, newItem: null };

    case UPDATE_CUSTOMER_SERVICE_STATUS:
      newOrderItems = state.orderItems;

      index = newOrderItems.findIndex(
        (item) => item.order_id == payload.order_id
      );

      if (index >= 0) {
        newOrderItems[index].order_status = payload.status;
      }

      return { ...state, orderItems: newOrderItems };

    case EDIT_SERVICE_ORDER_DATE_TIME:
      newOrderItems = state.orderItems;

      index = newOrderItems.findIndex((item) => item.id == payload.id);

      if (index >= 0) {
        newOrderItems[index].booking_date = payload.date;
        newOrderItems[index].booking_start = payload.start;
        newOrderItems[index].booking_end = payload.end;
      }

      return { ...state, orderItems: newOrderItems };

    default:
      return state;
  }
};
