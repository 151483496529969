import {
  FETCH_PRODUCTION_WORKING_HOURS,
  FETCH_PRODUCTION_WORKING_HOURS_LOADING,
  SLOT_CONTROL,
  CHANGE_PD_DAY_START_END,
  BULK_TOGGLE_SLOTS,
  BULK_DELETE_SLOTS,
  CREATE_NEW_SLOTS,
  SWITCH_STORE_BUSY,
  UPDATE_STORE_BUSY_DATE_TIME,
  UPDATE_STORE_BUSY_DATE_TIME_SOCKET,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchProductionAndWorkingHours = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCTION_WORKING_HOURS_LOADING });
    try {
      let res = await instance.get("/fetch_production_delivery_slots/");
      if (res.data.success) {
        dispatch({ type: FETCH_PRODUCTION_WORKING_HOURS, payload: res.data });
      } else
        message.error(
          "Could not load slots. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view this page");
      else
        message.error(
          "Could not load hours. Please try again or contact us for help"
        );
    }
  };
};

export const slotControl = (type, actionType, sid, newLimit) => {
  return {
    type: SLOT_CONTROL,
    payload: {
      type: type,
      actionType: actionType,
      sid: sid,
      newLimit: newLimit,
    },
  };
};

export const changeDayStartEnd = (type, fieldType, sid, value) => {
  return {
    type: CHANGE_PD_DAY_START_END,
    payload: { type: type, fieldType: fieldType, sid: sid, value: value },
  };
};

export const bulkToggleSlots = (type, ids, status) => {
  return {
    type: BULK_TOGGLE_SLOTS,
    payload: { type: type, ids: ids, status: status },
  };
};

export const bulkDeleteSlots = (type, ids) => {
  return {
    type: BULK_DELETE_SLOTS,
    payload: { type: type, ids: ids },
  };
};

export const createNewSlots = (type, slots) => {
  return {
    type: CREATE_NEW_SLOTS,
    payload: { type: type, slots: slots },
  };
};

export const switchStoreBusy = () => {
  return {
    type: SWITCH_STORE_BUSY,
  };
};

export const updateStoreBusyDateTime = (date, time) => {
  return {
    type: UPDATE_STORE_BUSY_DATE_TIME,
    payload: { date: date, time: time },
  };
};

export const updateStoreBusyDateTimeSocket = (payload) => {
  return {
    type: UPDATE_STORE_BUSY_DATE_TIME_SOCKET,
    payload: payload,
  };
};
