import {
  SOCKET_FILE_IS_READY,
  FETCH_DOWNLOADS_LOADING,
  FETCH_DOWNLOADS,
  FETCH_CUSTOMER_GROWTH_LOADING,
  FETCH_CUSTOMER_GROWTH,
  FETCH_RFM,
  FETCH_SALES_BY_LOCATION,
  FETCH_CLOSING_REPORT,
  FETCH_SALES_BY_MONTH,
  FETCH_SALES_BY_PRODUCT,
  FETCH_SALES_BY_DAY,
  FETCH_SALES_OVER_TIME,
  FETCH_SALES_BY_HOUR,
} from "./actionTypes";

import instance from "./instance";
import { message } from "antd";

export const fetchDownloads = (t) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DOWNLOADS_LOADING });

    try {
      const res = await instance.get(`/reports_fetch_downloads/`);
      if (res.data.success == true) {
        dispatch({ type: FETCH_DOWNLOADS, payload: res.data.downloads });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchCustomerGrowth = (start, end, withLoading, t, period) => {
  return async (dispatch) => {
    if (withLoading) dispatch({ type: FETCH_CUSTOMER_GROWTH_LOADING });

    try {
      const res = await instance.post(`/reports_fetch_customer_growth/`, {
        start: start,
        end: end,
        period: period,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_CUSTOMER_GROWTH, payload: res.data.data });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchRFM = (
  start,
  end,
  t,
  customersType,
  from,
  period,
  countriesCodes
) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_rfm/`, {
        start,
        end,
        period,
        customersType,
        from,
        countriesCodes,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_RFM, payload: res.data.data });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchSalesByLocation = (start, end, t) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_sales_by_location/`, {
        start,
        end,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_SALES_BY_LOCATION, payload: res.data.data });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchClosingReport = (
  day,
  basedOn,
  statuses,
  t,
  branchId,
  channel,
  username,
  placedBy
) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_closing_report/`, {
        for_date: day,
        based_on: basedOn,
        statuses: statuses,
        branch_id: branchId,
        channel: channel,
        username: username,
        placedBy: placedBy,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_CLOSING_REPORT, payload: res.data.data });
      } else message.error(t("SomethingWentWrong") || res.data.errors);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchSalesByMonth = (start, end, t) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_sales_by_month/`, {
        start: start,
        end: end,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_SALES_BY_MONTH, payload: res.data.data });
      } else message.error(t("SomethingWentWrong") || res.data.errors);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchSalesOverTime = (
  start,
  end,
  grain,
  branch,
  t,
  channel,
  status,
  basedOn,
  language,
  paymentStatus,
  orderType,
  prevPeriod,
  prevRangeStart,
  prevRangeEnd,
  fetchPrev,
  compare,
) => {

  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_sales_over_time/`, {
        start: start,
        end: end,
        grain: grain,
        branch: branch,
        channel: channel,
        status: status,
        based_on: basedOn,
        language: language,
        paymentStatus: paymentStatus,
        orderType: orderType,
        prevPeriod: prevPeriod,
        prevRangeStart: prevRangeStart,
        prevRangeEnd: prevRangeEnd,
        fetchPrev: fetchPrev,
        compare:compare,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_SALES_OVER_TIME, payload: {data: res.data.data, fetchPrev: fetchPrev, compare:compare} });
      } else message.error(t("SomethingWentWrong") || res.data.errors);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};
export const fetchTableScanHistory = (start, end, grain, branch, t, table) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_table_scan_history/`, {
        start: start,
        end: end,
        grain: grain,
        branch: branch,
        table: table,
      });
      if (res.data.success == true) {
        dispatch({ type: "FETCH_TABLE_SCAN_HISTORY", payload: res.data.data });
      } else message.error(t("SomethingWentWrong") || res.data.errors);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchSalesByHour = (
  start,
  end,
  t,
  branch,
  channel,
  status,
  basedOn,
  language,
  paymentStatus,
  orderType
) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_sales_by_hour/`, {
        start: start,
        end: end,
        branch: branch,
        channel: channel,
        status: status,
        based_on: basedOn,
        language: language,
        paymentStatus: paymentStatus,
        orderType: orderType,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_SALES_BY_HOUR, payload: res.data.data });
      } else message.error(t("SomethingWentWrong") || res.data.errors);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchSalesByProduct = (start, end, t, branch, channel) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/reports_fetch_sales_by_product/`, {
        start: start,
        end: end,
        branch: branch,
        channel: channel,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_SALES_BY_PRODUCT, payload: res.data.data });
      } else message.error(t("SomethingWentWrong") || res.data.errors);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const socketFileIsReady = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_FILE_IS_READY,
      payload: data,
    });
  };
};
