const trans = {
  "Review Order English": "مراجعة الطلب بالانجليزي",
  "Review Order Arabic": "مراجعة الطلب بالعربي",
  "Place Order English": "اتمام الطلب بالانجليزي",
  "Place Order Arabic": "اتمام الطلب بالعربي",
  "Select Location English": "اختر المنطقة بالانجليزي",
  "Select Location Arabic": "اختر المنطقة بالعربي",
  "Add To Cart English": "اضف للسلة بالانجليزي",
  "Add To Cart Arabic": "اضف للسلة بالعربي",
  "Special Request Product English": "الملاحظات بالانجليزي",
  "Special Request Product Arabic": "الملاحظات بالعربي",
  "Most Selling English": "الاكثر مبيعا بالانجليزي",
  "Most Selling Arabic": "الاكثر مبيعا بالعربي",
  "Newest Products English": "احدث المنتجات بالانجليزي",
  "Newest Products Arabic": "احدث المنتجات بالعربي",
  "Store Busy Message English": "الاشعار بأن المتجر مشغول بالانجليزي",
  "Store Busy Message Arabic": "الاشعار بأن المتجر مشغول بالعربي",
  "Upload Home Picture English": "رفع صورة المنزل بالانجليزي",
  "Upload Home Picture Arabic": "رفع صورة المنزل بالعربي",
  "Select A Variant English": "اختر الصنف بالانجليزي",
  "Select A Variant Arabic": "اختر الصنف بالعربي",
  "Gift Close Button English": "زر اغلاق الهدية بالانجليزي",
  "Gift Close Button Arabic": "زر اغلاق الهدية بالعربي",
  "Time Confirmation Button English": "زر تأكيد الوقت بالانجليزي",
  "Time Confirmation Button Arabic": "زر تأكيد الوقت بالعربي",
  "Delivery Fee Text English": "خدمات التوصيل بالانجليزي",
  "Delivery Fee Text Arabic": "خدمات التوصيل بالعربي",
  "No Waiting List English": "لا يوجد قائمة انتظار بالانجليزي",
  "No Waiting List Arabic": "لا يوجد قائمة انتظار بالعربي",
  "Category Is Private English": "الصنف محمي بالانجليزي",
  "Category Is Private Arabic": "الصنف محمي بالعربي",
  "Bookables Select Date English": "اختر تاريخ الحجوزات بالانجليزي",
  "Bookables Select Date Arabic": "اختر تاريخ الحجوزات بالعربي",
  "Bookables Select Time English": "اختر وقت الحجوزات بالانجليزي",
  "Bookables Select Time Arabic": "اختر وقت الحجوزات بالعربي",
  "Coming By Car English": "قادم بالسيارة بالانجليزي",
  "Coming By Car Arabic": "قادم بالسيارة بالعربي",
  "Pickup Time English": "وقت الاستلام بالانجليزي",
  "Pickup Time Arabic": "وقت الاستلام بالعربي",
  "Delivery Time English": "وقت التوصيل بالانجليزي",
  "Delivery Time Arabic": "وقت التوصيل بالعربي",
  "Pickup English": "استلام بالانجليزي",
  "Pickup Arabic": "استلام بالعربي",
  "Additional Fee English": "رسوم اضافية بالانجليزي",
  "Additional Fee Arabic": "رسوم اضافية بالعربي",
  "Debit Card English": "بطاقة السحب الآلي بالانجليزي",
  "Debit Card Arabic": "بطاقة السحب الآلي العربي",
  "Credit Card English": "بطاقة الائتمان بالانجليزي",
  "Credit Card Arabic": "بطاقة الائتمان بالعربي",
  "Preorder Popup Title English": "عنوان تنبيه الطلب المسبق بالانجليزي",
  "Preorder Popup Title Arabic": "عنوان تنبيه الطلب المسبق بالعربي",
  "Preorder Popup Message English": "نص تنبيه الطلب المسبق بالانجليزي",
  "Preorder Popup Message Arabic": "نص تنبيه الطلب المسبق بالعربي",
  "Out Of Stock English": "منتج غير متوفر بالانجليزي",
  "Out Of Stock Arabic": "منتج غير متوفر بالعربي",
  "Fully Booked English": "محجوز بالكامل بالانجليزي",
  "Fully Booked Arabic": "محجوز بالكامل بالعربي",
  "No Provided Location English": "لا يتوفر عنوان بالانجليزي",
  "No Provided Location Arabic": "لا يتوفر عنوان بالعربي",
  "Earliest Arrival English": "أقرب وصول بالانجليزي",
  "Earliest Arrival Arabic": "أقرب وصول بالعربي",
  "Earliest Pickup English": "أقرب استلام بالانجليزي",
  "Earliest Pickup Arabic": "أقرب استلام بالعربي",
  "Dinesuperb Button English": "زر داين سوبرب بالانجليزي",
  "Dinesuperb Button Arabic": "زر داين سوبرب بالعربي",
  "Special Remarks English": "الملاحظات بالانجليزي",
  "Special Remarks Arabic": "الملاحظات بالعربي",
  "Pay With Knet Machine English": "الدفع مع آلة كي نت بالانجليزي",
  "Pay With Knet Machine Arabic": "الدفع مع آلة كي نت بالعربي",
  "No Cutlery English": "دون ادوات الطعام بالانجليزي",
  "No Cutlery Arabic": "دون ادوات الطعام بالعربي",
  "Whatsapp Message English": "رسالة واتس اب باللغة الإنجليزية",
  "Whatsapp Message Arabic": "رسالة واتس اب باللغة بالعربية",
  "Whatsapp Message Booking English": "رسالة واتس اب الحجز باللغة الإنجليزية",
  "Whatsapp Message Booking Arabic": "رسالة واتس اب الحجز باللغة بالعربية",
  "Dashboard Custom Text": "نصوص صفحة التحكم",
  "Please use the following keywords for editing the whatsapp messages if needed :-":
    "يرجى استخدام الكلمات الأساسية التالية لتعديل رسائل واتس اب إذا لزم الأمر: -",
  "Whatsapp Message": "رسالة واتس اب",
  "Customer Name": "اسم العميل",
  "Order Tracking Code": "كود تتبع الطلب",
  "Site Domain": "موقع الكتروني",
  "Store Name": "اسم المتجر",
  "Product Name": "اسم المنتج",
  "Booking Date": "تاريخ الحجز",
  "Booking Time": "وقت الحجز",
  "See Shortcodes": "الرموز المختصرة",
  Shortcodes: "الرموز المختصرة",
  "Menu English": "القائمة بالانجليزي",
  "Menu Arabic": "القائمة بالعربي",
  "Go to checkout English": "الذهاب الى الدفع بالإنجليزي",
  "Go to checkout Arabic": "الذهاب الى الدفع بالعربي",
  "Bookables Morning Shift English":
    "اوقات الحجوزات الفترة الصباحية بالانجليزي",
  "Bookables Morning Shift Arabic": "اوقات الحجوزات الفترة الصباحية بالعربي",
  "Bookables Night Shift English": "اوقات الحجوزات الفترة المسائية بالانجليزي",
  "Bookables Night Shift Arabic": "اوقات الحجوزات الفترة المسائية بالعربي",
};

export default trans;
