const translations = {
  "Production slots": "ساعات العمل",
  "Delivery slots": "ساعات التوصيل",
  "Locations settings": "اعدادت المتجر",
  "Store is busy?": "المتجر مشغول؟",

  "Orders Limits": "حدود الطلبات",
  Limits: "الحدود",
  "New Order Limit": "إضافة حد",
  "New Order Limits": "إضافة حدود",
  "Select the days that you want to set a limit to":
    "إختر الأيام التي تريد ان تضيف حد لها",
  "Limits created successfully": "تم إنشاء الحدود بنجاح",
  "Limit deleted successfully": "تم حذف الحد بنجاح",
  "Limit toggled successfully": "تم تغير حالة الحد بنجاح",
  "Limits deleted successfully": "تم حذف الحدود بنجاح",
  "Limits toggled successfully": "تم تغير حالة الحدود بنجاح",
  "Limit changed successfully": "تم تعديل الحد بنجاح",
  "limits selected": "حدود محددة",
  "Branch Orders limits": "حدود طلبات الأفرع",
  "The end must be greater than the start":
    "نهاية الفترة يجب ان تكون اكبر من بدايتها",
  "Maximum number of orders": "العدد الأقصى للطلبات",
  "No Maximum": "لا يوجد",
  "View closure history": "عرض سجل الاغلاق",
  "Closure log": "سجل الاغلاق",
  "Changed at": "تغير في",
  Difference: "الفرق",
  "Old value": "القيمة القديمة",
  "New value": "القيمة الجديدة",
  "By user": "المستخدم",
  ON: "مفعل",
  OFF: "معطل",
  Activation: "التفعيل",
  Midnight: "00:00",
  Noon: "12:00 م",
  "Show advanced controls": "عرض التحكم المتقدم",
  "Hide advanced controls": "إخفاء التحكم المتقدم",
  every: "كل",
  "Split slots by": "تقسيم الساعات",
  "Don't split": "بدون تقسيم",
  "Custom ": "مخصص",
  Custom: "مخصص",

  "60 minutes": "60 دقيقة",
  "90 minutes": "90 دقيقة",
  "120 minutes": "120 دقيقة",
  "180 minutes": "180 دقيقة",
  "You don't have any slots. Add slots by clicking on the New button":
    "لا يوجد لديك ساعات للعمل. اضف ساعات بالضغط على زر إضافة",
  edit: "تعديل",
  "Working hours": "ساعات العمل",
  Preset: "اعداد",
  "Save as preset": "حفظ كاعداد",
  "Save preset": "حفظ الاعداد",
  "Create new preset": "إنشاء اعداد جديد",
  "Override existing preset": "تعديل على اعداد موجود",
  "Preset name": "اسم الاعداد",
  "Select a preset": "اختر اعداد",
  "a preset already exists with this name": "يوجد اعداد بنفس الاسم",
  "Switched preset successfully": "تم تغير الاعداد بنجاح",
  "Preset created successfully": "تم إنشاء الاعداد بنجاح",
  "Preset edited successfully": "تم تعديل الاعداد بنجاح",
  "Deleted preset successfully": "تم حذف الاعداد بنجاح",
  // Hours: "ساعات",
  Work: "العمل",
  "Delivery ": "التوصيل",
};

export default translations;
