import {
  FETCH_DELIVERY_RATES_AREAS,
  FETCH_DELIVERY_RATES_AREAS_LOADING,
  TOGGLE_DELIVERY_CHARGE,
  DELETE_DELIVERY_CHARGE,
  CHANGE_DELIVERY_CHARGE_PRICE,
  ADD_AREA,
  ADD_PROVINCE,
  DELIVERY_CHARGES_BULK_CONTROL,
  DELIVERY_CHARGES_BULK_DELETE,
  CHANGE_DELIVERY_CONFIG,
  FETCH_ZONES_LOADING,
  FETCH_ZONES,
  CHANGE_AREA_OCIMS_ID,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchDeliveryRatesAndAreas = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DELIVERY_RATES_AREAS_LOADING });
    try {
      let res = await instance.get("/fetch_delivery_rates_areas/");
      if (res.data.success) {
        dispatch({ type: FETCH_DELIVERY_RATES_AREAS, payload: res.data });
      } else
        message.error(
          "Could not load areas. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to view areas and rates");
      else
        message.error(
          "Could not load areas and rates. Please try again or contact us for help"
        );
    }
  };
};

export const toggleDeliveryCharge = (id) => {
  return {
    type: TOGGLE_DELIVERY_CHARGE,
    payload: id,
  };
};

export const deleteDeliveryCharge = (id) => {
  return {
    type: DELETE_DELIVERY_CHARGE,
    payload: id,
  };
};

export const changeDeliveryChargePrice = (id, value) => {
  return {
    type: CHANGE_DELIVERY_CHARGE_PRICE,
    payload: { id: id, value: value },
  };
};

export const addArea = (area) => {
  return {
    type: ADD_AREA,
    payload: area,
  };
};

export const addProvince = (province) => {
  return {
    type: ADD_PROVINCE,
    payload: province,
  };
};

export const deliveryChargesBulkControl = (ids, status, price) => {
  return {
    type: DELIVERY_CHARGES_BULK_CONTROL,
    payload: { ids: ids, status: status, price: price },
  };
};

export const deliveryChargesBulkDelete = (ids) => {
  return {
    type: DELIVERY_CHARGES_BULK_DELETE,
    payload: ids,
  };
};

export const changeDeliveryConfigurations = (field, value) => {
  return {
    type: CHANGE_DELIVERY_CONFIG,
    payload: { field: field, value: value },
  };
};

export const fetchZones = (skipLoading) => {
  return async (dispatch) => {
    if (!skipLoading) dispatch({ type: FETCH_ZONES_LOADING });
    try {
      let res = await instance.post("/fetch_zones/");

      if (res.data.success) {
        dispatch({
          type: FETCH_ZONES,
          payload: res.data,
        });
      } else
        message.error(
          "Could not load zones. Please try again or contact us for help"
        );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You do not have permission to do that");
      else
        message.error(
          "Could not load zones. Please try again or contact us for help"
        );
    }
  };
};

export const changeAreaOcimsID = (id, value, type) => {
  return {
    type: CHANGE_AREA_OCIMS_ID,
    payload: { id: id, value: value, type: type },
  };
};

export const changeProvinceSortOrder=(provinces,t)=>{
  return async (dispatch)=>{
    try {

        await instance.patch("/change_province_sort_order/",{provinces})
      dispatch({type:"CHANGE_PROVINCE_SORT_ORDER",
        payload:provinces
      })
      message.success(t("Saved successfully"))
    }catch (err) {
       if (err.response && err.response.status === 403){
         message.error("You do not have permission to do that");
       }
      else {message.error(t("Something went wrong"));
       }
    }
  }
}
