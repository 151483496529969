import { FETCH_USER_PROFILE } from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const updateUserProfile = (profile) => {
  return {
    type: FETCH_USER_PROFILE,
    payload: profile,
  };
};

export const fetchUserProfile = () => {
  return async (dispatch) => {
    try {
      let res = await instance.get("/fetch_user_profile/");
      if (res.data.success) {
        dispatch(updateUserProfile(res.data.data));
      } else
        message.error(
          "Could not fetch your profile. Please refresh or contact us for help"
        );
    } catch (err) {
      message.error(
        "Could not fetch your profile. Please refresh or contact us for help"
      );
    }
  };
};
