import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import {
  Modal,
  Table,
  Row,
  Col,
  Input,
} from "antd";

//Actions

const { Column } = Table;
const { Search } = Input;

class CustomModal extends Component {
  state = {
    loading: true,
    customers: this.props.customer_list,
    selectedRowKeys: this.props.selectedCustomers,
    selectedRowKeysNumbers: this.props.selectedCustomersNumbers,
    search: "",
  };

  onSelectChange = (selectedRowKeys) => {
    const { t } = this.props;
    const { customers } = this.state;

    const selectedCustomers = customers.filter((customer) =>
      selectedRowKeys.includes(customer.customer_id)
    );
    const selectedRowKeysNumbers = selectedCustomers.map(
      (customer) => customer.phone
    );

    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRowKeysNumbers: [...new Set(selectedRowKeysNumbers)],
    });
  };

  render() {
    const { visible, config, t, settings } = this.props;
    const {
      customers,
      loading,
      selectedRowKeys,
      selectedRowKeysNumbers,
      search,
      sendTargetedEmails,
      targetedEmailBody,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    let data = [];
    customers
      .filter((customer) => {
        return customer.phone;
      })
      .forEach((customer) => {
        if (
          customer.customer_name.toLowerCase().includes(search.toLowerCase()) ||
          customer.phone.includes(search)
        ) {
          data.push({ ...customer, key: customer.customer_id });
        }
      });

    return (
      <Modal
        style={{ top: 20 }}
        width={1200}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Customers")}
          </div>
        }
        visible={visible}
        onCancel={() => {
          this.props.onClose(false);
        }}
        onOk={() => {
          this.props.onClose(true, selectedRowKeys, selectedRowKeysNumbers);
        }}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="mb-4">
          <Row className="mb-2" justify={"space-between"}>
            <Col style={{ fontSize: 16 }} className="px-1"></Col>
            <Col style={{ fontSize: 16 }}>
              {`${selectedRowKeys.length} / ${data.length} ${t("Selected")}`}
              {selectedRowKeys.length > 0 && (
                <>
                  <span className="mx-2">-</span>
                  <span
                    style={{
                      color: "blue",
                      fontSize: "0.8rem",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => this.setState({ selectedRowKeys: [] })}
                  >
                    {t("clear selection")}
                  </span>
                </>
              )}
            </Col>
          </Row>
          <Search
            placeholder={t("Search for a user")}
            className="mt-1 mb-3"
            enterButton
            onChange={(e) => this.setState({ search: e.target.value })}
          />

          <Table
            dataSource={data}
            size="small"
            rowSelection={rowSelection}
            rowClassName={() => "text-center"}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
          >
            <Column
              title={t("Segment")}
              key="segment"
              dataIndex="segment"
              render={(text) => (
                <div>{t(text.charAt(0).toUpperCase() + text.slice(1))}</div>
              )}
              filters={[
                "New",
                "Best",
                "Spenders",
                "Loyal",
                "Risk",
                "Frugal",
                "Lost",
                "Other",
              ].map((segment) => {
                return { text: t(segment), value: segment.toLowerCase() };
              })}
              onFilter={(value, record) => record.segment === value}
            />
            <Column
              title={t("Name")}
              key="customer_name"
              dataIndex="customer_name"
            />
            <Column title={t("Phone")} key="phone" dataIndex="phone" />
            <Column
              sorter={{
                compare: (a, b) => a.days - b.days,
              }}
              title={t("Days Since Last Order")}
              key="days"
              dataIndex="days"
            />
            <Column
              sorter={{
                compare: (a, b) => a.purchases - b.purchases,
              }}
              title={t("Number Of Orders")}
              key="purchases"
              dataIndex="purchases"
            />
            <Column
              title={t("Spendings")}
              key="spend"
              dataIndex="spend"
              render={(text, record) => (
                <div>
                  {text ? text.toFixed(settings.currency_decimals) : "-"}{" "}
                  {settings.currency}
                </div>
              )}
              sorter={{
                compare: (a, b) => a.spend - b.spend,
              }}
            />
          </Table>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    customer_list: state.reports.rfmData?.combined_customers_list,
  };
};

export default connect(mapStateToProps)(withNamespaces()(CustomModal));
