import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
import moment from "moment";
// Antd components
import {
  Button,
  Tabs,
  message,
  Modal,
  Spin,
  Input,
  Table,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Column } = Table;
const { RangePicker } = DatePicker;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class OrderHistory extends Component {
  state = {
    loading: false,
    log: [],
  };

  fetchLog = async () => {
    this.setState({ loading: true });
    const { t, order } = this.props;

    try {
      const res = await instance.post(`/fetch_order_log_history/`, {
        id: order,
      });
      if (res.data.success == true) {
        this.setState({ log: res.data.log });
      } else {
        message.error(t("Couldn't load the log"));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
    this.setState({ loading: false });
  };

  componentDidMount = () => {
    const { permissions } = this.props;
    if (!permissions.can_view_audit_logs) return;
    this.fetchLog();
  };

  renderFromToField = (value) => {
    const { t } = this.props;
    if (value === true)
      return <span style={{ color: "green" }}>{t("PaymentComplete")}</span>;
    else if (value === false)
      return <span style={{ color: "red" }}>{t("PaymentIncomplete")}</span>;
    if (value == "") value = "-";
    return <span>{t(value)}</span>;
  };

  renderFieldName = (value, related_object) => {
    const { t, config } = this.props;

    const values = {
      status: t("Status"),
      created: t("Received"),
      dispatched: t("Dispatched"),
      approved: t("Approved"),
      payment_complete: t("Payment Status"),
      customer: t("Customer"),
      delivery_address: t("Delivery Address"),
      pickup_address: t("Pickup Address"),
      branch: t("Branch"),
      order_type: t("Order Type"),
      special_remarks: t("Special Remarks"),
      rate: t("Delivery Charge"),
      expected_date: t("Expected Date"),
      printing_invoice: t("Invoice Printed by"),

      payment_method: t("Payment Type"),

      change_quantity: `${t("Quantity for Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      change_option_quantity: `${t("Quantity for Option")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      ignore_inventory: `${t("Ignore Inventory for Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      booking_slot: `${t("Booking Slot for Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      product_price: `${t("Price of Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      product_special_remarks: `${t("Special Remarks for Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      new_product: `${t("Added Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      new_option: `${t("Added Option")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      new_extra_field: `${t("Added Extra Field")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      new_discount: `${t("Added Discount")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      delete_product: `${t("Deleted Product")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      delete_option: `${t("Deleted Option")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      delete_extra_field: `${t("Deleted Extra Field")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      delete_discount: `${t("Deleted Discount")} ${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
      change_extra_field: `${
        (config.language === "arabic" && related_object?.ar_name) ||
        related_object?.name
      }`,
    };
    return values[value];
  };

  render() {
    const { t, config, permissions } = this.props;
    if (!permissions.can_view_audit_logs) return;

    const columnAlignment = "center";

    const data = this.state.log.map((obj, index) => ({ key: index, obj: obj }));
    return (
      <div>
        <Modal
          width={800}
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Order log")}
            </div>
          }
          onCancel={() => this.props.onClose()}
          cancelText={t("Close")}
          visible={true}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            style: { display: "none" },
          }}
        >
          <div className="mx-2 my-3">
            {this.state.loading ? (
              <div className="mt-3 text-center">
                <Spin indicator={antIcon} className="mx-2" />
              </div>
            ) : (
              <Table dataSource={data} size="small" scroll={{ x: 500 }}>
                <Column
                  onHeaderCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  onCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  title={t("Changed at")}
                  key="dateNtime"
                  render={(text, record) => (
                    <span>
                      {moment(record.obj.date, "YYYY-MM-DD HH:mm:ss").format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </span>
                  )}
                  sorter={{
                    compare: (a, b) =>
                      moment(a.obj.date, "YYYY-MM-DD HH:mm") -
                      moment(b.obj.date, "YYYY-MM-DD HH:mm"),
                  }}
                  sortDirections={["descend", "ascend"]}
                />
                <Column
                  onHeaderCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  onCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  title={t("Difference")}
                  key="field"
                  render={(text, record) => (
                    <span>
                      {this.renderFieldName(record.obj.field, {
                        name: record.obj.related_object,
                        ar_name: record.obj.related_object_ar,
                      })}
                    </span>
                  )}
                />
                <Column
                  onHeaderCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  onCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  title={t("Old value")}
                  key="from"
                  render={(text, record) => (
                    <div>{this.renderFromToField(record.obj.from)}</div>
                  )}
                />
                <Column
                  onHeaderCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  onCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  title={t("New value")}
                  key="to"
                  render={(text, record) => (
                    <div>{this.renderFromToField(record.obj.to)}</div>
                  )}
                />
                <Column
                  onHeaderCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  onCell={() => {
                    return {
                      style: { textAlign: columnAlignment },
                    };
                  }}
                  title={t("By user")}
                  key="user"
                  render={(text, record) => <span>{record.obj.user}</span>}
                />
              </Table>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OrderHistory));
