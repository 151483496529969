import React from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";

const DIV_WIDTH = 170;
const AramexShipmentDetail = ({
  aramexShipmentInfo,
  t,
  branches,
  settings,
}) => {
  const {
    shipment_id,
    pickup_id,
    reference_id,
    pickup_from_branch_id,
    pickup_datetime,
    origin_city,
    destination_city,
    origin_code,
    destination_code,
    currency_code,
    shipping_charge,
  } = aramexShipmentInfo;

  const fromBranch = branches.filter(
    (branch) => branch.id === pickup_from_branch_id
  );

  return (
    <div>
      <p
        style={{
          fontWeight: "bold",
          width: DIV_WIDTH,
          display: "inline-block",
        }}
      >
        {t("Shipment Details")}
      </p>
      <p className="mb-0">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Shipment ID:")}
        </span>
        {shipment_id}
      </p>
      <p className="mb-0">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Pickup ID:")}
        </span>
        {pickup_id}
      </p>
      <p className="">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Reference ID:")}
        </span>
        {reference_id}
      </p>
      <br />
      <p className="mb-0">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Pickup Branch:")}
        </span>
        {fromBranch[0].name}
      </p>
      <p className="">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Pickup Date:")}
        </span>
        <span
          style={{
            textDecoration: "underline",
          }}
        >
          {moment(pickup_datetime).format("MMM Do, YYYY ")}{" "}
          {`(${moment(pickup_datetime, "").format("hh:mm A")} )`}
        </span>
      </p>
      <br />
      <p className="mb-0">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("From:")}
        </span>
        {origin_city}
      </p>
      <p className="">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Country Code:")}
        </span>
        {origin_code}
      </p>
      <br />
      <p className="mb-0">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("To:")}
        </span>
        {destination_city}
      </p>
      <p className="">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Country Code:")}
        </span>
        {destination_code}
      </p>
      <br />
      <p className="mb-0">
        <span
          style={{
            fontWeight: "bold",
            width: DIV_WIDTH,
            display: "inline-block",
          }}
        >
          {t("Shipping charge:")}
        </span>
        {shipping_charge.toFixed(settings.currency_decimals)} {currency_code}
      </p>
      {/*<p className="mb-0">*/}
      {/*  <span*/}
      {/*    style={{*/}
      {/*      fontWeight: "bold",*/}
      {/*      width: DIV_WIDTH,*/}
      {/*      display: "inline-block",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {t("Chargeable Weight:")}*/}
      {/*  </span>*/}
      {/*  {chargeable_weight} {chargeable_weight_unit}*/}
      {/*</p>*/}
    </div>
  );
};

export default withNamespaces()(AramexShipmentDetail);
