import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { phoneCodes } from "../Locations/phoneCodes";

// Antd components
import { Modal, Input, Select, Button, Row, Col, Checkbox, Switch } from "antd";

// Actions
import { setGiftPOS } from "../../store/actions";
import instance from "../../store/actions/instance";

const { Option } = Select;

function findDefaultPhoneCode(baseCountry) {
  const gccCodes = {
    Bahrain: "+973",
    Qatar: "+974",
    "Saudi Arabia": "+966",
    UAE: "+971",
    Oman: "+968",
    Jordan: "+962",
  };
  return gccCodes[baseCountry] || "+965";
}
class GiftModal extends Component {
  constructor(props) {
    super(props);
    let state = {
      gift_name: "",
      gift_message: "",
      gift_recipient_number: null,
      gift_link: "",
      send_gift_anonymously: false,
      call_recipient: false,
      is_gift: false,
      phoneCode: findDefaultPhoneCode(this.props.baseCountry),
      phoneNumber: null,
      gift_wrapping: false,
    };

    this.state = props.gift ? props.gift : state;
  }

  saveGift = async () => {
    this.props.setGiftPOS({
      ...this.state,
      gift_recipient_number:
        this.state.phoneCode && this.state.phoneNumber
          ? `${this.state.phoneCode}-${this.state.phoneNumber}`
          : null,
    });
    this.props.onClose();
  };

  phoneSelect = React.createRef();
  render() {
    const { t, config, visible, settings } = this.props;
    const {
      gift_name,
      gift_message,
      gift_link,
      send_gift_anonymously,
      call_recipient,
      phoneCode,
      phoneNumber,
      is_gift,
      gift_wrapping,
    } = this.state;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Gift Details")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            className="posActionButton"
            block
            key="submit"
            type="primary"
            onClick={() => this.saveGift()}
          >
            {t("Ok")}
          </Button>,
        ]}
        onOk={() => this.saveGift()}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="px-2 my-3">
          <>
            <div className="mt-1">
              <Switch
                defaultChecked={is_gift}
                onChange={(v) => this.setState({ is_gift: v })}
              />{" "}
              <span className="mx-3">{t("Is this a gift?")}</span>
            </div>
            {is_gift && (
              <>
                <div className="mt-3">
                  <p>{t("Receiver name")}</p>
                  <Input
                    defaultValue={gift_name}
                    placeholder={t("Receiver name")}
                    onChange={(e) =>
                      this.setState({
                        gift_name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="mt-3  mb-4 ">
                  <p>{t("Receiver phone")}</p>
                  <Row gutter={15}>
                    <Col span={8}>
                      <Select
                        ref={this.phoneSelect}
                        showSearch
                        className="w-100"
                        placeholder={t("Select a country")}
                        defaultValue={phoneCode.replace("+", "")}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          const select = this.phoneSelect.current;
                          if (select) {
                            select.blur();
                          }
                          this.setState({ phoneCode: "+" + e });
                        }}
                      >
                        {phoneCodes.map((code) => (
                          <Option
                            key={code.code}
                            value={code.code.replace("+", "")}
                          >
                            {`${code.name} ${code.code}`}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={16}>
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="w-100"
                        defaultValue={phoneNumber}
                        placeholder={t("Phone Number")}
                        onChange={(e) =>
                          this.setState({ phoneNumber: e.target.value.trim() })
                        }
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mt-3">
                  <Checkbox
                    defaultChecked={call_recipient}
                    onChange={(e) =>
                      this.setState({ call_recipient: e.target.checked })
                    }
                  >
                    {t("Call recipient on arrival")}
                  </Checkbox>
                </div>
                {settings.enable_send_gift_anonymously &&
                  settings.enable_send_gift_anonymously_staff && (
                    <div className="mt-3">
                      <Checkbox
                        defaultChecked={send_gift_anonymously}
                        onChange={(e) =>
                          this.setState({
                            send_gift_anonymously: e.target.checked,
                          })
                        }
                      >
                        {t("Send gift anonymously")}
                      </Checkbox>
                    </div>
                  )}
                {settings.enable_gift_wrapping && (
                  <div className="mt-3">
                    <Checkbox
                      defaultChecked={gift_wrapping}
                      onChange={(e) =>
                        this.setState({
                          gift_wrapping: e.target.checked,
                        })
                      }
                    >
                      {t("Gift wrapping")}{" "}
                      {settings.gift_wrapping_price > 0 && (
                        <span
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          (
                          {config.direction == "rtl"
                            ? ""
                            : `${settings.gift_wrapping_price.toFixed(
                                settings.currency_decimals
                              )} ${settings.currency}`}
                          )
                        </span>
                      )}
                    </Checkbox>
                  </div>
                )}

                <div className="mt-3">
                  <p>{t("Gift message")}</p>
                  <Input
                    defaultValue={gift_message}
                    placeholder={t("Gift message")}
                    onChange={(e) =>
                      this.setState({ gift_message: e.target.value })
                    }
                  />
                </div>
                <div className="mt-3">
                  <p>{t("Gift link")}</p>
                  <Input
                    defaultValue={gift_link}
                    placeholder={t("Gift link")}
                    onChange={(e) =>
                      this.setState({ gift_link: e.target.value })
                    }
                  />
                </div>
              </>
            )}
          </>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    customers: state.customers.customers,
    permissions: state.auth.permissions,
    baseCountry: state.auth.settings.base_country,
    cart: state.pos.cart,
    settings: state.auth.settings,
    tables: state.pos.tables,
    address: state.pos.address,
    customer: state.pos.customer,
    gift: state.pos.gift,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGiftPOS: (params) => dispatch(setGiftPOS(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(GiftModal));
