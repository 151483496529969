import {
  FETCH_CUSTOMERS,
  SET_CUSTOMERS_FETCH_LOADING,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  FETCH_USERS,
  SET_CUSTOMER_WALLET,
  SET_CUSTOMER_POINTS,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";
import i18next from "i18next";

export const fetchCustomers = (t) => {
  return async (dispatch) => {
    dispatch({ type: SET_CUSTOMERS_FETCH_LOADING, payload: true });
    try {
      let page = 1;
      let res;
      let numberOfPages = 0; //Any initial value
      do {
        res = await instance.post("/get_customers/", {
          page: page,
        });
        const customers = res.data;
        numberOfPages = customers.page_count;
        page += 1;
        dispatch({
          type: FETCH_CUSTOMERS,
          payload: customers.customers_list,
        });
      } while (page <= numberOfPages);
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
    dispatch({ type: SET_CUSTOMERS_FETCH_LOADING, paylod: false });
  };
};

export const createNewCustomer = (customer) => {
  return {
    type: CREATE_CUSTOMER,
    payload: customer,
  };
};

export const updateCustomer = (params) => {
  return {
    type: UPDATE_CUSTOMER,
    payload: params,
  };
};

export const fetchUsers = (t) => {
  return async (dispatch) => {
    dispatch({ type: SET_CUSTOMERS_FETCH_LOADING });
    try {
      let res = await instance.get(`/get_users/`);
      const users = res.data;
      dispatch({
        type: FETCH_USERS,
        payload: users,
      });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};
export const fetchUserFavoriteProduct = (selectedUserId) => {
  return async (dispatch) => {
    try {
      let res = await instance.post(`/fetch_favorite_product_db/`, {
        selectedUserId: selectedUserId,
      });
      dispatch({
        type: "FETCH_USER_FAVORITE_PRODUCTS",
        payload: {
          data: res.data.data,
          selectedUserId: selectedUserId,
        },
      });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(i18next.t("AccessDeniedMessage"));
      else message.error(i18next.t("SomethingWentWrong"));
    }
  };
};

export const setCustomerWallet = (payload) => {
  return {
    type: SET_CUSTOMER_WALLET,
    payload: payload,
  };
};

export const setCustomerPoints = (payload) => {
  return {
    type: SET_CUSTOMER_POINTS,
    payload: payload,
  };
};
export const setCustomerHistoryPoints = (payload) => {
  return {
    type: "SET_CUSTOMER_POINTS_HISTORY",
    payload: payload,
  };
};
