import React, { useState } from "react";
import axios from "axios";
import sad from "./assets/sad-face.png";
import happy from "./assets/happy-face.png";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import instance from "../../store/actions/instance";
import { message } from "antd";
import { updateUserProfile } from "../../store/actions/profile";

function Nps(props) {
  const [closed, setClosed] = useState(false);
  const { t } = props;

  const handleClick = async (rating) => {
    const { t } = props;

    try {
      setClosed(true);

      const res = await instance.post("/nps-rating/", {
        nps_score: rating,
        nps_session_id: props.auth.last_session_id,
      });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }

    if (rating !== 0) {
      message.success("Thank you for your feedback!");
    }
  };

  return (
    <div>
      {props.auth.display_nps && !closed ? (
        <div className="NPSContainer">
          <div className="NPSInput animated ">
            <button className="NPSInput-Close" onClick={() => handleClick(0)}>
              ✕
            </button>
            <div className="NPSInput-Inner">
              <p className="NPSInput-Message">
                {t("How likely are you to recommend ordable/ to a friend?")}
              </p>
              <div className="NPSScale">
                <div className="NPSScale-Values">
                  {[...Array(10).keys()].map((i) => (
                    <div
                      key={i + 1}
                      className={`NPSScale-Value`}
                      onClick={() => handleClick(i + 1)}
                    >
                      <div>{i + 1}</div>
                    </div>
                  ))}
                </div>
                <div className="NPSScale-Legend">
                  <div className="NPSScale-Label left">
                    <img src={sad} alt="sad face" />
                    <div>{t("Not at all likely")}</div>
                  </div>
                  <div className="NPSScale-Label right">
                    <img src={happy} alt="happy face" />
                    <div>{t("Extremely likely")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (profile) => dispatch(updateUserProfile(profile)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(Nps)));
