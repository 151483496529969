import messages from "./messages";
const trans = {
  ...messages,
  "Add-Ons": "الإضافات",
  "Installed Add-Ons": "الإضافات المفعلة",
  "Add-on": "الإضافات",
  "Search for an add-on": "ابحث عن اضافة",
  Month: "شهريا",
  Year: "سنويا",
  Free: "مجانا",
  Install: "تفعيل",
  Uninstall: "تعطيل",
  "Contact us": "تواصل معنا",
  Installed: "مفعل",
  "Other Addons": "إضافات أخرى",
  Buy: "شراء",
  Overview: "وصف",
  "Add extra branches": "إضافة أفرع إضافية",
  "Reduce extra branches": "ازالة أفرع إضافية",
  "Extra branches you have purchased": "أفرع إضافية قمت بشرائها",
  "Available branches": "الأفرع المتاحة",
  "Used branches from extra branches": "الأفرع المستخدمة من الأفرع الإضافية",
  "Used branches": "الأفرع المستخدمة",
  "How many branches would you like to add?":
    "كم عدد الأفرع التي تريد إضافتها؟",
  "How many branches would you like to reduce?":
    "كم عدد الأفرع التي تريد ازالتها؟",
  Done: "إتمام",
  Reduce: "إزالة",
  max: "كحد أقصى",
  Channels: "المنصات",
  "Dashboard Power-Ups": "",
  "Additional Product Types": "انواع منتجات اضافية",
  "International Selling": "البيع الدولي",
  "Integrations: Payments": "ربط الدفع",
  "Integrations: POS & Inventory": "ربط نقاط بيع وخدمات ادارة المخزون",
  "Integrations: Delivery": "ربط التوصيل",
  "Dashboard Power-Ups": "اضافات شاشة التحكم",
  "Are you sure you want to uninstall ***? You will not be refunded and you will not be able to turn it back on unless you install it again. Are you sure you want to continue?":
    "هل انت متأكد من تعطيل ***؟ لن تتمكن من استرجاع نقودك أو اعادة تشغيل الميزة الا عند تفعيلها مرة اخرى. هل تريد الاتمام؟",
  "Your transaction was not captured": "فشلت عملت الدفع",
  "You were not charged, but you can try again at any time":
    "لم يخصم من حسابك، لكن تستطيع المحاولة مرة اخرى",
  "Your payment was successful!": "تمت عملية الدفع بنجاح!",
  "*** has been activated until ---": "تم تفعيل *** لغاية ---",
  "You've successfully purchased 1000 points for SMS usage":
    "لقد قمت بشراء الف نقطة للرسائل النصية",
  "To view your bill, you can visit the *** section":
    "لعرض فاتورتك، يمكنك زيارة قسم ***",

  "Don't have an account? Register now": "ليس لديك حساب؟ سجل الآن",
  "Sign up with UPay": "قم بالتسجيل مع UPay",
  "Tap Registration": "تسجيل Tap",
  "Your business type": "نوعية الشركة",
  "Required documents": "الملفات المطلوبة",
  "Legal name in English": "اسم الشركة القانوني بالانجليزي",
  "Legal name in Arabic": "اسم الشركة القانوني بالعربي",
  "IBAN number": "رقم الايبان",
  Register: "سجل",
  "Should be the same as the bank account name": "يجب ان يطابق اسم حساب البنك",
  "Should be the same as the bank account Arabic name":
    "يجب ان يطابق اسم حساب البنك بالعربي",
  Individual: "شخصي",
  Corporation: "شركة",
  "Civil ID (front & back) for the authorized person":
    "صورة من البطاقة المدنية (امام/خلف) للشخص المفوض",
  "Bank Certificate": "شهادة تحويل جميع متحصلات عمليات الدفع الإلكتروني",
  "Commercial License": "صورة من الرخصة/السجل التجاري",
  "Authorized Signature": "إعتماد التوقيع",
  "Virtual Registration/Freelance Certificate": "سجلي او شهادة عمل حر",
  "PDPL consent Signature":
    "توقيع الموافقة على قانون حماية البيانات الشخصية من PDPL",
  "License number": "رقم الرخصة التجارية",
  "Allowed file formats: CSV, DOCX, GIF, JPEG, PDF, PNG, XLS, XLSX. Must be 1 MB or less":
    "نوعية الملفات المقبولة: CSV, DOCX, GIF, JPEG, PDF, PNG, XLS, XLSX. يجب ان لا يتجاوز 1 ميجابايت",
  "National ID (front copy)": "الهوية الوطنية (صورة امامية)",
  "National ID for authorized person (front copy)":
    "الهوية الوطنية/الاقامة للشخص المفوض (صورة امامية)",
  "VAT Registration Certificate": "شهادة التسجيل الضريبي",
  "Freelance Certificate": "شهادة عمل حر",
  "Your registration request was submitted successfully! We'll notify you know once it's done":
    "تم ارسال الطلب بنجاح. سيتم اشعارك عند الانتهاء",
  "Registered with Tap successfully. You can view your details in payment settings":
    "تم التسجيل مع Tap بنجاح. يمكنك عرض بياناتك من اعدادات الدفع",
  "Tap registration failed. Please try again or contact us for help":
    "فشل التسجيل مع Tap. الرجاء المحاولة مرة اخرى او تواصل معنا للدعم",
  "You can only sign up once": "يمكنك التسجيل مرة واحدة فقط",
  "Congratulations! You have successfully registered with Tap":
    "تم تسجيلك مع Tap بنجاح!",
  "You will be contacted by Tap once your application is approved or if they have any issues with the documents uploaded":
    "ستقوم Tap بالتواصل معك في حال وجود متطلبات اخرى",
  "In the meantime, you can optionally start receiving orders on your website. Please note your payments will not be deposited into your account until your registration is approved":
    "الى ان يتم التحقق من بياناتك، يمكنك المباشرة بالبيع ولكن لن يتم تحصيل مستحقاتك حتى يتم الموافقة على حسابك",
  "Go to Payment Settings": "الذهاب الى اعدادات الدفع",
  "Something went wrong while processing your request. Please try again or contact us for help":
    "حدث خطأ ما عند معالجة طلبك. الرجاء المحاولة مرة اخرى، أو تواصلنا معنا للمساعدة",
  "Please wait while we submit your request to Tap...":
    "الرجاء الانتظار بينما نقوم بارسال طلبك الى Tap...",
  "This should take about 30 seconds": "تستغرق العملية حوالي 30 ثانية",
  Click: "اضغط",
  "for more info on the requirements": "للمزيد من المعلومات عن المتطلبات",
  "You can view your registration status in the Payment Settings page":
    "يمكنك مراجعة حالة حسابك من صفحة اعدادات الدفع",
  "Check your IBAN": "تحقق من الايبان",
  "Congratulations!": "مبروك!",
  "You have successfully registered with Tap": "تم التسجيل مع Tap بنجاح",
  "What's next?": "الخطوات القادمة:",
  "You can start receiving orders now (optional)":
    "يمكنك استقبال عمليات الدفع الآن (اختياري)",

  "Tap will contact you to approve your account":
    "ستتواصل معك Tap عند الموافقة على طلبك",
  "Payments will be held with Tap until your account is approved":
    "سيتم حجز مستحقاتك مع Tap الى ان يتم الموافقة",
  "You can check approval status on your Payment Settings page":
    "يمكنك التحقق من حالة التحقق بصفحة اعدادات الدفع",
  "Happy selling!": "بيع سعيد!",
  "Tap is reviewing your account. You can optionally start selling but amounts will be held with Tap until your account is approved":
    "حسابك قيد المراجعة من Tap. يمكنك بدء استقبال عمليات الدفع من الآن (اختياري) ولكن مستحقاتك ستبقى محجوزة عند Tap حتى تتم الموافقة",
  "Business name in English": "اسم الشركة بالانجليزي",
  "Business name in Arabic": "اسم الشركة بالعربي",
  "Business Email": "ايميل الشركة",
  "Pay *** ** now": "ادفع *** ** الآن",
  "Add extra businesses": "إضافة شركات إضافية",
  "Reduce extra businesses": "ازالة شركات إضافية",
  "Extra businesses you have purchased": "شركات إضافية قمت بشرائها",
  "Used businesses from extra businesses":
    "الشركات المستخدمة من الشركات الإضافية",
  "How many businesses would you like to reduce?":
    "كم عدد الشركات التي تريد ازالتها؟",
  "How many businesses would you like to add?":
    "كم عدد الشركات التي تريد إضافتها؟",
  "Available businesses": "الشركات المتاحة",
  "Used businesses": "الشركات المستخدمة",
};
export default trans;
