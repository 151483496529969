import axios from "axios";
import axiosRetry from "axios-retry";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const instance = axios.create({
  baseURL: "/",
});
axiosRetry(instance, {
  retries: 3,
  retryDelay: () => {
    return 5000;
  },
});
export default instance;
