import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { GetEnglishOrArabic } from "..";

// Antd Components
import { Card, Button, message } from "antd";
import { InfoOutlined, EllipsisOutlined } from "@ant-design/icons";
//My Components
import LazyLoad from "react-lazyload";
//Actions
import { addProductToCart, updateUsedComponents } from "../../../store/actions";
import {
  reduceComponentsInventories,
  reduceCompositeInventories,
  preciseNumber,
  isEmpty,
} from "../../../store/reducers/pos";
import { ReactComponent as Clock } from "./assets/alarm-clock.svg";
import {
  isApplicableCategeoryPromo,
  isNotExcludedProuduct,
} from "../DiscountModal/helpers";
import { setDeliveryChargePOS } from "../../../store/actions/pos";


let getPrepTime = (prep, language) => {
  let prepTime = "";
  if (language === "english") {
    if (prep >= 10080 && prep % 10080 == 0)
      prepTime = `${prep / 10080} week${prep / 10080 > 1 ? "s" : ""}`;
    else if (prep >= 1440 && prep % 1440 == 0)
      prepTime = `${prep / 1440} day${prep / 1440 > 1 ? "s" : ""}`;
    else if (prep >= 60) {
      prepTime = `${(prep / 60).toFixed(1)} hour${prep / 60 === 1 ? "" : "s"}`;
    } else {
      prepTime = `${prep} minute${prep === 1 ? "" : "s"}`;
    }
  } else {
    if (prep >= 10080 && prep % 10080 == 0) prepTime = `${prep / 10080} اسبوع`;
    else if (prep >= 1440 && prep % 1440 == 0) prepTime = `${prep / 1440} يوم`;
    else if (prep >= 60) {
      prepTime = `${(prep / 60).toFixed(1)} ساعة`;
    } else {
      prepTime = `${prep} دقيقة`;
    }
  }

  return prepTime;
};

class Product extends Component {
  GetVariantName = (keys) => {
    let name = "";
    const length = keys.length;
    const language = this.props.config.language;
    let key;
    for (let i = 0; i < length; i++) {
      key = keys[i];

      name += `${GetEnglishOrArabic(
        key.variant_key,
        key.variant_key_ar,
        language
      )}: ${GetEnglishOrArabic(
        key.variant_value,
        key.variant_value_ar,
        language
      )}`;
      if (i !== length - 1) {
        name += " / ";
      }
    }
    return name;
  };
  shouldOpenProductModal = (product, checkInventory = false) => {
    if (
      product.has_options ||
      product.has_variants ||
      product.type_of_product === "bookable" ||
      product.type_of_product == "service" ||
      (["stocked", "composite"].includes(product.type_of_product) &&
        checkInventory &&
        product.inventory_on_hand < product.increments)
    )
      return true;
  };
  quickAddProductToCart = (product) => {
    const { t, products, promotions, categories } = this.props;
    let usedComponents = { ...this.props.usedComponents };
    let shouldUpdateComponents = false;
    if (["stocked", "composite"].includes(product.type_of_product)) {
      product.inventory_on_hand = preciseNumber(
        product.inventory_on_hand - product.increments
      );
      if (!isEmpty(product.composed_products)) {
        usedComponents = reduceCompositeInventories(
          product,
          product.increments,
          products,
          usedComponents
        );
        shouldUpdateComponents = true;
      }
      if (!isEmpty(product.components_dict)) {
        usedComponents = reduceComponentsInventories(
          product,
          product.increments,
          products,
          usedComponents
        );
        shouldUpdateComponents = true;
      }
    }
    if (shouldUpdateComponents) {
      this.props.updateUsedComponents(usedComponents);
    }

    let promoItems = [];
    if (promotions.products.length || promotions.category_free?.length) {
      promoItems = promotions.products
        .filter(
          (p) =>
            p.quantity > 0 &&
            ((p.has_variants && p.product_id === product.parent) ||
              (!p.has_variants && p.product_id === product.id))
        )
        .concat(
          promotions.category_free.filter(
            (cd) =>
              cd.quantity > 0 &&
              isApplicableCategeoryPromo(
                cd.categories.map((c) => c.id),
                product,
                categories
              ) &&
              isNotExcludedProuduct(product, [], [])
          )
        );
    }

    if (promoItems.length) {
      this.props.addProductToCart({
        product: product,
        quantity: Math.min(product.increments, promoItems[0].quantity),
        total_price: product.price,
        options: {},
        ignoreInventory: false,
        specialRemarks: "",
        quantityExcludedOptionsTotal: 0,
        extraFields: {},
        promo: promoItems[0].promo,
        discount: promoItems[0].id,
        discountType:
          (promoItems[0].categories && "category_free") || "products",
        forcedPrice: 0,
      });
    } else {
      this.props.addProductToCart({
        product: product,
        quantity: product.increments,
        total_price: product.price,
        options: {},
        ignoreInventory: false,
        specialRemarks: "",
        quantityExcludedOptionsTotal: 0,
        extraFields: {},
      });

      const allCharged = this.props.pos.cart?.every(
        (product) => !product["chargeDelivery"]
      );

      this.props.setDeliveryChargePOS({
        deliveryCharge:
          this.props.pos.cart.length && allCharged
            ? 0
            : this.props.pos.deliveryChargeBackup,
        fromPage: "products",
      });
    }
    if (product.is_variant) {
      this.props.setStateIndex({ variantModalVisible: false });
    }

    message.success(t("Product Added to Cart"));
  };
  openProductModal = (product) => {
    if (product.is_variant) {
      this.props.setStateIndex({
        productModalVisible: true,
        selectedProduct: product,
        variantModalVisible: false,
      });
    } else {
      this.props.setStateIndex({
        productModalVisible: true,
        selectedProduct: product,
      });
    }
  };
  openVariantModal = (product, width) => {
    this.props.setStateIndex({
      variantModalVisible: true,
      selectedProduct: product,
      variantWidth: width,
    });
  };

  render() {
    const { config, product, width, settings } = this.props;

    const shouldOpenModal = this.shouldOpenProductModal(product, false);

    return (
      <Card
        onClick={(e) => {
          e.stopPropagation();
          product.has_variants
            ? this.openVariantModal(product, width)
            : this.shouldOpenProductModal(product, true)
            ? this.openProductModal(product)
            : this.quickAddProductToCart(product);
        }}
        bodyStyle={{ padding: "15px 10px 0px 10px", fontSize: 13 }}
        hoverable
        style={{ width: width, height: 170, borderRadius: 4 }}
        cover={
          <div style={{ position: "relative" }}>
            <img
              loading={"lazy"}
              width={width}
              height={105}
              style={{
                objectFit: product.stretch_photo ? "cover" : "contain",
                borderBottom: "1px solid #CCCCCC",
              }}
              src={product.photo_small || product.photo}
            />
            {product.preparation_time > 0 && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  justifyContent: "center",
                  gridColumnGap: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.85)",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  padding: 5,
                }}
              >
                <Clock
                  style={{
                    width: "18px",
                    height: "22px",
                    fill: "#0099CC",
                  }}
                />
                <p style={{ fontSize: "15px", color: "#0099CC", margin: 0 }}>
                  {getPrepTime(product.preparation_time, config.language)}
                </p>
              </div>
            )}
          </div>
        }
      >
        <Button
          onClick={(event) => {
            event.stopPropagation();
            product.has_variants
              ? this.openVariantModal(product)
              : this.openProductModal(product);
          }}
          size="small"
          shape="circle"
          style={{
            position: "absolute",
            top: 10,
            zIndex: 2,
            borderColor: "#0099CC",
            color: "#0099CC",
            right: 10,
          }}
        >
          {shouldOpenModal ? <EllipsisOutlined /> : <InfoOutlined />}
        </Button>

        <p
          style={{
            color: "black",
            marginBottom: settings.show_prices_pos && 4,
            WebkitLineClamp: settings.show_prices_pos && 1,
          }}
          className="textEllipsis"
        >
          {product.is_variant
            ? this.GetVariantName(product.variant_keys)
            : GetEnglishOrArabic(
                product.name,
                product.ar_name,
                config.language
              )}
        </p>
        {settings.show_prices_pos && (
          <p style={{ color: "#0099CC" }} className="textEllipsis">
            {`${product.price?.toFixed(
              settings.currency_decimals
            )} ${GetEnglishOrArabic(
              settings.currency,
              settings.currency_local,
              config.language
            )}`}
          </p>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    usedComponents: state.pos.usedComponents,
    settings: state.auth.settings,
    promotions: state.pos.discounts,
    categories: state.pos.branchProducts?.categories || [],
    pos: state.pos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addProductToCart: (params) => dispatch(addProductToCart(params)),
    updateUsedComponents: (usedComponents) =>
      dispatch(updateUsedComponents(usedComponents)),
    setDeliveryChargePOS: (params) => dispatch(setDeliveryChargePOS(params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Product));
