import {
  FETCH_HEALTH,
  DISMISS_HEALTH,
  FETCH_HEALTH_LOADING,
} from "./actionTypes";

import instance from "./instance";
import { message } from "antd";

export const fetchStoreHealth = (fresh = null) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_HEALTH_LOADING });
    try {
      const res = await instance.get(
        "/check_store_health/".concat(fresh ? "?fresh=1" : "")
      );
      if (res.data.success == true) {
        dispatch({ type: FETCH_HEALTH, payload: res.data.issues });
      } else message.error("Something went wrong");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error("You don't have permissions to view this page");
      else message.error("Something went wrong");
    }
  };
};

export const dismissHealthNotification = () => {
  return {
    type: DISMISS_HEALTH,
  };
};
