const initialState = {
  tapProcessingModalOpen: false,
  tapSuccessModalOpen: false,
  tapFailModalOpen: false,
  errorMessage: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_TAP_PROCESSING_MODAL_OPEN":
      return { ...state, tapProcessingModalOpen: payload };
    case "SET_TAP_SUCCESS_MODAL_OPEN":
      return { ...state, tapSuccessModalOpen: payload };
    case "SET_TAP_FAIL_MODAL_OPEN":
      return { ...state, tapFailModalOpen: payload };
    case "SET_TAP_FAIL_MESSAGE":
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};
