import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";

// Antd Components
import { Modal, message, Input, InputNumber } from "antd";

// Actions
import { addProductUnit } from "../../store/actions";

class NewPaymentModal extends Component {
  state = {
    saveLoading: false,

    amount: null,
  };

  addProductUnit = async () => {
    this.setState({ saveLoading: true });
    const { t, orderID } = this.props;
    const { amount } = this.state;
    try {
      const res = await instance.post("/add_order_payment/", {
        amount,
        order_id: orderID,
      });

      message.success(t("Added successfully"));
      this.props.addProductUnit({ unit: res.data });
      this.props.onClose();
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
  };

  saveButtonDisabled = () => {
    const { amount } = this.state;
    return amount <= 0;
  };

  render() {
    const { visible, t, config } = this.props;

    return (
      <>
        <Modal
          style={{ top: 20 }}
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("New unit")}
            </div>
          }
          visible={visible}
          onCancel={() => this.props.onClose()}
          onOk={() => {
            this.addProductUnit();
          }}
          okButtonProps={{
            disabled: this.saveButtonDisabled(),
            loading: this.state.saveLoading,
          }}
          okText={t("Save")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
        >
          <div className="px-2 mb-3">
            <div>
              <p className="mt-3">{t("Amount")}</p>
              <InputNumber
                className="w-100"
                placeholder={t("Amount")}
                onChange={(e) => this.setState({ amount: e })}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return { addProductUnit: (unit) => dispatch(addProductUnit(unit)) };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewPaymentModal));
