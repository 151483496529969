import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
// Antd Components
import { Modal } from "antd";
import { withRouter } from "react-router-dom";
import { ReactComponent as Sound } from "./assets/sound.svg";
import { toggleSound } from "../../store/actions/orders";
// Actions

class PaymentOverdueModal extends Component {
  state = {};

  render() {
    const { config, t, permissions } = this.props;
    if (!permissions.can_view_orders) return <></>;

    let message = t("Would you like to be alerted for new orders?");

    return (
      <Modal
        width={350}
        destroyOnClose={true}
        visible={true}
        onCancel={(e) => {
          if (e.type === "click") this.props.onClose();
        }}
        okText={t("Yes")}
        cancelText={t("No")}
        bodyStyle={{
          width: "auto",
          padding: "0px 18px",
          paddingTop: 20,
        }}
        onOk={() => {
          if (!this.props.soundEnabled) this.props.toggleSound();
          this.props.onClose();
        }}
        // onCancel={() => {
        //   this.props.toggleSound();
        //   this.props.toggleSound();
        //   this.props.onClose();
        // }}
        cancelButtonProps={{
          onClick: () => {
            if (this.props.soundEnabled) this.props.toggleSound();
            this.props.onClose();
          },
        }}
      >
        <div className="p-2 my-3 text-center">
          <Sound style={{ width: 90, height: 90 }} />
          <p className="mt-3">{message}</p>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    locations: state.locations,
    info: state.account.info,
    permissions: state.auth.permissions,
    soundEnabled: state.orders.soundEnabled,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleSound: () => dispatch(toggleSound()),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNamespaces()(PaymentOverdueModal))
);
