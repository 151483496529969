import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
// Antd Components
import { Modal, Select, message, Input, Checkbox, Tooltip } from "antd";

// My components

// Actions

const { Option } = Select;
const { TextArea } = Input;

class CancellationReasonModal extends Component {
  state = {
    saveLoading: false,
    reason: "",
    isAnother: false,
    refundCustomer: false,
    refundWallet: false,
    returnInventory: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.refundLoading === true && this.props.refundLoading === false)
      this.props.onClose();
  };

  canRefundOrder = (checkRefundWallet = false) => {
    const { settings } = this.props;
    const order = this.props.originalOrder;

    if (order.refund_id) return false;
    if (
      settings.enable_myfatoorah &&
      order.payment_method.toLowerCase().includes("fatoorah")
    )
      return true;

    if (
      (settings.enable_tahseeel || settings.enable_tahseeel_credit) &&
      order.payment_method.toLowerCase().includes("tahseeel")
    )
      return true;

    if (
      settings.enable_tap &&
      order.payment_method.toLowerCase().includes("tap")
    )
      return true;

    if (
      settings.enable_sadad &&
      order.payment_method.toLowerCase().includes("sadad")
    )
      return true;

    if (
      (settings.enable_bookey || settings.enable_bookey_credit) &&
      order.payment_method.toLowerCase().includes("bookey")
    )
      return true;

    if (
      settings.enable_tap_v2 &&
      order.payment_method.toLowerCase().includes("tap_v2")
    )
      return true;

    if (
      settings.enable_hesabe &&
      order.payment_method.toLowerCase().includes("hesabe")
    )
      return true;

    if (
      settings.enable_tamara &&
      order.payment_method.toLowerCase().includes("tamara")
    )
      return true;

    if (
      settings.enable_upay_v2 &&
      order.payment_method.toLowerCase().includes("upay_v2") &&
      !!order.payment_reference
    )
      return true;

    if (
      settings.enable_upay &&
      order.payment_method.toLowerCase().includes("upay") &&
      !order.payment_method.toLowerCase().includes("v2") &&
      !!order.payment_extra1
    )
      return true;

    if (order.payment_method.toLowerCase().includes("cash")) return true;

    if (
      !checkRefundWallet &&
      settings.enable_wallet &&
      settings.enable_wallet_staff &&
      (order.payment_method === "wallet" || order.user)
    ) {
      return true;
    }

    return false;
  };

  canReturnPoints = () => {
    const { settings } = this.props;
    const order = this.props.originalOrder;

    return (
      order.user &&
      settings.enable_loyalty_points &&
      settings.enable_loyalty_points_staff
    );
  };
  render() {
    const { config, t, settings, refundLoading, permissions, originalOrder } =
      this.props;

    const reasons = ["address", "contact info", "delivery", "order", "payment"];
    return (
      <>
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("Cancel order")}
            </div>
          }
          visible={true}
          onCancel={() => this.props.onClose()}
          onOk={() => {
            this.props.cancelOrder(
              this.state.reason,
              this.state.refundCustomer,
              this.state.returnInventory,
              !this.canRefundOrder(true) ? true : this.state.refundWallet
            );
          }}
          okText={t("Cancel")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: refundLoading,
            danger: true,
            disabled: !(String(this.state.reason) || "").trim(),
          }}
          width={500}
        >
          <div className="px-2 mb-3 pt-3">
            {this.canRefundOrder() && (
              <div className="mb-3">
                {permissions.can_refund_orders ? (
                  <>
                    <Checkbox
                      onChange={(e) =>
                        this.setState({ refundCustomer: e.target.checked })
                      }
                    >
                      {t("Refund customer")}
                    </Checkbox>
                    {originalOrder.payment_method !== "wallet" &&
                      originalOrder.user &&
                      settings.enable_wallet &&
                      settings.enable_wallet_staff &&
                      this.canRefundOrder(true) && (
                        <Checkbox
                          disabled={!this.state.refundCustomer}
                          onChange={(e) =>
                            this.setState({ refundWallet: e.target.checked })
                          }
                        >
                          {t("Refund to wallet")}
                        </Checkbox>
                      )}
                  </>
                ) : (
                  <Tooltip
                    title={t(
                      "You do not have the permission to refund. Please visit the Permissions section"
                    )}
                  >
                    <Checkbox disabled={true}>{t("Refund customer")}</Checkbox>
                  </Tooltip>
                )}
              </div>
            )}
            <p>{t("Please select a reason")}</p>

            <Select
              style={{
                width: "100%",
              }}
              placeholder={t("Select a reason")}
              onChange={(e) =>
                e == -1
                  ? this.setState({ isAnother: true, reason: "" })
                  : this.setState({ reason: e, isAnother: false })
              }
            >
              {reasons.map((reason) => (
                <Option
                  value={"Problem with ".concat(reason)}
                  style={{
                    textAlign: config.direction == "ltr" ? "left" : "right",
                  }}
                >
                  {t("Problem with ".concat(reason))}
                </Option>
              ))}
              <Option
                value={-1}
                style={{
                  textAlign: config.direction == "ltr" ? "left" : "right",
                }}
              >
                {t("Another")}
              </Option>
            </Select>

            {this.state.isAnother && (
              <>
                <p className="mt-3">{t("Enter the reason")}</p>
                <TextArea
                  rows={4}
                  onChange={(e) => this.setState({ reason: e.target.value })}
                />
              </>
            )}
            <div className="mt-3">
              <Checkbox
                onChange={(e) =>
                  this.setState({ returnInventory: e.target.checked })
                }
              >
                {this.canReturnPoints()
                  ? t("Return inventory / points")
                  : t("Return inventory")}
              </Checkbox>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    refundLoading: state.orders.refundLoading,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(
  withNamespaces()(CancellationReasonModal)
);
