import sound from "./notification.mp3";
import sound2 from "./notification-2.mp3";
import sound3 from "./notification-3.mp3";
import sound4 from "./notification-4.mp3";
import sound5 from "./notification-5.mp3";
import sound6 from "./notification-6.mp3";
import sound7 from "./notification-7.mp3";
import sound8 from "./notification-8.mp3";
import sound9 from "./notification-9.mp3";
import sound10 from "./notification-10.mp3";

export default function getAudioFile(filename) {
  let f = null;
  if (filename === "sound") f = sound;
  if (filename === "sound2") f = sound2;
  if (filename === "sound3") f = sound3;
  if (filename === "sound4") f = sound4;
  if (filename === "sound5") f = sound5;
  if (filename === "sound6") f = sound6;
  if (filename === "sound7") f = sound7;
  if (filename === "sound8") f = sound8;
  if (filename === "sound9") f = sound9;
  if (filename === "sound10") f = sound10;
  return f;
}
