import {
  Select,
  message,
  Spin,
  Popconfirm,
  InputNumber,
  Input,
  Radio,
  Button,
  Switch,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  LoadingOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import instance from "../../store/actions/instance";
import { SketchPicker } from "react-color";
import "./GiftTemplate.css";
import FontPicker from "font-picker-react";
import NewGiftingTemplate from "./NewGiftingTemplate";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TextArea } = Input;

function isArabic(text) {
  if (!text) return false;
  let pattern = /[\u0600-\u06FF\u0750-\u077F]/;
  return pattern.test(text);
}

// const arabicFonts = [
//   "Cairo",
//   "Tajawal",
//   "Qahiri",
//   "Almarai",
//   "Amiri",
//   "Changa",
//   "El Messiri",
//   "Markazi Text",
//   "Jomhuria",
//   "Mada",
//   "Reem Kufi",
//   "Lalezar",
//   "Lemonada",
//   "Lateef",
//   "Scheherazade",
//   "Mirza",
//   "Harmattan",
//   "Kufam",
//   "Katibeh",
//   "Rakkas",
//   "Aref Ruqaa",
//   "Vibes",
// ];

class GiftingTemplates extends Component {
  constructor(props) {
    super(props);
    this.mainDiv = React.createRef();
  }
  state = {
    loading: true,
    templates: [],
    sources: [],
    selectedTemplate: undefined,
    deleteLoading: false,
    message: "",
    language: !this.props.selectedOrder.gift_message
      ? this.props.config.language === "arabic"
        ? 1
        : 0
      : isArabic(this.props.selectedOrder.gift_message)
      ? 1
      : 0,
    saveLoading: false,
    showColorPicker: false,
    newTemplateModalOpen: false,
    fontPickerKey: Math.random() * 10000,
    showQR: true,
  };

  handleClickAway = (e) => {
    if (
      !this.mainDiv?.current?.contains(e.target) &&
      this.state.showColorPicker
    ) {
      this.setState({ showColorPicker: false });
    }
  };

  fetchTemplates = async () => {
    const { t } = this.props;
    try {
      const res = await instance.post("/fetch_gifting_templates/", {
        oid: this.props.selectedOrder.id,
      });
      if (res.data.success)
        this.setState({
          templates: res.data.templates,
          sources: res.data.sources,
          message: res.data.sources[0]?.value || "",
        });
      else message.error(t("SomethingWentWrong"));
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  };

  deleteTemplate = async (id) => {
    this.setState({ deleteLoading: true });
    const { t } = this.props;
    try {
      const res = await instance.post("/delete_gifting_template/", {
        tid: id,
      });
      if (res.data.success) {
        let newTemplates = this.state.templates.filter((tem) => tem.id !== id);
        this.setState({ templates: newTemplates, selectedTemplate: undefined });
        message.success(t("Deleted successfully"));
      } else message.error(t("SomethingWentWrong"));
    } catch (e) {
      console.error(e);
    }
    this.setState({ deleteLoading: false });
  };

  componentDidMount = () => {
    this.fetchTemplates();
    document.addEventListener("mousedown", this.handleClickAway);
  };
  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickAway);
  };
  onChange = (v, field) => {
    let newSelectedTemplate = this.state.selectedTemplate;
    newSelectedTemplate[field] = v;
    this.setState({ selectedTemplate: newSelectedTemplate });
  };

  generateGiftCard = (token) => {
    const { settings } = this.props;
    const st = this.state.selectedTemplate;
    const text = this.state.message;
    window.open(
      `https://${settings.domain}/generate_gift_template/${token}/?id=${
        st.id
      }&c=${st.columns}&r=${st.rows}&w=${st.width}&h=${st.height}&fs=${
        st.font_size
      }&fc=${st.font_color.substring(1)}&ff=${
        st.font_family
      }&text=${text}&oid=${this.props.selectedOrder.id}&qr=${
        this.state.showQR ? "1" : "0"
      }`,
      "_blank"
    );
  };

  updateTemplate = async () => {
    this.setState({ saveLoading: true });
    const { t } = this.props;
    const { selectedTemplate } = this.state;
    try {
      const res = await instance.post("/update_gifting_template/", {
        ...selectedTemplate,
      });
      if (res.data.success) {
        this.generateGiftCard(res.data.token);
      } else message.error(t("SomethingWentWrong"));
    } catch (e) {
      console.error(e);
    }
    this.setState({ saveLoading: false });
  };
  createNewTemplate = (template) => {
    let newTemplates = this.state.templates;
    newTemplates = [...newTemplates, template];
    this.setState({ selectedTemplate: template, templates: newTemplates });
  };

  render() {
    const { settings, config, t, selectedOrder } = this.props;
    const { loading, templates, selectedTemplate, newTemplateModalOpen } =
      this.state;
    if (loading)
      return (
        <div className="my-4 text-center">
          <Spin indicator={antIcon} />
        </div>
      );
    return (
      <div>
        {newTemplateModalOpen && (
          <NewGiftingTemplate
            onCreate={(template) => this.createNewTemplate(template)}
            onClose={() => this.setState({ newTemplateModalOpen: false })}
          />
        )}
        <p>{t("Choose your template")}</p>
        <div>
          <Select
            value={selectedTemplate?.id || undefined}
            onChange={(v) => {
              this.setState({
                selectedTemplate: templates.find((tem) => tem.id === v),
              });
            }}
            size="large"
            style={{ width: 300 }}
            placeholder={t("Select a template")}
          >
            {templates.map((template) => (
              <Option
                value={template.id}
                style={{
                  textAlign: config.language === "arabic" ? "right" : "left",
                }}
              >
                <img
                  src={template.background}
                  style={{
                    width: 40,
                    height: 32,
                    marginBottom: 5,
                    [`margin${
                      config.language === "arabic" ? "Left" : "Right"
                    }`]: 10,
                  }}
                />{" "}
                <span>{template.name || "(unnamed)"}</span>
              </Option>
            ))}
          </Select>{" "}
          {!!selectedTemplate && (
            <span style={{ cursor: "pointer", color: "red" }} className="mx-2">
              <Popconfirm
                title={t("Are you sure you want to delete this template?")}
                onConfirm={() => this.deleteTemplate(selectedTemplate.id)}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                {this.state.deleteLoading ? antIcon : <DeleteOutlined />}
              </Popconfirm>
            </span>
          )}
          <span
            style={{ cursor: "pointer", color: "green" }}
            className="mx-2"
            onClick={() => this.setState({ newTemplateModalOpen: true })}
          >
            <PlusOutlined />
          </span>
        </div>

        {!!selectedTemplate && (
          <div>
            <div
              style={{
                marginTop: 15,
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridTemplateRows: "auto auto auto",
                columnGap: 100,
                rowGap: 15,
              }}
            >
              <div>
                <p className="mb-2">{t("Width")}</p>
                <InputNumber
                  placeholder={t("Width")}
                  value={selectedTemplate.width}
                  onChange={(e) => this.onChange(e, "width")}
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                <p className="mb-2">{t("Height")}</p>
                <InputNumber
                  placeholder={t("Height")}
                  value={selectedTemplate.height}
                  onChange={(e) => this.onChange(e, "height")}
                  style={{ width: "100%" }}
                />
              </div>

              <div>
                <p className="mb-2">{t("Rows")}</p>
                <InputNumber
                  placeholder={t("Rows")}
                  value={selectedTemplate.rows}
                  onChange={(e) => this.onChange(e, "rows")}
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                <p className="mb-2">{t("Columns")}</p>
                <InputNumber
                  placeholder={t("Columns")}
                  value={selectedTemplate.columns}
                  onChange={(e) => this.onChange(e, "columns")}
                  style={{ width: "100%" }}
                />
              </div>

              <div>
                <p className="mb-2">{t("Font size")}</p>
                <InputNumber
                  placeholder={t("Font size")}
                  value={selectedTemplate.font_size}
                  onChange={(e) => this.onChange(e, "font_size")}
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                <p className="mb-2">{t("Font color")}</p>
                <div
                  style={{
                    width: "100%",
                    height: 30,
                    borderRadius: 3,
                    backgroundColor: selectedTemplate.font_color,
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ showColorPicker: true })}
                ></div>
                <div
                  ref={this.mainDiv}
                  style={{
                    position: "relative",
                    [config.direction === "ltr" ? "left" : "right"]: -70,
                  }}
                >
                  {this.state.showColorPicker && (
                    <SketchPicker
                      presetColors={[
                        "#000000",
                        "#0099cc",
                        "#00796B",
                        "#303F9F",
                        "#FFC107",
                        "#FF4081",
                        "#CDDC39",
                        "#FF9800",
                        "#388E3C",
                        "#795548",
                      ]}
                      disableAlpha={true}
                      color={selectedTemplate.font_color}
                      onChange={(color, event) => {
                        this.onChange(color.hex, "font_color");
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <p className="mb-2">{t("Font family")}</p>
                <FontPicker
                  key={this.state.fontPickerKey}
                  limit={100}
                  apiKey="AIzaSyCNYuH7iK6mczoMuCdknB9p3vVAQkgOUEo"
                  activeFontFamily={selectedTemplate.font_family}
                  onChange={(nextFont, e) => {
                    this.onChange(nextFont.family, "font_family");
                  }}
                  scripts={
                    this.state.language === 1 ? ["arabic"] : ["latin", "greek"]
                  }
                  sort="popularity"
                />
              </div>
            </div>
            {!!selectedOrder.gift_link && (
              <div className="mt-3">
                <Switch
                  defaultChecked={true}
                  onChange={(e) => this.setState({ showQR: e })}
                />
                <span className="mx-2">{t("Show gift QR code")}</span>
              </div>
            )}
            <p className="mt-3">{t("Gift message source")} </p>
            <div>
              <Select
                defaultValue={this.state.message || undefined}
                onChange={(v) => {
                  this.setState({
                    message: v,
                    language: isArabic(v) ? 1 : 0,
                  });
                }}
                style={{ width: "100%" }}
                placeholder={t("Select a source")}
              >
                {this.state.sources.map((source) => (
                  <Option
                    value={source.value}
                    style={{
                      textAlign:
                        config.language === "arabic" ? "right" : "left",
                    }}
                  >
                    {config.language === "arabic"
                      ? source.ar_name || source.name
                      : source.name}{" "}
                    ({source.value})
                  </Option>
                ))}
              </Select>
            </div>
            <p className="mt-3">
              {t("Gift message")}{" "}
              <span
                style={{
                  float: config.language === "arabic" ? "left" : "right",
                }}
              >
                <Radio.Group
                  value={this.state.language}
                  size="small"
                  onChange={(v) =>
                    this.setState({
                      language: v.target.value,
                      fontPickerKey: Math.random() * 10000,
                    })
                  }
                >
                  <Radio.Button value={0}>English</Radio.Button>
                  <Radio.Button value={1}>عربي</Radio.Button>
                </Radio.Group>
              </span>
            </p>
            <TextArea
              value={this.state.message.replace(/<br>/g, "\n")}
              onChange={(e) => {
                this.setState({
                  message: e.target.value.replace(/\n/g, "<br>"),
                });
              }}
              autoSize={{ minRows: 2, maxRows: 10 }}
              style={{ direction: this.state.language === 1 ? "rtl" : "ltr" }}
              placeholder={t("Gift message")}
              className="apply-font"
            />
            <div className="mt-3">
              <Button
                type="primary"
                loading={this.state.saveLoading}
                onClick={() => this.updateTemplate()}
              >
                {t("Print gift card")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    x: () => dispatch({ type: "fd" }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(GiftingTemplates));
