const trans = {
  "Addon enabled successfully": "تم تفعيل الإضافة بنجاح",
  "Uninstalled Add-On successfully": "تم تعطيل الإضافة بنجاح",
  "Add-On is already uninstalled": "الإضافة معطلة مسبقا",
  "Add-On is already enabled": "الإضافة مفعلة مسبقا",
  "Please contact us to enable this addon":
    "الرجاء التواصل معنا لتفعيل الإضافة",
};

export default trans;
