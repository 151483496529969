import {
  FETCH_WAITLISTS,
  SET_WAITLISTS_FETCH_LOADING,
  DELETE_WAITLIST,
  UNDELETE_WAITLIST,
  TOGGLE_WAITLIST_STATUS,
  CREATE_WAITLIST,
  CHANGE_ENTRY_ORDER,
  UPDATE_WAITLIST_NAMES,
  CREATE_WAITLIST_ENTRY,
  UPDATE_WAITLIST_ENTRY,
  BULK_TOGGLE_WAITLIST_ENTRIES,
  SOCKET_NEW_WAITLIST_ENTRY,
  SOCKET_UPDATE_WAITLIST_ENTRY,
  SOCKET_BULK_UPDATE_WAITLIST_ENTRY,
  SOCKET_CHANGE_ENTRY_ORDER,
  FETCH_WAITLISTS_ENTRIES,
  QUICK_CHANGE_WAITLIST_ENTRY,
} from "./actionTypes";

import instance from "./instance";
import { message } from "antd";
import moment from "moment";
export const fetchWaitLists = (t) => {
  return async (dispatch) => {
    dispatch({ type: SET_WAITLISTS_FETCH_LOADING });
    try {
      let res = await instance.get(`/get_waitlists/`);
      const waitLists = res.data;
      dispatch({
        type: FETCH_WAITLISTS,
        payload: waitLists,
      });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const deleteWaitList = (index, id, t) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_WAITLIST, payload: index });
    try {
      await instance.post("/delete_waitlist/", { id });
      message.success(t("Wait list was deleted successfully"));
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({ type: UNDELETE_WAITLIST, payload: index });
    }
  };
};

export const toggleWaitListStatus = (index, id, t) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_WAITLIST_STATUS,
      payload: index,
    });
    try {
      await instance.post("/toggle_waitlist_status/", {
        id,
      });
      message.success(t("Status has been toggled successfully"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({
        type: TOGGLE_WAITLIST_STATUS,
        payload: index,
      });
    }
  };
};

export const createWaitList = (waitList) => {
  return {
    type: CREATE_WAITLIST,
    payload: waitList,
  };
};

export const changeEntryOrder = (
  entryIndex,
  waitListIndex,
  waitListId,
  direction,
  t
) => {
  return async (dispatch, getState) => {
    const waitlists = getState().waitList.waitLists;
    const socket = getState().socket.socket;
    let socket_id = null;
    if (socket) {
      socket_id = socket.id;
    }
    let currentEntry = waitlists[waitListIndex].entries[entryIndex].key;

    let nextEntry =
      waitlists[waitListIndex].entries[entryIndex - direction].key;

    const modified_date = moment().format("YYYY-MM-DDTHH:mm:ss.S").toString();
    dispatch({
      type: CHANGE_ENTRY_ORDER,
      payload: { entryIndex, waitListIndex, direction, modified_date },
    });
    try {
      await instance.post("/change_waitlist_entry_order/", {
        entryIndex,
        nextEntryIndex: entryIndex - direction,
        waitListId,
        currentEntry,
        nextEntry,
        socket_id,
        modified_date,
      });
    } catch {
      message.error(t("SomethingWentWrong"));
      dispatch({
        type: CHANGE_ENTRY_ORDER,
        payload: { entryIndex, waitListIndex, direction, modified_date },
      });
    }
  };
};

export const updateWaitListNames = (params) => {
  return {
    type: UPDATE_WAITLIST_NAMES,
    payload: params,
  };
};

export const createWaitListEntry = (entry, waitListIndex) => {
  return {
    type: CREATE_WAITLIST_ENTRY,
    payload: { entry, waitListIndex },
  };
};

export const updateWaitListEntry = (params) => {
  return {
    type: UPDATE_WAITLIST_ENTRY,
    payload: params,
  };
};

export const bulkToggleWaitListEntries = (
  ids,
  status,
  waitListIndex,
  modified_date
) => {
  return {
    type: BULK_TOGGLE_WAITLIST_ENTRIES,
    payload: { ids, status, waitListIndex, modified_date },
  };
};
export const socketNewWaitListEntry = (entry) => {
  return async (dispatch) => {
    // let res = await instance.post("/get_waitlist_entry/", {
    //   id,
    // });
    dispatch({
      type: SOCKET_NEW_WAITLIST_ENTRY,
      payload: entry,
    });
  };
};

export const socketUpdateWaitListEntry = (entry) => {
  return async (dispatch) => {
    // let res = await instance.post("/get_waitlist_entry/", {
    //   id,
    // });

    dispatch({
      type: SOCKET_UPDATE_WAITLIST_ENTRY,
      payload: entry,
    });
  };
};

export const socketBulkUpdateWaitListEntries = (
  entries,
  status,
  waitlist_id,
  modified_date
) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_BULK_UPDATE_WAITLIST_ENTRY,
      payload: { entries, status, waitlist_id, modified_date },
    });
  };
};

export const socketChangeEntryOrder = (params) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_CHANGE_ENTRY_ORDER,
      payload: params,
    });
  };
};

export const FetchWaitListEntries = (id) => {
  return async (dispatch) => {
    try {
      let res = await instance.post("/get_waitlist_entries/", {
        id,
      });

      dispatch({
        type: FETCH_WAITLISTS_ENTRIES,
        payload: { waitlist_id: id, entries: res.data },
      });
    } catch {
      message.error("Error, please refresh the page");
    }
  };
};

export const quickChangeWaitListEntryStatus = (
  waitListIndex,
  waitListEntryIndex,
  status
) => {
  return {
    type: QUICK_CHANGE_WAITLIST_ENTRY,
    payload: { waitListIndex, waitListEntryIndex, status },
  };
};
