import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
//Ant

//My Components
import OptionGroup from "./OptionGroup";
//Actions

export class index extends Component {
  render() {
    const {
      t,
      options,
      selectedOptions,
      productUsedComponents,
      productQuantity,
      editedProduct,
      optionsGroups,
    } = this.props;
    let optionGroups = [];
    if (optionsGroups.length > 0) {
      optionGroups = optionsGroups.map((og) => (
        <span key={og.id} sortOrder={og.sort_order}>
          {options
            .filter((o) => o.groups.includes(og.id))
            .map((option, index) => (
              <OptionGroup
                key={`${og?.id || ""} ${index}-${option.id}`}
                option={option}
                productUsedComponents={productUsedComponents}
                addOptionToProduct={this.props.addOptionToProduct}
                selectedOverall={
                  selectedOptions[option.id + "-" + og.i]
                    ? selectedOptions[option.id + "-" + og.id].selected
                    : 0
                }
                selectedOptions={
                  selectedOptions[option.id + "-" + og.id]
                    ? selectedOptions[option.id + "-" + og.id].selectedOptions
                    : {}
                }
                productQuantity={productQuantity}
                editedProduct={editedProduct}
                group={og}
              />
            ))}
        </span>
      ));

      options.forEach((option, index) => {
        if (option.groups.length <= 0) {
          optionGroups.push(
            <OptionGroup
              key={`${index}-${option.id}`}
              option={option}
              productUsedComponents={productUsedComponents}
              addOptionToProduct={this.props.addOptionToProduct}
              selectedOverall={
                selectedOptions[option.id]
                  ? selectedOptions[option.id].selected
                  : 0
              }
              selectedOptions={
                selectedOptions[option.id]
                  ? selectedOptions[option.id].selectedOptions
                  : {}
              }
              productQuantity={productQuantity}
              editedProduct={editedProduct}
              sortOrder={option.sort_order}
            />
          );
        }
      });
      optionGroups.sort((a, b) => a.props.sortOrder - b.props.sortOrder);
    } else {
      optionGroups = options.map((option, index) => (
        <OptionGroup
          key={`${index}-${option.id}`}
          option={option}
          productUsedComponents={productUsedComponents}
          addOptionToProduct={this.props.addOptionToProduct}
          selectedOverall={
            selectedOptions[option.id] ? selectedOptions[option.id].selected : 0
          }
          selectedOptions={
            selectedOptions[option.id]
              ? selectedOptions[option.id].selectedOptions
              : {}
          }
          productQuantity={productQuantity}
          editedProduct={editedProduct}
        />
      ));
    }
    return (
      <div className="my-4  ">
        <p style={{ fontSize: 20 }} className=" mt-5 text-center">
          {t("Options")}
        </p>
        {optionGroups}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(index));
