import React from "react";
import { Button, Radio, Spin } from "antd";
import {
  EyeOutlined,
  LoadingOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const QuictActionPrinting = ({
  t,
  printLayout,
  printLanguage,
  printOptions,
  invoicesLoading,
  localStorage,
  setState,
  onPrint,
  onView,
}) => {
  const direction = useSelector((state) => state.config.direction);

  return (
    <div
      className="mb-3 "
      style={{
        textAlign: direction === "ltr" ? "left" : "right",
      }}
    >
      <p className="mb-">{t("Print")} </p>

      <Radio.Group
        onChange={(e) => {
          setState("printLayout", e.target.value);
          localStorage.setItem("printLayout", e.target.value);
        }}
        defaultValue={printLayout}
        buttonStyle="solid"
        size="medium"
        className={"mb-2"}
      >
        <Radio.Button value="a4">{t("A4")}</Radio.Button>
        <Radio.Button value="thermal">{t("Thermal")}</Radio.Button>
        <Radio.Button value="items">{t("Preparation")}</Radio.Button>
      </Radio.Group>

      <Radio.Group
        onChange={(e) => {
          setState("printLanguage", e.target.value);
          localStorage.setItem("printLanguage", e.target.value);
        }}
        defaultValue={printLanguage}
        buttonStyle="solid"
        size="medium"
        style={{ margin: "0px 6px" }}
      >
        <Radio.Button value="english">{t("English")}</Radio.Button>
        <Radio.Button value="arabic">{t("Arabic")}</Radio.Button>
      </Radio.Group>

      <Radio.Group
        onChange={(e) => {
          setState("printOptions", e.target.value);
          localStorage.setItem("printOptions", e.target.value);
        }}
        defaultValue={printOptions}
        buttonStyle="solid"
        size="medium"
      >
        <Radio.Button value="show_options">{t("Show options")}</Radio.Button>
        <Radio.Button value="hide_options">{t("Hide options")}</Radio.Button>
      </Radio.Group>

      {!invoicesLoading ? (
        <div className="mt-3">
          <Button
            icon={<PrinterOutlined style={{ fontSize: 19 }} />}
            onClick={() => onPrint()}
          >
            {t("Print")}
          </Button>
          <Button
            icon={<EyeOutlined style={{ fontSize: 19 }} />}
            className="mx-1"
            onClick={() => onView()}
          >
            {t("View")}
          </Button>
        </div>
      ) : (
        <div className="mt-3">
          <Spin indicator={antIcon} />
        </div>
      )}
    </div>
  );
};

export default withNamespaces()(QuictActionPrinting);
