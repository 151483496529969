import {
  FETCH_PROMOTIONS,
  DELETE_PROMOTION,
  UNDELETE_PROMOTION,
  TOGGLE_PROMOTION_STATUS,
  CREATE_PROMOTION,
  SET_PROMOTIONS_FETCH_LOADING,
  QUICK_CHANGE_PROMOTION_IMAGE,
  BULK_DELETE_PROMOTIONS,
  BULK_TOGGLE_PROMOTIONS,
  UPDATE_PROMOTION_DETAILS,
  EXTEND_PROMOTION,
  EDIT_PROMOTION,
  TOGGLE_COUPON_PRIVATE,
} from "../actions/actionTypes";
const initialState = { loadingCoupons: false, loadingPromotions: false };

export default function store(state = initialState, { type, payload }) {
  let newCoupons = state.coupons;
  let newPromotions = state.promotions;
  let searched_coupon = null;
  let searched_promotion = null;
  switch (type) {
    case FETCH_PROMOTIONS:
      if (payload.isSmartPromo)
        return { ...state, promotions: payload.list, loadingPromotions: false };
      return { ...state, coupons: payload.list, loadingCoupons: false };

    case CREATE_PROMOTION:
      if (payload.isSmartPromo) {
        newPromotions.unshift(payload.promotion);
      } else {
        newCoupons.unshift(payload.promotion);
      }
      return { ...state, coupons: newCoupons, promotions: newPromotions };

    case DELETE_PROMOTION:
      if (payload.isSmartPromo) {
        newPromotions[payload.index].is_deleted = true;
      } else {
        newCoupons[payload.index].is_deleted = true;
      }
      return { ...state, coupons: newCoupons, promotions: newPromotions };
    case UNDELETE_PROMOTION:
      if (payload.isSmartPromo) {
        newPromotions[payload.index].is_deleted = false;
      } else {
        newCoupons[payload.index].is_deleted = false;
      }
      return { ...state, coupons: newCoupons, promotions: newPromotions };
    case TOGGLE_PROMOTION_STATUS:
      if (payload.isSmartPromo) {
        newPromotions[payload.index].is_active =
          !newPromotions[payload.index].is_active;
      } else {
        newCoupons[payload.index].is_active =
          !newCoupons[payload.index].is_active;
      }

      return { ...state, coupons: newCoupons, promotions: newPromotions };

    case TOGGLE_COUPON_PRIVATE:
       newCoupons[payload.index].is_private =
         !newCoupons[payload.index].is_private;
         return { ...state, coupons: newCoupons};

    case SET_PROMOTIONS_FETCH_LOADING:
      return {
        ...state,
        loadingCoupons: payload ? state.loadingCoupons : true,
        loadingPromotions: payload ? true : state.loadingPromotions,
      };
    case QUICK_CHANGE_PROMOTION_IMAGE:
      if (payload.isSmartPromo) {
        newPromotions[payload.index].banner = payload.image;
      } else {
        newCoupons[payload.index].banner = payload.image;
      }

      return { ...state, coupons: newCoupons, promotions: newPromotions };
    case BULK_DELETE_PROMOTIONS:
      if (payload.isSmartPromo) {
        payload.ids.forEach((id) => {
          searched_promotion = newPromotions.find((promo) => promo.key === id);
          if (searched_promotion) {
            searched_promotion.is_deleted = true;
          }
        });
      } else {
        payload.ids.forEach((id) => {
          searched_coupon = newCoupons.find((c) => c.key === id);
          if (searched_coupon) {
            searched_coupon.is_deleted = true;
          }
        });
      }
      return { ...state, coupons: newCoupons, promotions: newPromotions };
    case BULK_TOGGLE_PROMOTIONS:
      if (payload.isSmartPromo) {
        payload.ids.forEach((id) => {
          searched_promotion = newPromotions.find((promo) => promo.key === id);
          if (searched_promotion) {
            searched_promotion.is_active = payload.status;
          }
        });
      } else {
        payload.ids.forEach((id) => {
          searched_coupon = newCoupons.find((c) => c.key === id);
          if (searched_coupon) {
            searched_coupon.is_active = payload.status;
          }
        });
      }
      return { ...state, coupons: newCoupons, promotions: newPromotions };
    case UPDATE_PROMOTION_DETAILS:
      if (payload.isSmartPromo) {
        newPromotions[payload.index].discount_description = payload.description;
        newPromotions[payload.index].ar_discount_description =
          payload.arabicDescription;
        newPromotions[payload.index].code = payload.code;
        newPromotions[payload.index].background_color = payload.backgroundColor;
      } else {
        newCoupons[payload.index].discount_description = payload.description;
        newCoupons[payload.index].ar_discount_description =
          payload.arabicDescription;
        newCoupons[payload.index].code = payload.code;
        newCoupons[payload.index].background_color = payload.backgroundColor;
      }
      return { ...state, coupons: newCoupons, promotions: newPromotions };
    case EXTEND_PROMOTION:
      if (payload.isSmartPromo) {
        newPromotions[payload.index].quantity += payload.increasedQuantity;
        newPromotions[payload.index].remaining += payload.increasedQuantity;
        newPromotions[payload.index].expiry = payload.expiryDate;
        newPromotions[payload.index].is_expired = payload.is_expired;
      } else {
        newCoupons[payload.index].quantity += payload.increasedQuantity;
        newCoupons[payload.index].remaining += payload.increasedQuantity;
        newCoupons[payload.index].expiry = payload.expiryDate;
        newCoupons[payload.index].is_expired = payload.is_expired;
      }

      return { ...state, coupons: newCoupons, promotions: newPromotions };

    case EDIT_PROMOTION:
      newPromotions[payload.index] = payload.promotion;
      return { ...state, promotions: newPromotions };

    default:
      return state;
  }
}
