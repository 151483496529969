import {
  SET_CUSTOMERS_FETCH_LOADING,
  FETCH_CUSTOMERS,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  FETCH_USERS,
  SET_CUSTOMER_WALLET,
  SET_CUSTOMER_POINTS,
} from "../actions/actionTypes";

const initialState = {
  customers: null,
  loading: false,
  users: null,
};

export default (state = initialState, { type, payload }) => {
  let newCustomers = state.customers;
  let newUsers = state.users;
  switch (type) {
    case SET_CUSTOMERS_FETCH_LOADING:
      return { ...state, loading: payload };
    case FETCH_CUSTOMERS:
      let customerList = state.customers || [];
      return {
        ...state,
        customers: [...customerList, ...payload],
      };
    case FETCH_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case "FETCH_USER_FAVORITE_PRODUCTS": {
      const newUsers = state.users;
      const index = newUsers.findIndex(
        (user) => user.id === payload.selectedUserId
      );
      newUsers[index].favorite_products = payload.data;
      return {
        ...state,
        users: newUsers,
      };
    }

    case CREATE_CUSTOMER:
      newCustomers.push(payload);
      return { ...state, customers: newCustomers };
    case UPDATE_CUSTOMER:
      newCustomers[payload.index].full_name = payload.name;
      newCustomers[payload.index].email = payload.email;
      newCustomers[payload.index].instagram = payload.instagram;
      newCustomers[payload.index].phone = payload.phone;
      newCustomers[payload.index].notes = payload.notes;
      newCustomers[payload.index].is_blacklist = payload.isBlacklist;
      return { ...state, customers: newCustomers };
    case SET_CUSTOMER_WALLET:
      newUsers[payload.index].wallet = payload.wallet;
      return { ...state, users: newUsers };

    case SET_CUSTOMER_POINTS:
      newUsers[payload.index].points = payload.points;
      newUsers[payload.index].total_points = payload.totalPoints;
      return { ...state, users: newUsers };
    case "SET_CUSTOMER_POINTS_HISTORY": {
      const newUserIndex = newUsers.findIndex((u) => u.id === payload.id);
      if (newUserIndex !== -1) {
        newUsers[newUserIndex].history = payload.history;
      }
      return { ...state, users: newUsers };
    }
    default:
      return state;
  }
};
