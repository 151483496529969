import {
  FETCH_ORDER_ITEMS_LOADING,
  FETCH_ORDER_ITEMS,
  SAVE_BOOKING_ORDER,
  SOCKET_NEW_BOOKING_ORDER,
  UPDATE_CUSTOMER_BOOKING_STATUS,
  EDIT_BOOKING_DATE_AND_TIME,
  SOCKET_REFETCH_BOOKING_ORDER,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchCustomerBookings = (rangeStart, rangeEnd, detail, groupBy, withLoading, t) => {
  return async (dispatch) => {
    if (withLoading) dispatch({ type: FETCH_ORDER_ITEMS_LOADING });

    try {
      const res = await instance.post(`/fetch_customer_bookings/`, {
        start: rangeStart,
        end: rangeEnd,
        detail: detail,
        groupBy: groupBy,
      });
      if (res.data.success == true) {
        dispatch({ type: FETCH_ORDER_ITEMS, payload: res.data });
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};

export const saveOrderObj = (bookingID, bookingIndex, orderObj) => {
  return {
    type: SAVE_BOOKING_ORDER,
    payload: { bookingID, bookingIndex, orderObj },
  };
};

export const socketNewBookingOrder = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_NEW_BOOKING_ORDER,
      payload: data,
    });
  };
};


export const socketShouldRefetchBookings = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_REFETCH_BOOKING_ORDER,
      payload: data,
    });
  };
};

export const updateCustomerBookingStatus = (order_id, status) => {
  return {
    type: UPDATE_CUSTOMER_BOOKING_STATUS,
    payload: { order_id, status },
  };
};

export const editBookingDateAndTime = (orderItem, bookingIndex) => {
  
  return {
    type: EDIT_BOOKING_DATE_AND_TIME,
    payload: { orderItem, bookingIndex },
  };
};
