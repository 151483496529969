const trans = {
  "Message Title": "عنوان الرسالة",
  "Message Body": "نص الرسالة",
  "Sent notifications": "الاشعارات السابقة",
  "Write a message for your mobile application users":
    "قم بإرسال اشعار لمستخدمي التطبيق",
  "Mobile Notifications": "اشعارات التطبيق",
  "No registered devices yet": "لا يوجد أجهزة مسجلة حتى الآن",
  Body: "نص الرسالة",
  "Received by": "عدد المستقبلين",
  "Are you sure you want to send this message to all your mobile users?":
    "هل أنت متأكد من إرسال هذه الرسالة إلى جميع مستخدمي التطبيق؟",
};

export default trans;
