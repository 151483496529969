import {
  FETCH_CUSTOM_TEXTS_LOADING,
  FETCH_CUSTOM_TEXTS,
  SAVE_CUSTOM_TEXTS,
  SAVE_DASHBOARD_CUSTOM_MESSAGES,
  FETCH_DASHBOARD_CUSTOM_TEXTS,
} from "../actions/actionTypes";

const initialState = {
  customTexts: null,
  customTextsLoading: false,
  dashboardCustomTexts:null
};

export default (state = initialState, { type, payload }) => {
  let newCustomTexts = null;
  switch (type) {
    case FETCH_CUSTOM_TEXTS_LOADING:
      return { ...state, customTextsLoading: true };

    case FETCH_CUSTOM_TEXTS:
      return {
        ...state,
        customTexts: payload.custom_texts,
        customTextsLoading: false,
      };
    case FETCH_DASHBOARD_CUSTOM_TEXTS:
      return {
        ...state,
        dashboardCustomTexts: payload,
      };

    case SAVE_CUSTOM_TEXTS:
      return { ...state, customTexts: payload };

      case SAVE_DASHBOARD_CUSTOM_MESSAGES:
      return { ...state, dashboardCustomTexts: payload };

    default:
      return state;
  }
};
