import recurring from "./recurring";
const trans = {
  ...recurring,
  Billing: "الاشتراك",
  "Account details": "تفاصيل الحساب",
  Active: "فعال",
  "Due soon": "ينتهي قريبا",
  Overdue: "منتهي",
  "Account status": "حالة الحساب",
  "Store name": "اسم المتجر",
  "Store domain": "رابط الموقع",
  "Contract start date": "تاريخ بداية العقد",
  "Contract fee": "رسوم العقد",
  "Last payment date": "تاريخ اخر دفعة",
  "Next payment date": "تاريخ الدفعة القادمة",
  "Your invoices": "الايصالات",
  Purpose: "الغرض",
  New: "جديد",
  "Invoice details": "تفاصيل الايصال",
  Renew: "تجديد",
  "View Invoice": "عرض الفاتورة",
  "Your account is due in *** days. ": "سينتهي اشتراك حسابك بعد *** ايام. ",
  "Your account is overdue. ": "تم انتهاء اشتراك حسابك. ",
  "Please visit the billing section to renew":
    "الرجاء الذهاب الى قسم الدفع لتجديد الاشتراك",
  "Please inform your manager to renew": "الرجاء اخبار مديرك للقيام بالدفع",
  "Go to billing": "الذهاب للدفع",
  "Your payment was successful. You can view and download your invoice from the table below":
    "تم الدفع بنجاح. بإمكانك عرض وتحميل الايصال من الجدول ادناه",
  "Your payment was unsuccessful. Please try again or contact us for help":
    "فشل الدفع. الرجاء المحاولة مرة اخرى او التواصل معنا للمساعدة",
  "days grace": "يوم مهلة",
  "Pay now": "ادفع الآن",
  "Paid on": "دفع في",
  "Your renewal is due in *** days": "اشتراكك ينتهي بعد *** يوم",
  "Renew early": "جدد مبكرا",
  "Pay Now": "ادفع الان",
  "&": "و",
  save: "وفر",
  "This offer expires in *** days": "هذا العرض ينتهي بعد *** يوم",
  Subscription: "الاشتراك",
  "Billing Cycle": "دورة الاشتراك",
  "Next Bill": "تاريخ الدفعة القادمة",
  Summary: "ملخص",
  Invoices: "الفواتير",
  "Your add-ons": "الاضافات المفعلة",
  "Early renewal discount (10%)": "خصم التجديد المبكر (10%)",
  "By paying this invoice, I agree to the": "بدفع هذه الفاتورة ، أوافق على",
  "terms and conditions": "الشروط و الاحكام",
  "Terms and Conditions": "الشروط و الاحكام",
  "Credit Card": "بطاقة إئتمان",
  Benefit: "بنفت",
  "Choose a payment method": "اختر طريقة الدفع",
};

export default trans;
