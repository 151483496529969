import messages from "./messages";

let locations = {
  ...messages,
  Locations: "الأفرع",
  "New branch": "فرع جديد",
  "Select a country": "اختر الدولة",
  "Are you sure you want to delete this branch? You can't undo this":
    "هل انت متأكد من حذف هذا الفرع؟ لا يمكنك الرجوع",
  "Branch details": "تفاصيل الفرع",
  "You don't have any locations": "لا يوجد لديك اي افرع. قم بأضافة فرع جديد",
  "Could not fetch country list": "لم نتمكن من احضار الدول",
  General: "عام",
  "Enable delivery": "تفعيل التوصيل",
  "Enable pickup": "تفعيل الاستلام",
  Catalog: "المنتجات",
  "Working Hours": "ساعات العمل",
  "Delivery Hours": "ساعات التوصيل",
  "Delivery Areas and Rates": "مناطق التوصيل والتسعيرات",
  "Location's catalog": "قائمة منتجات الفرع",
  "Add products": "اضافة منتجات",
  "Select all products": "اختيار جميع المنتجات",
  "Please select the products": "الرجاء اختيار المنتجات",
  "Please select the categories": "الرجاء اختيار الأصناف",
  "Please select the modifiers": "الرجاء اختيار الإضافات",
  "All the products have been added to the *** branch":
    "جميع المنتجات تم اضافتها لفرع ***",
  BUSY: "مشغول",
  AVAILABLE: "متاح",
  "Select the products that you want to add to the *** branch. You can select multiple products at once":
    "اختر المنتجات التي تريد اضافتها لفرع ***. يمكنك اضافة اكثر من منتج مرة واحدة",
  Add: "اضافة",
  Remove: "ازالة",
  "Actions on selected products": "تغيير المنتجات المحددة",
  Unset: "غير محدد",
  "Override Stock?": "تجاوز المخزون؟",
  "Override Options?": "تجاوز الخيارات؟",
  "Stock level": "مستوى المخزون",
  "N/A": "غير متاح",
  "Edit Options": "تعديل الخيارات",
  "Turn on/off options for *** branch": "قم بتشغيل او تعطيل الخيارات لفرع ***",
  Choice: "القيمة",
  "Remove product": "ازالة المنتج",
  "Branch delivery hours": "ساعات توصيل الفرع",
  "Branch working hours": "ساعات عمل الفرع",
  "New Slots": "اضافة خانات",
  "Actions on selected slots": "التحكم في الخانات المحددة",
  "Select the days that you want to add slots to, then add time ranges that will be applied for the selected days":
    "اختر الايام التي تريد اضافة ساعات لها، ثم اختر الفترات التي سيتم تطبيقها على الايام المحددة",
  "Please select the days": "الرجاء اختيار الايام",
  "Add time ranges": "اضف الفترات",
  "Delivery areas and rates for": "مناطق التوصيل والتسعيرات لفرع",
  "Add areas": "اضافة مناطق",
  "Search for an area": "البحث عن منطقة",
  "Actions on selected areas": "التحكم في المناطق المحددة",
  "areas selected": "مناطق محددة",
  "Override Rate?": "تجاوز التكلفة؟",
  "Delivery rate": "سعر التوصيل",
  "Override minimum order value?": "تجاوز الحد الادنى للطلب",
  "Minimum order value": "الحد الادنى للطلب",
  "Override delivery mins?": "تجاوز وقت التوصيل",
  "Delivery mins": "وقت التوصيل",
  "Select the areas that you want to add to the *** branch. You can select multiple areas at once":
    "اختر المناطق التي تريد اضافتها لفرع ***. يمكنك اختيار اكثر من منطقة في وقت واحد",
  "Select all areas": "اختيار جميع المناطق",
  "Please select the areas": "الرجاء اختيار المناطق",
  "All the areas have been added to the *** branch":
    "جميع المناطق تم اضافتها لفرع ***",
  "All the countries have been added to the *** branch":
    "جميع الدول تم اضافتها لفرع ***",
  "Search for a branch": "ابحث في الافرع",
  "Manage your business by branch?": "هل تريد ادارة عملك من خلال الفرع؟",
  "If you manage your business differently per branch, turn this switch on. For example, if each branch has different catalogs, delivery rates, working hours, or other business rules, then you should switch this on.":
    "اذا أردت ادارة أعمالك عن خلال فرع, قم بتشغيل هذا الزر. من الممكن ان يكون لدى كل فرع  كتالوج خاص به و أسعار توصيل مختلفة أو ساعات عمل مختلفة, او حتى قواعد عمل مختلفة. اذا كان كذلك فيجب عليك تشغيل هذا الزر.",
  Explanation: "الشرح",
  "Branch is busy?": "الفرع مشغول؟",
  Until: "حتى",
  Branches: "الأفرع",
  "Close all branches?": "اغلاق جميع الأفرع؟",
  Timing: "التوقيت",
  "Select roles": "اختر الوظائف",
  "You have reached your limit of branches":
    "لقد وصلت العدد المتاح لديك من الافرع. الرجاء التواصل معنا للمساعدة",
  Marketing: "التسويق",
  "Marketing tools": "أدوات التسويق",
  "Inventory management tools": "أدوات إدارة المخزون",
  "Enable Google analytics": "تفعيل احصائيات جوجل",

  "Enable Facebook pixel": "تفعيل فيسبوك بيكسل",
  "Enable Snapchat pixel": "تفعيل سناب شات بيكسل",
  "Enable Ackoo": "تفعيل أكو",
  "Hide branch from store and invoice": "اخفاء الفرع من المتجر و من الفاتورة",
  "Hide branch from invoice": "اخفاء الفرع من الفاتورة",
  "Hide branch phone number": "اخفاء رقم الفرع",
  "Copy slots to other branches": "نسخ الخانات لأفرع اخرى",
  "Copy slots": "نسخ خانات",
  "Select the branch you want to copy slots to":
    "اختر الافرع التي تريد النسخ اليها",
  "Please select the branches": "الرجاء اختيار الأفرع",
  "When enabled, customers will not be able to place orders when the branch is not operating within the working hours":
    "عند التفعيل، لن يتمكن زبائنك من وضع الطلبات خارج ساعات عمل الفرع",
  "Keeping fields empty will keep the original values":
    "ترك اي خانة فارغة سيبقي القيم الاصلية",
  "Use -1 to reset the override toggle for the below fields":
    "استخدم -1 لتعطيل التجاوز وارجاع القيم الاصلية",
  "Actions on selected options": "تعديل الخيارات المحددة",
  "options selected": "خيارات محددة",
  "15 minutes": "15 دقيقة",
  "30 minutes": "30 دقيقة",
  "45 minutes": "45 دقيقة",
  "1 hour": "ساعة واحدة",
  "3 hours": "3 ساعات",
  "9 hours": "9 ساعات",
  "12 hours": "12 ساعات",
  "1 day": "يوم واحد",
  "Custom duration": "اختيار تاريخ و وقت",
  Duration: "المدة",
  "Close all branches": "اغلاق جميع الأفرع",
  Busy: "مشغول",
  "Choose time": "اختر وقت",
  "Not active": "غير فعال",
  "Close store": "اغلاق المتجر",
  "Close the branch": "اغلاق الفرع",
  Zones: "مجموعات المناطق",
  "Zones allow you to group areas together. Disabling a zone will turn off all the areas within that zone":
    "مجموعات المناطق تمكنك من تشغيل/تعطيل عدة مناطق بشكل سهل. تعطيل المجموعة سيقوم بتعطيل جميع المناطق بداخلها",
  "When the zone is enabled, only enabled areas within the zone will show to your customers":
    "عندما تكون المجموعة مفعلة، ستظهر فقط المناطق المفعلة داخل المجموعة لعملائك",
  "To enable/disable areas individually, you can visit the Delivery Areas and Rates tab":
    "لتفعيل/تعطيل المناطق فرديا، يمكنك زيارة قسم مناطق التوصيل والتسعيرات",
  "To enable/disable areas individually, you can visit the Areas tab":
    "لتفعيل/تعطيل المناطق فرديا، يمكنك زيارة قسم المناطق",
  "Create zone": "مجموعة جديدة",
  "Search for a zone": "البحث عن مجموعة",
  "Zone name": "اسم المجموعة",
  "Areas count": "عدد المناطق الاجمالي",
  "Active areas count": "عدد المناطق المفعلة",
  "Edit zone": "تعديل المجموعة",
  "All the areas have been added to this zone":
    "جميع المناطق تم اضافتها الى المجموعة",
  "Filter by distance from branch": "فلترة حسب المسافة من الفرع",
  "Select all": "اختيار الجميع",
  "Clear selection": "ازالة التحديد",
  "0 KM": "0 كم",
  "25 KM": "25 كم",
  "50 KM": "50 كم",
  "75 KM": "75 كم",
  ">= 100 KM": ">= 100 كم",
  "Delete selected": "ازالة المحدد",
  "zones selected": "مجموعات مختارة",
  "Filter by distance from": "فلترة حسب المسافة من",
  branch: "الفرع",
  "Please select a branch": "الرجاء اختيار فرع",
  "New zone": "مجموعة جديدة",
  Priority: "الأولوية",
  "The higher the number, the higher the priority":
    "الرقم الأعلى يعطي أولوية اكبر",
  "QR Code": "رمز QR",
  "Scan to automatically choose pickup for this branch":
    "استخدم هذا الرمز لاختيار الفرع  تلقائيا لطلبات الاستلام",
  "Delivery / Pickup": "التوصيل / الاستلام",
  "Add selected areas to the permissions of users with access to *** branch":
    "اضافة المناطق المختارة الى صلاحيات مستخدمي فرع ***",
  "Override minimum order (pickup)":
    "تجاوز الحد الأدنى للطلب (لطلبات الإستلام)",
  "Minimum order (pickup)": "الحد الأدنى للطلب (لطلبات الإستلام)",
  "This zone will be deleted after closing the modal if no areas are added.":
    "سيتم حذف هذه المنطقة بعد إغلاق النموذج في حالة عدم إضافة مناطق.",
  "Can't edit the follow delivery charge because its included in":
    "لا يمكن تعديل المنطقة لأنها ضمن ",
  "The area is disabled from  Areas & Rates settings.":
    "تم إغلاق المنطقة من إعدادات  المناطق و التسعيرات.",
  "Number of Branches": "عدد الأفرع",
  "This area is not assigned to any branch": "لم يتم تخصيص هذه المنطقة لأي فرع",
  "This area is assigned to all branches": "هذه المنطقة مخصصة لجميع الفروع",
  "Areas with no branches": "مناطق ليس بها فروع",
  "Areas with branches": "مناطق بها فروع",
  "Add area to branch": "أضف منطقة إلى فرع",
  "Branches not assigned to this area:": "الفروع غير المخصصة لهذه المنطقة:",
  "Branches assigned to this area:": "الفروع المخصصة لهذه المنطقة:",
  "List of branches": "قائمة الفروع",
  "Are you sure you want to delete this branch ?":
    "هل أنت متأكد أنك تريد حذف هذا الفرع؟",
  "Add selected area to the permissions of users with access to the selected branch":
    "أضف المنطقة المختارة الى صلاحيات مستخدمي الفرع المختار",
  "Help Center": "مركز المساعدة",
  "No branches": "لا يوجد فروع",
  "Add this area to branches:": "أضف هذه المنطقة إلى الفروع:",
  Assigned: "مخصص للفرع",
  "Assigned to many": "مخصص لعدة فروع",
  Unassigned: "غير مخصص",
  "This product is disabled from the master list":
    "هذا المنتج غير مفعل من قائمة المنتجات الرئيسية",
  "Same as delivery working hours": "استخدم ساعات العمل للتوصيل",
  "Branch pickup hours": "ساعات الاستلام من الفرع",
  "Scheduled Delivery Slots": "ساعات التوصيل المجدول",
  "Schedule Store Busy": "فترات الاغلاق",
  "Branch closure periods": "اغلاق الفرع في المستقبل",
  "This allows you to set the store as busy for a future duration":
    "تتيح لك هذه الخاصية اغلاق الفرع بين فترات محددة في المستقبل",
  "New Period": "فترة جديدة",
  "Actions on selected periods": "التحكم في الفترات المحددة",
  "periods selected": "فترات محددة",
  "Can accept orders for later": "يمكن قبول الطلبات للتوصيل في وقت لاحق",
  "Apply settings to all branches": "تطبيق الإعدادات على جميع الفروع",
  "Exclude delivery charges from VAT":
    "استثناء رسوم التوصيل من الضريبة المضافة",
  "You only need to use your pixel ID. To find your pixel ID, refer to the image":
    "ما عليك سوى استخدام pixel ID الخاص بك. للعثور على معرف pixel ID بك، راجع الصورة",
  "Disable cash": "تعطيل الكاش",
  "Disable auto receiving": "تعطيل استلام الطلبات تلقائيا",
  "Current coordinates": "الاحداثيات الحالية",
  "Are you sure you want to reset the address coordinates?":
    "هل انت متأكد من مسح الاحداثيات الحالية؟",
  "Clear ": "مسح",
  "Not set": "غير محدد",
};

export default locations;
