import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

//Ant

//My Components
import ProductTextField from "./ProductTextField";
import ProductFileField from "./ProductFileField";

//Actions

export class index extends Component {
  render() {
    const {
      t,
      extraFields,
      selectedExtraFields,
      addExtraFieldsToProduct,
      optionsGroups,
    } = this.props;
    let extraGroups = [];
    if (optionsGroups.length > 0) {
      extraGroups = optionsGroups.map((og) => (
        // console.log(og)
        <span key={og.id} sortOrder={og.sort_order}>
          {extraFields
            .filter((e) => e.groups.includes(og.id))
            .map((extraField) =>
              extraField.type == "text" ? (
                <ProductTextField
                  key1={`${extraField.id}${og ? "-" + og.id : ""}`}
                  extraField={extraField}
                  addExtraFieldsToProduct={this.props.addExtraFieldsToProduct}
                  selectedExtraField={
                    selectedExtraFields[
                      `${extraField.id}${og ? "-" + og.id : ""}`
                    ]
                  }
                  group={og}
                />
              ) : (
                <ProductFileField
                  key1={`${extraField.id}${og ? "-" + og.id : ""}`}
                  extraField={extraField}
                  addExtraFieldsToProduct={this.props.addExtraFieldsToProduct}
                  selectedExtraField={
                    selectedExtraFields[
                      `${extraField.id}${og ? "-" + og.id : ""}`
                    ]
                  }
                  setUploadLoading={(v) => this.props.setUploadLoading(v)}
                  group={og}
                />
              )
            )}
        </span>
      ));

      extraFields.forEach((extraField) => {
        if (extraField.groups.length <= 0) {
          if (extraField.type == "text") {
            extraGroups.push(
              <ProductTextField
                key1={extraField.id}
                extraField={extraField}
                addExtraFieldsToProduct={this.props.addExtraFieldsToProduct}
                selectedExtraField={selectedExtraFields[extraField.id]}
              />
            );
          }
          if (extraField.type == "file") {
            extraGroups.push(
              <ProductFileField
                key1={extraField.id}
                extraField={extraField}
                addExtraFieldsToProduct={this.props.addExtraFieldsToProduct}
                selectedExtraField={selectedExtraFields[extraField.id]}
                setUploadLoading={(v) => this.props.setUploadLoading(v)}
              />
            );
          }
        }
      });
      extraGroups.sort((a, b) => a.props.sortOrder - b.props.sortOrder);
    } else {
      extraGroups = extraFields.map((extraField) => {
        if (extraField.type == "text") {
          return (
            <ProductTextField
              key1={extraField.id}
              extraField={extraField}
              addExtraFieldsToProduct={this.props.addExtraFieldsToProduct}
              selectedExtraField={selectedExtraFields[extraField.id]}
            />
          );
        }
        if (extraField.type == "file") {
          return (
            <ProductFileField
              key1={extraField.id}
              extraField={extraField}
              addExtraFieldsToProduct={this.props.addExtraFieldsToProduct}
              selectedExtraField={selectedExtraFields[extraField.id]}
              setUploadLoading={(v) => this.props.setUploadLoading(v)}
            />
          );
        }
      });
    }
    return (
      <div className="my-4  ">
        <p style={{ fontSize: 20 }} className=" mt-5 text-center">
          {t("Extra Fields")}
        </p>
        {extraGroups}
      </div>
    );
  }
}

export default withNamespaces()(index);
