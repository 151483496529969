// import socketIOClient from "socket.io-client";
import io from "socket.io-client";
import { JOIN_SOCKET } from "./actionTypes";
import { fetchOrders } from "./orders";
import {
  socketNewWaitListEntry,
  socketUpdateWaitListEntry,
  socketBulkUpdateWaitListEntries,
  socketChangeEntryOrder,
  socketNewFeedBack,
} from "./";
import {
  socketNewBookingOrder,
  socketShouldRefetchBookings,
} from "./customerBookings";
import { socketNewServiceOrder } from "./serviceBookings";
import { socketFileIsReady } from "./reports";
import { socketUpdateOrderStatusMashkor } from "./orders";
import { message } from "antd";
import { logout } from "./auth";
import { Button, notification } from "antd";
import sound from "../../car.wav";
import soundWaiter from "../../waiter.mp3";
import { ReactComponent as Car } from "../../assets/icons/car.svg";
import { ReactComponent as Waiter } from "../../assets/icons/request.svg";
import { fetchPickupRequests } from "./orders";

import { updateStoreBusyDateTimeSocket } from "./pdHours";
import { updateBranchBusySocket, updateBranchBusy } from "./locations";

const playAudio = () => {
  const audio = new Audio(sound);
  audio.play();
};
const playWaiterAudio = () => {
  const audio = new Audio(soundWaiter);
  audio.play();
};

export const joinSocket = (t, history = null) => {
  return async (dispatch, getState) => {
    try {
      const socket = io("https://socket.tapcom.com/");

      socket.on("fetchIncomingOrders", function (data) {
        if (
          !data?.for_branch_id ||
          getState()
            .auth.permissions.all_permitted_branches.map((b) => b.id)
            .includes(data.for_branch_id)
        )
          dispatch(fetchOrders(t, data?.tracking_id));
      });

      // Downloads
      socket.on("newDownloadable", function (data) {
        const user_id = getState().auth.user.user_id;

        if (data.success) {
          if (data.file.user == user_id) {
            dispatch(socketFileIsReady(data.file));
            message.success(t("Your file is ready to download"));
          }
        } else message.error(t("Could not download the file. Please try again or contact us for help"));
      });

      // Customer bookings
      socket.on("fetchBookingOrder", function (data) {
        dispatch(socketShouldRefetchBookings(true));
      });

      socket.on("tapOnboardingFail", function (data) {
        dispatch({ type: "SET_SIGNED_UP_WITH_TAP", payload: false });
        dispatch({ type: "SET_TAP_PROCESSING_MODAL_OPEN", payload: false });
        dispatch({ type: "SET_TAP_FAIL_MODAL_OPEN", payload: true });
        dispatch({ type: "SET_TAP_FAIL_MESSAGE", payload: data.message });
        message.error(data.message);
      });
      socket.on("tapOnboardingSuccess", function (data) {
        dispatch({ type: "SET_SIGNED_UP_WITH_TAP", payload: true });
        dispatch({ type: "SET_TAP_PROCESSING_MODAL_OPEN", payload: false });
        dispatch({ type: "SET_TAP_SUCCESS_MODAL_OPEN", payload: true });
      });

      socket.on("newOrderForAssociatedStore", function (data) {
        dispatch({
          type: "NEW_ORDER_FOR_ASSOCIATED_STORE",
          payload: data.orderFor,
        });
      });

      socket.on("removeOrderForAssociatedStore", function (data) {
        dispatch({
          type: "REMOVE_ORDER_FOR_ASSOCIATED_STORE",
          payload: data.orderFor,
        });
      });

      socket.on("fetchServiceOrder", function (data) {
        dispatch(socketNewServiceOrder(data));
      });

      socket.on("updateBranchAvailability", function (data) {
        if (data.branch.is_store) {
          dispatch(updateStoreBusyDateTimeSocket(data.branch));
        } else {
          dispatch(updateBranchBusySocket(data.branch));
        }
      });

      // waitlist
      socket.on("newWaitingListEntry", function (data) {
        dispatch(socketNewWaitListEntry(data.entry));
      });

      socket.on("updateWaitingListEntry", function (data) {
        dispatch(socketUpdateWaitListEntry(data.entry));
      });

      socket.on("updateWaitingListEntriesOrder", function (data) {
        dispatch(socketChangeEntryOrder(data));
      });
      socket.on("bulkUpdateWaitingListEntry", function (data) {
        dispatch(
          socketBulkUpdateWaitListEntries(
            data.entries,
            data.status,
            data.waitlist_id,
            data.modified_date
          )
        );
      });

      //Feedback
      socket.on("newFeedBack", function (data) {
        dispatch(socketNewFeedBack(data.feedback));
      });

      //Feedback
      socket.on("updateStatusMashkor", function (data) {
        dispatch(socketUpdateOrderStatusMashkor(data));
      });

      socket.on("refresh", function (data) {
        window.location.reload();
      });

      socket.on("logout", function (data) {
        if (
          getState().auth?.username &&
          getState().auth.username === data.email
        ) {
          logout();
          window.location.reload();
        }
      });

      //Feedback
      socket.on("customerAtLocation", function (data) {
        dispatch(fetchPickupRequests());
        if (data.customer_name.startsWith("Table #")) playWaiterAudio();
        else playAudio();

        notification.open({
          duration: 3,
          placement:
            localStorage.getItem("language") === "arabic"
              ? "topLeft"
              : "topRight",
          message: (
            <div className="text-center">
              {data.customer_name.startsWith("Table #") ? (
                <Waiter style={{ height: 60, width: 60 }} />
              ) : (
                <Car style={{ height: 45 }} />
              )}
            </div>
          ),
          description: (
            <div
              className={
                localStorage.getItem("language") === "arabic"
                  ? "text-right"
                  : "text-left"
              }
            >
              <p>
                <span style={{ fontWeight: "bold" }}>
                  {data.customer_name}{" "}
                </span>
                {data.customer_name.startsWith("Table #")
                  ? data.paymentMethod
                    ? `${t("wants to pay using")} ${data.paymentMethod}`
                    : t("is requesting a waiter")
                  : t("is here to pickup their order")}
              </p>
              {!!data.tracking_id && (
                <p>
                  {t("Order")}:{" "}
                  <span style={{ fontWeight: "bold" }}>{data.tracking_id}</span>
                </p>
              )}
            </div>
          ),
          onClick: () => {
            if (!!data.tracking_id) {
              if (
                !window.location.pathname.includes("manage/orders") &&
                !!history
              ) {
                history.replace("/manage/orders");
                window.setTimeout(
                  () =>
                    dispatch({
                      type: "SET_OPEN_ORDER_MODAL",
                      payload: data.tracking_id,
                    }),
                  300
                );
              } else
                dispatch({
                  type: "SET_OPEN_ORDER_MODAL",
                  payload: data.tracking_id,
                });
            }
          },
        });
      });

      dispatch({
        type: JOIN_SOCKET,
        payload: socket,
      });
    } catch (err) {
      console.error(err);
    }
  };
};
