let messagesTranslation = {
  "Changed product status successfully": "تم تغيير حالة المنتج بنجاح",
  "Edited prep. time successfully": "تم تغيير وقت التحضير بنجاح",
  "Edited sort order successfully": "تم تغيير الترتيب بنجاح",
  "The product is already deleted": "تم حذف هذا المنتج مسبقا",
  "Product was deleted successfully": "تم حذف المنتج بنجاح",
  "Image was changed successfully": "تم تغيير الصورة بنجاح",
  "Inventory was modified successfully": "تم تعديل المخزون بنجاح",
  "Settings saved successfully": "تم حفظ الاعدادات بنجاح",
  "Product duplicated successfully": "تم تكرار المنتج بنجاح",
  "Product updated successfully": "تم تحديث المنتج بنجاح",
  "Image deleted successfully": "تم حذف الصورة بنجاح",
  "Products updated successfully": "تم تحديث المنتجات بنجاح",
  "Products deleted successfully": "تم حذف المنتجات بنجاح",
  "Product created successfully": "تم انشاء المنتج بنجاح",
  "Category created successfully": "تم انشاء الصنف بنجاح",
  "Updated inventory successfully": "تم تحديث المخزون بنجاح",
  "Category deleted successfully": "تم حذف الصنف بنجاح",
  "Category has been toggled successfully": "تم تغيير حالة الصنف بنجاح",
  "Couldn't toggle category status": "لم نتمكن من تغيير حالة الصنف",
  "Name has changed successfully": "تم تغيير الاسم بنجاح",
  "Couldn't change category name": "لم نتمكن من تغيير اسم الصنف",
  "Order has changed successfully": "تم تغيير الترتيب بنجاح",
  "Couldn't change category order": "لم نتمكن من تغيير ترتيب الصنف",
  "Changed status successfully": "تم تغيير الحالة بنجاح",
  "Deleted categories successfully": "تم حذف الاصناف بنجاح",
  "Deleted successfully": "تم الحذف بنجاح",
  "Added successfully": "تمت الاضافة بنجاح",
  "Saved successfully": "تم الحفظ بنجاح",
  "Toggled successfully": "تم تغيير الحالة بنجاح",
  "Toggled file successfully": "تم تغيير حالة الملف بنجاح",
  "Deleted file successfully": "تم حذف الملف بنجاح",
  "Changed name successfully": "تم تغيير الاسم بنجاح",
  "Slot toggled successfully": "تم تغيير حالة الخانة بنجاح",
  "Slot deleted successfully": "تم حذف الخانة بنجاح",
  "Slot duplicated successfully": "تم تكرار الخانة بنجاح",
  "Inventory edited successfully": "تم تعديل المخزون بنجاح",
  "Edited successfully": "تم التعديل بنجاح",
  "Updated choice successfully": "تم تحديث القيمة بنجاح",
  "Updated option successfully": "تم تحديث الخيار بنجاح",
  "Deleted option successfully": "تم حذف الخيار بنجاح",
  "Created option successfully": "تم انشاء الخيار بنجاح",
  "Key updated successfully": "تم تحديث العمود بنجاح",
  "Key deleted successfully": "تم حذف العمود بنجاح",
  "Key created successfully": "تم انشاء عمود بنجاح",
  "Variant created successfully": "تم انشاء نوع جديد بنجاح",
  "Changed successfully": "تم التعديل بنجاح",
  "Detached successfully": "تم الفصل بنجاح",
  "Variants created!": "تم عمل الأنواع بنجاح!",
  "Minimum order value has changed successfully": "تم تغيير الحد الأدنى بنجاح",
  "Couldn't change category minimum order value":
    "لم نتمكن من تغيير الحد الأدنى",
  "Copied options successfully": "تم نسخ الخيارات بنجاح",
  "Attributes saved successfully": "تم حفظ الخصائص بنجاح",
  "Modified slots successfully": "تم تعديل الخانات بنجاح",
  "Cin7 code": "رمز سين7",
  "Price was modified successfully": "تم تعديل السعر بنجاح",
  "Please add a category first": "الرجاء اضافة صنف اولا",
  "Reset successfully": "تم إعادة الضبط بنجاح",
  "Please add variant keys": "الرجاء اضافة عمود جديد",
  "Those products don't have a variant key. Make sure you add variant keys to them":
    "هذه المنتجات لا تحتوي على نوع. تأكد من انك اضفت نوع لها",
};

export default messagesTranslation;
