import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { GetEnglishOrArabic } from "../../";
//Ant
import { Radio, Checkbox, Input, Row, Col } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

//Actions

export class OptionChoice extends Component {
  state = { quantity: this.props.selected, radioValue: this.props.choice.id };

  handleChoiceClick = (addOption, quantity, quantityDifference) => {
    const {
      choice,
      option,
      productQuantity,
      productUsedComponents,
      usedComponents,
      editedProduct,
      groupID,
    } = this.props;

    if (
      quantityDifference > 0 &&
      choice.related_product &&
      choice.inventory !== null
    ) {
      let modifierAddedQuantity =
        ((!option.quantity_exclusive && productQuantity) || 1) +
        (productUsedComponents[choice.related_product?.id + "Q"] || 0) +
        (productUsedComponents[choice.related_product?.id] || 0) *
          productQuantity;

      const choiceInventory =
        choice.inventory +
        (editedProduct?.usedComponents?.[choice.related_product?.id] *
          editedProduct?.quantity || 0) +
        (editedProduct?.usedComponents?.[choice.related_product?.id + "Q"] ||
          0) -
        (usedComponents[choice.related_product?.id] || 0);

      if (choiceInventory - modifierAddedQuantity < 0) return;
    }
    this.props.addOptionToProduct(
      this.props.choice,
      this.props.option,
      quantity,
      quantityDifference,
      addOption,
      groupID
    );
    this.setState({ selected: addOption, quantity: quantity });
  };

  render() {
    const {
      t,
      option,
      choice,
      selectedOverall,
      settings,
      selected,
      config,
      usedComponents,
      productUsedComponents,
      productQuantity,
      editedProduct, // mabe
    } = this.props;
    const { quantity } = this.state;
    const is_radio_option = option.maximum === 1;
    const is_multiple = option.multiple && option.maximum !== 1;

    const currency =
      config.language === "arabic"
        ? settings.currency_local
        : settings.currency_english;

    const isOutOfStock =
      choice.inventory !== null &&
      choice.inventory +
        (editedProduct?.usedComponents?.[choice.related_product?.id] *
          editedProduct?.quantity || 0) +
        (editedProduct?.usedComponents?.[choice.related_product?.id + "Q"] ||
          0) -
        (usedComponents[choice.related_product?.id] || 0) <=
        0;

    let counter = (
      <Row className="mt-3 disable-select">
        <MinusCircleOutlined
          style={{ fontSize: "25px", color: "#0099CC" }}
          onClick={(event) => {
            event.stopPropagation();
            let newQuantity = quantity;
            if (!newQuantity) return;
            let newSelected = selected;
            newQuantity -= 1;
            if (newQuantity <= 0) {
              newQuantity = 0;
              newSelected = false;
            }
            // this.setState({ quantity: newQuantity, selected: newSelected });
            this.handleChoiceClick(newSelected, newQuantity, -1);
          }}
        />
        <p style={{ border: "none", textAlign: "center", width: 30 }}>
          {quantity}
        </p>
        <PlusCircleOutlined
          style={{ fontSize: "25px", color: "#0099CC" }}
          onClick={(event) => {
            event.stopPropagation();

            if (
              selectedOverall < (option.maximum || Infinity) &&
              quantity < (choice.maximum || Infinity)
            ) {
              let newQuantity = quantity;
              newQuantity += 1;
              // this.setState({ quantity: newQuantity, selected: true });
              this.handleChoiceClick(true, newQuantity, 1);
            }
          }}
        />
      </Row>
    );

    return (
      <Row
        onClick={() => {
          if (isOutOfStock) return;
          if (is_radio_option) {
            const newSelected = !selected;

            if (!option.is_required || newSelected) {
              this.handleChoiceClick(newSelected, parseInt(newSelected * 1), 1);
              this.props.setRadioValue((newSelected && choice.id) || null);
            }
          } else {
            const checked = !selected;
            if (selectedOverall < (option.maximum || Infinity) || !checked) {
              this.handleChoiceClick(
                checked,
                parseInt(checked * 1),
                checked ? 1 : -quantity
              );
            }
          }
        }}
        className={`mx-auto my-3 OptionChoiceContainer ${
          selected && "OptionChoiceContainerSelected"
        }`}
        style={{
          cursor: !isOutOfStock && "pointer",
          border: "1px solid #d9d9d9",
          width: "100%",
          minHeight: 50,
          opacity: (isOutOfStock && 0.3) || 1,
        }}
      >
        <Col span={1} offset={1} className="my-auto">
          {is_radio_option ? (
            <Radio value={choice.id} checked={selected} />
          ) : (
            <Checkbox key="Option1" data-id="option1" checked={selected} />
          )}
        </Col>

        <Col span={!is_multiple ? 12 : 7} offset={2} className="pt-3">
          <p
            style={{
              textAlign: "center",
              wordBreak: "break-word",
            }}
          >
            {GetEnglishOrArabic(choice.value, choice.ar_value, config.language)}
          </p>
        </Col>
        <Col
          span={!is_multiple ? 6 : 4}
          offset={2}
          className="pt-3"
          style={{ alignSelf: "center" }}
        >
          <p style={{ whiteSpace: "nowrap" }}>
            {`${choice.price.toFixed(settings.currency_decimals)}`}
            <span style={{ whiteSpace: "break-spaces" }}> {currency}</span>
          </p>
        </Col>
        {is_multiple && (
          <Col span={6} offset={1} style={{ alignSelf: "center" }}>
            {counter}
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.auth.settings,
  config: state.config,
  usedComponents: state.pos.usedComponents,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OptionChoice));
