import { combineReducers } from "redux";
import socket from "./socket";
import orders from "./orders";
import auth from "./auth";
import config from "./config";
import products from "./products";
import locations from "./locations";
import businessOrders from "./businessOrders";
import faqs from "./faq";
import pdHours from "./pdHours";
import deliverySettings from "./deliverySettings";
import appearance from "./appearance";
import promotions from "./promotions";
import waitList from "./waitList";
import permissions from "./permissions";
import integration from "./integration";
import feedbacks from "./feedback";
import customers from "./customers";
import businesses from "./businesses";
import customerBookings from "./customerBookings";
import serviceBookings from "./serviceBookings";
import reports from "./reports";
import pos from "./pos";
import health from "./health";
import account from "./account";
import ordersLimits from "./ordersLimits";
import profile from "./profile";
import serviceProviders from "./serviceProviders";
import pages from "./pages";
import banners from "./banners";
import news from "./news";
import home from "./home";
import addons from "./addons";
import driver from "./drivers"


const rootReducer = combineReducers({
  auth: auth,
  config: config,
  socket: socket,
  orders: orders,
  products: products,
  locations: locations,
  businessOrders: businessOrders,
  faqs: faqs,
  pdHours: pdHours,
  deliverySettings: deliverySettings,
  appearance: appearance,
  promotions: promotions,
  waitList: waitList,
  rolesAndUsers: permissions,
  feedbacks: feedbacks,
  integration: integration,
  customers: customers,
  businesses: businesses,
  customerBookings: customerBookings,
  reports: reports,
  pos: pos,
  health: health,
  account: account,
  ordersLimits: ordersLimits,
  profile: profile,
  serviceProviders: serviceProviders,
  serviceBookings: serviceBookings,
  pages: pages,
  banners: banners,
  news: news,
  home: home,
  addons: addons,
  driver: driver
});

export default rootReducer;
