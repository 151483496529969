let tr;

export default tr = {
  Feedback: "التعليقات",
  Rating: "التقيم",
  Comment: "التعليق",
  "Customer's Name": "اسم العميل",
  "Phone Number": "رقم الهاتف",
  "Order ID": "رمز الطلب",
  "Reorder":"اعادة الطلب",
  "Search for a feedback": "إبحث عن تعليق",
  "Search for a survey": "ابحث عن استبيان",
  Read: "مقروء",
  Unread: "غير مقروء",
  "Read?": "مقروء؟",
  "Invoice not found": "لم يتم العثور على الفاتوره",
  "Order related feedbacks": "تعليقات على طلبات",
  "Other feedbacks": "تعليقات أخرى",
  "Actions on selected feedbacks": "التحكم في التعليقات المحددين",
  "feedback selected": "تعليق محدد",
  "Set read to": "تغير حالة القراءة الى",
  "read feedback": "التعليقات المقروءة",
  "unread feedback": "التعليقات غير المقروة",
  "View Feedback": "مشاهدة التعليق",
  feedback: "تعليق",
  "New Survey": "استبيان جديد",
  Reference: "المرجع",
  Share: "نشر",
  "Submitted Date": "تاريخ التقديم",
  "Are you sure you want to delete this survey?":"هل انت متاكد من حذف هذا الاستبيان",
  "Survey deleted successfully":"تم حذف الاستبيان بنجاح",
  Pending: "لم يقدم",
  Submitted: "قدم",
  "Share it Through": "انشرها بواسطة",
  "Show on website": "اعرضها على الموقع",
  "Create New Survey": "انشاء استبيان جديد",
  "Survey created successfully": "تم اضافة الاستبيان بنجاح",
  "Create a survey and share to collect ratings and comments from your customers":
    "اصنع استبيان و شاركه مع زبائنك لجمع التقييمات و التعليقات ",
  "Quick Create": "انشاء فوري",
  "A way to differentiate between responses": "طريقة للتمييز بين الردود",
};
