import {
  FETCH_SERVICE_PROVIDERS,
  CHANGE_PROVIDER_PHOTO,
  DELETE_PROVIDER_PHOTO,
  EDIT_PROVIDER_FIELD,
  CHANGE_PROVIDER_BRANCH,
  TOGGLE_PROVIDER,
  DELETE_PROVIDER,
  CREATE_NEW_PROVIDER,
  BULK_TOGGLE_PROVIDERS,
  BULK_DELETE_PROVIDERS,
  FETCH_PROVIDER_SERVICES,
  TOGGLE_PROVIDER_SERVICE,
  DELETE_PROVIDER_SERVICE,
  BULK_DELETE_PROVIDER_SERVICES,
  BULK_TOGGLE_PROVIDER_SERVICES,
  FETCH_PROVIDER_SLOTS,
  DELETE_RESOURCE_AVAILABILITY_SLOT,
  TOGGLE_RESOURCE_AVAILABILITY_SLOT,
  CREATE_RESOURCE_AVAILABILITY_SLOTS,
  BULK_DELETE_RESOURCE_AVAILABILITIES,
  EDIT_RESOURCE_AVAILABILITY,
  EDIT_BREAK_MINUTES,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  serviceProviders: [],
  branches: [],
};

export default (state = initialState, { type, payload }) => {
  let newServiceProviders = [];
  let index = null;
  let index2 = null;
  switch (type) {
    case "FETCH_SERVICE_PROVIDERS_LOADING":
      return { ...state, loading: true };

    case FETCH_SERVICE_PROVIDERS:
      return {
        ...state,
        serviceProviders: payload.resources,
        branches: payload.branches,
        loading: false,
      };

    case CHANGE_PROVIDER_PHOTO:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index].photo = payload.photo;
      newServiceProviders[index].photo_small = payload.photo_small;

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case DELETE_PROVIDER_PHOTO:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index].photo = null;
      newServiceProviders[index].photo_small = null;

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case EDIT_PROVIDER_FIELD:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index][payload.field] = payload.newValue;

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case CHANGE_PROVIDER_BRANCH:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index].branch = payload.branch_id;

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };
    case TOGGLE_PROVIDER:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index].is_active = !newServiceProviders[index]
        .is_active;

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };
    case DELETE_PROVIDER:
      newServiceProviders = state.serviceProviders;
      newServiceProviders = newServiceProviders.filter(
        (s) => s.id != payload.id
      );

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case CREATE_NEW_PROVIDER:
      return {
        ...state,
        serviceProviders: [payload.resource, ...state.serviceProviders],
      };

    case BULK_TOGGLE_PROVIDERS:
      newServiceProviders = state.serviceProviders;
      newServiceProviders = newServiceProviders.map((s) =>
        payload.ids.includes(s.id) ? { ...s, is_active: payload.status } : s
      );
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case BULK_DELETE_PROVIDERS:
      newServiceProviders = state.serviceProviders;
      newServiceProviders = newServiceProviders.filter(
        (s) => !payload.ids.includes(s.id)
      );
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case FETCH_PROVIDER_SERVICES:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index].services = payload.services;
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case TOGGLE_PROVIDER_SERVICE:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      index2 = newServiceProviders[index].services.findIndex(
        (s) => s.id == payload.id
      );
      newServiceProviders[index].services[
        index2
      ].is_active = !newServiceProviders[index].services[index2].is_active;

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case DELETE_PROVIDER_SERVICE:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      newServiceProviders[index].services = newServiceProviders[
        index
      ].services.filter((s) => s.id != payload.id);

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case BULK_TOGGLE_PROVIDER_SERVICES:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      newServiceProviders[index].services = newServiceProviders[
        index
      ].services.map((s) =>
        payload.ids.includes(s.id) ? { ...s, is_active: payload.status } : s
      );

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case BULK_DELETE_PROVIDER_SERVICES:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);

      newServiceProviders[index].services = newServiceProviders[
        index
      ].services.filter((s) => !payload.ids.includes(s.id));

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case FETCH_PROVIDER_SLOTS:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.id);
      newServiceProviders[index].slots = payload.slots;
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case DELETE_RESOURCE_AVAILABILITY_SLOT:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      newServiceProviders[index].slots = newServiceProviders[
        index
      ].slots.filter((s) => s.id != payload.id);

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case TOGGLE_RESOURCE_AVAILABILITY_SLOT:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      index2 = newServiceProviders[index].slots.findIndex(
        (s) => s.id == payload.id
      );
      newServiceProviders[index].slots[index2].is_active = !newServiceProviders[
        index
      ].slots[index2].is_active;
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case CREATE_RESOURCE_AVAILABILITY_SLOTS:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      newServiceProviders[index].slots = [
        ...payload.slots,
        ...newServiceProviders[index].slots,
      ].sort((a, b) => {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        else {
          if (a.start > b.start) return 1;
          return -1;
        }
      });
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case BULK_DELETE_RESOURCE_AVAILABILITIES:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);

      newServiceProviders[index].slots = newServiceProviders[
        index
      ].slots.filter((s) => !payload.ids.includes(s.id));

      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case EDIT_RESOURCE_AVAILABILITY:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      index2 = newServiceProviders[index].slots.findIndex(
        (s) => s.id == payload.id
      );
      if (payload.type != "all_day")
        newServiceProviders[index].slots[index2][payload.type] = payload.value;
      else {
        newServiceProviders[index].slots[index2].all_day = !newServiceProviders[
          index
        ].slots[index2].all_day;
        newServiceProviders[index].slots[index2].start = "00:00:00";
        newServiceProviders[index].slots[index2].end = "23:59:00";
      }
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    case EDIT_BREAK_MINUTES:
      newServiceProviders = state.serviceProviders;
      index = newServiceProviders.findIndex((s) => s.id == payload.providerID);
      index2 = newServiceProviders[index].services.findIndex(
        (s) => s.id == payload.id
      );
      newServiceProviders[index].services[index2].break_minutes =
        payload.breakMinutes;
      return {
        ...state,
        serviceProviders: newServiceProviders,
      };

    default:
      return state;
  }
};
