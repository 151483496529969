import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import { Modal, Button, InputNumber, Row, Col } from "antd";

class ProductDescription extends Component {
  render() {
    let { t, config, visible, product } = this.props;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Product Description")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={null}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "10px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="mt-3 mb-4 text-left">
          {(config.language == "arabic" && product.ar_short_description) ||
            product.short_description}

          <div
            id="description"
            dangerouslySetInnerHTML={{
              __html:
                (config.language === "arabic" && product.ar_description) ||
                product.description,
            }}
            style={{
              direction: config.language == "arabic" ? "rtl" : "ltr",
            }}
          ></div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(ProductDescription));
