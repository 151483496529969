import {
  FETCH_ALL_PRODUCTS,
  SET_PRODUCTS_FETCH_LOADING,
  TOGGLE_PRODUCT_STATUS,
  DELETE_PRODUCT,
  UNDELETE_PRODUCT,
  CHANGE_INVENTORY,
  QUICK_CHANGE_PRODUCT_IMAGE,
  ADD_PHOTO_TO_GALLERY,
  DELETE_GALLERY_IMAGE,
  DELETE_PRODUCT_IMAGE,
  UPDATE_PRODUCT,
  UPDATE_ASSOCIATED_BRANCHES,
  CREATE_PRODUCT,
  CREATE_CATEGORY,
  RESTORE_PRODUCTS,
  DELETE_CATEGORY,
  TOGGLE_CATEGORY_STATUS,
  CHANGE_CATEGORY_NAME,
  CHANGE_CATEGORY_ORDER,
  QUICK_CHANGE_CATEGORY_IMAGE,
  DELETE_CATEGORY_IMAGE,
  DELETE_PRODUCT_AVAILABILITY,
  UPDATE_OPTION,
  CREATE_OPTION,
  DELETE_OPTION,
  DUPLICATE_PRODUCT,
  DELETE_VARIANT_KEY,
  REPLACE_PRODUCTS,
  BULK_TOGGLE_CATEGORIES,
  BULK_DELETE_CATEGORIES,
  UPDATE_BOOKING_SLOT_LOCALLY,
  CHANGE_CATEGORY_MINIMUM,
  CHANGE_CATEGORY_PARENT,
  ADD_PRODUCT_LIMIT,
  CONTROL_PRODUCT_LIMIT,
  CHANGE_CATEGORY_PASSWORD,
  CHANGE_CATEGORY_BRAND_ID,
  SAVE_BULK_OPTIONS_IN_PRODUCTS,
  ADD_PRODUCT_COMPONENT,
  CONTROL_PRODUCT_COMPONENT,
  ADD_PRODUCT_UNIT,
  ADD_SUPPLIER,
  CONTROL_UNIT,
  BULK_DELETE_UNITS,
  CONTROL_SUPPLIER,
  BULK_DELETE_SUPPLIERS,
  BULK_UPDATE_COMPOSITE_INVENTORIES,
  CONTROL_EXTRA_FIELD,
  ADD_EXTRA_FIELD,
  UPDATE_PRODUCTS_EXTRA_FIELDS,
  UPDATE_PRODUCTS_OPTIONS,
  UPDATE_EXTRA_FIELD,
  UPDATE_PRODUCT_BARCODE,
  UPDATE_BRANCH_PRODUCT_MASTER,
  RESET_PRODUCT_LIST,
  UPDATE_MODIFIER,
  ADD_MODIFIER,
  CONTROL_MODIFIER,
  TOGGLE_CATEGORY_MINGLING,
  CHANGE_PRICE,
  CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL,
  UPDATE_CATEGORY_DESCRIPTION,
  FETCH_OPTIONS_GROUPS,
  ADD_OPTIONS_GROUP,
  CONTROL_OPTIONS_GROUP,
  SET_OPTIONS_GROUPS_LOADING,
  UPDATE_CATEGORY_AVAILABILITY,
  ADD_SECTION,
  FETCH_SECTIONS,
  SET_SECTIONS_LOADING,
  CONTROL_SECTION,
} from "../actions/actionTypes";
import { isEmpty } from "../reducers/pos";

const initialState = {
  loadingAll: true,
  allProducts: [],
  categories: [],
  productsCount: null,
  loadedProducts: 0,
  units: [],
  suppliers: [],
  modifiers: [],
  optionsGroups: null,
  optionsGroupsLoading: false,
  branches: [],
  filters: [],
  tags: null,
  sections: [],
  sectionsLoading: false,

  allFeedbacks: [],
};

export default (state = initialState, { type, payload }) => {
  const updateCategories = (oldCategory, newCategory) => {
    let categories = state.categories;
    const oldCatIndx = categories.findIndex((cat) => cat.id == oldCategory);
    const newCatIndx = categories.findIndex((cat) => cat.id == newCategory);

    if (categories[oldCatIndx])
      categories[oldCatIndx].products_count =
        categories[oldCatIndx].products_count - 1;
    if (categories[newCatIndx])
      categories[newCatIndx].products_count =
        categories[newCatIndx].products_count + 1;
    return categories;
  };

  let newProducts = [];
  let newCategories = [];
  let newUnits;
  let newSuppliers;
  let index = null;
  let newModifiers;

  let product;
  switch (type) {
    case FETCH_ALL_PRODUCTS:
      newProducts = state.allProducts;
      newProducts = [...newProducts, ...payload.products];

      return {
        ...state,
        allProducts: newProducts,
        categories: state.categories.length
          ? state.categories
          : payload.categories,
        loadingAll:
          state.allProducts.length + payload.products.length ==
          payload.products_count
            ? false
            : true,
        productsCount: payload.products_count,
        loadedProducts: state.allProducts.length + payload.products.length,
        units: !state.units.length ? payload.units : state.units,
        suppliers: !state.suppliers.length
          ? payload.suppliers
          : state.suppliers,
        sections: !state.sections.length ? payload.sections : state.sections,

        modifiers: !state.modifiers.length
          ? payload.modifiers
          : state.modifiers,
      };
    case SET_PRODUCTS_FETCH_LOADING:
      return { ...state, loadingAll: true };

    case TOGGLE_PRODUCT_STATUS:
      const id = payload.id;
      const ptype = payload.type;
      index = state.allProducts.findIndex((product) => product.id == id);
      let newAllProducts = state.allProducts;
      if (ptype === "pos") {
        newAllProducts[index].pos_is_active =
          !newAllProducts[index].pos_is_active;
      } else {
        newAllProducts[index].is_active = !newAllProducts[index].is_active;
      }
      return { ...state, allProducts: newAllProducts };

    case DELETE_PRODUCT:
      const index2 = state.allProducts.findIndex(
        (product) => product.id == payload
      );
      let newAllProducts2 = state.allProducts;
      newAllProducts2[index2].is_deleted = true;

      //Reducing products count from the category it was deleted from
      let Categories = state.categories;
      const categoryIndx = Categories.findIndex(
        (cat) => cat.id == newAllProducts2[index2].category_id
      );
      if (categoryIndx >= 0)
        Categories[categoryIndx].products_count =
          Categories[categoryIndx].products_count - 1;

      // If it's variant, and the only variant, uncheck has_variants of the parent
      if (newAllProducts2[index2].is_variant) {
        const parentIndex = newAllProducts2.findIndex(
          (product) => product.id == newAllProducts2[index2].parent
        );
        const variants = newAllProducts2.filter(
          (product) =>
            product.parent == newAllProducts2[index2].parent &&
            product.is_deleted == false
        );
        if (variants.length == 0) {
          newAllProducts2[parentIndex].has_variants = false;
        }
      }
      return { ...state, allProducts: newAllProducts2, categories: Categories };

    case UNDELETE_PRODUCT:
      const index3 = state.allProducts.findIndex(
        (product) => product.id == payload
      );
      let newAllProducts3 = state.allProducts;
      newAllProducts3[index3].is_deleted = false;
      return { ...state, allProducts: newAllProducts3 };

    case CHANGE_INVENTORY:
      const index4 = state.allProducts.findIndex(
        (product) => product.id == payload.id
      );
      let newAllProducts4 = state.allProducts;
      newAllProducts4[index4].inventory_on_hand = payload.newInventory;

      return { ...state, allProducts: newAllProducts4 };

    case QUICK_CHANGE_PRODUCT_IMAGE:
      const index5 = state.allProducts.findIndex(
        (product) => product.id == payload.id
      );
      let newAllProducts5 = state.allProducts;
      newAllProducts5[index5].photo_thumb = payload.photo;
      newAllProducts5[index5].photo = payload.photo;
      let newModifiers5 = state.modifiers;
      payload.modifiers_id.forEach(async (modifier2) => {
        let index50 = state.modifiers.findIndex(
          (modifier) => modifier.id == modifier2
        );
        newModifiers5[index50].photo_thumb = payload.photo;
        newModifiers5[index50].photo = payload.photo;
      });
      return {
        ...state,
        allProducts: newAllProducts5,
        modifiers: newModifiers5,
      };

    case ADD_PHOTO_TO_GALLERY:
      const index6 = state.allProducts.findIndex(
        (product) => product.id == payload.id
      );
      let newAllProducts6 = state.allProducts;
      newAllProducts6[index6].gallery = [
        ...newAllProducts6[index6].gallery,
        { photo: payload.photo, id: payload.pmid, sort_order: 0 },
      ];
      return { ...state, allProducts: newAllProducts6 };

    case DELETE_GALLERY_IMAGE:
      const index7 = state.allProducts.findIndex(
        (product) => product.id == payload.pid
      );

      let newAllProducts7 = state.allProducts;
      //   let productObj = newAllProducts7[index7]
      //   const imageIndex = productObj.gallery.findIndex(image => image.id == payload.pmid)
      newAllProducts7[index7].gallery = newAllProducts7[index7].gallery.filter(
        (image) => image.id != payload.pmid
      );

      return { ...state, allProducts: newAllProducts7 };
    case "SORT_ORDER_GALLERY_IMAGE": {
      const index7 = state.allProducts.findIndex(
        (product) => product.id === payload.pid
      );

      let newAllProducts7 = state.allProducts;
      const newSortOrderTable = payload.newSortOrder.reduce((acc, item) => {
        acc[item.id] = item.sort_order;
        return acc;
      }, {});
      newAllProducts7[index7].gallery.forEach((image) => {
        image.sort_order = newSortOrderTable[image.id];
      });
      // newAllProducts7[index7].gallery.sort(
      //   (a, b) => a.sort_order - b.sort_order
      // );
      return { ...state, allProducts: newAllProducts7 };
    }

    case DELETE_PRODUCT_IMAGE:
      const index8 = state.allProducts.findIndex(
        (product) => product.id == payload.pid
      );
      let newAllProducts8 = state.allProducts;
      newAllProducts8[index8].photo_thumb = null;
      newAllProducts8[index8].photo = null;
      newAllProducts8[index8].photo_small = null;
      newAllProducts8[index8].photo_medium = null;
      newAllProducts8[index8].photo_large = null;
      newAllProducts8[index8].photo_retina = null;
      newAllProducts8[index8].store_cover__product = null;
      return { ...state, allProducts: newAllProducts8 };
    case UPDATE_PRODUCT:
      const pid = payload.id;

      const index9 = state.allProducts.findIndex(
        (product) => product.id == pid
      );
      let newAllProducts9 = state.allProducts;
      const oldCategory = state.allProducts[index9].category_id;
      newAllProducts9[index9] = {
        ...payload,
        inventory_on_hand: newAllProducts9[index9].inventory_on_hand,
      };
      if (!newAllProducts9[index9].did_load)
        newAllProducts9[index9].did_load = true;
      //This is to update the categories products count by reducing old category and incrementing new category
      const newUpdatedCategories = updateCategories(
        oldCategory, //id
        payload.category_id //id
      );
      if (oldCategory != payload.category_id) {
        newAllProducts9 = newAllProducts9.map((product) => {
          let tempProd = product;
          if (product.parent == payload.id) {
            tempProd.category_id = payload.category_id;
            tempProd.category_slug = payload.category_slug;
            tempProd.category_name = payload.category_name;
          }
          return tempProd;
        });
      }

      return {
        ...state,
        allProducts: newAllProducts9,
        categories: newUpdatedCategories,
        branches: payload.branches || state.branches,
      };

    case UPDATE_ASSOCIATED_BRANCHES:
      const productID = payload.pid;

      const product_index = state.allProducts.findIndex(
        (product) => product.id == productID
      );
      let newAllProducts11 = state.allProducts;
      newAllProducts11[product_index].associated_branches =
        payload.associated_branches;

      return {
        ...state,
        allProducts: newAllProducts11,
      };

    case UPDATE_BRANCH_PRODUCT_MASTER:
      const productid = payload.id;
      const key = payload.key;
      const index10 = state.allProducts.findIndex(
        (product) => product.id == productid
      );

      const newAllProducts10 = state.allProducts;

      newAllProducts10[index10].associated_branches.forEach((branch, indx) => {
        if (payload.bid == branch.branch_id) {
          branch[key] = payload.value;
        }
      });
      return {
        ...state,
        allProducts: newAllProducts10,
      };

    case CREATE_PRODUCT:
      let products = state.allProducts;
      products = [payload, ...products];

      //Increasing products count for the category it belongs to
      let tmpCategories = state.categories;
      const catIndx = tmpCategories.findIndex(
        (cat) => cat.id == payload.category_id
      );
      tmpCategories[catIndx].products_count =
        tmpCategories[catIndx].products_count + 1;
      return { ...state, allProducts: products, categories: tmpCategories };

    case RESTORE_PRODUCTS:
      let products2 = state.allProducts;

      products2 = [...payload, ...products2];

      // Increasing products count for each category the products belong to
      let tmpCategories2 = state.categories;
      payload.forEach((product) => {
        const catIndx = tmpCategories2.findIndex(
          (cat) => cat.id === product.category_id
        );
        if (catIndx !== -1) {
          tmpCategories2[catIndx].products_count += 1;
        }
      });

      return { ...state, allProducts: products2, categories: tmpCategories2 };

    case CREATE_CATEGORY:
      let tempCategories = state.categories;
      tempCategories = [payload, ...tempCategories];
      tempCategories.sort((a, b) => a.order - b.order);

      return { ...state, categories: tempCategories };

    case DELETE_CATEGORY:
      newProducts = state.allProducts;
      newProducts.forEach((prod, indx) => {
        if (prod.category_id == payload) {
          newProducts[indx].category_id = null;
          newProducts[indx].category_slug = null;
          newProducts[indx].category_name = null;
        }
      });
      newCategories = state.categories;
      const theToDeleteCategory = state.categories.find((c) => c.id == payload);
      if (theToDeleteCategory?.has_children) {
        newCategories = newCategories.map((c) => {
          if (theToDeleteCategory.sub_categories.includes(c.id))
            return { ...c, is_child: false };
          return c;
        });
      }
      newCategories = newCategories.filter(
        (category) => category.id != payload
      );

      return { ...state, categories: newCategories, allProducts: newProducts };

    case TOGGLE_CATEGORY_STATUS:
      newCategories = state.categories;

      let indx = newCategories.findIndex((cat) => cat.id == payload);
      newCategories[indx].is_active = !newCategories[indx].is_active;
      return { ...state, categories: newCategories };

    case CHANGE_CATEGORY_NAME:
      const cid = payload.id;
      const newName = payload.newName;
      const language = payload.language;

      newCategories = state.categories;

      let indx2 = newCategories.findIndex((cat) => cat.id == cid);
      if (language == "english") newCategories[indx2].name = newName;
      else newCategories[indx2].ar_name = newName;

      return { ...state, categories: newCategories };
    case CHANGE_CATEGORY_ORDER:
      const cid2 = payload.id;
      const newOrder = payload.newOrder;

      let newCategories4 = state.categories;

      let indx3 = newCategories4.findIndex((cat) => cat.id == cid2);
      newCategories4[indx3].order = newOrder;

      newCategories4.sort((a, b) => a.order - b.order);

      return { ...state, categories: newCategories4 };
    case CHANGE_CATEGORY_MINIMUM:
      const cid3 = payload.id;
      const newCategeryMin = payload.newCategeryMin;

      let newCategories5 = state.categories;

      let indx4 = newCategories5.findIndex((cat) => cat.id == cid3);
      newCategories5[indx4].minimum_order_value = newCategeryMin;

      return { ...state, categories: newCategories5 };

    case QUICK_CHANGE_CATEGORY_IMAGE:
      const catIndex = state.categories.findIndex(
        (cat) => cat.id == payload.id
      );
      let newCats = state.categories;
      newCats[catIndex].photo = payload.photo;
      return { ...state, categories: newCats };

    case DELETE_CATEGORY_IMAGE:
      const catID = payload.id;
      const catIndex2 = state.categories.findIndex((cat) => cat.id == catID);
      newCategories = state.categories;
      newCategories[catIndex2].photo = null;
      newCategories[catIndex2].photo_thumb = null;
      newCategories[catIndex2].photo_small = null;
      newCategories[catIndex2].photo_medium = null;
      newCategories[catIndex2].photo_large = null;
      newCategories[catIndex2].photo_retina = null;
      newCategories[catIndex2].store_cover__category = null;
      return { ...state, categories: newCategories };

    case DELETE_PRODUCT_AVAILABILITY:
      const prodID = payload.pid;
      const paid = payload.paid; //product availability id

      newProducts = state.allProducts;
      index = newProducts.findIndex((p) => p.id == prodID);
      newProducts[index].availability = newProducts[index].availability.filter(
        (av) => av.id != paid
      );

      return { ...state, allProducts: newProducts };

    case UPDATE_OPTION:
      const option = payload;
      newProducts = state.allProducts;
      index = newProducts.findIndex((prod) => prod.id == option.product_id);
      const optionIndex = newProducts[index].options.findIndex(
        (op) => op.id == option.id
      );
      newProducts[index].options[optionIndex] = option;

      return { ...state, allProducts: newProducts };

    case CREATE_OPTION:
      const optionObj = payload;
      newProducts = state.allProducts;
      index = newProducts.findIndex((prod) => prod.id == optionObj.product_id);

      newProducts[index].options = [optionObj, ...newProducts[index].options];
      newProducts[index].options.sort((a, b) => a.sort_order - b.sort_order);

      return { ...state, allProducts: newProducts };

    case DELETE_OPTION:
      newProducts = state.allProducts;
      index = newProducts.findIndex((prod) => prod.id == payload.pid);
      newProducts[index].options = newProducts[index].options.filter(
        (option) => option.id != payload.id
      );

      return { ...state, allProducts: newProducts };

    case DUPLICATE_PRODUCT:
      newProducts = state.allProducts;
      newProducts = [...payload, ...newProducts];

      return { ...state, allProducts: newProducts };

    case DELETE_VARIANT_KEY:
      const variantsToDelete = payload;
      newProducts = state.allProducts;
      variantsToDelete.forEach((variant) => {
        index = newProducts.findIndex((prod) => prod.id == variant.pid);
        newProducts[index].variant_keys = newProducts[
          index
        ].variant_keys.filter((key) => key.id != variant.vid);
      });
      return { ...state, allProducts: newProducts };

    case REPLACE_PRODUCTS:
      newProducts = state.allProducts;
      const productsToReplace = payload;
      productsToReplace.forEach((product) => {
        index = newProducts.findIndex((prod) => prod.id == product.id);
        if (index >= 0) newProducts[index] = product;
        else newProducts = [product, ...newProducts];
      });
      return { ...state, allProducts: newProducts };
    case BULK_TOGGLE_CATEGORIES:
      const catIds = payload.ids;
      const newStatus = payload.status;

      newCategories = state.categories;
      state.categories.forEach((cat, indx) => {
        if (catIds.includes(cat.id)) {
          newCategories[indx].is_active = newStatus;
        }
      });
      return { ...state, categories: newCategories };

    case BULK_DELETE_CATEGORIES:
      newProducts = state.allProducts;
      newProducts.forEach((prod, indx) => {
        if (payload.includes(prod.category_id)) {
          newProducts[indx].category_id = null;
          newProducts[indx].category_slug = null;
          newProducts[indx].category_name = null;
        }
      });
      newCategories = state.categories.filter(
        (category) => !payload.includes(category.id)
      );
      return { ...state, categories: newCategories, allProducts: newProducts };

    case UPDATE_BOOKING_SLOT_LOCALLY:
      newProducts = state.allProducts;
      index = newProducts.findIndex((prod) => prod.id == payload.pid);
      const slotIndex = newProducts[index].booking_slots.findIndex(
        (slot) => slot.id == payload.booking_id
      );

      if (payload.type == "date") {
        newProducts[index].booking_slots[slotIndex].booking_date =
          payload.value;
      } else if (payload.type == "start") {
        newProducts[index].booking_slots[slotIndex].start = payload.value;
      } else if (payload.type == "end") {
        newProducts[index].booking_slots[slotIndex].end = payload.value;
      } else if (payload.type == "all_day") {
        newProducts[index].booking_slots[slotIndex].all_day =
          !newProducts[index].booking_slots[slotIndex].all_day;
        newProducts[index].booking_slots[slotIndex].start = "00:00:00";
        newProducts[index].booking_slots[slotIndex].end = "23:59:00";
      } else if (payload.type == "inventory") {
        newProducts[index].booking_slots[slotIndex].inventory = payload.value;
      }
      return { ...state, allProducts: newProducts };

    case CHANGE_CATEGORY_PARENT:
      newCategories = state.categories;

      // Replacing the new updated categories
      payload.categories.forEach((category) => {
        index = newCategories.findIndex((cat) => cat.id == category.id);

        if (index >= 0) {
          newCategories[index] = category;
        }
      });

      return { ...state, categories: newCategories };

    case ADD_PRODUCT_LIMIT:
      newProducts = state.allProducts;
      const searchedProduct = newProducts.find(
        (p) => p.id === payload.productID
      );

      if (searchedProduct) {
        searchedProduct.limits = payload.limits;
      }

      return { ...state, allProducts: newProducts };
    case CONTROL_PRODUCT_LIMIT:
      newProducts = state.allProducts;
      const productWithLimit = newProducts.find(
        (p) => p.id === payload.productID
      );
      if (productWithLimit) {
        if (payload.type === "delete") {
          productWithLimit.limits = productWithLimit.limits.filter(
            (l) => l.id !== payload.limit
          );
        } else if (payload.type === "toggle") {
          let editedLimit = productWithLimit.limits.find(
            (l) => l.id === payload.limit
          );

          if (editedLimit) {
            editedLimit.is_active = !editedLimit.is_active;
          }
        } else if (payload.type === "edit") {
          {
            let editedLimit = productWithLimit.limits.find(
              (l) => l.id === payload.limit
            );

            if (editedLimit) {
              editedLimit.max = payload.max;
            }
          }
        }
      }

      return { ...state, allProducts: newProducts };

    case CHANGE_CATEGORY_PASSWORD:
      newCategories = state.categories;

      index = newCategories.findIndex((cat) => cat.id == payload.id);

      newCategories[index].password = payload.password;

      return { ...state, categories: newCategories };

    case CHANGE_CATEGORY_BRAND_ID:
      newCategories = state.categories;

      index = newCategories.findIndex((cat) => cat.id == payload.id);

      newCategories[index].enable_brand_id = payload.brandID;

      return { ...state, categories: newCategories };

    case SAVE_BULK_OPTIONS_IN_PRODUCTS:
      newProducts = state.allProducts;

      let newOptions = payload.options.flat(1);

      newOptions.forEach((op) => {
        index = newProducts.findIndex((p) => p.id == op.product_id);
        if (!newProducts[index].did_load)
          newProducts[index].options = [...newProducts[index].options, op];
      });

      return { ...state, allProducts: newProducts };
    case ADD_PRODUCT_COMPONENT:
      newProducts = state.allProducts;
      product = newProducts.find((p) => p.id === payload.id);
      if (product) {
        product.components.push(payload.component);
      }
      return { ...state, allProducts: newProducts };
    case CONTROL_PRODUCT_COMPONENT:
      newProducts = state.allProducts;
      product = newProducts.find((p) => p.id === payload.productID);

      if (product) {
        let component;
        component = product.components.find(
          (c) => c.key === payload.componentID
        );

        if (component) {
          if (payload.action === "delete") {
            component.is_deleted = !component.is_deleted;
          } else if (payload.action === "edit") {
            component.required_amount = payload.requiredAmount;
          }
        }
      }
      return { ...state, allProducts: newProducts };
    case ADD_PRODUCT_UNIT:
      newUnits = state.units;
      newUnits.push(payload.unit);
      return { ...state, units: newUnits };
    case ADD_SUPPLIER:
      newSuppliers = state.suppliers;
      newSuppliers.push(payload.supplier);
      return { ...state, suppliers: newSuppliers };
    case CONTROL_UNIT:
      newUnits = state.units;
      let unit = newUnits[payload.index];

      if (payload.action === "delete") {
        newUnits.splice(payload.index, 1);
      } else if (payload.action === "edit english") {
        unit.name = payload.name;
      } else if (payload.action === "edit arabic") {
        unit.ar_name = payload.name;
      }
      return { ...state, units: newUnits };
    case BULK_DELETE_UNITS:
      newUnits = state.units.filter((unit) => !payload.units.includes(unit.id));
      return { ...state, units: newUnits };

    case CONTROL_SUPPLIER:
      newSuppliers = state.suppliers;
      let supplier = newSuppliers[payload.index];
      if (payload.action === "delete") {
        newSuppliers.splice(payload.index, 1);
      } else if (payload.action === "edit english") {
        supplier.name = payload.name;
      } else if (payload.action === "edit arabic") {
        supplier.ar_name = payload.name;
      }
      return { ...state, suppliers: newSuppliers };
    case BULK_DELETE_SUPPLIERS:
      newSuppliers = state.suppliers.filter(
        (supplier) => !payload.suppliers.includes(supplier.id)
      );
      return { ...state, suppliers: newSuppliers };
    case BULK_UPDATE_COMPOSITE_INVENTORIES:
      const { compositeProducts, deletedComponents, deletedComponentsIds } =
        payload;
      let newAllCompositeProducts = state.allProducts;

      for (let product of newAllCompositeProducts) {
        if (
          product.type_of_product == "composite" &&
          compositeProducts[product.id]
        ) {
          product.inventory_on_hand =
            compositeProducts[product.id].newInventory;

          product.components_dict =
            compositeProducts[product.id].newComponentsDict;

          if (deletedComponents) {
            product.components = product.components.filter(
              (component) =>
                !deletedComponentsIds.includes(component.component_product) &&
                !deletedComponentsIds.includes(
                  component.component_product_parent
                )
            );
          }

          delete compositeProducts[product.id];
          if (isEmpty(compositeProducts)) break;
        }
      }

      return { ...state, allProducts: newAllCompositeProducts };
    case CONTROL_EXTRA_FIELD:
      newProducts = state.allProducts;
      product = newProducts.find((p) => p.id === payload.pid);

      if (product) {
        let extraField;
        extraField = product.extra_fields.find((f) => f.id === payload.fid);

        if (extraField) {
          if (payload.actionType === "delete") {
            extraField.is_deleted = !extraField.is_deleted;
          } else if (payload.actionType === "toggle") {
            extraField.is_active = !extraField.is_active;
          }
        }
      }
      return { ...state, allProducts: newProducts };

    case ADD_EXTRA_FIELD:
      newProducts = state.allProducts;
      product = newProducts.find((p) => p.id === payload.pid);

      if (product) {
        product.extra_fields.push(payload.extraField);
      }
      return { ...state, allProducts: newProducts };

    case UPDATE_PRODUCTS_EXTRA_FIELDS:
      newProducts = state.allProducts;

      payload.products.forEach((newProduct) => {
        product = newProducts.find((p) => p.id === newProduct);
        if (product) {
          product.did_load = false;
        }
      });

      return { ...state, allProducts: newProducts };
    case UPDATE_PRODUCTS_OPTIONS:
      newProducts = state.allProducts;

      payload.products.forEach((newProduct) => {
        product = newProducts.find((p) => p.id === newProduct);
        if (product) {
          product.has_options = payload.has_options;
          product.did_load = false;
        }
      });

      return { ...state, allProducts: newProducts };
    case UPDATE_EXTRA_FIELD:
      newProducts = state.allProducts;
      let extraField;

      product = newProducts.find((p) => p.id === payload.productID);
      if (product) {
        extraField = product.extra_fields.findIndex(
          (ef) => ef.id === payload.extraField.id
        );

        if (extraField !== -1) {
          product.extra_fields[extraField] = payload.extraField;
        }
      }

      return { ...state, allProducts: newProducts };
    case UPDATE_PRODUCT_BARCODE:
      newProducts = state.allProducts;

      product = newProducts.find((p) => p.id === payload.productID);
      if (product) {
        product.barcode = payload.barcode;
      }

      return { ...state, allProducts: newProducts };
    case "UPDATE_PRODUCT_SKU": {
      const newProducts = state.allProducts;
      if (payload.isBulk) {
        const productSkuList = payload.data;
        productSkuList.forEach((productSku) => {
          const productIndex = newProducts.findIndex(
            (product) => product.id === productSku.product_id
          );
          if (productIndex !== -1) {
            newProducts[productIndex].sku = productSku.sku;
          }
        });
      } else {
        product = newProducts.find((p) => p.id === payload.productID);
        if (product) {
          product.sku = payload.data;
        }
      }
      return { ...state, allProducts: newProducts };
    }
    case RESET_PRODUCT_LIST:
      return {
        ...state,
        allProducts: [],
        loadingAll: true,
        productsCount: 0,
        loadedProducts: 0,
        categories: [],
      };

    case "RESET_PRODUCTS":
      newProducts = state.allProducts;
      newProducts.forEach((p) => {
        if (payload.includes(p.id)) {
          index = newProducts.findIndex((pr) => pr.id === p.id);
          if (index >= 0) {
            newProducts[index].did_load = false;
          }
        }
      });
      return {
        ...state,
        allProducts: newProducts,
      };
    case ADD_MODIFIER:
      newModifiers = state.modifiers;
      newModifiers.push(payload);
      return { ...state, modifiers: newModifiers };
    case UPDATE_MODIFIER:
      newModifiers = state.modifiers;
      newModifiers[payload.index] = { ...payload.modifier };
      return { ...state, modifiers: newModifiers };

    case CONTROL_MODIFIER:
      newModifiers = state.modifiers;
      if (payload.action === "delete") {
        let modifier = newModifiers[payload.index];
        modifier.is_deleted = !modifier.is_deleted;
        modifier.options_deleted = payload.deleteOptions;
      } else if (payload.action === "toggle") {
        let modifier = newModifiers[payload.index];
        modifier.is_active = payload.status;
      } else if (payload.action === "bulk_toggle") {
        newModifiers.forEach((modifier) => {
          if (payload.ids.includes(modifier.id)) {
            modifier.is_active = payload.status;
          }
        });
      } else if (payload.action === "bulk_delete") {
        newModifiers.forEach((modifier) => {
          if (payload.ids.includes(modifier.id)) {
            modifier.is_deleted = true;
            modifier.options_deleted = payload.deleteOptions;
          }
        });
      }

      return { ...state, modifiers: newModifiers };

    case TOGGLE_CATEGORY_MINGLING:
      newCategories = state.categories;

      let index1 = newCategories.findIndex((cat) => cat.id == payload);
      newCategories[index1].no_mingling = !newCategories[index1].no_mingling;
      return { ...state, categories: newCategories };
    case "TOGGLE_CATEGORY_SHOW_COVER": {
      newCategories = state.categories;

      let index1 = newCategories.findIndex((cat) => cat.id == payload);
      newCategories[index1].store_cover__category = newCategories[index1]
        .store_cover__category
        ? null
        : payload;

      return { ...state, categories: newCategories };
    }
    case "TOGGLE_PRODUCT_SHOW_COVER": {
      const id = payload;
      index = state.allProducts.findIndex((product) => product.id == id);
      let newAllProducts = state.allProducts;
      newAllProducts[index].store_cover__product = newAllProducts[index]
        .store_cover__product
        ? null
        : payload;
      return { ...state, allProducts: newAllProducts };
    }
    case CHANGE_PRICE:
      index = state.allProducts.findIndex(
        (product) => product.id == payload.id
      );
      newProducts = state.allProducts;
      newProducts[index].price = payload.newPrice;

      return { ...state, allProducts: newProducts };

    case CHANGE_PRODUCT_NOT_AVAILABLE_UNTIL:
      index = state.allProducts.findIndex(
        (product) => product.id == payload.id
      );
      newProducts = state.allProducts;
      newProducts[index].not_available_until = payload.value;
      return { ...state, allProducts: newProducts };

    case UPDATE_CATEGORY_DESCRIPTION:
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload.id);
      newCategories[index].description = payload.description;
      newCategories[index].ar_description = payload.ar_description;
      return { ...state, categories: newCategories };

    case "UPDATE_CATEGORY_SCHEDULED_AVAILABILITY":
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload.id);
      if (payload.type === "start")
        newCategories[index].scheduled_available_from = payload.value;
      else newCategories[index].scheduled_available_until = payload.value;
      return { ...state, categories: newCategories };

    case "UPDATE_SHOW_CATEGORY_WHEN_NOT_AVAILABILE":
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload.id);
      newCategories[index].show_when_not_available = payload.value;
      return { ...state, categories: newCategories };

    case "UPDATE_CATEGORY_EXCLUSIVE_IN":
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload.id);
      newCategories[index].exclusive_in = payload.value;
      return { ...state, categories: newCategories };

    case "RESET_CATEGORY_SCHEDULED_AVAILABILITY":
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload);
      newCategories[index].scheduled_available_from = null;
      newCategories[index].scheduled_available_until = null;
      return { ...state, categories: newCategories };

    case UPDATE_CATEGORY_AVAILABILITY:
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload.id);
      if (payload.value.length) {
        newCategories[index].has_availability = true;
        newCategories[index].availabilities = payload.value;
      } else {
        newCategories[index].has_availability = false;
        newCategories[index].availabilities = payload.value;
      }
      return { ...state, categories: newCategories };
    case "UPDATE_CATEGORY_SEO": {
      newCategories = state.categories;
      index = newCategories.findIndex((cat) => cat.id === payload.id);

      newCategories[index].meta_name = payload.value.metaName;
      newCategories[index].meta_description = payload.value.metaDescription;

      return { ...state, categories: newCategories };
    }
    case "FETCH_FILTER":
      return {
        ...state,
        filters: payload,
      };
    case "CREATE_FILTER": {
      const newFilters = state.filters;
      newFilters.push({ ...payload, number_of_options: 0, options: [] });
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "DELETE_FILTER": {
      const newFilters = state.filters.filter(
        (filter) => filter.id !== payload
      );
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "UPDATE_FILTER": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);

      if (payload.name !== newFilters[index].name) {
        newFilters[index].name = payload.name;
      }
      if (payload.ar_name !== newFilters[index].ar_name) {
        newFilters[index].ar_name = payload.ar_name;
      }
      if (payload.type !== newFilters[index].type) {
        newFilters[index].type = payload.type;
      }

      return {
        ...state,
        filters: newFilters,
      };
    }
    case "TOGGLE_FILTER_STATUS": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload);
      newFilters[index].is_active = !newFilters[index].is_active;
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "FETCH_FILTER_OPTIONS": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);
      newFilters[index].options = payload.options;
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "CREATE_FILTER_OPTION": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);
      if (payload.type === "text") {
        newFilters[index].options.push({
          ...payload.option,
          filter_id: payload.id,
        });
        newFilters[index].number_of_options =
          newFilters[index].number_of_options + 1;
      } else if (payload.type === "number") {
        payload.option.forEach((option) => {
          newFilters[index].options.push({
            ...option,
            filter_id: payload.id,
          });
          newFilters[index].number_of_options =
            newFilters[index].number_of_options + 1;
        });
      }
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "DELETE_FILTER_OPTION": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);
      newFilters[index].options = newFilters[index].options.filter(
        (option) => option.id !== payload.optionId
      );
      newFilters[index].number_of_options =
        newFilters[index].number_of_options - 1;
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "UPDATE_FILTER_OPTION": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);
      const optionIndex = newFilters[index].options.findIndex(
        (option) => option.id === payload.option.id
      );
      if (newFilters[index].options[optionIndex].name !== payload.option.name) {
        newFilters[index].options[optionIndex].name = payload.option.name;
      }
      if (
        newFilters[index].options[optionIndex].ar_name !==
        payload.option.ar_name
      ) {
        newFilters[index].options[optionIndex].ar_name = payload.option.ar_name;
      }
      if (
        newFilters[index].options[optionIndex].sort_order !==
        payload.option.sort_order
      ) {
        newFilters[index].options[optionIndex].sort_order =
          payload.option.sort_order;
      }
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "TOGGLE_FILTER_OPTION_STATUS": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);
      const optionIndex = newFilters[index].options.findIndex(
        (option) => option.id === payload.optionId
      );
      newFilters[index].options[optionIndex].is_active =
        !newFilters[index].options[optionIndex].is_active;
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "FETCH_FILTER_OPTIONS_PRODUCTS": {
      const newFilters = state.filters;
      const index = newFilters.findIndex((filter) => filter.id === payload.id);
      const optionIndex = newFilters[index].options.findIndex(
        (option) => option.id === payload.optionId
      );
      if (payload.products === null || payload.products.length === 0) {
        newFilters[index].options[optionIndex].products = [];
      } else {
        newFilters[index].options[optionIndex].products = payload.products;
      }
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "FETCH_PRODUCTS_FILTERS": {
      const newProducts = state.allProducts;
      const index = newProducts.findIndex(
        (product) => product.id === payload.id
      );
      newProducts[index].filters = payload.filters;
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "CREATE_PRODUCT_FILTER": {
      const newProducts = state.allProducts;
      const index = newProducts.findIndex(
        (product) => product.id === payload.id
      );
      if (newProducts[index].filters) {
        newProducts[index].filters.push(payload.filter);
      } else {
        newProducts[index].filters = [payload.filter];
      }
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "DELETE_PRODUCT_FILTER": {
      //remove filter from product
      const newProducts = state.allProducts;
      const index = newProducts.findIndex(
        (product) => product.id === payload.id
      );
      newProducts[index].filters = newProducts[index].filters.filter(
        (filter) => filter.id !== payload.productFilterId
      );
      const newFilters = state.filters;
      //remove product from options -> products

      const indexFilter = newFilters.findIndex(
        (filter) => filter.id === payload.filterId
      );
      const optionIndex = newFilters[indexFilter].options.findIndex(
        (option) => option.id === payload.optionId
      );
      if (newFilters[indexFilter].options[optionIndex].products) {
        newFilters[indexFilter].options[optionIndex].products = newFilters[
          indexFilter
        ].options[optionIndex].products.filter(
          (product) => product.id !== payload.id
        );
      }

      return {
        ...state,
        allProducts: newProducts,
        filters: newFilters,
      };
    }
    case "TOGGLE_PRODUCT_FILTER_STATUS": {
      const newProducts = state.allProducts;
      const index = newProducts.findIndex(
        (product) => product.id === payload.id
      );
      const filterIndex = newProducts[index].filters.findIndex(
        (filter) => filter.id === payload.filterId
      );
      newProducts[index].filters[filterIndex].is_active =
        !newProducts[index].filters[filterIndex].is_active;
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "ASSIGN_PRODUCT_FILTER_OPTION": {
      const newFilters = state.filters;
      const index = newFilters.findIndex(
        (filter) => filter.id === payload.filterId
      );
      const optionIndex = newFilters[index].options.findIndex(
        (option) => option.id === payload.optionId
      );
      newFilters[index].options[optionIndex].products = payload.productList;
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "BULK_FILTER_STATUS_CHANGE": {
      const newFilters = state.filters;
      payload.filterIds.forEach((filterId) => {
        const index = newFilters.findIndex((filter) => filter.id === filterId);
        newFilters[index].is_active = !newFilters[index].is_active;
      });
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "BULK_DELETE_FILTER": {
      const newFilters = state.filters;
      const filteredFilters = newFilters.filter(
        (filter) => !payload.filterIds.includes(filter.id)
      );
      return {
        ...state,
        filters: filteredFilters,
      };
    }
    case "BULK_DELETE_FILTER_OPTIONS": {
      const newFilters = state.filters;
      const index = newFilters.findIndex(
        (filter) => filter.id === payload.filterId
      );
      const filteredOptions = newFilters[index].options.filter(
        (option) => !payload.filterOptionsIds.includes(option.id)
      );
      newFilters[index].options = filteredOptions;
      return {
        ...state,
        filters: newFilters,
      };
    }
    case "BULK_FILTER_OPTIONS_STATUS_CHANGE": {
      const newFilters = state.filters;
      const index = newFilters.findIndex(
        (filter) => filter.id === payload.filterId
      );
      payload.filterOptionsIds.forEach((optionId) => {
        const optionIndex = newFilters[index].options.findIndex(
          (option) => option.id === optionId
        );
        newFilters[index].options[optionIndex].is_active =
          !newFilters[index].options[optionIndex].is_active;
      });
      return {
        ...state,
        filters: newFilters,
      };
    }

    case "TOGGLE_PRODUCT_BUY_NOW": {
      const id = payload.id;
      index = state.allProducts.findIndex((product) => product.id === id);
      let newAllProducts = state.allProducts;
      newAllProducts[index].enable_buy_now =
        !newAllProducts[index].enable_buy_now;
      return { ...state, allProducts: newAllProducts };
    }

    case FETCH_OPTIONS_GROUPS:
      return { ...state, optionsGroups: payload };

    case ADD_OPTIONS_GROUP:
      return { ...state, optionsGroups: [...state.optionsGroups, payload] };

    case CONTROL_OPTIONS_GROUP:
      const newOptionsGroups = state.optionsGroups;
      if (payload.type === "delete") {
        let optionGroup = newOptionsGroups[payload.index];
        optionGroup.is_deleted = !optionGroup.is_deleted;
      } else if (payload.type === "edit") {
        newOptionsGroups[payload.index] = payload.optionGroup;
      }
      return { ...state, optionsGroups: newOptionsGroups };
    case SET_OPTIONS_GROUPS_LOADING:
      return { ...state, optionsGroupsLoading: payload };

    case FETCH_SECTIONS:
      return { ...state, sections: payload };

    case ADD_SECTION:
      return { ...state, sections: [...state.sections, payload] };

    case CONTROL_SECTION:
      const newSections = state.sections;
      if (payload.type === "delete") {
        let section = newSections[payload.index];
        section.is_deleted = !section.is_deleted;
      } else if (payload.type === "edit") {
        newSections[payload.index] = payload.section;
      }
      return { ...state, sections: newSections };
    case SET_SECTIONS_LOADING:
      return { ...state, sectionsLoading: payload };

    case "FETCH_PRODUCT_FEEDBACK": {
      const newProducts = state.allProducts;
      const index = newProducts.findIndex(
        (product) => product.id === payload.id
      );

      newProducts[index].feedbacks = payload.data;
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "TOGGLE_PRODUCT_FEEDBACK": {
      const newProducts = state.allProducts;
      const allFeedbacks = state.allFeedbacks;
      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      const allIndex = allFeedbacks.findIndex(
        (feedback) => feedback.id === payload.id
      );

      //check if product has feedbacks and toggle show_on_website
      if (index >= 0 && newProducts[index]?.feedbacks) {
        const feedbackIndex = newProducts[index]?.feedbacks.findIndex(
          (feedback) => feedback.id === payload.id
        );
        if (feedbackIndex >= 0) {
          newProducts[index].feedbacks[feedbackIndex].show_on_website =
            !newProducts[index].feedbacks[feedbackIndex].show_on_website;
        }
      }

      //check if feedback is in all feedbacks and toggle show_on_website
      if (allIndex >= 0 && allFeedbacks.length > 0) {
        allFeedbacks[allIndex].show_on_website =
          !allFeedbacks[allIndex].show_on_website;
      }

      return {
        ...state,
        allProducts: newProducts,
        allFeedbacks: allFeedbacks,
      };
    }
    case "SET_READ_BY_PRODUCT_FEEDBACK": {
      const newProducts = state.allProducts;
      const allFeedbacks = state.allFeedbacks;

      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      const allIndex = allFeedbacks.findIndex(
        (feedback) => feedback.id === payload.id
      );

      //check if product has feedbacks and toggle show_on_website
      if (index >= 0 && newProducts[index]?.feedbacks) {
        const feedbackIndex = newProducts[index]?.feedbacks.findIndex(
          (feedback) => feedback.id === payload.id
        );
        if (feedbackIndex >= 0) {
          newProducts[index].feedbacks[feedbackIndex].read = true;
        }
      }

      //check if feedback is in all feedbacks and toggle show_on_website
      if (allIndex >= 0 && allFeedbacks.length > 0) {
        allFeedbacks[allIndex].read = true;
      }
      return {
        ...state,
        allProducts: newProducts,
      };
    }

    case "FETCH_ALL_PRODUCTS_FEEDBACKS": {
      let feedbacksList = state.allFeedbacks || [];

      return {
        ...state,
        allFeedbacks: [...state.allFeedbacks, ...payload.data],
      };
    }

    case "FETCH_CROSS_SELL_ITEMS": {
      const newProducts = state.allProducts;
      const index = newProducts.findIndex(
        (product) => product.id === payload.id
      );
      newProducts[index].cross_sell = payload.data;
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "TOGGLE_CROSS_SELL_STATUS": {
      const newProducts = state.allProducts;

      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      const crossSellIndex = newProducts[index].cross_sell.findIndex(
        (item) => item.id === payload.id
      );
      newProducts[index].cross_sell[crossSellIndex].is_active =
        payload.isActive;
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "EDIT_CROSS_SELL_SORT_ORDER": {
      const newProducts = state.allProducts;

      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      const crossSellIndex = newProducts[index].cross_sell.findIndex(
        (item) => item.id === payload.id
      );
      newProducts[index].cross_sell[crossSellIndex].sort_order = payload.value;
      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "DELETE_CROSS_SELL": {
      const newProducts = state.allProducts;

      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      newProducts[index].cross_sell = newProducts[index].cross_sell.filter(
        (item) => item.id !== payload.id
      );

      return {
        ...state,
        allProducts: newProducts,
      };
    }
    case "ADD_CROSS_SELL": {
      const newProducts = state.allProducts;

      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      newProducts[index].cross_sell = [
        ...newProducts[index].cross_sell,
        payload.data,
      ];

      return {
        ...state,
        allProducts: newProducts,
      };
    }

    case "REPLACE_CROSS_SELL": {
      const newProducts = state.allProducts;

      const index = newProducts.findIndex(
        (product) => product.id === payload.productId
      );
      // const crossSellIndex = newProducts[index].cross_sell.findIndex(
      //   (item) => item.id === payload.id
      // );
      // newProducts[index].cross_sell[crossSellIndex] = payload.data;

      newProducts[index].cross_sell = newProducts[index].cross_sell.filter(
        (item) => item.id !== payload.id
      );
      newProducts[index].cross_sell = [
        ...newProducts[index].cross_sell,
        payload.data,
      ];

      return {
        ...state,
        allProducts: newProducts,
      };
    }

    case "FETCH_PRODUCT_TAG": {
      return {
        ...state,
        tags: payload,
      };
    }

    case "CREATE_PRODUCT_TAG": {
      const newTags = state.tags;
      newTags.push({ ...payload });
      return {
        ...state,
        filters: newTags,
      };
    }
    case "DELETE_PRODUCT_TAG": {
      const newTags = state.tags.filter((tag) => tag.id !== payload);
      return {
        ...state,
        tags: newTags,
      };
    }
    case "TOGGLE_PRODUCT_TAG": {
      const newTags = state.tags;

      const index = newTags.findIndex((tag) => tag.id === payload);

      newTags[index].is_active = !newTags[index].is_active;

      return {
        ...state,
        tags: newTags,
      };
    }
    case "UPDATE_PRODUCT_TAG": {
      const newTags = state.tags;

      const index = newTags.findIndex((tag) => tag.id === payload.id);

      if (payload.text_en !== newTags[index].text_en) {
        newTags[index].text_en = payload.text_en;
      }
      if (payload.text_ar !== newTags[index].text_ar) {
        newTags[index].text_ar = payload.text_ar;
      }
      if (payload.color !== newTags[index].color) {
        newTags[index].color = payload.color;
      }
      if (payload.enable_tag_until !== newTags[index].enable_tag_until) {
        newTags[index].enable_tag_until = payload.enable_tag_until;
      }
      if (payload.show_tag_until !== newTags[index].show_tag_until) {
        newTags[index].show_tag_until = payload.show_tag_until;
      }

      return {
        ...state,
        tags: newTags,
      };
    }
    case "BULK_TAG_STATUS_CHANGE": {
      const newTags = state.tags;
      payload.tagIds.forEach((tagID) => {
        const index = newTags.findIndex((tag) => tag.id === tagID);
        newTags[index].is_active = !newTags[index].is_active;
      });
      return {
        ...state,
        tags: newTags,
      };
    }
    case "BULK_DELETE_TAG": {
      const newTags = state.tags;
      const filteredTags = newTags.filter(
        (tag) => !payload.tagIds.includes(tag.id)
      );
      return {
        ...state,
        tags: filteredTags,
      };
    }

    default:
      return state;
  }
};
