import {
  FETCH_FEEDBACKS,
  SET_FEEDBACKS_FETCH_LOADING,
  TOGGLE_FEEDBACK_READBY,
  SOCKET_NEW_FEEDBACK,
  BULK_SET_READ_BY_FEEDBACK,
  FETCH_SURVIES,
  SET_SURVIES_FETCH_LOADING,
  CREATE_SURVIES,
  DELETE_SURVEY,
} from "./actionTypes";

import instance from "./instance";
import { message } from "antd";
import i18n from "i18next";

export const fetchSurvies = (t) => {
  return async (dispatch) => {
    dispatch({ type: SET_SURVIES_FETCH_LOADING });
    try {
      let res = await instance.get(`/get_survies/`);
      const survies = res.data;
      dispatch({
        type: FETCH_SURVIES,
        payload: survies,
      });
    } catch (err) {
      if (err.response && err.response.status == 403) {
      } else message.error(t("SomethingWentWrong"));
    }
  };
};

export const deleteSurvey = (id, t) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`/delete_survey/`, {
        id: id,
      });
      if (res.data.success == true) {
        dispatch({
          type: DELETE_SURVEY,
          payload: id,
        });
        message.success(t("Survey deleted successfully"));
      }
    } catch (err) {
      if (err.response && err.response.status == 403) {
      } else message.error(t("SomethingWentWrong"));
    }
  };
};

export const fetchFeedbacks = (
  t,
  page,
  pageNumber,
  pageSize,
  totalPages,
  filters
) => {
  return async (dispatch) => {
    dispatch({ type: SET_FEEDBACKS_FETCH_LOADING });
    try {
      let res = await instance.post(`/get_feedbacks/`, {
        page: page,
        pageNumber: pageNumber,
        pageSize: pageSize,
        totalPages: totalPages,
        filters: filters,
      });
      const feedbacks = res.data;
      dispatch({
        type: FETCH_FEEDBACKS,
        payload: {
          feedbacks: feedbacks,
          pageNumber: pageNumber,
        },
      });
    } catch (err) {
      if (err.response && err.response.status == 403) {
      } else message.error(t("SomethingWentWrong"));
    }
  };
};

export const setReadBy = (id, index, t, page) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_FEEDBACK_READBY,
      payload: { index, value: 1, page: page },
    });

    try {
      await instance.post(`/set_readby/`, { id });
    } catch (err) {
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({
        type: TOGGLE_FEEDBACK_READBY,
        payload: { index, value: -1, page: page },
      });
    }
  };
};

export const bulkSetReadByFeedbacks = (ids, status) => {
  return async (dispatch) => {
    dispatch({
      type: BULK_SET_READ_BY_FEEDBACK,
      payload: { ids, status },
    });
  };
};

export const socketNewFeedBack = (feedback) => {
  return async (dispatch) => {
    dispatch({
      type: SOCKET_NEW_FEEDBACK,
      payload: feedback,
    });
  };
};

export const toggleShowOnWebsite = (id, page) => {
  return async (dispatch) => {
    try {
      const res = await instance.post("/toggle_show_on_website_feedback/", {
        id: id,
      });
      if (res.data.success === true) {
        dispatch({
          type: "TOGGLE_SHOW_ON_WEBSITE_FEEDBACK",
          payload: { id, page },
        });
        message.success(i18n.t("Changed successfully"));
      } else message.error(i18n.t("SomethingWentWrong"));
    } catch (err) {
      if (err.response && err.response.status === 403)
        message.error(i18n.t("AccessDeniedMessage"));
      else message.error(i18n.t("SomethingWentWrong"));
    }
  };
};

export const createSurvey = (survey, isBulk) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_SURVIES,
      payload: { survey: survey, isBulk: isBulk },
    });
  };
};
