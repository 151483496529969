import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { phoneCodes } from "../Locations/phoneCodes";

// Antd components
import {
  Modal,
  Input,
  Select,
  Radio,
  Button,
  Row,
  Col,
  message,
  Switch,
} from "antd";

// Actions
import {
  fetchCustomers,
  fetchTables,
  setAddressPOS,
  setCustomerPOS,
} from "../../store/actions";
import instance from "../../store/actions/instance";

const { Option } = Select;

function findDefaultPhoneCode(baseCountry) {
  const gccCodes = {
    Bahrain: "+973",
    Qatar: "+974",
    "Saudi Arabia": "+966",
    UAE: "+971",
    Oman: "+968",
    Jordan: "+962",
  };
  return gccCodes[baseCountry] || "+965";
}
class CustomerModal extends Component {
  constructor(props) {
    super(props);
    let state = {
      name: "",
      displayName: "Walk-in Customer",
      phoneCode: findDefaultPhoneCode(this.props.baseCountry),
      phoneNumber: null,
      email: "",
      customerType: "Walk-in",
      is_blacklist: false,
      notes: "",
      customerId: null,
      tableName: null,
      tableId: null,
      loading: false,
      tablesLoading: false,
      loadingUser: false,
      filterUsers: false,
    };

    this.state = props.customer ? props.customer : state;
  }
  state = {
    name: "",
    phoneCode: findDefaultPhoneCode(this.props.baseCountry),
    phoneNumber: null,
    email: "",
  };
  openOrderSettingsModal = () => {
    this.props.setStateIndex({ orderSettingsModalVisible: true });
  };
  saveCustomer = async () => {
    let displayName = "";
    let notes = "";
    let is_blacklist = false;
    let userID;
    let userDetails = null;

    let { customerType, customerId, name } = this.state;

    let address = this.props.address;
    switch (customerType) {
      case "Walk-in":
        displayName = "Walk-in Customer";
        break;
      case "Old":
        let customers_list = this.props.customers || [];
        const customer = customers_list.find((c) => c.key === customerId);
        if (customer) {
          displayName = customer.full_name;
          is_blacklist = customer.is_blacklist;
          notes = customer.notes;
        }
        if (customer?.user) {
          this.setState({ loadingUser: true });
          try {
            userDetails = (
              await instance.post("get_user_details/", {
                user_id: customer?.user,
                smartPromosIds: this.props.promotions
                  .filter(
                    (promo) =>
                      promo.is_active &&
                      !promo.is_expired &&
                      promo.apply_to_pos_orders
                  )
                  .map((promo) => promo.key),
              })
            ).data;
          } catch {}
          this.setState({ loadingUser: false });

          userID = customer?.user;
        }
        break;
      case "New":
        displayName = name;
        break;
      default:
        displayName = "";
    }

    this.props.setCustomerPOS({
      ...this.state,
      displayName: displayName,
      notes: notes,
      is_blacklist: is_blacklist,
      userID,
      userDetails,
    });

    if (
      this.props.customer &&
      this.props.customer.customerType === "Old" &&
      (customerId !== this.props.customer.customerId ||
        customerType !== "Old") &&
      address &&
      address.orderType === "Delivery" &&
      (address.billingAddress.type === "old" ||
        address.deliveryAddress.type === "old")
    ) {
      this.props.setAddressPOS(null);
    }
    this.props.onClose();
    if (this.props.ContinueCheckoutAfterModal) {
      if (!address && !this.props.settings.enable_table_qr) {
        this.openLocationModal(true);
      } else if (this.props.cart.length) {
        if (this.props.deliveryCharge != "TBD") this.openOrderSettingsModal();
      }
    }
  };

  openLocationModal = (continueCheckout = false) => {
    this.props.setStateIndex({
      locationModalVisible: true,
      ContinueCheckoutAfterModal: continueCheckout,
    });
  };

  openOrderSettingsModal = () => {
    this.props.setStateIndex({ orderSettingsModalVisible: true });
  };
  buttonDisabled = () => {
    const { customerType, customerId, name, tableName, phoneNumber } =
      this.state;
    const { settings } = this.props;
    if (!settings.enable_table_qr) {
      if (customerType === "Old" && !customerId) {
        return true;
      } else if (customerType === "New" && (!name.trim() || !phoneNumber)) {
        return true;
      }
    } else {
      if (!tableName) {
        return true;
      }
    }
    return false;
  };

  async componentDidMount() {
    const { settings, tables } = this.props;
    if (settings.enable_table_qr && tables.length <= 0) {
      this.fetchTables();
    }
    if (!this.props.customers && this.props.permissions.can_view_customers) {
      this.setState({ loading: true });
      await this.props.fetchCustomers(this.props.t);
      this.setState({ loading: false });
    }
  }

  fetchTables = async () => {
    const { t } = this.props;
    try {
      this.setState({ tablesLoading: true });
      const res = await instance.get("/fetch_tables/");
      if (res.data.success) this.props.fetchTables({ tables: res.data.tables });
      else message.error(t("SomethingWentWrong"));
    } catch (e) {
      message.error(t("SomethingWentWrong"));
    }
    this.setState({ tablesLoading: false });
  };

  oldSelect = React.createRef();
  phoneSelect = React.createRef();
  render() {
    const { t, config, visible, customers, permissions, settings, tables } =
      this.props;
    const {
      name,
      phoneCode,
      phoneNumber,
      email,
      customerType,
      customerId,
      tableName,
      loading,
      tablesLoading,
      filterUsers,
    } = this.state;
    let customers_list = customers || [];

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Customer Details")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            className="posActionButton"
            disabled={this.buttonDisabled()}
            loading={this.state.loadingUser}
            block
            key="submit"
            type="primary"
            onClick={() => this.saveCustomer()}
          >
            {t("Ok")}
          </Button>,
        ]}
        onOk={this.saveCustomer}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="px-2 my-3">
          {settings.enable_table_qr && (
            <div className="mt-3 mb-4 ">
              <p>{t("Table")}</p>
              <Select
                loading={tablesLoading}
                showSearch={true}
                style={{ width: "100%" }}
                placeholder={t("Select Table")}
                value={tableName || undefined}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  const tableName = tables.find(
                    (td) => td.id === e
                  ).display_name;
                  this.setState({
                    tableName: tableName,
                    tableId: e,
                  });
                }}
              >
                {tables
                  .filter((tab) => tab.branch_id == this.props.branch)
                  .map((table) => (
                    <Option key={table.id} value={table.id}>
                      {`${table.display_name}`}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
          <div className="text-center">
            <Radio.Group
              defaultValue="products"
              buttonStyle="solid"
              className="my-3  w-100"
              value={customerType}
              style={{ whiteSpace: "nowrap" }}
              onChange={(e) => {
                this.setState({ customerType: e.target.value });
              }}
            >
              {permissions.can_create_walkin_orders && (
                <Radio.Button className="posRadioButton" value="Walk-in">
                  {t("Walk-in")}
                </Radio.Button>
              )}
              {permissions.can_create_customers && (
                <Radio.Button className="posRadioButton" value="New">
                  {t("New")}
                </Radio.Button>
              )}
              {permissions.can_view_customers && (
                <Radio.Button className="posRadioButton" value="Old">
                  {t("Existing")}
                </Radio.Button>
              )}
            </Radio.Group>
          </div>
          {customerType === "New" && (
            <>
              <div className="mt-3">
                <p>{t("Name")}</p>
                <Input
                  defaultValue={name}
                  placeholder={t("Name")}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mt-3">
                <p>{t("Email")}</p>
                <Input
                  defaultValue={email}
                  placeholder={t("Email")}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="mt-3  mb-4 ">
                <p>{t("Phone")}</p>
                <Row gutter={15}>
                  <Col span={8}>
                    <Select
                      ref={this.phoneSelect}
                      showSearch
                      className="w-100"
                      placeholder={t("Select a country")}
                      defaultValue={phoneCode.replace("+", "")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => {
                        const select = this.phoneSelect.current;
                        if (select) {
                          select.blur();
                        }
                        this.setState({ phoneCode: "+" + e });
                      }}
                    >
                      {phoneCodes.map((code) => (
                        <Option
                          key={code.code}
                          value={code.code.replace("+", "")}
                        >
                          {`${code.name} ${code.code}`}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={16}>
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="w-100"
                      defaultValue={phoneNumber}
                      placeholder={t("Phone Number")}
                      onChange={(e) =>
                        this.setState({ phoneNumber: e.target.value.trim() })
                      }
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
          {customerType === "Old" && (
            <div className="mt-3 mb-4 ">
              <p>{t("Customer")}</p>
              <div className="mb-3">
                <Switch
                  checked={this.state.filterUsers}
                  onChange={(c) => this.setState({ filterUsers: c })}
                />
                <span className="mx-3">
                  {t("Show only registered customers")}
                </span>
              </div>
              <Select
                ref={this.oldSelect}
                loading={loading}
                showSearch={true}
                style={{ width: "100%" }}
                placeholder={t("Select Customer")}
                value={customerId || undefined}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  const select = this.oldSelect.current;
                  if (select) {
                    select.blur();
                  }

                  this.setState({
                    customerId: e,
                  });
                }}
              >
                {customers_list
                  .filter((customer) => !filterUsers || customer.user)
                  .map((customer) => (
                    <Option key={customer.key} value={customer.key}>
                      {`${customer.full_name} ${customer.phone}`}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    customers: state.customers.customers,
    permissions: state.auth.permissions,
    baseCountry: state.auth.settings.base_country,
    cart: state.pos.cart,
    settings: state.auth.settings,
    tables: state.pos.tables,
    address: state.pos.address,
    customer: state.pos.customer,
    promotions: state.promotions?.promotions || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomers: (t) => dispatch(fetchCustomers(t)),
    fetchTables: (params) => dispatch(fetchTables(params)),
    setAddressPOS: (params) => dispatch(setAddressPOS(params)),
    setCustomerPOS: (params) => dispatch(setCustomerPOS(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(CustomerModal));
