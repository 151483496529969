const trans = {
  "Notice Banner": "شعار التنبيه",
  "Show banner?": "اظهار التنبيه",
  "Banner color": "لون التنبيه",
  "Content in English": "المحتوى بالانجليزي",
  "Content in Arabic": "المحتوى بالعربي",
  "(Max. 75 characters)": "(75 حرف كحد اقصى)",

  "Display a banner popup": "عرض صوره اعلانية",
  "Display a text message popup": "عرض رسالة نصية",
  "Customers must agree to a message to continue":
    "يجب الموافقة على الشروط قبل دخول الموقع",
  "Shows a gallery of products": "البوم متحرك لعرض المنتجات",
  "Display a message and redirect customers to an external page":
    "اعادة توجيه العميل الى صفحة خارجة بعد عرض رسالة نصية",
  "Displays a form for collecting user feedback": "عرض استبيان",
  "Please upload a JPG or PNG file": "يرجى تحميل ملف JPG او PNG",
  "The file size must be less than 5MB": "يجب ان يكون حجم الملف اقل من  5MB",
  "New Banner": "اعلان جديد",
  Upload: "تحميل",
  Update: "تحديث",
  Create: "انشاء",
  Close: "اغلاق",
  "There are different types of banners": "يوجد اعلانات متنوعة",
  "Banner Type": "نوع الاعلان",
  "Please select a banner type": "يرجى اختيار نوع الاعلان",
  "Select or drop an image": "اختر او اسحب صورة للتغيير",
  Title: "عنوان",
  "Redirect Link": "رابط اعادة التوجيه",
  "Enable conditions": "استخدام شروط للاعلان",
  "Display conditions": "شروط عرض الاعلان",
  "Customer conditions": "شروط للعميل",
  "Enable Auto Redirect": " إعادة التوجيه تلقائيا",
  "Enable Auto Close": "ازالة الاعلان تلقائيا",
  Seconds: "ثواني",
  "Gallery Type": "نوع الكاروسيل",
  "Please select a gallery type": "يجب اختيار نوع الالبوم",
  Redirect: "إعادة التوجيه",
  Redirecting: "إعادة التوجيه الى",
  "Popup Banner": "اعلان",
  Preview: "معاينة",
  Image: "صورة",
  Notice: "اشعار",
  Consent: "موافقة",
  Survey: "استبيان",
  "Auto close in": "إغلاق تلقائي في",
  "Auto redirect in": "إعادة التوجيه التلقائي في",
  "Enable Popup Banner": "تفعيل البانر المنبثق",
  "Are you sure you want to delete this banner?":
    "هل أنت متأكد أنك تريد حذف هذا الشعار؟",
  "Display only if the customer is on a": "العرض فقط إذا كان العميل على ملف",
  "Device Type": "نوع الجهاز",
  Mobile: "هاتف محمول",
  Tablet: "الكمبيوتر اللوحي",
  Computer: "كومبيوتر",
  "Number of times to display banner per customer":
    "عدد مرات عرض البانر لكل عميل",
  "Display only if the customer is logged": "العرض فقط إذا تم تسجيل العميل",
  Status: "حالة",
  In: "في",
  Out: "خارج",
  "Display only if the customer is from": "العرض فقط إذا كان العميل من",
  Country: "دولة",
  Kuwait: "الكويت",
  Qatar: "دولة قطر",
  UAE: "الإمارات العربية المتحدة",
  Oman: "سلطنة عمان",
  Jordan: "الأردن",
  Bahrain: "البحرين",
  "Saudi Arabia": "المملكة العربية السعودية",
  "Other Countries": "بلدان اخرى",
  "Page to display on": "اظهر الاعلان في صفحات معينة",
  Everywhere: "في كل مكان",
  Homepage: "الصفحة الرئيسية",
  Product: "المنتج",
  Cart: "سلة التسوق",
  Checkout: "الدفع",
  Track: "التتبع",
  "Customer behavior conditions": "شروط للعمليات على المتجر",
  "No Events Selected": "لم يتم تحديد خيار",
  "on Adding to Cart": "عند الإضافة إلى سلة التسوق",
  "on Removing from Cart": "عند إزالة منتج من سلة التسوق",
  "on Placing an Order": "عند تقديم طلب",
  "Popup Banners": "شعارات التنبيه",
  "Notice Banner For Customer Storefront": "لافتة إشعار لواجهة متجر العميل",
  "Notice Banner For Businesses Storefront": "لافتة إشعار لواجهة متجر الأعمال",
};

export default trans;
