import {
  FETCH_ROLES_USERS,
  FETCH_ROLES_USERS_LOADING,
  CREATE_STORE_ROLE,
  DELETE_STORE_ROLE,
  CHANGE_ROLE_NAME,
  SWITCH_PERMISSIONS,
  CHANGE_PERMISSION_AREAS,
  CHANGE_PERMISSION_BRANCHES,
  CHANGE_USER_ROLE,
  BULK_CHANGE_USER_ROLE,
  CREATE_NEW_USER,
  COPY_BRANCHES_AREAS_TO_PERMISSIONS,
  DELETE_STORE_USER_ROLE,
} from "../actions/actionTypes";

const initialState = {
  roles: [],
  users: [],
  areas: [],
  branches: [],
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  let newRoles = [];
  let newUsers = [];
  let index = null;
  switch (type) {
    case FETCH_ROLES_USERS_LOADING:
      return { ...state, loading: true };

    case FETCH_ROLES_USERS:
      return {
        ...state,
        roles: payload.roles,
        users: payload.users,
        areas: payload.areas,
        branches: payload.branches,
        loading: false,
      };

    case CREATE_STORE_ROLE:
      newRoles = state.roles;
      newRoles = [...newRoles, payload];
      return {
        ...state,
        roles: newRoles,
      };

    case DELETE_STORE_ROLE:
      newRoles = state.roles.filter((role) => role.id != payload);
      return {
        ...state,
        roles: newRoles,
      };

    case CHANGE_ROLE_NAME:
      newRoles = state.roles;
      index = newRoles.findIndex((role) => role.id == payload.id);
      newRoles[index].name = payload.value;
      return {
        ...state,
        roles: newRoles,
      };

    case SWITCH_PERMISSIONS:
      newRoles = state.roles;
      index = newRoles.findIndex((role) => role.id == payload.roleID);
      newRoles[index].permissions[payload.field] = payload.value;
      return {
        ...state,
        roles: newRoles,
      };

    case CHANGE_PERMISSION_AREAS:
      newRoles = state.roles;
      index = newRoles.findIndex((role) => role.id == payload.roleID);

      // Additions
      if (payload.additions.length)
        newRoles[index].permissions.areas = [
          ...payload.additions,
          ...newRoles[index].permissions.areas,
        ];

      // Deletions
      if (payload.deletions.length)
        newRoles[index].permissions.areas = newRoles[
          index
        ].permissions.areas.filter((id) => !payload.deletions.includes(id));

      return {
        ...state,
        roles: newRoles,
      };

    case CHANGE_PERMISSION_BRANCHES:
      newRoles = state.roles;
      index = newRoles.findIndex((role) => role.id == payload.roleID);

      // Additions
      if (payload.additions.length)
        newRoles[index].permissions.branches = [
          ...payload.additions,
          ...newRoles[index].permissions.branches,
        ];

      // Deletions
      if (payload.deletions.length)
        newRoles[index].permissions.branches = newRoles[
          index
        ].permissions.branches.filter((id) => !payload.deletions.includes(id));

      return {
        ...state,
        roles: newRoles,
      };

    case CHANGE_USER_ROLE:
      newUsers = state.users;
      index = newUsers.findIndex((user) => user.id == payload.userID);

      const oldRole = newUsers[index].role;
      newUsers[index].role = payload.newRole;

      if (oldRole) {
        newRoles = state.roles;
        index = newRoles.findIndex((role) => role.id == oldRole);
        if (index >= 0) newRoles[index].users_count -= 1;
      }

      index = newRoles.findIndex((role) => role.id == payload.newRole);
      newRoles[index].users_count += 1;

      return {
        ...state,
        roles: newRoles,
        users: newUsers,
      };

    case BULK_CHANGE_USER_ROLE:
      newUsers = state.users;
      newRoles = state.roles;

      payload.ids.forEach((id) => {
        index = newUsers.findIndex((usr) => usr.id == id);
        let tempRole = newUsers[index].role;
        newUsers[index].role = payload.newRole;

        // Reducing users count
        if (tempRole) {
          index = newRoles.findIndex((role) => role.id == tempRole);
          if (index >= 0) {
            newRoles[index].users_count -= 1;
          }
        }
      });

      // Incrementing users count
      index = newRoles.findIndex((role) => role.id == payload.newRole);
      newRoles[index].users_count += payload.ids.length;

      return {
        ...state,
        roles: newRoles,
        users: newUsers,
      };

    case CREATE_NEW_USER:
      newUsers = state.users;
      newUsers = [...newUsers, payload];

      newRoles = state.roles;
      index = newRoles.findIndex((role) => role.id == payload.role);

      newRoles[index].users_count += 1;

      return {
        ...state,
        roles: newRoles,
        users: newUsers,
      };

    case COPY_BRANCHES_AREAS_TO_PERMISSIONS:
      newRoles = state.roles;
      index = newRoles.findIndex((role) => role.id == payload.role);
      newRoles[index].permissions.areas = payload.areas;
      return {
        ...state,
        roles: newRoles,
      };

    case DELETE_STORE_USER_ROLE:
      newUsers = state.users;
      newUsers = newUsers.filter((u) => u.id !== payload.id);
      return { ...state, users: newUsers };

    case "UPDATE_PERMISSIONS_ROLES":
      newRoles = state.roles;
      index = newRoles.findIndex((r) => r.id === payload.permissions.role);
      newRoles[index].permissions = payload.permissions;
      return {
        ...state,
        roles: newRoles,
      };
    default:
      return state;
  }
};
