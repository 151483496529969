import {
  LIMIT_CONTROL,
  FETCH_ORDER_LIMTIS,
  FETCH_ORDER_LIMTIS_LOADING,
} from "./actionTypes";
import { message } from "antd";
import instance from "./instance";
export const fetchOrderLimits = (t) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ORDER_LIMTIS_LOADING });
    try {
      let res = await instance.get("/fetch_orders_limits/");

      dispatch({ type: FETCH_ORDER_LIMTIS, payload: res.data });
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({ type: FETCH_ORDER_LIMTIS, payload: { limits: [] } });
    }
  };
};

export const limitControl = (actionType, sid, max) => {
  return {
    type: LIMIT_CONTROL,
    payload: { actionType: actionType, sid: sid, max: max },
  };
};

export const createNewLimits = (limits) => {
  return {
    type: FETCH_ORDER_LIMTIS,
    payload: { limits: limits },
  };
};
