import React, { Component } from "react";
import {
  Button,
  Input,
  message,
  Popover,
  Radio,
  Spin,
  Table,
  Tabs,
  Drawer,
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import {
  changeOrdersLayout,
  fetchOrders,
  storeSearchedOrders,
  updateOrderStatusAndPayment,
} from "../../store/actions/orders";
import QuickActionsModal from "./QuickActionsModal";
import RadioFilters from "./RadioFilters";
import {
  BorderOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DashOutlined,
  EyeOutlined,
  FilterFilled,
  HistoryOutlined,
  LoadingOutlined,
  MenuOutlined,
  WhatsAppOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PopoverContent from "./PopoverContent";
import { Redirect } from "react-router-dom";
import NoAccess from "./NoAccess";
import { withNamespaces } from "react-i18next";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import instance from "../../store/actions/instance";
import { updateCustomerBookingStatus } from "../../store/actions/customerBookings";
import { updateCustomerServiceStatus } from "../../store/actions/serviceBookings";
import OrderHistory from "./OrderHistory";
import { ReactComponent as OrdersIcon } from "../../assets/icons/invoice.svg";
import PickupCustomers from "./PickupCustomers";
import EditBranchModal from "./EditBranchModal";
import TableScroller from "../common/TableScroller";
import { ReactComponent as BranchesIcon } from "../../assets/icons/shop.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/pie-chart.svg";
import QRScanDrawer from "./QRScanDrawer";


const search = (val) => instance.get(`/search_orders/${val}/`);
const searchDebounced = AwesomeDebouncePromise(search, 1000);

const { Search } = Input;
const { TabPane } = Tabs;
const { Column } = Table;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class index extends Component {
  state = {
    orderDetailModal: false, //The big "preview" modal state
    selectedOrder: null, //Set when any of the two modal opens
    search: "", //Search field value
    radioValue: "today", //First radio group value
    radioValue2: "all", //Second radio group value
    quickActionModal: false, //Small modal state
    orderStatusValueInModal: "", //The value of the order status inside the small modal
    paymentStatusValueInModal: "", //The value of the payment status inside the small modal
    orderReceivedLoading: false,
    selectedRowKeys: [],
    popoverVisible: false,

    searchLoading: false,
    goToNotes: false,
    orderHistoryModalOpen: false,
    selectedHistoryOrder: true,
    editBranchOpen: false,
    futureRangeStart: moment().add(1, "days"),
    futureRangeEnd: moment().add(15, "days"),
  };

  //Classnames for the spans of the dot (status column)
  statusDotClassName = (status) => {
    if (status.includes("New")) return "dot-green";
    else if (status == "Received" || status === "Open") return "dot-blue";
    else if (status == "Preparing" || status == "Processing")
      return "dot-yellow";
    else if (status == "Out for Delivery") return "dot-orange";
    else if (status == "Cancelled" || status == "Failed") return "dot-red";
    else if (status == "Refunded") return "dot-red";
    else if (status == "Complete" || status == "Pushed") return "dot-green";
  };

  handleStatusChangeInQuickActionsModal = (e) => {
    this.setState({ orderStatusValueInModal: e.target.value });
  };

  handlePaymentStatusValueInModalChange = (e) => {
    this.setState({ paymentStatusValueInModal: e.target.value });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleDateChange = (date, dateStr) => {
    this.setState({
      futureRangeStart: date[0],
      futureRangeEnd: date[1],
    });
  };

  //If performed on an order, the value of the order status and payment status will be sent to the backend
  //to update the selected order. If a promise is selected, the promise will be received first and then the the status
  // will change along with the payment status
  updateOrderStatusAndPayment = async (
    status,
    cancelReason,
    refundCustomer,
    returnInventory,
    refundWallet = false
  ) => {
    if (!this.props.is_staff) {
      if (!this.props.permissions.can_edit_orders) return;
    }
    const selectedOrder = this.state.selectedOrder;
    const orderType = selectedOrder.obj.type == "Promise" ? "promise" : "order";

    const id = selectedOrder.key;

    let orderStatus =
      this.state.orderStatusValueInModal == "New" ||
      this.state.orderStatusValueInModal == "New POS"
        ? "Received"
        : this.state.orderStatusValueInModal;

    if (orderStatus == "") orderStatus = "Received";
    if (status) orderStatus = status;

    const res = await this.props.updateOrderStatusAndPayment(
      orderType,
      id,
      orderStatus,
      this.state.paymentStatusValueInModal,
      cancelReason,
      refundCustomer || false,
      returnInventory,
      refundWallet
    );
    if (res) {
      this.setState({
        selectedOrder: this.convertOrderToSelectedOrder(res),
        orderReceivedLoading: false,
        orderStatusValueInModal: res.status,
      });
    }
    if (orderType == "promise" || selectedOrder.status == "New POS") {
      this.setState({ radioValue2: "all", radioValue: "recentlyReceived" });
    }
  };
  quickModalOkAction = () => {
    this.setState({ quickActionModal: false, goToNotes: false });
    this.updateOrderStatusAndPayment();
  };
  quickModalCancelAction = () => {
    this.setState({
      quickActionModal: false,
      orderStatusValueInModal: "",
      orderReceivedLoading: false,
      goToNotes: false,
    });
  };

  receivePromise = () => {
    this.updateOrderStatusAndPayment();
    this.setState({ orderReceivedLoading: true });
  };
  cancelOrder = (
    reason,
    refundCustomer,
    returnInventory,
    refundWallet = false
  ) => {
    this.updateOrderStatusAndPayment(
      refundCustomer ? "Refunded" : "Cancelled",
      reason,
      refundCustomer,
      returnInventory,
      refundWallet
    );
    this.setState({
      selectedOrder: {
        ...this.state.selectedOrder,
        status: refundCustomer ? "Refunded" : "Cancelled",
        cancellation_reason: reason,
      },
    });

    if (
      this.props.customerBookingsItems.length &&
      this.props.customerBookingsItems.find(
        (item) => item.order_id == this.state.selectedOrder.obj.id
      )
    ) {
      this.props.updateCustomerBookingStatus(
        this.state.selectedOrder.obj.id,
        refundCustomer ? "Refunded" : "Cancelled"
      );
    }

    if (
      this.props.serviceBookingsItems.length &&
      this.props.serviceBookingsItems.find(
        (item) => item.order_id == this.state.selectedOrder.obj.id
      )
    ) {
      this.props.updateCustomerServiceStatus(
        this.state.selectedOrder.obj.id,
        refundCustomer ? "Refunded" : "Cancelled"
      );
    }
  };
  uncancelOrder = () => {
    this.updateOrderStatusAndPayment("Received");
    this.setState({
      selectedOrder: {
        ...this.state.selectedOrder,
        status: "Received",
        cancellation_reason: "",
      },
      orderStatusValueInModal: "Received",
    });

    if (
      this.props.customerBookingsItems.length &&
      this.props.customerBookingsItems.find(
        (item) => item.order_id == this.state.selectedOrder.obj.id
      )
    ) {
      this.props.updateCustomerBookingStatus(
        this.state.selectedOrder.obj.id,
        "Received"
      );
    }

    if (
      this.props.serviceBookingsItems.length &&
      this.props.serviceBookingsItems.find(
        (item) => item.order_id == this.state.selectedOrder.obj.id
      )
    ) {
      this.props.updateCustomerServiceStatus(
        this.state.selectedOrder.obj.id,
        "Received"
      );
    }
  };
  convertOrderToSelectedOrder = (order) => {
    const { t, config } = this.props;
    return {
      key: order.id,
      index: order.type == "Order" ? order.id : "-",
      status: order.status,
      type: order.is_delivery ? "Delivery" : "Pick up",
      pn: !order.is_delivery ? order.address?.parking_number || "-" : "-",
      placed: moment(order.placed, "YYYY-MM-DDTHH:mm:ss").format(
        "DD-MM-YYYY h:mm A"
      ),
      received: order.received
        ? moment(order.received, "YYYY-MM-DDTHH:mm:ss").format(
            "DD-MM-YYYY h:mm A"
          )
        : "-",
      customer: [`${order.first_name} ${order.last_name}`, order.phone],
      tracking: order.tracking_id,
      expectedDate: order.expected_delivery_date
        ? moment(order.expected_delivery_date, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )
        : "-",
      expectedTime: `${
        order.expected_delivery_slot_start
          ? moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
              "h:mm A"
            )
          : ""
      } - ${
        order.expected_delivery_slot_end
          ? moment(order.expected_delivery_slot_end, "HH:mm:ss").format(
              "h:mm A"
            )
          : ""
      }`,
      area: order.area
        ? config.language == "arabic"
          ? order.area.ar_name
            ? order.area.ar_name
            : "-"
          : order.area.name
          ? order.area.name
          : "-"
        : "-",
      paymentType:
        order.payment_method == "cash"
          ? "Cash"
          : order.payment_method == "pos"
          ? `POS`
          : order.payment_method == "benefit-transfer"
          ? t("Benefit Transfer")
          : order.payment_method == "wallet"
          ? t("Wallet")
          : order.payment_method == "custom"
          ? `Custom (${order.payment_ctype})`
          : `Online (${
              order.payment_method?.includes("link") && !order.payment_complete
                ? this.capitalizeFirstLetter(
                    order.payment_method.split(" link")[0].split("_")[0]
                  )
                : order.payment_ctype === "1"
                ? "Knet"
                : ["0", "2", "5"].includes(order.payment_ctype)
                ? "credit"
                : order.payment_ctype
            })`,
      paymentStatus: order.payment_status,
      paymentReference: order.payment_reference ? order.payment_reference : "-",
      promise: order.type == "Promise" ? true : false,
      obj: order,
      cancellation_reason: order.cancellation_reason,
      refund_id: order.refund_id,
      total: order.total,
      currency: order.currency,
    };
  };
  componentDidUpdate = (prevProps) => {
    const { t, config } = this.props;
    if (
      this.props.orders.recentlyReceived != prevProps.orders.recentlyReceived &&
      prevProps.orders.recentlyReceived != null &&
      this.state.quickActionModal
    ) {
      let order = this.props.orders.recentlyReceived.filter(
        (ord) => ord.from_promise == this.state.selectedOrder.key
      );

      if (order.length) {
        order = order[0];
        this.setState({
          selectedOrder: this.convertOrderToSelectedOrder(order),
          orderReceivedLoading: false,
          orderStatusValueInModal: order.status,
        });
      }
    }

    if (
      this.props.openOrderModal !== prevProps.openOrderModal &&
      this.props.openOrderModal !== null
    ) {
      let record = [
        ...(this.props.orders.orders || []),
        ...(this.props.orders.new_orders || []),
        ...(this.props.orders.recentlyReceived || []),
        ...(this.props.orders.pastOrders || []),
      ].find((o) => o.tracking_id === this.props.openOrderModal);
      if (!record) {
        record = this.props.orders.promises.find(
          (o) => o.tracking_id === this.props.openOrderModal
        );
      }
      if (record) {
        record = this.convertOrderToSelectedOrder(record);
        this.setState({
          paymentStatusValueInModal:
            record.paymentStatus == 3 ? "Complete" : "Incomplete",
          orderStatusValueInModal: record.status,
          selectedOrder: record,
          quickActionModal: true,
        });
      }
      this.props.setOpenOrderModal(null);
    }
  };
  onRowSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  translateDateTimeToArabic = (text) => {
    const { config } = this.props;
    if (config.language != "arabic") return text;
    let newText = text;

    while (newText.includes("PM")) {
      newText = newText.replace("PM", "م");
    }
    while (newText.includes("AM")) {
      newText = newText.replace("AM", "ص");
    }
    return newText;
  };

  renderColumnOrderPlacedBy = (text, record) => {
    if (text) return text;
    else return "-";
  };

  renderNotesColumn = (text, record) => {
    const { t, config } = this.props;
    if (!record.obj.notes) return "-";
    else
      return (
        <div style={{ width: 170, cursor: "pointer", textAlign: "center" }}>
          <p
            className="cut-text-one-line-notes mb-0"
            onClick={() => {
              this.setState({
                paymentStatusValueInModal:
                  record.paymentStatus == 3 ? "Complete" : "Incomplete",
                orderStatusValueInModal: record.status,
                selectedOrder: record,
                goToNotes: true,
                quickActionModal: true,
              });
            }}
          >
            {record.obj.notes}
          </p>
          <Popover
            placement={config.direction == "ltr" ? "left" : "right"}
            title={t("Notes")}
            content={<div style={{ maxWidth: 275 }}>{record.obj.notes}</div>}
            trigger="hover"
          >
            <EyeOutlined />
          </Popover>
        </div>
      );
  };

  search = (value, e) => {
    this.setState({ search: value });

    if (this.state.radioValue2 == "search") this.fetchSearchedOrders(value);
  };

  fetchSearchedOrders = async (query) => {
    if (!query) {
      return;
    }
    this.setState({ searchLoading: true });
    const { t } = this.props;
    try {
      let res = await searchDebounced(query);

      if (res.data.success == true) {
        this.props.storeSearchedOrders(res.data.orders);
      } else message.error(t("SomethingWentWrong"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
    this.setState({ searchLoading: false });
  };

  renderTableRowBackgroundColor = (record) => {
    if (record.status == "Cancelled" || record.status == "Refunded")
      return "#ffcaca";

    if (
      record.obj.payment_method === "benefit-transfer" &&
      record.obj.payment_complete === false
    )
      return "#92378654";

    if (record.obj.expected_delivery_date > moment().format("YYYY-MM-DD"))
      return "#0099cc59";

    if (
      record.obj.customer__is_blacklist &&
      this.props.settings.enable_blacklist
    )
      return "#ffefca";
  };

  onSoonFilter = (value, record) => {
    const now = moment();
    const orderTime = moment(
      record.obj.expected_delivery_slot_start,
      "HH:mm:ss"
    );
    const { settings } = this.props;
    switch (value) {
      case "later": {
        return orderTime.isAfter(moment().add(settings.due_soon, "minutes"));
        break;
      }
      case "soon": {
        const soonStartTime = moment();
        const soonEndTime = moment().add(settings.due_soon, "minutes");
        return orderTime.isBetween(soonStartTime, soonEndTime);
        break;
      }
      case "due": {
        return orderTime.isSameOrBefore(moment());
        break;
      }
    }
  };
  checkDueSoon = (expectedTime) => {
    const { settings } = this.props;
    const now = moment();
    const orderTime = moment(expectedTime, "HH:mm:ss");

    if (orderTime.isAfter(moment().add(settings.due_soon, "minutes"))) {
      return "later";
    } else if (
      orderTime.isBetween(moment(), moment().add(settings.due_soon, "minutes"))
    ) {
      return "soon";
    } else {
      return "due";
    }
  };

  checkDueSoonStatus = (expectedDeliveryTime) => {
    const { t, config } = this.props;
    const value = this.checkDueSoon(expectedDeliveryTime);
    const remainingMinutes = moment(expectedDeliveryTime, "HH:mm:ss").diff(
      moment(),
      "minutes"
    );

    let displayText;
    const remainingHours = Math.floor(remainingMinutes / 60);
    const remainingMinutesInHour = remainingMinutes % 60;

    const getHourText = (hours, language) => {
      if (hours === 0) {
        return "";
      }
      if (language === "english") {
        return `${hours} ${t(hours > 1 ? "hours" : "hour")}`;
      } else {
        if (hours === 1) {
          return "ساعة و";
        } else if (hours === 2) {
          return "ساعتين و";
        } else {
          return `${hours} ساعات و`;
        }
      }
    };

    const getMinuteText = (minutes, language) => {
      if (minutes === 0) {
        return "";
      }
      if (language === "english") {
        return `${minutes} ${t(minutes > 1 ? "mins" : "min")}`;
      } else {
        if (minutes === 1) {
          return "دقيقة";
        } else if (minutes === 2) {
          return "دقيقتين";
        } else {
          return `${minutes} دقيقة`;
        }
      }
    };

    if (remainingHours > 0) {
      const hourText = getHourText(remainingHours, config.language);
      const minuteText = getMinuteText(remainingMinutesInHour, config.language);

      displayText = `(${hourText} ${minuteText})`;
    } else {
      const minuteText = getMinuteText(remainingMinutes, config.language);

      displayText = `(${minuteText})`;
    }

    if (value === "later") {
      return (
        <span>
          <span className={"dot-green"}></span>{" "}
          {config.language === "english" ? "Later" : "لاحقاً"} {displayText}
        </span>
      );
    } else if (value === "soon") {
      return (
        <span>
          <span className={"dot-yellow"}></span>{" "}
          {config.language === "english" ? "Soon" : "قريبًا"} {displayText}
        </span>
      );
    } else if (value === "due") {
      return (
        <span>
          <span className={"dot-red"}></span>{" "}
          {config.language === "english" ? "Overdue" : "مستحق"}
        </span>
      );
    }
  };
  onFilterPaymentMethod = (value, record) => {
    const { paymentType } = record;
    const { t } = this.props;
    if (
      this.props.settings.enable_table_qr &&
      this.props.settings.enable_cash === false &&
      value === "cash" &&
      record.obj.payment_complete === false
    ) {
      return "";
    }

    switch (value) {
      case "cash":
        return paymentType === "Cash";
      case "pos":
        return paymentType === "POS";
      case "benefit-transfer":
        return paymentType === t("Benefit Transfer");
      case "wallet":
        return paymentType === t("Wallet");
      case "custom":
        return paymentType.startsWith("Custom");
      case "online":
        return paymentType.startsWith("Online");
    }
  };

  render() {
    if (!this.props.user) return <Redirect to="/manage/login" />;
    if (!this.props.is_staff) {
      if (!this.props.permissions || !this.props.permissions.can_view_orders)
        return <NoAccess />;
    }

    //Not used yet
    // function onChange(pagination, filters, sorter, extra) {
    //   console.log("params", pagination, filters, sorter, extra);
    // }unset
    const { t, config, settings, ordersLayout } = this.props;
    const permissions = this.props.permissions;
    const { selectedRowKeys, orderHistoryModalOpen, selectedHistoryOrder } =
      this.state;
    let rowSelection = {
      selectedRowKeys,
      onChange: this.onRowSelectChange,
    };
    if (permissions && !permissions.can_edit_orders && !this.props.is_staff) {
      rowSelection = null;
    }
    const hasSelected = selectedRowKeys.length > 0;
    const orders = this.props.orders.orders; //from redux
    const promises = this.props.orders.promises; //from redux
    const new_orders = this.props.orders.new_orders; // from redux

    let filteredOrders = [];

    if (this.state.radioValue2 != "search") {
      filteredOrders =
        this.state.radioValue2 == "new"
          ? this.props.orders.promises && promises.concat(new_orders)
          : this.state.radioValue == "past"
          ? this.props.orders.pastOrders && this.props.orders.pastOrders
          : this.state.radioValue == "recentlyReceived"
          ? this.props.orders.recentlyReceived.length > 0 &&
            this.props.orders.recentlyReceived
          : this.props.orders.orders && orders;
    } else {
      // filteredOrders = this.state.searchedOrders;

      // What I'm doing here basically is merging all the states after fetching orders from the search
      filteredOrders = [];

      if (this.state.search) {
        if (this.props.orders.pastOrders) {
          filteredOrders = [...this.props.orders.pastOrders];
        }

        if (this.props.orders.orders) {
          filteredOrders = [...filteredOrders, ...this.props.orders.orders];
        }
      }
    }

    filteredOrders = filteredOrders || [];

    //The data that fill the table
    if (
      this.state.radioValue2 != "new" &&
      this.state.radioValue2 != "search" &&
      filteredOrders
    ) {
      filteredOrders = filteredOrders.filter((order) => {
        //Filtering based on the first radio group (expected date related)
        // if (this.state.radioValue == "all") return true;
        if (this.state.radioValue == "open") return true;
        if (
          this.state.radioValue == "today" &&
          order.expected_delivery_date == moment().format("YYYY-MM-DD")
        )
          return true;
        else if (
          this.state.radioValue == "future" &&
          !!order.expected_delivery_date &&
          // order.expected_delivery_date > moment().format("YYYY-MM-DD")
          order.expected_delivery_date >=
            this.state.futureRangeStart.format("YYYY-MM-DD") &&
          order.expected_delivery_date <=
            this.state.futureRangeEnd.format("YYYY-MM-DD")
        )
          return true;
        else if (
          this.state.radioValue == "past" &&
          order.expected_delivery_date < moment().format("YYYY-MM-DD")
        )
          return true;
        else if (this.state.radioValue == "recentlyReceived") return true;
      });
    }
    let data = filteredOrders.filter((order) => {
      //Filtering based on the second radio group (order/promise status related)
      if (this.state.radioValue2 == "all" || this.state.radioValue2 == "search")
        return true;
      if (
        this.state.radioValue2 == "new" &&
        (order.status == "New" || order.status == "New POS")
      )
        return true;
      else if (
        this.state.radioValue2 == "received" &&
        order.status == "Received"
      )
        return true;
      else if (
        this.state.radioValue2 == "preparing" &&
        order.status == "Preparing"
      )
        return true;
      else if (
        this.state.radioValue2 == "delivery" &&
        order.status == "Out for Delivery"
      )
        return true;
      else if (
        this.state.radioValue2 == "complete" &&
        order.status == "Complete"
      )
        return true;
      else if (
        this.state.radioValue2 == "cancelled" &&
        order.status == "Cancelled"
      )
        return true;
      else if (
        this.state.radioValue2 == "refunded" &&
        order.status == "Refunded"
      )
        return true;
      else if (this.state.radioValue2 == "open" && order.status == "Open")
        return true;
    });
    data = data.filter(
      //Search filter (customer name/phone, area name, payment method, order id, expected date, payment reference, notes)
      (order) =>
        `${order.first_name} ${order.last_name}`
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        order.phone.includes(this.state.search) ||
        (order.area != "-" &&
          order.area.name
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (order.area != "-" &&
          order.area.ar_name.includes(this.state.search.toLowerCase())) ||
        order.payment_method
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        order.id.toString().includes(this.state.search) ||
        moment(order.expected_delivery_date, "YYYY-MM-DD")
          .format("DD-MM-YYYY")
          .includes(this.state.search) ||
        (order.payment_reference &&
          order.payment_reference
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        order.tracking_id
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        order.status.toLowerCase().includes(this.state.search.toLowerCase()) ||
        (order.civil_id && order.civil_id.includes(this.state.search)) ||
        (order.notes &&
          order.notes.toLowerCase().includes(this.state.search.toLowerCase()))
    );

    data = data.filter((o) =>
      !permissions.can_view_unpaid_orders
        ? permissions.can_view_cash_orders
          ? o.payment_complete || o.payment_method === "cash"
          : o.payment_complete
        : true
    );
    data = data.map((order, indx) => {
      return {
        key: order.id,
        index: order.type == "Order" ? order.id : "-",
        status: order.status,
        type: order.is_delivery ? "Delivery" : "Pick up",
        pn: !order.is_delivery ? order.address?.parking_number || "-" : "-",
        placed: moment(order.placed, "YYYY-MM-DDTHH:mm:ss").format(
          "DD-MM-YYYY h:mm A"
        ),
        received: order.received
          ? moment(order.received, "YYYY-MM-DDTHH:mm:ss").format(
              "DD-MM-YYYY h:mm A"
            )
          : "-",
        customer: [`${order.first_name} ${order.last_name}`, order.phone],
        tracking: order.tracking_id,
        expectedDate: order.expected_delivery_date
          ? moment(order.expected_delivery_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )
          : "-",
        expectedTime: `${
          order.expected_delivery_slot_start
            ? moment(order.expected_delivery_slot_start, "HH:mm:ss").format(
                "h:mm A"
              )
            : ""
        } - ${
          order.expected_delivery_slot_end
            ? moment(order.expected_delivery_slot_end, "HH:mm:ss").format(
                "h:mm A"
              )
            : ""
        }`,
        area: order.area
          ? config.language == "arabic"
            ? order.area.ar_name
              ? order.area.ar_name
              : "-"
            : order.area.name
            ? order.area.name
            : "-"
          : "-",
        paymentType:
          order.payment_method == "cash"
            ? "Cash"
            : order.payment_method == "pos"
            ? `POS`
            : order.payment_method == "benefit-transfer"
            ? t("Benefit Transfer")
            : order.payment_method == "wallet"
            ? t("Wallet")
            : order.payment_method == "custom"
            ? `Custom (${order.payment_ctype})`
            : `Online (${
                order.payment_method?.includes("link") &&
                !order.payment_complete
                  ? this.capitalizeFirstLetter(
                      order.payment_method.split(" link")[0].split("_")[0]
                    )
                  : order.payment_ctype === "1"
                  ? "Knet"
                  : ["0", "2", "5"].includes(order.payment_ctype)
                  ? "credit"
                  : order.payment_ctype
              })`,
        paymentStatus: order.payment_status,
        paymentReference: order.payment_reference
          ? order.payment_reference
          : "-",
        promise:
          order.type == "Promise" || order.status === "New POS" ? true : false,
        order_placed_by: order.order_placed_by,
        obj: order,
        cancellation_reason: order.cancellation_reason,
        refund_id: order.refund_id,
        total: order.total,
        currency: order.currency,
        channel: order.channel,
      };
    });

    const statusesOrder = {
      Received: 1,
      Preparing: 2,
      "Out for Delivery": 3,
      Complete: 4,
      Cancelled: 5,
      Refunded: 6,
    };
    let highestOrderStatus = 1;
    let hasOrderWithMultiplePayments = false;
    if (this.state.selectedRowKeys.length)
      this.state.selectedRowKeys.forEach((key) => {
        let order;
        if (
          this.state.radioValue2 != "new" &&
          this.state.radioValue2 != "search"
        ) {
          if (this.state.radioValue == "past") {
            order = this.props.orders.pastOrders.find(
              (order) => order.id == key
            );
          } else {
            order = this.props.orders.orders.find((order) => order.id == key);
          }
        } else {
          order = this.props.orders.promises.find(
            (promise) => promise.id == key
          );
          if (!order) {
            order = this.props.orders.new_orders.find(
              (promise) => promise.id == key
            );
          }
        }
        if (order) {
          if (statusesOrder[order.status] > highestOrderStatus) {
            highestOrderStatus = statusesOrder[order.status];
          }
          if (order.number_of_payments > 1) {
            hasOrderWithMultiplePayments = true;
          }
        }
      });

    const tableLoading = {
      spinning: this.state.searchLoading,
      indicator: <Spin indicator={antIcon} />,
    };

    let channels = data.map((i) => i.obj.channel);
    let allChannels = [...new Set(channels)];
    const channelMappings = {
      "Online Portal": "Online",
      "Online POS": "POS",
    };

    let allBranches = [];
    let allCountries = [];
    let allAreas = [];
    let allUsers = [];
    if (settings.is_gcc) {
      allCountries =
        config.language == "arabic"
          ? data.map((i) => i.obj.area.country_ar_name)
          : data.map((i) => i.obj.area.country_name);
      allCountries = new Set(allCountries);
      allCountries = [...allCountries];
    }
    if (settings.is_branch_based_ordering) {
      allBranches =
        config.language == "arabic"
          ? data.map((i) => i.obj.for_branch_ar)
          : data.map((i) => i.obj.for_branch);
      allBranches = new Set(allBranches);
      allBranches = [...allBranches];
    }
    if (!settings.enable_table_qr) {
      allAreas =
        config.language == "arabic"
          ? data.map((i) => i.obj.area.ar_name)
          : data.map((i) => i.obj.area.name);
      allAreas = new Set(allAreas);
      allAreas = [...allAreas];
    }
    allUsers = data.map((i) =>
      i.obj.order_placed_by !== "" ? i.obj.order_placed_by : "-"
    );
    allUsers = new Set(allUsers);
    allUsers = [...allUsers];

    const keywords = {
      "#customer#":
        this.state.selectedOrder?.language === "arabic"
          ? this.state.selectedOrder?.customer
            ? this.state.selectedOrder?.customer[0]?.trim()
            : "بك"
          : this.state.selectedOrder?.customer
          ? this.state.selectedOrder?.customer[0]
          : "",
      "#tracking_code#": this.state.selectedOrder?.obj.tracking_id,
      "#domain#": this.props.settings.domain,
    };
    const customWhatsAppMessage =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message.replace(
        /#domain#|#customer#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );
    const customWhatsAppMessageArabic =
      this.props.appearance.dashboardCustomTexts?.whatsapp_message_ar.replace(
        /#domain#|#customer#|#tracking_code#|#storename#|#product_name#|#booking_date#|#booking_start#/gi,
        function (matched) {
          return keywords[matched];
        }
      );

    // const whatsappMessageArabic = (trackingID, name) =>
    // `مرحبا ${name} من ${settings.ar_name}. لقد قمنا بإستلام طلبك وجاري الآن تجهيزه. رقم تتبع الطلب ${trackingID}`;

    const { editBranchOpen } = this.state;

    const IconStyleB = {
      height: "16px",
      width: "16px",
      verticalAlign: "middle",
      marginLeft: config.direction == "ltr" ? "0px" : "5px",
      marginRight: config.direction == "rtl" ? "0px" : "5px",
    };

    let countBusyBranches = 0;
    const now = new Date();
    if (
      settings.busy_until_active &&
      settings.busy_until_date &&
      settings.busy_until_time &&
      (settings.is_staff ||
        permissions.all_permitted_branches.length ==
          permissions.switchable_branches.length)
    ) {
      const busyDate = new Date(settings.busy_until_date);
      const busyTime = settings.busy_until_time.split(":");
      busyDate.setHours(busyTime[0]);
      busyDate.setMinutes(busyTime[1]);

      if (busyDate.getTime() >= now.getTime()) {
        countBusyBranches = permissions.switchable_branches.length;
      }
    } else {
      const busyBranches = permissions.all_permitted_branches.filter(
        (branch) => {
          if (
            branch.busy_until_active &&
            branch.busy_until_date &&
            branch.busy_until_time
          ) {
            const busyDate = new Date(branch.busy_until_date);
            const busyTime = branch.busy_until_time.split(":");
            busyDate.setHours(busyTime[0]);
            busyDate.setMinutes(busyTime[1]);

            return busyDate.getTime() >= now.getTime();
          }
          return false;
        }
      );
      countBusyBranches = busyBranches.length;
    }

    return (
      <div style={{ textAlign: config.direction == "ltr" ? "left" : "right" }}>
        {orderHistoryModalOpen && (
          <OrderHistory
            order={selectedHistoryOrder}
            onClose={() =>
              this.setState({
                orderHistoryModalOpen: false,
                selectedHistoryOrder: null,
              })
            }
          />
        )}
        {editBranchOpen && permissions.can_transfer_orders && (
          <EditBranchModal
            visible={editBranchOpen}
            selectedOrders={selectedRowKeys}
            orderType={this.state.radioValue2 == "new" ? "Promise" : "Order"}
            isFromBulkActions={true}
            originalBranchID={null}
            onClose={() => {
              this.setState({ editBranchOpen: false, selectedRowKeys: [] });
            }}
          />
        )}
        <div
          style={{
            textAlign: config.direction === "rtl" ? "left" : "right",
            height: 0,
          }}
        >
          {countBusyBranches > 0 && (
            <Button
              type="default"
              style={{
                position: "relative",
                top: -8,
                [config.direction === "rtl" ? "right" : "left"]: -10,
              }}
              onClick={() => this.props.history.push("/manage/locations")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <BranchesIcon style={{ ...IconStyleB }} />
                {countBusyBranches + " " + t("Busy")}
                <span
                  className="dot-red"
                  style={{
                    marginRight: config.direction == "ltr" ? "0px" : "5px",
                    marginLeft: config.direction == "rtl" ? "0px" : "5px",
                  }}
                />
              </div>
            </Button>
          )}
          {settings.enable_pickup_arrival_requests && (
            <PickupCustomers
              style={{
                top: -11,
                [config.direction === "rtl" ? "right" : "left"]: -5,
              }}
            />
          )}
          <Radio.Group
            defaultValue={ordersLayout}
            buttonStyle="solid"
            onChange={(e) => {
              localStorage.setItem("orders_layout", e.target.value);
              this.props.changeOrdersLayout(e.target.value);
            }}
          >
            <Radio.Button value="new" style={{ width: 40 }}>
              <DashOutlined
                style={{ position: "absolute", top: 0, left: 0, right: 0 }}
              />
              <BorderOutlined
                style={{ position: "absolute", top: 10, left: 0, right: 0 }}
              />
            </Radio.Button>
            <Radio.Button value="old" style={{ width: 40, top: -11 }}>
              <MenuOutlined />
            </Radio.Button>
          </Radio.Group>
          <Button
            className={"report-button"}
            style={{ width: 40, top: -11, marginLeft: "5px" }}
            onClick={() => {
              this.props.history.push("/manage/reports/downloads");
            }}
          >
            <ReportsIcon className={"report-icon"} />
          </Button>
        </div>
        <div className="pageContainer ordersContainer">
          <h1
            className={
              config.direction === "rtl" ? "pageArTitle" : "pageEnTitle"
            }
          >
            <OrdersIcon
              className={
                config.direction === "rtl"
                  ? "pageArTitleIcon"
                  : "pageEnTitleIcon"
              }
            />
            {t("Customer Orders")}{" "}
            <span>
              {" "}
              {this.props.orders.loading && <Spin indicator={antIcon} />}
            </span>
          </h1>
          {this.state.quickActionModal && (
            <QuickActionsModal
              visible={this.state.quickActionModal}
              onOk={() => this.quickModalOkAction()}
              onClose={() => this.quickModalCancelAction()}
              receivePromise={() => this.receivePromise()}
              loading={this.state.orderReceivedLoading}
              selectedOrder={this.state.selectedOrder}
              onChangeOrderStatus={this.handleStatusChangeInQuickActionsModal}
              status={this.state.orderStatusValueInModal}
              onChangePaymentStatus={this.handlePaymentStatusValueInModalChange}
              paymentStatus={this.state.paymentStatusValueInModal}
              cancelOrder={(
                reason,
                refundCustomer,
                returnInventory,
                refundWallet
              ) =>
                this.cancelOrder(
                  reason,
                  refundCustomer,
                  returnInventory,
                  refundWallet
                )
              }
              uncancelOrder={() => this.uncancelOrder()}
              goToNotes={this.state.goToNotes}
            />
          )}

          <RadioFilters
            onChange1={(e) =>
              this.setState({
                radioValue: e.target.value,
                selectedRowKeys: [],
                search: "",
              })
            }
            onChange2={(e) =>
              this.setState({
                radioValue2: e.target.value,
                selectedRowKeys: [],
                search: "",
                radioValue:
                  e.target.value === "open" ? "today" : this.state.radioValue,
              })
            }
            radioValue={this.state.radioValue}
            radioValue2={this.state.radioValue2}
            futureRangeStart={this.state.futureRangeStart}
            futureRangeEnd={this.state.futureRangeEnd}
            handleDateChange={this.handleDateChange}
          />
          <div
            className="mb-3"
            style={{
              display: "flow-root",
            }}
          >
            <Search
              placeholder={t("ordersSearchPlaceholder")}
              onChange={(e) => this.setState({ tempSearch: e.target.value })}
              onSearch={this.search}
              enterButton={
                <Button type="primary" disabled={!this.state.tempSearch}>
                  <SearchOutlined />
                </Button>
              }
              style={{
                maxWidth: 300,
                float: config.direction === "ltr" ? "left" : "right",
              }}
              value={this.state.tempSearch}
            />

            {this.props.is_staff ||
            (permissions && permissions.can_edit_orders) ? (
              <div
                style={{
                  marginBottom: 16,
                  textAlign: config.direction == "ltr" ? "left" : "right",
                  direction: config.direction,
                  float: config.direction === "ltr" ? "left" : "right",
                }}
                className="mx-3"
              >
                <Popover
                  visible={this.state.popoverVisible}
                  content={
                    <PopoverContent
                      selectedKeys={selectedRowKeys}
                      highestOrderStatus={highestOrderStatus}
                      hasOrderWithMultiplePayments={
                        hasOrderWithMultiplePayments
                      }
                      ordersType={
                        this.state.radioValue2 == "new" ? "promises" : "orders"
                      }
                      setPopoverInvisible={() =>
                        this.setState({
                          popoverVisible: false,
                          // selectedRowKeys: [],
                        })
                      }
                      clearSelectedKeys={() =>
                        this.setState({
                          popoverVisible: false,
                          selectedRowKeys: [],
                        })
                      }
                      setEditBranchModalVisible={() => {
                        this.setState({ editBranchOpen: true });
                      }}
                      switchToRecentlyReceived={() =>
                        this.setState({
                          radioValue2: "all",
                          radioValue: "recentlyReceived",
                        })
                      }
                    />
                  }
                  trigger="click"
                  placement={config.direction === "ltr" ? "bottom" : "bottom"}
                >
                  <Button
                    onClick={() => this.setState({ popoverVisible: true })}
                    type="primary"
                    disabled={!hasSelected}
                  >
                    {t("Perform Action")}
                  </Button>
                </Popover>
                <span
                  style={{
                    marginLeft: config.direction == "ltr" && 8,
                    marginRight: config.direction == "rtl" && 8,
                  }}
                >
                  {hasSelected
                    ? `${selectedRowKeys.length} ${t("SelectedBulk")}`
                    : ""}
                </span>
                {this.state.selectedRowKeys.length > 0 && (
                  <span
                    style={{
                      color: "blue",
                      fontSize: "0.8rem",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => this.setState({ selectedRowKeys: [] })}
                    className={config.direction === "ltr" ? "ml-2" : "mr-2"}
                  >
                    {t("clear selection")}
                  </span>
                )}
              </div>
            ) : null}
          </div>

          <TableScroller
            tableName={"orderKanban"}
            fixate={false}
            direction={config.direction}
          />

          <div
            className="whiteSubContainer"
            style={{ paddingBottom: 15, clear: "both" }}
          >
            <Table
              id="orderKanban"
              loading={tableLoading}
              dataSource={data}
              scroll={{
                x: settings.enable_table_qr
                  ? 2100
                  : settings.is_branch_based_ordering
                  ? 2700
                  : 2400,
              }}
              rowSelection={rowSelection}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
              }}
              onRow={(record, index) => ({
                style: {
                  backgroundColor: this.renderTableRowBackgroundColor(record),
                },
              })}
            >
              {settings.is_gcc && (
                <Column
                  title={t("Country")}
                  key="country"
                  filters={allCountries.map((b) => ({ text: b, value: b }))}
                  onFilter={(value, record) =>
                    record.obj.area[
                      config.language == "arabic"
                        ? "country_ar_name"
                        : "country_name"
                    ] == value
                  }
                  render={(text, record) => (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          paymentStatusValueInModal:
                            record.paymentStatus == 3
                              ? "Complete"
                              : "Incomplete",
                          orderStatusValueInModal: record.status,
                          selectedOrder: record,
                          quickActionModal: true,
                        });
                      }}
                    >
                      {config.language == "arabic"
                        ? record.obj.area.country_ar_name
                        : record.obj.area.country_name}
                    </div>
                  )}
                />
              )}
              {settings.is_branch_based_ordering && (
                <Column
                  title={t("For branch")}
                  key="forbranch"
                  filters={allBranches.map((b) => ({ text: b, value: b }))}
                  onFilter={(value, record) =>
                    record.obj[
                      config.language == "arabic"
                        ? "for_branch_ar"
                        : "for_branch"
                    ] == value
                  }
                  render={(text, record) => (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          paymentStatusValueInModal:
                            record.paymentStatus == 3
                              ? "Complete"
                              : "Incomplete",
                          orderStatusValueInModal: record.status,
                          selectedOrder: record,
                          quickActionModal: true,
                        });
                      }}
                    >
                      {config.language == "arabic"
                        ? record.obj.for_branch_ar
                        : record.obj.for_branch}
                    </div>
                  )}
                />
              )}
              {!settings.enable_table_qr && (
                <Column
                  title={t("Type")}
                  dataIndex="type"
                  key="type"
                  render={(text, record) => (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          paymentStatusValueInModal:
                            record.paymentStatus == 3
                              ? "Complete"
                              : "Incomplete",
                          orderStatusValueInModal: record.status,
                          selectedOrder: record,
                          quickActionModal: true,
                        });
                      }}
                    >
                      {t(text)}
                    </div>
                  )}
                />
              )}
              {(settings.enable_car_qr || settings.enable_table_qr) && (
                <Column
                  title={settings.enable_car_qr ? t("Parking #") : t("Table #")}
                  dataIndex="pn"
                  key="pn"
                />
              )}
              <Column
                title="#"
                dataIndex="index"
                key="index"
                render={(text, record) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        paymentStatusValueInModal:
                          record.paymentStatus == 3 ? "Complete" : "Incomplete",
                        orderStatusValueInModal: record.status,
                        selectedOrder: record,
                        quickActionModal: true,
                      });
                    }}
                  >
                    {text}
                  </div>
                )}
              />
              <Column
                title={t("Total")}
                dataIndex="total"
                key="total"
                render={(text, record) => (
                  <div
                    style={{
                      cursor: "pointer",
                      minWidth: 80,
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      this.setState({
                        paymentStatusValueInModal:
                          record.paymentStatus == 3 ? "Complete" : "Incomplete",
                        orderStatusValueInModal: record.status,
                        selectedOrder: record,
                        quickActionModal: true,
                      });
                    }}
                  >
                    <span>
                      <span
                        className={
                          record.paymentStatus === 3
                            ? "dot-green"
                            : record.paymentStatus === 1
                            ? "dot-red"
                            : "dot-orange"
                        }
                      ></span>{" "}
                      {text ? text.toFixed(settings.currency_decimals) : "-"}{" "}
                      {record.currency}
                    </span>
                  </div>
                )}
              />
              <Column
                title={t("Tracking Code")}
                dataIndex="tracking"
                key="tracking"
                render={(text, record) => (
                  <div
                    style={{ cursor: "pointer", width: 100 }}
                    onClick={() => {
                      this.setState({
                        paymentStatusValueInModal:
                          record.paymentStatus == 3 ? "Complete" : "Incomplete",
                        orderStatusValueInModal: record.status,
                        selectedOrder: record,
                        quickActionModal: true,
                      });
                    }}
                  >
                    {text}
                  </div>
                )}
              />
              {settings.enable_ocims && this.state.radioValue2 !== "new" && (
                <Column
                  title={t("Ocims Status")}
                  key="ocims_status"
                  dataIndex={"ocims_status"}
                  render={(text, record) => (
                    <span>
                      <span
                        className={this.statusDotClassName(
                          record.obj.ocims_status
                        )}
                      ></span>
                      {t(record.obj.ocims_status)}
                    </span>
                  )}
                />
              )}
              <Column
                title={t("Status")}
                dataIndex="status"
                filters={
                  this.state.radioValue2 !== "new" && [
                    { text: t("Received"), value: "Received" },
                    { text: t("Preparing"), value: "Preparing" },
                    { text: t("Out for Delivery"), value: "Out for Delivery" },
                    { text: t("Ready to collect"), value: "Ready to collect" },
                    { text: t("Complete"), value: "Complete" },
                    { text: t("Cancelled"), value: "Cancelled" },
                    { text: t("Refunded"), value: "Refunded" },
                  ]
                }
                onFilter={(value, record) => {
                  if (this.state.radioValue2 === "new") return true;
                  if (value === "Out for Delivery") {
                    return record.obj.is_delivery && record.status === value;
                  }
                  if (value === "Ready to collect") {
                    return (
                      !record.obj.is_delivery &&
                      record.status === "Out for Delivery"
                    );
                  }
                  return record.status === value;
                }}
                render={(text, record) => (
                  <span
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    onClick={() => {
                      this.setState({
                        paymentStatusValueInModal:
                          record.paymentStatus == 3 ? "Complete" : "Incomplete",
                        orderStatusValueInModal: record.status,
                        selectedOrder: record,
                        quickActionModal: true,
                      });
                    }}
                  >
                    <span className={this.statusDotClassName(text)}></span>{" "}
                    {t(
                      text != "Out for Delivery"
                        ? text
                        : record.obj.is_delivery
                        ? "Out for Delivery"
                        : "Ready to collect"
                    )}
                  </span>
                )}
                key="status"
              />
              {(!settings.enable_table_qr ||
                settings.require_dine_in_contact_info) && (
                <Column
                  title={t("Customer")}
                  dataIndex="customer"
                  render={(customer, record) => (
                    <div
                      onClick={() => {
                        this.setState({
                          paymentStatusValueInModal:
                            record.paymentStatus == 3
                              ? "Complete"
                              : "Incomplete",
                          orderStatusValueInModal: record.status,
                          selectedOrder: record,
                          quickActionModal: true,
                        });
                      }}
                      style={{
                        width: 140,
                        cursor: "pointer",
                        direction: "ltr",
                      }}
                    >
                      <p
                        className="mb-0 text-center"
                        style={{ width: 90, fontSize: 13 }}
                      >
                        {settings.enable_blacklist && (
                          <Popover
                            placement={
                              config.direction == "ltr" ? "left" : "right"
                            }
                            title={t("Flagged Customer")}
                            content={
                              <div
                                style={{
                                  maxWidth: 275,
                                  wordWrap: "break-word",
                                }}
                              >
                                {record.obj.customer__notes}
                              </div>
                            }
                            trigger="hover"
                          >
                            <span
                              style={{
                                position: "relative",
                                top: 0,
                                left: -2,
                                cursor: "pointer",
                              }}
                              className={
                                record.obj.customer__is_blacklist
                                  ? "dot-red"
                                  : ""
                              }
                            ></span>{" "}
                          </Popover>
                        )}
                        {customer ? customer[0] : ""}
                      </p>
                      <p
                        className="text-left"
                        style={{
                          fontSize: 13,
                          lineHeight: 0.8,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {customer[1]}{" "}
                        <a
                          style={{
                            position: "relative",
                            top: -3,
                            left: 2,
                            cursor: "pointer",
                          }}
                          href={`https://wa.me/${customer[1].slice(1)}?text=${
                            record.obj.language === "arabic"
                              ? customWhatsAppMessageArabic
                              : customWhatsAppMessage
                          }`}
                          target="_blank"
                        >
                          <WhatsAppOutlined
                            style={{ fontSize: 13, color: "#25d366" }}
                          />
                        </a>
                      </p>
                    </div>
                  )}
                  key="customer"
                />
              )}
              {!settings.enable_table_qr && (
                <Column
                  title={t("Area")}
                  dataIndex="area"
                  key="area"
                  filterIcon={<FilterFilled className="mr-2" />}
                  sortUpIcon={<CaretUpOutlined />}
                  sortDownIcon={<CaretDownOutlined />}
                  sorter={(a, b) =>
                    a.obj.area.name.localeCompare(b.obj.area.name)
                  }
                  filters={allAreas.map((a) => ({ text: a, value: a }))}
                  onFilter={(value, record) =>
                    record.obj.area[
                      config.language == "arabic" ? "ar_name" : "name"
                    ] == value
                  }
                />
              )}
              {settings.due_soon_toggle &&
                this.state.radioValue === "today" &&
                this.state.radioValue2 !== "new" && (
                  <Column
                    title={t("Due Status")}
                    key="due"
                    filters={[
                      {
                        text:
                          config.language === "english" ? "Later" : "لاحقاً",
                        value: "later",
                      },
                      {
                        text: config.language === "english" ? "Soon" : "قريبًا",
                        value: "soon",
                      },
                      {
                        text:
                          config.language === "english" ? "Overdue" : "مستحق",
                        value: "due",
                      },
                    ]}
                    sorter={(a, b) => {
                      const aStatus = this.checkDueSoon(
                        a.obj.expected_delivery_slot_start
                      );
                      const bStatus = this.checkDueSoon(
                        b.obj.expected_delivery_slot_start
                      );
                      const order = ["later", "soon", "due"];
                      return order.indexOf(aStatus) - order.indexOf(bStatus);
                    }}
                    onFilter={(value, record) =>
                      this.onSoonFilter(value, record)
                    }
                    render={(text, record) => (
                      <div
                        style={{
                          cursor: "pointer",
                          minWidth: 80,
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          this.setState({
                            paymentStatusValueInModal:
                              record.paymentStatus == 3
                                ? "Complete"
                                : "Incomplete",
                            orderStatusValueInModal: record.status,
                            selectedOrder: record,
                            quickActionModal: true,
                          });
                        }}
                      >
                        {this.checkDueSoonStatus(
                          record.obj.expected_delivery_slot_start
                        )}
                      </div>
                    )}
                  />
                )}
              {!settings.enable_table_qr && (
                <Column
                  title={t("Expected Date")}
                  dataIndex="expectedDate"
                  key="expectedDate"
                  sorter={{
                    compare: (a, b) =>
                      moment(
                        `${a.obj.expected_delivery_date || "2099-10-23"} ${
                          a.obj.expected_delivery_slot_start || "00:00:00"
                        }`,
                        "YYYY-MM-DD HH:mm"
                      ) -
                      moment(
                        `${b.obj.expected_delivery_date || "2099-10-23"} ${
                          b.obj.expected_delivery_slot_start || "00:00:00"
                        }`,
                        "YYYY-MM-DD HH:mm"
                      ),
                  }}
                  sortDirections={["descend", "ascend"]}
                  render={(text, record) => (
                    <div>
                      <p className="mb-0">
                        {text === "-"
                          ? "-"
                          : moment(text, "DD-MM-YYYY").format(
                              config.language == "arabic"
                                ? "DD-MM-YYYY"
                                : "DD-MMM-YYYY"
                            )}
                      </p>
                      {record.obj.date_edited_by && (
                        <p
                          style={{
                            color: "#aaaaaa",
                            fontStyle: "italic",
                            fontSize: "0.75rem",
                          }}
                        >
                          {t("Last edited by")} {record.obj.date_edited_by}
                        </p>
                      )}
                    </div>
                  )}
                />
              )}
              {!settings.enable_table_qr && (
                <Column
                  title={t("Expected Time")}
                  dataIndex="expectedTime"
                  key="expectedTime"
                  sorter={{
                    compare: (a, b) =>
                      moment(a.obj.expected_delivery_slot_start, "HH:mm:ss") -
                      moment(b.obj.expected_delivery_slot_start, "HH:mm:ss"),
                  }}
                  sortDirections={["descend", "ascend"]}
                  defaultSortOrder={
                    this.state.radioValue == "today" && "descend"
                  }
                  render={(text, record) => (
                    <div>
                      <p className="mb-0">
                        {this.translateDateTimeToArabic(text)}
                      </p>
                      {record.obj.time_edited_by && (
                        <p
                          style={{
                            color: "#aaaaaa",
                            fontStyle: "italic",
                            fontSize: "0.75rem",
                          }}
                        >
                          {t("Last edited by")} {record.obj.time_edited_by}
                        </p>
                      )}
                    </div>
                  )}
                />
              )}
              <Column
                title={t("Placed")}
                dataIndex="placed"
                key="placed"
                sorter={{
                  compare: (a, b) =>
                    moment(a.placed, "DD-MM-YYYY h:mm A") -
                    moment(b.placed, "DD-MM-YYYY h:mm A"),
                }}
                render={(text) => (
                  <span>
                    {this.translateDateTimeToArabic(
                      moment(text, "DD-MM-YYYY h:mm A").format(
                        config.language == "arabic"
                          ? "DD-MM-YYYY h:mm A"
                          : "DD-MMM-YYYY h:mm A"
                      )
                    )}
                  </span>
                )}
              />
              <Column
                title={t("Received")}
                dataIndex="received"
                key="received"
                sorter={{
                  compare: (a, b) =>
                    moment(a.received, "DD-MM-YYYY h:mm A") -
                    moment(b.received, "DD-MM-YYYY h:mm A"),
                }}
                render={(text) => (
                  <span>
                    {text === "-"
                      ? "-"
                      : this.translateDateTimeToArabic(
                          moment(text, "DD-MM-YYYY h:mm A").format(
                            config.language == "arabic"
                              ? "DD-MM-YYYY h:mm A"
                              : "DD-MMM-YYYY h:mm A"
                          )
                        )}
                  </span>
                )}
              />
              <Column
                title={t("Payment Type")}
                dataIndex="paymentType"
                key="paymentType"
                filters={[
                  { text: "Cash", value: "cash" },
                  { text: "POS", value: "pos" },
                  settings.enable_benefit_transfer
                    ? {
                        text: t("Benefit Transfer"),
                        value: "benefit-transfer",
                      }
                    : null,
                  settings.enable_wallet_staff
                    ? { text: t("Wallet"), value: "wallet" }
                    : null,
                  { text: "Custom", value: "custom" },
                  { text: "Online", value: "online" },
                ].filter((filter) => filter !== null)}
                onFilter={(value, record) =>
                  this.onFilterPaymentMethod(value, record)
                }
                render={(text, record) => (
                  <div>
                    <p className="mb-0">
                      {settings.enable_table_qr &&
                      settings.enable_cash === false &&
                      text === "Cash"
                        ? "-"
                        : text}
                    </p>
                    {record.obj.payment_edited_by && (
                      <p
                        style={{
                          color: "#aaaaaa",
                          fontStyle: "italic",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Last edited by")} {record.obj.payment_edited_by}
                      </p>
                    )}
                  </div>
                )}
              />
              <Column
                title={t("Payment Status")}
                dataIndex="paymentStatus"
                key="paymentStatus"
                render={(text, record) => (
                  <span
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    onClick={() =>
                      this.setState({
                        orderStatusValueInModal: record.status,
                        paymentStatusValueInModal:
                          record.paymentStatus == 3 ? "Complete" : "Incomplete",
                        selectedOrder: record,
                        quickActionModal: true,
                      })
                    }
                  >
                    <span
                      className={
                        record.paymentStatus === 3
                          ? "dot-green"
                          : record.paymentStatus === 1
                          ? "dot-red"
                          : "dot-orange"
                      }
                    ></span>{" "}
                    {record.paymentStatus === 3
                      ? t("PaymentComplete")
                      : record.paymentStatus === 1
                      ? t("PaymentIncomplete")
                      : t("Partial")}
                  </span>
                )}
                filters={[
                  { text: t("Complete"), value: 3 },
                  { text: t("Partial"), value: 2 },
                  { text: t("Incomplete"), value: 1 },
                ]}
                onFilter={(value, record) => record.paymentStatus === value}
              />
              <Column
                title={t("Payment Reference")}
                dataIndex="paymentReference"
                key="paymentReference"
              />
              {settings.enable_pos && (
                <Column
                  title={t("Channel")}
                  key="channel"
                  filters={allChannels.map((b) => ({ text: b, value: b }))}
                  onFilter={(value, record) => record.obj["channel"] == value}
                  render={(text, record) => (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          paymentStatusValueInModal:
                            record.paymentStatus == 3
                              ? "Complete"
                              : "Incomplete",
                          orderStatusValueInModal: record.status,
                          selectedOrder: record,
                          quickActionModal: true,
                        });
                      }}
                    >
                      {t(channelMappings[record.channel])}
                    </div>
                  )}
                />
              )}
              <Column
                title={t("Order Placed By")}
                dataIndex="order_placed_by"
                render={this.renderColumnOrderPlacedBy}
                key="order_placed_by"
                filterIcon={<FilterFilled className="mr-2" />}
                sorter={(a, b) =>
                  a.obj.order_placed_by.localeCompare(b.obj.order_placed_by)
                }
                filters={allUsers.map((a) => ({ text: a, value: a }))}
                onFilter={(value, record) =>
                  value == "-"
                    ? record.obj.order_placed_by == ""
                    : record.obj.order_placed_by == value
                }
              />
              <Column
                title={t("Notes")}
                render={this.renderNotesColumn}
                key="notes"
              />

              {settings.enable_pos && this.state.radioValue2 != "new" && (
                <Column
                  title={t("Edit")}
                  render={(text, record) => (
                    <div className="text-center">
                      {record.obj.payment_complete ||
                      !permissions.can_edit_orders ||
                      (!permissions.can_edit_other_users_orders &&
                        record.order_placed_by != this.props.username) ? (
                        <Popover
                          placement={
                            config.direction == "ltr" ? "left" : "right"
                          }
                          content={
                            <div style={{ maxWidth: 275 }}>
                              {t(
                                "The order cannot be edited either because you dont have permission or the payment is complete"
                              )}
                            </div>
                          }
                          trigger="hover"
                        >
                          <Button disabled={true} type="primary">
                            {t("Edit")}
                          </Button>
                        </Popover>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() =>
                            this.props.history.push("/manage/pos", {
                              orderID: record.key,
                            })
                          }
                        >
                          {t("Edit")}
                        </Button>
                      )}
                    </div>
                  )}
                  key="edit"
                />
              )}
              {permissions.can_view_integrations &&
                (settings.enable_deliverect ||
                  settings.enable_grubtech ||
                  settings.enable_urban_piper) &&
                this.state.radioValue2 !== "new" && (
                  <Column
                    title={
                      settings.enable_grubtech
                        ? t("Received on Grubtech")
                        : settings.enable_urban_piper
                        ? t("Received on Urban Piper")
                        : t("Received on Deliverect")
                    }
                    key="deliverect_received"
                    render={(text, record) => (
                      <div className="text-center">
                        {record?.obj?.deliverect_received ? (
                          <CheckCircleTwoTone
                            twoToneColor="#52c41a"
                            style={{ fontSize: 22, top: 0 }}
                          />
                        ) : (
                          <CloseCircleTwoTone
                            twoToneColor="#ff4d4f"
                            style={{ fontSize: 22, top: 0 }}
                          />
                        )}
                      </div>
                    )}
                  />
                )}
              {permissions.can_view_integrations &&
                settings.enable_foodics &&
                this.state.radioValue2 !== "new" && (
                  <Column
                    title={t("Received on Foodics")}
                    filters={[
                      {
                        text: t("On"),
                        value: true,
                      },
                      {
                        text: t("Off"),
                        value: false,
                      },
                    ]}
                    onFilter={(value, record) =>
                      Boolean(record.obj.foodics_id) == value
                    }
                    render={(text, record) => (
                      <div className="text-center">
                        {record?.obj?.foodics_id ? (
                          <CheckCircleTwoTone
                            twoToneColor="#52c41a"
                            style={{ fontSize: 22, top: 0 }}
                          />
                        ) : (
                          <CloseCircleTwoTone
                            twoToneColor="#ff4d4f"
                            style={{ fontSize: 22, top: 0 }}
                          />
                        )}
                      </div>
                    )}
                  />
                )}
              {permissions.can_view_integrations &&
                settings.enable_matix &&
                this.state.radioValue2 !== "new" && (
                  <Column
                    title={t("Received on Matix")}
                    filters={[
                      {
                        text: t("On"),
                        value: true,
                      },
                      {
                        text: t("Off"),
                        value: false,
                      },
                    ]}
                    onFilter={(value, record) =>
                      Boolean(record.obj.pos_rocket_id) == value
                    }
                    render={(text, record) => (
                      <div className="text-center">
                        {record?.obj?.pos_rocket_id ? (
                          <CheckCircleTwoTone
                            twoToneColor="#52c41a"
                            style={{ fontSize: 22, top: 0 }}
                          />
                        ) : (
                          <CloseCircleTwoTone
                            twoToneColor="#ff4d4f"
                            style={{ fontSize: 22, top: 0 }}
                          />
                        )}
                      </div>
                    )}
                  />
                )}
              {permissions.can_view_audit_logs &&
                this.state.radioValue2 !== "new" && (
                  <Column
                    title={t("History")}
                    key="history"
                    width={100}
                    render={(text, record) => (
                      <div className="text-center">
                        <Button
                          className="px-1"
                          type="link"
                          icon={<HistoryOutlined />}
                          style={{ color: "#006391" }}
                          onClick={() =>
                            this.setState({
                              selectedHistoryOrder: record.obj.id,
                              orderHistoryModalOpen: true,
                            })
                          }
                        ></Button>
                      </div>
                    )}
                  />
                )}
            </Table>
            <span
              style={{ position: "relative", top: data.length ? -37 : -50 }}
            >
              {t("Total")}: {data.length} {t("orders")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    username: state.auth.username,
    user: state.auth.user,
    permissions: state.auth.permissions,
    config: state.config,
    is_staff: state.auth.is_staff,
    settings: state.auth.settings,
    ordersLayout: state.orders.ordersLayout,
    customerBookingsItems: state.customerBookings.orderItems,
    serviceBookingsItems: state.serviceBookings.orderItems,
    appearance: state.appearance,
    openOrderModal: state.orders.openOrderModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrders: () => dispatch(fetchOrders()),
    updateOrderStatusAndPayment: (
      type,
      id,
      status,
      paymentStatus,
      cancelReason,
      refundCustomer,
      returnInventory,
      refundWallet
    ) =>
      dispatch(
        updateOrderStatusAndPayment(
          type,
          id,
          status,
          paymentStatus,
          cancelReason,
          refundCustomer,
          returnInventory,
          refundWallet
        )
      ),
    storeSearchedOrders: (orders) => dispatch(storeSearchedOrders(orders)),
    changeOrdersLayout: (layout) => dispatch(changeOrdersLayout(layout)),
    updateCustomerBookingStatus: (order_id, status) =>
      dispatch(updateCustomerBookingStatus(order_id, status)),
    updateCustomerServiceStatus: (order_id, status) =>
      dispatch(updateCustomerServiceStatus(order_id, status)),
    setOpenOrderModal: (val) =>
      dispatch({ type: "SET_OPEN_ORDER_MODAL", payload: val }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(index));
