import messages from "./messages";
import configurations from "./configurations";

const translations = {
  ...messages,
  ...configurations,
  "Add area": "اضافة منطقة",
  "Areas & Rates": "المناطق و التسعيرات",
  "Delivery ": "التوصيل",
  Province: "المحافظة",
  "Delivery charge (numbers)": "سعر التوصيل (رقم)",
  "Delivery cost": "تكلفة التوصيل",
  "Select a province": "اختر المحافظة",
  Role: "الصلاحيات",
  Default: "الافتراضي",
  "Pin area (anywhere in the area for reporting)": "ضع العنوان",
  "New Area": "منطقة جديدة",
  "New Province": "محافظة جديدة",
  "Add province": "إضافة محافظة",

  "Select all?": "تحديد الكل؟",
  "areas selected out of": "مناطق محددة من اصل",
  "Set charge to": "تغيير السعر الى",
  "Set price for all": "حدد السعر",
  "Leaving the field empty will keep the prices as is":
    "ترك السعر بدون تحديد لن يؤثر على الاسعار الحالية",
  "Cancel selection?": "الغاء التحديد؟",
  "To use this feature,": "لاستخدام هذه الميزة، قم بإضافة",
  "and make sure to mark your location on the map provided":
    "و تأكد من تحديد موقع الفرع على الخريطة",
  "DHL compatible": "متوافق مع DHL",
  "Are you sure you want to delete this area?":
    "هل انت متاكد من حذف هذه المنطقة؟",
  "Are you sure you want to delete all selected areas? You can't undo this action":
    "هل انت متأكد من حذف جميع المناطق المحددة؟ لا يمكنك الرجوع",

  "DHL incompatible": "غير متوافق مع DHL",
  "By Date and Time": "حسب تاريخ و وقت التوصيل",
  Provinces: "المحافظات",
  Continent: "القارة",
  "Add countries": "اضافة دول",
  "Search for a country": "البحث عن دولة",
  International: "عالمي",
  Local: "محلي",
  GCC: "دول الخليج",
};

export default translations;
