import { SWITCH_LANGUAGE } from "../actions/actionTypes";

const initialState = {
  language: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english",
  direction: localStorage.getItem("language")
    ? localStorage.getItem("language") === "arabic"
      ? "rtl"
      : "ltr"
    : "ltr",
  runTutorial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SWITCH_LANGUAGE:
      localStorage.setItem("language", payload);
      return {
        ...state,
        language: payload,
        direction: payload == "arabic" ? "rtl" : "ltr",
      };

    case "TOGGLE_RUN_TUTORIAL":
      return { ...state, runTutorial: !state.runTutorial };
    default:
      return state;
  }
};
