import {
  FETCH_HIGHLIGHTS_LOADING,
  FETCH_HIGHLIGHTS,
} from "../actions/actionTypes";

const initialState = {
  highlightsLoading: false,
  highlights: null,
  branches: [],
  byDay: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HIGHLIGHTS_LOADING:
      return { ...state, highlightsLoading: true };

    case FETCH_HIGHLIGHTS:
      return {
        ...state,
        highlights: payload.highlights,
        branches: payload.branches || [],
        highlightsLoading: false,
        byDay: payload.by_day,
      };

    default:
      return state;
  }
};
