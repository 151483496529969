import messages from "./messages";

const trans = {
  ...messages,
  Businesses: "ادارة الشركات",
  Amount: "الاجمالي",
  "Placed On": "تاريخ الطلب",
  "Expected Delivery Date": "تاريخ التوصيل",
  "Invoiced Amount": "المبلغ المفوتر",
  "Opening Balance": "الرصيد الافتتاحي",
  "Not Paid": "غير مدفوع",
  "Num. Orders": "عدد الطلبات",
  "Search by tracking id or branch name": "البحث عن رمز الطلب او الفرع",
  "Date Range": "المده",
  "End Balance": "الرصيد النهائي",
  "Payment Terms": "شروط الدفع",
  Age: "عمر الفاتورة بالأيام",
  "Invoice ID": "رمز الفاتوره",
  days: "يوم",
  Terms: "الشروط",
  "Cut Off Times": "اوقات التوصيل",
  "Delivery on": "ايام التوصيل",
  "Business name": "اسم الشركة",
  "Lead Time": "عدد الايام للتحضير",
  "Payment Days": "شرط ايام للدفع",

  "Business Clients": "الشركاة",
  "Prep. Sheet": "جدول التحضير",
  "Statement of Account": "كشف الحساب",
  "Invoiced for order by": "ايصال لطلب من",
  "for delivery on": "للتوصيل في يوم",
  Billed: "مفوترة",
  "Search for a business": "ابحث عن شركة",
  "Distributor details": "تفاصيل الشركة",
  "Are you sure you want to delete this distributor? You can't undo this":
    "هل انت متأكد من حذف هذه الشركة؟ لا يمكنك الرجوع",
  "New business": "اضافة شركة",
  Open: "مفتوح",
  "Existing customer": "عميل سابق",
  "Preparation Sheet": "جدول التحضير",
  "All Businesses": "جميع الشركات",
  "All Order Statuses": "جميع حالات الطلب",
  "New customer": "عميل جديد",
  "Customer's full name": "اسم العميل",
  "Customer's phone": "رقم العميل",
  "Full name": "الاسم الكامل",
  "Phone number": "رقم الهاتف",
  "Distributor name": "اسم الشركة",
  "Contact name": "اسم التواصل",
  "Email address": "الايميل",
  "Minimum order value": "الحد الادنى للطلب",
  "Lead time": "الوقت المستغرق للطلب",
  "Payment days": "ايام الدفع",
  "Lead time in days": "الوقت المستغرق بالايام",
  "Select a phone number": "اختر رقم الهاتف",
  "Minimum quantity": "الحد الادنى",
  Increments: "الزيادات",
  "Remove from": "ازالة من",
  "Only this business": "هذه الشركة فقط",
  "All businesses": "جميع الشركات",
  "Toggle product": "تغيير حالة المنتج",
  "Turn on for": "تشغيل ل",
  "Turn off for": "تعطيل ل",
  "Select the products that you want to add to the *** busienss. You can select multiple products at once":
    "اختر المنتجات التي تريد اضافتها الى شركة ***. يمكنك اختيار اكثر من منتج مرة واحدة",
  "Add to all businesses": "اضافة الى جميع الشركات",
  "Minimum order quantity": "الحد الادنى للطلب",
  "Unselect all products": "الغاء التحديد عن المنتجات",
  "products selected out of": "منتجات محددة من اصل",
  "Set minimum order for all": "حدد الحد الادنى للجميع",
  "Set increments for all": "حدد الزيادات للجميع",
  "Leave fields empty to keep original values":
    "اترك الخانات فارغة لإبقاء القيم الاصلية",
  "Are you sure you want to remove the selected products?":
    "هل انت متأكد من ازالة جميع المنتجات المحددة؟",
  Reset: "اعادة ضبط",
  "Additional Info": "معلومات اضافية",
  "Edit branch": "تعديل الفرع",
  "Delete branch": "حذف الفرع",
  "Are you sure you want to delete this branch?":
    "هل انت متأكد من حذف هذا الفرع؟",
  "Are you sure you want to delete this Email?":
    "هل انت متأكد من حذف هذا الايميل؟",
  "Every distributor should have at least one branch. Branches allow distributors to order on their landing page using the branch phone number.":
    "يجب على كل شركة ان يكون لديها فرع واحد على الأقل. الأفرع تمكن الشركات من الطلب من واجهة الموقع المخصص لهم عن طريق رقم الهاتف",
  "branches selected out of": "فرع محدد من اصل",
  "Actions on selected branches": "تغيير الأفرع المحددة",
  "Set delivery rate to": "تغيير سعر التوصيل الى",
  "Branch name": "اسم الفرع",
  Email: "الايميل",
  Create: "انشاء",
  "Edit *** branch": "تعديل فرع ***",
  "Cut off times (when does each day end)": "وقت نهاية كل يوم",
  "Minimum order quantity": "العدد الادنى للطلب",
  "Enable online payments": "تفعيل عمليات دفع الاونلاين",
  "Invoice Due After": "يتم التحصيل بعد",
  Days: "ايام",
  "Send Reminders": "تفعيل رسائل التذكير",
  "Send Reminder": "ارسال رسالة تذكير",
  "Reminder Frequency": "الفترة بين كل رسالة تذكير",
  "Last sent": "آخر رسالة",
  Send: "ارسل",
  "The amount due before the selected start date.":
    "المبلغ المفوتر قبل التاريخ المحدد",
  "The number of days allowed before each invoice must be paid. Invoices that are not marked as paid will be past due after this many days.":
    "عدد الايام المتاحة لسداد كل ايصال",
  "Send a summary of outstanding payments to the distributor for any invoice past due the agreed payment days.":
    "ارسال بريد الكتروني تلقائيا لتذكير العميل بالايصالات الغير مدفوعة",
  "An email will be sent to the distributor at this interval. For example, every 3 days.":
    "الفترة بين كل رسالة تذكيرية بالايام.",
  "Payment Due Date": "التاريخ المحدد للدفع",
  "Enable on credit payments": "تفعيل الدفع من المحفظة",
  "Wallet balance": "رصيد المحفظة",
  "The business can use this balance to place orders using the on-credit payment method":
    "تستطيع هذه الشركة من وضع طلبات باستخدام رصيد محفظتهم وسيتم خصم قيمة الطلبات من الرصيد",
  "Wallet password": "كلمة مرور المحفظة",
  "The business can use this password to enter their profile and top-up their wallet":
    "على هذه الشركة ان تستخدم كلمة مرور للدخول في حسابهم وتعبئة رصيد للمحفظة",
  "New Discount": "خصم جديد",
  Discounts: "الخصومات",
  "Manage Discounts": "إدارة الخصومات",
  "Add discounts": "أضف الخصم",
  "Discount Description": "وصف الخصم",
  "Search for a discount description": "ابحث عن وصف الخصم",
  "Please select discount type": "الرجاء تحديد نوع الخصم",
  "Enter discount description": "أدخل وصف الخصم",
  "Enter discount amount": "أدخل مبلغ الخصم",
  "Can't apply discounts to paid order": "لا يمكن تطبيق الخصومات على طلب مدفوع",
  "Custom ID": "تعريف خاص",
  "Free Delivery Charges": "رسوم التوصيل المجاني",
  "Are you sure you want to delete this discount?":
    "هل أنت متأكد أنك تريد حذف هذا الخصم؟",
  delivery: "توصيل",
  fixed: "مبلغ محدد",
  percent: "نسبة",
  "Expected On": "تاريخ التوصيل",
  "Enable review cart": "تفعيل مراجعة عربة التسوق",
  "Delivery Charges": "رسوم التوصيل",
  "Order placed date": "تاريخ الطلب",
  "Create order": "إنشاء طلب",
  "Delivery charge": "رسوم التوصيل",
  "Order placed successfully": "تم إنشاء الطلب بنجاح",
  "Select branch": "اختر الفرع",
  "Expected Delivery Date": "تاريخ التوصيل المتوقع",
  "Download Quotation": "تحميل العرض",
  "Use original product price": "استخدم سعر المنتج الأصلي",
  businesses: "شركة",
  "You can increase your businesses limit from": "يمكنك اضافة شركات اضافية من",
};

export default trans;
