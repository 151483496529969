import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd Components
import {
  Modal,
  Input,
  Spin,
  Tabs,
  message,
  Select,
  Button,
  Popconfirm,
  Table,
  List,
  Radio,
} from "antd";
import instance from "../../store/actions/instance";
import { deleteDriver } from "../../store/actions/drivers";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  EditOutlined,
  SaveOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
// Actions

class NewPaymentMethodModal extends Component {
  state = {
    saveLoading: false,
    paymentMethod: "",
    paymentReference: this.props.paymentReference || "",
    currentTab: "list",
    paymentMethodLoading: false,
    selectedPayment: this.props.paymentCType || "",
    editPaymentMethodSwitch: false,
    editSelectedPaymentId: null,
    editSelectedPayment: null,
  };

  addPaymentMethod = async () => {
    const { paymentMethod, paymentReference, currentTab, selectedPayment } =
      this.state;
    const { t } = this.props;

    if (
      !this.props.paymentMethods.find(
        (method) =>
          method.payment_method.toLowerCase().trim() ===
          paymentMethod.toLowerCase().trim()
      )
    ) {
      try {
        const res = await instance.post("/create_custom_payment_methods/", {
          payment_method: paymentMethod,
        });
        this.props.addPaymentMethodToList(res.data);
        message.success(t("Payment Method added successfully"));
        this.setState({
          paymentMethod: "",
          paymentReference: "",
          currentTab: "list",
          selectedPayment: res.data.payment_method,
        });
      } catch (err) {
        if (err.response && err.response.status === 403)
          message.error(t("AccessDeniedMessage"));
        else message.error(t("SomethingWentWrong"));
      }
    } else {
      message.error(t("Payment Method already in the list"));
    }
  };

  deletePaymentMethod = async (id) => {
    const { t } = this.props;
    try {
      const res = await instance.post("/delete_custom_payment_methods/", {
        id: id,
      });
      this.props.deletePaymentMethodToList(id);
    } catch (err) {
      if (err.response && err.response.status === 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };

  updatePaymentMethod = async (id, payment) => {
    const { t } = this.props;
    if (
      payment.toLowerCase().trim() ===
        this.state.editSelectedPayment.toLowerCase().trim() ||
      this.props.paymentMethods.find(
        (method) =>
          method.payment_method.toLowerCase().trim() ===
          this.state.editSelectedPayment.toLowerCase().trim()
      )
    ) {
      message.error(t("Payment Method already in the list"));
      this.setState({
        editPaymentMethodSwitch: false,
        editSelectedPaymentId: null,
        editSelectedPayment: null,
      });
      return;
    }
    try {
      const res = await instance.post("/update_custom_payment_methods/", {
        id: id,
        payment_method: this.state.editSelectedPayment,
      });
      this.props.updatePaymentMethodToList(id, res.data);
      this.setState({
        editPaymentMethodSwitch: false,
        editSelectedPaymentId: null,
        editSelectedPayment: null,
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        message.error(t("AccessDeniedMessage"));
      } else {
        this.setState({
          editPaymentMethodSwitch: false,
          editSelectedPaymentId: null,
          editSelectedPayment: null,
        });
        message.error(t("SomethingWentWrong"));
      }
    }
  };

  saveButtonDisabled = () => {
    const s = this.state;
    return !s.selectedPayment;
  };

  updateButtonDisabled = (editSelectedPayment, editSelectedPaymentId, item) => {
    if (editSelectedPaymentId === item.id) {
      return (
        editSelectedPayment?.toLowerCase().trim() ===
        item.payment_method?.toLowerCase().trim()
      );
    } else if (this.state.editPaymentMethodSwitch === true) {
      return true;
    }
    return false;
  };
  componentDidMount = async () => {
    if (this.props.paymentMethods === null) {
      const { t } = this.props;
      try {
        const res = await instance.post("/get_custom_payment_methods/");
        this.props.setPaymentMethods(res.data);
      } catch (err) {
        if (err.response && err.response.status === 403)
          message.error(t("AccessDeniedMessage"));
        else message.error(t("SomethingWentWrong"));
      }
    }
  };

  render() {
    const { visible, t, config } = this.props;
    const { paymentMethod, paymentReference, selectedPayment } = this.state;

    return (
      <Modal
        style={{ top: 20 }}
        destroyOnClose={true}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {t("Custom Payment Method")}
          </div>
        }
        visible={visible}
        onCancel={() => this.props.onClose()}
        onOk={() => {
          this.props.onClose(selectedPayment, paymentReference);
        }}
        okButtonProps={{
          disabled: this.saveButtonDisabled(),
          loading: this.state.saveLoading,
          style: {
            display: this.state.currentTab === "manage" ? "none" : "",
          },
        }}
        okText={t("Save")}
        cancelText={t("Close")}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className={"mt-3"}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={paymentMethod}
              style={{ width: "90%" }}
              placeholder={t("Add Payment Method")}
              onChange={(e) => this.setState({ paymentMethod: e.target.value })}
            />
            <Button
              type="primary"
              shape={"circle"}
              style={{
                marginLeft: config.direction === "rtl" ? 0 : 10,
                marginRight: config.direction === "rtl" ? 10 : 0,
              }}
              icon={<PlusOutlined style={{ top: 0 }} />}
              onClick={() => this.addPaymentMethod()}
              disabled={!this.state.paymentMethod}
            ></Button>
          </div>
        </div>
        <div className="mb-3">
          <p className={"mt-3"}>{t("Payment Methods List")}</p>

          <List
            itemLayout="horizontal"
            dataSource={this.props.paymentMethods || []}
            renderItem={(item) => (
              <List.Item
                className={"on-hover-payment-method"}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <div
                  onClick={() => {
                    if (this.state.editPaymentMethodSwitch === false) {
                      this.setState({
                        selectedPayment: item.payment_method,
                      });
                    }
                  }}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flex: 1,
                    padding: "16px 10px",
                  }}
                >
                  {this.state.editPaymentMethodSwitch &&
                  this.state.editSelectedPaymentId === item.id ? (
                    <Input
                      value={this.state.editSelectedPayment}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.state.editSelectedPaymentId === item.id)
                            this.updatePaymentMethod(
                              item.id,
                              item.payment_method
                            );
                        }
                      }}
                      onChange={(e) =>
                        this.setState({
                          editSelectedPayment: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <>
                      <Radio
                        checked={
                          this.state.selectedPayment === item.payment_method
                        }
                      >
                        <span
                          style={{
                            fontSize: 16,
                          }}
                        >
                          {item.payment_method}
                        </span>
                      </Radio>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: config.direction === "rtl" ? 0 : 10,
                    marginRight: config.direction === "rtl" ? 10 : 0,
                  }}
                >
                  {!this.state.editPaymentMethodSwitch && (
                    <Button
                      shape="circle"
                      style={{
                        marginRight: config.direction === "rtl" ? 0 : 10,
                        marginLeft: config.direction === "rtl" ? 10 : 0,
                      }}
                      disabled={this.updateButtonDisabled(
                        this.state.editSelectedPayment,
                        this.state.editSelectedPaymentId,
                        item
                      )}
                      onClick={() => {
                        this.setState({
                          editPaymentMethodSwitch: true,
                          editSelectedPaymentId: item.id,
                          selectedPayment: null,
                          editSelectedPayment: item.payment_method,
                        });
                      }}
                      icon={<EditOutlined style={{ top: 0 }} />}
                    ></Button>
                  )}
                  {this.state.editPaymentMethodSwitch && (
                    <Button
                      style={{
                        marginRight: config.direction === "rtl" ? 0 : 10,
                        marginLeft: config.direction === "rtl" ? 10 : 0,
                      }}
                      shape="circle"
                      icon={<CloseOutlined style={{ top: 0 }} />}
                      disabled={this.state.editSelectedPaymentId !== item.id}
                      onClick={() => {
                        this.setState({
                          editPaymentMethodSwitch: false,
                          editSelectedPaymentId: null,
                          editSelectedPayment: null,
                          selectedPayment: null,
                        });
                      }}
                    ></Button>
                  )}
                  <Button
                    danger
                    shape="circle"
                    disabled={this.state.editSelectedPaymentId}
                    onClick={() => {
                      this.setState({
                        editPaymentMethodSwitch: false,
                        editSelectedPaymentId: null,
                        selectedPayment: null,
                      });
                      this.deletePaymentMethod(item.id);
                    }}
                    icon={<DeleteOutlined style={{ top: 0 }} />}
                  ></Button>
                </div>
              </List.Item>
            )}
          />

          <div>
            <p className="mt-3">{t("Payment Reference")}</p>
            <Input
              value={paymentReference}
              placeholder={t("Payment Reference")}
              onChange={(e) =>
                this.setState({ paymentReference: e.target.value })
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    paymentMethods: state.pos.paymentMethods,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentMethods: (data) =>
      dispatch({
        type: "SET_PAYMENT_METHODS",
        payload: data,
      }),
    addPaymentMethodToList: (data) =>
      dispatch({
        type: "ADD_PAYMENT_METHODS",
        payload: data,
      }),
    deletePaymentMethodToList: (data) =>
      dispatch({
        type: "DELETE_PAYMENT_METHODS",
        payload: data,
      }),
    updatePaymentMethodToList: (id, data) =>
      dispatch({
        type: "UPDATE_PAYMENT_METHODS",
        payload: data,
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewPaymentMethodModal));
