import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import { Modal, Input, Button } from "antd";
import { overrideItemPrice, setOrderPOS } from "../../store/actions";

class OverrideTotalModal extends Component {
  state = { subTotal: null };

  setTotal = () => {
    const { overriddenProduct } = this.props;
    if (overriddenProduct == null) {
      this.props.setOrderPOS({
        forcedSubtotal: parseFloat(this.state.subTotal),
      });
    } else {
      this.props.overrideItemPrice({
        index: overriddenProduct,
        forcedPrice: this.state.subTotal,
      });
    }
    this.props.onClose();
  };

  render() {
    const { t, config, visible, settings, overriddenProduct } = this.props;
    const { subTotal } = this.state;

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {overriddenProduct !== null
              ? t("Override Price")
              : t("Override Total")}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            className="posActionButton"
            block
            key="submit"
            type="primary"
            onClick={() => this.setTotal()}
          >
            {overriddenProduct !== null
              ? t("Override Price")
              : t("Override Total")}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        <div className="px-2 mb-3">
          <div className="mt-3">
            <p className="">
              {overriddenProduct !== null ? t("Price") : t("Total")}
            </p>
            <Input
              type="number"
              autoFocus
              suffix={
                config.language === "english"
                  ? settings.currency_english
                  : settings.currency_local
              }
              className="w-100"
              min={0}
              onChange={(e) =>
                this.setState({
                  subTotal: e.target.value,
                })
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return { config: state.config, settings: state.auth.settings };
};

const mapDispatchToProps = (dispatch) => {
  return {
    overrideItemPrice: (payload) => dispatch(overrideItemPrice(payload)),
    setOrderPOS: (params) => dispatch(setOrderPOS(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(OverrideTotalModal));
