const trans = {
  "Landing Links": "صفحة الوصول",
  "Enable landing links": "تفعيل صفحة الوصول",
  "Access link": "رابط الصفحة",
  Links: "الروابط",
  "Lifetime Analytics": "اجمالي الاحصائيات",
  Views: "المشاهدات",
  Clicks: "الضغطات",
  Url: "الرابط",
  "Title in English": "العنوان بالانجليزي",
  "Title in Arabic": "العنوان بالعربي",
  "Url in English": "الرابط بالانجليزي",
  "Url in Arabic": "الرابط بالعربي",
  "Add New Link": "اضافة رابط جديد",
  "Basic info": "المعلومات الأساسية",
  "Arabic title": "العنوان بالعربي",
  Bio: "الوصف",
  "Arabic bio": "الوصف بالعربي",
  Colors: "الألوان",
  "Profile title color": "لون العنوان",
  "Background type": "نوع الخلفية",
  "Background photo": "صورة الخلفية",
  "Plain color": "لون سادة",
  "Bio color": "لون الوصف",
  "Background color": "لون الخلفية",
  "Buttons background": "لون خلفية الأزرار",
  "Buttons text color": "لون عناوين الأزرار",
  "Buttons outline color": "لون اطار الأزرار",
  "Open page": "فتح الصفحة",
  "Create a page with a list of links": "انشئ صفحة بقائمة روابط",
  Analytics: "الاحصائيات",
};

export default trans;
