import {
  FETCH_PROMOTIONS,
  DELETE_PROMOTION,
  UNDELETE_PROMOTION,
  TOGGLE_PROMOTION_STATUS,
  CREATE_PROMOTION,
  SET_PROMOTIONS_FETCH_LOADING,
  QUICK_CHANGE_PROMOTION_IMAGE,
  BULK_DELETE_PROMOTIONS,
  BULK_TOGGLE_PROMOTIONS,
  UPDATE_PROMOTION_DETAILS,
  EXTEND_PROMOTION,
  EDIT_PROMOTION,
  TOGGLE_COUPON_PRIVATE,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const fetchPromotions = (isSmartPromo = false, t) => {
  return async (dispatch) => {
    dispatch({ type: SET_PROMOTIONS_FETCH_LOADING, payload: isSmartPromo });
    try {
      let res = await instance.get(`/get_promotions_list/${isSmartPromo}`);
      const list = res.data;
      dispatch({
        type: FETCH_PROMOTIONS,
        payload: { list, isSmartPromo },
      });
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
    }
  };
};
export const deletePromotion = (index, id, isSmartPromo = false, t) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PROMOTION, payload: { index, isSmartPromo } });
    try {
      let res = await instance.post("/delete_promotion/", { id, isSmartPromo });

      const success = res.data.success;
      const messageRes = res.data.message;
      if (success) {
        message.success(t(messageRes));
      } else {
        dispatch({
          type: UNDELETE_PROMOTION,
          payload: { index, isSmartPromo },
        });
        message.error(t(messageRes));
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({ type: UNDELETE_PROMOTION, payload: { index, isSmartPromo } });
    }
  };
};

export const togglePromotionStatus = (index, id, isSmartPromo = false, t) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_PROMOTION_STATUS,
      payload: { index, isSmartPromo },
    });
    try {
      let res = await instance.post("/toggle_promotion_status/", {
        id,
        isSmartPromo,
      });

      message.success(t("Status has been toggled successfully"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({
        type: TOGGLE_PROMOTION_STATUS,
        payload: { index, isSmartPromo },
      });
    }
  };
};

export const toggleCouponPrivate = (index, id, t) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_COUPON_PRIVATE,
      payload: { index },
    });
    try {
      let res = await instance.post("/toggle_coupon_private/", {
        id,
      });
      message.success(t("Toggled successfully"));
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 403)
        message.error(t("AccessDeniedMessage"));
      else message.error(t("SomethingWentWrong"));
      dispatch({
        type: TOGGLE_COUPON_PRIVATE,
        payload: { index },
      });
    }
  };
};

export const createPromotion = (promotion, isSmartPromo = false) => {
  return {
    type: CREATE_PROMOTION,
    payload: { promotion, isSmartPromo },
  };
};

export const editSmartPromotion = (promotion, index) => {
  return {
    type: EDIT_PROMOTION,
    payload: { promotion, index },
  };
};

export const quickChangePromotionImage = (
  index,
  image,
  isSmartPromo = false
) => {
  return {
    type: QUICK_CHANGE_PROMOTION_IMAGE,
    payload: { index, image, isSmartPromo },
  };
};

export const updatePromotionDetails = (params) => {
  return {
    type: UPDATE_PROMOTION_DETAILS,
    payload: params,
  };
};

export const extendPromotion = (params) => {
  return {
    type: EXTEND_PROMOTION,
    payload: params,
  };
};

export const bulkDeletePromotions = (ids, isSmartPromo = false) => {
  return {
    type: BULK_DELETE_PROMOTIONS,
    payload: { ids, isSmartPromo },
  };
};

export const bulkTogglePromotions = (ids, status, isSmartPromo = false) => {
  return {
    type: BULK_TOGGLE_PROMOTIONS,
    payload: { ids, status, isSmartPromo },
  };
};
