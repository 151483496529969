import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Antd components
import {
  Modal,
  Button,
  Checkbox,
  Row,
  Col,
  InputNumber,
  Input,
  message,
  Spin,
} from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { GetEnglishOrArabic } from "../";
// Actions
import {
  addProductToCart,
  updateUsedComponents,
  updatePOSBranchProduct,
  editProductFromCart,
} from "../../../store/actions";
import {
  isEmpty,
  preciseNumber,
  reduceComponentsInventories,
  reduceCompositeInventories,
} from "../../../store/reducers/pos";
import { updateProduct } from "../../../store/actions/products";
import instance from "../../../store/actions/instance";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import { setDeliveryChargePOS } from "../../../store/actions/pos";


//Components

import ProductOptions from "./ProductOptions";
import ProductBookables from "./ProductBookables";
import ProductResources from "./ProductResources";
import ProductExtraFields from "./ProductExtraFields";
import ProductDescription from "./ProductDescription";
import {
  isApplicableCategeoryPromo,
  isNotExcludedProuduct,
} from "../DiscountModal/helpers";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function CalculateOptionsPrice(options) {
  let sum = 0;
  let selected_option = {};

  for (let key of Object.keys(options)) {
    selected_option = options[key].selectedOptions;

    for (let selected_key of Object.keys(selected_option)) {
      sum +=
        selected_option[selected_key].quantity *
        selected_option[selected_key].price;
    }
  }
  return sum;
}

class ProductModal extends Component {
  getInitialQuantity = () => {
    const product = this.props.originalProduct;
    const EditedProduct = this.props.EditedProduct;
    if (EditedProduct && !EditedProduct.hasError) {
      return EditedProduct.quantity;
    }
    if (
      ["stocked", "composite"].includes(product.type_of_product) &&
      product.increments > product.inventory_on_hand &&
      product.inventory_on_hand > 0
    ) {
      return product.inventory_on_hand;
    }
    return product.increments;
  };
  getUsedComponents = () => {
    const { EditedProduct } = this.props;
    if (!EditedProduct) return {};
    if (EditedProduct.usedComponents)
      return { ...this.props.EditedProduct?.usedComponents };
    return {};
  };

  state = {
    quantity: this.getInitialQuantity(),
    options: this.props.EditedProduct?.options || {},
    extraFields: this.props.EditedProduct?.extraFields || {},
    bookingSlot: this.props.EditedProduct?.booking_slot || null,
    ignoreInventory: this.props.EditedProduct?.ignoreInventory || false,

    specialRemarks: this.props.EditedProduct?.specialRemarks || "",

    has_resources: this.props.EditedProduct?.has_resources || true,
    has_resource_slots: this.props.EditedProduct?.has_resource_slots || true,
    resource: this.props.EditedProduct?.resource || null,
    resourceSlot: this.props.EditedProduct?.resourceSlot || null,
    resourceError: this.props.EditedProduct?.resourceError || false,

    descriptionModalVisible: false,

    usedComponents: this.getUsedComponents(),
    filename: this.props.specialRemarksFileName || undefined,
  };

  fetchProductDetails = async (id) => {
    const { t, settings, branch } = this.props;
    try {
      const res = await instance.get(
        `/get_product_details/${id}/${branch ? branch + "/" : ""}`
      );
      if (settings.is_branch_based_ordering) {
        this.props.updatePOSBranchProduct(res.data.product);
      } else {
        this.props.updateProduct(res.data.product);
      }
    } catch (err) {
      if (err.response.status == 403) message.error(t("SomethingWentWrong"));
    }
  };

  componentDidMount = () => {
    const { originalProduct } = this.props;

    if (!originalProduct.did_load) {
      this.fetchProductDetails(originalProduct.id);
    }
  };

  getMaximumProductInventoryFromModifiers = () => {
    let productMaxInventory = Infinity;
    let modifierUsedQuantity = {};
    const { options } = this.state;
    const { usedComponents, EditedProduct } = this.props;
    Object.values(options).forEach((option) => {
      Object.values(option.selectedOptions).forEach((choice) => {
        if (
          choice.maxInventory !== null &&
          choice.related_product &&
          !option.quantityExclusive
        ) {
          let choiceInventory =
            choice.maxInventory +
            (EditedProduct?.usedComponents?.[choice.related_product.id] *
              EditedProduct?.quantity || 0) +
            (EditedProduct?.usedComponents?.[choice.related_product.id + "Q"] ||
              0) -
            (usedComponents[choice.related_product.id] || 0);

          modifierUsedQuantity[choice.related_product.id] =
            (modifierUsedQuantity[choice.related_product.id] || 0) +
            choice.quantity;

          productMaxInventory = Math.min(
            productMaxInventory,
            choiceInventory /
              (modifierUsedQuantity[choice.related_product.id] || 0)
          );
        }
      });
    });

    return productMaxInventory;
  };
  hasEnoughInventory = (newQuantity, originalProduct) => {
    let type_of_product = originalProduct.type_of_product;
    const editedProductQuantity =
      (!this.props.EditedProduct?.ignoreInventory &&
        this.props.EditedProduct?.EditedProduct?.booking_slot ==
          this.state.bookingSlot &&
        this.props.EditedProduct?.quantity) ||
      0;

    if (
      (["stocked", "composite"].includes(type_of_product) &&
        (this.state.ignoreInventory ||
          newQuantity <=
            originalProduct.inventory_on_hand + editedProductQuantity)) ||
      type_of_product === "produced" ||
      (type_of_product === "bookable" &&
        !["Out", -1].includes(this.state.bookingSlot) &&
        (this.state.ignoreInventory ||
          newQuantity <=
            originalProduct.booking_slots.find(
              (slot) => slot.id === this.state.bookingSlot
            ).inventory +
              editedProductQuantity))
    ) {
      return true;
    }
    return false;
  };

  getMaxInventory = (originalProduct, IgnoreOverrideInventory = false) => {
    const EditedProduct = this.props.EditedProduct;

    switch (originalProduct.type_of_product) {
      case "composite":
      case "stocked":
        return this.state.ignoreInventory && !IgnoreOverrideInventory
          ? Infinity
          : originalProduct.inventory_on_hand +
              ((!this.state.ignoreInventory && EditedProduct?.quantity) || 0);
      case "bookable":
        const bookingSlot = originalProduct.booking_slots.find(
          (slot) => slot.id === this.state.bookingSlot
        );
        return bookingSlot
          ? this.state.ignoreInventory && !IgnoreOverrideInventory
            ? Infinity
            : bookingSlot.inventory +
              ((!this.state.ignoreInventory &&
                EditedProduct?.booking_slot == this.state.bookingSlot &&
                EditedProduct?.quantity) ||
                0)
          : 0;
      default:
        return Infinity;
    }
  };

  getBookingSlotDetails = () => {
    const { originalProduct } = this.props;
    const { bookingSlot } = this.state;
    if (bookingSlot) {
      return {
        ...originalProduct.booking_slots.find(
          (slot) => slot.id === bookingSlot
        ),
      };
    }
    return null;
  };

  addProductToCart = (promoItems) => {
    const { product, addProductToCart, onClose, originalProduct, t, products } =
      this.props;
    const {
      quantity,
      options,
      bookingSlot,
      ignoreInventory,
      specialRemarks,
      extraFields,
    } = this.state;
    let bookingSlotDetails = null;
    if (originalProduct.type_of_product === "service") {
      if (
        !this.state.resource ||
        !this.state.resourceSlot ||
        this.state.resourceSlot == -1
      ) {
        this.setState({
          resourceError: true,
        });
        return;
      }
    }

    let quantityLeft = quantity;
    let quantityToBeAdded = quantityLeft;
    for (let i = 0; i < promoItems.length; i++) {
      quantityToBeAdded = quantityLeft;
      if (promoItems[i].quantity < quantityLeft) {
        quantityToBeAdded = promoItems[i].quantity;
      }
      addProductToCart({
        product,
        quantity: quantityToBeAdded,
        ...this.getTotalPrice(product.price, 1, options, extraFields),
        options: options,
        booking_slot: bookingSlot,
        bookingSlotDetails:
          (bookingSlot && this.getBookingSlotDetails()) || null,
        ignoreInventory: ignoreInventory,
        specialRemarks: specialRemarks,
        resource: this.state.resource,
        resourceSlot: this.state.resourceSlot,
        extraFields: extraFields,
        usedComponents: this.state.usedComponents,
        promo: promoItems[i].promo,
        discount: promoItems[i].id,
        discountType:
          (promoItems[i].categories && "category_free") || "products",
        forcedPrice: 0,
      });
      quantityLeft -= quantityToBeAdded;
      if (quantityLeft <= 0) break;
    }

    if (quantityLeft > 0) {
      addProductToCart({
        product,
        quantity: quantityLeft,
        ...this.getTotalPrice(product.price, 1, options, extraFields),
        options: options,
        booking_slot: bookingSlot,
        bookingSlotDetails:
          (bookingSlot && this.getBookingSlotDetails()) || null,
        ignoreInventory: ignoreInventory,
        specialRemarks: specialRemarks,
        resource: this.state.resource,
        resourceSlot: this.state.resourceSlot,
        extraFields: extraFields,
        usedComponents: this.state.usedComponents,
      });
    }
    const allCharged = this.props.pos.cart?.every(
      (product) => !product["chargeDelivery"]
    );
    this.props.setDeliveryChargePOS({
      deliveryCharge:
        this.props.pos.cart.length && allCharged
          ? 0
          : this.props.pos.deliveryChargeBackup,
      fromPage: "products",
    });
    let usedComponents = { ...this.props.usedComponents };

    let shouldUpdateComponents = false;
    Object.entries(this.state.usedComponents).forEach(
      ([optionId, optionQuantity]) => {
        shouldUpdateComponents = true;
        usedComponents[optionId] =
          (usedComponents[optionId] || 0) +
          optionQuantity *
            ((optionId.toString().includes("Q") && 1) || quantity);
      }
    );

    if (!ignoreInventory) {
      let type_of_product = originalProduct.type_of_product;
      if (["stocked", "composite"].includes(type_of_product)) {
        originalProduct.inventory_on_hand = preciseNumber(
          originalProduct.inventory_on_hand - quantity
        );
        if (!isEmpty(originalProduct.composed_products)) {
          usedComponents = reduceCompositeInventories(
            originalProduct,
            quantity,
            products,
            usedComponents
          );
          shouldUpdateComponents = true;
        } else if (type_of_product === "stocked") {
          usedComponents[originalProduct.id] =
            (usedComponents[originalProduct.id] || 0) + quantity;
          shouldUpdateComponents = true;
        }
        if (!isEmpty(originalProduct.components_dict)) {
          usedComponents = reduceComponentsInventories(
            originalProduct,
            quantity,
            products,
            usedComponents
          );
          shouldUpdateComponents = true;
        }
      } else if (type_of_product === "bookable") {
        originalProduct.booking_slots.find(
          (slot) => slot.id === this.state.bookingSlot
        ).inventory -= quantity;
      }
    }
    if (shouldUpdateComponents) {
      this.props.updateUsedComponents(usedComponents);
    }
    message.success(t("Product Added to Cart"));

    onClose();
  };
  returnInventory = (originalProduct, item, quantity) => {
    const type_of_product = originalProduct.type_of_product;
    let products = this.props.products;
    let usedComponents = { ...this.props.usedComponents };
    let shouldUpdateComponents = false;

    if (["stocked", "composite"].includes(type_of_product)) {
      originalProduct.inventory_on_hand = preciseNumber(
        originalProduct.inventory_on_hand + quantity
      );

      if (!isEmpty(originalProduct.composed_products)) {
        usedComponents = reduceCompositeInventories(
          originalProduct,
          -quantity,
          products,
          usedComponents
        );
        shouldUpdateComponents = true;
      }
      if (!isEmpty(originalProduct.components_dict)) {
        usedComponents = reduceComponentsInventories(
          originalProduct,
          -quantity,
          products,
          usedComponents
        );
        shouldUpdateComponents = true;
      }
    } else if (type_of_product === "bookable") {
      const bookingSlot = originalProduct.booking_slots.find(
        (bs) => bs.id === item.booking_slot
      );
      bookingSlot.inventory += quantity;
    }

    if (shouldUpdateComponents) {
      this.props.updateUsedComponents(usedComponents);
    }
  };

  EditProduct = () => {
    const {
      product,
      editProductFromCart,
      onClose,
      originalProduct,
      t,
      EditedProduct,
    } = this.props;
    const {
      quantity,
      options,
      bookingSlot,
      ignoreInventory,
      specialRemarks,
      extraFields: extraFields,
    } = this.state;

    if (originalProduct.type_of_product === "service") {
      if (
        !this.state.resource ||
        !this.state.resourceSlot ||
        this.state.resourceSlot == -1
      ) {
        this.setState({
          resourceError: true,
        });
        return;
      }
    }

    if (
      EditedProduct.booking_slot != bookingSlot ||
      (!EditedProduct.ignoreInventory && ignoreInventory)
    ) {
      this.returnInventory(
        originalProduct,
        EditedProduct,
        EditedProduct.quantity
      );
    }

    editProductFromCart({
      product,
      quantity,
      ...this.getTotalPrice(product.price, 1, options, extraFields),
      options: options,
      booking_slot: bookingSlot,
      bookingSlotDetails: bookingSlot && this.getBookingSlotDetails(),
      ignoreInventory: ignoreInventory,
      specialRemarks: specialRemarks,
      resource: this.state.resource,
      resourceSlot: this.state.resourceSlot,
      productIndex: EditedProduct.index,
      extraFields: extraFields,
      order_item_id: EditedProduct.order_item_id,
      forcedPrice: EditedProduct.forcedPrice,
      discount: EditedProduct.discount,
      discountType: EditedProduct.discountType,
    });

    let deltaQuantity = quantity - EditedProduct.quantity;

    if (EditedProduct.ignoreInventory && !ignoreInventory) {
      deltaQuantity = quantity;
    }

    if (!ignoreInventory) {
      this.returnInventory(originalProduct, EditedProduct, -deltaQuantity);
    }
    message.success(t("Changed successfully"));

    onClose();
  };
  checkOptions = () => {
    const selectedOptions = this.state.options;
    const options = this.props.originalProduct.options;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.is_required) {
        const optionGroups = [...option.groups];
        if (optionGroups.length <= 0) {
          optionGroups.push(null); // incase option is not assigned to any group , push null so it can loop
        }
        for (let j = 0; j < optionGroups.length; j++) {
          const optionKey = optionGroups[j]
            ? option.id + "-" + optionGroups[j]
            : option.id;
          if (
            !selectedOptions[optionKey] ||
            option.minimum > selectedOptions[optionKey].selected
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };
  checkExtraFields = () => {
    let selectedExtraFields = this.state.extraFields;
    let ProductExtraFields = this.props.originalProduct.extra_fields;

    for (let ProductExtraField of ProductExtraFields) {
      const optionGroups = [...ProductExtraField.groups];
      if (optionGroups.length <= 0) {
        optionGroups.push(null);
      }
      for (let j = 0; j < optionGroups.length; j++) {
        const extraKey = optionGroups[j]
          ? ProductExtraField.id + "-" + optionGroups[j]
          : ProductExtraField.id;

        if (
          ProductExtraField.is_required &&
          ProductExtraField.type == "text" &&
          !selectedExtraFields[extraKey]?.value
        ) {
          return true;
        }
      }
      return false;
    }
  };
  checkBookingSlots = () => {
    const bookingSlot = this.state.bookingSlot;

    if (!bookingSlot || bookingSlot === -1 || bookingSlot === "Out") {
      return true;
    }
    return false;
  };
  checkOutOfStock = () => {
    const originalProduct = this.props.originalProduct;
    const ignoreInventory = this.state.ignoreInventory;
    let type_of_product = originalProduct.type_of_product;
    const bookingSlotId = this.state.bookingSlot;
    let bookingSlot;
    if (bookingSlotId && !["OUT", -1].includes(bookingSlotId)) {
      bookingSlot = originalProduct.booking_slots.find(
        (slot) => slot.id === bookingSlotId
      );
    }

    let inventoryReturnedFromEditedProduct =
      (!this.props.EditedProduct?.ignoreInventory &&
        bookingSlotId == this.props.EditedProduct?.booking_slot &&
        this.props.EditedProduct?.quantity) ||
      0;

    if (
      !ignoreInventory &&
      ((["stocked", "composite"].includes(type_of_product) &&
        originalProduct.inventory_on_hand +
          inventoryReturnedFromEditedProduct <=
          0) ||
        (type_of_product === "bookable" &&
          (!bookingSlot ||
            bookingSlot.inventory + inventoryReturnedFromEditedProduct <= 0)))
    ) {
      return true;
    }
    return false;
  };
  addOptionToProduct = (
    choice,
    option,
    quantity,
    quantityDifference,
    addOption,
    groupID
  ) => {
    const optionKey = !groupID ? choice.option : choice.option + "-" + groupID;

    let newOptions = this.state.options;
    if (!newOptions[optionKey]) {
      newOptions[optionKey] = {
        name: option.name,
        ar_name: option.ar_name,
        selected: quantity,
        multiple: option.multiple,
        quantityExclusive: option.quantity_exclusive,
        selectedOptions: {
          [choice.id]: {
            value: choice.value,
            ar_value: choice.ar_value,
            quantity: quantity,
            price: choice.price,
            related_product: choice.related_product,
            modifier: choice.modifier,
            maxInventory: choice.inventory,
            preparationTime: choice.preparation_time,
          },
        },
      };
    } else {
      if (!addOption) {
        delete newOptions[optionKey].selectedOptions[choice.id];
        if (isEmpty(newOptions[optionKey].selectedOptions)) {
          delete newOptions[optionKey];
        } else {
          newOptions[optionKey].selected += quantityDifference;
        }
      } else {
        if (option.maximum !== 1) {
          newOptions[optionKey].selected += quantityDifference;
          newOptions[optionKey].selectedOptions[choice.id] = {
            value: choice.value,
            ar_value: choice.ar_value,
            quantity: quantity,
            price: choice.price,
            related_product: choice.related_product,
            maxInventory: choice.inventory,
            preparationTime: choice.preparation_time,
          };
        } else {
          newOptions[optionKey].selectedOptions = {
            [choice.id]: {
              value: choice.value,
              ar_value: choice.ar_value,
              quantity: quantity,
              price: choice.price,
              related_product: choice.related_product,
              maxInventory: choice.inventory,
              preparationTime: choice.preparation_time,
            },
          };
        }
      }
    }
    const newUsedComponents = this.state.usedComponents;
    if (choice.inventory !== null && choice.related_product) {
      if (!option.quantity_exclusive) {
        newUsedComponents[choice.related_product.id] =
          (newUsedComponents[choice.related_product.id] || 0) +
          quantityDifference;
      } else {
        newUsedComponents[choice.related_product.id + "Q"] =
          (newUsedComponents[choice.related_product.id + "Q"] || 0) +
          quantityDifference;
      }
    }

    this.setState({ options: newOptions, usedComponents: newUsedComponents });
  };

  addExtraFieldsToProduct = (extraField, value, key) => {
    let newExtraFields = this.state.extraFields;

    if (!newExtraFields[key]) {
      newExtraFields[key] = {
        value: value,
        type: extraField.type,
        name: extraField.name,
        ar_name: extraField.ar_name,
        price: extraField.price,
        quantityExclusive: extraField.quantity_exclusive,
      };
    } else {
      newExtraFields[key].value = value;
      if (value == "" || value == null) {
        delete newExtraFields[key];
      }
    }

    this.setState({ extraFields: newExtraFields });
  };
  SetBookingSlot = (slot) => {
    this.setState({ bookingSlot: slot, quantity: 1 });
  };

  addButttonDisabled = (outOfStock) => {
    const { originalProduct } = this.props;

    if (this.state.uploadLoading) return true;
    if (!originalProduct.did_load) return true;

    if (outOfStock) {
      return true;
    }
    if (originalProduct.options.length && this.checkOptions()) {
      return true;
    }

    if (originalProduct.extra_fields.length && this.checkExtraFields()) {
      return true;
    }
    if (
      originalProduct.type_of_product === "bookable" &&
      this.checkBookingSlots()
    ) {
      return true;
    }

    if (
      originalProduct.type_of_product === "service" &&
      (!this.state.has_resources || !this.state.has_resource_slots)
    ) {
      return true;
    }

    if (this.state.quantity <= 0) return true;

    return false;
  };
  handleInputNumberChange = (
    newQuantity,
    originalProduct,
    originalQuantity
  ) => {
    const maxQuantity = Math.min(
      this.getMaxInventory(originalProduct),
      this.getMaximumProductInventoryFromModifiers()
    );

    if (!newQuantity || !maxQuantity) {
      this.setState({ quantity: originalProduct.increments });
      return;
    }
    if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }
    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = originalQuantity;
    }

    this.setState({ quantity: newQuantity });
  };
  setIgnoreInventory = (checked) => {
    const { quantity } = this.state;
    const { originalProduct } = this.props;

    if (!checked && quantity > originalProduct.increments) {
      const maxQuantity = this.getMaxInventory(originalProduct, true);

      if (maxQuantity < quantity) {
        this.setState({ quantity: maxQuantity });
      }
    }
    this.setState({ ignoreInventory: checked });
  };
  getTotalPrice = (sum, quantity, options, extras, getTotal = false) => {
    const { EditedProduct } = this.props;
    if (quantity <= 0) return 0;
    if (getTotal && EditedProduct?.forcedPrice != null) {
      return EditedProduct.forcedPrice * quantity;
    }
    let selected_option = {};
    let optionSum = 0;
    sum = sum * quantity;
    let quantityExcludedOptionsTotal = 0;

    for (let key of Object.keys(options)) {
      optionSum = 0;
      selected_option = options[key].selectedOptions;

      if (isEmpty(selected_option)) delete options[key];
      else {
        for (let selected_key of Object.keys(selected_option)) {
          optionSum +=
            selected_option[selected_key].quantity *
            selected_option[selected_key].price;
        }
        if (options[key].quantityExclusive) {
          quantityExcludedOptionsTotal = preciseNumber(
            quantityExcludedOptionsTotal + optionSum
          );
        } else {
          sum += optionSum * quantity;
        }
      }
    }

    Object.values(extras).forEach((extra) => {
      if (extra.quantityExclusive) {
        quantityExcludedOptionsTotal = preciseNumber(
          quantityExcludedOptionsTotal + extra.price
        );
      } else {
        sum = preciseNumber(sum + extra.price * quantity);
      }
    });

    if (getTotal) {
      return preciseNumber(sum + quantityExcludedOptionsTotal);
    }

    return {
      total_price: sum,
      quantityExcludedOptionsTotal: quantityExcludedOptionsTotal,
    };
  };
  render() {
    const {
      t,
      config,
      originalProduct,
      visible,
      product,
      settings,
      EditedProduct,
      promotions,
      categories,
    } = this.props;
    const {
      quantity,
      options,
      ignoreInventory,
      extraFields,
      specialRemarks,
      bookingSlot,
      descriptionModalVisible,
      usedComponents,
    } = this.state;

    const outOfStock = this.checkOutOfStock();

    let currency = settings.currency_english;
    let totalPriceFloat = "float-right";
    if (config.language === "arabic") {
      currency = settings.currency_local;
      totalPriceFloat = "float-left";
    }

    let promoQuantity = 0;
    let promoItems = [];
    if (promotions.products.length || promotions.category_free?.length) {
      promoItems = promotions.products
        .filter(
          (p) =>
            p.quantity > 0 &&
            ((p.has_variants && p.product_id === product.parent) ||
              (!p.has_variants && p.product_id === product.id)) &&
            (!EditedProduct || p.id === EditedProduct?.discount)
        )
        .concat(
          promotions.category_free.filter(
            (cd) =>
              cd.quantity > 0 &&
              isApplicableCategeoryPromo(
                cd.categories.map((c) => c.id),
                product,
                categories
              ) &&
              isNotExcludedProuduct(product, [], []) //test
          )
        );

      if (promoItems.length) {
        let newpromoQuantity = 0;
        promoItems.forEach((item) => {
          newpromoQuantity += item.quantity;
        });
        promoQuantity = newpromoQuantity;
      }
    }
    const price = this.getTotalPrice(
      product.price,
      quantity - promoQuantity,
      options,
      extraFields,
      true
    );
    return (
      <Modal
        destroyOnClose
        style={{ top: 20 }}
        title={
          <div
            className="ant-modal-title"
            style={{
              textAlign: config.direction == "rtl" ? "right" : "left",
            }}
          >
            {EditedProduct && t("Editing ")}
            {GetEnglishOrArabic(product.name, product.ar_name, config.language)}
            {originalProduct.did_load &&
              (product.description || product.short_description) && (
                <Button
                  type={"link"}
                  onClick={() =>
                    this.setState({ descriptionModalVisible: true })
                  }
                >
                  {t("More Details")}
                  <QuestionCircleOutlined
                    style={{ position: "relative", top: -2 }}
                  />
                </Button>
              )}
          </div>
        }
        visible={visible}
        onCancel={this.props.onClose}
        footer={[
          <Button
            block
            className="posActionButton"
            disabled={this.addButttonDisabled(outOfStock)}
            key="add to cart"
            type="primary"
            onClick={() =>
              EditedProduct
                ? this.EditProduct()
                : this.addProductToCart(promoItems)
            }
          >
            {this.state.uploadLoading ? (
              <div className="text-center">
                <Spin indicator={antIcon} />
              </div>
            ) : (
              <Row justify="space-between">
                <Col span={8}></Col>
                <Col span={8}>
                  {outOfStock
                    ? t("Out of Stock")
                    : EditedProduct
                    ? t("Edit")
                    : t("Add to Cart")}
                </Col>
                <Col span={8}>
                  {!outOfStock && (
                    <span
                      className={totalPriceFloat}
                    >{`${price} ${currency}`}</span>
                  )}
                </Col>
              </Row>
            )}
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: 0,
          width: "auto",
          padding: "0px 18px",
          textAlign: config.direction == "ltr" ? "left" : "right",
        }}
      >
        {originalProduct.did_load ? (
          <>
            {descriptionModalVisible && (
              <ProductDescription
                product={originalProduct}
                visible={descriptionModalVisible}
                onClose={() => {
                  this.setState({
                    descriptionModalVisible: false,
                  });
                }}
              />
            )}

            {originalProduct.booking_slots.length > 0 &&
              originalProduct.type_of_product == "bookable" && (
                <ProductBookables
                  bookingSlot={bookingSlot}
                  bookingSlots={originalProduct.booking_slots}
                  SetBookingSlot={this.SetBookingSlot}
                  ignoreInventory={ignoreInventory}
                />
              )}
            {product.type_of_product == "service" && (
              <ProductResources
                current_product={product}
                setResource={(rid) =>
                  this.setState({
                    resource: rid,
                    resourceError: false,
                    resourceSlot: null,
                  })
                }
                setResourceSlot={(slotStr) =>
                  this.setState({ resourceSlot: slotStr, resourceError: false })
                }
                resourceError={this.state.resourceError}
                setHasResources={(val) => this.setState({ has_resources: val })}
                setHasResourceSlots={(val) =>
                  this.setState({ has_resource_slots: val })
                }
              />
            )}

            {product.type_of_product !== "service" && (
              <div className=" mt-5 mb-4 disable-select text-center">
                <p style={{ fontSize: 20 }}>{t("Quantity")}</p>
                <Row
                  className="mt-2"
                  justify="center"
                  style={{ alignItems: "baseline" }}
                >
                  <Col span={2}>
                    {" "}
                    <MinusCircleOutlined
                      style={{ fontSize: "25px", color: "#0099CC" }}
                      onClick={() => {
                        let newQuantity = quantity;
                        let increments = originalProduct["increments"];

                        newQuantity = parseFloat(
                          (newQuantity - increments).toPrecision(6)
                        );
                        if (newQuantity <= 0) newQuantity = increments;
                        this.setState({ quantity: newQuantity });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <InputNumber
                      className="product-input-number border"
                      value={quantity}
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        border: "none",
                      }}
                      onChange={(newQuantity) => {
                        if (EditedProduct?.discount) {
                          newQuantity = Math.min(
                            newQuantity,
                            promoQuantity + EditedProduct?.quantity
                          );
                        }
                        this.handleInputNumberChange(
                          newQuantity,
                          originalProduct,
                          quantity
                        );
                      }}
                    />
                  </Col>
                  <Col span={2}>
                    <PlusCircleOutlined
                      style={{ fontSize: "25px", color: "#0099CC" }}
                      onClick={() => {
                        let newQuantity = quantity;
                        let increments = originalProduct["increments"];

                        newQuantity = parseFloat(
                          (newQuantity + increments).toPrecision(6)
                        );

                        if (EditedProduct?.discount) {
                          newQuantity = Math.min(
                            newQuantity,
                            promoQuantity + EditedProduct?.quantity
                          );
                        }

                        if (
                          this.hasEnoughInventory(
                            newQuantity,
                            originalProduct
                          ) &&
                          newQuantity <=
                            this.getMaximumProductInventoryFromModifiers()
                        ) {
                          this.setState({ quantity: newQuantity });
                        }
                      }}
                    />
                  </Col>
                </Row>
                {["stocked", "bookable", "composite"].includes(
                  originalProduct.type_of_product
                ) && (
                  <div className="mt-3 mb-4 ">
                    <p>
                      <Checkbox
                        checked={ignoreInventory}
                        className="mx-3"
                        onChange={(e) => {
                          this.setIgnoreInventory(e.target.checked);
                        }}
                      />
                      {`${t("Override Stock")}`}{" "}
                    </p>
                  </div>
                )}
              </div>
            )}
            {originalProduct.options.length > 0 && (
              <ProductOptions
                options={originalProduct.options}
                addOptionToProduct={this.addOptionToProduct}
                selectedOptions={{ ...options }}
                productUsedComponents={usedComponents}
                productQuantity={quantity}
                editedProduct={this.props.EditedProduct}
                optionsGroups={originalProduct.options_groups}
              />
            )}
            {originalProduct.extra_fields?.length > 0 && (
              <ProductExtraFields
                extraFields={originalProduct.extra_fields}
                selectedExtraFields={{ ...extraFields }}
                addExtraFieldsToProduct={this.addExtraFieldsToProduct}
                optionsGroups={originalProduct.extra_fields_options_groups}
                setUploadLoading={(v) => this.setState({ uploadLoading: v })}
              />
            )}
            <div className="mt-3 mb-4">
              <p className="text-center" style={{ fontSize: 20 }}>
                {t("Special Remarks")}
              </p>
              <Input
                placeholder={t("Special Remarks")}
                value={specialRemarks}
                onChange={(e) =>
                  this.setState({ specialRemarks: e.target.value.trimLeft() })
                }
              />
            </div>
          </>
        ) : (
          <div className="my-4 text-center">
            <Spin indicator={antIcon} />
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    usedComponents: state.pos.usedComponents,
    promotions: state.pos.discounts,
    categories: state.pos.branchProducts?.categories || [],
    pos:state.pos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editProductFromCart: (params) => dispatch(editProductFromCart(params)),
    addProductToCart: (params) => dispatch(addProductToCart(params)),
    updateProduct: (product) => dispatch(updateProduct(product)),
    updatePOSBranchProduct: (product) =>
      dispatch(updatePOSBranchProduct(product)),
    updateUsedComponents: (usedComponents) =>
      dispatch(updateUsedComponents(usedComponents)),
    editProductFromCart: (params) => dispatch(editProductFromCart(params)),
    setDeliveryChargePOS: (params) => dispatch(setDeliveryChargePOS(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(ProductModal));
