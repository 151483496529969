import {
  FETCH_DELIVERY_RATES_AREAS,
  FETCH_DELIVERY_RATES_AREAS_LOADING,
  TOGGLE_DELIVERY_CHARGE,
  DELETE_DELIVERY_CHARGE,
  CHANGE_DELIVERY_CHARGE_PRICE,
  ADD_AREA,
  DELIVERY_CHARGES_BULK_CONTROL,
  DELIVERY_CHARGES_BULK_DELETE,
  FETCH_ZONES_LOADING,
  FETCH_ZONES,
  CHANGE_AREA_OCIMS_ID,
  ADD_PROVINCE,
} from "../actions/actionTypes";

const initialState = {
  areas: [],
  countries: [],
  provinces: [],
  roles: [],
  loading: false,
  zones: [],
  zonesLoading: false,
  branches: [], // used to calculate distances
};

export default (state = initialState, { type, payload }) => {
  let newAreas = [];
  let index = null;
  let newZones = [];
  let newProvinces = []
  switch (type) {
    case FETCH_DELIVERY_RATES_AREAS_LOADING:
      return { ...state, loading: true };

    case FETCH_DELIVERY_RATES_AREAS:
      return {
        ...state,
        areas: payload.areas,
        countries: payload.countries,
        provinces: payload.provinces,
        roles: payload.roles,
        loading: false,
      };

    case TOGGLE_DELIVERY_CHARGE:
      newAreas = state.areas;
      index = newAreas.findIndex((area) => area.id == payload);

      newAreas[index].is_active = !newAreas[index].is_active;

      return { ...state, areas: newAreas };

    case DELETE_DELIVERY_CHARGE:
      newAreas = state.areas;
      newAreas = newAreas.filter((area) => area.id != payload);

      return { ...state, areas: newAreas };

    case CHANGE_DELIVERY_CHARGE_PRICE:
      newAreas = state.areas;
      index = newAreas.findIndex((area) => area.id == payload.id);
      newAreas[index].price = payload.value;

      return { ...state, areas: newAreas };

    case ADD_AREA:
      newAreas = state.areas;
      newAreas = [payload, ...newAreas];
      return { ...state, areas: newAreas };

    case ADD_PROVINCE:
      newProvinces = state.provinces;
      newProvinces = [payload, ...newProvinces];
      return { ...state, provinces: newProvinces };

    case DELIVERY_CHARGES_BULK_CONTROL:
      newAreas = state.areas;

      payload.ids.forEach((id) => {
        index = newAreas.findIndex((area) => area.id == id);

        if (payload.status !== "") {
          newAreas[index].is_active = payload.status;
        }
        if (payload.price !== "") {
          newAreas[index].price = payload.price;
        }
      });
      return { ...state, areas: newAreas };

    case DELIVERY_CHARGES_BULK_DELETE:
      newAreas = state.areas;
      newAreas = newAreas.filter((area) => !payload.includes(area.id));
      return { ...state, areas: newAreas };

    case FETCH_ZONES_LOADING:
      return { ...state, zonesLoading: true };
    case FETCH_ZONES:
      return {
        ...state,
        zones: payload.zones,
        branches: payload.branches,
        areasInZones: payload.area_ids,
        zonesLoading: false,
      };

    case "CREATE_ZONE":
      return { ...state, zones: [...state.zones, payload.zone] };

    case "EDIT_ZONE_NAME":
      newZones = state.zones;
      index = newZones.findIndex((z) => z.id === payload.id);
      newZones[index].name = payload.value;
      return { ...state, zones: newZones };
    case "EDIT_ZONE_DELIVERY_RATE":
      newZones = state.zones;
      index = newZones.findIndex((z) => z.id === payload.id);
      newZones[index].delivery_rate = payload.value;
      return { ...state, zones: newZones };
    case "EDIT_ZONE_MOV":
      newZones = state.zones;
      index = newZones.findIndex((z) => z.id === payload.id);
      newZones[index].minimum_order_value = payload.value;
      return { ...state, zones: newZones };
    case "EDIT_ZONE_DELIVERY_MINUTES":
      newZones = state.zones;
      index = newZones.findIndex((z) => z.id === payload.id);
      newZones[index].delivery_minutes_value = payload.value;
      return { ...state, zones: newZones };

    case "TOGGLE_ZONE":
      newZones = state.zones;
      index = newZones.findIndex((z) => z.id === payload.id);
      newZones[index].is_active = payload.value;
      return { ...state, zones: newZones };

    case "DELETE_ZONE":
      newZones = state.zones.filter((z) => z.id !== payload.id);
      return { ...state, zones: newZones };

    case "BULK_CONTROL_ZONES":
      newZones = state.zones;
      if (payload.type === "toggle") {
        newZones = newZones.map((z) =>
          payload.ids.includes(z.id) ? { ...z, is_active: payload.active } : z
        );
      } else if (payload.type === "delete") {
        newZones = newZones.filter((z) => !payload.ids.includes(z.id));
      }
      return { ...state, zones: newZones };

    case CHANGE_AREA_OCIMS_ID:
      newAreas = state.areas;
      index = newAreas.findIndex((area) => area.area_id == payload.id);
      if (payload.type == "block") {
        newAreas[index].ocims_block = payload.value;
      } else {
        newAreas[index].ocims_id = payload.value;
      }
      return { ...state, areas: newAreas };
    case "CHANGE_PROVINCE_SORT_ORDER": {
      let newProvinces = state.provinces;
      for (const key in payload) {
        const index = newProvinces.findIndex(
          (province) => +province.id === +key
        );
        newProvinces[index].sort_order = payload[key];
      }
      return { ...state, provinces: newProvinces };
    }
    default:
      return state;
  }
};
