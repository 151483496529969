import React from "react";
import { Button, Select, message, Radio, DatePicker } from "antd";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
import { setDHLInfo, setDHLOrderDispatched } from "../../store/actions/orders";
import { connect } from "react-redux";
import moment from "moment";
const { Option } = Select;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function DHL({
  t,
  selectedOrder,
  permissions,
  setDHLInfo,
  config,
  setDHLOrderDispatched,
  orders,
}) {
  const [quoteLoading, setQuoteLoading] = React.useState(false);
  const [dispatchLoading, setDispatchLoading] = React.useState(false);
  const [DHLPickupDateTime, setDHLPickupDateTime] = React.useState(null);
  const [branchID, setBranchID] = React.useState(null);
  const [useArea, setUseArea] = React.useState("");
  const [DHLAreas, setDHLAreas] = React.useState([]);

  let orderObj =
    orders.orders?.find((o) => o.id == selectedOrder.obj.id) ||
    orders.pastOrders?.find((o) => o.id == selectedOrder.obj.id) ||
    orders.recentlyReceived?.find((o) => o.id == selectedOrder.obj.id) ||
    orders.new_orders?.find((o) => o.id == selectedOrder.obj.id);

  const getDHLQuote = async () => {
    setQuoteLoading(true);

    let postData = {
      oid: orderObj.id,
      branch: branchID,
      pickup_datetime: null,
      is_quote: true,
      useArea: useArea,
    };
    if (permissions.all_permitted_branches.length == 1 && !branchID) {
      postData["branch"] = permissions.all_permitted_branches[0].id;
    }
    try {
      const res = await instance.post(`/dispatch_dhl/`, postData);

      if (res.data.data) {
        setDHLInfo({ oid: orderObj.id, dhl_info: res.data.data });
      }
      if (res.data.success) {
        message.success(t("Quote generated successfully"));
      } else if (res.data.areas) {
        setUseArea("");
        if (!res.data.areas.length) {
          message.error(
            t(
              "The destination country is not supported. Please contact us for help"
            )
          );
        } else {
          setDHLAreas(res.data.areas);
          message.warning(t("Please correct the delivery area"));
        }
      } else {
        message.error(t(res.data.message));
      }
    } catch (err) {
      console.error(err);
      if (err.response)
        if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
      message.error(t("SomethingWentWrong"));
    }
    setQuoteLoading(false);
  };

  const actuallyDispatch = async () => {
    setDispatchLoading(true);

    let postData = {
      oid: orderObj.id,
      branch: branchID,
      pickup_datetime: DHLPickupDateTime.format("YYYY-MM-DD HH:mm"),
      is_quote: false,
      useArea: useArea,
    };
    if (permissions.all_permitted_branches.length == 1 && !branchID) {
      postData["branch"] = permissions.all_permitted_branches[0].id;
    }
    try {
      const res = await instance.post(`/dispatch_dhl/`, postData);

      if (res.data.data) {
        setDHLInfo({ oid: orderObj.id, dhl_info: res.data.data });
      }
      if (res.data.success) {
        setDHLOrderDispatched(orderObj.id);
        message.success(t("Dispatched successfully"));
      } else {
        message.error(t(res.data.message));
      }
    } catch (err) {
      console.error(err);
      if (err.response)
        if (err.response.status == 403) message.error(t("AccessDeniedMessage"));
      message.error(t("SomethingWentWrong"));
    }
    setDispatchLoading(false);
  };

  const dhl_info = orderObj.shipping.dhl_info || {};

  const didGenerateQuote = () => {
    return true;
    if (!dhl_info) return false;
    if (
      dhl_info.currency_code &&
      dhl_info.shipping_charge !== null &&
      dhl_info.dimensional_weight !== null &&
      dhl_info.local_product_name &&
      dhl_info.weight_unit
    )
      return true;
    return false;
  };

  const didAttemptDispatch = () => {
    if (!didGenerateQuote() || !dhl_info) return false;
    const quoteFields = [
      "currency_code",
      "shipping_charge",
      "dimensional_weight",
      "local_product_name",
      "weight_unit",
    ];

    return Object.keys(dhl_info)
      .filter((key) => !quoteFields.includes(key))
      .some((key) => !!dhl_info[key]);
  };

  const areThereAnyErrors = () => {
    if (!didGenerateQuote() || !dhl_info) return false;

    if (!!dhl_info.shipment_error || !!dhl_info.pickup_error) return true;
    return false;
  };

  const didDispatchSuccessfully = () => {
    if (didAttemptDispatch() && areThereAnyErrors()) return false;
    if (dhl_info?.airway_bill_number && dhl_info?.pickup_confirmation_number)
      return true;
    return false;
  };

  const pickupDateTimeIsInvalid = () => {
    if (!DHLPickupDateTime) return false;
    if (DHLPickupDateTime.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD"))
      return true;
    if (
      DHLPickupDateTime.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
    ) {
      if (DHLPickupDateTime.format("HH:mm") < moment().format("HH:mm"))
        return true;
    }
    if (
      DHLPickupDateTime.format("HH:mm") < "09:00" ||
      DHLPickupDateTime.format("HH:mm") > "14:59"
    )
      return true;
    return false;
  };
  return (
    <div>
      {!didDispatchSuccessfully() && (
        <>
          {permissions.all_permitted_branches.length > 1 ? (
            <>
              <p>1 - {t("Select a branch")}</p>
              <Radio.Group
                onChange={(e) => setBranchID(e.target.value)}
                value={branchID}
                className="mb-3"
              >
                {permissions.all_permitted_branches.map((branch) => (
                  <Radio.Button value={branch.id}>
                    {config.language == "arabic" ? branch.ar_name : branch.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </>
          ) : null}
          <div>
            <p>2 - {t("Get a quote to view details about the shipment")}</p>
            <div className="my-3">
              {!!DHLAreas?.length && (
                <div className="my-3">
                  <p>
                    - DHL couldn't recognize the area "{orderObj.area?.name}" in{" "}
                    {orderObj.area.country_name}. Please correct it from the
                    list below, then try to get the quote again
                  </p>
                  <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder={t("Select an area")}
                    optionFilterProp="children"
                    value={useArea || undefined}
                    onChange={(value) => {
                      setUseArea(value);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {DHLAreas.map((area) => (
                      <Option key={Math.random() * 89999} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              <Button loading={quoteLoading} onClick={() => getDHLQuote()}>
                {t("Get quote")}
              </Button>
            </div>
          </div>
        </>
      )}

      {didGenerateQuote() && (
        <div>
          <p>{t("Quote details")}:</p>
          {!!dhl_info.currency_code && (
            <p className="mb-0">
              {t("Currency code")}: {dhl_info.currency_code}
            </p>
          )}
          {!!dhl_info.local_product_name && (
            <p className="mb-0">
              {t("Local product name")}: {dhl_info.local_product_name}
            </p>
          )}
          {!!dhl_info.weight_unit && (
            <p className="mb-0">
              {t("Weight unit")}: {dhl_info.weight_unit}
            </p>
          )}
          {dhl_info.dimensional_weight !== null && (
            <p className="mb-0">
              {t("Dimensional Weight")}: {dhl_info.dimensional_weight}
            </p>
          )}
          {dhl_info.shipping_charge !== null && (
            <p className="mb-0">
              {t("Shipping charge")}: {dhl_info.shipping_charge}
            </p>
          )}

          {didAttemptDispatch() && (
            <>
              <p className="my-3">{t("Dispatch info")}</p>
              {!!dhl_info.airway_bill_number && (
                <p className="mb-0">
                  Airway bill number: {dhl_info.airway_bill_number}
                </p>
              )}
              {!!dhl_info.pickup_confirmation_number && (
                <p className="mb-0">
                  Pickup confirmation number:{" "}
                  {dhl_info.pickup_confirmation_number}
                </p>
              )}

              {!!dhl_info.pdf_file && (
                <p className="mb-0">
                  PDF file:{" "}
                  <a target="_blank" href={dhl_info.pdf_file}>
                    {t("Open AWB file")}
                  </a>
                </p>
              )}
              {!!dhl_info.pdf_invoice && (
                <p className="mb-0">
                  PDF file:{" "}
                  <a target="_blank" href={dhl_info.pdf_invoice}>
                    {t("Open DHL invoice file")}
                  </a>
                </p>
              )}
              {!!dhl_info.shipment_date && (
                <p className="mb-0">
                  {t("Shipment date")}:{" "}
                  {moment(dhl_info.shipment_date, "YYYY-MM-DD").format(
                    "YYYY-MM-DD"
                  )}
                </p>
              )}

              {!!dhl_info.pickup_from_branch && (
                <p className="mb-0">
                  {t("Pickup from branch")}: {dhl_info.pickup_from_branch}
                </p>
              )}

              {!!dhl_info.pickup_datetime && (
                <p className="mb-0">
                  {t("Pickup date & time")}:{" "}
                  {moment(dhl_info.pickup_datetime, "YYYY-MM-DDTHH:mm").format(
                    "YYYY-MM-DD h:mm A"
                  )}
                </p>
              )}

              {areThereAnyErrors() && (
                <>
                  <p className="my-3">{t("Errors")}</p>
                  {!!dhl_info.shipment_error && (
                    <p className="mb-0" style={{ color: "red" }}>
                      - Shipment error: {dhl_info.shipment_error}
                    </p>
                  )}

                  {!!dhl_info.pickup_error && (
                    <p className="mb-0" style={{ color: "red" }}>
                      - Pickup booking error: {dhl_info.pickup_error}
                    </p>
                  )}
                  <p className="mt-3">
                    {t(
                      "Please contact your account manager to resolve the errors, or you can try to dispatch again"
                    )}
                  </p>
                </>
              )}
            </>
          )}

          {!didDispatchSuccessfully() && (
            <div className="mt-4">
              <span>3 - {t("Select a pickup date")}: </span>
              <DatePicker
                allowClear={false}
                style={{ maxWidth: 180 }}
                className={config.direction == "ltr" ? "ml-2" : "mr-2"}
                format={"YYYY-MM-DD HH:mm"}
                value={DHLPickupDateTime}
                showTime={{ format: "HH:mm" }}
                onChange={(value, dateString) => {
                  if (value.add(10, "seconds") >= moment())
                    setDHLPickupDateTime(value);
                }}
                disabledDate={(current) => {
                  return (
                    current &&
                    (current.format("YYYY-MM-DD") <
                      moment().format("YYYY-MM-DD") ||
                      current.format("YYYY-MM-DD") >
                        moment().add(6, "days").format("YYYY-MM-DD") ||
                      current.isoWeekday() == 5 ||
                      current.isoWeekday() == 6)
                  );
                }}
                disabledTime={() => {
                  return {
                    disabledHours: () => range(0, 9).concat(range(15, 24)),
                  };
                }}
              />
              {pickupDateTimeIsInvalid() && (
                <p className="mt-3" style={{ color: "red" }}>
                  {t("This pickup date or time is invalid")}
                </p>
              )}
              <p className="my-2">
                {t(
                  "Note: pickup time should be between 9 AM and 3 PM, Sunday to Thursday. For GCC shipping, the order will take 2 working days to be delivered after pickup."
                )}
              </p>
              <Button
                type="primary"
                className="mt-3"
                disabled={!DHLPickupDateTime || pickupDateTimeIsInvalid()}
                onClick={() => actuallyDispatch()}
                loading={dispatchLoading}
              >
                {t("Dispatch order")}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    config: state.config,
    permissions: state.auth.permissions,
    orders: state.orders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDHLInfo: (data) => dispatch(setDHLInfo(data)),
    setDHLOrderDispatched: (oid) => dispatch(setDHLOrderDispatched(oid)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(DHL));
