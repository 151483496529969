import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import instance from "../../store/actions/instance";
// Antd Components
import {
  Modal,
  message,
  Radio,
  Select,
  Input,
  InputNumber,
  Button,
  Space,
  DatePicker,
  Row,
  Col,
  Spin,
  Popover,
} from "antd";
import { fetchRFM } from "../../store/actions/reports";
import moment from "moment";
import {
  PlusOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import CustomModal from "./CustomModal";

const { RangePicker } = DatePicker;
const { confirm } = Modal;
// My components
const { Option } = Select;
// Actions
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { TextArea } = Input;

function RadioButt({ selected, title, onClick, disabled, exp, language }) {
  return (
    <div
      style={{
        width: "100%",
        height: "50px",
        borderColor: "#0099cc",
        background: selected ? "#0099cc47" : "transparent",
        borderRadius: 3,
        border: "1px solid #0099cc",
        padding: 12,
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <Radio checked={selected} disabled={disabled}>
        {title}{" "}
      </Radio>
      {!!exp && (
        <Popover
          placement="bottom"
          content={
            <div style={{ maxWidth: 350 }}>
              <p>{exp}</p>
            </div>
          }
          title={title.split(" (")[0]}
        >
          <InfoCircleOutlined
            style={{
              fontSize: 14,
              color: "#0099cc",
              position: "relative",
              top: 4,
              float: language === "arabic" ? "left" : "right",

              margin:
                language == "arabic" ? "0px 5px 0px 10px" : "0px 10px 0px 5px",
            }}
          />
        </Popover>
      )}
    </div>
  );
}
class NewCampaignModal extends Component {
  state = {
    saveLoading: false,
    name: "",
    purpose: "retain",
    segment: null,
    content: "",
    start: moment().add(-90, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),

    saveButtonDisabled: true,

    confirmModalOpen: false,
    language: this.props.config.language,
    customersType: "customers",
    from: "smsCampign",
    customModalVisible: false,
    selectedCustomers: [],
    selectedCustomersNumbers: [],
    countriesCodes: [],
  };

  findDefaultCountry = () => {
    const countriesArray = [
      { Kuwait: "+965" },
      { Oman: "+968" },
      { Bahrain: "+973" },
      { "Saudi Arabia": "+966" },
      { "United Arab Emirates": "+971" },
      { Qatar: "+974" },
    ];
    let default_country = [];
    const filteredCountries = countriesArray.filter((countryObj) => {
      const countryName = Object.keys(countryObj)[0];
      return (
        countryName.toLowerCase() ===
        this.props.settings.all_countries[0].name.toLowerCase()
      );
    });
    if (filteredCountries.length > 0) {
      default_country = [
        filteredCountries[0][this.props.settings.all_countries[0].name],
      ];
    }
    this.setState({ countriesCodes: default_country });
  };

  componentDidMount = async () => {
    let { start, end } = this.state;
    this.setState({ rfmLoading: true });
    await this.props.fetchRFM(
      start,
      end,
      this.props.t,
      this.state.customersType,
      this.state.from,
      "custom",
      this.state.countriesCodes
    );
    this.setState({ rfmLoading: false });
    this.findDefaultCountry();
  };

  saveButtonDisabled = () => {
    const { name, content } = this.state;
    const { settings } = this.props;
    if (this.calculateCost()) {
      if (settings.sms_balance - this.calculateCost() < 0) return true;
    }
    if (name.trim().length && content.trim().length && !!this.state.segment) {
      if (this.state.saveButtonDisabled)
        this.setState({ saveButtonDisabled: false });
      return false;
    }
    if (!this.state.saveButtonDisabled)
      this.setState({ saveButtonDisabled: true });

    return true;
  };

  calculateCost = () => {
    const { segment, content, name, selectedCustomers } = this.state;
    const { settings, rfmData } = this.props;

    if (
      !rfmData ||
      (!rfmData[segment] &&
        segment == "custom" &&
        selectedCustomers.length == 0) ||
      !content ||
      !name
    )
      return 0;
    if (segment == "custom" && selectedCustomers.length > 0)
      return (
        selectedCustomers.length * settings.sms_charge * this.costMultiplier()
      );
    return rfmData[segment] * settings.sms_charge * this.costMultiplier();
  };

  create = async (calculate) => {
    const { t } = this.props;
    this.setState({ saveLoading: true });
    try {
      const res = await instance.post("/create_sms_campaign/", {
        ...this.state,
        calculate: calculate,
      });
      if (res.data.success) {
        message.success(t("Created the campaign successfully!"));
        this.props.onClose(res.data.campaigns);
      } else {
        message.error(res.data.message || t("SomethingWentWrong"));
      }
    } catch (e) {
      console.error(e);
      message.error(t("SomethingWentWrong"));
    }
    this.setState({ saveLoading: false });
  };

  showAppendNote = () => {
    const { settings } = this.props;
    const { content } = this.state;

    if (
      settings.sms_sender_id === "ORDABLE" &&
      !content.includes(window.location.hostname)
    )
      return true;
    return false;
  };

  costMultiplier = () => {
    let { content, language } = this.state;
    const { settings } = this.props;
    content = content.trim();
    const hasArabic = /[\u0600-\u06FF]/.test(content);
    let oneSMSLength = hasArabic ? 70 : 160;
    let contentLength = content.length;
    if (
      settings.sms_sender_id === "ORDABLE" &&
      !content.includes(window.location.hostname)
    ) {
      contentLength = contentLength + 4 + window.location.hostname.length;
    }
    return Math.ceil(contentLength / oneSMSLength);
  };

  render() {
    const { config, t, settings } = this.props;
    const { segment, customModalVisible, selectedCustomers } = this.state;
    let { rfmData } = this.props;
    if (!rfmData) rfmData = {};

    return (
      <>
        {customModalVisible && (
          <CustomModal
            visible={customModalVisible}
            selectedCustomers={selectedCustomers}
            onClose={(
              shouldUpdate,
              selectedRowKeys,
              selectedRowKeysNumbers
            ) => {
              this.setState({ customModalVisible: false });
              if (shouldUpdate) {
                this.setState({
                  selectedCustomers: selectedRowKeys,
                  selectedCustomersNumbers: selectedRowKeysNumbers,
                });
              }
            }}
          />
        )}
        <Modal
          destroyOnClose={true}
          title={
            <div
              className="ant-modal-title"
              style={{
                textAlign: config.direction == "rtl" ? "right" : "left",
              }}
            >
              {t("New Campaign")}
            </div>
          }
          visible={true}
          onCancel={() => this.props.onClose()}
          onOk={() =>
            confirm({
              icon: null,
              content: (
                <p
                  style={{
                    position: "relative",
                    top: -5,
                    lineHeight: 2,
                    direction: config.direction,
                    textAlign: config.direction === "ltr" ? "left" : "right",
                  }}
                >
                  {t(
                    "Once you create this campaign, a SMS message will be sent to *** customers. Proceed?"
                  ).replace(
                    "***",
                    segment == "custom" ?
                      selectedCustomers.length:rfmData[segment]
                  )}
                </p>
              ),

              onOk: () => {
                this.create();
              },
              okButtonProps: {
                loading: this.state.saveLoading,
              },
              onCancel: () => {},
              width: 380,
            })
          }
          okText={t("Create Campaign")}
          cancelText={t("Close")}
          bodyStyle={{
            paddingTop: 0,
            width: "auto",
            padding: "0px 18px",
            textAlign: config.direction == "ltr" ? "left" : "right",
          }}
          okButtonProps={{
            loading: this.state.saveLoading,
            disabled: this.saveButtonDisabled() || !this.calculateCost(),
          }}
          width={850}
          style={{ top: 10 }}
        >
          <div className="pt-2 px-2">
            <p className="mt-3 bold">{t("Campaign name")}</p>
            <Input
              placeholder={t("Name")}
              onChange={(e) => this.setState({ name: e.target.value })}
              style={{ width: "100%" }}
              value={this.state.name}
            ></Input>

            <p className="mt-4 bold">{t("Campaign purpose")}</p>

            <Radio.Group
              defaultValue="retain"
              buttonStyle="solid"
              onChange={(e) => this.setState({ purpose: e.target.value })}
            >
              <Radio.Button value="retain">
                {t("Increase retention rate")}
              </Radio.Button>
              <Radio.Button value="increase basket">
                {t("Increase average order value")}
              </Radio.Button>
              <Radio.Button value="re-engage">
                {t("Increase engagement")}
              </Radio.Button>
              <Radio.Button value="encourage">
                {t("Increase sales")}
              </Radio.Button>
            </Radio.Group>

            <p className="mt-4 bold">{t("Targeted segment")}</p>
            <div>
              <span style={{ fontWeight: "bold" }} className="mx-2">
                {t("Date")}:
              </span>{" "}
              <RangePicker
                picker="date"
                value={[
                  moment(this.state.start, "YYYY-MM-DD"),
                  moment(this.state.end, "YYYY-MM-DD"),
                ]}
                onChange={(date, dateStr) => {
                  this.setState({ start: dateStr[0], end: dateStr[1] });
                  this.props.fetchRFM(
                    dateStr[0],
                    dateStr[1],
                    t,
                    this.state.customersType,
                    this.state.from,
                    "custom",
                    this.state.countriesCodes
                  );
                }}
                allowClear={false}
                disabledDate={(current) => current > moment()}
                style={{ marginBottom: 10 }}
              />
              {this.props.settings.enable_businesses && (
                <Select
                  style={{
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                  defaultValue="customers"
                  onChange={(value) => {
                    this.props.fetchRFM(
                      this.state.start,
                      this.state.end,
                      t,
                      value,
                      this.state.from,
                      "custom",
                      this.state.countriesCodes
                    );
                    this.setState({ customersType: value });
                  }}
                >
                  <Option value="customers">{t("Customers")}</Option>
                  <Option value="business">{t("Business")}</Option>
                </Select>
              )}
              {this.props.settings.is_gcc && (
                <Select
                  style={{
                    marginRight: 5,
                    marginLeft: 5,
                    minWidth: 195,
                  }}
                  mode="multiple"
                  defaultValue={this.state.countriesCodes}
                  onChange={(value) => {
                    this.props.fetchRFM(
                      this.state.start,
                      this.state.end,
                      t,
                      this.state.customersType,
                      this.state.from,
                      "custom",
                      value
                    );
                    this.setState({ countriesCodes: value });
                  }}
                >
                  <Option value="+965">{t("Kuwait")}</Option>
                  <Option value="+973">{t("Bahrain")}</Option>
                  <Option value="+974">{t("Qatar")}</Option>
                  <Option value="+966">{t("Saudi Arabia")}</Option>
                  <Option value="+968">{t("Oman")}</Option>
                  <Option value="+971">{t("United Arab Emirates")}</Option>
                </Select>
              )}
            </div>
            <div>
              {this.state.rfmLoading ? (
                <div className="my-5 text-center">
                  {" "}
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "all_store_customers"}
                      title={
                        `${t("All customers")} (` +
                        rfmData.all_store_customers +
                        ")"
                      }
                      onClick={() =>
                        this.setState({ segment: "all_store_customers" })
                      }
                      disabled={rfmData.all_store_customers === 0}
                      language={config.language}
                      exp={t(
                        "These are all registered customers including imported ones"
                      )}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "all"}
                      title={`${t("All segments")} (` + rfmData.all + ")"}
                      onClick={() => this.setState({ segment: "all" })}
                      disabled={rfmData.all === 0}
                      language={config.language}
                      exp={t(
                        "All customers who have made a purchase and fall under any of the segments"
                      )}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "best"}
                      title={`${t("Best")} (` + rfmData.best + ")"}
                      onClick={() => this.setState({ segment: "best" })}
                      disabled={rfmData.best === 0}
                      exp={t(
                        "Buy and spend more then others and have very recently made a purchase - Suggest to reward with premium perks and benefits"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "loyal"}
                      title={`${t("Loyal")} (` + rfmData.loyal + ")"}
                      onClick={() => this.setState({ segment: "loyal" })}
                      disabled={rfmData.loyal === 0}
                      exp={t(
                        "Purchase from you very often and spend a relatively good amount relative to other customers - Suggest to reward with exclusive perks and benefits"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "risk"}
                      title={`${t("Risk")} (` + rfmData.risk + ")"}
                      onClick={() => this.setState({ segment: "risk" })}
                      disabled={rfmData.risk === 0}
                      exp={t(
                        "Spend well with you however recently have dropped - Reengage with a call/message and discount voucher"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "new"}
                      title={`${t("New")} (` + rfmData.new + ")"}
                      onClick={() => this.setState({ segment: "new" })}
                      disabled={rfmData.new === 0}
                      exp={t(
                        "New in the selected time period. - Encourage a second order with a call/message and discount voucher"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "frugal"}
                      title={`${t("Frugal")} (` + rfmData.frugal + ")"}
                      onClick={() => this.setState({ segment: "frugal" })}
                      disabled={rfmData.frugal === 0}
                      exp={t(
                        "Do not spend much or purchase from you often. - Targeting these clients with advertising is not recommended, instead understand what these customers need and provide through promotions etc."
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "spenders"}
                      title={`${t("Spenders")} (` + rfmData.spenders + ")"}
                      onClick={() => this.setState({ segment: "spenders" })}
                      disabled={rfmData.spenders === 0}
                      exp={t(
                        "Do not purchase frequently, but when they do, they spend more than the average customer. - Keep engaged with updates, as discounts may not incentivize this segment to purchase more frequently"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "lost"}
                      title={`${t("Lost")} (` + rfmData.lost + ")"}
                      onClick={() => this.setState({ segment: "lost" })}
                      disabled={rfmData.lost === 0}
                      exp={t(
                        "Purchased very little and less recently relative to others. - Energy better focused on other segments"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "other"}
                      title={`${t("Other")} (` + rfmData.other + ")"}
                      onClick={() => this.setState({ segment: "other" })}
                      disabled={rfmData.other === 0}
                      exp={t(
                        "These customers do not fall into any particular segment in the selected period"
                      )}
                      language={config.language}
                    />
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} xxs={24}>
                    <RadioButt
                      selected={segment === "custom"}
                      title={`${t("Custom")}`}
                      onClick={() =>
                        this.setState({
                          segment: "custom",
                          customModalVisible: true,
                        })
                      }
                      language={config.language}
                    />
                  </Col>
                </Row>
              )}
            </div>
            <p className="mb-2 mt-4">
              {t("Sender ID")}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {settings.sms_sender_id === "ORDABLE"
                  ? "O-HELLO"
                  : settings.sms_sender_id}
              </span>{" "}
              {settings.sms_sender_id === "ORDABLE" &&
                settings.base_country === "Kuwait" && (
                  <Button
                    type="link"
                    onClick={() => this.props.history.push("/manage/addons/62")}
                  >
                    {t("Customize")}
                  </Button>
                )}
            </p>

            <p
              className="bold"
              style={{ float: config.language === "arabic" ? "right" : "left" }}
            >
              {t("SMS content")}
            </p>
            <span
              style={{
                float: config.language === "arabic" ? "left" : "right",
                position: "relative",
                top: -4,
              }}
            >
              <Radio.Group
                value={this.state.language}
                size="small"
                onChange={(v) =>
                  this.setState({
                    language: v.target.value,
                  })
                }
              >
                <Radio.Button value={"english"}>English</Radio.Button>
                <Radio.Button value={"arabic"}>عربي</Radio.Button>
              </Radio.Group>
            </span>
            <TextArea
              className={!this.showAppendNote() ? "mb-4" : ""}
              autoSize={true}
              style={{
                minHeight: 80,
                direction: this.state.language === "arabic" ? "rtl" : "ltr",
              }}
              onChange={(e) => {
                this.setState({ content: e.target.value });
              }}
              placeholder={t("Ex: Checkout our offer on new products")}
              value={this.state.content}
            />
            {this.showAppendNote() && (
              <p className="mb-4 mt-1" style={{ color: "gray", fontSize: 13 }}>
                {t("* Note:")}{" "}
                {t(
                  "Your store domain will be appended to the end of the message"
                )}
              </p>
            )}

            {!!this.calculateCost() && (
              <>
                <p>
                  <span className="bold">{t("Campaign cost")}:</span>{" "}
                  {this.calculateCost()}{" "}
                  {this.calculateCost() > 2 && this.calculateCost() < 11
                    ? t("pointss").replace("ss", "s")
                    : t("points")}
                </p>
                <p>
                  <span className="bold">{t("Current balance")}:</span>{" "}
                  <span
                    style={
                      settings.sms_balance < this.calculateCost()
                        ? {
                            color: "red",
                          }
                        : {}
                    }
                  >
                    {settings.sms_balance}{" "}
                    {settings.sms_balance > 2 && settings.sms_balance < 11
                      ? t("pointss").replace("ss", "s")
                      : t("points")}
                  </span>{" "}
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => this.props.history.push("/manage/addons/63")}
                  >
                    {t("Top-up")}
                  </Button>
                </p>
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    user: state.auth.username,
    rfmData: state.reports.rfmData?.totals,
    customer_list: state.reports.rfmData?.customer_list,
    settings: state.auth.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchRFM: (start, end, t, customersType, from, period, countriesCodes) =>
      dispatch(
        fetchRFM(start, end, t, customersType, from, period, countriesCodes)
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewCampaignModal));
