import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
// Antd Components
import {
  Divider,
  Button,
  Row,
  Col,
  Input,
  Spin,
  message,
  Upload,
  Result,
  Table,
  Popover,
} from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  InboxOutlined,
  WhatsAppOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";

//My Components
import NoAccess from "../Orders/NoAccess";
import instance from "../../store/actions/instance";
import { ReactComponent as OrdersIcon } from "../../assets/icons/invoice.svg";
import NewCampaignModal from "./NewCampaignModal";
import DetailCampaignModal from "./DetailCampaignModal";

const { Dragger } = Upload;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Search } = Input;
const { Column } = Table;
class index extends Component {
  state = {
    loading: true,
    camapigns: [],
    detailModalOpen: false,
    selectedCampaign: null,
  };

  componentDidMount = () => {
    this.fetchCampaigns();
  };

  fetchCampaigns = async () => {
    const { t } = this.props;
    try {
      const res = await instance.get("/fetch_sms_campaigns/");
      if (res.data.success)
        this.setState({ campaigns: res.data.data, loading: false });
      else message.error(t("SomethingWentWrong"));
    } catch (e) {
      console.error(e);
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  handleOpenDetailModal = (campaign) => {
    this.setState({
      detailModalOpen: true,
      selectedCampaign: campaign,
    });
  };

  render() {
    const { config, t, permissions, user, settings } = this.props;
    if (!permissions?.can_create_sms_campaigns) return <NoAccess />;
    const { loading, promises } = this.state;
    if (loading) {
      return (
        <div className="mt-5 text-center">
          <Spin indicator={antIcon} />
        </div>
      );
    }
    let data = this.state.campaigns.map((c) => ({ key: c.id, obj: c }));

    const onCellProps = (record) => {
      return {
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
      };
    };

    // const titlesClassName = "mb-2 ".concat(
    //   config.direction == "ltr" ? "text-left ml-1" : "text-right mr-1"
    // );

    return (
      <div className="pageContainer">
        {this.state.newCampaignModalOpen && (
          <NewCampaignModal
            history={this.props.history}
            onClose={(newCampaigns) =>
              this.setState({
                newCampaignModalOpen: false,
                campaigns: newCampaigns || this.state.campaigns,
              })
            }
          />
        )}
        {this.state.detailModalOpen && (
          <DetailCampaignModal
            open={this.state.detailModalOpen}
            campaign={this.state.selectedCampaign}
            capitalizeFirstLetter={this.capitalizeFirstLetter}
            onClose={() =>
              this.setState({
                detailModalOpen: false,
                selectedCampaign: null,
              })
            }
          />
        )}
        <h1
          className={config.direction === "rtl" ? "pageArTitle" : "pageEnTitle"}
        >
          <OrdersIcon
            className={
              config.direction === "rtl" ? "pageArTitleIcon" : "pageEnTitleIcon"
            }
          />
          {t("SMS Campaigns")}
        </h1>

        <Row
          gutter={[15, 8]}
          style={{
            textAlign: config.direction === "ltr" ? "left" : "right",
          }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button
              type="link"
              icon={<PlusOutlined style={{ position: "relative", top: -2 }} />}
              onClick={() => {
                this.setState({
                  newCampaignModalOpen: true,
                });
              }}
              style={{ float: config.direction === "ltr" ? "right" : "left" }}
            >
              {t("New Campaign")}
            </Button>
          </Col>
          <Col xs={24} sm={18} md={18} lg={18} xl={18}></Col>
        </Row>

        <div className="whiteSubContainer" style={{ paddingBottom: 40 }}>
          <Table
            dataSource={data}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
            onRow={(record, index) => ({
              onClick: (event) => {
                this.handleOpenDetailModal(record.obj);
              },
            })}
          >
            <Column
              title={t("Name")}
              dataIndex="name"
              key="name"
              render={(text, record) => (
                <div style={{ cursor: "pointer", minWidth: 80 }}>
                  {record.obj.name}
                </div>
              )}
              onCell={onCellProps}
            />

            <Column
              title={t("Segment")}
              dataIndex="segment"
              key="segment"
              render={(text, record) => (
                <div style={{ cursor: "pointer" }}>
                  {t(this.capitalizeFirstLetter(record.obj.segment))}
                </div>
              )}
              onCell={onCellProps}
            />

            <Column
              title={t("Reach")}
              dataIndex="reach"
              key="reach"
              render={(text, record) => (
                <div style={{ cursor: "pointer" }}>
                  {record.obj.recipients?.split(",")?.length || 0}
                </div>
              )}
              onCell={onCellProps}
            />

            <Column
              title={t("Date")}
              dataIndex="reach"
              key="reach"
              render={(text, record) => (
                <div style={{ cursor: "pointer" }}>
                  {moment(record.obj.created, "YYYY-MM-DD HH:mm")
                    .format("YYYY-MM-DD h:mm A")
                    .replace("AM", config.language === "arabic" ? "ص" : "AM")
                    .replace("PM", config.language === "arabic" ? "م" : "PM")}
                </div>
              )}
              onCell={onCellProps}
            />
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    settings: state.auth.settings,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(index));
