import React from "react";

export default function Slide({ photo, title, body }) {
  return (
    <div>
      <div
        style={{ width: "100%", minHeight: 300, backgroundColor: "#0099cc" }}
      >
        <img
          style={{ width: "100%", minHeight: 300, border: "none" }}
          src={photo}
        />
      </div>
      <div className="p-4" style={{ minHeight: 200 }}>
        <h2 style={{ fontSize: 24 }}>{title}</h2>
        <p>{body}</p>
      </div>
    </div>
  );
}
